import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { TableParamsSorting } from '../../models/table/TableParams';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import NotificationCenter from 'services/NotificationCenter';
import TableFilter from 'components/TableFilter';
import { RoleModel, RoleRetrieveListModel, RoleRetrieveListModelInterface, UserModel, UserRetrieveListModel, UserRetrieveListModelInterface } from "api";
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import RoleActions from 'reducers/types/Role';
import UserActions from 'reducers/types/User';
import UserState from 'reducers/interface/UserState';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { AccountMultiple } from 'mdi-material-ui';

interface StateProps {
    roles: RoleModel[];
    userReducer: UserState;
    appReducer: AppState;
}

interface DispatchProps {
    listUsers: (params: UserRetrieveListModelInterface) => void;
    fetchRoles: (params: RoleRetrieveListModelInterface) => void;
    clearUsers: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    user?: UserModel;
}

class UserList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    filterColumns: FilterColumn[] = [];

    constructor(props) {
        super(props);
        this.state = {
            user: null
        };
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'full_name', isSortable: true, label: i18next.t('users.full_name')}),
            new TableColumn({ key: 'email', isSortable: true, label: i18next.t('users.email')}),
            new TableColumn({ key: 'role_name', isSortable: true, label: i18next.t('users.role_name')}),
            new TableColumn({ key: 'active', isSortable: true, label: i18next.t('users.active'), align: 'right', customValue: (row: UserModel) => row.active ? i18next.t('general.yes') : i18next.t('general.no') }),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_USER_LIST_FAILURE
        ])
        this.props.fetchRoles({});

        this.updateTabTitle(i18next.t('users.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.API_ROLE_LIST_SUCCESS,
            this.configFilters
        );
    }

    configFilters = () => {
        let roleFilters: Record<string, string> = {};
        this.props.roles.forEach((el: RoleModel) => {
            roleFilters[ el.name ] = el.description;
        });
        this.filterColumns = [
            FilterColumn.makeFilter('user_role', FilterTypes.ENUM, i18next.t('users.role_name'), roleFilters)
        ]
        this.setFilters([]);
    }

    onDetails = (row: UserModel) => {
        this.props.navigate(`/users/${row.uuid}`)
    }

    onCreate = () => {
        this.props.navigate("/users/create")
    }

    getData = () => { this.reloadData() }

    getItemCount = (): number => {
        return this.props.userReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listUsers( this.getParams().getApiFilter() )
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('users', this.props.location)){
            this.props.clearUsers();
        } 
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if (this.getParams() == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('users.model')}
                            headerIcon={<AccountMultiple/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('users.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('users.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={this.props.userReducer.collection.count_filtered}
                                    listItems={this.props.userReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_USER_LIST)}
                                    tableParams={ this.getParams() }
                                    delegate={ this }
                                    idColumn={ 'uuid' } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('full_name', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns;
    }

}

const  mapStateToProps = (state: any) => ({
    userReducer: state.users,
    appReducer: state.app,
    roles: state.roles.collection.items
});

const mapDispatchToProps = (dispatch) => {
    return {
        listUsers: (params: UserRetrieveListModel) => dispatch(UserActions.CreateUserList(params)),
        clearUsers: () => dispatch(UserActions.CreateClearUserList()),
        fetchRoles: (params: RoleRetrieveListModelInterface) => dispatch(RoleActions.CreateRoleList(new RoleRetrieveListModel(params))),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(UserList, TableStyles)
            )
        )
    )
);