import React, { PropsWithChildren } from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import useUnauthorizedStyles from 'styles/UnathorizedStyles';
import ButtonStyled from 'components/styled/ButtonStyled';

interface UnauthorizedProps {

}

const Unauthorized = (props: PropsWithChildren<UnauthorizedProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {classes} = useUnauthorizedStyles();

    return (
        <div className={classes.container}>
            <img src='../../../403bee.png' alt='403-bee' style={{height: 120}}/>
            <h3 style={{marginTop: 40}}>{t('global.title.403_page')}</h3>
            <ButtonStyled 
                onClick={() => navigate('/')}
                className={classes.button}
            >
                {t('global.action.back_to_dashboard')}
            </ButtonStyled>
        </div>
    );
};

export default Unauthorized;

