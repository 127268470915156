import Setting from "api/override/SettingModel";
import { SettingSaga } from "sagas/SettingSagas";
import Log from "utils/Log";
import BaseController from "./BaseController";
import { SettingApi, SettingListRequest } from "api";
import SettingManager from "utils/SettingManager";

export default class SettingController extends BaseController<Setting> {

    private static settingCache: { [key: string]: Setting } = {};

    getSetting = async (key: string): Promise<Setting | undefined> => {
        try {
            if (SettingController.settingCache[key] == null) {
                const response = await SettingSaga.settingRetrieve(key);
                SettingController.settingCache[key] = response;
            }
            return SettingController.settingCache[key];

        } catch (e) {
            Log(e)
        }
        return undefined;
    }

    getSettings = async (): Promise<Setting[]> => {
        let args = {
            settingRetrieveListModel: {
                limit: 100,
                offset: 0,
            }
        } as SettingListRequest;
        const settings = (await new SettingApi().settingList(args)).items as Setting[];
        args.settingRetrieveListModel.offset = 100;
        return settings.concat(
            (await new SettingApi().settingList(args)).items as Setting[]
        )
    }

    setupSettings = async (): Promise<SettingManager> => {
        return new SettingManager(
            await this.getSettings()
        );
    }
}