import ActionTypes from "config/ActionTypes";
import { TableParams } from "models/table/TableParams";
import { FetchFiltersAction, SetFiltersAction } from "reducers/interface/FilterState";



export default class FilterActions {

    static setFilters(pageName: string, params: TableParams): SetFiltersAction {
        return {
            type: ActionTypes.SET_FILTERS,
            pageName: pageName,
            params: params
        }
    }

    static fetchFilters(pageName: string): FetchFiltersAction {
        return {
            type: ActionTypes.FETCH_FILTERS,
            pageName: pageName
        }
    }

}
