import { ApiSuccessResponseModel, SystemCopyDatabaseRequest, SystemCopyDatabaseVerifyRequest, TransferVerifyResponseModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface ValidateInputs extends BaseReducerAction {
    type: ActionTypes.API_DB_TRANSFER_VALIDATE;
    params: SystemCopyDatabaseVerifyRequest;
}

export interface ValidateInputsSuccess extends BaseSagaListResponse<TransferVerifyResponseModel> {
    type: ActionTypes.API_DB_TRANSFER_VALIDATE_SUCCESS;
}

export interface DatabaseTransfer extends BaseReducerAction {
    type: ActionTypes.API_DB_TRANSFER_START;
    params: SystemCopyDatabaseRequest;
}

export interface DatabaseTransferSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_DB_TRANSFER_START_SUCCESS;
}

export default class DatabaseTransferActions {

    static CreateValidateInputs(params: SystemCopyDatabaseVerifyRequest): ValidateInputs {
        return {
            type: ActionTypes.API_DB_TRANSFER_VALIDATE,
            params: params
        }
    }

    static CreateValidateInputsSuccess(params: TransferVerifyResponseModel): ValidateInputsSuccess {
        return {
            type: ActionTypes.API_DB_TRANSFER_VALIDATE_SUCCESS,
            params: params
        }
    }

    static CreateDatabaseTransfer(params: SystemCopyDatabaseRequest): DatabaseTransfer {
        return {
            type: ActionTypes.API_DB_TRANSFER_START,
            params: params
        }
    }

    static CreateDatabaseTransferSuccess(params: ApiSuccessResponseModel): DatabaseTransferSuccess {
        return {
            type: ActionTypes.API_DB_TRANSFER_START_SUCCESS,
            params: params
        }
    }
}