import { ApiSuccessResponseModel, HistoryResponseModel, UserCreateSuccessModel, UserHistoryListRequest, UserListResultModel, UserRetrieveListModel } from "api";
import User from "api/override/UserModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface UserReducerAction extends BaseReducerAction {
    errorObj?: any;
}

export interface UserList extends UserReducerAction {
    type: ActionTypes.API_USER_LIST;
    params: UserRetrieveListModel;
}

export interface UserListSuccess extends BaseSagaListResponse<UserListResultModel> {
    type: ActionTypes.API_USER_LIST_SUCCESS;
}

export interface UserUpdateParams {
    uuid: string;
    userModel: User;
}

export interface UserUpdate extends UserReducerAction {
    type: ActionTypes.API_USER_UPDATE;
    params: UserUpdateParams;
}

export interface UserUpdateSuccess extends BaseSagaListResponse<UserCreateSuccessModel> { 
    type: ActionTypes.API_USER_UPDATE_SUCCESS;
}

export interface UserRetrieve extends UserReducerAction {
    type: ActionTypes.API_USER_RETRIEVE;
    params: string;
}

export interface UserRetrieveSuccess extends BaseSagaListResponse<User> {
    type: ActionTypes.API_USER_RETRIEVE_SUCCESS;
}

export interface UserDelete extends UserReducerAction {
    type: ActionTypes.API_USER_DELETE;
    params: string;
}

export interface UserDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_USER_DELETE_SUCCESS;
}

export interface UserCreate extends UserReducerAction {
    type: ActionTypes.API_USER_CREATE;
    params: User;
}

export interface UserCreateSuccess extends BaseSagaListResponse<UserCreateSuccessModel> {
    type: ActionTypes.API_USER_CREATE_SUCCESS;
}

export interface UserHistoryList extends UserReducerAction {
    type: ActionTypes.API_USER_HISTORY_LIST;
    params: UserHistoryListRequest;
}

export interface UserHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_USER_HISTORY_LIST_SUCCESS;
}

export interface UserClearListParams {
    force?: boolean;
}
export interface UserClearList extends BaseReducerAction {
    type: ActionTypes.API_USER_LIST_CLEAR;
    params?: UserClearListParams;
}

export default class UserActions {

    static CreateUserList(params: UserRetrieveListModel): UserList {
        return {
            type: ActionTypes.API_USER_LIST,
            params: params
        }
    }

    static CreateUserListSuccess(params: UserListResultModel): UserListSuccess {
        return {
            type: ActionTypes.API_USER_LIST_SUCCESS,
            params: params
        }
    }

    static CreateUserUpdate(params: User): UserUpdate {
        return {
            type: ActionTypes.API_USER_UPDATE,
            params: {
                uuid: params.uuid,
                userModel: params
            }
        }
    }

    static CreateUserUpdateSuccess(params: UserCreateSuccessModel): UserUpdateSuccess {
        return {
            type: ActionTypes.API_USER_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateUserRetrieve(params: string): UserRetrieve {
        return {
            type: ActionTypes.API_USER_RETRIEVE,
            params: params
        }
    }

    static CreateUserRetrieveSuccess(params: User): UserRetrieveSuccess {
        return {
            type: ActionTypes.API_USER_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateUserDelete(params: string): UserDelete {
        return {
            type: ActionTypes.API_USER_DELETE,
            params: params
        }
    }

    static CreateUserDeleteSuccess(params: ApiSuccessResponseModel): UserDeleteSuccess {
        return {
            type: ActionTypes.API_USER_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateUserCreate(params: User): UserCreate {
        return {
            type: ActionTypes.API_USER_CREATE,
            params: params
        }
    }

    static CreateUserCreateSuccess(params: UserCreateSuccessModel): UserCreateSuccess {
        return {
            type: ActionTypes.API_USER_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateUserHistoryList(params: UserHistoryListRequest): UserHistoryList {
        return {
            type: ActionTypes.API_USER_HISTORY_LIST,
            params: params
        }
    }

    static CreateUserHistoryListSuccess(params: HistoryResponseModel): UserHistoryListSuccess {
        return {
            type: ActionTypes.API_USER_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearUserList(force: boolean = false): UserClearList {
        return {
            type: ActionTypes.API_USER_LIST_CLEAR,
            params: {force: force}
        }   
    }
}