/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface LoadBalancerModelInterface {
    hostname?: string;
    ip_address?: string;
    lb_state?: string;
    mem_free?: number;
    mem_total?: number;
    load_1?: number;
    load_5?: number;
    load_15?: number;
}

/**
 * 
 * @export
 * @interface LoadBalancerModel
 */
export class LoadBalancerModel extends BaseModel<LoadBalancerModelInterface> {

    /**
     * hostname of the load balancer
     * @type {string}
     * @memberof LoadBalancerModel
     */
    hostname?: string;
    /**
     * IP Address of the load balancer
     * @type {string}
     * @memberof LoadBalancerModel
     */
    ip_address?: string;
    /**
     * Status of this load balancer in the pool
     * @type {string}
     * @memberof LoadBalancerModel
     */
    lb_state?: string;
    /**
     * Memory free in Kb
     * @type {number}
     * @memberof LoadBalancerModel
     */
    mem_free?: number;
    /**
     * Memory total in Kb
     * @type {number}
     * @memberof LoadBalancerModel
     */
    mem_total?: number;
    /**
     * Load average of the last 1 minute
     * @type {number}
     * @memberof LoadBalancerModel
     */
    load_1?: number;
    /**
     * Load average of the last 5 minute
     * @type {number}
     * @memberof LoadBalancerModel
     */
    load_5?: number;
    /**
     * Load average of the last 15 minute
     * @type {number}
     * @memberof LoadBalancerModel
     */
    load_15?: number;

    constructor(data?: LoadBalancerModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: LoadBalancerModel, value: StringDateInterface<LoadBalancerModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function LoadBalancerModelFromJSON(json: any): LoadBalancerModel {
    return LoadBalancerModelFromJSONTyped(json, false);
}

export function LoadBalancerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadBalancerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LoadBalancerModel;
    try {
        let localClass = require.context('../override', true)('./LoadBalancerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'lb_state': !exists(json, 'lb_state') ? undefined : json['lb_state'],
        'mem_free': !exists(json, 'mem_free') ? undefined : Number(json['mem_free']),
        'mem_total': !exists(json, 'mem_total') ? undefined : Number(json['mem_total']),
        'load_1': !exists(json, 'load_1') ? undefined : Number(json['load_1']),
        'load_5': !exists(json, 'load_5') ? undefined : Number(json['load_5']),
        'load_15': !exists(json, 'load_15') ? undefined : Number(json['load_15']),
    });
}

export function LoadBalancerModelToJSON(value?: LoadBalancerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LoadBalancerModel;
    try {
        let localClass = require.context('../override', true)('./LoadBalancerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'hostname': value.hostname,
        'ip_address': value.ip_address,
        'lb_state': value.lb_state,
        'mem_free': value.mem_free,
        'mem_total': value.mem_total,
        'load_1': value.load_1,
        'load_5': value.load_5,
        'load_15': value.load_15,
    } as StringDateInterface<LoadBalancerModel>;
    return createClass.toJson(value, model);
}


