import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import Condition from 'api/override/ConditionModel';
import ConditionActions, { ConditionCreate, ConditionDelete, ConditionHistoryList, ConditionList, ConditionRetrieve, ConditionUpdate } from 'reducers/types/Condition';
import { ConditionListRequest, ConditionApi, ConditionUpdateRequest, ConditionRetrieveRequest, ConditionCreateRequest, ConditionDeleteRequest, ConditionHistoryListRequest } from 'api/apis/ConditionApi';

export function* ConditionSagas() {
    yield takeLatest(ActionTypes.API_CONDITION_LIST, ConditionSaga.conditionListWorkerSaga);
    yield takeLatest(ActionTypes.API_CONDITION_UPDATE, ConditionSaga.conditionUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONDITION_RETRIEVE, ConditionSaga.conditionRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_CONDITION_CREATE, ConditionSaga.conditionCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONDITION_DELETE, ConditionSaga.conditionDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_CONDITION_HISTORY_LIST, ConditionSaga.conditionHistoryListWorkerSaga);
}

export class ConditionSaga {
    
    static *conditionListWorkerSaga(action: ConditionList) {
        try {
            const conditionsArgs = {
                conditionRetrieveListModel: action.params
            } as ConditionListRequest

            const data = yield ApiHandler(new ConditionApi().conditionList, conditionsArgs)
            yield put(ConditionActions.CreateConditionListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_CONDITION_LIST_FAILURE, error as Error))
        }
    }

    static *conditionUpdateWorkerSaga(action: ConditionUpdate) {
        try {
            
            const data = yield ApiHandler(new ConditionApi().conditionUpdate, action.params as ConditionUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(ConditionActions.CreateConditionUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONDITION_UPDATE_FAILURE, error as Error))
        }
    }

    static conditionRetrieve = async(condition: number): Promise<Condition> => {
        return await new ConditionApi().conditionRetrieve({id: condition}) as Condition;
    }
    
    static *conditionRetrieveWorkerSaga(action: ConditionRetrieve) {
        try {
    
            const conditionsArgs = {
                id: action.params
            } as ConditionRetrieveRequest
            const data = yield ApiHandler(new ConditionApi().conditionRetrieve, conditionsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ConditionActions.CreateConditionRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONDITION_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *conditionCreateWorkerSaga(action: ConditionCreate) {
        try {
    
            const conditionsArgs = {
                conditionModel: action.params
            } as ConditionCreateRequest
            const data = yield ApiHandler(new ConditionApi().conditionCreate, conditionsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ConditionActions.CreateConditionCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONDITION_CREATE_FAILURE, error as Error))
        }
    }
    
    static *conditionDeleteWorkerSaga(action: ConditionDelete) {
        try {
            const conditionsArgs = {
                id: action.params
            } as ConditionDeleteRequest
            const data = yield ApiHandler(new ConditionApi().conditionDelete, conditionsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ConditionActions.CreateConditionDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONDITION_DELETE_FAILURE, error as Error))
        }
    }
    
    static *conditionHistoryListWorkerSaga(action: ConditionHistoryList) {
        try{
            const conditionArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ConditionHistoryListRequest
    
            const data = yield ApiHandler(new ConditionApi().conditionHistoryList, conditionArgs);
            yield put(ConditionActions.CreateConditionHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONDITION_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

