import i18next from "i18next";
import * as Yup from "yup";

export const retrieveSettingSchema = () => Yup.object().shape({
    key: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    value: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    stage_value: Yup.string(),
    encrypted: Yup.bool()
        .required(i18next.t('global.validation_schema.required')),
    type: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    description: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    group: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
});