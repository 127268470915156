import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ButtonStyled from 'components/styled/ButtonStyled';
import Client from 'api/override/ClientModel';
import { retrieveClientSchema } from 'schemas/Client';
import ClientForm from 'views/Clients/components/ClientForm';
import { ClientModelRequestEnum } from 'api/models/ClientModel';


interface ClientCreateFormProps {
    onFormSubmit: (value: Client) => void;
}

const ClientCreateForm = (props: ClientCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new Client({
                name: '',
                company_name: '',
                city: '',
                address: '',
                email: '',
                phone: '',
                website: '',
                industry: '',
                request: ClientModelRequestEnum.Backoffice,
            })}
            validationSchema={retrieveClientSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Client>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('clients.model')})}</h2>
                    
                    <ClientForm formik={formik} isCreate/>

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('clients.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default ClientCreateForm;