import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format as formatDate } from 'date-fns';
import { de, enGB, fr, nl } from 'date-fns/locale';
import EnvUtil from 'utils/EnvUtil';

const DateFnsLocale = {'de': de, 'en': enGB, 'fr': fr, 'nl-NL': nl};

i18n
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        fallbackLng: EnvUtil.isProduction() ? 'en' : false,
        debug: !EnvUtil.isProduction(),
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            format: function(value, format, lng) {
                if(value instanceof Date) return formatDate(value, format, {locale:DateFnsLocale[lng]});
                return value;
            },
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true
        },
        backend: {
            queryStringParams: { v: EnvUtil.getTranslationsVersion() }
        }
    });

export default i18n;