import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import {
    Container,
    Card,
    CardContent,
    Grid
} from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import InstanceActions, { InstanceTriggerJobParams } from 'reducers/types/Instance';
import { InstanceRetrieveListModel } from 'api';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import Instance from 'api/override/InstanceModel';
import AppState from 'reducers/interface/AppState';
import CustomActionButton, { CustomLinkIconButton } from 'models/table/CustomActionButton';
import { InstanceTableMenuButton } from './components/InstanceMenu';
import ReloadDataButton from 'components/ReloadDataButton';
import DispatchTableViewComponent, { DispatchTableViewState } from 'components/DispatchTableViewComponent';
import Tasks from 'config/Tasks';
import TableHeader from 'components/TableHeader';
import StorageIcon from '@mui/icons-material/Storage';
import NotificationCenter from 'services/NotificationCenter';
import { BaseAction } from 'reducers/interface/ReducerAction';
import { withStyles } from 'tss-react/mui';
import InstanceState from 'reducers/interface/InstanceState';
import { CheckCircle, CloseCircle, CloudRefresh, Details, PlayBoxMultipleOutline, WebRefresh } from 'mdi-material-ui';
import { IconButton, Tooltip } from "@mui/material";


interface StateProps {
    instances: InstanceState;
    appReducer: AppState;
}

interface DispatchProps {
    listInstances: (params: InstanceRetrieveListModel) => void;
    deleteInstance: (params: string) => void;
    triggerTasks: (params: InstanceTriggerJobParams) => void;
    clearInstances: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends DispatchTableViewState {
    instance?: Instance;
    isDetailsModalOpen: boolean;
    isCreateModalOpen: boolean;
    selected: Instance[];
}

class InstanceList extends DispatchTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customButtons: CustomActionButton[];
    
    constructor(props) {
        super(props);
        this.state = {
            isDetailsModalOpen: false,
            isCreateModalOpen: false,
            selected: []
        };
        this.customButtons = [
            new CustomLinkIconButton('detailsLink', i18next.t('global.button.details'), 'redir-instance', null, Details, '/instances/', 'uuid'),
            new InstanceTableMenuButton()
        ]
        this.setFailuresToListenTo([
            ActionTypes.API_INSTANCE_TRIGGER_JOB_FAILURE,
            ActionTypes.API_INSTANCE_LIST_FAILURE
        ]);
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('instance.name') }),
            new TableColumn({ key: 'domain', isSortable: true, label: i18next.t('instance.domain'), customValue: (row: Instance) => { return row.domain + ".bumbal.eu"} }),
            new TableColumn({ key: 'active', isSortable: false, label: i18next.t('instance.active'), customValue: (row: Instance) => row.active ? <CheckCircle className={this.props.classes.indicator + ' success'} /> : <CloseCircle className={this.props.classes.indicator + ' error'} /> }),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateTabTitle(i18next.t('instance.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.NODE_INSTANCE_DELETE,
            (action: BaseAction) => {
                this.updateListOnDeleteProcessFinished(action)
            }
        );
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.instances.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listInstances( this.getParams().getApiFilter() );
        }
    }

    onDetails = (row: Instance) => {
        this.props.navigate(`/instances/${row.uuid}`)
    }

    onCreate = () => {
        this.props.navigate("/instances/create")
    }

    dialogClosed = () => {
        this.setState({
            isDetailsModalOpen: false,
            isCreateModalOpen: false
        })
    }

    handleMultiRedeploy(selected: string[], taskToPerform: Tasks = Tasks.MULTI_REDEPLOY): void {
        this.props.triggerTasks({uuid: selected.bbFirst(), task: taskToPerform, uuids:selected.bbRemoveFirstItem()})
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('instance', this.props.location)){
            this.props.clearInstances();
        }
    }
    
    renderView() {
        const { classes, appReducer } = this.props;

        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.getData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('instance.model')}
                            headerIcon={<StorageIcon/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('instance.model')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('instance.model')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() } 
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    selectableRows={true}
                                    selected={this.state.selected}
                                    columns={this.columns}
                                    count={this.props.instances.collection.count_filtered}
                                    listItems={this.props.instances.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_LIST)}
                                    delegate={ this }
                                    tableParams={this.getParams()}
                                    customActions={this.customButtons}
                                    idColumn={ 'uuid' } 
                                /> 
                            </Grid>
                        </CardContent>
                        { this.getRedeployView() }
                    </Card>
                </Container>
            </React.Fragment>
        );
    }

    getRedeployButtons(title: string): JSX.Element {
        return (
            <>
                <Tooltip title={i18next.t('global.button.redeploy')} style={{textTransform: 'capitalize'}}>
                    <IconButton
                        onClick={() => this.handleMultiRedeploy(this.state.selected)}
                        style={{color: 'white', padding: 10}}
                        size="large">
                        <PlayBoxMultipleOutline/>
                    </IconButton>
                </Tooltip>
                    
                <Tooltip title={i18next.t('instance.view.redeploy-frontend-selection')} style={{textTransform: 'capitalize'}}>
                    <IconButton
                        onClick={() => this.handleMultiRedeploy(this.state.selected, Tasks.FRONTEND_MULTI_REDEPLOY)}
                        style={{color: 'white', padding: 10}}
                        size="large">
                        <WebRefresh />
                    </IconButton>
                </Tooltip>
                
                <Tooltip title={i18next.t('instance.view.redeploy-backend-selection')} style={{textTransform: 'capitalize'}}>
                    <IconButton
                        onClick={() => this.handleMultiRedeploy(this.state.selected, Tasks.BACKEND_MULTI_REDEPLOY)}
                        style={{color: 'white', padding: 10}}
                        size="large">
                        <CloudRefresh />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'desc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            FilterColumn.makeFilter('active', FilterTypes.BOOLEAN, i18next.t('instance.active')).setIsAlwaysActive(true, true),
            FilterColumn.makeFilter('include_removed', FilterTypes.BOOLEAN, i18next.t('instance.filter.removed')),
            FilterColumn.makeFilter('domain', FilterTypes.STRING, i18next.t('instance.domain'))
        ];
    }
    
}

const mapStateToProps = (state: any) => ({
    instances: state.instances,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listInstances: (params: InstanceRetrieveListModel) => dispatch(InstanceActions.CreateInstanceList(params)),
        triggerTasks: (params: InstanceTriggerJobParams) => dispatch(InstanceActions.CreateInstanceTriggerJob(params)),
        clearInstances: () => dispatch(InstanceActions.CreateClearInstanceList())
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(
                    InstanceList, TableStyles
                )
            )
        )
    )
);