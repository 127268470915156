import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Card, CardContent, Container, Grid } from "@mui/material";
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import TabManager, { TabView } from 'components/tabs/TabManager';
import NotificationCenter from 'services/NotificationCenter';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import ReloadDataButton from 'components/ReloadDataButton';
import DetailsViewHeader from 'components/DetailsViewHeader';
import ClientActions from 'reducers/types/Client';
import ClientState from 'reducers/interface/ClientState';
import { ClientCreateSuccessModel, ClientHistoryListRequest } from 'api';
import Client from 'api/override/ClientModel';
import ClientDetails from './components/ClientDetails';
import ClientCreateForm from 'views/CreationForms/ClientCreateForm';
import ClientDetailsDialog from 'views/Dialog/ClientDetailsDialog';
import ClientTrialsTab from './components/ClientTrialsTab';
import HideOnProd from 'components/HideOnProd';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    appReducer: AppState;
    clientReducer: ClientState;
}

interface DispatchProps {
    retrieveClient: (params: number) => void;
    listClientHistory: (params: ClientHistoryListRequest) => void;
    updateClient: (params: Client) => void;
    createClient: (params: Client) => void;
    deleteClient: (params: number) => void;
    clearClients: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    client?: Client;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class ClientView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            client: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_CLIENT_RETRIEVE_FAILURE,
            ActionTypes.API_CLIENT_CREATE_FAILURE,
            ActionTypes.API_CLIENT_UPDATE_FAILURE,
            ActionTypes.API_CLIENT_DELETE_FAILURE,
            ActionTypes.API_CLIENT_HISTORY_LIST_FAILURE,
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_CLIENT_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_CLIENT_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_CLIENT_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_CLIENT_DELETE_SUCCESS, 
            this.onDeleteSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({client: action.params}, () => {
            this.updateTabTitle(this.state.client?.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const clientSuccess: ClientCreateSuccessModel = action.params
        this.setState({client: new Client(clientSuccess.model)})
        this.props.navigate(`/clients/${clientSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('clients.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('clients.model')}), {variant: 'success'})
        this.props.clearClients(true)
        this.reloadData()
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('clients.model')}), {variant: 'success'})
        this.props.clearClients(true)
        this.props.navigate('/clients')
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveClient(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_CLIENT_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.client != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.props.retrieveClient(this.props.params.slug)
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, appReducer, clientReducer } = this.props;
        return (
            <HideOnProd>
                <React.Fragment>
                    {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                    <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_CLIENT_RETRIEVE)}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container maxWidth={"lg"}>
                        <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                            <Grid item xs={12} md={12} lg={9}>
                                <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                        <div>
                                        <DetailsViewHeader
                                            model={i18next.t('clients.model')}
                                            title={this.state.client?.name}
                                        />
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/clients/${this.state.client?.id}`,
                                                    view: (
                                                        <ClientDetails 
                                                            client={this.state.client} 
                                                            onDelete={this.onDelete}
                                                            onEdit={this.onEdit}
                                                        />
                                                    )
                                                },{
                                                    title: i18next.t('trials.model_plural'),
                                                    link: `/clients/${this.state.client?.id}/trials`,
                                                    initialTab: this.isPathIncluded('trials'),
                                                    view: (<ClientTrialsTab trials={this.state.client?.trials}/>
                                                    )
                                                },{
                                                    title:  i18next.t('history.model'),
                                                    link: `/clients/${this.state.client?.id}/history`,
                                                    initialTab: this.isPathIncluded('history'),
                                                    view: (
                                                        <HistoryTab
                                                            model={this.state.client}
                                                        />
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if (newTab.link.includes('history')){
                                                    if (clientReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_CLIENT_HISTORY_LIST)) {
                                                        this.sendNotification({type: ActionTypes.API_CLIENT_HISTORY_UPDATE}, null);
                                                    }
                                                }
                                            }}
                                        />
                                        </div>
                                    }
                                    {this.shouldRenderCreateForm() &&
                                        <CardContent>
                                            <ClientCreateForm
                                                onFormSubmit={(client: Client) => {
                                                    this.props.createClient(client);
                                                }}
                                            /> 
                                        </CardContent>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>

                    <ClientDetailsDialog
                        open={this.state.isEditModalOpen}
                        onModalClosePress={this.dialogClosed}
                        onModalSubmit={(client: Client) => {
                            this.props.updateClient(client)
                        }}
                    />

                    <ConfirmDeleteDialog
                        open={this.state.isDeleteModalOpen}
                        onModalClosePress={this.dialogClosed}
                        onConfirm={() => {
                            this.props.deleteClient(this.state.client?.id)
                        }}
                        title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('clients.model')).toLowerCase()})}
                    />
                </React.Fragment>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    clientReducer: state.clients
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveClient: (params: number) => dispatch(ClientActions.CreateClientRetrieve(params)),
        listClientHistory: (params: ClientHistoryListRequest) => dispatch(ClientActions.CreateClientHistoryList(params)),
        updateClient: (params: Client) => dispatch(ClientActions.CreateClientUpdate(params)),
        createClient: (params: Client) => dispatch(ClientActions.CreateClientCreate(params)),
        deleteClient: (params: number) => dispatch(ClientActions.CreateClientDelete(params)),
        clearClients: (params: boolean) => dispatch(ClientActions.CreateClearClientList(params))
    }
}


export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ClientView, InstanceViewStyles)
            )
        )
    )
);