import { put, takeLatest } from "@redux-saga/core/effects";
import { HistoryApi, HistoryListRequest } from "api";
import ActionTypes from "config/ActionTypes";
import { AppActions } from "reducers/types/App";
import HistoryActions, { HistoryList } from "reducers/types/History";
import { ApiHandler } from "./APIHandler";


export function* HistorySagas() {
    yield takeLatest(ActionTypes.API_HISTORY_LIST, HistorySaga.historyListWorkerSaga);
}

export class HistorySaga {
    static *historyListWorkerSaga(action: HistoryList) {
        try {
            const historyArgs = {
                historyRetrieveListModel: {
                    ...action.params?.historyRetrieveListModel,
                    sort_by: 'created_at'
                }
            } as HistoryListRequest

            const data = yield ApiHandler(new HistoryApi().historyList, historyArgs);
            yield put(HistoryActions.CreateHistoryListSuccess(data));
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}