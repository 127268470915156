/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelFromJSONTyped,
    ApiResponseModelToJSON,
    TransferVerifyDataResponseModel,
    TransferVerifyDataResponseModelFromJSON,
    TransferVerifyDataResponseModelFromJSONTyped,
    TransferVerifyDataResponseModelToJSON,
    TransferVerifyResponseModelAllOf,
    TransferVerifyResponseModelAllOfFromJSON,
    TransferVerifyResponseModelAllOfFromJSONTyped,
    TransferVerifyResponseModelAllOfToJSON,
} from './';


export interface TransferVerifyResponseModelInterface {
    message?: string;
    code?: number;
    data?: TransferVerifyDataResponseModel;
}

/**
 * 
 * @export
 * @interface TransferVerifyResponseModel
 */
export class TransferVerifyResponseModel extends BaseModel<TransferVerifyResponseModelInterface> {

    /**
     * Message
     * @type {string}
     * @memberof TransferVerifyResponseModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof TransferVerifyResponseModel
     */
    code?: number;
    /**
     * 
     * @type {TransferVerifyDataResponseModel}
     * @memberof TransferVerifyResponseModel
     */
    data?: TransferVerifyDataResponseModel;

    constructor(data?: TransferVerifyResponseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TransferVerifyResponseModel, value: StringDateInterface<TransferVerifyResponseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TransferVerifyResponseModelFromJSON(json: any): TransferVerifyResponseModel {
    return TransferVerifyResponseModelFromJSONTyped(json, false);
}

export function TransferVerifyResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferVerifyResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TransferVerifyResponseModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : TransferVerifyDataResponseModelFromJSON(json['data']),
    });
}

export function TransferVerifyResponseModelToJSON(value?: TransferVerifyResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TransferVerifyResponseModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': TransferVerifyDataResponseModelToJSON(value.data),
    } as StringDateInterface<TransferVerifyResponseModel>;
    return createClass.toJson(value, model);
}


