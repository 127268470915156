import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {Button, Card, CardContent, CardHeader, Container } from "@mui/material";
import BasePageComponent from 'components/base/BasePageComponent';
import { ViewProps, componentConnect } from 'components/base/BasePageComponent';
import { withStyles } from 'tss-react/mui';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import StorageIcon from '@mui/icons-material/Storage';
import i18next from 'i18next';
import InstanceController from 'controllers/InstanceController';
import ConfirmDialog from 'components/ConfirmDialog';

interface InstanceManageViewState {
    isRedeployFrontendOpen: boolean;
    isRedeployBackendOpen: boolean;
}

class InstanceManageView extends BasePageComponent<ViewProps, InstanceManageViewState> {

    constructor(props) {
        super(props);
        this.state = {
            isRedeployFrontendOpen: false,
            isRedeployBackendOpen: false
        };
    }

    dialogClosed = () => {
        this.setState({
            isRedeployFrontendOpen: false,
            isRedeployBackendOpen: false
        })
    }

    redeployFrontends = async() => {
        await new InstanceController().redoployAllFrontends();
        this.dialogClosed();
    }

    redeployBackends = async() => {
        await new InstanceController().redeployAllBackends();
        this.dialogClosed();
    }

    renderView() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <CardHeader
                            avatar={ <StorageIcon /> }
                            title={ i18next.t('mainnav.instance-manage') }
                        />
                        <CardContent>
                            <Button onClick={() => this.setState({isRedeployFrontendOpen: true})}>{i18next.t('instance.view.redeploy-frontends')}</Button>
                            <Button onClick={() => this.setState({isRedeployBackendOpen: true})}>{i18next.t('instance.view.redeploy-backends')}</Button>
                        </CardContent>
                    </Card>
                    <ConfirmDialog
                        open={this.state.isRedeployFrontendOpen}
                        onModalClosePress={this.dialogClosed}
                        onConfirm={this.redeployFrontends}
                        title={i18next.t('instance.view.redeploy-confirm')} // Are you sure you want to redeploy all instances
                    />
                    <ConfirmDialog
                        open={this.state.isRedeployBackendOpen}
                        onModalClosePress={this.dialogClosed}
                        onConfirm={this.redeployBackends}
                        title={i18next.t('instance.view.redeploy-confirm')} // Are you sure you want to redeploy all instances
                    />
                </Container>
            </React.Fragment>
        );
    }
}

export default componentConnect(
    () => {},
    () => ({}),
    withTranslation()(
        withRouter(
            withStyles(InstanceManageView, InstanceViewStyles)
        )
    )
);