import i18next from "i18next";
import * as Yup from "yup";

export const retrieveMailSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    subject: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    event: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
});