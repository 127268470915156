import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import { ConnectorListSuccess } from "./types/Connector";
import ConnectorContainerState from "./interface/ConnectorContainerState";
import { ErrorInterface } from "./types/App";
import { ConnectorContainerGetConfigSuccess } from "./types/ConnectorContainer";
import ConfigData from "api/override/ConfigDataModel";
import ConnectorContainer from "api/override/ConnectorContainerModel";

const initialState: ConnectorContainerState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    configData: null,
    error: null,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    }
};

export function ConnectorContainerReducers(state = initialState, action: BaseReducerAction): ConnectorContainerState {
    switch (action.type) {
        case ActionTypes.API_CONNECTOR_CONTAINER_UPDATE:
        case ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE:
        case ActionTypes.API_CONNECTOR_CONTAINER_LIST:
        case ActionTypes.API_CONNECTOR_CONTAINER_CREATE:
        case ActionTypes.API_CONNECTOR_CONTAINER_DELETE:
        case ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_SUCCESS:
        case ActionTypes.API_CONNECTOR_CONTAINER_CREATE_SUCCESS:
        case ActionTypes.API_CONNECTOR_CONTAINER_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_FAILURE:
        case ActionTypes.API_CONNECTOR_CONTAINER_CREATE_FAILURE:
        case ActionTypes.API_CONNECTOR_CONTAINER_DELETE_FAILURE:
        case ActionTypes.API_CONNECTOR_CONTAINER_LIST_FAILURE:
        case ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_FAILURE:
            return {
                ...state,
                error: (action as ErrorInterface).data?.errors
            }

        case ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as ConnectorContainer,
                error: null
            };

        case ActionTypes.API_CONNECTOR_CONTAINER_LIST_SUCCESS:
            const result = action as ConnectorListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<ConnectorContainer>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                }
            };

        case ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };
        

        case ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG:
            return {
                ...state,
            }

        case ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS:
            return {
                ...state,
                configData: (action as ConnectorContainerGetConfigSuccess).params?.data as ConfigData
            }

        case ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE:
            return {
                ...state,
                configData: null,
                error: (action as ErrorInterface).data?.errors
            }

        default:
            return state;
    }
}