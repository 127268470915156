import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import {
    Container,
    Card,
    CardContent,
    Grid,
    Tooltip
} from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { TableParamsSorting } from '../../models/table/TableParams';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import MailLogActions from 'reducers/types/MailLog';
import MailLogState from 'reducers/interface/MailLogState';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import MailLog from 'api/override/MailLogModel';
import { RootState } from 'store/AppStore';
import { MailLogRetrieveListModel } from 'api/models/MailLogRetrieveListModel';
import { Alert, EmailOpen, EmailOpenMultipleOutline, SendCheck, SendClock } from 'mdi-material-ui';
import { MailLogModelStatusEnum } from 'api/models/MailLogModel';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    mailLogReducer: MailLogState;
    appReducer: AppState;
}

interface DispatchProps {
    listMailLogs: (params: MailLogRetrieveListModel) => void;
    clearMailLogs: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    mailLog?: MailLog;
}

class MailLogList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    filterColumns: FilterColumn[] = [];

    constructor(props) {
        super(props);
        this.state = {
            mailLog: undefined
        };
    }

    configFilters = () => {
        let statusFilters: Record<string, string> = {};
        for (const values of Object.values(MailLogModelStatusEnum)) {
            statusFilters[ values ] = values
        }

        this.filterColumns=[
            FilterColumn.makeFilter('status', FilterTypes.ENUM, i18next.t('general.status'), statusFilters),
        ]
    }

    /*
        i18next.t(`mail_logs.status.sent`)
        i18next.t(`mail_logs.status.not_sent`)
        i18next.t(`mail_logs.status.error`)
        i18next.t(`mail_logs.status.read`)
    */
    getStatusRow = (mailLog: MailLog): JSX.Element => {
        const { classes } = this.props
        let icon: JSX.Element;
        switch (mailLog.status) {
            case MailLogModelStatusEnum.Sent:
                    icon = <SendCheck className={classes.mail_log_status_icon + ' sent'}/>
                    break;
            case MailLogModelStatusEnum.NotSent:
                    icon = <SendClock className={classes.mail_log_status_icon + ' not_sent'}/>
                    break;
            case MailLogModelStatusEnum.Error:
                    icon = <Alert className={classes.mail_log_status_icon + ' error'}/>
                    break;
            case MailLogModelStatusEnum.Read:
                    icon = <EmailOpen className={classes.mail_log_status_icon + ' read'}/>
                    break;
            default:
                throw new Error('Maillog status not recognised')
        }
        return <Tooltip title={i18next.t(`mail_logs.status.${mailLog.status}`)}>
            {icon}
        </Tooltip>
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'sender', isSortable: true, label: i18next.t('mail_logs.sender')}),
            new TableColumn({ key: 'client', isSortable: true, label: i18next.t('mail_logs.client_name'), customValue: (row: MailLog) => row.client.name}),
            new TableColumn({ key: 'mail', isSortable: true, label: i18next.t('mails.model'), customValue: (row: MailLog) => row.mail.name}),
            new TableColumn({ key: 'status', isSortable: true, label: i18next.t('general.status'), customValue: (row: MailLog) => this.getStatusRow(row), align: 'center'}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_MAIL_LOG_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('mail_logs.model_plural'));
        this.configFilters();
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
    }

    onDetails = (row: MailLog) => {
        this.props.navigate(`/mail-logs/${row.id}`)
    }

    getData = () => { this.reloadData() }

    getItemCount = (): number => {
        return this.props.mailLogReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listMailLogs( this.getParams().getApiFilter() )
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('mail_logs', this.props.location)){
            this.props.clearMailLogs();
        } 
    }

    renderView() {
        const { classes, appReducer, mailLogReducer } = this.props;

        if (this.getParams() == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('mail_logs.model')}
                            headerIcon={<EmailOpenMultipleOutline/>}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('mail_logs.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('mail_logs.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={mailLogReducer.collection.count_filtered}
                                    listItems={mailLogReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_MAIL_LOG_LIST)}
                                    tableParams={ this.getParams() }
                                    delegate={ this } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns;
    }

}

const  mapStateToProps = (state: RootState) => ({
    mailLogReducer: state.mail_logs,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch) => {
    return {
        listMailLogs: (params: MailLogRetrieveListModel) => dispatch(MailLogActions.CreateMailLogList(params)),
        clearMailLogs: () => dispatch(MailLogActions.CreateClearMailLogList()),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(MailLogList, TableStyles)
            )
        )
    )
);