import React from 'react'
import { styled } from '@mui/material/styles';
import {Card, CardContent, CardHeader, Chip, LinearProgress } from '@mui/material'
import MemoryIcon from '@mui/icons-material/Memory'
import StorageIcon from '@mui/icons-material/Storage'
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import BaseComponent, { BaseState } from 'components/base/BaseComponent';
import {  ViewProps } from 'components/base/BasePageComponent';
import { withTranslation } from 'react-i18next';
import { ComponentStatusModel, RancherStatusModel } from "api";
import RancherStyles from 'styles/RancherStyles';
import i18next from 'i18next';
import { withStyles } from 'tss-react/mui';


const PREFIX = 'RancherGeneralInfo';

const classes = {
    root: `${PREFIX}-root`,
    colorPrimary: `${PREFIX}-colorPrimary`,
    bar: `${PREFIX}-bar`,
    root2: `${PREFIX}-root2`,
    colorPrimary2: `${PREFIX}-colorPrimary2`,
    bar2: `${PREFIX}-bar2`,
    root3: `${PREFIX}-root3`,
    colorPrimary3: `${PREFIX}-colorPrimary3`,
    bar3: `${PREFIX}-bar3`
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        height: 25,
        borderRadius: 10,
        width: '80%'
    },

    [`& .${classes.colorPrimary}`]: {
        backgroundColor: "#313131",
    },

    [`& .${classes.bar}`]: {
        borderRadius: 10,
        backgroundColor: '#C5A0FF',
    },

    [`& .${classes.root2}`]: {
        height: 25,
        borderRadius: 10,
        width: '80%'
    },

    [`& .${classes.colorPrimary2}`]: {
        backgroundColor: "#313131",
    },

    [`& .${classes.bar2}`]: {
        borderRadius: 10,
        backgroundColor: '#FF7968',
    },

    [`& .${classes.root3}`]: {
        height: 25,
        borderRadius: 10,
        width: '80%'
    },

    [`& .${classes.colorPrimary3}`]: {
        backgroundColor: "#313131",
    },

    [`& .${classes.bar3}`]: {
        borderRadius: 10,
        backgroundColor: '#F6B91E',
    }
}));


const CpuBar = LinearProgress;

const MemoryBar = LinearProgress;

const PodsBar = LinearProgress;


interface RancherGeneralInfoProps {
    data: RancherStatusModel
}

type Props = RancherGeneralInfoProps & ViewProps

interface State extends BaseState {
    chipColor: string,
    usedCPUPerc: number,
    usedMemPerc: number,
    usedPodsPerc: number,
}

class RancherGeneralInfo extends BaseComponent<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            chipColor: 'grey',
            usedCPUPerc: 0,
            usedMemPerc: 0,
            usedPodsPerc: 0,
        }
    }

    calculateInPercentage() {
        const { allocatable, requested } = this.props.data
        if (requested && allocatable){
            // let cpu = Math.round(requested.cpu * 100 / allocatable.cpu)
            // let memory = Math.round(requested.memory * 100 / allocatable.memory)
            // let pods = Math.round(requested.pods * 100 / allocatable.pods)
            // this.setState({ usedCPUPerc: cpu, usedMemPerc: memory, usedPodsPerc: pods })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.calculateInPercentage()
    }

    renderView() {
        const { classes } = this.props;
        const { allocatable, requested } = this.props.data
        return (
            <StyledCard variant="outlined" className={classes.root}>
                <CardHeader className={classes.center_text} title={i18next.t('rancher.title.statistics')} />
                <CardContent className={classes.card_content}>

                    <div className={classes.bar_content}>
                        <div className={classes.label_container}>
                            <MemoryIcon />
                            <p className={classes.bar_label}>{i18next.t('rancher.cpu')} ({requested?.cpu}/{allocatable?.cpu})</p>
                        </div>
                        <div className={classes.bar_containerA}>
                            <p className={classes.in_bar_percentage}>{this.state.usedCPUPerc}%</p>
                            <CpuBar
                                className={classes.center}
                                variant='determinate'
                                value={this.state.usedCPUPerc}
                                classes={{
                                    root: classes.root,
                                    colorPrimary: classes.colorPrimary,
                                    bar: classes.bar
                                }} />
                        </div>
                    </div>
                    <div className={classes.bar_content}>
                        <div className={classes.label_container}>
                            <StorageIcon />
                            <p className={classes.bar_label}>{i18next.t('rancher.memory')} ({requested?.memory}/{allocatable?.memory})</p>
                        </div>
                        <div className={classes.bar_containerA}>
                            <p className={classes.in_bar_percentage}>{this.state.usedMemPerc}%</p>
                            <MemoryBar
                                className={classes.center}
                                variant='determinate'
                                value={this.state.usedMemPerc}
                                classes={{
                                    root: classes.root2,
                                    colorPrimary: classes.colorPrimary2,
                                    bar: classes.bar2
                                }} />
                        </div>
                    </div>
                    <div className={classes.bar_content}>
                        <div className={classes.label_container}>
                            <GroupWorkIcon />
                            <p className={classes.bar_label}>{i18next.t('rancher.pods')} ({requested?.pods}/{allocatable?.pods})</p>
                        </div>
                        <div className={classes.bar_containerA}>
                            <p className={classes.in_bar_percentage}>{this.state.usedPodsPerc}%</p>
                            <PodsBar
                                className={classes.center}
                                variant='determinate'
                                value={this.state.usedPodsPerc}
                                classes={{
                                    root: classes.root3,
                                    colorPrimary: classes.colorPrimary3,
                                    bar: classes.bar3
                                }} />
                        </div>
                    </div>

                    <CardHeader className={classes.comp_status_header} title={i18next.t('rancher.title.component_statuses')} />
                    <div className={classes.chips_container}>
                        {this.props.data.component_statuses?.map((comp: ComponentStatusModel) => {
                            if(comp.status === "Healthy"){
                                return (
                                    <Chip
                                        avatar={<Avatar style={{backgroundColor: "green", color: "white"}}><DoneIcon/></Avatar>}
                                        label={comp.name}
                                        style={{backgroundColor: "#4aab3f", color: "white"}}
                                        className={classes.chip}
                                        key={'chip-'+ comp.name}
                                    />
                                )
                            } else {
                                return (
                                    <Chip
                                        avatar={<Avatar style={{backgroundColor: "#f21c05", color: "white"}}><CloseIcon/></Avatar>}
                                        label={comp.name}
                                        style={{backgroundColor: "#f55442", color: "white"}}
                                        key={'chip-'+ comp.name}
                                    />
                                )
                            }
                        })}
                    </div>
                </CardContent>
            </StyledCard>
        );
    }
}


export default withTranslation()(withStyles(RancherGeneralInfo, RancherStyles));