import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useFileUploadStyles = makeStyles()((theme: Theme) => {
    return {
        container: {
            display: 'flex', 
            alignItems: 'center',
            margin: '10px auto',
            cursor: 'pointer',
            background: '#0d82cb',
            padding: '5px 10px',
            borderRadius: 7,
            justifyContent: 'center',
            width: '95%'
        },
        button: {
            background: '#0d82cb', 
            color: 'white', 
            padding: 7,
        },
        title: {
            margin: '0 0.3em', 
            fontSize: '1.25em',
            color: '#fff'
        }
    }
})

export default useFileUploadStyles