import React from 'react'
import ReactJson, {ReactJsonViewProps} from '@microlink/react-json-view';
import useJSONViewerStyles from 'styles/JSONViewerStyles';
import LocalStorageManager from 'utils/LocalStorageManager';

type ReactJsonViewPropsFormatted = Omit<ReactJsonViewProps, 'src'>

export interface JSONViewerProps extends ReactJsonViewPropsFormatted {
    jsonObject: object;
}

const JSONViewer = (props: JSONViewerProps) => {

    const {jsonObject} = props
    const {classes} = useJSONViewerStyles()

    const defaultProps: ReactJsonViewPropsFormatted = {
        enableClipboard: false,
        displayDataTypes: false,
        displayObjectSize: false,
    }

    const getTheme = () => {
        return LocalStorageManager.getThemeMode() === 'dark' ? 'ocean' : 'rjv-default'
    }

    return (
        <div className={classes.root}>
            <ReactJson src={jsonObject} {...defaultProps} {...props} theme={getTheme()} iconStyle='square'/>
        </div>
    )
}

export default JSONViewer;