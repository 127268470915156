/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageModel,
    ImageModelFromJSON,
    ImageModelFromJSONTyped,
    ImageModelToJSON,
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelFromJSONTyped,
    ImageVersionModelToJSON,
    ServiceConfigModel,
    ServiceConfigModelFromJSON,
    ServiceConfigModelFromJSONTyped,
    ServiceConfigModelToJSON,
} from './';


export interface ServiceModelInterface {
    id?: number;
    uuid?: string;
    status?: string;
    image_id?: number;
    image?: ImageModel;
    image_version_id?: number | null;
    image_version?: ImageVersionModel;
    name?: string;
    url?: string;
    text?: string | null;
    volumes?: string;
    databases?: string;
    has_port?: boolean;
    standalone?: boolean;
    config?: ServiceConfigModel;
    launch_config?: ServiceConfigModel;
    config_correct?: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

/**
 * 
 * @export
 * @interface ServiceModel
 */
export class ServiceModel extends BaseModel<ServiceModelInterface> {

    /**
     * id of the service
     * @type {number}
     * @memberof ServiceModel
     */
    id?: number;
    /**
     * Unique per service
     * @type {string}
     * @memberof ServiceModel
     */
    uuid?: string;
    /**
     * Last known status of the container
     * @type {string}
     * @memberof ServiceModel
     */
    status?: string;
    /**
     * id of the image
     * @type {number}
     * @memberof ServiceModel
     */
    image_id?: number;
    /**
     * 
     * @type {ImageModel}
     * @memberof ServiceModel
     */
    image?: ImageModel;
    /**
     * id of the image version
     * @type {number}
     * @memberof ServiceModel
     */
    image_version_id?: number | null;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof ServiceModel
     */
    image_version?: ImageVersionModel;
    /**
     * Name of the service
     * @type {string}
     * @memberof ServiceModel
     */
    name?: string;
    /**
     * Url after the domain that the service should run on
     * @type {string}
     * @memberof ServiceModel
     */
    url?: string;
    /**
     * WYSWYG text about the connector
     * @type {string}
     * @memberof ServiceModel
     */
    text?: string | null;
    /**
     * Volumes that are connected to the service
     * @type {string}
     * @memberof ServiceModel
     */
    volumes?: string;
    /**
     * Databases that are connected to the service
     * @type {string}
     * @memberof ServiceModel
     */
    databases?: string;
    /**
     * Service should be accessible from outside
     * @type {boolean}
     * @memberof ServiceModel
     */
    has_port?: boolean;
    /**
     * Boolean indicating whether this service should be launched on its own
     * @type {boolean}
     * @memberof ServiceModel
     */
    standalone?: boolean;
    /**
     * 
     * @type {ServiceConfigModel}
     * @memberof ServiceModel
     */
    config?: ServiceConfigModel;
    /**
     * 
     * @type {ServiceConfigModel}
     * @memberof ServiceModel
     */
    launch_config?: ServiceConfigModel;
    /**
     * A bool indicating whether the config is up to date with the config
     * @type {boolean}
     * @memberof ServiceModel
     */
    config_correct?: boolean;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceModel
     */
    deleted_at?: Date;

    constructor(data?: ServiceModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceModel, value: StringDateInterface<ServiceModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceModelFromJSON(json: any): ServiceModel {
    return ServiceModelFromJSONTyped(json, false);
}

export function ServiceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceModel;
    try {
        let localClass = require.context('../override', true)('./ServiceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'image_id': !exists(json, 'image_id') ? undefined : Number(json['image_id']),
        'image': !exists(json, 'image') ? undefined : ImageModelFromJSON(json['image']),
        'image_version_id': !exists(json, 'image_version_id') ? undefined : Number(json['image_version_id']),
        'image_version': !exists(json, 'image_version') ? undefined : ImageVersionModelFromJSON(json['image_version']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'volumes': !exists(json, 'volumes') ? undefined : json['volumes'],
        'databases': !exists(json, 'databases') ? undefined : json['databases'],
        'has_port': !exists(json, 'has_port') ? undefined : json['has_port'],
        'standalone': !exists(json, 'standalone') ? undefined : json['standalone'],
        'config': !exists(json, 'config') ? undefined : ServiceConfigModelFromJSON(json['config']),
        'launch_config': !exists(json, 'launch_config') ? undefined : ServiceConfigModelFromJSON(json['launch_config']),
        'config_correct': !exists(json, 'config_correct') ? undefined : json['config_correct'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function ServiceModelToJSON(value?: ServiceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceModel;
    try {
        let localClass = require.context('../override', true)('./ServiceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'uuid': value.uuid,
        'status': value.status,
        'image_id': value.image_id,
        'image': ImageModelToJSON(value.image),
        'image_version_id': value.image_version_id,
        'image_version': ImageVersionModelToJSON(value.image_version),
        'name': value.name,
        'url': value.url,
        'text': value.text,
        'volumes': value.volumes,
        'databases': value.databases,
        'has_port': value.has_port,
        'standalone': value.standalone,
        'config': ServiceConfigModelToJSON(value.config),
        'launch_config': ServiceConfigModelToJSON(value.launch_config),
        'config_correct': value.config_correct,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
    } as StringDateInterface<ServiceModel>;
    return createClass.toJson(value, model);
}


