/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConditionFilterModel,
    ConditionFilterModelFromJSON,
    ConditionFilterModelFromJSONTyped,
    ConditionFilterModelToJSON,
} from './';


export interface ConditionRetrieveListModelInterface {
    filters?: ConditionFilterModel;
    search_text?: string | null;
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
}

/**
 * 
 * @export
 * @interface ConditionRetrieveListModel
 */
export class ConditionRetrieveListModel extends BaseModel<ConditionRetrieveListModelInterface> {

    /**
     * 
     * @type {ConditionFilterModel}
     * @memberof ConditionRetrieveListModel
     */
    filters?: ConditionFilterModel;
    /**
     * Search String
     * @type {string}
     * @memberof ConditionRetrieveListModel
     */
    search_text?: string | null;
    /**
     * Offset
     * @type {number}
     * @memberof ConditionRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof ConditionRetrieveListModel
     */
    limit?: number;
    /**
     * Sort by Column
     * @type {string}
     * @memberof ConditionRetrieveListModel
     */
    sort_by?: string;
    /**
     * Sort direction
     * @type {string}
     * @memberof ConditionRetrieveListModel
     */
    sort_direction?: string;

    constructor(data?: ConditionRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConditionRetrieveListModel, value: StringDateInterface<ConditionRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConditionRetrieveListModelFromJSON(json: any): ConditionRetrieveListModel {
    return ConditionRetrieveListModelFromJSONTyped(json, false);
}

export function ConditionRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConditionRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ConditionRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'filters': !exists(json, 'filters') ? undefined : ConditionFilterModelFromJSON(json['filters']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'sort_by': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sort_direction': !exists(json, 'sort_direction') ? undefined : json['sort_direction'],
    });
}

export function ConditionRetrieveListModelToJSON(value?: ConditionRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConditionRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ConditionRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'filters': ConditionFilterModelToJSON(value.filters),
        'search_text': value.search_text,
        'offset': value.offset,
        'limit': value.limit,
        'sort_by': value.sort_by,
        'sort_direction': value.sort_direction,
    } as StringDateInterface<ConditionRetrieveListModel>;
    return createClass.toJson(value, model);
}


