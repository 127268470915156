import BaseComponent from "components/base/BaseComponent";
import ActionTypes from "config/ActionTypes";
import { PureComponent } from "react";
import BaseReducerAction, { BaseAction } from "reducers/interface/ReducerAction";
import { RootState } from "store/AppStore";

export type StateCallback = (action: BaseAction, ...params: any[]) => void;
export type Listenable = BaseComponent | PureComponent | NCListener;

export class NCListener {
    handleEvent: StateCallback;
    event: ActionTypes;
    constructor(event: ActionTypes, handleEvent: StateCallback) {
        this.event = event;
        this.handleEvent = handleEvent
    }
}
export interface NotificationListener {
    component: Listenable;
    event: ActionTypes;
    callback: StateCallback;
}

class NotificationCenter {

    static default: NotificationCenter = new NotificationCenter();

    private _listeners: NotificationListener[];

    private constructor() {
        this._listeners = [];
    }
    
    addListener(component: Listenable, event: ActionTypes, callback: StateCallback) {
        if (!this._listeners.some(listener => listener.component === component && listener.event === event)) {
            this._listeners.push({ component, event, callback });
        }
    }

    addListeners(component: BaseComponent, events: ActionTypes[], callback: StateCallback) {
        events.forEach(e => this.addListener(component, e, callback))
    }

    addNCListener(ncListener: NCListener) {
        this.addListener(ncListener, ncListener.event, ncListener.handleEvent);
    }

    addNCListeners(ncListeners: NCListener[]) {
        ncListeners.forEach(ncListener => this.addNCListener(ncListener))
    }

    removeListener(component: Listenable) {
        this._listeners = this._listeners.filter(item => item.component !== component);
    }

    removeListeners(components: Listenable[]) {
        components.forEach(component => this.removeListener(component))
    }

    sendStateUpdate(oldState: RootState, newState: RootState, action: BaseReducerAction) {
        this._listeners.forEach((el: NotificationListener) => {
            if (el.event === action.type) {
                el.callback(action, newState, oldState);
            }
        });
    }

    sendNotification(action: BaseAction, params: any = {}): boolean {
        let didSend = false;
        this._listeners.forEach((el: NotificationListener) => {
            if (el.event === action.type) {
                didSend = true;
                el.callback(action, params);
            }
        });
        return didSend;
    }

}

export default NotificationCenter;