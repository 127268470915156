/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    NodeCapacityModel,
    NodeCapacityModelFromJSON,
    NodeCapacityModelFromJSONTyped,
    NodeCapacityModelToJSON,
    NodeInfoModel,
    NodeInfoModelFromJSON,
    NodeInfoModelFromJSONTyped,
    NodeInfoModelToJSON,
} from './';


export interface NodeModelInterface {
    name?: string;
    state?: string;
    allocatable?: NodeCapacityModel;
    requested?: NodeCapacityModel;
    info?: NodeInfoModel;
}

/**
 * 
 * @export
 * @interface NodeModel
 */
export class NodeModel extends BaseModel<NodeModelInterface> {

    /**
     * name of this node
     * @type {string}
     * @memberof NodeModel
     */
    name?: string;
    /**
     * state
     * @type {string}
     * @memberof NodeModel
     */
    state?: string;
    /**
     * 
     * @type {NodeCapacityModel}
     * @memberof NodeModel
     */
    allocatable?: NodeCapacityModel;
    /**
     * 
     * @type {NodeCapacityModel}
     * @memberof NodeModel
     */
    requested?: NodeCapacityModel;
    /**
     * 
     * @type {NodeInfoModel}
     * @memberof NodeModel
     */
    info?: NodeInfoModel;

    constructor(data?: NodeModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: NodeModel, value: StringDateInterface<NodeModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function NodeModelFromJSON(json: any): NodeModel {
    return NodeModelFromJSONTyped(json, false);
}

export function NodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NodeModel;
    try {
        let localClass = require.context('../override', true)('./NodeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'allocatable': !exists(json, 'allocatable') ? undefined : NodeCapacityModelFromJSON(json['allocatable']),
        'requested': !exists(json, 'requested') ? undefined : NodeCapacityModelFromJSON(json['requested']),
        'info': !exists(json, 'info') ? undefined : NodeInfoModelFromJSON(json['info']),
    });
}

export function NodeModelToJSON(value?: NodeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NodeModel;
    try {
        let localClass = require.context('../override', true)('./NodeModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'state': value.state,
        'allocatable': NodeCapacityModelToJSON(value.allocatable),
        'requested': NodeCapacityModelToJSON(value.requested),
        'info': NodeInfoModelToJSON(value.info),
    } as StringDateInterface<NodeModel>;
    return createClass.toJson(value, model);
}


