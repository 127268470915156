/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    InstanceSettingModel,
    InstanceSettingModelFromJSON,
    InstanceSettingModelFromJSONTyped,
    InstanceSettingModelToJSON,
} from './';


export interface InstanceSettingsPostModelInterface {
    items?: Array<InstanceSettingModel>;
}

/**
 * 
 * @export
 * @interface InstanceSettingsPostModel
 */
export class InstanceSettingsPostModel extends BaseModel<InstanceSettingsPostModelInterface> {

    /**
     * 
     * @type {Array<InstanceSettingModel>}
     * @memberof InstanceSettingsPostModel
     */
    items?: Array<InstanceSettingModel>;

    constructor(data?: InstanceSettingsPostModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSettingsPostModel, value: StringDateInterface<InstanceSettingsPostModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSettingsPostModelFromJSON(json: any): InstanceSettingsPostModel {
    return InstanceSettingsPostModelFromJSONTyped(json, false);
}

export function InstanceSettingsPostModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSettingsPostModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSettingsPostModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsPostModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(InstanceSettingModelFromJSON)),
    });
}

export function InstanceSettingsPostModelToJSON(value?: InstanceSettingsPostModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSettingsPostModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsPostModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(InstanceSettingModelToJSON)),
    } as StringDateInterface<InstanceSettingsPostModel>;
    return createClass.toJson(value, model);
}


