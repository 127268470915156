/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface RefreshTokenResultModelInterface {
    token?: string;
}

/**
 * 
 * @export
 * @interface RefreshTokenResultModel
 */
export class RefreshTokenResultModel extends BaseModel<RefreshTokenResultModelInterface> {

    /**
     * JWT Token to be used for further API calls
     * @type {string}
     * @memberof RefreshTokenResultModel
     */
    token?: string;

    constructor(data?: RefreshTokenResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: RefreshTokenResultModel, value: StringDateInterface<RefreshTokenResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function RefreshTokenResultModelFromJSON(json: any): RefreshTokenResultModel {
    return RefreshTokenResultModelFromJSONTyped(json, false);
}

export function RefreshTokenResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RefreshTokenResultModel;
    try {
        let localClass = require.context('../override', true)('./RefreshTokenResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'token': !exists(json, 'token') ? undefined : json['token'],
    });
}

export function RefreshTokenResultModelToJSON(value?: RefreshTokenResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RefreshTokenResultModel;
    try {
        let localClass = require.context('../override', true)('./RefreshTokenResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'token': value.token,
    } as StringDateInterface<RefreshTokenResultModel>;
    return createClass.toJson(value, model);
}


