import NCNotification from "./NCNotification";
import NodeNotification from "./NodeNotification";

export default class NCNotificationModel implements NCNotification {
    
    type: string
    params: NodeNotification
    notification: NCNotification
    redirUrlBase: string

    protected constructor(notif: NCNotification) {
        this.notification = notif
        this.setUrlBase(notif.type)
    }

    static makeNotification(notif: NCNotification): NCNotificationModel {
        if (notif.type.includes('instance')) {
            return Object.assign(new InstanceNotification(notif), notif)
        } else if(notif.type.includes('connector-container')) {
            return Object.assign(new ConnectorContainerNotification(notif), notif)
        } else if(notif.type.includes('service-container')) {
            return Object.assign(new ServiceContainerNotification(notif), notif)
        } else if(notif.type.includes('connector')) {
            return Object.assign(new ConnectorNotification(notif), notif)
        } else if(notif.type.includes('service')) {
            return Object.assign(new ServiceNotification(notif), notif)
        }
 
        return Object.assign(new NCNotificationModel(notif), notif)
    }

    setUrlBase(base: string) {
        if (this.notification.type.includes('instance')) {
            this.redirUrlBase = '/instances/'
        }
    }

    getKey(): string {
        return this.notification.type + this.params.data.process_token
    }

    getRedirect(): string {
        return `/`
    }
}

export class InstanceNotification extends NCNotificationModel {

    getKey(): string {
        return super.getKey() + this.notification.params.data.meta.instance_uuid + this.notification.type
    }

    getRedirect(): string {
        return '/instances/' + this.notification.params.data.meta.instance_uuid
    }

}

export class ConnectorContainerNotification extends NCNotificationModel {

    getKey(): string {
        return super.getKey() + this.notification.params.data.meta.connector_container + this.notification.type
    }

    getRedirect(): string {
        return '/instances/' + this.notification.params.data.meta.instance_uuid + '/connectors'
    }

}

export class ConnectorNotification extends NCNotificationModel {

    getKey(): string {
        return super.getKey() + this.notification.params.data.meta.connector + this.notification.type
    }

    getRedirect(): string {
        return '/connectors/' + this.notification.params.data.meta.connector // TODO: add connector_id here
    }

}

export class ServiceNotification extends NCNotificationModel {

    getKey(): string {
        return super.getKey() + this.notification.params.data.meta.service + this.notification.type
    }

    getRedirect(): string {
        return '/services/' + this.notification.params.data.meta.service
    }

}

export class ServiceContainerNotification extends NCNotificationModel {

    getKey(): string {
        return super.getKey() + this.notification.params.data.meta.service_container + this.notification.type
    }

    getRedirect(): string {
        return '/instances/' + this.notification.params.data.meta.instance_uuid + '/services'
    }

}