/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    TrialCreateSuccessModel,
    TrialCreateSuccessModelFromJSON,
    TrialCreateSuccessModelToJSON,
    TrialListResultModel,
    TrialListResultModelFromJSON,
    TrialListResultModelToJSON,
    TrialRetrieveListModel,
    TrialRetrieveListModelFromJSON,
    TrialRetrieveListModelToJSON,
} from '../models';

export interface TrialCancelRequest {
    id: number;
}

export interface TrialHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface TrialListRequest {
    trialRetrieveListModel: TrialRetrieveListModel;
}

export interface TrialUpdateRequest {
    id: number;
}

/**
 * 
 */
export class TrialApi extends runtime.BaseAPI {

    /**
     * Cancel a trial
     * Cancel a trial
     */
    async trialCancelRaw(requestParameters: TrialCancelRequest): Promise<runtime.ApiResponse<TrialCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trialCancel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/trial/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrialCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Cancel a trial
     * Cancel a trial
     */
    trialCancel = async(requestParameters: TrialCancelRequest): Promise<TrialCreateSuccessModel> => {
        const response = await this.trialCancelRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a trial
     * retrieve a list of historical logs for a trial
     */
    async trialHistoryListRaw(requestParameters: TrialHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trialHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling trialHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/trial/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a trial
     * retrieve a list of historical logs for a trial
     */
    trialHistoryList = async(requestParameters: TrialHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.trialHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all trials
     * List all trials
     */
    async trialListRaw(requestParameters: TrialListRequest): Promise<runtime.ApiResponse<TrialListResultModel>> {
        if (requestParameters.trialRetrieveListModel === null || requestParameters.trialRetrieveListModel === undefined) {
            throw new runtime.RequiredError('trialRetrieveListModel','Required parameter requestParameters.trialRetrieveListModel was null or undefined when calling trialList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/trial/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrialRetrieveListModelToJSON(requestParameters.trialRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrialListResultModelFromJSON(jsonValue));
    }

    /**
     * List all trials
     * List all trials
     */
    trialList = async(requestParameters: TrialListRequest): Promise<TrialListResultModel> => {
        const response = await this.trialListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Extend a trial
     * Extend a trial
     */
    async trialUpdateRaw(requestParameters: TrialUpdateRequest): Promise<runtime.ApiResponse<TrialCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling trialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/trial/{id}/extend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrialCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Extend a trial
     * Extend a trial
     */
    trialUpdate = async(requestParameters: TrialUpdateRequest): Promise<TrialCreateSuccessModel> => {
        const response = await this.trialUpdateRaw(requestParameters);
        return await response.value();
    }

}
