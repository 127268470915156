import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import ActionTypes from "config/ActionTypes";
import TableList from 'components/TableList';
import { RootState } from 'store/AppStore';
import { useDispatch, useSelector } from 'react-redux';
import RequestLog from 'api/override/RequestLogModel';
import Instance from 'api/override/InstanceModel';
import Trial from 'api/override/TrialModel';
import { dateTimeFormat } from 'config/DateTimeFormatter';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';
import TableColumn from 'models/table/TableColumn';
import i18next from 'i18next';
import useTableDelegateHook from 'hooks/TableDelegateHook'
import TrialActions from 'reducers/types/Trial';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import { TrialFilterModel, TrialRetrieveListModel } from 'api';

interface InstanceTrialsTabProps {
    instance: Instance;
}

const InstanceTrialsTab = (props: InstanceTrialsTabProps) => {
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ))
    const { instance } = props;
    const trialReducer = useSelector((state: RootState) => state.trials)
    const appReducer = useSelector((state: RootState) => state.app)
    const dispatch = useDispatch();
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            dispatch(TrialActions.CreateTrialList(new TrialRetrieveListModel({ 
                ...tableParams.getApiFilter(),
                filters: new TrialFilterModel({instance_uuid: instance.uuid}), 
            })))
        },
        onDetails: (item: RequestLog) => {

        }
    });
    const columns = [
        new TableColumn({ key: 'status', isSortable: false, label: i18next.t('trials.status')}),
        new TableColumn({ key: 'requested_at', isSortable: true, label: i18next.t('general.timestamp.requested_at'), customValue: (row: Trial) =>  dateTimeFormat(row?.requested_at, 'do MMM yyyy')}),
        new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('general.timestamp.created_at'), customValue: (row: Trial) => dateTimeFormat(row?.created_at, 'do MMM yyyy')}),
        new TableColumn({ key: 'updated_at', isSortable: true, label: i18next.t('general.timestamp.updated_at'), customValue: (row: Trial) => dateTimeFormat(row?.updated_at, 'do MMM yyyy')}),
    ];
    const componentDidMount = () => {
        const ncListener = new NCListener(ActionTypes.TRIAL_UPDATE_LIST, delegate.getData)
        NotificationCenter.default.addNCListener(ncListener)
        return () => {NotificationCenter.default.removeListener(ncListener)}
    }
    // eslint-disable-next-line
    useEffect(componentDidMount, [])
    return (
        <Grid item xs={12} >
            <TableList
                hasDetailsAction
                columns={columns}
                count={trialReducer.collection?.count_filtered}
                listItems={trialReducer.collection?.items}
                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_TRIAL_LIST)}
                tableParams={ tableParams }
                delegate={ delegate } />
        </Grid>
    );
}

export default InstanceTrialsTab