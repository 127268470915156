/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    TrialModel,
    TrialModelFromJSON,
    TrialModelFromJSONTyped,
    TrialModelToJSON,
} from './';


export interface ClientModelInterface {
    id?: number;
    name?: string;
    company_name?: string;
    city?: string | null;
    address?: string | null;
    email?: string;
    phone?: string | null;
    website?: string | null;
    industry?: string | null;
    properties?: object;
    trials?: Array<TrialModel>;
    request?: ClientModelRequestEnum;
    requested_at?: Date;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface ClientModel
 */
export class ClientModel extends BaseModel<ClientModelInterface> {

    /**
     * id of the Client
     * @type {number}
     * @memberof ClientModel
     */
    id?: number;
    /**
     * Name for the client's contact person
     * @type {string}
     * @memberof ClientModel
     */
    name?: string;
    /**
     * Name of client's company
     * @type {string}
     * @memberof ClientModel
     */
    company_name?: string;
    /**
     * City of client
     * @type {string}
     * @memberof ClientModel
     */
    city?: string | null;
    /**
     * Address of client
     * @type {string}
     * @memberof ClientModel
     */
    address?: string | null;
    /**
     * Contact email
     * @type {string}
     * @memberof ClientModel
     */
    email?: string;
    /**
     * Contact phone
     * @type {string}
     * @memberof ClientModel
     */
    phone?: string | null;
    /**
     * Website of client
     * @type {string}
     * @memberof ClientModel
     */
    website?: string | null;
    /**
     * Industry of client's company
     * @type {string}
     * @memberof ClientModel
     */
    industry?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ClientModel
     */
    properties?: object;
    /**
     * 
     * @type {Array<TrialModel>}
     * @memberof ClientModel
     */
    trials?: Array<TrialModel>;
    /**
     * Type of request for trial. Set to backoffice if it can skip approval
     * @type {string}
     * @memberof ClientModel
     */
    request?: ClientModelRequestEnum;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ClientModel
     */
    requested_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ClientModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ClientModel
     */
    updated_at?: Date;

    constructor(data?: ClientModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ClientModel, value: StringDateInterface<ClientModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ClientModelFromJSON(json: any): ClientModel {
    return ClientModelFromJSONTyped(json, false);
}

export function ClientModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ClientModel;
    try {
        let localClass = require.context('../override', true)('./ClientModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'company_name': !exists(json, 'company_name') ? undefined : json['company_name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'trials': !exists(json, 'trials') ? undefined : ((json['trials'] as Array<any>).map(TrialModelFromJSON)),
        'request': !exists(json, 'request') ? undefined : json['request'],
        'requested_at': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'].replace(' ', 'T'))),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function ClientModelToJSON(value?: ClientModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ClientModel;
    try {
        let localClass = require.context('../override', true)('./ClientModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'company_name': value.company_name,
        'city': value.city,
        'address': value.address,
        'email': value.email,
        'phone': value.phone,
        'website': value.website,
        'industry': value.industry,
        'properties': value.properties,
        'trials': value.trials === undefined ? undefined : ((value.trials as Array<any>).map(TrialModelToJSON)),
        'request': value.request,
        'requested_at': value.requested_at === undefined ? undefined : (value.requested_at.toApiFormat()),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<ClientModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum ClientModelRequestEnum {
    Open = 'open',
    Accepted = 'accepted',
    Cancelled = 'cancelled',
    Backoffice = 'backoffice'
}


