import Historiable from "interface/Historiable";
import ImageVersionable from "interface/ImageVersionable";
import { ConnectorContainerModel, ConnectorContainerModelInterface } from "api/models";
import Connector from "./ConnectorModel";
import ImageVersion from "./ImageVersionModel";
import Instance from "./InstanceModel";
import BuildableModel from "interface/BuildableModel";
import Statuses from "interface/Statuses";

export default class ConnectorContainer extends ConnectorContainerModel implements ImageVersionable, Historiable, BuildableModel {
    actionName: string="connector_container";
    reducerName: string="connectorContainers";

    image_version: ImageVersion = null;
    connector: Connector = null;
    instance: Instance = null;
    status: string = Statuses.STATUS_BUILD_FAILED.toString();

    constructor(data?: ConnectorContainerModelInterface) {
        super(data)
        this.image_version = data.image_version as ImageVersion;
        this.connector = data.connector as Connector;
        this.instance = data.instance as Instance;
        this.status = data.status ?? Statuses.STATUS_BUILD_FAILED.toString();
    }

    getFullUrl(): string {
        return `https://${this.connector.url}.bumbal.eu/${this.instance.domain}/`;
    }

    getDeleteDialog(): string {
        return `${this.connector.name.toLowerCase().includes('connector') ? ' ' : ' connector '}`
    }

    getImageVersionString(): string {
        return this.image_version.fullVersionString;
    }

}