import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import TokenUtil from "utils/TokenUtil";
import { Page403 } from "./Page403";

interface RoleRouteProps extends PropsWithChildren {
    allowed_roles?: string[];
}

const RoleRoute = (props: RoleRouteProps): JSX.Element => {
    const userRole = TokenUtil.getTokenUtil().getRole();
    if (userRole == null) {
        return (
            <Navigate replace to='/sign-out' />
        )
    }
    const {allowed_roles} = props;
    const roleArray = allowed_roles ?? [];
    if (roleArray.bbIsEmpty() || roleArray.includes(userRole)) {
        return (<>{props.children}</>);
    } else {
        return <Page403 />;
    }
}


export default RoleRoute;