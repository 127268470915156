/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface StepviewRemoveSuccessModelInterface {
    id?: number;
    message?: string;
}

/**
 * 
 * @export
 * @interface StepviewRemoveSuccessModel
 */
export class StepviewRemoveSuccessModel extends BaseModel<StepviewRemoveSuccessModelInterface> {

    /**
     * Unique per stepview
     * @type {number}
     * @memberof StepviewRemoveSuccessModel
     */
    id?: number;
    /**
     * A message stating the success or error
     * @type {string}
     * @memberof StepviewRemoveSuccessModel
     */
    message?: string;

    constructor(data?: StepviewRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewRemoveSuccessModel, value: StringDateInterface<StepviewRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewRemoveSuccessModelFromJSON(json: any): StepviewRemoveSuccessModel {
    return StepviewRemoveSuccessModelFromJSONTyped(json, false);
}

export function StepviewRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function StepviewRemoveSuccessModelToJSON(value?: StepviewRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'message': value.message,
    } as StringDateInterface<StepviewRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


