import React, { Fragment } from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import DashboardStyles from 'styles/DashboardStyles';
import { withSnackbar } from "notistack";
import { withStyles } from 'tss-react/mui';
import AppState from 'reducers/interface/AppState';
import DashboardState from 'reducers/interface/DashboardState';
import { RetrieveDataRequest } from 'api';
import DashboardActions from 'reducers/types/Dashboard';
import ActionTypes from 'config/ActionTypes';
import EditToggle from './components/EditToggle';
import NotificationCenter from 'services/NotificationCenter';
import WidgetTypes from 'config/WidgetTypes';
import LiveCountWidget from './components/LiveCountWidget';
import i18next from 'i18next';
import RancherWidget from './components/RancherWidget';
import LoginHistoryWidget from './components/LoginHistoryWidget';
import LastVisitedDetailsWidget from './components/LastVisitedDetailsWidget';
import WidgetTemplateView from './components/WidgetTemplateView';
import QuickActionsWidget from './components/QuickActionsWidget';
import WidgetUtil from 'utils/WidgetUtil';
import LocalStorageManager from 'utils/LocalStorageManager';
import { BaseAction } from 'reducers/interface/ReducerAction';

interface StateProps {
    dashboardReducer: DashboardState;
    appReducer: AppState;
}

interface DispatchProps {
    getAvailableWidgets: () => void;
    getWidgetData: (params: RetrieveDataRequest) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State {
    activeWidgets: string[],
    isEditingEnabled: boolean
}

class Dashboard extends BasePageComponent<Props, State> {
    constructor(props) {
        super(props)

        this.state = {
            activeWidgets: [],
            isEditingEnabled: false
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
        if(this.props.dashboardReducer.available.length === 0){
            this.fetchAvailableWidgets()
        }
        this.updateTabTitle(i18next.t('dashboard.page_title'))
        if (LocalStorageManager.getUser()?.email === 'dave@bumbal.eu') {
            setTimeout(() => {
                document.getElementById('dave-smpstars')?.childNodes?.forEach(div => {
                    div['className'] = 'move';
                })
                setTimeout(() => {
                    document.getElementById('dave-smpstars')?.childNodes?.forEach(div => {
                        div['className'] = '';
                    })
                }, 8000);
            }, 1000);
        }
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.API_WIDGET_GET_AVAILABLE_SUCCESS,
            this.onRetrieveAvailableWidgets
        )
    }

    fetchAvailableWidgets = (): void => {
        this.props.getAvailableWidgets()
    }

    dashboardInit = () => {
        this.props.dashboardReducer.available.forEach((availableWidget: string) => {
            WidgetUtil.addActiveWidget(availableWidget)
        })
    }

    getWidgetData = (widgets: string[]) => {
        this.props.getWidgetData({ widgets: this.props.dashboardReducer.available })
    }

    onRetrieveAvailableWidgets = (action: BaseAction) => {
        this.getWidgetData(action.params.data)
        if (WidgetUtil.getNumberOfActiveWidgets() === 0) {
            this.dashboardInit()
        }
    }

    handleEditToggled = (): void => {
        this.setState(
            { isEditingEnabled: !this.state.isEditingEnabled },
            () => {
                if (this.state.isEditingEnabled) {
                    this.fetchAvailableWidgets()
                }
            }
        )
    }

    getWidget = (type: string, data: Object | Array<any>): JSX.Element => {
        const defaultProps = {
            key: type,
            title: i18next.t(`dashboard.widgets.${type}`),
            type: type,
            editView: this.state.isEditingEnabled,
            isActive: WidgetUtil.isActiveWidget(type),
            data: data
        }
        switch (type) {
            case WidgetTypes.LIVE_COUNT:
                return <LiveCountWidget {...defaultProps} />
            case WidgetTypes.RANCHER_STATISTICS:
                return <RancherWidget {...defaultProps} />
            case WidgetTypes.LOGIN_HISTORY:
                return <LoginHistoryWidget {...defaultProps} />
            case WidgetTypes.LAST_VISITED_DETAILS:
                return <LastVisitedDetailsWidget {...defaultProps} data={LocalStorageManager.getLastVisitedFromStorage()} />
            case WidgetTypes.QUICK_ACTIONS_WIDGET:
                return <QuickActionsWidget {...defaultProps} />
            default:
                return <Fragment key={type}></Fragment>
        }
    }

    renderView() {
        const { classes, dashboardReducer, appReducer } = this.props;

        return (
            <React.Fragment>
                <div className={classes.page_title}>
                    <h1> {LocalStorageManager.getUser()?.email === 'kasper@bumbal.eu' ? 'AchterKantoor' : i18next.t('general.backoffice')}</h1>
                    <h4>{i18next.t('dashboard.page_title')}</h4>
                </div>
                <EditToggle
                    onToggle={this.handleEditToggled}
                    isEditingEnabled={this.state.isEditingEnabled}
                />
                {(appReducer.isFetchingData.get(ActionTypes.API_WIDGET_GET_AVAILABLE) || appReducer.isFetchingData.get(ActionTypes.API_WIDGET_GET_DATA)) && 
                    <WidgetTemplateView/>
                }
                <div className={classes.widget_container}>
                    {Object.entries(dashboardReducer.widgetData).map(([widgetKey, widgetData]) => (
                        this.getWidget(widgetKey, widgetData)
                    ))}
                </div>
                { LocalStorageManager.getUser()?.email === 'dave@bumbal.eu' && (
                    <div className={classes.smpstars} id={'dave-smpstars'}>
                        {[...Array(8).keys()].map(i => (
                            <i key={'star-' + i} style={{transitionDelay: (75 * i) + 'ms'}}></i>
                        ))}
                    </div>
                ) }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    dashboardReducer: state.dashboard,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailableWidgets: () => dispatch(DashboardActions.CreateWidgetGetAvailable()),
        getWidgetData: (params: RetrieveDataRequest) => dispatch(DashboardActions.CreateWidgetGetData(params))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(Dashboard, DashboardStyles)
            )
        )
    )
);