import { Theme } from '@mui/material/styles';

const MenuDrawerStyles = ((theme: Theme) => ({
    drawer: {
        zIndex: 1400,
    },
    paper: {
        left: 56,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

export default MenuDrawerStyles