/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageModel,
    ImageModelFromJSON,
    ImageModelFromJSONTyped,
    ImageModelToJSON,
} from './';


export interface ImageVersionModelInterface {
    id?: number;
    version_name?: string | null;
    description?: string | null;
    version_tag?: string | null;
    latest?: boolean;
    image_id?: number | null;
    image?: ImageModel;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface ImageVersionModel
 */
export class ImageVersionModel extends BaseModel<ImageVersionModelInterface> {

    /**
     * id of the image version
     * @type {number}
     * @memberof ImageVersionModel
     */
    id?: number;
    /**
     * Name for the version
     * @type {string}
     * @memberof ImageVersionModel
     */
    version_name?: string | null;
    /**
     * Description of the version
     * @type {string}
     * @memberof ImageVersionModel
     */
    description?: string | null;
    /**
     * Docker version tag
     * @type {string}
     * @memberof ImageVersionModel
     */
    version_tag?: string | null;
    /**
     * Image is latest version
     * @type {boolean}
     * @memberof ImageVersionModel
     */
    latest?: boolean;
    /**
     * id of the parent image
     * @type {number}
     * @memberof ImageVersionModel
     */
    image_id?: number | null;
    /**
     * 
     * @type {ImageModel}
     * @memberof ImageVersionModel
     */
    image?: ImageModel;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ImageVersionModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ImageVersionModel
     */
    updated_at?: Date;

    constructor(data?: ImageVersionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageVersionModel, value: StringDateInterface<ImageVersionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageVersionModelFromJSON(json: any): ImageVersionModel {
    return ImageVersionModelFromJSONTyped(json, false);
}

export function ImageVersionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVersionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageVersionModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'version_name': !exists(json, 'version_name') ? undefined : json['version_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'version_tag': !exists(json, 'version_tag') ? undefined : json['version_tag'],
        'latest': !exists(json, 'latest') ? undefined : json['latest'],
        'image_id': !exists(json, 'image_id') ? undefined : Number(json['image_id']),
        'image': !exists(json, 'image') ? undefined : ImageModelFromJSON(json['image']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function ImageVersionModelToJSON(value?: ImageVersionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageVersionModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'version_name': value.version_name,
        'description': value.description,
        'version_tag': value.version_tag,
        'latest': value.latest,
        'image_id': value.image_id,
        'image': ImageModelToJSON(value.image),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<ImageVersionModel>;
    return createClass.toJson(value, model);
}


