
import { FormModel, FormModelInterface } from "api/models/FormModel";
import Historiable from "interface/Historiable";
import FormField from "./FormFieldModel";

export default class Form extends FormModel implements Historiable {

    actionName: string = "form"
    reducerName: string = "forms"

    fields: Array<FormField> = null;

    constructor(data?: FormModelInterface) {
        super(data)
        this.fields = data.fields as Array<FormField>;
    }
}