class InstanceAdminPassword {
    newPassword: string;
    confirmPassword: string;

    constructor() {
        this.newPassword = ''
        this.confirmPassword = ''
    }
}

export default InstanceAdminPassword