import React from 'react';
import useFormBuilderStyles from 'styles/FormBuilderStyles';
import { useTranslation } from 'react-i18next';

interface PaneTitleProps {
    pane: 'inputs' | 'preview' | 'options'
}

const PaneTitle = (props: PaneTitleProps) => {
    const { t } = useTranslation();
    const { pane } = props;
    const {classes} = useFormBuilderStyles();

    const getTitle = (): string => {
        switch (pane) {
            case 'inputs':
                return t('forms.pane.inputs_title')
            case 'preview':
                return t('forms.pane.preview_title')
            case 'options':
                return t('forms.pane.options_title')
            default:
                return ''
        }
    }

    const getSubtitle = (): string => {
        switch (pane) {
            case 'inputs':
                return t('forms.pane.inputs_subtitle')
            case 'preview':
                return t('forms.pane.preview_subtitle')
            case 'options':
                return t('forms.pane.options_subtitle')
            default:
                return ''
        }
    }

    return (
        <div className={classes.pane_title}>
            <h3>{getTitle()}</h3>
            <p>{getSubtitle()}</p>
        </div>
    );
};

export default PaneTitle;