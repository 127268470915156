import { HistoryListRequest, HistoryResponseModel } from "api"
import ActionTypes from "config/ActionTypes"
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction"


export interface HistoryList extends BaseReducerAction {
    type: ActionTypes.API_HISTORY_LIST;
    params: HistoryListRequest;
}

export interface HistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_HISTORY_LIST_SUCCESS;
}

export default class HistoryActions {
    
    static CreateHistoryList(params: HistoryListRequest): HistoryList {
        return {
            type: ActionTypes.API_HISTORY_LIST,
            params: params
        }
    }

    static CreateHistoryListSuccess(params: HistoryResponseModel): HistoryListSuccess {
        return {
            type: ActionTypes.API_HISTORY_LIST_SUCCESS,
            params: params
        }
    }
}
