import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Card, CardContent, Chip, Avatar } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory'
import StorageIcon from '@mui/icons-material/Storage'
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import BaseComponent, { BaseState } from 'components/base/BaseComponent';
import { ViewProps } from 'components/base/BasePageComponent';
import { withTranslation } from 'react-i18next';
import DonutChartable from 'models/DonutChartable';
import RancherNodeStyles from 'styles/RancherNodeStyles';
import { withStyles } from 'tss-react/mui';
import { NodeModel } from 'api';

interface RancherNodeCardProps {
    data: NodeModel
}

type Props = RancherNodeCardProps & ViewProps

interface State extends BaseState {
    labels: string[],
    datasets: DonutChartable[],
    options: {
        [legends: string]: {
            display: boolean
        }
    }

}

class RancherNodeCard extends BaseComponent<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            labels: ["Free", "Used"],
            datasets: [
                {
                    label: "cpu",
                    data: [1, 1],
                    backgroundColor: ["rgba(27, 27, 27, 0.9)", "#C5A0FF"]
                },
                {
                    label: "memory",
                    data: [1, 1],
                    backgroundColor: ["rgba(27, 27, 27, 0.9)", "#FF7968"]
                },
                {
                    label: "pods",
                    data: [1, 1],
                    backgroundColor: ["rgba(27, 27, 27, 0.9)", "#F6B91E"]
                }
            ],
            options: {
                legends: {
                    display: false
                }
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateChartData()
    }

    updateChartData() {
        const { allocatable, requested } = this.props.data
        const roundValues = (num) => Math.round(num * 100) / 100
        this.setState(state => {
            let updatedData = []
            state.datasets.forEach((item: DonutChartable) => {
                updatedData.push({
                    label: item.label, 
                    data: [roundValues(allocatable[item.label] - requested[item.label]), requested[item.label]], 
                    backgroundColor: item.backgroundColor
                })
            })
            
            return {
                datasets: updatedData
            }  as Pick<State, keyof State>;
        });
    }

    renderView() {
        const { classes } = this.props;
        const { allocatable, requested } = this.props.data
        return (
            <Card variant="outlined" className={classes.root}>
                <CardContent className={classes.card_content}>

                    <div className={classes.node_name}>
                        <h3>{this.props.data.name}</h3>
                    </div>


                    <Chip
                        avatar={<Avatar style={{ backgroundColor: "#7c59b3", color: "white" }}><MemoryIcon /></Avatar>}
                        label={`CPU: ${requested.cpu}/${allocatable.cpu}`}
                        style={{ backgroundColor: "#C5A0FF" }}
                        className={classes.chip_legend}
                    />
                    <Chip
                        avatar={<Avatar style={{ backgroundColor: "#a6392b", color: "white" }}><StorageIcon /></Avatar>}
                        label={`Memory: ${requested.memory}/${allocatable.memory}`}
                        style={{ backgroundColor: "#FF7968" }}
                        className={classes.chip_legend}
                    />
                    <Chip
                        avatar={<Avatar style={{ backgroundColor: "#b07f04", color: "white" }}><GroupWorkIcon /></Avatar>}
                        label={`Pods: ${requested.pods}/${allocatable.pods}`}
                        style={{ backgroundColor: "#F6B91E" }}
                        className={classes.chip_legend}
                    />

                    <Doughnut 
                        data={{ datasets: this.state.datasets, labels: this.state.labels }} 
                        options={{ maintainAspectRatio: false }} 
                    />

                </CardContent>
            </Card>
        )
    }
}

export default withTranslation()(withStyles(RancherNodeCard, RancherNodeStyles));