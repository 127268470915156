import { takeLatest, put } from "redux-saga/effects";
import { RoleApi, RoleListRequest } from "api";
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from "./APIHandler";
import { AppActions } from "reducers/types/App";
import RoleActions, { RoleList } from "reducers/types/Role";

export function* RoleSagas() {
    yield takeLatest(ActionTypes.API_ROLE_LIST, RoleSaga.roleListWorkerSaga);
}

export class RoleSaga {
    static *roleListWorkerSaga(action: RoleList) {
        try {
    
            const roleArgs = {
                roleRetrieveListModel: action.params
            } as RoleListRequest
            const data = yield ApiHandler(new RoleApi().roleList, roleArgs);
    
            // dispatch a success action to the store with the activities
            yield put(RoleActions.CreateRoleListSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_ROLE_LIST_FAILURE, error as Error))
        }
    }
}

