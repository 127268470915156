import uuid from "react-uuid";

export default class ServiceVolumeTableRow {

    public id: string;
    public type: string;
    public path: string;

    constructor(type: string, path: string) {
        this.id = uuid();
        this.type = type;
        this.path = path;
    }

}