/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ImageFilterModelInterface {
    for_model?: Array<string>;
}

/**
 * 
 * @export
 * @interface ImageFilterModel
 */
export class ImageFilterModel extends BaseModel<ImageFilterModelInterface> {

    /**
     * Get images for a type of model
     * @type {Array<string>}
     * @memberof ImageFilterModel
     */
    for_model?: Array<string>;

    constructor(data?: ImageFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageFilterModel, value: StringDateInterface<ImageFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageFilterModelFromJSON(json: any): ImageFilterModel {
    return ImageFilterModelFromJSONTyped(json, false);
}

export function ImageFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageFilterModel;
    try {
        let localClass = require.context('../override', true)('./ImageFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'for_model': !exists(json, 'for_model') ? undefined : json['for_model'],
    });
}

export function ImageFilterModelToJSON(value?: ImageFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageFilterModel;
    try {
        let localClass = require.context('../override', true)('./ImageFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'for_model': value.for_model,
    } as StringDateInterface<ImageFilterModel>;
    return createClass.toJson(value, model);
}


