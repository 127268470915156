import React from 'react';
import {  } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {
    Card,
    CardContent,
    Container,
    Grid
} from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { AccountGroup } from 'mdi-material-ui';
import ClientActions from 'reducers/types/Client';
import {  ClientRetrieveListModel } from 'api';
import ClientState from 'reducers/interface/ClientState';
import Client from 'api/override/ClientModel';
import HideOnProd from 'components/HideOnProd';
import { withRouter } from 'utils/withRouter';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    clientReducer: ClientState;
    appReducer: AppState;
}

interface DispatchProps {
    listClients: (params: ClientRetrieveListModel) => void;
    clearClients: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    client?: Client;
}

class ClientList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);

        this.state = {
            client: undefined
        };
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name') }),
            new TableColumn({ key: 'company_name', isSortable: true, label: i18next.t('clients.company_name') }),
            new TableColumn({ key: 'city', isSortable: true, label: i18next.t('clients.city') }),
            new TableColumn({ key: 'email', isSortable: true, label: i18next.t('clients.email') }),
            new TableColumn({ key: 'website', isSortable: true, label: i18next.t('clients.website') })
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_CLIENT_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('clients.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.clientReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listClients( this.getParams().getApiFilter() as any )
        }
    }

    onDetails = (row?: Client) => {
        this.props.navigate(`/clients/${row?.id}`)
    }

    onCreate = () => {
        this.props.navigate("/clients/create")
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('clients', this.props.location)){
            this.props.clearClients();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <HideOnProd>
                <React.Fragment>
                    <ReloadDataButton loadData={this.reloadData} />
                    <Container maxWidth={"lg"}>
                        <Card className={classes.root}>
                            <TableHeader
                                entity={i18next.t('clients.model')}
                                headerIcon={<AccountGroup/>}
                                onCreate={this.onCreate}
                            />
                            <CardContent className={classes.table_container}>
                                <TableFilter
                                    searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('clients.model_plural')}) }
                                    searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('clients.model_plural')}) }
                                    onFilterChange={ this.setFilters }
                                    columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                    searchText={this.tableParams?.search_text}
                                />
                                <Grid item xs={12} >
                                    <TableList
                                        hasDetailsAction
                                        columns={this.columns}
                                        count={this.props.clientReducer.collection.count_filtered}
                                        listItems={this.props.clientReducer.collection.items}
                                        isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_CLIENT_LIST)}
                                        tableParams={this.getParams()}
                                        delegate={ this } />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                </React.Fragment>
            </HideOnProd>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            FilterColumn.makeFilter('has_trials', FilterTypes.BOOLEAN, i18next.t('clients.has_trials')),
            FilterColumn.makeFilter('has_instances', FilterTypes.BOOLEAN, i18next.t('clients.has_instances')),
        ];
    }

}

const  mapStateToProps = (state: any) => ({
    clientReducer: state.clients,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listClients: (params: ClientRetrieveListModel) => dispatch(ClientActions.CreateClientList(params)),
        clearClients: (params: boolean) => dispatch(ClientActions.CreateClearClientList(params))
    }
}

export default tableConnect(
    mapStateToProps, 
    mapDispatchToProps, 
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ClientList, TableStyles)
            )
        )
    )
)