import React, { useState } from 'react';
import {
    Grid
} from "@mui/material";
import ActionTypes from "config/ActionTypes";
import TableList from 'components/TableList';
import Trial from 'api/override/TrialModel';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import TableColumn from 'models/table/TableColumn';
import i18next from 'i18next';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';
import { dateTimeFormat } from 'config/DateTimeFormatter';
import { useNavigate } from "react-router-dom";
import { TrialModel } from 'api/models/TrialModel';
import useTableDelegateHook from 'hooks/TableDelegateHook';

interface ClientTrialsTabProps {
    trials?: Array<TrialModel>;
}

const ClientTrialsTab = (props: ClientTrialsTabProps) => {
    const { trials } = props
    const navigate = useNavigate();
    const appReducer = useSelector((state: RootState) => state.app)
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('status', 'asc'), '', [] ));
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
        },
        onDetails: (trial: Trial) => {
            navigate(`/instances/${trial.instance_uuid}`)
        }
    });
    const columns = [
        new TableColumn({ key: 'status', isSortable: false, label: i18next.t('trials.status')}),
        new TableColumn({ key: 'requested_at', isSortable: true, label: i18next.t('general.timestamp.requested_at'), customValue: (row: Trial) =>  dateTimeFormat(row?.requested_at, 'do MMM yyyy')}),
        new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('general.timestamp.created_at'), customValue: (row: Trial) => dateTimeFormat(row?.created_at, 'do MMM yyyy')}),
        new TableColumn({ key: 'updated_at', isSortable: true, label: i18next.t('general.timestamp.updated_at'), customValue: (row: Trial) => dateTimeFormat(row?.updated_at, 'do MMM yyyy')}),
    ];

    return (
        <Grid item xs={12} >
            <TableList
                hasDetailsAction
                columns={columns}
                count={trials.length}
                listItems={trials}
                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_CLIENT_RETRIEVE)}
                tableParams={ tableParams }
                delegate={ delegate } />
        </Grid>
    );
}

export default ClientTrialsTab
