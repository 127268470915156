import React, { useEffect, useState } from 'react';
import {
    Grid
} from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import TableColumn from '../../models/table/TableColumn';
import TableList from 'components/TableList';
import { TableParams, TableParamsSorting } from '../../models/table/TableParams';
import { RootState } from 'store/AppStore';
import { useDispatch, useSelector } from 'react-redux';
import Historiable from 'interface/Historiable';
import RequestLog from 'api/override/RequestLogModel';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import useTableDelegateHook from 'hooks/TableDelegateHook';

interface HistoryTabProps {
    model: Historiable;
    overrideUuid?: string;
}

const HistoryTab = (props: HistoryTabProps) => {
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ))
    const { model, overrideUuid } = props;
    const dispatch = useDispatch();
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            const apiParams = {
                ...model
            }
            if (overrideUuid != null) {
                apiParams['uuid'] = overrideUuid;
            }
            dispatch({type: ActionTypes['API_'+ model.actionName.toUpperCase() + '_HISTORY_LIST'], 
            params: {
                ...apiParams,
                historyModelRetrieveListModel: tableParams.getApiFilter()
            }})
        },
        onDetails: (item: RequestLog) => {
            window.open(`https://logging.bumbal.eu/search?q=%22${item.token}%22&rangetype=relative&relative=0`) //open link in a new tab
        }
    });
    const columns = [
        new TableColumn({ key: 'message', isSortable: false, label: i18next.t('history.message')}),
        new TableColumn({ key: 'user', isSortable: true, label: i18next.t('users.model'), customValue: (row: RequestLog) =>  row?.user?.full_name}),
        new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('connector.date'), customValue: (row: RequestLog) => row?.createdDate()})
    ];
    const globalReducer = useSelector((state: RootState) => state)
    const loadData = (): void => {delegate.getData()}
    const componentDidMount = () => {
        const ncListener = new NCListener(ActionTypes['API_'+ model.actionName.toUpperCase() + '_HISTORY_UPDATE'], loadData)
        NotificationCenter.default.addNCListener(ncListener)
        return () => {NotificationCenter.default.removeListener(ncListener)}
    }
    // eslint-disable-next-line
    useEffect(componentDidMount, [])
    return (
        <Grid item xs={12} >
            <TableList
                hasDetailsAction
                columns={columns}
                count={globalReducer[model.reducerName].history?.count_filtered}
                listItems={globalReducer[model.reducerName].history?.items}
                isRefreshing={globalReducer.app.isFetchingData.get(ActionTypes['API_'+ model.actionName.toUpperCase() + '_HISTORY_LIST'])}
                tableParams={ tableParams }
                delegate={ delegate }
                idColumn={ 'token' } />
        </Grid>
    );
}

export default HistoryTab