import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useDetailsTabStyles = makeStyles()((theme: Theme) => {
    return {
        property_nan: {
            color: '#979797'
        },
        action_button: {
            padding: 6,
            borderRadius: 10,
            margin: 2.42069,
            transition: 'background 0.3s, box-shadow 0.2s',
            '&.error': {
                background: theme.palette.background.paper,
                color: theme.palette.error.main,
                border: '2px solid '+ theme.palette.error.main,
                '&:hover': {
                    background: theme.palette.error.main,
                    color: theme.palette.background.paper
                }
            },
            '&.secondary': {
                background: theme.palette.background.paper,
                color: theme.palette.primary.main,
                border: '2px solid ' + theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.background.paper
                }
            },
            '&.primary': {
                background: theme.palette.background.paper,
                color: theme.palette.primary.main,
                border: '2px solid ' + theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.background.paper
                }
            },
            '&.warn': {
                background: theme.palette.background.paper,
                color: theme.palette.primary.main,
                border: '2px solid ' + theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.background.paper
                }
            },
            '&.success': {
                background: theme.palette.background.paper,
                color: theme.palette.primary.main,
                border: '2px solid ' + theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.background.paper
                }
            },
            '&.token_generate': {
                background: theme.palette.background.paper,
                color: theme.palette.primary.main,
                border: '2px solid ' + theme.palette.primary.main,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: theme.palette.background.paper
                }
            },
            '&.copy': {
                padding: 0,
                margin: '10px',
                width: 0,
                height: 0,
                '&:hover': {
                    color: theme.palette.primary.main,
                }
            },
            '&:hover': {
                boxShadow: '0 0 5px 0 rgba(0,0,0,0.34)',
            }
        },
        info_icon: {
            color: '#404040',
            cursor: 'pointer',
            '&:hover':{
                color: theme.palette.primary.main,
            }
        },
        switch_container: {
            display: 'flex',
            alignItems: 'center'
        },
        process_edit_button: {
            padding: 5
        },
        documentation_title: {
            textAlign: 'center', 
            fontSize: 14, 
            fontWeight: 300
        },
        divider: {
            margin: 30
        },
        migration_button: {
            marginTop: 50,
            width: '100%',
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: -16,
            textTransform: 'uppercase'
        },
        action_buttons_container: {
            display: 'flex',
            width: 'fit-content',
            position: 'absolute',
            right: 30,
            justifyContent: 'center',
            alignItems: 'center'
        },
        card_content: {
            margin: 40
        },
        token_heading: {
            fontStyle: 'italic',
            color: '#979797',
            textTransform: 'capitalize',
            fontSize: '12px',
            fontWeight: 500,
            padding: 0,
            display: 'block',
            marginTop: 12
        },
        token_panel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: 1.43,
            width: 124,

            '& p': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        details_field_container: {
            margin: '20px 0 0 2px',
            display: 'flex',
            flexDirection: 'column',
        },
        actions_container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            position: 'absolute',
            right: 40
        },
        base_action_buttons_container: {
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        link_text: {
            color: theme.palette.mode === 'dark' ? '#a2a2ff' : '#5656ff'
        },
        details_field_label: {
            color: '#979797'
        },
        details_field_icon_button: {
            marginLeft: 6,
            padding: 4
        }
    }
});

export default useDetailsTabStyles
