import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TokenUtil from 'utils/TokenUtil';
import Service from 'api/override/ServiceModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ReadOnlyField from 'components/ReadOnlyField';
import StatusSingleSelect from 'components/form_controls/StatusSingleSelect';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import ImageController from 'controllers/ImageController';
import ImageVersionController from 'controllers/ImageVersionController';
import { ImageModelForModelEnum } from 'api';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import BBCheckbox from 'components/form_controls/BBCheckbox';
import FormErrorTextStyled from 'components/styled/FormErrorTextStyled';
import DatabaseMultiSelect from 'components/form_controls/DatabaseMultiSelect';
import ServicePathForm from 'views/Services/components/ServicePathForm';

interface ServiceDetailsDialogProps extends BaseFormModalProps<Service> {
    service?: Service;
}

const ServiceDetailsDialog = (props: ServiceDetailsDialogProps) => {

    const { service } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const isAdmin: boolean = TokenUtil.getTokenUtil().isAccountManager()
    const [imageController] = useState<ImageController>(new ImageController())
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', { model: t('service.model') })}
            initialValues={service}
            action={ActionTypes.API_SERVICE_CREATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) =>
                <div className={classes.fields_container}>
                    <TextFieldStyled
                        id='name'
                        name='name'
                        label={t('general.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        disabled={!isAdmin}
                    />
                    { formik.values.standalone ? (
                        <StatusSingleSelect formik={formik as any} />
                    ) : (
                        <ReadOnlyField
                            label={t('instance.status')}
                            value={t('general.no_status')}
                            disabled
                        />
                    )}

                    <ReadOnlyField
                        id='url'
                        name='url'
                        label={t('connector.url')}
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        disabled
                    />

                    <ImageSingleSelect 
                        disabled={service.standalone || !isAdmin}
                        id='image'
                        name='image_id'
                        controller={imageController}
                        formik={formik}
                        label={t('connector.image')}
                        model={[ImageModelForModelEnum.ServiceContainer]}
                    />
                    { formik.values.image_id !== formik.initialValues.image_id ? (
                        <FormErrorTextStyled isWarning message={t('service.edit_image_warning')}/>
                    ) : (null)}

                    { service.standalone ? (
                        <ImageVersionSingleSelect 
                            id='image_version'
                            name='image_version_id'
                            controller={imageVersionController}
                            formik={formik}
                            imageId={formik.values.image_id}
                            disabled={formik.values?.image_id == null}
                            label={t('image_versions.model')}
                        />
                    ) : (null)}

                    { !service.standalone ? (
                        <>
                            <BBCheckbox
                                checkboxProps={{
                                    checked: formik.values.has_port,
                                    onChange: formik.handleChange,
                                    id: 'has_port',
                                    name: 'has_port'
                                }}
                                label={t('service.has_port')}
                            />
                            { formik.values.has_port !== formik.initialValues.has_port ? (
                                <FormErrorTextStyled isWarning message={t('service.has_port_edit_warning')}/>
                            ) : (null)}
                        </>
                    ) : (null)}

                    <DatabaseMultiSelect formik={formik} />

                    { service.standalone ? (null) : (
                        <ServicePathForm formik={formik} />
                    )}

                </div>
            }
        />
    );
};

export default ServiceDetailsDialog;