import React from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import BaseSelect, { BaseSelectProps } from '../base/BaseSelect';

interface MultiSelectProps extends BaseSelectProps {
    onChange: (name: React.ChangeEvent<{}>, value: MultiSelectOption[]) => void;
}

const MultiSelect = (props: MultiSelectProps) => {

    const handleOnChange = (event: React.ChangeEvent<{}>, value: MultiSelectOption[]) => {
        props.onChange(event, value)
    }

    return (
        <BaseSelect
            {...props}
            multiple
            onChange={handleOnChange}
        />
    )
}

export default MultiSelect;