import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useUnauthorizedStyles = makeStyles()((theme: Theme) => {
    return {
        container: {
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center'  
        },
        button : {
            marginTop: 20
        }
    }
})

export default useUnauthorizedStyles
