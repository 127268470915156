import React from "react";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import Stepview from "api/override/StepviewModel";
import { Divider, IconButton } from "@mui/material";
import { FormikProps } from "formik";
import { DeleteOutline, HelpCircleOutline, } from "mdi-material-ui";
import StepviewWidget from "api/override/StepviewWidgetModel";
import Widget from "api/override/WidgetModel";
import TextFieldStyled from "components/styled/TextFieldStyled";
import { WidgetFieldModel } from "api";
import TextEditor from "components/TextEditor";
import BBCheckbox from "components/form_controls/BBCheckbox";

interface StepviewWidgetViewProps {
    stepviewWidget: StepviewWidget;
    formik: FormikProps<Stepview>
    widgets: Array<Widget>;
    swWidgetIndex: number;
    onRemove: () => void;
}

const StepviewWidgetView = (props: StepviewWidgetViewProps) => {
    
    const {stepviewWidget, formik, onRemove } = props
    const { classes } = useWizardBuilderStyles()
    
    const renderWidgetIcon = (): JSX.Element => {
        const WidgetIcon = (getWidgetById(stepviewWidget.widget_id) as Widget)?.mdIcon
        return WidgetIcon != null ? <WidgetIcon/> : <HelpCircleOutline/>
    }

    const getWidgetById = (id: number): Widget => {
        return props.widgets.find((widget: Widget) => widget.id === id)
    }

    const handleChangeTextFreeInput = (text: string, field: WidgetFieldModel) => {
        formik.setFieldValue(`stepview_widgets[${props.swWidgetIndex}][fields][${field.field}]`, text)
    }

    const renderWidgetFields = (): JSX.Element => {
        const widget: Widget = getWidgetById(stepviewWidget.widget_id)
        return (<>
            {widget.fields.map((field: WidgetFieldModel, i: number) => {
                const {type, title, optional} = field
                switch(type) {
                    case 'text':
                        return <TextFieldStyled
                            id={`fields.${title}`}
                            name={`stepview_widgets[${props.swWidgetIndex}][fields][${field.field}]`}
                            label={title}
                            required={optional}
                            value={formik.values.stepview_widgets?.[props.swWidgetIndex]?.fields?.[field.field] ?? ''}
                            onChange={formik.handleChange}/>
                    case 'html':
                        return <TextEditor 
                            value={ formik.values.stepview_widgets?.[props.swWidgetIndex]?.fields?.[field.field] ?? '' }
                            onChange={ (text: string) => handleChangeTextFreeInput(text, field) }
                            />
                    case 'bool':
                        return <BBCheckbox
                                checkboxProps={{
                                    checked: formik.values.stepview_widgets?.[props.swWidgetIndex]?.fields?.[field.field],
                                    onChange: formik.handleChange,
                                    id: `fields.${title}`,
                                    name: `stepview_widgets[${props.swWidgetIndex}][fields][${field.field}]`
                                }}
                                label={title}
                            />
                        
                        
                    default:
                        return <></>
                }

            })}
        </>)
    }

    return (
        <div className={classes.stepview_widget_view_root}>
            <div className={classes.stepview_widget_view_icon}>
                {renderWidgetIcon()}
                <Divider orientation="vertical"/>
            </div>
            <div className={classes.stepview_widget_view_fields}>
                {renderWidgetFields()}
            </div>
            <IconButton onClick={onRemove} size="large">
                <DeleteOutline/>
            </IconButton>
        </div>
    );
}

export default StepviewWidgetView;