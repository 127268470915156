import RancherNode from 'interface/widgets/RancherNode';
import React, { Fragment, useEffect, useState }  from 'react';
import useWidgetStyles from 'styles/WidgetStyles';
import BaseWidget, { BaseWidgetProps } from "./BaseWidget";
import WidgetRowView from './WidgetRowView';

interface RancherWidgetProps extends BaseWidgetProps {
}

const RancherWidget = (props: RancherWidgetProps) => {
 
    const { data } = props;
    const [formattedData, setFormattedData] = useState<Array<RancherNode>>([])
    const {classes} = useWidgetStyles();
    
    const formatPercentageLabel = (str: string): string => {
        if(str === '0') {
            return '<1%'
        }
        return str + '%'
    }

    useEffect(() => {
        setFormattedData((data as Array<RancherNode>).map((node: RancherNode) => {
            let formattedCpuPercentage = Math.round(node.usage.cpu as number).toString()
            let formattedMemoryPercentage = Math.round(node.usage.memory as number).toString()
            return {...node, usage: {cpu: formatPercentageLabel(formattedCpuPercentage), memory: formatPercentageLabel(formattedMemoryPercentage)}}
        }))
    }, [data])

    return (
        <BaseWidget {...props}>
            <WidgetRowView isHeader customStyle={{gridTemplateColumns: '4fr 1fr 1fr'}}>
                <h4>IP</h4>
                <h4>CPU</h4>
                <h4>RAM</h4>
            </WidgetRowView>
            {formattedData.map((node: RancherNode) => {
                return (
                    <Fragment key={`racherNode_container_${node.ip}`}>
                        <WidgetRowView key={`racherNode_${node.ip}`} customStyle={{gridTemplateColumns: '4fr 1fr 1fr'}}>
                            <p>
                                <span key={`racherNode_indicator_${node.ip}`} className={`${classes.status_indicator} active`}></span>
                                {node.ip}
                            </p>
                            <p>{node.usage.cpu}</p>
                            <p>{node.usage.memory}</p>
                        </WidgetRowView>
                    </Fragment>
                )
            })}

        </BaseWidget>
    );
};

export default RancherWidget;