import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Service from 'api/override/ServiceModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import ServiceActions from 'reducers/types/Service';
import { Form, Formik, FormikProps } from 'formik';
import ServiceSecret from 'api/override/ServiceSecretModel';
import { ContentSaveOutline } from 'mdi-material-ui';
import ButtonStyled from 'components/styled/ButtonStyled';
import useServiceStyles from 'styles/ServiceStyles';
import { LinearProgress } from '@mui/material';
import ServiceSecretForm from './ServiceSecretForm';
import ActionTypes from 'config/ActionTypes';

interface ServiceSecretsTabProps {
    service: Service;
    onSaveChanges: (serviceSecrets: ServiceSecret) => void;
}

const ServiceSecretsTab = (props: ServiceSecretsTabProps) => {
    const { t } = useTranslation();
    const { service, onSaveChanges } = props;
    const {classes} = useServiceStyles();

    const formRef = useRef<FormikProps<ServiceSecret>>(null);
    const dispatch = useDispatch();
    const serviceSecrets = useSelector((state: RootState) => state.services.secrets)
    const isFetchingSecrets = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_SERVICE_RETRIEVE_SECRETS))

    const handleComponentDidMount = () => {
        dispatch(ServiceActions.CreateServiceRetrieveSecrets({uuid: service.uuid}))
    }
    // eslint-disable-next-line    
    React.useEffect(handleComponentDidMount, [])
    
    return (
        serviceSecrets != null ?
        <div className={classes.card_content}>
            <Formik
            initialValues={serviceSecrets}
            onSubmit={onSaveChanges}
            innerRef={formRef}>
                {(formik: FormikProps<ServiceSecret>) => 
                <Form>
                    <>
                    <div className={classes.ss_header}>
                        <h2>{t('service.secrets')}</h2>
                        <ButtonStyled type="submit" startIcon={<ContentSaveOutline/>}>
                            {t('global.button.save')}
                        </ButtonStyled>
                    </div>
                    <ServiceSecretForm formik={formik} />
                    </>
                </Form>
                }
            </Formik>
        </div>
        : isFetchingSecrets ?
            <LinearProgress/>
        :
            <></>
    );
};

export default ServiceSecretsTab;