interface TableColumnProps {
    key: string;
    isSortable?: boolean;
    sortingColumn?: string;
    numeric?: boolean;
    disablePadding?: boolean;
    label: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    customValue?: (row: any) => string | JSX.Element;
    width?: string;
}

export default class TableColumn {
    key: string;
    isSortable: boolean;
    sortingColumn?: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    align: "inherit" | "left" | "center" | "right" | "justify";
    customValue?: (row: any) => string | JSX.Element;
    width?: string;

    constructor(val: TableColumnProps) {
        this.key = val.key;
        this.isSortable = val.isSortable || false;
        this.sortingColumn = val.sortingColumn;
        this.numeric = val.numeric || false;
        this.disablePadding = val.disablePadding || false;
        this.label = val.label;
        this.customValue = val.customValue;
        this.align = val.align ?? 'left';
        this.width = val.width;
    }
}