import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import { FormListRequest, FormApi, FormUpdateRequest, FormRetrieveRequest, FormCreateRequest, FormDeleteRequest, FormHistoryListRequest } from 'api';
import Form from 'api/override/FormModel';
import FormActions, { FormCreate, FormDelete, FormHistoryList, FormList, FormRetrieve, FormUpdate } from 'reducers/types/Form';

export function* FormSagas() {
    yield takeLatest(ActionTypes.API_FORM_LIST, FormSaga.formListWorkerSaga);
    yield takeLatest(ActionTypes.API_FORM_UPDATE, FormSaga.formUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_FORM_RETRIEVE, FormSaga.formRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_FORM_CREATE, FormSaga.formCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_FORM_DELETE, FormSaga.formDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_FORM_HISTORY_LIST, FormSaga.formHistoryListWorkerSaga);
}

export class FormSaga {
    
    static *formListWorkerSaga(action: FormList) {
        try {
            const formsArgs = {
                formRetrieveListModel: action.params
            } as FormListRequest

            const data = yield ApiHandler(new FormApi().formList, formsArgs)
            yield put(FormActions.CreateFormListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_FORM_LIST_FAILURE, error as Error))
        }
    }

    static *formUpdateWorkerSaga(action: FormUpdate) {
        try {
            
            const data = yield ApiHandler(new FormApi().formUpdate, action.params as FormUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(FormActions.CreateFormUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_FORM_UPDATE_FAILURE, error as Error))
        }
    }

    static formRetrieve = async(form: number): Promise<Form> => {
        return await new FormApi().formRetrieve({id: form}) as Form;
    }
    
    static *formRetrieveWorkerSaga(action: FormRetrieve) {
        try {
    
            const formsArgs = {
                id: action.params
            } as FormRetrieveRequest
            const data = yield ApiHandler(new FormApi().formRetrieve, formsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(FormActions.CreateFormRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_FORM_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *formCreateWorkerSaga(action: FormCreate) {
        try {
    
            const formsArgs = {
                formModel: action.params
            } as FormCreateRequest
            const data = yield ApiHandler(new FormApi().formCreate, formsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(FormActions.CreateFormCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_FORM_CREATE_FAILURE, error as Error))
        }
    }
    
    static *formDeleteWorkerSaga(action: FormDelete) {
        try {
            const formsArgs = {
                id: action.params
            } as FormDeleteRequest
            const data = yield ApiHandler(new FormApi().formDelete, formsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(FormActions.CreateFormDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_FORM_DELETE_FAILURE, error as Error))
        }
    }
    
    static *formHistoryListWorkerSaga(action: FormHistoryList) {
        try{
            const formArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as FormHistoryListRequest
    
            const data = yield ApiHandler(new FormApi().formHistoryList, formArgs);
            yield put(FormActions.CreateFormHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_FORM_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

