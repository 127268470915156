/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConfigDataModel,
    ConfigDataModelFromJSON,
    ConfigDataModelFromJSONTyped,
    ConfigDataModelToJSON,
} from './';


export interface GetConfigModelInterface {
    code?: number;
    success?: boolean;
    data?: ConfigDataModel;
    errors?: Array<string>;
}

/**
 * 
 * @export
 * @interface GetConfigModel
 */
export class GetConfigModel extends BaseModel<GetConfigModelInterface> {

    /**
     * HTTP status of the request
     * @type {number}
     * @memberof GetConfigModel
     */
    code?: number;
    /**
     * Bool indicating request could be performed
     * @type {boolean}
     * @memberof GetConfigModel
     */
    success?: boolean;
    /**
     * 
     * @type {ConfigDataModel}
     * @memberof GetConfigModel
     */
    data?: ConfigDataModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConfigModel
     */
    errors?: Array<string>;

    constructor(data?: GetConfigModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: GetConfigModel, value: StringDateInterface<GetConfigModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function GetConfigModelFromJSON(json: any): GetConfigModel {
    return GetConfigModelFromJSONTyped(json, false);
}

export function GetConfigModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConfigModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = GetConfigModel;
    try {
        let localClass = require.context('../override', true)('./GetConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : ConfigDataModelFromJSON(json['data']),
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    });
}

export function GetConfigModelToJSON(value?: GetConfigModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = GetConfigModel;
    try {
        let localClass = require.context('../override', true)('./GetConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'code': value.code,
        'success': value.success,
        'data': ConfigDataModelToJSON(value.data),
        'errors': value.errors,
    } as StringDateInterface<GetConfigModel>;
    return createClass.toJson(value, model);
}


