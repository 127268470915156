import React from 'react'
import {Checkbox, CheckboxProps } from '@mui/material'

const CheckboxStyled = (props: CheckboxProps) => {

    return (
        <Checkbox color="primary" {...props}/>
    )
}

export default CheckboxStyled