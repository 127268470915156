/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface WidgetFieldModelInterface {
    field?: string;
    title?: string | null;
    type?: string;
    optional?: boolean;
}

/**
 * 
 * @export
 * @interface WidgetFieldModel
 */
export class WidgetFieldModel extends BaseModel<WidgetFieldModelInterface> {

    /**
     * Configuration name
     * @type {string}
     * @memberof WidgetFieldModel
     */
    field?: string;
    /**
     * Proper title for the user
     * @type {string}
     * @memberof WidgetFieldModel
     */
    title?: string | null;
    /**
     * Type of input
     * @type {string}
     * @memberof WidgetFieldModel
     */
    type?: string;
    /**
     * Bool indicating whether this input can be left empty
     * @type {boolean}
     * @memberof WidgetFieldModel
     */
    optional?: boolean;

    constructor(data?: WidgetFieldModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: WidgetFieldModel, value: StringDateInterface<WidgetFieldModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function WidgetFieldModelFromJSON(json: any): WidgetFieldModel {
    return WidgetFieldModelFromJSONTyped(json, false);
}

export function WidgetFieldModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetFieldModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = WidgetFieldModel;
    try {
        let localClass = require.context('../override', true)('./WidgetFieldModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
    });
}

export function WidgetFieldModelToJSON(value?: WidgetFieldModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = WidgetFieldModel;
    try {
        let localClass = require.context('../override', true)('./WidgetFieldModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'field': value.field,
        'title': value.title,
        'type': value.type,
        'optional': value.optional,
    } as StringDateInterface<WidgetFieldModel>;
    return createClass.toJson(value, model);
}


