import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import Mail from 'api/override/MailModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { retrieveMailSchema } from 'schemas/Mail';


interface MailDetailsDialogProps extends BaseFormModalProps<Mail> {
    mail: Mail;
}

const MailDetailsDialog = (props: MailDetailsDialogProps) => {

    const { mail } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('mails.model')})}
            initialValues={mail}
            validationSchema={retrieveMailSchema()}
            action={ActionTypes.API_MAIL_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('general.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="subject" 
                        name="subject"
                        label={t('mails.subject')}
                        type="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="event" 
                        name="event"
                        label={t('mails.event')}
                        type="event"
                        value={formik.values.event}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('general.description')}
                        type="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                </div>
            }
        />
    );
};

export default MailDetailsDialog;