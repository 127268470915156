import React, { Fragment } from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Grid, LinearProgress } from "@mui/material";
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import { ViewProps } from 'components/base/BasePageComponent';
import i18next from 'i18next';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { ClientRetrieveListModel, ClientStatusModel, ClientStatusModelRequestEnum, TrialHistoryListRequest } from 'api';
import TrialState from 'reducers/interface/TrialState';
import TrialActions, { TrialUpdateParams } from 'reducers/types/Trial';
import ReloadDataButton from 'components/ReloadDataButton';
import Trial from 'api/override/TrialModel';
import TrialsDashboardViewStyles from 'styles/TrialsDashboardViewStyles';
import TrialSectionHeader from './components/TrialSectionHeader';
import OpenTrialCard from './components/OpenTrialCard';
import AcceptedTrialCard from './components/AcceptedTrialCard';
import ClientState from 'reducers/interface/ClientState';
import ClientActions, { ClientStatusUpdateParams } from 'reducers/types/Client';
import ButtonStyled from 'components/styled/ButtonStyled';
import { AccountGroup } from 'mdi-material-ui';
import TableViewDelegate from 'interface/TableViewDelegate';
import TableColumn from 'models/table/TableColumn';
import Client from 'api/override/ClientModel';
import { ClientModelRequestEnum } from 'api/models/ClientModel';
import ClientAcceptRequestDialog from './components/ClientAcceptRequestDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import NotificationCenter from 'services/NotificationCenter';
import HideOnProd from 'components/HideOnProd';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    appReducer: AppState;
    trialReducer: TrialState;
    clientReducer: ClientState;
}

interface DispatchProps {
    retrieveTrial: (params: string) => void;
    listClients: (params: ClientRetrieveListModel) => void;
    updateTrial: (params: Trial) => void;
    createTrial: (params: Trial) => void;
    deleteTrial: (params: string) => void;
    clientUpdateStatus: (params: ClientStatusUpdateParams) => void;
    clearTrials: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    trial?: Trial;
    isOpenTrialsExpanded: boolean;
    isAcceptedTrialsExpanded: boolean;
    isAcceptRequestDialogOpen: boolean;
    isConfirmRejectRequestDialogOpen: boolean;
    clientToUpdate: Client;
}

class TrialsDashboard extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);

        this.state = {
            trial: null,
            isOpenTrialsExpanded: true,
            isAcceptedTrialsExpanded: true,
            isAcceptRequestDialogOpen: false,
            isConfirmRejectRequestDialogOpen: false,
            clientToUpdate: undefined
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_TRIAL_UPDATE_FAILURE,
            ActionTypes.API_CLIENT_LIST_FAILURE,
            ActionTypes.API_CLIENT_STATUS_UPDATE_FAILURE,
        ])
        this.getData()
        this.updateTabTitle(i18next.t('trials.dashboard_title'))

    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CLIENT_STATUS_UPDATE_SUCCESS,
            this.onStatusUpdateSuccess
        )
    }

    configureColumns = () => {
        this.columns = [];
    }

    getData() {
        if (this.getParams() != null) {
            this.props.listClients({...this.getParams().getApiFilter()})
        }
        // this.props.listClients({...this.getParams().getApiFilter(), filters: { has_trials: true, trial_status: ClientFilterModelTrialStatusEnum.Running}})
    }

    getItemCount = (): number => {
        return this.props.clientReducer.collection.items.length
    }

    handleOnExpandOpen = () => {
        this.setState({isOpenTrialsExpanded: !this.state.isOpenTrialsExpanded})
    }

    handleOnExpandAccepted = () => {
        this.setState({isAcceptedTrialsExpanded: !this.state.isAcceptedTrialsExpanded})
    }

    getClientsWithTrials = (): Array<Client> => {
        return this.props.clientReducer.collection.items.filter((client: Client) => client.request === ClientModelRequestEnum.Accepted) ?? []
    }

    getClientsWithOpenRequests = (): Array<Client> => {
        return this.props.clientReducer.collection.items.filter((client: Client) => client.request === ClientModelRequestEnum.Open ) ?? []
    }

    clientAcceptRequest = (clientStatusModel: ClientStatusModel) => {
        this.props.clientUpdateStatus({id: this.state.clientToUpdate.id, clientStatusModel: clientStatusModel})
    }
    
    clientRejectRequest = () => {
        const updateModel = new ClientStatusModel({ request: ClientStatusModelRequestEnum.Cancelled });
        this.props.clientUpdateStatus({id: this.state.clientToUpdate.id, clientStatusModel: updateModel})
    }

    onClientAcceptRequest = (client: Client) => {
        this.setState({
            isAcceptRequestDialogOpen: true,
            clientToUpdate: client
        })
    }
    
    onClientRejectRequest = (client: Client) => {
        this.setState({
            isConfirmRejectRequestDialogOpen: true,
            clientToUpdate: client
        })
    }

    onStatusUpdateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('trials.model')}), {variant: 'success'});
        this.dialogClosed();
        this.getData()
    }

    dialogClosed = () => {
        this.setState({
            isAcceptRequestDialogOpen: false,
            isConfirmRejectRequestDialogOpen: false
        })        
    }

    isFetchingClients = (): boolean => this.props.appReducer.isFetchingData.get(ActionTypes.API_CLIENT_LIST)

    renderView() {
        const { classes } = this.props;
        return (
            <HideOnProd>
                <Fragment>
                    <div className={classes.page_title}>
                        <div>
                            <h1> {i18next.t('trials.dashboard_title')}</h1>
                            <h4>{i18next.t('dashboard.page_title')}</h4>
                        </div>
                        <ReloadDataButton loadData={this.getData} style={{top: 14}}/>
                        <ButtonStyled startIcon={<AccountGroup/>} onClick={() => this.props.navigate('/clients')}>
                            {i18next.t('clients.model_plural')}
                        </ButtonStyled>
                    </div>
                    <Grid container direction="row" spacing={6} className={classes.root} justifyContent='center'>
                        <Grid container item lg={7} md={12} xs={12} spacing={2}>
                            <div className={classes.open_container}>
                                <TrialSectionHeader isOpen onExpand={this.handleOnExpandOpen}/>
                                {(this.state.isOpenTrialsExpanded && !this.isFetchingClients()) && 
                                    <>
                                        {this.getClientsWithOpenRequests().map((client: Client, index: number) => (
                                            <OpenTrialCard 
                                                key={`client_${client.id}_${index}`}
                                                client={client} 
                                                onAcceptClientRequest={(client: Client) => this.onClientAcceptRequest(client)}
                                                onRejectClientRequest={(client: Client) => this.onClientRejectRequest(client)}
                                            />
                                        ))}
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid container item lg={5} md={12} xs={12} spacing={2} alignContent='flex-start' justifyContent='center'>
                                <TrialSectionHeader onExpand={this.handleOnExpandAccepted}/>
                                {(this.state.isAcceptedTrialsExpanded && !this.isFetchingClients()) && 
                                    <Grid container item xs={12} spacing={2} style={{padding: '12px 0'}}>
                                        {this.getClientsWithTrials().map((client: Client, index: number) => (
                                            <AcceptedTrialCard client={client} key={`client_${client.id}_${index}`}/>
                                        ))}
                                        {/* two accepted trial cards */}
                                    </Grid>
                                }
                        </Grid>
                    </Grid>
                    {this.isFetchingClients() && <LinearProgress color="primary" className={classes.loading_bar}/> }
                    <ClientAcceptRequestDialog 
                        open={this.state.isAcceptRequestDialogOpen}
                        client={this.state.clientToUpdate}
                        onModalClosePress={this.dialogClosed}
                        onModalSubmit={(clientStatusModel: ClientStatusModel) => {
                            this.clientAcceptRequest(clientStatusModel)
                        }}
                    />

                    <ConfirmDialog 
                        open={this.state.isConfirmRejectRequestDialogOpen}
                        onModalClosePress={this.dialogClosed}
                        onConfirm={this.clientRejectRequest}
                        title={i18next.t('clients.reject_request_confirm_model', {model: this.state.clientToUpdate?.name ?? ''})}
                    />
                </Fragment>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    trialReducer: state.trials,
    clientReducer: state.clients
});

const mapDispatchToProps = (dispatch) => {
    return {
        listClients: (params: ClientRetrieveListModel) => dispatch(ClientActions.CreateClientList(params)),
        clientUpdateStatus: (params: ClientStatusUpdateParams) => dispatch(ClientActions.CreateClientStatusUpdate(params)),
        updateTrial: (params: TrialUpdateParams) => dispatch(TrialActions.CreateTrialUpdate(params)),
        listTrialHistory: (params: TrialHistoryListRequest) => dispatch(TrialActions.CreateTrialHistoryList(params)),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(TrialsDashboard, TrialsDashboardViewStyles)
            )
        )
    )
);