import Connector from "api/override/ConnectorModel";
import MenuButton from "components/MenuButton";
import Tasks from "config/Tasks";
import { DotsVertical } from "mdi-material-ui";
import { CustomMenuButton } from "models/table/CustomActionButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ConnectorActions from "reducers/types/Connector";

export interface ConnectorMenuProps {
    connector: Connector;
}

const ConnectorMenu = (props: ConnectorMenuProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let options: string[] = [t('global.button.trigger.create'), t('global.button.trigger.delete')]
    if(props.connector.hasRedirect()) {
        options = options.concat(t('global.button.trigger.redeploy'))
    }
    if(!location.pathname.includes(props.connector.uuid)) {
        options = options.concat(t('global.button.redirect'))
    }
    // if developer
    options = options.concat(t('general.goto_image'))
    //
    const handleClick = (option: string) => {
        switch (option) {
            case t('global.button.trigger.create'):
                dispatch(ConnectorActions.CreateConnectorTriggerJob({uuid: props.connector.uuid, task: Tasks.CREATE}))
                break;
            case t('global.button.trigger.delete'):
                dispatch(ConnectorActions.CreateConnectorTriggerJob({uuid: props.connector.uuid, task: Tasks.DELETE}))
                break;
            case t('global.button.trigger.redeploy'):
                dispatch(ConnectorActions.CreateConnectorTriggerJob({uuid: props.connector.uuid, task: Tasks.REDEPLOY}))
                break;
            case t('global.button.redirect'):
                navigate(`/connectors/${props.connector.uuid}`)
                break;
            case t('general.goto_image'):
                navigate(`/images/${props.connector?.image?.id}`)
                break;
            default:
                throw new Error('How did this happen');
        }
    }

    return (
        <MenuButton
            options={ options }
            onClick={ handleClick }
            buttonIcon={ DotsVertical }
        />
    )
}

export class ConnectorTableMenuButton extends CustomMenuButton {
    
    constructor() {
        super('menu', 'connectorMenu', 'connector-menu', null, [], null)
    }

    render(row: Connector) {
        return (
            <ConnectorMenu
                connector={row}
            />
        )
    }

}

export default ConnectorMenu;