import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {ConnectorApi, ConnectorConnectorContainerListRequest, ConnectorCreateRequest, ConnectorDeleteRequest, ConnectorHistoryListRequest, ConnectorListRequest, ConnectorListResultModel, ConnectorLogsListRequest, ConnectorRetrieveRequest, ConnectorTasksRequest, ConnectorUpdateRequest} from "api";
import { ApiHandler } from './APIHandler';
import ConnectorActions, { ConnectorCreate, ConnectorDelete, ConnectorHistoryList, ConnectorList, ConnectorListInstances, ConnectorLogsList, ConnectorRetrieve, ConnectorTriggerJob, ConnectorUpdate } from 'reducers/types/Connector';
import { AppActions } from 'reducers/types/App';
import { TableParams } from 'models/table/TableParams';

export function* ConnectorSagas() {
    yield takeLatest(ActionTypes.API_CONNECTOR_UPDATE, ConnectorSaga.connectorUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_RETRIEVE, ConnectorSaga.connectorRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_LIST, ConnectorSaga.connectorListWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CREATE, ConnectorSaga.connectorCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_DELETE, ConnectorSaga.connectorDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_LIST_INSTANCES, ConnectorSaga.connectorListInstancesWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_TRIGGER_JOB, ConnectorSaga.connectorTriggerJobWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_LOGS_LIST, ConnectorSaga.connectorLogsListWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_HISTORY_LIST, ConnectorSaga.connectorHistoryListWorkerSaga);
    
}
export class ConnectorSaga {
    static *connectorUpdateWorkerSaga(action: ConnectorUpdate) {
        try {
            const connectorsArgs = {
                uuid: action.params.uuid,
                connectorModel: action.params.connectorModel
            } as ConnectorUpdateRequest

            const data = yield ApiHandler(new ConnectorApi().connectorUpdate, connectorsArgs);

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_UPDATE_FAILURE, error as Error))
        }
    }

    static *connectorRetrieveWorkerSaga(action: ConnectorRetrieve) {
        try {

            const connectorsArgs = {
                uuid: action.params
            } as ConnectorRetrieveRequest
            const data = yield ApiHandler(new ConnectorApi().connectorRetrieve, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_RETRIEVE_FAILURE, error as Error))
        }
    }

    static *connectorListWorkerSaga(action: ConnectorList) {
        try {

            const connectorsArgs = {
                connectorRetrieveListModel: action.params
            } as ConnectorListRequest
            const data = yield ApiHandler(new ConnectorApi().connectorList, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorListSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_LIST_FAILURE, error as Error))
        }
    }

    static async connectorList (tableParams?: TableParams): Promise<ConnectorListResultModel> {
        const retrieveListRequest = {
            connectorRetrieveListModel: Object.assign({}, tableParams.getApiFilter())
        } as ConnectorListRequest;
        return await new ConnectorApi().connectorList(retrieveListRequest);
    }

    static *connectorCreateWorkerSaga(action: ConnectorCreate) {
        try {
            const connectorsArgs = {
                connectorModel: action.params
            } as ConnectorCreateRequest
            const data = yield ApiHandler(new ConnectorApi().connectorCreate, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CREATE_FAILURE, error as Error))
        }
    }

    static *connectorDeleteWorkerSaga(action: ConnectorDelete) {
        try {
            const connectorsArgs = {
                uuid: action.params
            } as ConnectorDeleteRequest
            const data = yield ApiHandler(new ConnectorApi().connectorDelete, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_DELETE_FAILURE, error as Error))
        }
    }

    static *connectorListInstancesWorkerSaga(action: ConnectorListInstances) {
        try {
            const connectorsArgs = {
                uuid: action.params.uuid,
                connectorInstanceRetrieveListModel: action.params.connectorInstanceRetrieveListModel
            } as ConnectorConnectorContainerListRequest
            const data = yield ApiHandler(new ConnectorApi().connectorConnectorContainerList, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorActions.CreateConnectorListInstancesSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_LIST_INSTANCES_FAILURE, error as Error))
        }
    }

    static *connectorTriggerJobWorkerSaga(action: ConnectorTriggerJob) {
        try {
            const connectorArgs = {
                uuid: action.params.uuid,
                task: action.params.task,
                uuids: action.params.uuids?.bbIsEmpty() ? undefined : action.params.uuids
            } as ConnectorTasksRequest
            const data = yield ApiHandler(new ConnectorApi().connectorTasks, connectorArgs)
            yield put(ConnectorActions.CreateConnectorTriggerJobSuccess(data))
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_TRIGGER_JOB_FAILURE, error as Error))
        }
    }

    static *connectorLogsListWorkerSaga(action: ConnectorLogsList) {
        try {
            const tableParams = action.params.tableParams;
            const connectorArgs = {
                uuid: action.params.uuid,
                limit: tableParams.limit,
                offset: tableParams.offset,
                sortBy: tableParams.sorting?.column ?? 'started_at',
                sortDir: tableParams.sorting?.direction ?? 'desc',
                searchText: tableParams.search_text
            } as ConnectorLogsListRequest
            const data = yield ApiHandler(new ConnectorApi().connectorLogsList, connectorArgs);
            yield put(ConnectorActions.CreateConnectorLogsListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_LOGS_LIST_FAILURE, error as Error))
        }
    }

    static *connectorHistoryListWorkerSaga(action: ConnectorHistoryList) {
        try {
            const connectorArgs = {
                uuid: action.params?.uuid,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ConnectorHistoryListRequest

            const data = yield ApiHandler(new ConnectorApi().connectorHistoryList, connectorArgs);
            yield put(ConnectorActions.CreateConnectorHistoryListSuccess(data))
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}