/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ClientModel,
    ClientModelFromJSON,
    ClientModelFromJSONTyped,
    ClientModelToJSON,
    MailModel,
    MailModelFromJSON,
    MailModelFromJSONTyped,
    MailModelToJSON,
} from './';


export interface MailLogModelInterface {
    id?: number;
    mail_id?: number;
    mail?: MailModel;
    client_id?: number;
    client?: ClientModel;
    sender?: string;
    status?: MailLogModelStatusEnum;
    status_message?: string;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface MailLogModel
 */
export class MailLogModel extends BaseModel<MailLogModelInterface> {

    /**
     * id of the mail log
     * @type {number}
     * @memberof MailLogModel
     */
    id?: number;
    /**
     * The id of the mail the log belongs to
     * @type {number}
     * @memberof MailLogModel
     */
    mail_id?: number;
    /**
     * 
     * @type {MailModel}
     * @memberof MailLogModel
     */
    mail?: MailModel;
    /**
     * The id of the client the mail went to
     * @type {number}
     * @memberof MailLogModel
     */
    client_id?: number;
    /**
     * 
     * @type {ClientModel}
     * @memberof MailLogModel
     */
    client?: ClientModel;
    /**
     * The email adres the mail was sent from
     * @type {string}
     * @memberof MailLogModel
     */
    sender?: string;
    /**
     * The status of the mail
     * @type {string}
     * @memberof MailLogModel
     */
    status?: MailLogModelStatusEnum;
    /**
     * The message from mailgun
     * @type {string}
     * @memberof MailLogModel
     */
    status_message?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof MailLogModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof MailLogModel
     */
    updated_at?: Date;

    constructor(data?: MailLogModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MailLogModel, value: StringDateInterface<MailLogModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MailLogModelFromJSON(json: any): MailLogModel {
    return MailLogModelFromJSONTyped(json, false);
}

export function MailLogModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailLogModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MailLogModel;
    try {
        let localClass = require.context('../override', true)('./MailLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'mail_id': !exists(json, 'mail_id') ? undefined : Number(json['mail_id']),
        'mail': !exists(json, 'mail') ? undefined : MailModelFromJSON(json['mail']),
        'client_id': !exists(json, 'client_id') ? undefined : Number(json['client_id']),
        'client': !exists(json, 'client') ? undefined : ClientModelFromJSON(json['client']),
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'status_message': !exists(json, 'status_message') ? undefined : json['status_message'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function MailLogModelToJSON(value?: MailLogModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MailLogModel;
    try {
        let localClass = require.context('../override', true)('./MailLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'mail_id': value.mail_id,
        'mail': MailModelToJSON(value.mail),
        'client_id': value.client_id,
        'client': ClientModelToJSON(value.client),
        'sender': value.sender,
        'status': value.status,
        'status_message': value.status_message,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<MailLogModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum MailLogModelStatusEnum {
    NotSent = 'not_sent',
    Sent = 'sent',
    Read = 'read',
    Error = 'error'
}


