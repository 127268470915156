/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceConfigModelInterface {
    volumes?: object;
    databases?: Array<string>;
    has_port?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceConfigModel
 */
export class ServiceConfigModel extends BaseModel<ServiceConfigModelInterface> {

    /**
     * Volumes that are connected to the service
     * @type {object}
     * @memberof ServiceConfigModel
     */
    volumes?: object;
    /**
     * Databases that are connected to the service
     * @type {Array<string>}
     * @memberof ServiceConfigModel
     */
    databases?: Array<string>;
    /**
     * Service should be accessible from outside
     * @type {boolean}
     * @memberof ServiceConfigModel
     */
    has_port?: boolean;

    constructor(data?: ServiceConfigModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceConfigModel, value: StringDateInterface<ServiceConfigModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceConfigModelFromJSON(json: any): ServiceConfigModel {
    return ServiceConfigModelFromJSONTyped(json, false);
}

export function ServiceConfigModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceConfigModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceConfigModel;
    try {
        let localClass = require.context('../override', true)('./ServiceConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'volumes': !exists(json, 'volumes') ? undefined : json['volumes'],
        'databases': !exists(json, 'databases') ? undefined : json['databases'],
        'has_port': !exists(json, 'has_port') ? undefined : json['has_port'],
    });
}

export function ServiceConfigModelToJSON(value?: ServiceConfigModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceConfigModel;
    try {
        let localClass = require.context('../override', true)('./ServiceConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'volumes': value.volumes,
        'databases': value.databases,
        'has_port': value.has_port,
    } as StringDateInterface<ServiceConfigModel>;
    return createClass.toJson(value, model);
}


