import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Stepview from 'api/override/StepviewModel';
import { retrieveStepviewSchema } from 'schemas/Stepview';
import StepviewWidgetSelect from './StepviewWidgetSelect';
import StepviewPreview from './StepviewPreview';
import { FormikProps } from 'formik';
import Widget from 'api/override/WidgetModel';
import { useDispatch, useSelector } from 'react-redux';
import { BaseAction } from 'reducers/interface/ReducerAction';
import StepviewActions, { StepviewRetrieveSuccess } from 'reducers/types/Stepview';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import { RootState } from 'store/AppStore';
import { Backdrop, CircularProgress } from '@mui/material';
import StepviewWidget from 'api/override/StepviewWidgetModel';

interface StepviewDetailsDialogProps extends BaseFormModalProps<Stepview> {
    stepviewId: number;
}

const StepviewDetailsDialog = (props: StepviewDetailsDialogProps) => {

    const { stepviewId } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const [widgets, setWidgets] = useState<Array<Widget>>([])
    const [stepview, setStepview] = useState<Stepview>(undefined)
    const dispatch = useDispatch()
    const isFetching = useSelector((state: RootState) => state.widgets.isAjaxListLoading)

    const handleComponentDidMount = () => {
        fetchStepview()
        Widget.getWidgets().then(assignWidgets);
        const stepviewFetched = new NCListener(ActionTypes.API_STEPVIEW_RETRIEVE_SUCCESS, (action: BaseAction) => onStepviewRetrieveSuccess(action as StepviewRetrieveSuccess))
        NotificationCenter.default.addNCListener(stepviewFetched)
        return () => NotificationCenter.default.removeListener(stepviewFetched)
    }

    const assignWidgets = (widgets: Array<Widget>) => {
        setWidgets(widgets)
    }
    // eslint-disable-next-line    
    React.useEffect(handleComponentDidMount, [])

    const fetchStepview = () => {
        dispatch(StepviewActions.CreateStepviewRetrieve(stepviewId))
    }

    const onStepviewRetrieveSuccess = (action: StepviewRetrieveSuccess) => {
        setStepview(new Stepview(action.params))
    }
    
    const onAddNewWidget = (formik: FormikProps<Stepview>) => (widget: Widget) => {
        const stepviewWidgetToBeAdded: StepviewWidget = new StepviewWidget({
            widget_id: widget.id, 
            stepview_id: stepviewId, 
            widget: widget,
            position: formik.values.stepview_widgets?.length + 1,
        })
        formik.setFieldValue('stepview_widgets', [...formik.values.stepview_widgets, stepviewWidgetToBeAdded])
    }

    return (
        <>
        {stepview == null ?
            <Backdrop className={classes.backdrop} style={{zIndex: 1501}} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
            :
            <BaseFormModal {...props} title={t('global.title.details_model', {model: t('stepviews.model')})}
                initialValues={stepview ?? new Stepview()}
                validationSchema={retrieveStepviewSchema()}
                action={ActionTypes.API_STEPVIEW_UPDATE}
                onSubmit={props.onModalSubmit}
                renderForm={(formik: FormikProps<Stepview>) => 
                    <div className={classes.fields_container}>
                        <TextFieldStyled 
                            id="name" 
                            name="name"
                            label={t('general.name')}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                        {!isFetching ?
                            <>
                                <StepviewWidgetSelect onAdd={onAddNewWidget(formik)} widgets={widgets}/>
                                <StepviewPreview formik={formik} widgets={widgets}/>
                            </>
                            :
                            <CircularProgress color="inherit" />
                        }
                        
                    </div>
                }
            />
        }
        </>
    );
};

export default StepviewDetailsDialog;