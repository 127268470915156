declare global {
    interface Date {
        /**
         * Adds the provided number of days to a date object
         * @params number of days to be added
         * @returns a date object with the added number of days
         */
        bbAddDays(numberOfDays: number): Date;
    }
}

// eslint-disable-next-line
Date.prototype.bbAddDays = function(numberOfDays: number): Date {
    return new Date(new Date(this).setDate(this.getDate() + numberOfDays)) // create new date - do not mutate the old one
}

export default Date