/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    DashboardWidgetResponseModel,
    DashboardWidgetResponseModelFromJSON,
    DashboardWidgetResponseModelToJSON,
} from '../models';

export interface RetrieveDataRequest {
    widgets?: Array<string>;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     * Get the names of all the available dashboard widgets
     * Get the names of all the available dashboard widgets
     */
    async getAvailableRaw(): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dashboard/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Get the names of all the available dashboard widgets
     * Get the names of all the available dashboard widgets
     */
    getAvailable = async(): Promise<ApiSuccessResponseModel> => {
        const response = await this.getAvailableRaw();
        return await response.value();
    }

    /**
     * Get all of the data for the dashboard widgets
     * Get all of the data for the dashboard widgets
     */
    async retrieveDataRaw(requestParameters: RetrieveDataRequest): Promise<runtime.ApiResponse<DashboardWidgetResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.widgets) {
            queryParameters['widgets[]'] = requestParameters.widgets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardWidgetResponseModelFromJSON(jsonValue));
    }

    /**
     * Get all of the data for the dashboard widgets
     * Get all of the data for the dashboard widgets
     */
    retrieveData = async(requestParameters: RetrieveDataRequest): Promise<DashboardWidgetResponseModel> => {
        const response = await this.retrieveDataRaw(requestParameters);
        return await response.value();
    }

}
