import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import DatabaseTransferActions, { DatabaseTransfer, ValidateInputs } from 'reducers/types/DatabaseTransfer';
import { SystemApi, SystemCopyDatabaseRequest, SystemCopyDatabaseVerifyRequest } from 'api';

export function* DatabaseTransferSagas() {
    yield takeLatest(ActionTypes.API_DB_TRANSFER_VALIDATE, DatabaseTransferSaga.validateInputsWorkerSaga);
    yield takeLatest(ActionTypes.API_DB_TRANSFER_START, DatabaseTransferSaga.databaseTransferWorkerSaga);
}

export class DatabaseTransferSaga {
    
    static *validateInputsWorkerSaga(action: ValidateInputs) {
        try {
            const transferArgs = {
                transferVerifyRequestModel: action.params.transferVerifyRequestModel
            } as SystemCopyDatabaseVerifyRequest
            const data = yield ApiHandler(new SystemApi().systemCopyDatabaseVerify, transferArgs);
    
            yield put(DatabaseTransferActions.CreateValidateInputsSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_DB_TRANSFER_VALIDATE_FAILURE, error as Error))
        }
    }

    static *databaseTransferWorkerSaga(action: DatabaseTransfer) {
        try {
            const transferArgs = {
                transferRequestModel: action.params.transferRequestModel
            } as SystemCopyDatabaseRequest
            const data = yield ApiHandler(new SystemApi().systemCopyDatabase, transferArgs);
    
            yield put(DatabaseTransferActions.CreateDatabaseTransferSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_DB_TRANSFER_START_FAILURE, error as Error))
        }
    }
}

