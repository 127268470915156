import Property from "api/override/PropertyModel";
import { TableParams } from "models/table/TableParams";
import { PropertySaga } from "sagas/PropertySagas";
import Log from "utils/Log";
import BaseController from "./BaseController";

export default class PropertyController extends BaseController<Property> {

    listProperties = async (params?: TableParams): Promise<Array<Property>> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await PropertySaga.propertyList(params);
                this.cacheResponseOfType(response.items as Array<Property> ?? [], params);
            }
            return this.getItemsOfTypeForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }
}