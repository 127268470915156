import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn from 'models/table/FilterColumn';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { FolderTableOutline } from 'mdi-material-ui';

import {  PropertyRetrieveListModel } from 'api';
import PropertyState from 'reducers/interface/PropertyState';
import Property from 'api/override/PropertyModel';
import PropertyActions from 'reducers/types/Property';
import HideOnProd from 'components/HideOnProd';

interface StateProps {
    propertyReducer: PropertyState;
    appReducer: AppState;
}

interface DispatchProps {
    listProperties: (params: PropertyRetrieveListModel) => void;
    clearProperties: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    property?: Property;
}

class PropertyList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);

        this.state = {
            property: undefined
        };
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name') }),
            new TableColumn({ key: 'type', isSortable: true, label: i18next.t('general.type') }),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_PROPERTY_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('properties.model_plural'))
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.propertyReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listProperties( this.getParams().getApiFilter() )
        }
    }

    onDetails = (row?: Property) => {
        this.props.navigate(`/properties/${row?.id}`)
    }

    onCreate = () => {
        this.props.navigate("/properties/create")
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('properties', this.props.location)){
            this.props.clearProperties();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <HideOnProd>
                <React.Fragment>
                    <ReloadDataButton loadData={this.reloadData} />
                    <Container maxWidth={"lg"}>
                        <Card className={classes.root}>
                            <TableHeader
                                entity={i18next.t('properties.model')}
                                headerIcon={<FolderTableOutline/>}
                                onCreate={this.onCreate}
                            />
                            <CardContent className={classes.table_container}>
                                <TableFilter
                                    searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('properties.model_plural')}) }
                                    searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('properties.model_plural')}) }
                                    onFilterChange={ this.setFilters }
                                    columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                    searchText={this.tableParams?.search_text}
                                />
                                <Grid item xs={12} >
                                    <TableList
                                        hasDetailsAction
                                        columns={this.columns}
                                        count={this.props.propertyReducer.collection.count_filtered}
                                        listItems={this.props.propertyReducer.collection.items}
                                        isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_PROPERTY_LIST)}
                                        tableParams={this.getParams()}
                                        delegate={ this } />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                </React.Fragment>
            </HideOnProd>

        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            
        ]
    }

}

const  mapStateToProps = (state: any) => ({
    propertyReducer: state.properties,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listProperties: (params: PropertyRetrieveListModel) => dispatch(PropertyActions.CreatePropertyList(params)),
        clearProperties: (params: boolean) => dispatch(PropertyActions.CreateClearPropertyList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(PropertyList, TableStyles)
            )
        )
    )
);