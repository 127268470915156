/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ProcessLogModelInterface {
    type?: ProcessLogModelTypeEnum;
    message?: string;
    associated_step?: string;
    additional_data?: string;
    created_at?: Date;
}

/**
 * 
 * @export
 * @interface ProcessLogModel
 */
export class ProcessLogModel extends BaseModel<ProcessLogModelInterface> {

    /**
     * UUID which identifies this Process
     * @type {string}
     * @memberof ProcessLogModel
     */
    type?: ProcessLogModelTypeEnum;
    /**
     * Log message
     * @type {string}
     * @memberof ProcessLogModel
     */
    message?: string;
    /**
     * Class name of the ossociated step
     * @type {string}
     * @memberof ProcessLogModel
     */
    associated_step?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessLogModel
     */
    additional_data?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessLogModel
     */
    created_at?: Date;

    constructor(data?: ProcessLogModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ProcessLogModel, value: StringDateInterface<ProcessLogModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ProcessLogModelFromJSON(json: any): ProcessLogModel {
    return ProcessLogModelFromJSONTyped(json, false);
}

export function ProcessLogModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessLogModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ProcessLogModel;
    try {
        let localClass = require.context('../override', true)('./ProcessLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'associated_step': !exists(json, 'associated_step') ? undefined : json['associated_step'],
        'additional_data': !exists(json, 'additional_data') ? undefined : json['additional_data'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
    });
}

export function ProcessLogModelToJSON(value?: ProcessLogModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ProcessLogModel;
    try {
        let localClass = require.context('../override', true)('./ProcessLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'type': value.type,
        'message': value.message,
        'associated_step': value.associated_step,
        'additional_data': value.additional_data,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
    } as StringDateInterface<ProcessLogModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum ProcessLogModelTypeEnum {
    Success = 'success',
    Notice = 'notice',
    Warning = 'warning',
    Error = 'error'
}


