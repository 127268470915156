/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    MailLogModel,
    MailLogModelFromJSON,
    MailLogModelFromJSONTyped,
    MailLogModelToJSON,
} from './';


export interface MailLogListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<MailLogModel>;
}

/**
 * 
 * @export
 * @interface MailLogListResultModel
 */
export class MailLogListResultModel extends BaseModel<MailLogListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof MailLogListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof MailLogListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<MailLogModel>}
     * @memberof MailLogListResultModel
     */
    items?: Array<MailLogModel>;

    constructor(data?: MailLogListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MailLogListResultModel, value: StringDateInterface<MailLogListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MailLogListResultModelFromJSON(json: any): MailLogListResultModel {
    return MailLogListResultModelFromJSONTyped(json, false);
}

export function MailLogListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailLogListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MailLogListResultModel;
    try {
        let localClass = require.context('../override', true)('./MailLogListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(MailLogModelFromJSON)),
    });
}

export function MailLogListResultModelToJSON(value?: MailLogListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MailLogListResultModel;
    try {
        let localClass = require.context('../override', true)('./MailLogListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MailLogModelToJSON)),
    } as StringDateInterface<MailLogListResultModel>;
    return createClass.toJson(value, model);
}


