/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelFromJSONTyped,
    InstanceModelToJSON,
} from './';


export interface InstanceListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<InstanceModel>;
}

/**
 * 
 * @export
 * @interface InstanceListResultModel
 */
export class InstanceListResultModel extends BaseModel<InstanceListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof InstanceListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof InstanceListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<InstanceModel>}
     * @memberof InstanceListResultModel
     */
    items?: Array<InstanceModel>;

    constructor(data?: InstanceListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceListResultModel, value: StringDateInterface<InstanceListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceListResultModelFromJSON(json: any): InstanceListResultModel {
    return InstanceListResultModelFromJSONTyped(json, false);
}

export function InstanceListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(InstanceModelFromJSON)),
    });
}

export function InstanceListResultModelToJSON(value?: InstanceListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(InstanceModelToJSON)),
    } as StringDateInterface<InstanceListResultModel>;
    return createClass.toJson(value, model);
}


