/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    InstanceListFilterModel,
    InstanceListFilterModelFromJSON,
    InstanceListFilterModelFromJSONTyped,
    InstanceListFilterModelToJSON,
} from './';


export interface InstanceRetrieveListModelInterface {
    filters?: InstanceListFilterModel;
    search_text?: string | null;
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
}

/**
 * 
 * @export
 * @interface InstanceRetrieveListModel
 */
export class InstanceRetrieveListModel extends BaseModel<InstanceRetrieveListModelInterface> {

    /**
     * 
     * @type {InstanceListFilterModel}
     * @memberof InstanceRetrieveListModel
     */
    filters?: InstanceListFilterModel;
    /**
     * Search String
     * @type {string}
     * @memberof InstanceRetrieveListModel
     */
    search_text?: string | null;
    /**
     * Offset
     * @type {number}
     * @memberof InstanceRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof InstanceRetrieveListModel
     */
    limit?: number;
    /**
     * Sort by Column
     * @type {string}
     * @memberof InstanceRetrieveListModel
     */
    sort_by?: string;
    /**
     * Sort direction
     * @type {string}
     * @memberof InstanceRetrieveListModel
     */
    sort_direction?: string;

    constructor(data?: InstanceRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceRetrieveListModel, value: StringDateInterface<InstanceRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceRetrieveListModelFromJSON(json: any): InstanceRetrieveListModel {
    return InstanceRetrieveListModelFromJSONTyped(json, false);
}

export function InstanceRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./InstanceRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'filters': !exists(json, 'filters') ? undefined : InstanceListFilterModelFromJSON(json['filters']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'sort_by': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sort_direction': !exists(json, 'sort_direction') ? undefined : json['sort_direction'],
    });
}

export function InstanceRetrieveListModelToJSON(value?: InstanceRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./InstanceRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'filters': InstanceListFilterModelToJSON(value.filters),
        'search_text': value.search_text,
        'offset': value.offset,
        'limit': value.limit,
        'sort_by': value.sort_by,
        'sort_direction': value.sort_direction,
    } as StringDateInterface<InstanceRetrieveListModel>;
    return createClass.toJson(value, model);
}


