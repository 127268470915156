import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TokenUtil from 'utils/TokenUtil';
import Connector from 'api/override/ConnectorModel';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import ImageController from 'controllers/ImageController';
import ImageVersionController from 'controllers/ImageVersionController';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ReadOnlyField from 'components/ReadOnlyField';
import { ImageModelForModelEnum } from 'api';
import StatusSingleSelect from 'components/form_controls/StatusSingleSelect';
import FormErrorTextStyled from 'components/styled/FormErrorTextStyled';

interface ConnectorDetailsDialogProps extends BaseFormModalProps<Connector> {
    connector?: Connector;
}

const ConnectorDetailsDialog = (props: ConnectorDetailsDialogProps) => {

    const { connector } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const isAdmin: boolean = TokenUtil.getTokenUtil().isAccountManager()
    const [imageController] = useState<ImageController>(new ImageController())
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('connector.model')})}
            initialValues={new Connector({
                ...connector,
                text: connector?.text ?? ''
            })}
            action={ActionTypes.API_CONNECTOR_CREATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>

                    <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('connector.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        disabled={!isAdmin}
                    />
                    
                    { formik.values.hasRedirect() ? (
                        <StatusSingleSelect formik={formik as any} />
                    ) : (
                        <ReadOnlyField
                            label={t('instance.status')}
                            value={t('general.no_status')}
                            disabled
                        />
                    )}

                    <ReadOnlyField 
                        id="url" 
                        name="url"
                        label={t('connector.url')}
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        disabled
                    />

                    <ReadOnlyField 
                        id="namespace" 
                        name="namespace"
                        label={t('connector.namespace')}
                        value={formik.values.namespace}
                        onChange={formik.handleChange}
                        disabled
                    />

                    <TextFieldStyled 
                        id="redirect_uri" 
                        name="redirect_uri"
                        label={t('connector.redirect_uri')}
                        value={formik.values.redirect_uri}
                        onChange={formik.handleChange}
                        disabled
                    />

                    <ImageSingleSelect 
                        disabled={!isAdmin}
                        id='image'
                        name='image_id'
                        controller={imageController}
                        formik={formik}
                        label={t('connector.image')}
                        model={[ImageModelForModelEnum.Connector, ImageModelForModelEnum.ConnectorContainer]}
                    />
                    { formik.values.image_id !== formik.initialValues.image_id ? (
                        <FormErrorTextStyled isWarning message={t('service.edit_image_warning')}/>
                    ) : (null)}

                    { connector.hasRedirect() ? (
                        <>
                            <ImageSingleSelect 
                                disabled={!isAdmin}
                                id='redirect_image'
                                name='redirect_image_id'
                                controller={imageController}
                                formik={formik}
                                label={t('connector.redirect_image')}
                                model={[ImageModelForModelEnum.Connector, ImageModelForModelEnum.ConnectorContainer]}
                            />

                            <ImageVersionSingleSelect 
                                id='redirect_image_version'
                                name='redirect_image_version_id'
                                controller={imageVersionController}
                                formik={formik}
                                imageId={formik.values.redirect_image_id}
                                disabled={formik.values?.redirect_image_id == null}
                                label={t('connector.redirect_image_version')}
                            />
                        </>
                    ) : (null)}

                </div>
            }
        />
    );
};

export default ConnectorDetailsDialog;