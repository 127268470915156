import React from 'react';
import BaseModal, { BaseModalProps } from 'components/base/BaseModal';
import NCNotificationModel from 'models/NCNotificationModel';
import {format} from "date-fns";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useProcessPanelStyles from 'styles/ProcessPanelStyles';

export interface ProcessLogModalProps extends BaseModalProps {
    notifications: NCNotificationModel[]
}

function ProcessLogModal(props: ProcessLogModalProps): JSX.Element {

    const {classes} = useProcessPanelStyles();
    const getTimestamp = (timestamp: Date): string => {
        return format(timestamp, 'H:mm:ss')
    }
    const getStatusIcon = (status: string): JSX.Element => {
        switch (status) {
            case "info":
                return <InfoIcon className={classes.status_icon_info}/>
            case "success":
                return <CheckCircleIcon className={classes.status_icon_success}/>
            case "warning":
                return <WarningIcon className={classes.status_icon_warning}/>
            case "error":
                return <ErrorIcon className={classes.status_icon_error}/>
            default:
                break;
        }
    }
    return (
        <BaseModal open={props.open} onModalClosePress={props.onModalClosePress} title={props.title}>
            {props.notifications.map((notification, i) => { 
                    return ( 
                        <div key={`progress_step${i}`} className={classes.global_progress_expanded_container}>
                            <p className={classes.modal_notification}>
                                {getStatusIcon(notification.params?.status)} [{getTimestamp(notification.params?.data?.timestamp)}]: {notification.params?.message}
                            </p>
                        </div> 
                    );
                })
            }
        </BaseModal>
    );
};

export default ProcessLogModal;