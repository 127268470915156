import React from 'react'
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import useStyledComponentsStyles from 'styles/StyledComponentsStyles';
import { useTranslation } from 'react-i18next';


const TextareaStyled = (props: TextareaAutosizeProps) => {
    const {classes} = useStyledComponentsStyles();
    const { t } = useTranslation();

    return (
        <TextareaAutosize minRows={3} className={classes.textarea} placeholder={props?.placeholder ?? t('global.form.textarea_placeholder')} {...props}/>
    )
}

export default TextareaStyled