import { Button, IconButton, SvgIcon, Tooltip } from '@mui/material';
import MenuButton from 'components/MenuButton';
import React from 'react';
import { Link } from 'react-router-dom';

export default abstract class CustomActionButton {
    name: string
    description?: string
    ariaLabel: string
    onClick?: (name?: string, row?: any) => void
    size?: 'small' | 'medium'
    color?: 'inherit' | 'primary' | 'secondary';

    constructor(name: string, description: string, ariaLabel: string, onClick?: (name: string, row: any) => void, size: 'small' | 'medium' = 'small', color: 'inherit' | 'primary' | 'secondary' = 'primary') {
        this.name = name
        this.description = description
        this.ariaLabel = ariaLabel
        this.onClick = onClick;
        this.size = size
        this.color = color
    }

    abstract render(row?: any): JSX.Element;
}

export class CustomAlertButton extends CustomActionButton {
    constructor(name: string, description: string, ariaLabel: string, onClick?: () => void, color: 'inherit' | 'primary' | 'secondary' = 'primary', size: 'small' | 'medium' = 'medium') {
        super(name, description, ariaLabel, onClick, size, color);
    }

    render() {
        return (
            <Button size={this.size} aria-label={this.ariaLabel} color={this.color} onClick={() => this.onClick()}>
                {this.name}
            </Button>
        )
    }
}

export class CustomIconButton extends CustomActionButton {

    buttonIcon: typeof SvgIcon;

    constructor(name: string, description: string, ariaLabel: string, onClick: (name?: string, row?: any) => void, buttonIcon: typeof SvgIcon, size: 'small' | 'medium' = 'small', color: 'inherit' | 'primary' | 'secondary' = 'primary') {
        super(name, description, ariaLabel, onClick, size, color);
        this.buttonIcon = buttonIcon;
    }

    render(row?: any, additionalEntity?: any) {
        return (
            <Tooltip title={this.description}>
                <IconButton size={this.size} aria-label={this.ariaLabel} color={this.color} onClick={this.name && row ? () => this.onClick(this.name, row) : () => this.onClick()}>
                    <this.buttonIcon />
                </IconButton>
            </Tooltip>
        )
    }

}

export class CustomLinkButton extends CustomActionButton {

    baseURL: string;

    constructor(name: string, description: string, ariaLabel: string, baseURL: string, size: 'small' | 'medium' = 'small', color: 'inherit' | 'primary' | 'secondary' = 'primary') {
        super(name, description, ariaLabel);
        this.baseURL = baseURL
    }

    render() {
        return (
            <Button component={Link} to={this.baseURL} size={this.size} color={this.color} aria-label={this.ariaLabel}>
                {this.name}
            </Button>
        )
    }
}

export class CustomLinkIconButton extends CustomIconButton {

    baseURL: string;
    key: string;
    suffix: string;

    constructor(name: string, description: string, ariaLabel: string, onClick: (name?: string, row?: any) => void, buttonIcon: typeof SvgIcon, baseURL: string, key: string, suffix?: string, size: 'small' | 'medium' = 'small', color: 'inherit' | 'primary' | 'secondary' = 'primary') {
        super(name, description, ariaLabel, null, buttonIcon, size, color);
        this.baseURL = baseURL;
        this.key = key;
        this.suffix = suffix;
    }

    render(row: any) {
        if (row[this.key] == null) {
            console.error(`${this.constructor.name} => Key '${this.key}' not found in row: `, row);
            return null;
        }
        const redirectLink: string = Boolean(this.suffix) ? this.baseURL + row[this.key] + this.suffix : this.baseURL + row[this.key]
        return (
            <Tooltip title={this.description}>
                <IconButton component={Link} to={redirectLink} size={this.size} color={this.color} aria-label={this.ariaLabel} onClick={this.name && row ? () => this.onClick?.(this.name, row) : () => this.onClick?.()}>
                    <this.buttonIcon />
                </IconButton>
            </Tooltip>
        )
    }

}

export class CustomMenuButton extends CustomIconButton {
    options: string[];
    anchorElem: HTMLElement;
    additionalEntity: any;

    constructor(name: string, description: string, ariaLabel: string, buttonIcon: typeof SvgIcon, options: string[], onClick: (name?: string, row?: any) => void, key?: string | '', size: 'small' | 'medium' = 'small', color: 'inherit' | 'primary' | 'secondary' = 'primary', additionalEntity?: any) {
        super(name, description, ariaLabel, onClick, buttonIcon, size, color);
        this.options = options;
        this.additionalEntity = additionalEntity
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.anchorElem = event.currentTarget;
    };

    handleClose = () => {
        this.anchorElem = null;
    };

    handleMenuItemClick = (option: string, row: any) => {
        this.onClick?.(option, row);
    }

    render(row: any, additionalEntity: any) {
        return (
            <MenuButton
                options={ this.options }
                buttonIcon={ this.buttonIcon }
                onClick={(option: string) => {
                    this.handleMenuItemClick( option, row );
                }}
            />
        )
    }
}