import React from "react";
import { Theme } from '@mui/material/styles';
import { Tab, Tabs, TabsProps } from "@mui/material";
import TabPanel from 'components/tabs/TabPanel';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from "tss-react";

const PREFIX = 'bbTabManager';

const classes = {
    bbTabManagerRoot: `${PREFIX}-bbTabManagerRoot`,
    bbTabScroller: `${PREFIX}-bbTabScroller`,
    bbTab: `${PREFIX}-bbTab`,
    bbTabPanelRoot: `${PREFIX}-bbTabPanelRoot`
};

export interface TabView {
    title: string;
    view: JSX.Element;
    initialTab?: boolean;
    link?: string;
    hidden?: boolean;
    disabled?: boolean;
}

export interface TabManagerProps {
    tabsProps?: TabsProps,
    items: TabView[];
    onShouldTabSwitch?: (toTab: TabView, fromTab: TabView) => boolean;
    onTabSwitch?: (toTab: TabView, fromTab: TabView) => void;
}
const useTabManagerStyles = makeStyles()((theme: Theme) => {
    return {
        [`&.${classes.bbTabManagerRoot}`]: {
            display: 'grid',
            overflow: 'hidden',
            gridTemplateRows: 'min-content auto',
            gridTemplateAreas: `
                'tabs'
                'tabpanel'
            `,
            gridTemplateColumns: '1fr',
            height: '100%',
        } as CSSObject,
    
        [`& .${classes.bbTabScroller}`]: {
            color: theme.palette.primary.dark,
            position: 'relative',
    
            '& .MuiTabScrollButton-root': {
                position: 'absolute',
                bottom: 0,
                height: '100%',
                zIndex: 10,
                background: 'white',
            },
            '& .MuiTabScrollButton-root:first-of-type': {
                left: 0,
                background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 86%, rgba(255,255,255,0) 100%)'
            },
            '& .MuiTabScrollButton-root:last-of-type': {
                right: 0,
                background: 'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 86%, rgba(255,255,255,0) 100%)'
            }
        } as CSSObject,
    
        [`& .${classes.bbTab}`]: {
            minWidth: 'initial',
            padding: '4px 40px'
        } as CSSObject,
    
        [`& .${classes.bbTabPanelRoot}`]: {
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            background: '#fafafa',
            position: 'relative',
            '&:before': {
                boxShadow: '0px -10px 16px 6px #B4B4B4',
                content: '\'\'',
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0,
            },
            '&.hiddenRender': {
                display: 'none'
            }
        } as CSSObject
    }
});

const TabManager = (props: TabManagerProps) => {
    const {classes} = useTabManagerStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = React.useState( Math.max(props.items.filter(tab => tab.hidden !== true).findIndex(item => item.initialTab === true && item.disabled !== true && item.hidden !== true), 0) );
    const tabItems: Array<TabView>= props.items.filter(tab => tab.hidden !== true);
    const updateUrlOnDisabledTab = () => {
        if(location.pathname !== tabItems[activeTab]?.link) { // change path in history in case the user inputs a URL of a disabled tab manually
            navigate(tabItems[activeTab]?.link)
        }
    }
    // eslint-disable-next-line
    React.useEffect(updateUrlOnDisabledTab, [])
    
    React.useEffect(() => {
        if(props.tabsProps && props.tabsProps.value) {
            setActiveTab(props.tabsProps.value||0);
        }
    }, [props.tabsProps])

    const handleTabSwitch = (newIndex: number) => {
        const currentTab = tabItems[activeTab];
        const newTab = tabItems[newIndex];
        const shouldSwitch = props.onShouldTabSwitch != null ? props.onShouldTabSwitch?.(newTab, currentTab) : true;
        if (shouldSwitch) {
            setActiveTab(newIndex);
            props.onTabSwitch?.(newTab, currentTab);
        }
    }
    const onChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        handleTabSwitch(newValue);
    };
    const onUrlChange = () => {
        const newIndex = tabItems.findIndex(tab => tab.link === location.pathname);
        if (newIndex > -1) {
            handleTabSwitch(newIndex)
        }
    }
    // eslint-disable-next-line
    React.useEffect(onUrlChange, [location])
    const a11yTabProps = (index: any) =>  {
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
    }

    const a11yTabpanelProps = (index: any) => {
        return {
          id: `tabpanel-${index}`,
          'aria-labelledby': `tab-${index}`,
        };
    }

    return (
        <div className={classes.bbTabManagerRoot}>
            <Tabs
                value={activeTab}
                onChange={onChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.bbTabScroller}
                {...props.tabsProps}
                >
                { tabItems.map( (tabItem: TabView, index: number) => {
                    if (tabItem.link != null) {
                        return (
                            <Tab className={classes.bbTab} key={`tab-${tabItem.title}`} label={ tabItem.title } {...a11yTabProps( index )} component={Link} to={ tabItem.link } disabled={tabItem?.disabled}/>
                        )
                    } else {
                        return (
                            <Tab className={classes.bbTab} key={`tab-${tabItem.title}`} label={ tabItem.title } {...a11yTabProps( index )} disabled={tabItem?.disabled}/>
                        )
                    }
                } ) }
            </Tabs>
            { tabItems.map( (tabItem: TabView, index: number) => (
                <TabPanel className={classes.bbTabPanelRoot} key={`tabpanel-${tabItem.title}`} hidden={ activeTab !== index } {...a11yTabpanelProps( index )}>
                    <div style={{position: 'relative'}}>
                    { tabItem.view }
                    </div>
                </TabPanel>
            ) ) }
        </div>
    );
}


export default TabManager;