import Instance from "api/override/InstanceModel";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import { InstanceSaga } from "sagas/InstanceSagas";
import Log from "utils/Log";
import DispatchController from "./DispatchController";
import { InstanceApi } from "api";

export default class InstanceController extends DispatchController<Instance> {

    modelName: string = 'instance';
    getApi(): InstanceApi {
        return new InstanceApi();
    }

    instanceToMultiselect = async (params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await InstanceSaga.instanceList(params);
                this.cacheResponse(response.items as Instance[] ?? [], params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }

    redoployAllFrontends = async() => {
        try {
            this.getApi().instanceRedeployFrontend();
        } catch (e) {
            Log(e)
        }
    }

    redeployAllBackends = async() => {
        try {
            this.getApi().instanceRedeployBackend();
        } catch (e) {
            Log(e)
        }
    }

}