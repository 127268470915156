/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ConnectorContainerRemoveSuccessModelInterface {
    uuid?: string;
    process_token?: string;
    message?: string;
}

/**
 * 
 * @export
 * @interface ConnectorContainerRemoveSuccessModel
 */
export class ConnectorContainerRemoveSuccessModel extends BaseModel<ConnectorContainerRemoveSuccessModelInterface> {

    /**
     * Unique per Connector Container
     * @type {string}
     * @memberof ConnectorContainerRemoveSuccessModel
     */
    uuid?: string;
    /**
     * Remove Process Token
     * @type {string}
     * @memberof ConnectorContainerRemoveSuccessModel
     */
    process_token?: string;
    /**
     * An important message from The System
     * @type {string}
     * @memberof ConnectorContainerRemoveSuccessModel
     */
    message?: string;

    constructor(data?: ConnectorContainerRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorContainerRemoveSuccessModel, value: StringDateInterface<ConnectorContainerRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorContainerRemoveSuccessModelFromJSON(json: any): ConnectorContainerRemoveSuccessModel {
    return ConnectorContainerRemoveSuccessModelFromJSONTyped(json, false);
}

export function ConnectorContainerRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorContainerRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorContainerRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'process_token': !exists(json, 'process_token') ? undefined : json['process_token'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function ConnectorContainerRemoveSuccessModelToJSON(value?: ConnectorContainerRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorContainerRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'process_token': value.process_token,
        'message': value.message,
    } as StringDateInterface<ConnectorContainerRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


