/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    UserModel,
    UserModelFromJSON,
    UserModelFromJSONTyped,
    UserModelToJSON,
} from './';


export interface UserListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<UserModel>;
}

/**
 * 
 * @export
 * @interface UserListResultModel
 */
export class UserListResultModel extends BaseModel<UserListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof UserListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof UserListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserListResultModel
     */
    items?: Array<UserModel>;

    constructor(data?: UserListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: UserListResultModel, value: StringDateInterface<UserListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function UserListResultModelFromJSON(json: any): UserListResultModel {
    return UserListResultModelFromJSONTyped(json, false);
}

export function UserListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserListResultModel;
    try {
        let localClass = require.context('../override', true)('./UserListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(UserModelFromJSON)),
    });
}

export function UserListResultModelToJSON(value?: UserListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserListResultModel;
    try {
        let localClass = require.context('../override', true)('./UserListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(UserModelToJSON)),
    } as StringDateInterface<UserListResultModel>;
    return createClass.toJson(value, model);
}


