import {PackageModel, PackageModelInterface, PackagePostModelInterface, StringDateInterface} from "api";
import Historiable from "interface/Historiable";
import Service from "./ServiceModel";
import MultiSelectOption from "interface/MultiSelectOption";

export default class Package extends PackageModel implements Historiable, MultiSelectOption {

    actionName: string = "package"
    reducerName: string = "packages"

    label: string = this.name
    value: number = this.id

    services: Service[] = [];

    service_uuids: Array<string>;

    constructor(data?: PackageModelInterface) {
        super(data);

        this.services = data?.services as Service[] ?? []
        this.service_uuids = this.populateServiceUuids()
    }

    populateServiceUuids() {
        return this.services.map((service: Service) => service.uuid)
    }

    static toJson(initValue: Package, value: StringDateInterface<Package>): StringDateInterface<PackagePostModelInterface> {
        return { 
            ...value,
            service_uuids: initValue.populateServiceUuids()
        };
    }
}