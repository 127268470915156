import React, { createRef, CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { ChipProps, CircularProgress, createFilterOptions, IconButton } from '@mui/material';
import MultiSelectOption from 'interface/MultiSelectOption';
import { TableParams } from 'models/table/TableParams';
import EditIcon from '@mui/icons-material/Edit';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { ChevronDown, ChevronUp } from 'mdi-material-ui';

export interface BaseSelectProps {
    id?: string;
    label: string;
    name: string;
    multiple?: boolean;
    creatable?: boolean;
    createText?: string;
    editable?: boolean;
    disabled?: boolean;
    creatableModalHandleOpen?: () => void;
    getAsyncValuesOnSearch?: boolean;
    disablePaging?: boolean;
    tableParams?: TableParams;
    options?: MultiSelectOption[];
    value: MultiSelectOption[] | MultiSelectOption;
    placeholderValue?: MultiSelectOption;
    chipProps?: ChipProps;
    getAsyncOptions?: (params?: TableParams) => Promise<MultiSelectOption[]>;
    onChange: (name: React.ChangeEvent<{}>, value?: any) => void;
    formikError?: boolean;
    formikHelperText?: string;
    autocompleteStyle?: CSSProperties;
    textFieldStyles?: CSSProperties;
    size?: 'small' | 'medium'
}

export interface BaseSelectRef {
    clearOptions: () => void;
}

const BaseSelect = forwardRef((props: BaseSelectProps, ref) => {
    if (props.value == null && props.placeholderValue == null) {
        throw new Error('Select must have either a value or a placeholder value!');
    }
    const [options, setOptions] = React.useState<MultiSelectOption[]>(props.options || []);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [apiCallsEnabled, setApiCallsEnabled] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const filter = createFilterOptions();
    const { t } = useTranslation();

    const currVal = props.value;
    const listBoxRef = createRef();
    const debounceSearch = React.useRef(debounce((searchText: string) => { handleApiRequest(searchText, setOptions, true) }, 500))

    const handleOnChange = (event: React.ChangeEvent<{}>, value: string | MultiSelectOption | MultiSelectOption[] | (string | MultiSelectOption | MultiSelectOption[])[]) => {
        props.onChange({ ...event, target: { ...event.target, name: props.name, value: (value as MultiSelectOption)?.value} as any }, value)

        if ((value as MultiSelectOption)?.label === t('general.base_select.add_new')) {
            props.creatableModalHandleOpen();
        }
    }

    const isOptionSelected = (option: MultiSelectOption | MultiSelectOption[], val: MultiSelectOption | MultiSelectOption[]): boolean => {
        if (Array.isArray(val)) {
            if (!Array.isArray(option)) {
                return val.find((val: MultiSelectOption) => val.value === option.value) != null
            } else { // this case doesn't make sense
                return false;
            }
        } else {
            return (val as MultiSelectOption)?.value === (option as MultiSelectOption)?.value
        }
    }

    const getOptionLabel = (option: string | MultiSelectOption | MultiSelectOption[]): string => {
        if (Array.isArray(option)) {
            return option[0].label || '';
        } else if (typeof option === 'string') {
            throw new Error('Strings should not be entered as a multi select option');
        } else {
            return option.label || '';
        }
    }

    const handleOnListBoxScroll = () => {
        if (((listBoxRef.current as HTMLElement).scrollHeight - (listBoxRef.current as HTMLElement).clientHeight - 100) <= (listBoxRef.current as HTMLElement).scrollTop) {
            if (!loading && props.disablePaging !== true) {
                handleApiRequest(null, (newOptions: MultiSelectOption[]) => {
                    // if the api response filtered_count is a multiplication of tableParams.limit then the api call enabled is not going to be disabled
                    if (newOptions.length < props.tableParams?.limit) {
                        setApiCallsEnabled(false)
                    }
                    setOptions([...options, ...newOptions])
                })
            }
        }
    }

    const handleApiRequest = useCallback((inputVal?: string, callback?: (options: MultiSelectOption[]) => void, skipOpenCheck: boolean = false) => {
        if (apiCallsEnabled && props.getAsyncOptions != null) {
            setLoading(true);
            (async () => {
                if (inputVal != null) {
                    props.tableParams?.setSearchText(inputVal);
                }
                const response = await props.getAsyncOptions(props.tableParams)

                props.tableParams?.increment();
                callback?.(response);
                setLoading(false);
            })();
        }
    // eslint-disable-next-line
    }, [props, apiCallsEnabled, open]);

    const handleOnInputChange = (e, inputVal: string) => {
        if (e == null) {
            return;
        }
        if (e?.['type'] !== 'click') {
            if (props.getAsyncValuesOnSearch) {
                setSearchText(inputVal);
                if (inputVal != null) {
                    setApiCallsEnabled(true)
                }
            }
        }
    }
    const handleOptionsChange = () => {
        setOptions(props.options ?? [])
    }
    const handleOpenStateChange = () => {
        if (props.getAsyncOptions && props.options == null && options.length === 0 && !loading && open) {
            if (props.value != null) {
                setOptions(Array.isArray(props.value) ? props.value : [props.value])
            }
            handleApiRequest(null, setOptions)
        }
    }
    useEffect(() => {
        debounceSearch?.current(searchText)
    }, [searchText])
    useEffect(handleOpenStateChange, [open, handleApiRequest, loading, options.length, props.getAsyncOptions, props.options, props.value]);
    useEffect(handleOptionsChange, [props.options])
    const getValue = (): MultiSelectOption | MultiSelectOption[] | undefined => {
        if(currVal != null){
            if (!Array.isArray(currVal) && currVal.value == null) {
                return {label: '', value: ''};
            }
        }
        return currVal;
    }
    const clearOptions = () => {
        setOptions([])
    }
    useImperativeHandle(ref, () => ({
        clearOptions: clearOptions
    }));
    return (
        <Autocomplete
            multiple={props.multiple}
            disableClearable
            freeSolo // this option actually allows for free input but does shut up the needless warning complaining about "no matching options". SOMETIMES THE USER STILL NEEDS TO PICK, OKAY MATERIALUI? NOW SHHHHHH
            open={open}
            disabled={props.disabled === true}
            value={getValue()}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            isOptionEqualToValue={isOptionSelected}
            getOptionLabel={getOptionLabel}
            onChange={handleOnChange}
            style={props?.autocompleteStyle}
            options={options}
            ChipProps={{ size: "small", ...props.chipProps }}
            ref={listBoxRef}
            ListboxProps={{
                onScroll: handleOnListBoxScroll
            }}
            onInputChange={handleOnInputChange}
            renderInput={(params) => {
                return (
                    <TextFieldStyled
                        {...params}
                        size={props.size ?? 'medium'}
                        label={props.label}
                        disabled={props.disabled === true}
                        error={props.formikError}
                        helperText={props?.formikHelperText}
                        placeholder={props.placeholderValue?.label}
                        style={props?.textFieldStyles}
                        InputProps={{
                            ...params.InputProps,
                            inputProps: { 'data-lpignore': true, ...params.inputProps },
                            endAdornment: (
                                <React.Fragment>
                                    {props.editable &&
                                        <IconButton onClick={props.creatableModalHandleOpen} size="small"><EditIcon /></IconButton>
                                    }
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                    <div style={{display: 'flex'}}>{open ? <ChevronUp/> : <ChevronDown/>}</div>
                                </React.Fragment>
                            ),
                        }}
                    />

                )
            }}
            filterOptions={(options, params: any) => {
                const filteredOptions: any = filter(options, params);
                return props.creatable === true ? [{ value: 0, label: t('general.base_select.add_new') }, ...filteredOptions] : filteredOptions;
            }}
        />
    );
});


export default BaseSelect;