
import { CheckboxProps, FormControlLabel } from '@mui/material';
import CheckboxStyled from 'components/styled/CheckboxStyled';
import React from 'react';

export interface BBCheckboxProps {
    checkboxProps: CheckboxProps;
    label?: string | JSX.Element;
    required?: boolean;
    className?: string;
}


const BBCheckbox = (props: BBCheckboxProps) => {
    return (
        <FormControlLabel
            className={props.className}
            control={
                <CheckboxStyled
                    {...props.checkboxProps}
                />}
            label={props.label}
        />
    )
}

export default BBCheckbox;