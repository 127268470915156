import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import i18next from "i18next";
import React, { CSSProperties, useState } from "react";
import { Web } from 'mdi-material-ui';
import FlagNL from '../Flag-NL.svg';
import FlagUS from '../Flag-US.svg';
import NotificationCenter from "services/NotificationCenter";
import ActionTypes from "config/ActionTypes";
import { AppActions } from "reducers/types/App";
import LocalStorageManager from "utils/LocalStorageManager";

export interface LanguageSelectMenuProps {

}

const LanguageSelectMenu = (props: LanguageSelectMenuProps) => {
    
    const [anchorElem, setAnchorElem] = useState<HTMLElement | null>( null );

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElem( event.currentTarget );
    }

    const handleClose = () => {
        setAnchorElem(null);
    }
    
    const setLanguage = (lng: string): void => {
        localStorage.setItem('i18nextLng', lng);
        i18next.changeLanguage(lng, () => {NotificationCenter.default.sendNotification(AppActions.CreateAction(ActionTypes.CHANGED_LANGUAGE))})
        handleClose()
    }

    const flagIconStyles = {height: 10, width: 15, marginRight: 10, objectFit: 'cover'} as CSSProperties

    return (
        <>
            <Tooltip title={i18next.t('language.change_lng')} placement="right" enterDelay={500}>
                <IconButton
                    aria-label="language"
                    aria-controls="lng-menu"
                    aria-haspopup="true"
                    onClick={ handleOpen }
                    style={{marginBottom: 10, color: LocalStorageManager.isThemeLight() ? '#2c2c2c' : '#808080'}}
                    size='medium'
                >
                    <Web/>
                </IconButton>
            </Tooltip>
            <Menu
                className='lng-menu'
                anchorEl={anchorElem}
                style={{zIndex: 1599, marginBottom: 10}}
                open={anchorElem != null}
                onClose={handleClose}
            >
                <MenuItem 
                    key="lng-en" 
                    disabled={localStorage.getItem('i18nextLng') === 'en-US'} 
                    onClick={() => setLanguage('en-US')}
                >
                    <img src={FlagUS} style={flagIconStyles} alt='us-flag'/>
                    {i18next.t('language.english')}
                </MenuItem>
                <MenuItem 
                    key="lng-nl" 
                    disabled={localStorage.getItem('i18nextLng') === 'nl-NL'} 
                    onClick={() => setLanguage('nl-NL')}
                >
                    <img src={FlagNL} style={flagIconStyles} alt='nl-flag'/>
                    {i18next.t('language.dutch')}
                </MenuItem>
            </Menu>
        </>
    )
}

export default LanguageSelectMenu;