import React from "react";
import { DefaultEditor } from 'react-simple-wysiwyg';

interface TextEditorProps {
    value: string;
    onChange: (text: string) => void;
}

const TextEditor = (props: TextEditorProps) => {
    return (
        <div className="text-editor-container">
            <DefaultEditor
                onChange={(content) => props.onChange(content.target['value'])}
                value={ props.value }
            />
        </div>
    );
};

export default TextEditor;