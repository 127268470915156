/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    StepviewWidgetModel,
    StepviewWidgetModelFromJSON,
    StepviewWidgetModelFromJSONTyped,
    StepviewWidgetModelToJSON,
} from './';


export interface StepviewWidgetListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<StepviewWidgetModel>;
}

/**
 * 
 * @export
 * @interface StepviewWidgetListResultModel
 */
export class StepviewWidgetListResultModel extends BaseModel<StepviewWidgetListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof StepviewWidgetListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof StepviewWidgetListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<StepviewWidgetModel>}
     * @memberof StepviewWidgetListResultModel
     */
    items?: Array<StepviewWidgetModel>;

    constructor(data?: StepviewWidgetListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewWidgetListResultModel, value: StringDateInterface<StepviewWidgetListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewWidgetListResultModelFromJSON(json: any): StepviewWidgetListResultModel {
    return StepviewWidgetListResultModelFromJSONTyped(json, false);
}

export function StepviewWidgetListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewWidgetListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewWidgetListResultModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(StepviewWidgetModelFromJSON)),
    });
}

export function StepviewWidgetListResultModelToJSON(value?: StepviewWidgetListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewWidgetListResultModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(StepviewWidgetModelToJSON)),
    } as StringDateInterface<StepviewWidgetListResultModel>;
    return createClass.toJson(value, model);
}


