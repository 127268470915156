import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {UserApi, UserCreateRequest, UserDeleteRequest, UserHistoryListRequest, UserListRequest, UserRetrieveRequest, UserListResultModel} from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import UserActions, { UserCreate, UserDelete, UserHistoryList, UserList, UserRetrieve, UserUpdate } from 'reducers/types/User';

export function* UserSagas() {
    yield takeLatest(ActionTypes.API_USER_CREATE, UserSaga.userCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_USER_RETRIEVE, UserSaga.userRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_USER_UPDATE, UserSaga.userUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_USER_DELETE, UserSaga.userDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_USER_LIST, UserSaga.userListWorkerSaga);
    yield takeLatest(ActionTypes.API_USER_HISTORY_LIST, UserSaga.userHistoryListWorkerSaga);
}

export class UserSaga {
    static *userCreateWorkerSaga(action: UserCreate) {
        try {
    
            action.params.active = true
            const userArgs = {
                userModel: action.params
            } as UserCreateRequest
            const data = yield ApiHandler(new UserApi().userCreate, userArgs)
    
            // dispatch a success action to the store with the activities
            yield put(UserActions.CreateUserCreateSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_USER_CREATE_FAILURE, error as Error))
        }
    }
    
    static *userRetrieveWorkerSaga(action: UserRetrieve) {
        try {
    
            const userArgs = {
                uuid: action.params
            } as UserRetrieveRequest
            const data = yield ApiHandler(new UserApi().userRetrieve, userArgs)
    
            // dispatch a success action to the store with the activities
            yield put(UserActions.CreateUserRetrieveSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_USER_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *userUpdateWorkerSaga(action: UserUpdate) {
        try {
            const data = yield ApiHandler(new UserApi().userUpdate, action.params)
    
            yield put(UserActions.CreateUserUpdateSuccess(data));
        } catch(error) {
    
            yield put(AppActions.CreateError(ActionTypes.API_USER_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *userDeleteWorkerSaga(action: UserDelete) {
        try {
    
            const userArgs = {
                uuid: action.params
            } as UserDeleteRequest
            const data = yield ApiHandler(new UserApi().userDelete, userArgs)
    
            yield put(UserActions.CreateUserDeleteSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_USER_DELETE_FAILURE, error as Error))
    
        }
    }
    
    static *userListWorkerSaga(action: UserList) {
        try {
    
            const userArgs = {
                userRetrieveListModel: action.params
            } as UserListRequest
            const data = yield ApiHandler(new UserApi().userList, userArgs);
    
            yield put(UserActions.CreateUserListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_USER_LIST_FAILURE, error as Error))
        }
    }

    static async userList(input: string): Promise<UserListResultModel> {
        const retrieveListRequest = {
            userRetrieveListModel: {
                search_text: input
            }
        } as UserListRequest;
        return await new UserApi().userList(retrieveListRequest);
    }

    static* userHistoryListWorkerSaga(action: UserHistoryList) {
        try{
            const userArgs = {
                uuid: action.params?.uuid,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as UserHistoryListRequest

            const data = yield ApiHandler(new UserApi().userHistoryList, userArgs);
            yield put(UserActions.CreateUserHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_USER_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}
