/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    DummyDataCreateSuccessModel,
    DummyDataCreateSuccessModelFromJSON,
    DummyDataCreateSuccessModelToJSON,
    DummyDataListResultModel,
    DummyDataListResultModelFromJSON,
    DummyDataListResultModelToJSON,
    DummyDataModel,
    DummyDataModelFromJSON,
    DummyDataModelToJSON,
    DummyDataRemoveSuccessModel,
    DummyDataRemoveSuccessModelFromJSON,
    DummyDataRemoveSuccessModelToJSON,
    DummyDataRetrieveListModel,
    DummyDataRetrieveListModelFromJSON,
    DummyDataRetrieveListModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface DummyDataCreateRequest {
    dummyDataModel: DummyDataModel;
}

export interface DummyDataDeleteRequest {
    id: number;
}

export interface DummyDataHistoryListRequest {
    id: number;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface DummyDataListRequest {
    dummyDataRetrieveListModel: DummyDataRetrieveListModel;
}

export interface DummyDataRetrieveRequest {
    id: number;
}

export interface DummyDataUpdateRequest {
    id: number;
    dummyDataModel: DummyDataModel;
}

/**
 * 
 */
export class DummyDataApi extends runtime.BaseAPI {

    /**
     * Create a new dummy data
     * Create a new dummy data
     */
    async dummyDataCreateRaw(requestParameters: DummyDataCreateRequest): Promise<runtime.ApiResponse<DummyDataCreateSuccessModel>> {
        if (requestParameters.dummyDataModel === null || requestParameters.dummyDataModel === undefined) {
            throw new runtime.RequiredError('dummyDataModel','Required parameter requestParameters.dummyDataModel was null or undefined when calling dummyDataCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DummyDataModelToJSON(requestParameters.dummyDataModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyDataCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new dummy data
     * Create a new dummy data
     */
    dummyDataCreate = async(requestParameters: DummyDataCreateRequest): Promise<DummyDataCreateSuccessModel> => {
        const response = await this.dummyDataCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an dummy data
     * Delete an dummy data
     */
    async dummyDataDeleteRaw(requestParameters: DummyDataDeleteRequest): Promise<runtime.ApiResponse<DummyDataRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dummyDataDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyDataRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an dummy data
     * Delete an dummy data
     */
    dummyDataDelete = async(requestParameters: DummyDataDeleteRequest): Promise<DummyDataRemoveSuccessModel> => {
        const response = await this.dummyDataDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an dummy data
     * retrieve a list of historical logs for an dummy data
     */
    async dummyDataHistoryListRaw(requestParameters: DummyDataHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dummyDataHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling dummyDataHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an dummy data
     * retrieve a list of historical logs for an dummy data
     */
    dummyDataHistoryList = async(requestParameters: DummyDataHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.dummyDataHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all dummy datas
     * List all dummy datas
     */
    async dummyDataListRaw(requestParameters: DummyDataListRequest): Promise<runtime.ApiResponse<DummyDataListResultModel>> {
        if (requestParameters.dummyDataRetrieveListModel === null || requestParameters.dummyDataRetrieveListModel === undefined) {
            throw new runtime.RequiredError('dummyDataRetrieveListModel','Required parameter requestParameters.dummyDataRetrieveListModel was null or undefined when calling dummyDataList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DummyDataRetrieveListModelToJSON(requestParameters.dummyDataRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyDataListResultModelFromJSON(jsonValue));
    }

    /**
     * List all dummy datas
     * List all dummy datas
     */
    dummyDataList = async(requestParameters: DummyDataListRequest): Promise<DummyDataListResultModel> => {
        const response = await this.dummyDataListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a dummy data by its id
     * Retrieve an dummy data by its id
     */
    async dummyDataRetrieveRaw(requestParameters: DummyDataRetrieveRequest): Promise<runtime.ApiResponse<DummyDataModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dummyDataRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyDataModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a dummy data by its id
     * Retrieve an dummy data by its id
     */
    dummyDataRetrieve = async(requestParameters: DummyDataRetrieveRequest): Promise<DummyDataModel> => {
        const response = await this.dummyDataRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an dummy data
     * Update an dummy data
     */
    async dummyDataUpdateRaw(requestParameters: DummyDataUpdateRequest): Promise<runtime.ApiResponse<DummyDataCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dummyDataUpdate.');
        }

        if (requestParameters.dummyDataModel === null || requestParameters.dummyDataModel === undefined) {
            throw new runtime.RequiredError('dummyDataModel','Required parameter requestParameters.dummyDataModel was null or undefined when calling dummyDataUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/dummy-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DummyDataModelToJSON(requestParameters.dummyDataModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyDataCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an dummy data
     * Update an dummy data
     */
    dummyDataUpdate = async(requestParameters: DummyDataUpdateRequest): Promise<DummyDataCreateSuccessModel> => {
        const response = await this.dummyDataUpdateRaw(requestParameters);
        return await response.value();
    }

}
