import User from "api/override/UserModel";
import ActionTypes from "config/ActionTypes";
import LastVisitedDetailsPage from "interface/widgets/LastVisitedDetailsPage";
import _ from "lodash";
import { AppActions } from "reducers/types/App";
import NotificationCenter from "services/NotificationCenter";
import TokenUtil from "./TokenUtil";
import { BaseAPI } from "api";

export default class LocalStorageManager {

    static clearLogin = (): void => {
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
    }

    private static getItem = (key: string): string | undefined => {
        return localStorage.getItem(key) ?? undefined;  // this makes more sense for ts
    }

    private static setItem = (key: string, value?: string): void => {
        if (value != null) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }

    private static removeAll = (): void => {
        localStorage.clear()
    }

    private static getJSON = (key: string, optionalValue: any = undefined): any | undefined => {
        if(LocalStorageManager.getItem(key) === undefined) {
            return optionalValue
        }
        return JSON.parse(LocalStorageManager.getItem(key))
    }

    private static getJSONArray = (key: string, optionalValue: Array<any> = []): Array<any> => {
        return LocalStorageManager.getJSON(key, optionalValue)
    }

    private static getJSONObject = (key: string, optionalValue: Object = {}): Object => {
        return LocalStorageManager.getJSON(key, optionalValue)
    }

    private static setJSONItem = (key: string, value: any): void => {
        LocalStorageManager.setItem(key, JSON.stringify(value))
    }

    static setActiveWidgets(widgetNames: Array<string>): void {
        LocalStorageManager.setJSONItem('active_widgets', widgetNames)
    }

    static getActiveWidgets(): Array<string> {
        return LocalStorageManager.getJSONArray('active_widgets')
    }

    static isActiveWidget(widgetName: string): boolean {
        return LocalStorageManager.getActiveWidgets()?.includes(widgetName)
    }

    static addActiveWidget(widgetName: string): void {
        const currentActiveWidgets = LocalStorageManager.getActiveWidgets() ?? []
        LocalStorageManager.setJSONItem('active_widgets', _.uniq([widgetName, ...currentActiveWidgets]))
    }

    static removeActiveWidget(widgetName: string): void {
        const currentActiveWidgets = LocalStorageManager.getActiveWidgets() ?? []
        LocalStorageManager.setJSONItem('active_widgets', [...currentActiveWidgets.filter(widgetType => widgetType !== widgetName)])
    }

    static setThemeMode(mode: 'light' | 'dark'): void {
        LocalStorageManager.setItem('boThemeMode', mode)
    }

    static getThemeMode(): string {
        if (LocalStorageManager.getItem('boThemeMode') == null) {
            LocalStorageManager.setThemeMode('light')
        }
        return LocalStorageManager.getItem('boThemeMode')
    }

    static isThemeLight(): boolean {
        return LocalStorageManager.getItem('boThemeMode') === 'light'
    }

    static getThemeAccentPrimary(): string {
        return LocalStorageManager.getItem('boCustomAccentPrimary')
    }

    static setThemeAccentPrimary(hexColor: string) {
        return LocalStorageManager.setItem('boCustomAccentPrimary', hexColor)
    }

    static getThemeAccentSecondary(): string {
        return LocalStorageManager.getItem('boCustomAccentSecondary')
    }

    static setThemeAccentSecondary(hexColor: string) {
        return LocalStorageManager.setItem('boCustomAccentSecondary', hexColor)
    }

    static getBorderAccentEnabled(): string {
        return LocalStorageManager.getItem('borderAcc')
    }

    static setBorderAccentEnabled(onOff: 'on' | 'off') {
        return LocalStorageManager.setItem('borderAcc', onOff)
    }

    static onImpersonate(newActiveToken: string, newUser: string): void {
        const impersonatorToken: string = LocalStorageManager.getItem('jwt');
        const user = LocalStorageManager.getItem('user');
        LocalStorageManager.setItem('impersonator_token', impersonatorToken);
        LocalStorageManager.setItem('impersonator_user', user);
        LocalStorageManager.setItem('jwt', newActiveToken);
        LocalStorageManager.setItem('user', newUser);
        TokenUtil.reconstruct();
        BaseAPI.setConfig(
            BaseAPI.config.basePath,
            'bearer ' + newActiveToken
        )
        NotificationCenter.default.sendNotification(AppActions.CreateAction(ActionTypes.APP_USER_CHANGED))
    }

    static onExitImpersonate(): boolean {
        const impersonatorToken: string = LocalStorageManager.getItem('impersonator_token');
        const user = LocalStorageManager.getItem('impersonator_user');
        LocalStorageManager.setItem('jwt', impersonatorToken);
        LocalStorageManager.setItem('user', user);
        LocalStorageManager.setItem('impersonator_token');
        LocalStorageManager.setItem('impersonator_user');
        TokenUtil.reconstruct();
        BaseAPI.setConfig(
            BaseAPI.config.basePath,
            'bearer ' + impersonatorToken
        )
        NotificationCenter.default.sendNotification(AppActions.CreateAction(ActionTypes.APP_USER_CHANGED))
        return impersonatorToken !== '';
    }

    static onClearCache(itemKey?: string) {
        if (Boolean(itemKey)) {
            LocalStorageManager.setItem(itemKey, null)

        } else {
            LocalStorageManager.removeAll()
        }
    }

    static getUser(): User {
        return new User(LocalStorageManager.getJSONObject('user'))
    }

    static addLastVisitedToStorage = (pageToAdd: LastVisitedDetailsPage): Array<LastVisitedDetailsPage> => {
        const current: Array<LastVisitedDetailsPage> = LocalStorageManager.getJSONArray('last_visited');
        LocalStorageManager.setJSONItem('last_visited', _.uniqBy([pageToAdd, ...current].slice(0, 9), 'url'));
        return current;
    }

    static getLastVisitedFromStorage = (): Array<LastVisitedDetailsPage> => {
        let lastVisitiedPages: Array<LastVisitedDetailsPage> =  LocalStorageManager.getJSONArray('last_visited');
        lastVisitiedPages = lastVisitiedPages.filter((lastVisitiedPage: LastVisitedDetailsPage) => lastVisitiedPage.detailsPageRecord.name != null && lastVisitiedPage.detailsPageRecord.name !== '')
        return lastVisitiedPages;
    }
}