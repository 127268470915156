/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    SettingCreateSuccessModel,
    SettingCreateSuccessModelFromJSON,
    SettingCreateSuccessModelToJSON,
    SettingListResultModel,
    SettingListResultModelFromJSON,
    SettingListResultModelToJSON,
    SettingModel,
    SettingModelFromJSON,
    SettingModelToJSON,
    SettingRetrieveListModel,
    SettingRetrieveListModelFromJSON,
    SettingRetrieveListModelToJSON,
} from '../models';

export interface SettingCreateRequest {
    settingModel: SettingModel;
}

export interface SettingDeleteRequest {
    key: string;
}

export interface SettingHistoryListRequest {
    key: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface SettingListRequest {
    settingRetrieveListModel: SettingRetrieveListModel;
}

export interface SettingRetrieveRequest {
    key: string;
}

export interface SettingUpdateRequest {
    key: string;
    settingModel: SettingModel;
}

/**
 * 
 */
export class SettingApi extends runtime.BaseAPI {

    /**
     * Create a new Setting
     * Create a new Setting
     */
    async settingCreateRaw(requestParameters: SettingCreateRequest): Promise<runtime.ApiResponse<SettingCreateSuccessModel>> {
        if (requestParameters.settingModel === null || requestParameters.settingModel === undefined) {
            throw new runtime.RequiredError('settingModel','Required parameter requestParameters.settingModel was null or undefined when calling settingCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingModelToJSON(requestParameters.settingModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new Setting
     * Create a new Setting
     */
    settingCreate = async(requestParameters: SettingCreateRequest): Promise<SettingCreateSuccessModel> => {
        const response = await this.settingCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Setting
     * Delete a Setting
     */
    async settingDeleteRaw(requestParameters: SettingDeleteRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling settingDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Delete a Setting
     * Delete a Setting
     */
    settingDelete = async(requestParameters: SettingDeleteRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.settingDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a setting
     * retrieve a list of historical logs for a setting
     */
    async settingHistoryListRaw(requestParameters: SettingHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling settingHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling settingHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting/{key}/history`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a setting
     * retrieve a list of historical logs for a setting
     */
    settingHistoryList = async(requestParameters: SettingHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.settingHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all Setting
     * List all Setting
     */
    async settingListRaw(requestParameters: SettingListRequest): Promise<runtime.ApiResponse<SettingListResultModel>> {
        if (requestParameters.settingRetrieveListModel === null || requestParameters.settingRetrieveListModel === undefined) {
            throw new runtime.RequiredError('settingRetrieveListModel','Required parameter requestParameters.settingRetrieveListModel was null or undefined when calling settingList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingRetrieveListModelToJSON(requestParameters.settingRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingListResultModelFromJSON(jsonValue));
    }

    /**
     * List all Setting
     * List all Setting
     */
    settingList = async(requestParameters: SettingListRequest): Promise<SettingListResultModel> => {
        const response = await this.settingListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a Setting by its key
     * Retrieve a Setting by its key
     */
    async settingRetrieveRaw(requestParameters: SettingRetrieveRequest): Promise<runtime.ApiResponse<SettingModel>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling settingRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a Setting by its key
     * Retrieve a Setting by its key
     */
    settingRetrieve = async(requestParameters: SettingRetrieveRequest): Promise<SettingModel> => {
        const response = await this.settingRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Setting
     * Update a Setting
     */
    async settingUpdateRaw(requestParameters: SettingUpdateRequest): Promise<runtime.ApiResponse<SettingCreateSuccessModel>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling settingUpdate.');
        }

        if (requestParameters.settingModel === null || requestParameters.settingModel === undefined) {
            throw new runtime.RequiredError('settingModel','Required parameter requestParameters.settingModel was null or undefined when calling settingUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/setting/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SettingModelToJSON(requestParameters.settingModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a Setting
     * Update a Setting
     */
    settingUpdate = async(requestParameters: SettingUpdateRequest): Promise<SettingCreateSuccessModel> => {
        const response = await this.settingUpdateRaw(requestParameters);
        return await response.value();
    }

}
