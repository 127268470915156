import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useProcessPanelStyles = makeStyles()((theme: Theme) => {
    return {
        notification: {
            display: 'flex', 
            alignItems: 'flex-end'
        } ,
        current_notification_container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        } ,
        status_icon_warning: {
            height: 15, 
            color: '#ff9429'
        },
        status_icon_success: {
            height: 15, 
            color: '#0a8400'
        },
        status_icon_error: {
            height: 15, 
            color: '#ff2d29'
        },
        status_icon_info: {
            height: 15, 
            color: '#0071b5'
        },
        linear_progress: {
            background: '#75b9ff',
            '& > *': {
                background: '#0071b5'
            }
        },
        global_progress_expanded_container: {
            padding: '0 15px'
        },
        global_progress_header: {
            margin: 0,
            padding: '10px 25px',
            textTransform: 'capitalize'
        },
        global_progress_linear_progress: {
            background: '#75b9ff',
            '& > *': {
                background: '#0071b5'
            },
            height: 7,
            width: '90%',
            borderRadius: 5,
            margin: 'auto',
            '&.finished': {
                background: '#75ff7c',
                '& > *': {
                    background: '#13ba1b'
                },
            },
            '&.error': {
                background: '#ffa09e',
                '& > *': {
                    background: '#ff2d29'
                },
            }
        },
        collapsed_panel: {
            height: '40px',
            width: '200px',
            maxHeight: 200,
            position: 'absolute',
            bottom: 15,
            right: 20,
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'row',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
            background: '#0094ec',
            color: 'white',
            borderRadius: 10,
            zIndex: theme.zIndex.modal - 2,
            opacity: '0.6',
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
            }
        },
        expanded_panel: {
            width: 500,
            position: 'absolute',
            maxHeight: 350,
            overflowY: 'auto',
            bottom: 15,
            right: 20,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
            borderRadius: 10,
            background: theme.palette.background.paper,
            zIndex: theme.zIndex.modal -2,
            padding: '14px 0'
        },
        expanded_panel_header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '90%',
            marginBottom: 'auto'
        },
        minimize_button: {
            padding: 5
        },
        expanded_panel_content: {
            paddingBottom: 30,
            width: '100%'
        },
        expanded_panel_content_top: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2em'
        },
        modal_notification: {
            display: 'flex',
            alignItems: 'flex-end',
            margin: 5
        }
    }
})

export default useProcessPanelStyles
