/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface PropertyFilterModelInterface {
    type?: PropertyFilterModelTypeEnum;
}

/**
 * 
 * @export
 * @interface PropertyFilterModel
 */
export class PropertyFilterModel extends BaseModel<PropertyFilterModelInterface> {

    /**
     * Type of property
     * @type {string}
     * @memberof PropertyFilterModel
     */
    type?: PropertyFilterModelTypeEnum;

    constructor(data?: PropertyFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: PropertyFilterModel, value: StringDateInterface<PropertyFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function PropertyFilterModelFromJSON(json: any): PropertyFilterModel {
    return PropertyFilterModelFromJSONTyped(json, false);
}

export function PropertyFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PropertyFilterModel;
    try {
        let localClass = require.context('../override', true)('./PropertyFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'type': !exists(json, 'type') ? undefined : json['type'],
    });
}

export function PropertyFilterModelToJSON(value?: PropertyFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PropertyFilterModel;
    try {
        let localClass = require.context('../override', true)('./PropertyFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'type': value.type,
    } as StringDateInterface<PropertyFilterModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum PropertyFilterModelTypeEnum {
    Checkbox = 'checkbox',
    Date = 'date',
    DatetimeLocal = 'datetime-local',
    Email = 'email',
    Number = 'number',
    Password = 'password',
    Radio = 'radio',
    Tel = 'tel',
    Text = 'text',
    Time = 'time',
    Url = 'url'
}


