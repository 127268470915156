import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import MailLogState from "./interface/MailLogState";
import { MailLogListSuccess, MailLogRetrieveSuccess } from "./types/MailLog";
import MailLog from "api/override/MailLogModel";

const initialState: MailLogState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    timestampLastList: 0
};

export function MailLogReducers(state = initialState, action: BaseReducerAction): MailLogState {
    switch (action.type) {
        case ActionTypes.API_MAIL_LOG_RETRIEVE:
        case ActionTypes.API_MAIL_LOG_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_MAIL_LOG_LIST_FAILURE:
        case ActionTypes.API_MAIL_LOG_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_MAIL_LOG_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as MailLogRetrieveSuccess).params as MailLog,
                error: undefined
            };

        case ActionTypes.API_MAIL_LOG_LIST_SUCCESS:
            const result = action as MailLogListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<MailLog>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_MAIL_LOG_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}