import ImageVersion from "api/override/ImageVersionModel";
import { TableParams } from "models/table/TableParams";
import { ImageVersionSaga } from "sagas/ImageVersionSagas";
import Log from "utils/Log";
import BaseController from "./BaseController";
import { ImageVersionApi } from "api";

export default class ImageVersionController extends BaseController<ImageVersion> {

    imageVersionToMultiselect = async (imageId?: number, params?: TableParams): Promise<ImageVersion[]> => {
        try {
            if (isNaN(imageId) || imageId == null) {
                return []
            }
            if (!this.hasItemsForRequestOnProp(imageId)) {
                const response = await ImageVersionSaga.imageVersionList(imageId, params);
                this.cacheResponseOnParamsAndProp(response['items'] as ImageVersion[], params, imageId);
            }
            return this.getItemsForRequestAndProps(params, imageId) as ImageVersion[];

        } catch (e) {
            Log(e)
        }
        return [];
    }
    
    getImageVersion = async(id: number): Promise<ImageVersion | undefined> => {
        try {
            return await new ImageVersionApi().imageVersionRetrieve({id: id}) as ImageVersion;
        } catch (e) {
            Log(e)
        }
        return undefined;
    }

}