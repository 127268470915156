/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    ConnectorInstanceListResultModel,
    ConnectorInstanceListResultModelFromJSON,
    ConnectorInstanceListResultModelToJSON,
    ConnectorInstanceRetrieveListModel,
    ConnectorInstanceRetrieveListModelFromJSON,
    ConnectorInstanceRetrieveListModelToJSON,
    ConnectorListResultModel,
    ConnectorListResultModelFromJSON,
    ConnectorListResultModelToJSON,
    ConnectorModel,
    ConnectorModelFromJSON,
    ConnectorModelToJSON,
    ConnectorRemoveSuccessModel,
    ConnectorRemoveSuccessModelFromJSON,
    ConnectorRemoveSuccessModelToJSON,
    ConnectorRetrieveListModel,
    ConnectorRetrieveListModelFromJSON,
    ConnectorRetrieveListModelToJSON,
    ConnectorSuccessResponseModel,
    ConnectorSuccessResponseModelFromJSON,
    ConnectorSuccessResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    ProcessListResultModel,
    ProcessListResultModelFromJSON,
    ProcessListResultModelToJSON,
} from '../models';

export interface ConnectorConnectorContainerListRequest {
    uuid: string;
    connectorInstanceRetrieveListModel: ConnectorInstanceRetrieveListModel;
}

export interface ConnectorCreateRequest {
    connectorModel: ConnectorModel;
}

export interface ConnectorDeleteRequest {
    uuid: string;
}

export interface ConnectorHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ConnectorListRequest {
    connectorRetrieveListModel: ConnectorRetrieveListModel;
}

export interface ConnectorLogsListRequest {
    uuid: string;
    limit: number;
    offset: number;
    sortBy?: string;
    sortDir?: string;
    searchText?: string;
}

export interface ConnectorRetrieveRequest {
    uuid: string;
}

export interface ConnectorTasksRequest {
    uuid: string;
    task: string;
    uuids?: Array<string>;
}

export interface ConnectorUnlockRequest {
    uuid: string;
}

export interface ConnectorUpdateRequest {
    uuid: string;
    connectorModel: ConnectorModel;
}

/**
 * 
 */
export class ConnectorApi extends runtime.BaseAPI {

    /**
     * List all containers of a connector
     * List all containers of a connector
     */
    async connectorConnectorContainerListRaw(requestParameters: ConnectorConnectorContainerListRequest): Promise<runtime.ApiResponse<ConnectorInstanceListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorConnectorContainerList.');
        }

        if (requestParameters.connectorInstanceRetrieveListModel === null || requestParameters.connectorInstanceRetrieveListModel === undefined) {
            throw new runtime.RequiredError('connectorInstanceRetrieveListModel','Required parameter requestParameters.connectorInstanceRetrieveListModel was null or undefined when calling connectorConnectorContainerList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}/containers`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorInstanceRetrieveListModelToJSON(requestParameters.connectorInstanceRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorInstanceListResultModelFromJSON(jsonValue));
    }

    /**
     * List all containers of a connector
     * List all containers of a connector
     */
    connectorConnectorContainerList = async(requestParameters: ConnectorConnectorContainerListRequest): Promise<ConnectorInstanceListResultModel> => {
        const response = await this.connectorConnectorContainerListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new Connector
     * Create a new Connector
     */
    async connectorCreateRaw(requestParameters: ConnectorCreateRequest): Promise<runtime.ApiResponse<ConnectorSuccessResponseModel>> {
        if (requestParameters.connectorModel === null || requestParameters.connectorModel === undefined) {
            throw new runtime.RequiredError('connectorModel','Required parameter requestParameters.connectorModel was null or undefined when calling connectorCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorModelToJSON(requestParameters.connectorModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Create a new Connector
     * Create a new Connector
     */
    connectorCreate = async(requestParameters: ConnectorCreateRequest): Promise<ConnectorSuccessResponseModel> => {
        const response = await this.connectorCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Connector
     * Delete a Connector
     */
    async connectorDeleteRaw(requestParameters: ConnectorDeleteRequest): Promise<runtime.ApiResponse<ConnectorRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a Connector
     * Delete a Connector
     */
    connectorDelete = async(requestParameters: ConnectorDeleteRequest): Promise<ConnectorRemoveSuccessModel> => {
        const response = await this.connectorDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a connector
     * retrieve a list of historical logs for a connector
     */
    async connectorHistoryListRaw(requestParameters: ConnectorHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling connectorHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a connector
     * retrieve a list of historical logs for a connector
     */
    connectorHistoryList = async(requestParameters: ConnectorHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.connectorHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all connectors
     * List all connectors
     */
    async connectorListRaw(requestParameters: ConnectorListRequest): Promise<runtime.ApiResponse<ConnectorListResultModel>> {
        if (requestParameters.connectorRetrieveListModel === null || requestParameters.connectorRetrieveListModel === undefined) {
            throw new runtime.RequiredError('connectorRetrieveListModel','Required parameter requestParameters.connectorRetrieveListModel was null or undefined when calling connectorList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorRetrieveListModelToJSON(requestParameters.connectorRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorListResultModelFromJSON(jsonValue));
    }

    /**
     * List all connectors
     * List all connectors
     */
    connectorList = async(requestParameters: ConnectorListRequest): Promise<ConnectorListResultModel> => {
        const response = await this.connectorListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all logs of a connector
     * List all logs of a connector
     */
    async connectorLogsListRaw(requestParameters: ConnectorLogsListRequest): Promise<runtime.ApiResponse<ProcessListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorLogsList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling connectorLogsList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling connectorLogsList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDir !== undefined) {
            queryParameters['sort_dir'] = requestParameters.sortDir;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}/logs`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessListResultModelFromJSON(jsonValue));
    }

    /**
     * List all logs of a connector
     * List all logs of a connector
     */
    connectorLogsList = async(requestParameters: ConnectorLogsListRequest): Promise<ProcessListResultModel> => {
        const response = await this.connectorLogsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a Connector by its uuid
     * Retrieve a Connector by its uuid
     */
    async connectorRetrieveRaw(requestParameters: ConnectorRetrieveRequest): Promise<runtime.ApiResponse<ConnectorModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a Connector by its uuid
     * Retrieve a Connector by its uuid
     */
    connectorRetrieve = async(requestParameters: ConnectorRetrieveRequest): Promise<ConnectorModel> => {
        const response = await this.connectorRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule tasks for a Connector
     * Schedule tasks for a Connector
     */
    async connectorTasksRaw(requestParameters: ConnectorTasksRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorTasks.');
        }

        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling connectorTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.task !== undefined) {
            queryParameters['task'] = requestParameters.task;
        }

        if (requestParameters.uuids) {
            queryParameters['uuids[]'] = requestParameters.uuids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}/task`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks for a Connector
     * Schedule tasks for a Connector
     */
    connectorTasks = async(requestParameters: ConnectorTasksRequest): Promise<ApiResponseModel> => {
        const response = await this.connectorTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock connector
     * Unlock connector
     */
    async connectorUnlockRaw(requestParameters: ConnectorUnlockRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}/unlock`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Unlock connector
     * Unlock connector
     */
    connectorUnlock = async(requestParameters: ConnectorUnlockRequest): Promise<ApiResponseModel> => {
        const response = await this.connectorUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Connector
     * Update a Connector
     */
    async connectorUpdateRaw(requestParameters: ConnectorUpdateRequest): Promise<runtime.ApiResponse<ConnectorSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorUpdate.');
        }

        if (requestParameters.connectorModel === null || requestParameters.connectorModel === undefined) {
            throw new runtime.RequiredError('connectorModel','Required parameter requestParameters.connectorModel was null or undefined when calling connectorUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorModelToJSON(requestParameters.connectorModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Update a Connector
     * Update a Connector
     */
    connectorUpdate = async(requestParameters: ConnectorUpdateRequest): Promise<ConnectorSuccessResponseModel> => {
        const response = await this.connectorUpdateRaw(requestParameters);
        return await response.value();
    }

}
