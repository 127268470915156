/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    StepviewWidgetCreateSuccessModel,
    StepviewWidgetCreateSuccessModelFromJSON,
    StepviewWidgetCreateSuccessModelToJSON,
    StepviewWidgetListResultModel,
    StepviewWidgetListResultModelFromJSON,
    StepviewWidgetListResultModelToJSON,
    StepviewWidgetModel,
    StepviewWidgetModelFromJSON,
    StepviewWidgetModelToJSON,
    StepviewWidgetRemoveSuccessModel,
    StepviewWidgetRemoveSuccessModelFromJSON,
    StepviewWidgetRemoveSuccessModelToJSON,
    StepviewWidgetRetrieveListModel,
    StepviewWidgetRetrieveListModelFromJSON,
    StepviewWidgetRetrieveListModelToJSON,
} from '../models';

export interface StepviewWidgetCreateRequest {
    stepviewWidgetModel: StepviewWidgetModel;
}

export interface StepviewWidgetDeleteRequest {
    id: number;
}

export interface StepviewWidgetHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface StepviewWidgetListRequest {
    stepviewWidgetRetrieveListModel: StepviewWidgetRetrieveListModel;
}

export interface StepviewWidgetRetrieveRequest {
    id: number;
}

export interface StepviewWidgetUpdateRequest {
    id: number;
    stepviewWidgetModel: StepviewWidgetModel;
}

/**
 * 
 */
export class StepviewWidgetApi extends runtime.BaseAPI {

    /**
     * Create a new stepview widget
     * Create a new stepview widget
     */
    async stepviewWidgetCreateRaw(requestParameters: StepviewWidgetCreateRequest): Promise<runtime.ApiResponse<StepviewWidgetCreateSuccessModel>> {
        if (requestParameters.stepviewWidgetModel === null || requestParameters.stepviewWidgetModel === undefined) {
            throw new runtime.RequiredError('stepviewWidgetModel','Required parameter requestParameters.stepviewWidgetModel was null or undefined when calling stepviewWidgetCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewWidgetModelToJSON(requestParameters.stepviewWidgetModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewWidgetCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new stepview widget
     * Create a new stepview widget
     */
    stepviewWidgetCreate = async(requestParameters: StepviewWidgetCreateRequest): Promise<StepviewWidgetCreateSuccessModel> => {
        const response = await this.stepviewWidgetCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an stepview widget
     * Delete an stepview widget
     */
    async stepviewWidgetDeleteRaw(requestParameters: StepviewWidgetDeleteRequest): Promise<runtime.ApiResponse<StepviewWidgetRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewWidgetDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewWidgetRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an stepview widget
     * Delete an stepview widget
     */
    stepviewWidgetDelete = async(requestParameters: StepviewWidgetDeleteRequest): Promise<StepviewWidgetRemoveSuccessModel> => {
        const response = await this.stepviewWidgetDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an stepview widget
     * retrieve a list of historical logs for an stepview widget
     */
    async stepviewWidgetHistoryListRaw(requestParameters: StepviewWidgetHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewWidgetHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling stepviewWidgetHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an stepview widget
     * retrieve a list of historical logs for an stepview widget
     */
    stepviewWidgetHistoryList = async(requestParameters: StepviewWidgetHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.stepviewWidgetHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all stepview widgets
     * List all stepview widgets
     */
    async stepviewWidgetListRaw(requestParameters: StepviewWidgetListRequest): Promise<runtime.ApiResponse<StepviewWidgetListResultModel>> {
        if (requestParameters.stepviewWidgetRetrieveListModel === null || requestParameters.stepviewWidgetRetrieveListModel === undefined) {
            throw new runtime.RequiredError('stepviewWidgetRetrieveListModel','Required parameter requestParameters.stepviewWidgetRetrieveListModel was null or undefined when calling stepviewWidgetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewWidgetRetrieveListModelToJSON(requestParameters.stepviewWidgetRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewWidgetListResultModelFromJSON(jsonValue));
    }

    /**
     * List all stepview widgets
     * List all stepview widgets
     */
    stepviewWidgetList = async(requestParameters: StepviewWidgetListRequest): Promise<StepviewWidgetListResultModel> => {
        const response = await this.stepviewWidgetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a stepview widget by its id
     * Retrieve an stepview widget by its id
     */
    async stepviewWidgetRetrieveRaw(requestParameters: StepviewWidgetRetrieveRequest): Promise<runtime.ApiResponse<StepviewWidgetModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewWidgetRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewWidgetModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a stepview widget by its id
     * Retrieve an stepview widget by its id
     */
    stepviewWidgetRetrieve = async(requestParameters: StepviewWidgetRetrieveRequest): Promise<StepviewWidgetModel> => {
        const response = await this.stepviewWidgetRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an stepview widget
     * Update an stepview widget
     */
    async stepviewWidgetUpdateRaw(requestParameters: StepviewWidgetUpdateRequest): Promise<runtime.ApiResponse<StepviewWidgetCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewWidgetUpdate.');
        }

        if (requestParameters.stepviewWidgetModel === null || requestParameters.stepviewWidgetModel === undefined) {
            throw new runtime.RequiredError('stepviewWidgetModel','Required parameter requestParameters.stepviewWidgetModel was null or undefined when calling stepviewWidgetUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview-widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewWidgetModelToJSON(requestParameters.stepviewWidgetModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewWidgetCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an stepview widget
     * Update an stepview widget
     */
    stepviewWidgetUpdate = async(requestParameters: StepviewWidgetUpdateRequest): Promise<StepviewWidgetCreateSuccessModel> => {
        const response = await this.stepviewWidgetUpdateRaw(requestParameters);
        return await response.value();
    }

}
