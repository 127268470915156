/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ServiceContainerModel,
    ServiceContainerModelFromJSON,
    ServiceContainerModelFromJSONTyped,
    ServiceContainerModelToJSON,
} from './';


export interface ServiceContainerListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<ServiceContainerModel>;
}

/**
 * 
 * @export
 * @interface ServiceContainerListResultModel
 */
export class ServiceContainerListResultModel extends BaseModel<ServiceContainerListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof ServiceContainerListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof ServiceContainerListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<ServiceContainerModel>}
     * @memberof ServiceContainerListResultModel
     */
    items?: Array<ServiceContainerModel>;

    constructor(data?: ServiceContainerListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceContainerListResultModel, value: StringDateInterface<ServiceContainerListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceContainerListResultModelFromJSON(json: any): ServiceContainerListResultModel {
    return ServiceContainerListResultModelFromJSONTyped(json, false);
}

export function ServiceContainerListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceContainerListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceContainerListResultModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ServiceContainerModelFromJSON)),
    });
}

export function ServiceContainerListResultModelToJSON(value?: ServiceContainerListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceContainerListResultModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ServiceContainerModelToJSON)),
    } as StringDateInterface<ServiceContainerListResultModel>;
    return createClass.toJson(value, model);
}


