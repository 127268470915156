import i18next from "i18next";
import * as Yup from "yup";

export const retrieveInstanceSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    domain: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .matches(/^((?!bumbal\.eu|freightlive\.eu).)*$/, i18next.t('global.validation_schema.only_client_name'))
        .matches(/^((?!\.).)*$/, i18next.t('global.validation_schema.no_dots'))
        .doesntMatterMuch(),
    admin_email: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .email(i18next.t('global.validation_schema.invalid_email')),
    admin_password: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    database_password: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
});

export const retrieveInstanceSchemaEdit = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    admin_email: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .email('Invalid email'),
    system_key: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    api_key: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
});