import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import MailLog from 'api/override/MailLogModel';

interface MailLogDetailsProps {
    mailLog: MailLog;
}

const MailLogDetails = (props: MailLogDetailsProps) => {
    const { t } = useTranslation();
    const { mailLog } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <DetailsField propKey={t('mail_logs.sender')} propVal={mailLog?.sender} />
            <DetailsField propKey={t('mails.model')} propVal={mailLog?.mail.name} />
            <DetailsField propKey={t('clients.model')} propVal={mailLog?.client.name} />
            <DetailsField propKey={t('general.status')} propVal={mailLog?.status} />
            <DetailsField propKey={t('general.description')} propVal={mailLog?.status_message} />
        </div>
    );
};

export default MailLogDetails;