import { Theme } from '@mui/material/styles';

const LoadBalancerCardStyles = ((theme: Theme) => ({
    root: {
        width: 375,
        height: 450,
        marginTop: 20
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        width: 375,
        height: 450,
        justifyContent: "space-between"
    },
    information: {
        display: "flex",
        flexDirection: "column",
    },
    chip: {
        width: 80,
        color: "white",
        alignSelf: "center"
    },
    heading: {
        fontSize: 28,
        margin: 0
    },
    memoryLabel: {
        fontSize: 16,
        alignSelf: "center",
        marginBottom: -40
    }
}))

export default LoadBalancerCardStyles