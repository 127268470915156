import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import Stepview from 'api/override/StepviewModel';
import StepviewActions, { StepviewCreate, StepviewDelete, StepviewHistoryList, StepviewList, StepviewRetrieve, StepviewUpdate } from 'reducers/types/Stepview';
import { StepviewListRequest, StepviewApi, StepviewUpdateRequest, StepviewRetrieveRequest, StepviewCreateRequest, StepviewDeleteRequest, StepviewHistoryListRequest } from 'api/apis/StepviewApi';

export function* StepviewSagas() {
    yield takeLatest(ActionTypes.API_STEPVIEW_LIST, StepviewSaga.stepviewListWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_UPDATE, StepviewSaga.stepviewUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_RETRIEVE, StepviewSaga.stepviewRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_CREATE, StepviewSaga.stepviewCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_DELETE, StepviewSaga.stepviewDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_HISTORY_LIST, StepviewSaga.stepviewHistoryListWorkerSaga);
}

export class StepviewSaga {
    
    static *stepviewListWorkerSaga(action: StepviewList) {
        try {
            const stepviewsArgs = {
                stepviewRetrieveListModel: action.params
            } as StepviewListRequest

            const data = yield ApiHandler(new StepviewApi().stepviewList, stepviewsArgs)
            yield put(StepviewActions.CreateStepviewListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_LIST_FAILURE, error as Error))
        }
    }

    static *stepviewUpdateWorkerSaga(action: StepviewUpdate) {
        try {
            
            const data = yield ApiHandler(new StepviewApi().stepviewUpdate, action.params as StepviewUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(StepviewActions.CreateStepviewUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_UPDATE_FAILURE, error as Error))
        }
    }

    static stepviewRetrieve = async(stepview: number): Promise<Stepview> => {
        return await new StepviewApi().stepviewRetrieve({id: stepview}) as Stepview;
    }
    
    static *stepviewRetrieveWorkerSaga(action: StepviewRetrieve) {
        try {
    
            const stepviewsArgs = {
                id: action.params
            } as StepviewRetrieveRequest
            const data = yield ApiHandler(new StepviewApi().stepviewRetrieve, stepviewsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewActions.CreateStepviewRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *stepviewCreateWorkerSaga(action: StepviewCreate) {
        try {
    
            const stepviewsArgs = {
                stepviewModel: action.params
            } as StepviewCreateRequest
            const data = yield ApiHandler(new StepviewApi().stepviewCreate, stepviewsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewActions.CreateStepviewCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_CREATE_FAILURE, error as Error))
        }
    }
    
    static *stepviewDeleteWorkerSaga(action: StepviewDelete) {
        try {
            const stepviewsArgs = {
                id: action.params
            } as StepviewDeleteRequest
            const data = yield ApiHandler(new StepviewApi().stepviewDelete, stepviewsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewActions.CreateStepviewDeleteSuccess(data, action.params))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_DELETE_FAILURE, error as Error))
        }
    }
    
    static *stepviewHistoryListWorkerSaga(action: StepviewHistoryList) {
        try{
            const stepviewArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as StepviewHistoryListRequest
    
            const data = yield ApiHandler(new StepviewApi().stepviewHistoryList, stepviewArgs);
            yield put(StepviewActions.CreateStepviewHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_STEPVIEW_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

