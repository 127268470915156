import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import Branch from 'api/override/BranchModel';
import BranchActions, { BranchCreate, BranchDelete, BranchHistoryList, BranchList, BranchRetrieve, BranchUpdate } from 'reducers/types/Branch';
import { BranchListRequest, BranchApi, BranchUpdateRequest, BranchRetrieveRequest, BranchCreateRequest, BranchDeleteRequest, BranchHistoryListRequest } from 'api/apis/BranchApi';
import { TableParams } from 'models/table/TableParams';
import { BranchListResultModel } from 'api';

export function* BranchSagas() {
    yield takeLatest(ActionTypes.API_BRANCH_LIST, BranchSaga.branchListWorkerSaga);
    yield takeLatest(ActionTypes.API_BRANCH_UPDATE, BranchSaga.branchUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_BRANCH_RETRIEVE, BranchSaga.branchRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_BRANCH_CREATE, BranchSaga.branchCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_BRANCH_DELETE, BranchSaga.branchDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_BRANCH_HISTORY_LIST, BranchSaga.branchHistoryListWorkerSaga);
}

export class BranchSaga {
    
    static *branchListWorkerSaga(action: BranchList) {
        try {
            const branchsArgs = {
                branchRetrieveListModel: action.params
            } as BranchListRequest

            const data = yield ApiHandler(new BranchApi().branchList, branchsArgs)
            yield put(BranchActions.CreateBranchListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_BRANCH_LIST_FAILURE, error as Error))
        }
    }

    static *branchUpdateWorkerSaga(action: BranchUpdate) {
        try {
            
            const data = yield ApiHandler(new BranchApi().branchUpdate, action.params as BranchUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(BranchActions.CreateBranchUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_BRANCH_UPDATE_FAILURE, error as Error))
        }
    }

    static branchRetrieve = async(branch: number): Promise<Branch> => {
        return await new BranchApi().branchRetrieve({id: branch}) as Branch;
    }
    
    static *branchRetrieveWorkerSaga(action: BranchRetrieve) {
        try {
    
            const branchsArgs = {
                id: action.params
            } as BranchRetrieveRequest
            const data = yield ApiHandler(new BranchApi().branchRetrieve, branchsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(BranchActions.CreateBranchRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_BRANCH_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *branchCreateWorkerSaga(action: BranchCreate) {
        try {
    
            const branchsArgs = {
                branchModel: action.params
            } as BranchCreateRequest
            const data = yield ApiHandler(new BranchApi().branchCreate, branchsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(BranchActions.CreateBranchCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_BRANCH_CREATE_FAILURE, error as Error))
        }
    }
    
    static *branchDeleteWorkerSaga(action: BranchDelete) {
        try {
            const branchsArgs = {
                id: action.params
            } as BranchDeleteRequest
            const data = yield ApiHandler(new BranchApi().branchDelete, branchsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(BranchActions.CreateBranchDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_BRANCH_DELETE_FAILURE, error as Error))
        }
    }
    
    static *branchHistoryListWorkerSaga(action: BranchHistoryList) {
        try{
            const branchArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as BranchHistoryListRequest
    
            const data = yield ApiHandler(new BranchApi().branchHistoryList, branchArgs);
            yield put(BranchActions.CreateBranchHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_BRANCH_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static async branchList (tableParams?: TableParams): Promise<BranchListResultModel> {
        const retrieveListRequest = {
            branchRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as BranchListRequest;
        return await new BranchApi().branchList(retrieveListRequest);
    }
}

