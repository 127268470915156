import { HistoryResponseModel, TrialCreateSuccessModel, TrialHistoryListRequest, TrialListResultModel, TrialRetrieveListModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface TrialList extends BaseReducerAction {
    type: ActionTypes.API_TRIAL_LIST;
    params: TrialRetrieveListModel;
}

export interface TrialListSuccess extends BaseSagaListResponse<TrialListResultModel> {
    type: ActionTypes.API_TRIAL_LIST_SUCCESS;
}

export interface TrialUpdateParams {
    id: number;
}

export interface TrialUpdate extends BaseReducerAction {
    type: ActionTypes.API_TRIAL_UPDATE;
    params: TrialUpdateParams;
}

export interface TrialUpdateSuccess extends BaseSagaListResponse<TrialCreateSuccessModel> {
    type: ActionTypes.API_TRIAL_UPDATE_SUCCESS;
}

export interface TrialCancelParams {
    id: number;
}

export interface TrialCancel extends BaseReducerAction {
    type: ActionTypes.API_TRIAL_CANCEL;
    params: TrialCancelParams;
}

export interface TrialCancelSuccess extends BaseSagaListResponse<TrialCreateSuccessModel> {
    type: ActionTypes.API_TRIAL_CANCEL_SUCCESS;
}

export interface TrialHistoryList extends BaseReducerAction {
    type: ActionTypes.API_TRIAL_HISTORY_LIST;
    params: TrialHistoryListRequest;
}

export interface TrialHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_TRIAL_HISTORY_LIST_SUCCESS;
}

export interface TrialClearListParams {
    force?: boolean;
}
export interface TrialClearList extends BaseReducerAction {
    type: ActionTypes.API_TRIAL_LIST_CLEAR;
    params?: TrialClearListParams;
}

export default class TrialActions {
    
    static CreateTrialList(params: TrialRetrieveListModel): TrialList {
        return {
            type: ActionTypes.API_TRIAL_LIST,
            params: params
        }
    }

    static CreateTrialListSuccess(params: TrialListResultModel): TrialListSuccess {
        return {
            type: ActionTypes.API_TRIAL_LIST_SUCCESS,
            params: params
        }
    }

    static CreateTrialUpdate(params: TrialUpdateParams): TrialUpdate {
        return {
            type: ActionTypes.API_TRIAL_UPDATE,
            params: {
                id: params.id,
            }
        }
    }

    static CreateTrialUpdateSuccess(params: TrialCreateSuccessModel): TrialUpdateSuccess {
        return {
            type: ActionTypes.API_TRIAL_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateTrialCancel(params: TrialCancelParams): TrialCancel {
        return {
            type: ActionTypes.API_TRIAL_CANCEL,
            params: {
                id: params.id,
            }
        }
    }

    static CreateTrialCancelSuccess(params: TrialCreateSuccessModel): TrialCancelSuccess {
        return {
            type: ActionTypes.API_TRIAL_CANCEL_SUCCESS,
            params: params
        }
    }

    static CreateTrialHistoryList(params: TrialHistoryListRequest): TrialHistoryList {
        return {
            type: ActionTypes.API_TRIAL_HISTORY_LIST,
            params: params
        }
    }

    static CreateTrialHistoryListSuccess(params: HistoryResponseModel): TrialHistoryListSuccess {
        return {
            type: ActionTypes.API_TRIAL_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearTrialList(force: boolean = false): TrialClearList {
        return {
            type: ActionTypes.API_TRIAL_LIST_CLEAR,
            params: {force: force}
        }   
    }

}