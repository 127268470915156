import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { Card, CardContent, Container, LinearProgress } from "@mui/material";
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import { withSnackbar } from 'notistack';
import TableStyles from 'styles/TableStyles';
import i18next from 'i18next';
import TableHeader from 'components/TableHeader';
import { DatabaseExport } from 'mdi-material-ui';
import TransferToolForm from './components/TransferToolForm';
import NotificationCenter from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import { BaseAction } from 'reducers/interface/ReducerAction';
import AppState from 'reducers/interface/AppState';
import DatabaseTransferActions from 'reducers/types/DatabaseTransfer';
import InstanceOptionForm from './components/InstanceOptionForm';
import { ApiErrorModel, InstanceMiniModel, SystemCopyDatabaseRequest, SystemCopyDatabaseVerifyRequest, TransferVerifyRequestModel } from 'api';
import NCNotification from 'models/NCNotification';
import ProcessProgress from 'views/Instances/components/ProcessProgress';
import FormErrorTextStyled from 'components/styled/FormErrorTextStyled';
import { TransferRequestModel } from 'api/models/TransferRequestModel';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    appReducer: AppState;
}

interface DispatchProps {
    validateInputs: (params: SystemCopyDatabaseVerifyRequest) => void;
    databaseTransfer: (params: SystemCopyDatabaseRequest) => void;
}

type Props = StateProps & DispatchProps & ViewProps

export interface DatabaseTransferViewState {
    isValidated: boolean;
    instanceOptions: Array<InstanceMiniModel>;
    databaseTransferObject: TransferRequestModel;
    notifications: NCNotification[];
    runningProcessIds: string[];
    warnings: Array<ApiErrorModel>;
}

class DatabaseTransferView extends BasePageComponent<Props, DatabaseTransferViewState> {

    constructor(props) {
        super(props)
        this.state = {
            isValidated: false,
            instanceOptions: [],
            databaseTransferObject: null,
            notifications: [],
            warnings: [],
            runningProcessIds: []
        }

        this.setFailuresToListenTo([
            ActionTypes.API_DB_TRANSFER_VALIDATE_FAILURE,
            ActionTypes.API_DB_TRANSFER_START_FAILURE
        ])
    }

    componentDidMount() {
        super.componentDidMount();
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.API_DB_TRANSFER_VALIDATE_SUCCESS,
            this.onValidateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_DB_TRANSFER_VALIDATE_FAILURE,
            this.onValidateFailure
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.NODE_DATABASE_TRANSFER,
            (action) => {
                this.setState({notifications: this.state.notifications.concat(action)})
                if (!this.state.runningProcessIds.includes(action?.params?.data?.process_token)){
                    this.setState({runningProcessIds: this.state.runningProcessIds.bbPrepend(action?.params?.data?.process_token)})
                }
            }
        )
    }

    shouldRenderProgress = () => {
        return (this.state.notifications.length !== 0)
    }

    onValidateFailure = (action: BaseAction) => {
        this.setState({isValidated: false, databaseTransferObject: null})
    }

    onValidateSuccess = (action: BaseAction) => {
        this.setState({instanceOptions: action.params.data?.instances, warnings: action.params.data?.warnings, isValidated: true})
    }

    validateInputs = (inputObj: TransferVerifyRequestModel) => {
        this.props.validateInputs({transferVerifyRequestModel: inputObj})
        this.setState({databaseTransferObject: inputObj})
    }

    beginDatabaseTransfer = (inputObj: InstanceMiniModel) => {
        this.setState(prevState => {
            return {...prevState, databaseTransferObject: new TransferRequestModel({...this.state.databaseTransferObject, instance_id: inputObj.id, backoffice: inputObj.backoffice})}
        }, () => {this.props.databaseTransfer({transferRequestModel: this.state.databaseTransferObject})})
    }
    
    renderView() {
        const { classes, appReducer } = this.props;

        return (
            <React.Fragment>
            <Container maxWidth={"lg"}>
                <Card className={classes.root}>
                    <TableHeader
                        headerIcon={<DatabaseExport/>}
                        overrideTitle={i18next.t('db-transfer-tool.title')}
                    />
                    <CardContent>
                        <h2 style={{color: 'red', fontWeight: 700, textAlign: 'center', padding: '0 16px'}}>{i18next.t('warning.db_transfer_warning.database_to_info')}</h2>
                        <TransferToolForm 
                            onFormSubmit={(inputs: TransferVerifyRequestModel) => this.validateInputs(inputs)}
                            isValid={this.state.isValidated}
                            onFormChange={(state: Partial<DatabaseTransferViewState>) => {
                                this.setState(prevState => {return {...prevState, ...state, databaseTransferObject: new TransferRequestModel({...prevState.databaseTransferObject, ...state.databaseTransferObject})}})
                            }}
                        />
                        {appReducer.isFetchingData.get(ActionTypes.API_DB_TRANSFER_VALIDATE) && 
                            <LinearProgress color='primary' style={{width: 300, height: 6, borderRadius: 5, margin: '0 auto'}}/>
                        }
                        {this.state.warnings.map((warning: ApiErrorModel, index: number) => (
                            <FormErrorTextStyled key={`warning_${warning.code}_${index}`} isWarning message={i18next.t(`warning.${warning.code}`)}/>
                        ))}
                        {this.state.isValidated &&
                            <InstanceOptionForm 
                                onFormSubmit={this.beginDatabaseTransfer}
                                instanceOptions={this.state.instanceOptions}
                            />
                        }
                        {this.shouldRenderProgress() &&
                            <CardContent>
                                {this.state.runningProcessIds.map((processId) => {
                                    return (
                                        <ProcessProgress 
                                            key={processId}     
                                            notifications={this.state.notifications.filter((notif) => processId === notif.params.data.process_token)}
                                            onFinish={(process: NCNotification) => {
                                                this.props.enqueueSnackbar(i18next.t(`node.${process.params.data.process_name}`, {entity: process.params.data.meta?.name}) + ' ' + i18next.t('global.notification.process_finished'), {variant: 'success'})
                                            }}
                                        />
                                    )
                                })}
                            </CardContent>
                        }
                    </CardContent>
                </Card>
            </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appReducer: state.app
});

const mapDispatchToProps = dispatch => {
    return {
        validateInputs: (params: SystemCopyDatabaseVerifyRequest) => dispatch(DatabaseTransferActions.CreateValidateInputs(params)),
        databaseTransfer: (params: SystemCopyDatabaseRequest) => dispatch(DatabaseTransferActions.CreateDatabaseTransfer(params))
    }
};

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(DatabaseTransferView, TableStyles)
            )
        )
    )
);