import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ServiceApi, ServiceCreateRequest, ServiceDeleteRequest, ServiceHistoryListRequest, ServiceListRequest, ServiceListResultModel, ServiceLogsListRequest, ServiceRetrieveRequest, ServiceUpdateRequest, ServiceServiceContainerListRequest, ServiceSecretsRetrieveRequest, ServiceSecretsUpdateRequest, ServiceContainerListResultModel, ServiceContainerRetrieveListModel, ServiceTasksRequest} from "api";
import { ApiHandler } from './APIHandler';
import ServiceActions, { ServiceCreate, ServiceDelete, ServiceHistoryList, ServiceList, ServiceListInstances, ServiceLogsList, ServiceRetrieve, ServiceRetrieveSecrets, ServiceTriggerJob, ServiceUpdate, ServiceUpdateSecrets } from 'reducers/types/Service';
import { AppActions } from 'reducers/types/App';
import { TableParams } from 'models/table/TableParams';

export function* ServiceSagas() {
    yield takeLatest(ActionTypes.API_SERVICE_CREATE, ServiceSaga.serviceCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_DELETE, ServiceSaga.serviceDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_HISTORY_LIST, ServiceSaga.serviceHistoryListWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_LIST_INSTANCES, ServiceSaga.serviceListInstancesWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_LIST, ServiceSaga.serviceListWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_LOGS_LIST, ServiceSaga.serviceLogsListWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_RETRIEVE, ServiceSaga.serviceRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_UPDATE, ServiceSaga.serviceUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_RETRIEVE_SECRETS, ServiceSaga.serviceRetrieveSecretsWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_UPDATE_SECRETS, ServiceSaga.serviceUpdateSecretsWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_TRIGGER_JOB, ServiceSaga.serviceTriggerJobWorkerSaga);

}
export class ServiceSaga {

    static *serviceUpdateWorkerSaga(action: ServiceUpdate) {
        try {
            const servicesArgs = {
                uuid: action.params.uuid,
                serviceModel: action.params.serviceModel
            } as ServiceUpdateRequest

            const data = yield ApiHandler(new ServiceApi().serviceUpdate, servicesArgs);

            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceUpdateSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_UPDATE_FAILURE, error as Error))
        }
    }
    static *serviceRetrieveWorkerSaga(action: ServiceRetrieve) {
        try {
            
            const servicesArgs = {
                uuid: action.params
            } as ServiceRetrieveRequest
            const data = yield ApiHandler(new ServiceApi().serviceRetrieve, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceRetrieveSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_RETRIEVE_FAILURE, error as Error))
        }
    }

    static *serviceRetrieveSecretsWorkerSaga(action: ServiceRetrieveSecrets) {
        try {
            
            const servicesArgs = {
                uuid: action.params.uuid
            } as ServiceSecretsRetrieveRequest
            const data = yield ApiHandler(new ServiceApi().serviceSecretsRetrieve, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceRetrieveSecretsSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_RETRIEVE_SECRETS_FAILURE, error as Error))
        }
    }

    static *serviceUpdateSecretsWorkerSaga(action: ServiceUpdateSecrets) {
        try {
            const servicesArgs = {
                uuid: action.params.uuid,
                serviceSecretModel: action.params.serviceSecretModel
            } as ServiceSecretsUpdateRequest
            const data = yield ApiHandler(new ServiceApi().serviceSecretsUpdate, servicesArgs)

            yield put(ServiceActions.CreateServiceUpdateSecretsSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_UPDATE_SECRETS_FAILURE, error as Error))
        }
    }

    static *serviceListWorkerSaga(action: ServiceList) {
        try {

            const servicesArgs = {
                serviceRetrieveListModel: action.params
            } as ServiceListRequest
            const data = yield ApiHandler(new ServiceApi().serviceList, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceListSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_LIST_FAILURE, error as Error))
        }
    }

    static async serviceList(tableParams?: TableParams): Promise<ServiceListResultModel> {
        const retrieveListRequest = {
            serviceRetrieveListModel: Object.assign({}, tableParams.getApiFilter())
        } as ServiceListRequest;
        return await new ServiceApi().serviceList(retrieveListRequest);
    }
    
    static *serviceCreateWorkerSaga(action: ServiceCreate) {
        try {
            const servicesArgs = {
                serviceModel: action.params
            } as ServiceCreateRequest
            const data = yield ApiHandler(new ServiceApi().serviceCreate, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceCreateSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CREATE_FAILURE, error as Error))
        }
    }
    
    static *serviceDeleteWorkerSaga(action: ServiceDelete) {
        try {
            const servicesArgs = {
                uuid: action.params
            } as ServiceDeleteRequest
            const data = yield ApiHandler(new ServiceApi().serviceDelete, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceDeleteSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_DELETE_FAILURE, error as Error))
        }
    }
    
    static *serviceLogsListWorkerSaga(action: ServiceLogsList) {
        try {
            const tableParams = action.params.tableParams;
            const serviceArgs = {
                uuid: action.params.uuid,
                limit: tableParams.limit,
                offset: tableParams.offset,
                sortBy: tableParams.sorting?.column ?? 'started_at',
                sortDir: tableParams.sorting?.direction ?? 'desc',
                searchText: tableParams.search_text
            } as ServiceLogsListRequest
            const data = yield ApiHandler(new ServiceApi().serviceLogsList, serviceArgs);
            yield put(ServiceActions.CreateServiceLogsListSuccess(data));
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_LOGS_LIST_FAILURE, error as Error))
        }
    }
    
    static *serviceHistoryListWorkerSaga(action: ServiceHistoryList) {
        try {
            const serviceArgs = {
                uuid: action.params?.uuid,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ServiceHistoryListRequest
            
            const data = yield ApiHandler(new ServiceApi().serviceHistoryList, serviceArgs);
            yield put(ServiceActions.CreateServiceHistoryListSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_HISTORY_LIST_FAILURE, error as Error))
        }
    }
    
    static *serviceListInstancesWorkerSaga(action: ServiceListInstances) {
        try {
            const servicesArgs = {
                uuid: action.params.uuid,
                serviceContainerRetrieveListModel: action.params.tableParams.getApiFilter()
            } as ServiceServiceContainerListRequest
            const data = yield ApiHandler(new ServiceApi().serviceServiceContainerList, servicesArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ServiceActions.CreateServiceListInstancesSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_LIST_INSTANCES_FAILURE, error as Error))
        }
    }

    static async serviceContainersList (serviceUuid: string, tableParams?: TableParams): Promise<ServiceContainerListResultModel> {
        const retrieveListRequest = {
            uuid: serviceUuid,
            serviceContainerRetrieveListModel: new ServiceContainerRetrieveListModel(tableParams.getApiFilter())
        } as ServiceServiceContainerListRequest;
        return await new ServiceApi().serviceServiceContainerList(retrieveListRequest);
        
    }

    static *serviceTriggerJobWorkerSaga(action: ServiceTriggerJob) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                task: action.params.task,
                uuids: action.params.uuids?.bbIsEmpty() ? undefined : action.params.uuids
            } as ServiceTasksRequest
            const data = yield ApiHandler(new ServiceApi().serviceTasks, ccArgs)
            yield put(ServiceActions.CreateServiceTriggerJobSuccess(data))
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB_FAILURE, error as Error))
        }
    }

}