/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    ConnectorContainerCreateSuccessModel,
    ConnectorContainerCreateSuccessModelFromJSON,
    ConnectorContainerCreateSuccessModelToJSON,
    ConnectorContainerModel,
    ConnectorContainerModelFromJSON,
    ConnectorContainerModelToJSON,
    ConnectorContainerRemoveSuccessModel,
    ConnectorContainerRemoveSuccessModelFromJSON,
    ConnectorContainerRemoveSuccessModelToJSON,
    ConnectorContainerUpdateModel,
    ConnectorContainerUpdateModelFromJSON,
    ConnectorContainerUpdateModelToJSON,
    FileUploadModel,
    FileUploadModelFromJSON,
    FileUploadModelToJSON,
    GetConfigModel,
    GetConfigModelFromJSON,
    GetConfigModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    PostConfigModel,
    PostConfigModelFromJSON,
    PostConfigModelToJSON,
} from '../models';

export interface ConnectorContainerConfigureRequest {
    uuid: string;
    postConfigModel: PostConfigModel;
}

export interface ConnectorContainerConfigureGetRequest {
    uuid: string;
}

export interface ConnectorContainerCreateRequest {
    connectorContainerModel: ConnectorContainerModel;
}

export interface ConnectorContainerDeleteRequest {
    uuid: string;
}

export interface ConnectorContainerFileRequest {
    uuid: string;
    fileUploadModel: FileUploadModel;
}

export interface ConnectorContainerHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ConnectorContainerResetRequest {
    uuid: string;
}

export interface ConnectorContainerRetrieveRequest {
    uuid: string;
}

export interface ConnectorContainerTasksRequest {
    uuid: string;
    task: string;
    dbName?: string;
    uuids?: Array<string>;
}

export interface ConnectorContainerUnlockRequest {
    uuid: string;
}

export interface ConnectorContainerUpdateRequest {
    uuid: string;
    connectorContainerUpdateModel: ConnectorContainerUpdateModel;
}

/**
 * 
 */
export class ConnectorContainerApi extends runtime.BaseAPI {

    /**
     * Configure a ConnectorContainer
     * Configure a ConnectorContainer
     */
    async connectorContainerConfigureRaw(requestParameters: ConnectorContainerConfigureRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerConfigure.');
        }

        if (requestParameters.postConfigModel === null || requestParameters.postConfigModel === undefined) {
            throw new runtime.RequiredError('postConfigModel','Required parameter requestParameters.postConfigModel was null or undefined when calling connectorContainerConfigure.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/configure`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostConfigModelToJSON(requestParameters.postConfigModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Configure a ConnectorContainer
     * Configure a ConnectorContainer
     */
    connectorContainerConfigure = async(requestParameters: ConnectorContainerConfigureRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.connectorContainerConfigureRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get configuration for a ConnectorContainer
     * Get configuration for a ConnectorContainer
     */
    async connectorContainerConfigureGetRaw(requestParameters: ConnectorContainerConfigureGetRequest): Promise<runtime.ApiResponse<GetConfigModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerConfigureGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/configure`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConfigModelFromJSON(jsonValue));
    }

    /**
     * Get configuration for a ConnectorContainer
     * Get configuration for a ConnectorContainer
     */
    connectorContainerConfigureGet = async(requestParameters: ConnectorContainerConfigureGetRequest): Promise<GetConfigModel> => {
        const response = await this.connectorContainerConfigureGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new ConnectorContainer
     * Create a new ConnectorContainer
     */
    async connectorContainerCreateRaw(requestParameters: ConnectorContainerCreateRequest): Promise<runtime.ApiResponse<ConnectorContainerCreateSuccessModel>> {
        if (requestParameters.connectorContainerModel === null || requestParameters.connectorContainerModel === undefined) {
            throw new runtime.RequiredError('connectorContainerModel','Required parameter requestParameters.connectorContainerModel was null or undefined when calling connectorContainerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorContainerModelToJSON(requestParameters.connectorContainerModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorContainerCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new ConnectorContainer
     * Create a new ConnectorContainer
     */
    connectorContainerCreate = async(requestParameters: ConnectorContainerCreateRequest): Promise<ConnectorContainerCreateSuccessModel> => {
        const response = await this.connectorContainerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an ConnectorContainer, given it\'s UUID
     * Delete a ConnectorContainer
     */
    async connectorContainerDeleteRaw(requestParameters: ConnectorContainerDeleteRequest): Promise<runtime.ApiResponse<ConnectorContainerRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorContainerRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an ConnectorContainer, given it\'s UUID
     * Delete a ConnectorContainer
     */
    connectorContainerDelete = async(requestParameters: ConnectorContainerDeleteRequest): Promise<ConnectorContainerRemoveSuccessModel> => {
        const response = await this.connectorContainerDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload a file to a ConnectorContainer
     * Upload a file to a ConnectorContainer
     */
    async connectorContainerFileRaw(requestParameters: ConnectorContainerFileRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerFile.');
        }

        if (requestParameters.fileUploadModel === null || requestParameters.fileUploadModel === undefined) {
            throw new runtime.RequiredError('fileUploadModel','Required parameter requestParameters.fileUploadModel was null or undefined when calling connectorContainerFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/set`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileUploadModelToJSON(requestParameters.fileUploadModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Upload a file to a ConnectorContainer
     * Upload a file to a ConnectorContainer
     */
    connectorContainerFile = async(requestParameters: ConnectorContainerFileRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.connectorContainerFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a connector container
     * retrieve a list of historical logs for a connector container
     */
    async connectorContainerHistoryListRaw(requestParameters: ConnectorContainerHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling connectorContainerHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a connector container
     * retrieve a list of historical logs for a connector container
     */
    connectorContainerHistoryList = async(requestParameters: ConnectorContainerHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.connectorContainerHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reset a ConnectorContainer login
     * Reset a ConnectorContainer login
     */
    async connectorContainerResetRaw(requestParameters: ConnectorContainerResetRequest): Promise<runtime.ApiResponse<ConnectorContainerCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerReset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/reset`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorContainerCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Reset a ConnectorContainer login
     * Reset a ConnectorContainer login
     */
    connectorContainerReset = async(requestParameters: ConnectorContainerResetRequest): Promise<ConnectorContainerCreateSuccessModel> => {
        const response = await this.connectorContainerResetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a ConnectorContainer
     * Retrieve a ConnectorContainer
     */
    async connectorContainerRetrieveRaw(requestParameters: ConnectorContainerRetrieveRequest): Promise<runtime.ApiResponse<ConnectorContainerModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorContainerModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a ConnectorContainer
     * Retrieve a ConnectorContainer
     */
    connectorContainerRetrieve = async(requestParameters: ConnectorContainerRetrieveRequest): Promise<ConnectorContainerModel> => {
        const response = await this.connectorContainerRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule tasks for a Connector Container
     * Schedule tasks for a Connector Container
     */
    async connectorContainerTasksRaw(requestParameters: ConnectorContainerTasksRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerTasks.');
        }

        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling connectorContainerTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.task !== undefined) {
            queryParameters['task'] = requestParameters.task;
        }

        if (requestParameters.dbName !== undefined) {
            queryParameters['db_name'] = requestParameters.dbName;
        }

        if (requestParameters.uuids) {
            queryParameters['uuids[]'] = requestParameters.uuids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/task`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks for a Connector Container
     * Schedule tasks for a Connector Container
     */
    connectorContainerTasks = async(requestParameters: ConnectorContainerTasksRequest): Promise<ApiResponseModel> => {
        const response = await this.connectorContainerTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock connector container
     * Unlock connector container
     */
    async connectorContainerUnlockRaw(requestParameters: ConnectorContainerUnlockRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}/unlock`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Unlock connector container
     * Unlock connector container
     */
    connectorContainerUnlock = async(requestParameters: ConnectorContainerUnlockRequest): Promise<ApiResponseModel> => {
        const response = await this.connectorContainerUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a ConnectorContainer
     * Update a ConnectorContainer
     */
    async connectorContainerUpdateRaw(requestParameters: ConnectorContainerUpdateRequest): Promise<runtime.ApiResponse<ConnectorContainerCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorContainerUpdate.');
        }

        if (requestParameters.connectorContainerUpdateModel === null || requestParameters.connectorContainerUpdateModel === undefined) {
            throw new runtime.RequiredError('connectorContainerUpdateModel','Required parameter requestParameters.connectorContainerUpdateModel was null or undefined when calling connectorContainerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/connector-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorContainerUpdateModelToJSON(requestParameters.connectorContainerUpdateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorContainerCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a ConnectorContainer
     * Update a ConnectorContainer
     */
    connectorContainerUpdate = async(requestParameters: ConnectorContainerUpdateRequest): Promise<ConnectorContainerCreateSuccessModel> => {
        const response = await this.connectorContainerUpdateRaw(requestParameters);
        return await response.value();
    }

}
