import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import NotificationCenter from 'services/NotificationCenter';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { BaseAction } from 'reducers/interface/ReducerAction';
import ReloadDataButton from 'components/ReloadDataButton';
import FormActions from 'reducers/types/Form';
import FormState from 'reducers/interface/FormState';
import { FormCreateSuccessModel, FormHistoryListRequest } from 'api';
import Form from 'api/override/FormModel';
import FormBuilder from './components/FormBuilder';
import DetailsViewHeader from 'components/DetailsViewHeader';
import TabManager, { TabView } from 'components/tabs/TabManager';
import HistoryTab from 'components/tabs/HistoryTab';
import FormDetails from './components/FormDetails';

interface StateProps {
    appReducer: AppState;
    formReducer: FormState;
}

interface DispatchProps {
    retrieveForm: (params: number) => void;
    listFormHistory: (params: FormHistoryListRequest) => void;
    updateForm: (params: Form) => void;
    createForm: (params: Form) => void;
    deleteForm: (params: number) => void;
    clearForms: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    form?: Form;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class FormView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            form: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_FORM_RETRIEVE_FAILURE,
            ActionTypes.API_FORM_CREATE_FAILURE,
            ActionTypes.API_FORM_UPDATE_FAILURE,
            ActionTypes.API_FORM_DELETE_FAILURE,
            ActionTypes.API_FORM_HISTORY_LIST_FAILURE,
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_DELETE_SUCCESS, 
            this.onDeleteSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({form: action.params}, () => {
            this.updateTabTitle(this.state.form?.title)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const formSuccess: FormCreateSuccessModel = action.params
        this.setState({form: new Form(formSuccess.model)})
        this.props.navigate(`/forms/${formSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('forms.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('forms.model')}), {variant: 'success'})
        this.props.clearForms()
        this.reloadData()
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('forms.model')}), {variant: 'success'})
        this.props.clearForms()
        this.props.navigate('/forms')
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveForm(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_FORM_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.form != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.props.navigate(`/forms/${this.props.params.slug}/builder`)
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    updateForm = (form: Form) => {
        this.props.updateForm(form)
    }

    createForm = (form: Form) => {
        
    }

    renderView() {
        const { classes, appReducer, formReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_FORM_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                    // <FormBuilder form={this.state.form} onUpdate={this.updateForm} onUpdateTitle={this.updateForm}/>
                                    <div>
                                    <DetailsViewHeader
                                        model={i18next.t('forms.model')}
                                        title={this.state.form?.title}
                                    />
                                    <TabManager
                                        items={[
                                            {
                                                title: i18next.t('general.detail'),
                                                link: `/forms/${this.state.form?.id}`,
                                                view: (
                                                    <FormDetails 
                                                        form={this.state.form} 
                                                        onDelete={this.onDelete}
                                                        onEdit={this.onEdit}
                                                    />
                                                )
                                            },{
                                                title:  i18next.t('history.model'),
                                                link: `/forms/${this.state.form?.id}/history`,
                                                initialTab: this.isPathIncluded('history'),
                                                view: (
                                                    <HistoryTab 
                                                        model={this.state.form}
                                                    />
                                                )
                                            }
                                        ]}
                                        onTabSwitch={(newTab: TabView) => {
                                            if (newTab.link.includes('history')){
                                                if (formReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_WIZARD_HISTORY_LIST)) {
                                                    this.sendNotification({type: ActionTypes.WIZARD_VIEW_UPDATE_HISTORY}, null);
                                                }
                                            }
                                        }}
                                    />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <FormBuilder form={new Form()} onCreate={this.createForm}/>
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                
                <ConfirmDeleteDialog
                    open={this.state.isDeleteModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onConfirm={() => {
                        this.props.deleteForm(this.state.form?.id)
                    }}
                    title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('forms.model')).toLowerCase()})}
                />
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    formReducer: state.forms
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveForm: (params: number) => dispatch(FormActions.CreateFormRetrieve(params)),
        listFormHistory: (params: FormHistoryListRequest) => dispatch(FormActions.CreateFormHistoryList(params)),
        updateForm: (params: Form) => dispatch(FormActions.CreateFormUpdate(params)),
        createForm: (params: Form) => dispatch(FormActions.CreateFormCreate(params)),
        deleteForm: (params: number) => dispatch(FormActions.CreateFormDelete(params)),
        clearForms: () => dispatch(FormActions.CreateClearFormList(true))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(FormView, InstanceViewStyles)
            )
        )
    )
);