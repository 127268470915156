import Constants from "config/Constants";
import ServiceVolumeTableRow from "./ServiceVolumeTableRow";

export default class ServiceVolume {

    public publicVolumes: string[];
    public privateVolumes: string[];
    public configVolumes: string[];

    constructor(json?: string) {
        const data = JSON.parse(json ?? Constants.SERVICE_VOLUMES);
        this.publicVolumes = data.public ?? [];
        this.privateVolumes = data.private ?? [];
        this.configVolumes = data.config ?? [];
    }

    toJson(): string {
        return JSON.stringify({
            public: this.publicVolumes,
            private: this.privateVolumes,
            config: this.configVolumes
        });
    }

    toTableData(): ServiceVolumeTableRow[] {
        return this.publicVolumes.map((val: string) => new ServiceVolumeTableRow('public', this.prepValue(val)))
            .concat(this.privateVolumes.map((val: string) => new ServiceVolumeTableRow('private', this.prepValue(val))))
            .concat(this.configVolumes.map((val: string) => new ServiceVolumeTableRow('config', this.prepValue(val))));
    }

    prepValue(val: string) {
        return val.startsWith('/') ? val : '/' + val;
    }

}