/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface HistoryFilterModelInterface {
    namespace?: Array<HistoryFilterModelNamespaceEnum>;
}

/**
 * 
 * @export
 * @interface HistoryFilterModel
 */
export class HistoryFilterModel extends BaseModel<HistoryFilterModelInterface> {

    /**
     * API namespace that was used for the request
     * @type {Array<string>}
     * @memberof HistoryFilterModel
     */
    namespace?: Array<HistoryFilterModelNamespaceEnum>;

    constructor(data?: HistoryFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: HistoryFilterModel, value: StringDateInterface<HistoryFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function HistoryFilterModelFromJSON(json: any): HistoryFilterModel {
    return HistoryFilterModelFromJSONTyped(json, false);
}

export function HistoryFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = HistoryFilterModel;
    try {
        let localClass = require.context('../override', true)('./HistoryFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
    });
}

export function HistoryFilterModelToJSON(value?: HistoryFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = HistoryFilterModel;
    try {
        let localClass = require.context('../override', true)('./HistoryFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'namespace': value.namespace,
    } as StringDateInterface<HistoryFilterModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum HistoryFilterModelNamespaceEnum {
    Authenticate = 'Authenticate',
    Instance = 'Instance',
    Connector = 'Connector',
    ConnectorContainer = 'ConnectorContainer',
    Docker = 'Docker',
    History = 'History',
    Image = 'Image',
    ImageVersion = 'ImageVersion',
    Log = 'Log',
    Port = 'Port',
    ProcessMonitor = 'ProcessMonitor',
    Role = 'Role',
    Setting = 'Setting',
    System = 'System',
    User = 'User',
    Webhooks = 'Webhooks'
}


