import { ApiSuccessResponseModel, HistoryResponseModel, ClientCreateSuccessModel, ClientHistoryListRequest, ClientListResultModel, ClientRetrieveListModel, ClientStatusModel } from "api";
import Client from "api/override/ClientModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface ClientList extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_LIST;
    params: ClientRetrieveListModel;
}

export interface ClientListSuccess extends BaseSagaListResponse<ClientListResultModel> {
    type: ActionTypes.API_CLIENT_LIST_SUCCESS;
}

export interface ClientCreate extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_CREATE;
    params: Client;
}

export interface ClientCreateSuccess extends BaseSagaListResponse<ClientCreateSuccessModel> {
    type: ActionTypes.API_CLIENT_CREATE_SUCCESS;
}

export interface ClientUpdateParams {
    id: number;
    clientModel: Client;
}

export interface ClientUpdate extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_UPDATE;
    params: ClientUpdateParams;
}

export interface ClientUpdateSuccess extends BaseSagaListResponse<ClientCreateSuccessModel> {
    type: ActionTypes.API_CLIENT_UPDATE_SUCCESS;
}

export interface ClientStatusUpdateParams {
    id: number;
    clientStatusModel: ClientStatusModel;
}

export interface ClientStatusUpdate extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_STATUS_UPDATE;
    params: ClientStatusUpdateParams;
}

export interface ClientStatusUpdateSuccess extends BaseSagaListResponse<ClientCreateSuccessModel> {
    type: ActionTypes.API_CLIENT_STATUS_UPDATE_SUCCESS;
}

export interface ClientRetrieve extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_RETRIEVE;
    params: number;
}

export interface ClientRetrieveSuccess extends BaseSagaListResponse<Client> {
    type: ActionTypes.API_CLIENT_RETRIEVE_SUCCESS
}

export interface ClientDelete extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_DELETE;
    params: number;
}

export interface ClientDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_CLIENT_DELETE_SUCCESS;
}

export interface ClientHistoryList extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_HISTORY_LIST;
    params: ClientHistoryListRequest;
}

export interface ClientHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_CLIENT_HISTORY_LIST_SUCCESS;
}

export interface ClientClearListParams {
    force?: boolean;
}
export interface ClientClearList extends BaseReducerAction {
    type: ActionTypes.API_CLIENT_LIST_CLEAR;
    params?: ClientClearListParams;
}

export default class ClientActions {
    
    static CreateClientList(params: ClientRetrieveListModel): ClientList {
        return {
            type: ActionTypes.API_CLIENT_LIST,
            params: params
        }
    }

    static CreateClientListSuccess(params: ClientListResultModel): ClientListSuccess {
        return {
            type: ActionTypes.API_CLIENT_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClientCreate(params: Client): ClientCreate {
        return {
            type: ActionTypes.API_CLIENT_CREATE,
            params: params
        }
    }

    static CreateClientCreateSuccess(params: ClientCreateSuccessModel): ClientCreateSuccess {
        return {
            type: ActionTypes.API_CLIENT_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateClientUpdate(params: Client): ClientUpdate {
        return {
            type: ActionTypes.API_CLIENT_UPDATE,
            params: {
                id: params.id,
                clientModel: params
            }
        }
    }

    static CreateClientUpdateSuccess(params: ClientCreateSuccessModel): ClientUpdateSuccess {
        return {
            type: ActionTypes.API_CLIENT_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateClientStatusUpdate(params: ClientStatusUpdateParams): ClientStatusUpdate {
        return {
            type: ActionTypes.API_CLIENT_STATUS_UPDATE,
            params: {
                id: params.id,
                clientStatusModel: params.clientStatusModel
            }
        }
    }

    static CreateClientStatusUpdateSuccess(params: ClientCreateSuccessModel): ClientStatusUpdateSuccess {
        return {
            type: ActionTypes.API_CLIENT_STATUS_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateClientRetrieve(params: number): ClientRetrieve {
        return {
            type: ActionTypes.API_CLIENT_RETRIEVE,
            params: params
        }
    }

    static CreateClientRetrieveSuccess(params: Client): ClientRetrieveSuccess {
        return {
            type: ActionTypes.API_CLIENT_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateClientDelete(params: number): ClientDelete {
        return {
            type: ActionTypes.API_CLIENT_DELETE,
            params: params
        }
    }

    static CreateClientDeleteSuccess(params: ApiSuccessResponseModel): ClientDeleteSuccess {
        return {
            type: ActionTypes.API_CLIENT_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateClientHistoryList(params: ClientHistoryListRequest): ClientHistoryList {
        return {
            type: ActionTypes.API_CLIENT_HISTORY_LIST,
            params: params
        }
    }

    static CreateClientHistoryListSuccess(params: HistoryResponseModel): ClientHistoryListSuccess {
        return {
            type: ActionTypes.API_CLIENT_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearClientList(force: boolean = false): ClientClearList {
        return {
            type: ActionTypes.API_CLIENT_LIST_CLEAR,
            params: {force: force}
        }   
    }

}