import { InstanceSettingModel, InstanceSettingModelInterface } from "api";
import InstanceSettingsValueOption from "./InstanceSettingsValueOptionModel";
import MultiSelectOption from "interface/MultiSelectOption";


export default class InstanceSetting extends InstanceSettingModel {
    
    value_options: InstanceSettingsValueOption[] = [];

    constructor(data: InstanceSettingModelInterface) {
        super(data);
        this.value_options = data.value_options as InstanceSettingsValueOption[] ?? [];
    }

    getValueAsMultiSelect(): MultiSelectOption {
        return this.value_options.find(option => option.value === this.value);
    }

}