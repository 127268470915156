import MultiSelectOption from "interface/MultiSelectOption";
import Image from "api/override/ImageModel";
import { TableParams } from "models/table/TableParams";
import { ImageSaga } from "sagas/ImageSagas";
import Log from "utils/Log";
import BaseController from "./BaseController";
import { ImageApi } from "api";

export default class ImageController extends BaseController<Image> {

    imageToMultiselect = async (params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await ImageSaga.imageList(params);
                const responseToMultiSelect: MultiSelectOption[] = response['items']?.map(item => {
                    return { value: item.id, label: `${item.name}` }
                });
                this.cacheResponse(responseToMultiSelect, params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }

    getImage = async(id: number): Promise<Image | undefined> => {
        try {
            return await new ImageApi().imageRetrieve({id: id}) as Image;
        } catch (e) {
            Log(e)
        }
        return undefined;
    }

}