import { Tooltip } from '@mui/material';
import { AlertCircleOutline } from 'mdi-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ServiceNameLabelProps {
    name: string;
    configCorrect: boolean;
}

const ServiceNameLabel = (props: ServiceNameLabelProps) => {
    const {t} = useTranslation();
    if (props.configCorrect) {
        return (<>{props.name}</>);
    }
    return (
        <Tooltip title={t('service_container.view.config_not_sync')}>
            <span style={{position: 'relative'}}>{props.name} <AlertCircleOutline style={{position: 'absolute', right: -20}} fontSize='small' color='warning' /></span>
        </Tooltip>
    )
}

export default ServiceNameLabel;