/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    ConnectorModel,
    ConnectorModelFromJSON,
    ConnectorModelFromJSONTyped,
    ConnectorModelToJSON,
    ConnectorSuccessResponseModelAllOf,
    ConnectorSuccessResponseModelAllOfFromJSON,
    ConnectorSuccessResponseModelAllOfFromJSONTyped,
    ConnectorSuccessResponseModelAllOfToJSON,
} from './';


export interface ConnectorSuccessResponseModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: ConnectorModel;
}

/**
 * 
 * @export
 * @interface ConnectorSuccessResponseModel
 */
export class ConnectorSuccessResponseModel extends BaseModel<ConnectorSuccessResponseModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof ConnectorSuccessResponseModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof ConnectorSuccessResponseModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof ConnectorSuccessResponseModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof ConnectorSuccessResponseModel
     */
    success?: boolean;
    /**
     * 
     * @type {ConnectorModel}
     * @memberof ConnectorSuccessResponseModel
     */
    model?: ConnectorModel;

    constructor(data?: ConnectorSuccessResponseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorSuccessResponseModel, value: StringDateInterface<ConnectorSuccessResponseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorSuccessResponseModelFromJSON(json: any): ConnectorSuccessResponseModel {
    return ConnectorSuccessResponseModelFromJSONTyped(json, false);
}

export function ConnectorSuccessResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorSuccessResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorSuccessResponseModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorSuccessResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : ConnectorModelFromJSON(json['model']),
    });
}

export function ConnectorSuccessResponseModelToJSON(value?: ConnectorSuccessResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorSuccessResponseModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorSuccessResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': ConnectorModelToJSON(value.model),
    } as StringDateInterface<ConnectorSuccessResponseModel>;
    return createClass.toJson(value, model);
}


