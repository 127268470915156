import ActionTypes from "config/ActionTypes";
import Package from 'api/override/PackageModel';
import { ErrorInterface } from "./types/App";
import { PackageListSuccess, PackageReducerAction } from "./types/Package";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import PackageState from "./interface/PackageState";

const initialState: PackageState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,

    createHasSuccess: null,
    createErrorMessage: null,

    updateHasSuccess: null,
    updateErrorMessage: null,

    deleteHasSuccess: null,
    deleteErrorMessage: null,

    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0
    },

    timestampLastList: 0
};

export function PackageReducers(state = initialState, action: PackageReducerAction): PackageState {
    switch (action.type) {
        case ActionTypes.API_PACKAGE_CREATE:
        case ActionTypes.API_PACKAGE_RETRIEVE:
        case ActionTypes.API_PACKAGE_UPDATE:
        case ActionTypes.API_PACKAGE_DELETE:
        case ActionTypes.API_PACKAGE_LIST:
        case ActionTypes.API_PACKAGE_HISTORY_LIST:
            return {
                ...state,
                selectedObject: undefined,

                createHasSuccess: null,
                createErrorMessage: null,

                updateHasSuccess: null,
                updateErrorMessage: null,

                deleteHasSuccess: null,
                deleteErrorMessage: null,
            };

        case ActionTypes.API_PACKAGE_CREATE_SUCCESS:
            return {
                ...state,
                createHasSuccess: true,
                createErrorMessage: null
            };

        case ActionTypes.API_PACKAGE_CREATE_FAILURE:

            return {
                ...state,
                createHasSuccess: false,
                createErrorMessage: (action as ErrorInterface).data.errors
            };

        case ActionTypes.API_PACKAGE_UPDATE_SUCCESS:
            return {
                ...state,
                updateHasSuccess: true,
                updateErrorMessage: null
            };

        case ActionTypes.API_PACKAGE_UPDATE_FAILURE:
            return {
                ...state,
                updateHasSuccess: false,
                updateErrorMessage: (action as ErrorInterface).data.errors
            };

        case ActionTypes.API_PACKAGE_DELETE_SUCCESS:
            return {
                ...state,
                deleteHasSuccess: true,
                deleteErrorMessage: null
            };

        case ActionTypes.API_PACKAGE_DELETE_FAILURE:
            return {
                ...state,
                deleteHasSuccess: false,
                deleteErrorMessage: (action as ErrorInterface).data.errors.join()
            };

        case ActionTypes.API_PACKAGE_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Package,
            };

        case ActionTypes.API_PACKAGE_LIST_SUCCESS:
            const result = action as PackageListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Package>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_PACKAGE_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_PACKAGE_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}