import {ProcessModel, ProcessModelInterface} from "api";
import DateTimeFormatter from "../../config/DateTimeFormatter";

export default class Process extends ProcessModel {

    constructor(data?: ProcessModelInterface) {
        super(data);
        this.process_logs = this.process_logs.map(log => {
            try {
                log.additional_data = JSON.parse(log.additional_data)
            } catch (e) {}
            return log
        });
    }

    createdDate(): string {
        return this.created_at != null ? DateTimeFormatter( this.created_at ) : '';
    }

    startedDate(): string {
        return this.started_at != null ? DateTimeFormatter( this.started_at ) : '';
    }

    finishedDate(): string {
        return this.finished_at != null ? DateTimeFormatter( this.finished_at ) : '';
    }

}