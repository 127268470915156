import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import SingleSelect from "components/form_controls/SingleSelect";
import MultiSelectOption from "interface/MultiSelectOption";
import ImageVersionController from "controllers/ImageVersionController";
import { TableParams } from "models/table/TableParams";

interface ImageVersionHistorySelectProps {
    onVersionSelect?: (imgVersionId: number) => void;
    imageId: number;
}

const ImageVersionHistorySelect = (props: ImageVersionHistorySelectProps) => {
    const { onVersionSelect, imageId} = props
    const { t } = useTranslation();
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const [chosenImageVersion, setChosenImageVersion] = React.useState<MultiSelectOption>(null);
    const getImageVersions = imageVersionController.imageVersionToMultiselect;
    const setImageVersion = (_, val: MultiSelectOption) => {
        setChosenImageVersion(val);
        onVersionSelect(+val.value)
    }
    return (
        <div style={{padding: '10px 150px'}}>
            <SingleSelect
                label={t('image_versions.model')}
                value={chosenImageVersion}
                name="image-version-select"
                onChange={setImageVersion}
                getAsyncValuesOnSearch
                tableParams={params}
                getAsyncOptions={(params: TableParams) => getImageVersions(imageId, params)}
            />
        </div>
    );
}

export default ImageVersionHistorySelect;