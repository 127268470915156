import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useFormBuilderStyles = makeStyles()((theme: Theme) => {
    return {
        builder_container: {

        },
        header: {   
            padding: '16px 37px',
            '& .MuiCardHeader-avatar': {
                height: 24,
                width: 24,
                marginRight: 0,
                '& svg': {
                    height: '100%',
                    width: '100%'
                }
            },
            '& .MuiCardHeader-action': {
                alignSelf: 'center',
                margin: 0
            }
        },
        header_container: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 20,
            '& > button': {
                padding: 4,
                marginLeft: 20
            }
        },
        header_title: {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
                color: '#696969'
            }
        },
        pane_title: {
            textAlign: 'center',
            margin: '20px 0',
            width: '100%',
            '& > h3': {
                marginBottom: 2
            },
            '& > p': {
                fontSize: 12,
                color: '#868686'
            }
        },
        input_pane: {
            width: '100%',
            padding: '0 20px',
            [theme.breakpoints.down('lg')]: {
                padding: 0
            }
        },
        add_input_btn: {
            color: '#303030',
            padding: 14,
            height: 20,
            width: 20,
            marginLeft: 10,
            transition: 'transform 0.3s',
            '&:hover': {
                transform: 'rotate(90deg)',
                color: theme.palette.primary.main,
                background: '#fff'
            }
        },
        template_input_container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        preview_pane: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 20px',
            [theme.breakpoints.down('lg')]: {
                padding: 0
            }
        },
        preview_input: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '80%',
            borderRadius: 6,
            paddingLeft: 10,
            cursor: 'pointer',
            '&:hover': {
                background: '#EDF7FF'
            },
            '&.selected': {
                background: '#E3F2FF',
            }
        },
        options_pane: {
            padding: '0 20px',
            [theme.breakpoints.down('lg')]: {
                padding: 0
            }
        },
        textarea: {
            width: '100%',
            padding: 6,
            margin: '10px 0',
            resize: 'vertical',
            background: '#fafafa',
            borderColor: '#cecece'
        },
        radio_group: {
            '& > label > span': {
                padding: '2px 9px'
            }
        },
        dropdown: {
            width: '100%'
        },
        remove_input_btn: {
            padding: 5,
            color: '#EF2121'
        },
        rearrange_btns_container: {
            display: 'flex',
            justifyContent: 'center',
            '& button': {
                color: theme.palette.primary.main
            }
        },
        options_field_container: {
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            '& > button': {
                padding: 0,
                marginLeft: 10,
                background: '#EF2121',
                color: '#fff',
                '&:hover': {
                    background: '#E71414'
                }
            }
        },
        add_option_btn: {
            padding: 4,
            marginTop: 10,
            color: '#fff',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.primary.dark
            }
        },
        options_pane_inputs: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form_save_btn: {
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 4,
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.1)',
            }
        }
    }
})

export default useFormBuilderStyles
