/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface StepviewWidgetFilterModelInterface {
    id?: number;
}

/**
 * 
 * @export
 * @interface StepviewWidgetFilterModel
 */
export class StepviewWidgetFilterModel extends BaseModel<StepviewWidgetFilterModelInterface> {

    /**
     * Default ID filter so OpenAPI generates files
     * @type {number}
     * @memberof StepviewWidgetFilterModel
     */
    id?: number;

    constructor(data?: StepviewWidgetFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewWidgetFilterModel, value: StringDateInterface<StepviewWidgetFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewWidgetFilterModelFromJSON(json: any): StepviewWidgetFilterModel {
    return StepviewWidgetFilterModelFromJSONTyped(json, false);
}

export function StepviewWidgetFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewWidgetFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewWidgetFilterModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
    });
}

export function StepviewWidgetFilterModelToJSON(value?: StepviewWidgetFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewWidgetFilterModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
    } as StringDateInterface<StepviewWidgetFilterModel>;
    return createClass.toJson(value, model);
}


