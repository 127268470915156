import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import Widget from 'api/override/WidgetModel';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn from 'models/table/FilterColumn';
import WidgetActions from 'reducers/types/Widget';
import { WidgetModel, WidgetRetrieveListModel } from 'api';
import WidgetState from 'reducers/interface/WidgetState';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import * as MDIcons from 'mdi-material-ui';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';

interface StateProps {
    widgetReducer: WidgetState;
    appReducer: AppState;
}

interface DispatchProps {
    listWidgets: (params: WidgetRetrieveListModel) => void;
    clearWidgets: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    widget?: Widget;
}

class WidgetList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);

        this.state = {
            widget: null
        };
    }

    getWidgetIcon = (icon: string): JSX.Element => {
        const Icon = MDIcons[icon];
        return <Icon/>
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name')}),
            new TableColumn({ key: 'icon', isSortable: true, label: i18next.t('widgets.icon'), customValue: (row: Widget) => <row.mdIcon />}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_WIDGET_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('widgets.model_plural'))
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.widgetReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listWidgets( this.getParams().getApiFilter() )
        }
    }

    onDetails = (row: WidgetModel) => {
        this.props.navigate(`/widgets/${row.id}`)
    }

    onCreate = () => {
        this.props.navigate("/widgets/create")
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('widgets', this.props.location)){
            this.props.clearWidgets();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('widgets.model')}
                            headerIcon={<MDIcons.WidgetsOutline/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('widgets.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('widgets.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={this.props.widgetReducer.collection.count_filtered}
                                    listItems={this.props.widgetReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_WIDGET_LIST)}
                                    tableParams={this.getParams()}
                                    delegate={ this } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return []
    }

}

const  mapStateToProps = (state: any) => ({
    widgetReducer: state.widgets,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listWidgets: (params: WidgetRetrieveListModel) => dispatch(WidgetActions.CreateWidgetList(params)),
        clearWidgets: (params: boolean) => dispatch(WidgetActions.CreateClearWidgetList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(WidgetList, TableStyles)
            )
        )
    )
);