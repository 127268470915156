import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useToolStyles = makeStyles()((theme: Theme) => {
    return {
        db_transfer_form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        db_transfer_fields_container: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 20px 0 20px',
            minHeight: 100,
            flexWrap: 'wrap'
        },
        db_transfer_text_field: {
            width: '40%',
            flexGrow: 1
        },
        db_transfer_checkbox: {
            position: 'relative',
            float: 'right'
        },
        db_transfer_fields_to: {
            width: '40%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            marginTop: 40
        },
        db_transfer_icon: {
            margin: '0 20px',
            color: theme.palette.primary.main
        },
        db_transfer_validate_btn: {
            margin: 20
        },
        db_transfer_btn: {
            margin: '20px auto',
            display: 'block'
        },
        errors_field: {
            color: 'red'
        },
        radio_group: {
            flexDirection: 'row !important',//SUFFERING
            padding: '10px 50px !important',//PAIN
            justifyContent: 'center !important' //AGONY
        } as any,
        form_control_label: {
            margin: 6,
            '& .MuiRadio-root': {
                display: 'none'
            },
            '& .Mui-checked + span > div': {
                background: theme.palette.primary.main,
                color: '#fff',
            }
        },
        form_control_label_span: {
            background: '#f0f0f0',
            padding: '10px 14px',
            borderRadius: 10,
        },
        domain_label: {
            textTransform: 'lowercase',
            fontSize: '10px'
        },
        name_label: {
            fontWeight: 600
        },
        form_label: {
            margin: 'auto',
            textAlign: 'center',
            marginTop: 14
        },
        instance_options_container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
})

export default useToolStyles
