import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import TableColumn from '../../models/table/TableColumn';
import TableList from 'components/TableList';
import { TableParams, TableParamsSorting } from '../../models/table/TableParams';
import { RootState } from 'store/AppStore';
import { useDispatch, useSelector } from 'react-redux';
import Process from 'api/override/ProcessModel';
import ProcessLogable from 'interface/ProcessLogable';
import { ProcessModel } from 'api';
import ProcessLogDetailsModal from 'views/Instances/components/ProcessLogDetailsModal';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import useTableDelegateHook from 'hooks/TableDelegateHook';

interface ProcessLogsTabProps {
    model: ProcessLogable
}
/*
t('node.service-container-create')
t('node.service-container-delete')
t('node.instance-cleanup')
*/
const ProcessLogsTab = (props: ProcessLogsTabProps) => {
    const [tableParams] = useState<TableParams>(new TableParams( 25, 0, new TableParamsSorting('created_at', 'desc'), '', [] ))
    const [selectedLog, setSelectedLog] = useState<ProcessModel>(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
    const { model } = props;
    const dispatch = useDispatch();
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            dispatch({type: ActionTypes['API_'+ model.actionName.toUpperCase() + '_LOGS_LIST'], 
            params: {
                uuid: model?.uuid,
                tableParams: tableParams
            }})
        },
        onDetails(row: ProcessModel) {
            setSelectedLog(row);
            setIsDetailsModalOpen(true)
        }
    });
    const columns = [
        new TableColumn({ key: 'process_name', isSortable: false, label: i18next.t('connector.name'), customValue: (row: Process) => i18next.t(`node.${row.process_name}`)}),
        new TableColumn({ key: 'state', isSortable: false, label: i18next.t('connector.status')}),
        new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('connector.date'), customValue: (row: Process) => row?.createdDate()})
    ];

    const globalReducer = useSelector((state: RootState) => state)
    const componentDidMount = () => {
        const ncListener = new NCListener(ActionTypes[model.actionName.toUpperCase() + '_VIEW_UPDATE_LOGS'], delegate.getData)
        NotificationCenter.default.addNCListener(ncListener)
        return () => {NotificationCenter.default.removeListener(ncListener)}
    }
    // eslint-disable-next-line
    useEffect(componentDidMount, [])    
    return (
        <div>
            <Grid item xs={12} >
                <TableList
                    hasDetailsAction
                    columns={columns}
                    count={globalReducer[model.reducerName].logs?.count_filtered}
                    listItems={globalReducer[model.reducerName].logs?.items}
                    isRefreshing={globalReducer.app.isFetchingData.get(ActionTypes['API_'+ model.actionName.toUpperCase() + '_LOGS_LIST'])}
                    tableParams={ tableParams }
                    delegate={ delegate }
                    idColumn={ 'token' } />
            </Grid>
            <ProcessLogDetailsModal 
                open={isDetailsModalOpen}
                onModalClosePress={() => setIsDetailsModalOpen(false)}
                title={i18next.t('process.process_details')}
                processLog={selectedLog}
            />
        </div>
    );
}

export default ProcessLogsTab