import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import Stepview from "api/override/StepviewModel";
import StepviewWidgetView from "./StepviewWidgetView";
import { FieldArray, FormikProps } from "formik";
import StepviewWidget from "api/override/StepviewWidgetModel";
import Widget from "api/override/WidgetModel";
import { Divider } from '@mui/material';

interface StepviewPreviewProps {
    formik: FormikProps<Stepview>
    widgets: Array<Widget>;
}

const StepviewPreview = (props: StepviewPreviewProps) => {
    const {formik, widgets} = props
    const { classes } = useWizardBuilderStyles()
    const {t} = useTranslation()
    const hasWidgets: boolean = formik.values.stepview_widgets?.length > 0

    return (
        <div className={classes.stepview_preview_root}>
            <h3>{t('stepviews.preview')}</h3>
            <div className={classes.stepview_preview_container}>
                {hasWidgets === true ? 
                    <FieldArray name='stepview_widgets'>
                        {({ insert, remove, push }) => (
                            <>
                                {(formik.values.stepview_widgets as Array<StepviewWidget>).map((stepviewWidget: StepviewWidget, index: number) =>
                                    <Fragment key={`stepview_widget_container_${index}`} >
                                        <StepviewWidgetView 
                                            widgets={widgets} 
                                            key={`stepview_widget_${index}`} 
                                            stepviewWidget={stepviewWidget} 
                                            formik={formik} 
                                            swWidgetIndex={index}
                                            onRemove={() => remove(index)}
                                        />
                                        <Divider/>
                                    </Fragment>
                                )}
                            </>
                        )}
                    </FieldArray>
                :
                    <p>{t('stepviews.preview.no_widgets_added')}</p> // No widgets added yet. Select one of the widgets shown above.
                }
            </div>
        </div>
    )
}

export default StepviewPreview;