/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    SettingCreateSuccessModelAllOf,
    SettingCreateSuccessModelAllOfFromJSON,
    SettingCreateSuccessModelAllOfFromJSONTyped,
    SettingCreateSuccessModelAllOfToJSON,
    SettingModel,
    SettingModelFromJSON,
    SettingModelFromJSONTyped,
    SettingModelToJSON,
} from './';


export interface SettingCreateSuccessModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: SettingModel;
}

/**
 * 
 * @export
 * @interface SettingCreateSuccessModel
 */
export class SettingCreateSuccessModel extends BaseModel<SettingCreateSuccessModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof SettingCreateSuccessModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof SettingCreateSuccessModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof SettingCreateSuccessModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof SettingCreateSuccessModel
     */
    success?: boolean;
    /**
     * 
     * @type {SettingModel}
     * @memberof SettingCreateSuccessModel
     */
    model?: SettingModel;

    constructor(data?: SettingCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: SettingCreateSuccessModel, value: StringDateInterface<SettingCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function SettingCreateSuccessModelFromJSON(json: any): SettingCreateSuccessModel {
    return SettingCreateSuccessModelFromJSONTyped(json, false);
}

export function SettingCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SettingCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./SettingCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : SettingModelFromJSON(json['model']),
    });
}

export function SettingCreateSuccessModelToJSON(value?: SettingCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SettingCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./SettingCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': SettingModelToJSON(value.model),
    } as StringDateInterface<SettingCreateSuccessModel>;
    return createClass.toJson(value, model);
}


