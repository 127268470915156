import {InstanceUserModel} from "api";
import Historiable from "interface/Historiable";
import MultiSelectOption from "interface/MultiSelectOption";

export default class InstanceUser extends InstanceUserModel implements Historiable, MultiSelectOption {

    actionName: string = "instanceUser"
    reducerName: string = "instanceUser"

    value: string | number;
    label: string;

    constructor(data?: InstanceUserModel) {
        super(data);
        this.label = `${this.email} | ${this.first_name} ${this.last_name}`
        this.value = this.id
    }

}