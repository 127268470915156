import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import Setting from 'api/override/SettingModel';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import SettingActions from 'reducers/types/Setting';
import { SettingModel, SettingRetrieveListModel } from 'api';
import SettingState from 'reducers/interface/SettingState';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { Cog } from 'mdi-material-ui';
import EnvUtil from 'utils/EnvUtil';

interface StateProps {
    settingReducer: SettingState;
    appReducer: AppState;
}

interface DispatchProps {
    listSettings: (params: SettingRetrieveListModel) => void;
    clearSettings: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    setting?: SettingModel;
}

class SettingList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);

        this.state = {
            setting: null
        };
    }

    configureColumns = () => {
        let cols = [
            new TableColumn({ key: 'key', isSortable: true, label: i18next.t('settings.key'), align: 'left' }),
            new TableColumn({ key: 'value', isSortable: true, label: i18next.t('settings.value'), customValue: (row: Setting) => (row.encrypted ? <em>{i18next.t('settings.value')}</em> : row.value), align: 'left' })
        ];
        if (!EnvUtil.isProduction()) {
            cols = cols.concat(new TableColumn({ key: 'stage_value', isSortable: true, label: i18next.t('settings.stage_value'), customValue: (row: Setting) => (row.encrypted && Boolean(row.stage_value) ? <em>{i18next.t('settings.stage_value')}</em> : row.stage_value), align: 'left' }))
        }
        this.columns = cols.concat([
            new TableColumn({ key: 'encrypted', isSortable: true, label: i18next.t('settings.encrypted'), customValue: (row: Setting) => row.encrypted ? i18next.t('general.yes'): i18next.t('general.no'), align: 'center' }),
            new TableColumn({ key: 'group', isSortable: true, label: i18next.t('settings.group'), align: 'right' })
        ]);
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_SETTING_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('settings.model_plural'))
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.settingReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listSettings( this.getParams().getApiFilter() )
        }
    }

    onDetails = (row: SettingModel) => {
        this.props.navigate(`/settings/${row.key}`)
    }

    onCreate = () => {
        this.props.navigate("/settings/create")
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('settings', this.props.location)){
            this.props.clearSettings();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('settings.model')}
                            headerIcon={<Cog/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('settings.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('settings.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={this.props.settingReducer.collection.count_filtered}
                                    listItems={this.props.settingReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_SETTING_LIST)}
                                    tableParams={this.getParams()}
                                    delegate={ this }
                                    idColumn={ 'key' } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('key', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            FilterColumn.makeFilter('group', FilterTypes.ENUM, i18next.t('settings.group'), {'rancher': 'Rancher', 'default': 'Default', 'load_balancers': 'Load Balancers'})
        ]
    }

}

const  mapStateToProps = (state: any) => ({
    settingReducer: state.settings,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listSettings: (params: SettingRetrieveListModel) => dispatch(SettingActions.CreateSettingList(params)),
        clearSettings: (params: boolean) => dispatch(SettingActions.CreateClearSettingList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(SettingList, TableStyles)
            )
        )
    )
);