import { takeLatest, put } from 'redux-saga/effects';

import ActionTypes from "../config/ActionTypes";
import {InstanceApi, InstanceAuthenticateApi, InstanceAuthenticateCreateTokenRequest, InstanceConnectorListRequest, InstanceConnectorListResultModel, InstanceConnectorRetrieveListModel, InstanceCreateRequest, InstanceDeleteRequest, InstanceHistoryListRequest, InstanceListRequest, InstanceListResultModel, InstanceLogsRetrieveRequest, InstanceRetrieveRequest, InstanceServiceListRequest, InstanceSetImageVersionRequest, InstanceTasksRequest, InstanceUpdateRequest, InstanceUsersRetrieveRequest, ServiceContainerListResultModel, ServiceContainerRetrieveListModel} from "api";
import { ApiHandler } from './APIHandler';
import InstanceActions, { InstanceAuthenticateCreateToken, InstanceConnectorList, InstanceCreate, InstanceDelete, InstanceHistoryList, InstanceList, InstanceLogsRetrieve, InstanceRetrieve, InstanceRetrieveSettings, InstanceServiceContainerList, InstanceSetImageVersion, InstanceToggleMaintenance, InstanceTriggerJob, InstanceUpdate, InstanceUpdateAdminPassword, InstanceUpdateSettings, InstanceUsersRetrieve } from 'reducers/types/Instance';
import { AppActions } from 'reducers/types/App';
import { TableParams } from 'models/table/TableParams';

export function* InstanceSagas() {
    yield takeLatest(ActionTypes.API_INSTANCE_UPDATE, InstanceSaga.instanceUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_RETRIEVE, InstanceSaga.instanceRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_LIST, InstanceSaga.instanceListWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_CREATE, InstanceSaga.instanceCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_DELETE, InstanceSaga.instanceDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_LOGS_LIST, InstanceSaga.instanceLogsRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_LIST_CONNECTORS, InstanceSaga.instanceConnectorsListWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS, InstanceSaga.instanceServiceContainersListWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_TRIGGER_JOB, InstanceSaga.instanceTriggerJobWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_SET_IMAGE_VERSION, InstanceSaga.instanceSetImageVersionWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_HISTORY_LIST, InstanceSaga.instanceHistoryListWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS, InstanceSaga.instanceRetrieveSettingsWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_UPDATE_SETTINGS, InstanceSaga.instanceUpdateSettingsWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD, InstanceSaga.instanceUpdateAdminPasswordWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN, InstanceSaga.instanceAuthenticateCreateTokenWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_USERS_RETRIEVE, InstanceSaga.instanceUsersRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE, InstanceSaga.instanceToggleMaintenanceModeWorkerSaga);
}

export class InstanceSaga {
 
    static *instanceUpdateWorkerSaga(action: InstanceUpdate) {
        try {
            const instanceArgs = {
                uuid: action.params?.uuid,
                instanceModel: action.params?.instanceModel,
                deleted: true
            } as InstanceUpdateRequest

            const data = yield ApiHandler(new InstanceApi().instanceUpdate, instanceArgs);
            // dispatch a success action to the store with the activities
            yield put(InstanceActions.CreateInstanceUpdateSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *instanceRetrieveWorkerSaga(action: InstanceRetrieve) {
        try {            
            const instanceArgs = {
                uuid: action.params,
                deleted: true
            } as InstanceRetrieveRequest 
            const data = yield ApiHandler(new InstanceApi().instanceRetrieve, instanceArgs);
    
            // dispatch a success action to the store with the activities
            yield put(InstanceActions.CreateInstanceRetrieveSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *instanceListWorkerSaga(action: InstanceList) {
        try {
    
            const instanceArgs = {
                instanceRetrieveListModel: action.params,
                deleted: true
            } as InstanceListRequest
    
            const data = yield ApiHandler(new InstanceApi().instanceList, instanceArgs);
    
            // dispatch a success action to the store with the activities
            yield put(InstanceActions.CreateInstanceListSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_LIST_FAILURE, error as Error))
        }
    }

    static async instanceList (tableParams?: TableParams): Promise<InstanceListResultModel> {
        const retrieveListRequest = {
            instanceRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as InstanceListRequest;
        return await new InstanceApi().instanceList(retrieveListRequest);
    }
    
    static *instanceCreateWorkerSaga(action: InstanceCreate) {
        try {
    
            const instanceArgs = {
                instanceCreateModel: action.params
            } as InstanceCreateRequest
    
            const data = yield ApiHandler(new InstanceApi().instanceCreate, instanceArgs);
    
            // dispatch a success action to the store with the activities
            yield put(InstanceActions.CreateInstanceCreateSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            const jsonError = yield error['clone']?.()['json']?.()
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_CREATE_FAILURE, error as Error))
            if (!jsonError.errors.bbIsEmpty() && jsonError.errors[0].includes('Domain already has an existing registration')) {
                yield put(InstanceActions.CreateInstanceCreateFailure(jsonError.data))
            }
        }
    }

    static* instanceLogsRetrieveWorkerSaga(action: InstanceLogsRetrieve) {
        try {
            const tableParams = action.params.tableParams;
            const instanceArgs = {
                uuid: action.params.uuid,
                limit: tableParams.limit,
                offset: tableParams.offset,
                deleted: true,
                sortBy: tableParams.sorting?.column ?? 'started_at',
                sortDir: tableParams.sorting?.direction ?? 'desc',
                searchText: tableParams.search_text
            } as InstanceLogsRetrieveRequest
            const data = yield ApiHandler(new InstanceApi().instanceLogsRetrieve, instanceArgs);
            yield put(InstanceActions.CreateInstanceLogsRetrieveSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_LOGS_LIST_FAILURE, error as Error))
        }
    }
    
    static *instanceDeleteWorkerSaga(action: InstanceDelete) {
        try {
    
            const instanceArgs = {
                uuid: action.params.uuid,
                deletionReason: action.params.deletionReason
            } as InstanceDeleteRequest 
    
            const data = yield ApiHandler(new InstanceApi().instanceDelete, instanceArgs);
    
            // dispatch a success action to the store with the activities
            yield put(InstanceActions.CreateInstanceDeleteSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_DELETE_FAILURE, error as Error))
        }
    }
    
    static *instanceConnectorsListWorkerSaga(action: InstanceConnectorList) {
        try {
            const instanceArgs = {
                uuid: action.params.uuid,
                instanceConnectorRetrieveListModel: action.params.instanceConnectorRetrieveListModel
            } as InstanceConnectorListRequest
    
            const data = yield ApiHandler(new InstanceApi().instanceConnectorList, instanceArgs);
            yield put(InstanceActions.CreateInstanceConnectorListSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_LIST_CONNECTORS_FAILURE, error as Error))
        }
    }

    static *instanceServiceContainersListWorkerSaga(action: InstanceServiceContainerList) {
        try{
            const instanceArgs = {
                uuid: action.params.uuid,
                serviceContainerRetrieveListModel: action.params.serviceContainerRetrieveListModel
            } as InstanceServiceListRequest
            const data = yield ApiHandler(new InstanceApi().instanceServiceList , instanceArgs);
            yield put(InstanceActions.CreateInstanceServiceContainerListSuccess(data))
        }catch(error){
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS_FAILURE, error as Error))
        }
    }

    static *instanceTriggerJobWorkerSaga(action: InstanceTriggerJob) {
        try {
            const instanceArgs = {
                uuid: action.params.uuid,
                task: action.params.task,
                deleted: true,
                uuids: action.params.uuids?.bbIsEmpty() ? undefined : action.params.uuids
            } as InstanceTasksRequest
            const data = yield ApiHandler(new InstanceApi().instanceTasks, instanceArgs)
            yield put(InstanceActions.CreateInstanceTriggerJobSuccess(data))
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_TRIGGER_JOB_FAILURE, error as Error))
        }
    }
    
    static *instanceSetImageVersionWorkerSaga(action: InstanceSetImageVersion) {
        try {
            const instanceArgs = {
                uuid: action.params?.uuid,
                instanceSetImageVersionModel: action.params?.instanceSetImageVersionModel
            } as InstanceSetImageVersionRequest
    
            const data = yield ApiHandler(new InstanceApi().instanceSetImageVersion, instanceArgs)
            yield put(InstanceActions.CreateInstanceSetImageVersionSuccess(data))
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_FAILURE, error as Error))
        }
    }
    
    static *instanceHistoryListWorkerSaga(action: InstanceHistoryList) {
        try {
            const instanceArgs = {
                uuid: action.params?.uuid,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as InstanceHistoryListRequest
    
            const data = yield ApiHandler(new InstanceApi().instanceHistoryList, instanceArgs)
            yield put(InstanceActions.CreateInstanceHistoryListSuccess(data))
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_FAILURE, error as Error))
        }
    }

    static *instanceRetrieveSettingsWorkerSaga(action: InstanceRetrieveSettings) {
        try {
            const data = yield ApiHandler(new InstanceApi().instanceSettingsRetrieve, { uuid: action.params });
            yield put(InstanceActions.CreateInstanceRetrieveSettingsSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS_FAILURE, error as Error))
        }
    }

    static *instanceUpdateSettingsWorkerSaga(action: InstanceUpdateSettings) {
        try {
            const data = yield ApiHandler(new InstanceApi().instanceSettingsUpdate, action.params);
            yield put(InstanceActions.CreateInstanceUpdateSettingsSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_UPDATE_SETTINGS_FAILURE, error as Error))
        }
    }

    static *instanceUpdateAdminPasswordWorkerSaga(action: InstanceUpdateAdminPassword) {
        try {
            const data = yield ApiHandler(new InstanceApi().instanceAdminUpdate, action.params);
            yield put(InstanceActions.CreateInstanceUpdateAdminPasswordSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD_FAILURE, error as Error))
        }
    }

    static *instanceAuthenticateCreateTokenWorkerSaga(action: InstanceAuthenticateCreateToken) {
        try {
            const instanceAuthArgs = {
                uuid: action.params?.uuid,
                instanceAuthenticateModel: action.params?.instanceAuthenticateModel
            } as InstanceAuthenticateCreateTokenRequest

            const data = yield ApiHandler(new InstanceAuthenticateApi().instanceAuthenticateCreateToken, instanceAuthArgs)
            yield put(InstanceActions.CreateInstanceAuthenticateCreateTokenSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_FAILURE, error as Error))
        }
    }

    static *instanceUsersRetrieveWorkerSaga(action: InstanceUsersRetrieve){
        try {
            const instanceArgs = {
                uuid: action.params?.uuid
            } as InstanceUsersRetrieveRequest

            const data = yield ApiHandler(new InstanceApi().instanceUsersRetrieve, instanceArgs)
            yield put(InstanceActions.CreateInstanceUsersRetrieveSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_USERS_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static async connectorContainersList (instanceUuid: string, tableParams?: TableParams): Promise<InstanceConnectorListResultModel> {
        const retrieveListRequest = {
            uuid: instanceUuid,
            instanceConnectorRetrieveListModel: new InstanceConnectorRetrieveListModel(tableParams.getApiFilter())
        } as InstanceConnectorListRequest;
        return await new InstanceApi().instanceConnectorList(retrieveListRequest);
    }

    static async serviceContainersList (instanceUuid: string, tableParams?: TableParams): Promise<ServiceContainerListResultModel> {
        const retrieveListRequest = {
            uuid: instanceUuid,
            serviceContainerRetrieveListModel: new ServiceContainerRetrieveListModel(tableParams.getApiFilter())
        } as InstanceServiceListRequest;
        return await new InstanceApi().instanceServiceList(retrieveListRequest);
    }

    static *instanceToggleMaintenanceModeWorkerSaga(action: InstanceToggleMaintenance) {
        try {
            const instanceArgs = {
                uuid: action.params
            } as InstanceUpdateRequest
            const data = yield ApiHandler(new InstanceApi().instanceToggleMaintenance, instanceArgs)
            yield put(InstanceActions.CreateInstanceToggleMaintenanceSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE_FAILURE, error as Error))
        }
    }

}

