import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps, componentConnect } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import NotificationCenter from 'services/NotificationCenter';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { BaseAction } from 'reducers/interface/ReducerAction';
import ReloadDataButton from 'components/ReloadDataButton';
import WizardActions from 'reducers/types/Wizard';
import WizardState from 'reducers/interface/WizardState';
import Wizard from 'api/override/WizardModel';
import HideOnProd from 'components/HideOnProd';
import { WizardHistoryListRequest } from 'api/apis/WizardApi';
import { WizardCreateSuccessModel } from 'api/models/WizardCreateSuccessModel';
import TabManager, { TabView } from 'components/tabs/TabManager';
import DetailsViewHeader from 'components/DetailsViewHeader';
import HistoryTab from 'components/tabs/HistoryTab';
import WizardDetails from './components/WizardDetails';

interface StateProps {
    appReducer: AppState;
    wizardReducer: WizardState;
}

interface DispatchProps {
    retrieveWizard: (params: number) => void;
    listWizardHistory: (params: WizardHistoryListRequest) => void;
    updateWizard: (params: Wizard) => void;
    createWizard: (params: Wizard) => void;
    deleteWizard: (params: number) => void;
    clearWizards: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    wizard?: Wizard;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class WizardView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            wizard: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_WIZARD_RETRIEVE_FAILURE,
            ActionTypes.API_WIZARD_CREATE_FAILURE,
            ActionTypes.API_WIZARD_UPDATE_FAILURE,
            ActionTypes.API_WIZARD_DELETE_FAILURE,
            ActionTypes.API_WIZARD_HISTORY_LIST_FAILURE,
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_DELETE_SUCCESS, 
            this.onDeleteSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({wizard: action.params}, () => {
            this.updateTabTitle(this.state.wizard?.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const wizardSuccess: WizardCreateSuccessModel = action.params
        this.setState({wizard: new Wizard(wizardSuccess.model)})
        this.props.navigate(`/wizards/${wizardSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
        this.props.clearWizards(true)
        this.reloadData()
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
        this.props.clearWizards(true)
        this.props.navigate('/wizards')
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveWizard(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.WIZARD_VIEW_UPDATE_HISTORY}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.wizard != null
    }

    shouldRenderCreateWizard = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.props.navigate(`/wizards/${this.props.params.slug}/builder`)
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    updateWizard = (wizard: Wizard) => {
        this.props.updateWizard(wizard)
    }

    createWizard = (wizard: Wizard) => {
        this.props.createWizard(wizard)
    }

    renderView() {
        const { classes, appReducer, wizardReducer } = this.props;
        return (
            <HideOnProd>
                <React.Fragment>
                    {!this.shouldRenderCreateWizard() && <ReloadDataButton loadData={this.reloadData} />}
                    <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_WIZARD_RETRIEVE || ActionTypes.API_STEPVIEW_CREATE)}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container maxWidth={"lg"}>
                        <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                            <Grid item xs={12} md={12} lg={9}>
                                <Card className={classes.root}>
                                    {this.shouldRenderDetails() &&
                                        <div>
                                        <DetailsViewHeader
                                            model={i18next.t('wizards.model')}
                                            title={this.state.wizard?.name}
                                        />
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/wizards/${this.state.wizard?.id}`,
                                                    view: (
                                                        <WizardDetails 
                                                            wizard={this.state.wizard} 
                                                            onDelete={this.onDelete}
                                                            onEdit={this.onEdit}
                                                        />
                                                    )
                                                },{
                                                    title:  i18next.t('history.model'),
                                                    link: `/wizards/${this.state.wizard?.id}/history`,
                                                    initialTab: this.isPathIncluded('history'),
                                                    view: (
                                                        <HistoryTab 
                                                            model={this.state.wizard}
                                                        />
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if (newTab.link.includes('history')){
                                                    if (wizardReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_WIZARD_HISTORY_LIST)) {
                                                        this.sendNotification({type: ActionTypes.WIZARD_VIEW_UPDATE_HISTORY}, null);
                                                    }
                                                }
                                            }}
                                        />
                                        </div>
                                    }
                                    {this.shouldRenderCreateWizard() &&
                                        <></>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                    
                    <ConfirmDeleteDialog
                        open={this.state.isDeleteModalOpen}
                        onModalClosePress={this.dialogClosed}
                        onConfirm={() => {
                            this.props.deleteWizard(this.state.wizard?.id)
                        }}
                        title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('wizards.model')).toLowerCase()})}
                    />
                </React.Fragment>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    wizardReducer: state.wizards
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveWizard: (params: number) => dispatch(WizardActions.CreateWizardRetrieve(params)),
        listWizardHistory: (params: WizardHistoryListRequest) => dispatch(WizardActions.CreateWizardHistoryList(params)),
        updateWizard: (params: Wizard) => dispatch(WizardActions.CreateWizardUpdate(params)),
        createWizard: (params: Wizard) => dispatch(WizardActions.CreateWizardCreate(params)),
        deleteWizard: (params: number) => dispatch(WizardActions.CreateWizardDelete(params)),
        clearWizards: (params: boolean) => dispatch(WizardActions.CreateClearWizardList(params))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(WizardView, InstanceViewStyles)
            )
        )
    )
);