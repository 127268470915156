import { AuthResultModel } from "api";
import Auth from "api/override/AuthenticateModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface SignIn extends BaseReducerAction {
    type: ActionTypes.API_SIGN_IN;
    params: Auth;
}

export interface SignInSuccess extends BaseSagaListResponse<AuthResultModel> {
    type: ActionTypes.API_SIGN_IN_SUCCESS;
}

export interface Impersonate extends BaseReducerAction {
    type: ActionTypes.API_IMPERSONATE;
    params: Auth;
}

export interface ImpersonateSuccess extends BaseSagaListResponse<AuthResultModel> {
    type: ActionTypes.API_IMPERSONATE_SUCCESS;
}

export interface AuthenticateToken extends BaseReducerAction {
    uuid: string
}

export interface AuthenticateTokenSuccess extends BaseReducerAction {
    response: AuthResultModel
}

export interface AuthenticateInvalidateToken extends AuthenticateToken {
    type: ActionTypes.API_INVALIDATE_TOKEN;
}

export interface AuthenticateInvalidateTokenSuccess extends AuthenticateTokenSuccess {
    type: ActionTypes.API_INVALIDATE_TOKEN_SUCCESS;
}

export interface AuthenticateSystemToken extends AuthenticateToken {
    type: ActionTypes.API_SYSTEM_TOKEN;
}

export interface AuthenticateSystemTokenSuccess extends AuthenticateTokenSuccess {
    type: ActionTypes.API_SYSTEM_TOKEN_SUCCESS;
}


export default class AuthenticateActions {

    static CreateSignIn(params: Auth): SignIn {
        return {
            type: ActionTypes.API_SIGN_IN,
            params: params
        }
    }

    static CreateSignInSuccess(params: AuthResultModel): SignInSuccess {
        return {
            type: ActionTypes.API_SIGN_IN_SUCCESS,
            params: params
        }
    }

    static CreateImpersonate(params: Auth): Impersonate {
        return {
            type: ActionTypes.API_IMPERSONATE,
            params: params
        }
    }

    static CreateImpersonateSuccess(params: AuthResultModel): ImpersonateSuccess {
        return {
            type: ActionTypes.API_IMPERSONATE_SUCCESS,
            params: params
        }
    }

    static authenticateInvalidateToken(uuid: string): AuthenticateInvalidateToken {
        return {
            type: ActionTypes.API_INVALIDATE_TOKEN,
            uuid: uuid
        }
    }

    static authenticateInvalidateTokenSuccess(response: Auth): AuthenticateInvalidateTokenSuccess {
        return {
            type: ActionTypes.API_INVALIDATE_TOKEN_SUCCESS,
            response: response
        }
    }

    static authenticateSystemToken(uuid: string): AuthenticateSystemToken {
        return {
            type: ActionTypes.API_SYSTEM_TOKEN,
            uuid: uuid
        }
    }

    static authenticateSystemTokenSuccess(response: Auth): AuthenticateSystemTokenSuccess {
        return {
            type: ActionTypes.API_SYSTEM_TOKEN_SUCCESS,
            response: response
        }
    }
}