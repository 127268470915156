/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ClientStatusModelInterface {
    request?: ClientStatusModelRequestEnum;
    instance_name?: string | null;
    instance_domain?: string | null;
}

/**
 * 
 * @export
 * @interface ClientStatusModel
 */
export class ClientStatusModel extends BaseModel<ClientStatusModelInterface> {

    /**
     * Type of request for trial. Set to backoffice if it can skip approval
     * @type {string}
     * @memberof ClientStatusModel
     */
    request?: ClientStatusModelRequestEnum;
    /**
     * Full name of this instance
     * @type {string}
     * @memberof ClientStatusModel
     */
    instance_name?: string | null;
    /**
     * DNS domain name of this instance
     * @type {string}
     * @memberof ClientStatusModel
     */
    instance_domain?: string | null;

    constructor(data?: ClientStatusModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ClientStatusModel, value: StringDateInterface<ClientStatusModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ClientStatusModelFromJSON(json: any): ClientStatusModel {
    return ClientStatusModelFromJSONTyped(json, false);
}

export function ClientStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ClientStatusModel;
    try {
        let localClass = require.context('../override', true)('./ClientStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'request': !exists(json, 'request') ? undefined : json['request'],
        'instance_name': !exists(json, 'instance_name') ? undefined : json['instance_name'],
        'instance_domain': !exists(json, 'instance_domain') ? undefined : json['instance_domain'],
    });
}

export function ClientStatusModelToJSON(value?: ClientStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ClientStatusModel;
    try {
        let localClass = require.context('../override', true)('./ClientStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'request': value.request,
        'instance_name': value.instance_name,
        'instance_domain': value.instance_domain,
    } as StringDateInterface<ClientStatusModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum ClientStatusModelRequestEnum {
    Open = 'open',
    Accepted = 'accepted',
    Cancelled = 'cancelled',
    Backoffice = 'backoffice'
}


