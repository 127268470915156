/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface NodeInfoModelInterface {
    cpu_count?: number;
    memory?: string;
    kubernetes_proxy_version?: string;
    kubernetes_kubelet_version?: string;
    docker_version?: string;
    kernel_version?: string;
    operating_system?: string;
}

/**
 * 
 * @export
 * @interface NodeInfoModel
 */
export class NodeInfoModel extends BaseModel<NodeInfoModelInterface> {

    /**
     * CPU count of this node
     * @type {number}
     * @memberof NodeInfoModel
     */
    cpu_count?: number;
    /**
     * Memory Available
     * @type {string}
     * @memberof NodeInfoModel
     */
    memory?: string;
    /**
     * Kubernetes Proxy Version
     * @type {string}
     * @memberof NodeInfoModel
     */
    kubernetes_proxy_version?: string;
    /**
     * Kubernetes Kubelet Version
     * @type {string}
     * @memberof NodeInfoModel
     */
    kubernetes_kubelet_version?: string;
    /**
     * Docker Version
     * @type {string}
     * @memberof NodeInfoModel
     */
    docker_version?: string;
    /**
     * Kernel Version
     * @type {string}
     * @memberof NodeInfoModel
     */
    kernel_version?: string;
    /**
     * Operating System
     * @type {string}
     * @memberof NodeInfoModel
     */
    operating_system?: string;

    constructor(data?: NodeInfoModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: NodeInfoModel, value: StringDateInterface<NodeInfoModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function NodeInfoModelFromJSON(json: any): NodeInfoModel {
    return NodeInfoModelFromJSONTyped(json, false);
}

export function NodeInfoModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeInfoModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NodeInfoModel;
    try {
        let localClass = require.context('../override', true)('./NodeInfoModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'cpu_count': !exists(json, 'cpu_count') ? undefined : Number(json['cpu_count']),
        'memory': !exists(json, 'memory') ? undefined : json['memory'],
        'kubernetes_proxy_version': !exists(json, 'kubernetes_proxy_version') ? undefined : json['kubernetes_proxy_version'],
        'kubernetes_kubelet_version': !exists(json, 'kubernetes_kubelet_version') ? undefined : json['kubernetes_kubelet_version'],
        'docker_version': !exists(json, 'docker_version') ? undefined : json['docker_version'],
        'kernel_version': !exists(json, 'kernel_version') ? undefined : json['kernel_version'],
        'operating_system': !exists(json, 'operating_system') ? undefined : json['operating_system'],
    });
}

export function NodeInfoModelToJSON(value?: NodeInfoModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NodeInfoModel;
    try {
        let localClass = require.context('../override', true)('./NodeInfoModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'cpu_count': value.cpu_count,
        'memory': value.memory,
        'kubernetes_proxy_version': value.kubernetes_proxy_version,
        'kubernetes_kubelet_version': value.kubernetes_kubelet_version,
        'docker_version': value.docker_version,
        'kernel_version': value.kernel_version,
        'operating_system': value.operating_system,
    } as StringDateInterface<NodeInfoModel>;
    return createClass.toJson(value, model);
}


