import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { ConditionHistoryListRequest } from "api/apis/ConditionApi";
import { ConditionCreateSuccessModel } from "api/models/ConditionCreateSuccessModel";
import { ConditionListResultModel } from "api/models/ConditionListResultModel";

import { ConditionRetrieveListModel } from "api/models/ConditionRetrieveListModel";
import Condition from "api/override/ConditionModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface ConditionList extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_LIST;
    params: ConditionRetrieveListModel;
}

export interface ConditionListSuccess extends BaseSagaListResponse<ConditionListResultModel> {
    type: ActionTypes.API_CONDITION_LIST_SUCCESS;
}

export interface ConditionCreate extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_CREATE;
    params: Condition;
}

export interface ConditionCreateSuccess extends BaseSagaListResponse<ConditionCreateSuccessModel> {
    type: ActionTypes.API_CONDITION_CREATE_SUCCESS;
}

export interface ConditionUpdateParams {
    id: number;
    conditionModel: Condition;
}

export interface ConditionUpdate extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_UPDATE;
    params: ConditionUpdateParams;
}

export interface ConditionUpdateSuccess extends BaseSagaListResponse<ConditionCreateSuccessModel> {
    type: ActionTypes.API_CONDITION_UPDATE_SUCCESS;
}

export interface ConditionRetrieve extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_RETRIEVE;
    params: number;
}

export interface ConditionRetrieveSuccess extends BaseSagaListResponse<Condition> {
    type: ActionTypes.API_CONDITION_RETRIEVE_SUCCESS
}

export interface ConditionDelete extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_DELETE;
    params: number;
}

export interface ConditionDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_CONDITION_DELETE_SUCCESS;
}

export interface ConditionHistoryList extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_HISTORY_LIST;
    params: ConditionHistoryListRequest;
}

export interface ConditionHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_CONDITION_HISTORY_LIST_SUCCESS;
}

export interface ConditionClearListParams {
    force?: boolean;
}
export interface ConditionClearList extends BaseReducerAction {
    type: ActionTypes.API_CONDITION_LIST_CLEAR;
    params?: ConditionClearListParams;
}

export default class ConditionActions {
    
    static CreateConditionList(params: ConditionRetrieveListModel): ConditionList {
        return {
            type: ActionTypes.API_CONDITION_LIST,
            params: params
        }
    }

    static CreateConditionListSuccess(params: ConditionListResultModel): ConditionListSuccess {
        return {
            type: ActionTypes.API_CONDITION_LIST_SUCCESS,
            params: params
        }
    }

    static CreateConditionCreate(params: Condition): ConditionCreate {
        return {
            type: ActionTypes.API_CONDITION_CREATE,
            params: params
        }
    }

    static CreateConditionCreateSuccess(params: ConditionCreateSuccessModel): ConditionCreateSuccess {
        return {
            type: ActionTypes.API_CONDITION_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateConditionUpdate(params: Condition): ConditionUpdate {
        return {
            type: ActionTypes.API_CONDITION_UPDATE,
            params: {
                id: params.id,
                conditionModel: params
            }
        }
    }

    static CreateConditionUpdateSuccess(params: ConditionCreateSuccessModel): ConditionUpdateSuccess {
        return {
            type: ActionTypes.API_CONDITION_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateConditionRetrieve(params: number): ConditionRetrieve {
        return {
            type: ActionTypes.API_CONDITION_RETRIEVE,
            params: params
        }
    }

    static CreateConditionRetrieveSuccess(params: Condition): ConditionRetrieveSuccess {
        return {
            type: ActionTypes.API_CONDITION_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateConditionDelete(params: number): ConditionDelete {
        return {
            type: ActionTypes.API_CONDITION_DELETE,
            params: params
        }
    }

    static CreateConditionDeleteSuccess(params: ApiSuccessResponseModel): ConditionDeleteSuccess {
        return {
            type: ActionTypes.API_CONDITION_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateConditionHistoryList(params: ConditionHistoryListRequest): ConditionHistoryList {
        return {
            type: ActionTypes.API_CONDITION_HISTORY_LIST,
            params: params
        }
    }

    static CreateConditionHistoryListSuccess(params: HistoryResponseModel): ConditionHistoryListSuccess {
        return {
            type: ActionTypes.API_CONDITION_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearConditionList(force: boolean = false): ConditionClearList {
        return {
            type: ActionTypes.API_CONDITION_LIST_CLEAR,
            params: {force: force}
        }   
    }

}