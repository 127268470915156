import { WizardModel, WizardModelInterface } from "api/models/WizardModel";
import Historiable from "interface/Historiable";
import Branch from "./BranchModel";
import Condition from "./ConditionModel";
import Stepview from "./StepviewModel";

export default class Wizard extends WizardModel implements Historiable {

    actionName: string = "wizard"
    reducerName: string = "wizards"

    private orderedBranches: Array<Branch> = []
    branches: Array<Branch> = []
    stepviews: Array<Stepview> = []

    constructor(data?: WizardModelInterface) {
        super(data);
        this.stepviews = data?.stepviews as Array<Stepview> ?? [];
        this.branches = data?.branches as Array<Branch> ?? []
    }

    getMaxWidth() {
        return this.stepviews.filter(sw => sw.next_stepview_id == null && sw.condition_id == null).length
    }

    getMaxWidthAtPosition() {
        
    }

    getBranchByConditionId(id: number): Branch {
        return this.branches.find((br: Branch) => br.condition_id === id)
    }

    getBranchByIds(ids: Array<number>): Array<Branch> {
        return this.branches.filter((br: Branch) => ids.includes(br.id)) ?? []
    }

    getStepviewByCondition(condition: Condition): Stepview {
        return this.stepviews.find((sw: Stepview) => sw.id === condition.stepview_id)
    }

    getStepviewGroupedByPosition(): Record<number, Stepview[]> {
        return (this.stepviews as Array<Stepview>).bbGroupBy('position')
    }

    getStepviewGroupedByBranches(): Record<number, Stepview[]> {
        return (this.stepviews as Array<Stepview>).bbGroupBy('branch_id');
    }

    getLastStepviewByBranchId(id: number): Stepview {
        return this.getStepviewsByBranchId(id).bbLast()
    }

    getOrderedBranches(): Array<Branch> {
        if (this.orderedBranches.bbIsEmpty()) {
            const initialBranch: Branch = (this.branches as Array<Branch>)?.find((branch: Branch) => branch.condition_id == null)
            const otherBranches = (this.branches as Branch[])?.filter(br => br.id !== initialBranch.id);
            this.orderedBranches = [
                initialBranch.assignChildren(otherBranches)
            ]
        }
        return this.orderedBranches;
    }

    getStepviewsByBranch(branch: Branch): Stepview[] {
        return this.getStepviewsByBranchId(branch.id);
    }

    getStepviewsByBranchId(id: number): Stepview[] {
        return this.getStepviewGroupedByBranches()[id] ?? [];
    }

}