import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { ViewProps } from 'components/base/BasePageComponent';
import NotificationCenter from 'services/NotificationCenter';
import { ConnectorConnectorContainerListRequest, ConnectorSuccessResponseModel, FileUploadModel } from 'api';
import Connector from 'api/override/ConnectorModel';
import { BaseAction } from 'reducers/interface/ReducerAction';
import NCNotification from 'models/NCNotification';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import TabManager, { TabView } from 'components/tabs/TabManager';
import ConnectorState from 'reducers/interface/ConnectorState';
import ConnectorActions from 'reducers/types/Connector';
import ConnectorCreateForm from 'views/CreationForms/ConnectorCreateForm';
import ConnectorDetails from './components/ConnectorDetails';
import ConnectorDetailsDialog from 'views/Dialog/ConnectorDetailsDialog';
import TableList from 'components/TableList';
import { tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from 'interface/TableViewDelegate';
import TableColumn from 'models/table/TableColumn';
import Instance from 'api/override/InstanceModel';
import CustomActionButton, { CustomIconButton } from 'models/table/CustomActionButton';
import AppState from 'reducers/interface/AppState';
import ProcessProgress from 'views/Instances/components/ProcessProgress';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { InstanceTableMenuButton } from 'views/Instances/components/InstanceMenu';
import ConnectorMenu from 'views/Instances/components/ConnectorMenu';
import ConnectorContainerController from 'controllers/ConnectorContainerController';
import ConfigFileUpload from 'interface/ConfigFileUpload';
import ConnectorContainerActions, { SetConfigFilesParams, ConnectorContainerSetConfigParams, ConnectorContainerUpdateParams, ConnectorContainerTriggerJobParams } from 'reducers/types/ConnectorContainer';
import ConnectorContainerConfigDialog from 'views/Dialog/ConnectorContainerConfigDialog';
import ConnectorContainerFileUploadModal from 'views/Dialog/ConnectorContainerFileUploadModal';
import PublishIcon from '@mui/icons-material/Publish';
import ConnectorContainerState from 'reducers/interface/ConnectorContainerState';
import ConnectorContainer from 'api/override/ConnectorContainerModel';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TokenUtil from 'utils/TokenUtil';
import ReloadDataButton from 'components/ReloadDataButton';
import { Details } from 'mdi-material-ui';
import ConnectorContainerDocumentationModal from './components/ConnectorContainerDocumentationModal';
import HistoryTab from 'components/tabs/HistoryTab';
import ProcessLogsTab from 'components/tabs/ProcessLogsTab';
import DispatchTableViewComponent, { DispatchTableViewState } from 'components/DispatchTableViewComponent';
import Tasks from 'config/Tasks';
import DetailsViewHeader from 'components/DetailsViewHeader';
import ConnectorContainerURLButton from 'components/buttons/ConnectorContainerURLButton';
import ImageVersionColumn from 'components/table_columns/ImageVersionColumn';
import { AccountSync } from 'mdi-material-ui';

interface StateProps {
    connectorReducer: ConnectorState;
    connectorContainerReducer: ConnectorContainerState;
    appReducer: AppState;
}

interface DispatchProps {
    createConnector: (params: Connector) => void;
    retrieveConnector: (params: string) => void;
    deleteConnector: (params: string) => void;
    updateConnector: (params: Connector) => void;
    listConnectorContainers: (params: ConnectorConnectorContainerListRequest) => void;
    getConnectorConfig: (params: string) => void;
    setConnectorConfig: (params: ConnectorContainerSetConfigParams) => void;
    setConnectorConfigFiles: (params: SetConfigFilesParams) => void;
    updateConnectorContainer: (params: ConnectorContainerUpdateParams) => void;
    deleteConnectorContainer: (params: string) => void;
    resetConnectorContainer: (params: string) => void;
    triggerTasks: (params: ConnectorContainerTriggerJobParams) => void;
    clearInstances: () => void;
    clearConnectors: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends DispatchTableViewState {
    connector?: Connector;
    container?: ConnectorContainer;
    instance?: Instance;
    updateProgressOpen: boolean;
    updateProgressDone: boolean;
    updateHasError: boolean;
    updateErrorMessage: string;
    notifications: NCNotification[];
    isExpanded: boolean;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
    runningProcessIds: string[];
    isCCDocumentationModalOpen: boolean;
    isFileUploadModalOpen: boolean;
    isConfigContainerModalOpen: boolean;
    isDeleteConnectorContainerModalOpen: boolean;
    isCCResetOpen: boolean;
}

class ConnectorView extends DispatchTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customActionButtons: CustomActionButton[];

    constructor(props) {
        super(props);

        this.state = {
            connector: null,
            container: null,
            instance: null,
            updateProgressOpen: false,
            updateProgressDone: false,
            updateHasError: false,
            updateErrorMessage: null,
            notifications: [],
            isExpanded: false,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
            runningProcessIds: [],
            isCCDocumentationModalOpen: false,
            isFileUploadModalOpen: false,
            isConfigContainerModalOpen: false,
            isDeleteConnectorContainerModalOpen: false,
            isCCResetOpen: false,
            selected: []
        };

        this.setFailuresToListenTo([
            ActionTypes.API_CONNECTOR_DELETE_FAILURE, 
            ActionTypes.API_CONNECTOR_CREATE_FAILURE, 
            ActionTypes.API_CONNECTOR_UPDATE_FAILURE,
            ActionTypes.API_CONNECTOR_TRIGGER_JOB_FAILURE
        ]);

        this.customActionButtons = [
            new CustomIconButton('config', i18next.t('connector_container.view.config'), 'config', (name?: string, row?: ConnectorContainer) => {
                this.onConfig(row)
            }, SettingsApplicationsIcon)
        ]
        const token: TokenUtil = TokenUtil.getTokenUtil()
        if (token.isAccountManager()){
            this.customActionButtons = [
                new CustomIconButton('upload', i18next.t('connector_container.view.table_upload'), 'upload', (name?: string, row?: ConnectorContainer) => {
                    this.setState({isFileUploadModalOpen: true, container: row, instance: row.instance, connector: row.connector})
                }, PublishIcon), ...this.customActionButtons
            ]
        }
        this.customActionButtons = [
            ...this.customActionButtons,
            new CustomIconButton('documentation', i18next.t('connector_container.view.docs_img_version_tooltip'), 'documentation', (name?: string, row?: ConnectorContainer) => {
                this.setState({isCCDocumentationModalOpen: true, container: row})
            }, Details),
            new CustomIconButton('creds', i18next.t('connector_container.view.reset_creds'), 'reset-creds', (name?: string, row?: ConnectorContainer) => {
                this.setState({isCCResetOpen: true, container: row})
            }, AccountSync),
            new ConnectorContainerURLButton()
        ]
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('connector_container.view.table_for_instance'), customValue: (row: ConnectorContainer) => row.instance.name }),
            new TableColumn({ key: 'status', isSortable: true, label: i18next.t('connector.status')}),
            new ImageVersionColumn()
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_CONNECTOR_RETRIEVE_FAILURE,
            ActionTypes.API_CONNECTOR_CREATE_FAILURE,
            ActionTypes.API_CONNECTOR_DELETE_FAILURE,
            ActionTypes.API_CONNECTOR_UPDATE_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_DELETE_FAILURE
        ]);
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_CONNECTOR_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_DELETE_SUCCESS, 
            this.onConnectorDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS, 
            this.onContainerConfigSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS,
            (action) => this.onGetConfigSuccess(action?.params?.data)
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_DELETE_SUCCESS,
            this.onContainerDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_SUCCESS,
            this.onConnectorContainerUpdateSuccess
        );
        NotificationCenter.default.addListeners(
            this, 
            [
                ActionTypes.NODE_CONNECTOR_CREATE, 
                ActionTypes.NODE_CONNECTOR_UPDATE, 
                ActionTypes.NODE_CONNECTOR_DELETE,
                ActionTypes.NODE_CONNECTOR_CONTAINER_DELETE
            ],
            (action) => {
                if(action.params?.data?.meta?.connector === this.props.params.slug) {
                    this.setState({notifications: this.state.notifications.concat(action)})
                    if (!this.state.runningProcessIds.includes(action?.params?.data?.process_token)){
                        this.setState({runningProcessIds: this.state.runningProcessIds.bbPrepend(action?.params?.data?.process_token)})
                    }
                    if(action.params?.status === 'finished') {
                        this.props.clearInstances()
                        this.props.retrieveConnector(this.props.params.slug)
                    }
                }
            }
        );
    }

    clearData = () => { 
        this.props.clearInstances() 
    }

    componentWillUnmount() {
        this.clearData()
        super.componentWillUnmount()
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveConnector(this.props.params.slug);
            if (this.isPathIncluded('instances')) {
                this.getData()
            }
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('logs')) {
            this.sendNotification({type: ActionTypes.CONNECTOR_VIEW_UPDATE_LOGS}, null);
        }
        else if(this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_CONNECTOR_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    onConnectorContainerUpdateSuccess = () => {
        this.getData(); 
        this.dialogClosed();
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('connector_container.documentation')}), {variant: 'success'}); 
    }

    onCreateSuccess = (action: BaseAction) => {
        const connectorSuccess: ConnectorSuccessResponseModel = action.params
        this.setState({connector: new Connector(connectorSuccess?.model)})
        this.props.navigate(`/connectors/${connectorSuccess?.model?.uuid}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('connector.model')}), {variant: 'success'})
        this.props.clearConnectors(true)
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({connector: action.params}, () => {
            if(!this.customActionButtons.some((button: CustomActionButton) => button.ariaLabel === 'instance-menu')) {
                this.customActionButtons = this.customActionButtons.concat([
                    new InstanceTableMenuButton( this.state.connector )
                ])
            }
            this.updateTabTitle(this.state.connector.name)
        })
    }

    onConfig = (row: ConnectorContainer) => {
        this.setState({isConfigContainerModalOpen: true, instance: row.instance, container: row})
        this.props.getConnectorConfig(row?.uuid)
    }

    onGetConfigSuccess = (data) => {
        if(data?.allowed_input == null || data?.allowed_input?.length === 0){
            this.props.enqueueSnackbar(i18next.t('connector_container.view.notification_not_ready'), {variant: 'info'})
        } else {
            this.setState({
                isConfigContainerModalOpen: true
            })
        }
    }

    onContainerConfigSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.config_success_model', {model: i18next.t('connector_container.model')}), {variant: 'success'})
        this.getData()
    }

    onContainerDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('connector_container.model')}), {variant: 'success'})
        this.getData()
    }

    onUpdateSuccess = (action: BaseAction) => {
        this.dialogClosed()
        const connectorSuccess: ConnectorSuccessResponseModel = action.params
        this.setState({connector: new Connector(connectorSuccess.model)})
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('connector.model')}), {variant: 'success'})
        this.props.clearConnectors(true)
    }

    onConnectorDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_process_started_model', {model: i18next.t('connector.model')}), {variant: 'success'})
        this.props.clearConnectors(true)
    }

    getData = () => {
        if (this.isPathIncluded('instances') && this.isDetailsView(this.props) && this.getParams() != null) {
            this.props.listConnectorContainers( {uuid: this.props.params.slug, connectorInstanceRetrieveListModel: this.getParams().getApiFilter() });
        }
    }

    getItemCount = (): number => {
        return this.props.connectorReducer.instances.items.length
    }

    onDeleteConnector = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onDelete = (connectorContainer: ConnectorContainer) => {
        this.setState({
            container: connectorContainer,
            isDeleteConnectorContainerModalOpen: true,
        });
    };

    onEdit = () => {
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
            isFileUploadModalOpen: false,
            isCCDocumentationModalOpen: false,
            isConfigContainerModalOpen: false,
            isDeleteConnectorContainerModalOpen: false,
            isCCResetOpen: false
        })
    }

    deleteConnector = () => {
        this.props.deleteConnector(this.state.connector?.uuid);
        this.dialogClosed()
    };

    deleteConnectorContainer = () => {
        this.props.deleteConnectorContainer(this.state.container?.uuid);
    }

    resetConnectorContainerConfig =() => {
        this.props.resetConnectorContainer(this.state.container?.uuid);
    }

    shouldRenderProgress = () => {
        return (this.state.notifications.length !== 0)
    }

    shouldRenderConnectorDetails = () => {
        return this.isDetailsView(this.props) && this.state.connector != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    handleMultiRedeploy(selected: string[]): void {
        this.props.triggerTasks({uuid: selected.bbFirst(), task: Tasks.MULTI_REDEPLOY, uuids: selected.bbRemoveFirstItem()})
    }

    renderView() {
        const { classes, connectorReducer, appReducer, connectorContainerReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_CONNECTOR_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderConnectorDetails() &&
                                    <div>
                                        <DetailsViewHeader
                                            model={i18next.t('connector.model')}
                                            title={this.state.connector?.name}
                                        >
                                            <div style={{marginLeft: 'auto'}}>
                                                <ConnectorMenu connector={this.state.connector}/>
                                            </div>
                                        </DetailsViewHeader>
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/connectors/${this.state.connector?.uuid}`,
                                                    view: (
                                                        <ConnectorDetails 
                                                            connector={this.state.connector} 
                                                            onConnectorDelete={this.onDeleteConnector}
                                                            onConnectorEdit={this.onEdit}
                                                        />
                                                    )
                                                },{
                                                    title: i18next.t('history.model'),
                                                    link: `/connectors/${this.state.connector?.uuid}/history`,
                                                    initialTab: this.isPathIncluded('history'),
                                                    view: (
                                                        <HistoryTab model={this.state.connector} />
                                                    )
                                                },{
                                                    title:  i18next.t('process.model_plural'),
                                                    link: `/connectors/${this.state.connector?.uuid}/logs`,
                                                    initialTab: this.isPathIncluded('logs'),
                                                    view: (
                                                        <ProcessLogsTab model={this.state.connector} />
                                                    )
                                                },{
                                                    title: i18next.t('connector_container.model'),
                                                    link: `/connectors/${this.state.connector?.uuid}/instances`,
                                                    initialTab: this.isPathIncluded('instances'),
                                                    view: (
                                                        <Grid item xs={12} >
                                                            <TableList
                                                                hasDeleteAction={TokenUtil.getTokenUtil().isAccountManager()}
                                                                selectableRows={true}
                                                                selected={this.state.selected}
                                                                columns={this.columns}
                                                                count={connectorReducer.instances.count_filtered}
                                                                listItems={connectorReducer.instances.items}
                                                                isRefreshing={connectorReducer.isFetchingInstances}
                                                                delegate={ this }
                                                                tableParams={this.getParams()}
                                                                customActions={this.customActionButtons}
                                                                idColumn={ 'uuid' } 
                                                            /> 
                                                            { this.getRedeployView(i18next.t('global.action.trigger_model_redeploy', {model: i18next.t('connector_container.model')})) }
                                                        </Grid>
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if (newTab.link.includes('instances')){
                                                    if (connectorReducer.instances.items.length === 0 && !connectorReducer.isFetchingInstances) {
                                                        this.props.listConnectorContainers( {uuid: this.props.params.slug, connectorInstanceRetrieveListModel: this.getParams().getApiFilter() });
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <ConnectorCreateForm
                                            onFormSubmit={(connector: Connector) => {
                                                this.props.createConnector(connector);
                                            }}
                                        /> 
                                    </CardContent>
                                }
                                {this.shouldRenderProgress() && 
                                    <CardContent>
                                        {this.state.runningProcessIds.map((processId) => {
                                            return (
                                                <ProcessProgress 
                                                    key={processId}     
                                                    notifications={this.state.notifications.filter((notif) => processId === notif.params.data.process_token)}
                                                    onFinish={(process: NCNotification) => {
                                                        this.props.enqueueSnackbar(i18next.t(`node.${process.params.data.process_name}`, {entity: process.params.data.meta?.name}) + ' ' + i18next.t('global.notification.process_finished'), {variant: 'success'})
                                                    }}
                                            />
                                            )
                                        })}
                                    </CardContent>
                                }
                            </Card>

                            <ConfirmDeleteDialog
                                open={this.state.isDeleteModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteConnector}
                                title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('connector.model')).toLowerCase()})}
                            />

                            <ConnectorDetailsDialog
                                open={this.state.isEditModalOpen}
                                onModalClosePress={this.dialogClosed}
                                connector={this.state.connector}
                                onModalSubmit={(connector: Connector) => {
                                    this.props.updateConnector(connector)
                                }}
                            />

                            <ConfirmDeleteDialog
                                open={this.state.isDeleteConnectorContainerModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteConnectorContainer}
                                title={i18next.t('global.title.delete_confirm_model', {model: `${this.state.container?.connector?.name} ${this.state.container?.getDeleteDialog()} on ${this.state.container?.instance?.name}`})}
                            />

                            <ConfirmDeleteDialog
                                open={this.state.isCCResetOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.resetConnectorContainerConfig}
                                title={i18next.t('global.title.reset_config_warning')}
                            />

                            <ConnectorContainerFileUploadModal
                                open={this.state.isFileUploadModalOpen}
                                instance={this.state.instance}
                                connector={this.state.connector}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(upload: ConfigFileUpload) => {
                                    const fileToUpload: FileUploadModel = new FileUploadModel({
                                        file: upload.filename,
                                        base64: upload.file.base64
                                    })
                                    const fileArgs = {
                                        uuid: this.state.container?.uuid,
                                        fileUploadModel: fileToUpload
                                    } as SetConfigFilesParams
                                    this.props.setConnectorConfigFiles(fileArgs)
                                    this.dialogClosed()
                                }}
                            />

                            <ConnectorContainerDocumentationModal
                                open={this.state.isCCDocumentationModalOpen}
                                connectorContainer={this.state.container}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(connectorContainer: ConnectorContainer) => {
                                    this.props.updateConnectorContainer({
                                        uuid: this.state.container?.uuid, 
                                        text: connectorContainer?.text,
                                        image_version_id: connectorContainer?.image_version_id
                                    })
                                }}
                            />

                            {appReducer.isFetchingData.get(ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG) ?
                                <Backdrop className={classes.backdrop} open={true}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            :
                                this.state.container != null && <ConnectorContainerConfigDialog
                                    open={this.state.isConfigContainerModalOpen}
                                    onModalClosePress={this.dialogClosed}
                                    config={connectorContainerReducer?.configData}
                                    connectorContainer={this.state.container}
                                    onModalSubmit={(config: object) => {
                                        const controller = new ConnectorContainerController();
                                        const unfilteredConfig = controller.prepareConfig(connectorContainerReducer.configData, config)
                                        const configArgs = {
                                            uuid: this.state.container?.uuid,
                                            config: controller.filterUnchanged(unfilteredConfig, connectorContainerReducer.configData)
                                        } as ConnectorContainerSetConfigParams
                                        this.props.setConnectorConfig(configArgs)
                                        const files = controller.prepareFiles( connectorContainerReducer.configData, config )
                                        files.forEach( (file: FileUploadModel) => {
                                            const fileArgs = {
                                                uuid: this.state.container?.uuid,
                                                fileUploadModel: file
                                            } as SetConfigFilesParams
                                            this.props.setConnectorConfigFiles(fileArgs)
                                        } );
                                    }}
                                />
                            }

                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    connectorReducer: state.connectors,
    connectorContainerReducer: state.connectorContainers,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        createConnector: (params: Connector) => dispatch(ConnectorActions.CreateConnectorCreate(params)),
        retrieveConnector: (params: string) => dispatch(ConnectorActions.CreateConnectorRetrieve(params)),
        deleteConnector: (params: string) => dispatch(ConnectorActions.CreateConnectorDelete(params)),
        updateConnector: (params: Connector) => dispatch(ConnectorActions.CreateConnectorUpdate(params)),
        listConnectorContainers: (params: ConnectorConnectorContainerListRequest) => dispatch(ConnectorActions.CreateConnectorListInstances(params)),
        getConnectorConfig: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerGetConfig(params)),
        setConnectorConfig: (params: ConnectorContainerSetConfigParams ) => dispatch(ConnectorContainerActions.CreateConnectorContainerSetConfig(params)),
        setConnectorConfigFiles: (params: SetConfigFilesParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerSetConfigFiles(params)),
        updateConnectorContainer: (params: ConnectorContainerUpdateParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerUpdate(params)),
        resetConnectorContainer: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerReset(params)),
        triggerTasks: (params: ConnectorContainerTriggerJobParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob(params)),
        deleteConnectorContainer: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerDelete(params)),
        clearInstances: () => dispatch(ConnectorActions.CreateClearInstances()),
        clearConnectors: (params: boolean) => dispatch(ConnectorActions.CreateClearConnectorList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ConnectorView, InstanceViewStyles)
            )
        )
    )
);