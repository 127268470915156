import ServiceSecret, { FormattedSecret } from 'api/override/ServiceSecretModel';
import IconButtonStyled from 'components/styled/IconButtonStyled';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { FieldArray, FormikProps } from 'formik';
import { Minus, Plus } from 'mdi-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useServiceStyles from 'styles/ServiceStyles';

interface ServiceSecretFormProps {
    formik: FormikProps<ServiceSecret>
}

const ServiceSecretForm = (props: ServiceSecretFormProps) => {

    const { t } = useTranslation();
    const {classes} = useServiceStyles();
    const { formik } = props;
    const sc = formik.values

    const configureAvailableGroups = (): Array<string> => {
        let groups: Array<string> = []
        if(sc['general'] !== undefined) {
            groups = groups.concat(['general'])
        }
        if(sc['container'] !== undefined) {
            groups = groups.concat(['container'])
        }
        return groups;
    }

    const renderSecrets = (): JSX.Element => {
        const groups: Array<string> = configureAvailableGroups()
        return (
            <>
                {groups.map((group: string) => {
                    return (
                        <div key={`${group}_section`}>
                            <h4>{t(`service.${group}_secrets`)}</h4> 
                            <FieldArray name={`${group}_formatted`}>
                                {({insert, remove, push}) => (
                                <>
                                {formik.values?.[`${group}_formatted`].map((secret: FormattedSecret, index: number) => {
                                    return (
                                        <div key={`${group}_${index}`} style={{display: 'flex', gap: 10, }}>
                                            <TextFieldStyled
                                            id={`${group}_formatted`} 
                                            name={`${group}_formatted[${index}].key`}
                                            label={t('general.key')}
                                            value={secret['key']}
                                            onChange={formik.handleChange}/>

                                            <TextFieldStyled
                                            id={`${group}_formatted`} 
                                            name={`${group}_formatted[${index}].value`}
                                            label={t('general.value')}
                                            value={secret['value']}
                                            onChange={formik.handleChange}/>

                                            <IconButtonStyled
                                            key={`${group}_${index}_removeBtn`}
                                            className={classes.ss_remove_btn}
                                            onClick={() => remove(index)}>
                                                <Minus/>
                                            </IconButtonStyled>
                                        </div>
                                    )
                                })}
                                <IconButtonStyled
                                className={classes.ss_add_btn}
                                onClick={() => push({key: '', value: ''})}>
                                    <Plus/>
                                </IconButtonStyled>
                                </>)}
                            </FieldArray>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <>{renderSecrets()}</>
    );
};

export default ServiceSecretForm;