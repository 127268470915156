import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps, componentConnect } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { WidgetCreateSuccessModel, WidgetHistoryListRequest } from 'api';
import TabManager, { TabView } from 'components/tabs/TabManager';
import WidgetState from 'reducers/interface/WidgetState';
import WidgetActions from 'reducers/types/Widget';
import WidgetDetails from './components/WidgetDetails';
import NotificationCenter from 'services/NotificationCenter';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import ReloadDataButton from 'components/ReloadDataButton';
import Widget from 'api/override/WidgetModel';
import DetailsViewHeader from 'components/DetailsViewHeader';
import WidgetCreateForm from './components/WidgetCreateForm';
import WidgetDetailsDialog from 'views/Dialog/WidgetDetailsDialog';
import { parseInt } from 'lodash';

interface StateProps {
    appReducer: AppState;
    widgetReducer: WidgetState;
}

interface DispatchProps {
    retrieveWidget: (params: number) => void;
    updateWidget: (params: Widget) => void;
    createWidget: (params: Widget) => void;
    deleteWidget: (params: number) => void;
    clearWidgets: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    widget?: Widget;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class WidgetView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            widget: null,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_WIDGET_UPDATE_FAILURE, 
            ActionTypes.API_WIDGET_DELETE_FAILURE, 
            ActionTypes.API_WIDGET_RETRIEVE_FAILURE, 
            ActionTypes.API_WIDGET_CREATE_FAILURE
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIDGET_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_WIDGET_UPDATE_SUCCESS,
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_WIDGET_DELETE_SUCCESS, 
            this.onDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_WIDGET_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({widget: action.params}, () => {
            this.updateTabTitle(this.state.widget.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const widgetSuccess: WidgetCreateSuccessModel = action.params
        this.setState({widget: new Widget(widgetSuccess.model)})
        this.props.navigate(`/widgets/${widgetSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('widgets.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('widgets.model')}), {variant: 'success'})
        this.props.clearWidgets(true)
        this.reloadData()
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('widgets.model')}), {variant: 'success'})
        this.props.clearWidgets(true)
        this.props.navigate('/widgets')
    }

    loadData = () => {
        const id = parseInt(this.props.params.slug);
        if (this.isDetailsView(this.props)) {
            this.props.retrieveWidget(id);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_WIDGET_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.widget != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.props.retrieveWidget(this.props.params.slug)
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, appReducer, widgetReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_WIDGET_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                            {this.shouldRenderDetails() &&
                                    <div>
                                    <DetailsViewHeader
                                        model={i18next.t('widgets.model')}
                                        title={this.state.widget?.name}
                                    />
                                    <TabManager
                                        items={[
                                            {
                                                title: i18next.t('general.detail'),
                                                link: `/widgets/${this.state.widget?.id}`,
                                                view: (
                                                    <WidgetDetails 
                                                        widget={this.state.widget} 
                                                        onDelete={this.onDelete}
                                                        onEdit={this.onEdit}
                                                    />
                                                )
                                            },{
                                                title:  i18next.t('history.model'),
                                                link: `/widgets/${this.state.widget?.id}/history`,
                                                initialTab: this.isPathIncluded('history'),
                                                view: (
                                                    <HistoryTab
                                                        model={this.state.widget}
                                                    />
                                                )
                                            }
                                        ]}
                                        onTabSwitch={(newTab: TabView) => {
                                            if (newTab.link.includes('history')){
                                                if (widgetReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_WIDGET_HISTORY_LIST)) {
                                                    this.sendNotification({type: ActionTypes.API_WIDGET_HISTORY_UPDATE}, null);
                                                }
                                            }
                                        }}
                                    />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <WidgetCreateForm
                                            onFormSubmit={(widget: Widget) => {
                                                this.props.createWidget(widget);
                                            }}
                                        /> 
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                <WidgetDetailsDialog
                    widget={this.state.widget}
                    open={this.state.isEditModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onModalSubmit={(widget: Widget) => {
                        this.props.updateWidget(widget)
                    }}
                />

                <ConfirmDeleteDialog
                    open={this.state.isDeleteModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onConfirm={() => {
                        this.props.deleteWidget(this.state.widget?.id)
                    }}
                    title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('widgets.model')).toLowerCase()})}
                />
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    widgetReducer: state.widgets
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveWidget: (params: number) => dispatch(WidgetActions.CreateWidgetRetrieve(params)),
        listWidgetHistory: (params: WidgetHistoryListRequest) => dispatch(WidgetActions.CreateWidgetHistoryList(params)),
        updateWidget: (params: Widget) => dispatch(WidgetActions.CreateWidgetUpdate(params)),
        createWidget: (params: Widget) => dispatch(WidgetActions.CreateWidgetCreate(params)),
        deleteWidget: (params: number) => dispatch(WidgetActions.CreateWidgetDelete(params)),
        clearWidgets: (params: boolean) => dispatch(WidgetActions.CreateClearWidgetList(params))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(WidgetView, InstanceViewStyles)
            )
        )
    )
);