import { Theme } from '@mui/material/styles';

const LoadBalancerPageStyles = ((theme: Theme) => ({
    root: {
        minWidth: 275,
        marginTop: '20px'
    },
    card_container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 20
    }
}))

export default LoadBalancerPageStyles