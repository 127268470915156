import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { WizardHistoryListRequest } from "api/apis/WizardApi";
import { WizardCreateSuccessModel } from "api/models/WizardCreateSuccessModel";
import { WizardListResultModel } from "api/models/WizardListResultModel";
import { WizardRetrieveListModel } from "api/models/WizardRetrieveListModel";
import Wizard from "api/override/WizardModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface WizardList extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_LIST;
    params: WizardRetrieveListModel;
}

export interface WizardListSuccess extends BaseSagaListResponse<WizardListResultModel> {
    type: ActionTypes.API_WIZARD_LIST_SUCCESS;
}

export interface WizardCreate extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_CREATE;
    params: Wizard;
}

export interface WizardCreateSuccess extends BaseSagaListResponse<WizardCreateSuccessModel> {
    type: ActionTypes.API_WIZARD_CREATE_SUCCESS;
}

export interface WizardUpdateParams {
    id: number;
    wizardModel: Wizard;
}

export interface WizardUpdate extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_UPDATE;
    params: WizardUpdateParams;
}

export interface WizardUpdateSuccess extends BaseSagaListResponse<WizardCreateSuccessModel> {
    type: ActionTypes.API_WIZARD_UPDATE_SUCCESS;
}

export interface WizardRetrieve extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_RETRIEVE;
    params: number;
}

export interface WizardRetrieveSuccess extends BaseSagaListResponse<Wizard> {
    type: ActionTypes.API_WIZARD_RETRIEVE_SUCCESS
}

export interface WizardDelete extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_DELETE;
    params: number;
}

export interface WizardDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_WIZARD_DELETE_SUCCESS;
}

export interface WizardHistoryList extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_HISTORY_LIST;
    params: WizardHistoryListRequest;
}

export interface WizardHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_WIZARD_HISTORY_LIST_SUCCESS;
}

export interface WizardClearListParams {
    force?: boolean;
}
export interface WizardClearList extends BaseReducerAction {
    type: ActionTypes.API_WIZARD_LIST_CLEAR;
    params?: WizardClearListParams;
}

export default class WizardActions {
    
    static CreateWizardList(params: WizardRetrieveListModel): WizardList {
        return {
            type: ActionTypes.API_WIZARD_LIST,
            params: params
        }
    }

    static CreateWizardListSuccess(params: WizardListResultModel): WizardListSuccess {
        return {
            type: ActionTypes.API_WIZARD_LIST_SUCCESS,
            params: params
        }
    }

    static CreateWizardCreate(params: Wizard): WizardCreate {
        return {
            type: ActionTypes.API_WIZARD_CREATE,
            params: params
        }
    }

    static CreateWizardCreateSuccess(params: WizardCreateSuccessModel): WizardCreateSuccess {
        return {
            type: ActionTypes.API_WIZARD_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateWizardUpdate(params: Wizard): WizardUpdate {
        return {
            type: ActionTypes.API_WIZARD_UPDATE,
            params: {
                id: params.id,
                wizardModel: params
            }
        }
    }

    static CreateWizardUpdateSuccess(params: WizardCreateSuccessModel): WizardUpdateSuccess {
        return {
            type: ActionTypes.API_WIZARD_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateWizardRetrieve(params: number): WizardRetrieve {
        return {
            type: ActionTypes.API_WIZARD_RETRIEVE,
            params: params
        }
    }

    static CreateWizardRetrieveSuccess(params: Wizard): WizardRetrieveSuccess {
        return {
            type: ActionTypes.API_WIZARD_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateWizardDelete(params: number): WizardDelete {
        return {
            type: ActionTypes.API_WIZARD_DELETE,
            params: params
        }
    }

    static CreateWizardDeleteSuccess(params: ApiSuccessResponseModel): WizardDeleteSuccess {
        return {
            type: ActionTypes.API_WIZARD_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateWizardHistoryList(params: WizardHistoryListRequest): WizardHistoryList {
        return {
            type: ActionTypes.API_WIZARD_HISTORY_LIST,
            params: params
        }
    }

    static CreateWizardHistoryListSuccess(params: HistoryResponseModel): WizardHistoryListSuccess {
        return {
            type: ActionTypes.API_WIZARD_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearWizardList(force: boolean = false): WizardClearList {
        return {
            type: ActionTypes.API_WIZARD_LIST_CLEAR,
            params: {force: force}
        }   
    }

}