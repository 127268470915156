import { Theme } from '@mui/material/styles';

const RancherNodeStyles = ((theme: Theme) => ({
    root: {
        width: 375,
        height: 555,
        marginTop: 20
    },
    card_content: {
        display: "flex",
        flexDirection: "column",
        width: 375,
        height: 450,
        justifyContent: "space-between"
    } ,
    information: {
        display: "flex",
        flexDirection: "column",
    } ,
    chip: {
        width: 80,
        color: "white",
        alignSelf: "center"
    } ,
    heading: {
        fontSize: 32,
        margin: 0
    },
    node_name: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 20
    } ,
    chip_legend: {
        color: "white",
        margin: "3px",
        alignSelf: "flex-start",
        fontSize: "14px"
    }
}))

export default RancherNodeStyles