import i18next from "i18next";
import * as Yup from "yup";

export const retrieveTransferToolSchema = () => Yup.object().shape({
    database_from: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    database_to: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    server_to: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    preserve_existing: Yup.bool()
});