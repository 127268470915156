import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import { Tooltip } from '@mui/material';
import Widget from "api/override/WidgetModel";

interface StepviewWidgetSelectProps {
    onAdd: (widget: Widget) => void;
    widgets: Array<Widget>;
}

/*
    t('stepviews.supported_widgets.TextWidget')
    t('stepviews.supported_widgets.VideoWidget')
    t('stepviews.supported_widgets.LinkWidget')
*/
const StepviewWidgetSelect = (props: StepviewWidgetSelectProps) => {
    
    const {onAdd, widgets} = props
    const { classes } = useWizardBuilderStyles()
    const {t} = useTranslation()

    return (
        <div className={classes.stepview_widgets_root}>
            <h3>{t('widgets.model_plural')}</h3>
            <div className={classes.stepview_widgets_container}>
                {widgets.map((widget: Widget, index: number) => 
                <Tooltip key={`widget_${widget.id}_${index}_tooltip`} title={t('global.action.add_model', {model: `${widget.name} ${t('widgets.model')}`})}>
                    <div className={classes.stepview_widget} onClick={() => onAdd(widget)} key={`widget_${widget.id}_${index}`}>
                        {<widget.mdIcon/>}
                        <h5>{t(`stepviews.supported_widgets.${widget.name}`)}</h5>
                    </div>
                </Tooltip>
                )}
            </div>
        </div>
    )
}

export default memo(StepviewWidgetSelect);