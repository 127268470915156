/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceUserModelInterface {
    id?: number;
    first_name?: string;
    name_prefix?: string;
    last_name?: string;
    email?: string;
}

/**
 * 
 * @export
 * @interface InstanceUserModel
 */
export class InstanceUserModel extends BaseModel<InstanceUserModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof InstanceUserModel
     */
    id?: number;
    /**
     * First name
     * @type {string}
     * @memberof InstanceUserModel
     */
    first_name?: string;
    /**
     * Name prefix
     * @type {string}
     * @memberof InstanceUserModel
     */
    name_prefix?: string;
    /**
     * Last name
     * @type {string}
     * @memberof InstanceUserModel
     */
    last_name?: string;
    /**
     * user email (used for login)
     * @type {string}
     * @memberof InstanceUserModel
     */
    email?: string;

    constructor(data?: InstanceUserModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceUserModel, value: StringDateInterface<InstanceUserModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceUserModelFromJSON(json: any): InstanceUserModel {
    return InstanceUserModelFromJSONTyped(json, false);
}

export function InstanceUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceUserModel;
    try {
        let localClass = require.context('../override', true)('./InstanceUserModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'name_prefix': !exists(json, 'name_prefix') ? undefined : json['name_prefix'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    });
}

export function InstanceUserModelToJSON(value?: InstanceUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceUserModel;
    try {
        let localClass = require.context('../override', true)('./InstanceUserModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'first_name': value.first_name,
        'name_prefix': value.name_prefix,
        'last_name': value.last_name,
        'email': value.email,
    } as StringDateInterface<InstanceUserModel>;
    return createClass.toJson(value, model);
}


