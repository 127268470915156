import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import RoleState from "./interface/RoleState";

const initialState: RoleState = {
    collection: {
        items: []
    },
    error: null,
};

export function RoleReducers(state = initialState, action: BaseReducerAction): RoleState {
    switch (action.type) {
        case ActionTypes.API_ROLE_LIST:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_ROLE_LIST_SUCCESS:
            return {
                ...state,
                collection: {
                    items: action.params.items
                },
                error: null
            };

        default:
            return state;
    }
}