import Service from "api/override/ServiceModel";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import { ServiceSaga } from "sagas/ServiceSagas";
import Log from "utils/Log";
import FilterColumn, { FilterTypes } from "models/table/FilterColumn";
import { BaseAPI, ServiceApi } from "api";
import DispatchController from "./DispatchController";

export default class ServiceController extends DispatchController<Service> {

    
    modelName: string = 'service';
    getApi(): BaseAPI {
        return new ServiceApi();
    }

    serviceToMultiselect = async (params?: TableParams): Promise<MultiSelectOption[]> => {

        try {
            params.setFilters([
                FilterColumn.makeFilter('standalone', FilterTypes.BOOLEAN, '').setInputValue(false)
            ])
            if (!this.hasItemsForRequest(params)) {
                const response = await ServiceSaga.serviceList(params);
                this.cacheResponse(response.items as Service[] ?? [], params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }
    
    getService = async (uuid: string): Promise<Service | undefined> => {
        try {
            return await new ServiceApi().serviceRetrieve({uuid: uuid}) as Service;
        } catch (e) {
            Log(e)
        }
        return undefined;
    }

}