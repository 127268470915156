import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useWizardBuilderStyles = makeStyles()((theme: Theme) => {
    return {
        root: {

        },
        backdrop: {
            zIndex: 10000,
            color: '#fff',
        },
        form_save_btn: {
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 4,
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.1)',
            }
        },
        stepview_card_container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        },
        stepview_card: {
            height: 100,
            width: 200,
            background: theme.palette.background.paper,
            borderRadius: 8,
            outline:  theme.palette.mode === 'dark' ? '2px solid #2c2c2c' : '2px solid #ececec',
            transition: 'box-shadow .3s, transform .3s, outline .3s',
            position: 'relative',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 6px',
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.125)',
                outline: '2px solid transparent',
                transform: 'scale(1.05)',
                '& button': {
                    display: 'block'
                }
            }
        },
        stepview_card_btn: {
            display: 'none',
            height: 40,
            width: 40,
            position: 'absolute',
            top: 0,
            padding: 4,
            '&:hover': {
                color: '#fff',
                border: `none`,
            },
            '&.edit': {
                color: theme.palette.primary.main,
                border: `3px solid ${theme.palette.primary.main}`,
                borderRadius: '0 8px',
                right: 0,
                '&:hover': {
                    background: theme.palette.primary.main,
                    color: '#fff',
                }
            },
            '&.delete': {
                color: theme.palette.error.main,
                border: `3px solid ${theme.palette.error.main}`,
                borderRadius: '8px 0',
                left: 0,
                '&:hover': {
                    background: theme.palette.error.main,
                    color: '#fff',
                }
            },
        },
        stepview_card_line: {
            background: theme.palette.mode === 'dark' ? '#2c2c2c' :'#ececec',
            height: 40,
            width: 2,
            '&.last': {
                height: 70
            }
        },
        stepview_branch_line: {
            background: theme.palette.mode === 'dark' ? '#2c2c2c' :'#ececec',
            height: 2,
            width: '50%',
        },
        stepview_add_btn: {
            outline: theme.palette.mode === 'dark' ? '2px solid #2c2c2c' : '2px solid #ececec',
            padding: 8,
            transition: 'box-shadow .3s, outline .3s, transform .2s',
            color: theme.palette.primary.main,
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.125)',
                outline: '2px solid transparent',
                transform: 'rotate(90deg) scale(1.1)',
                background: theme.palette.background.paper,
            },
        },
        add_branch_button: {
            outline: theme.palette.mode === 'dark' ? '2px solid #2c2c2c' : '2px solid #ececec',
            padding: 8,
            transition: 'box-shadow .3s, outline .3s, transform .2s',
            color: theme.palette.primary.main,
            position: 'absolute',
            bottom: 50,
            background: theme.palette.background.paper,
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.125)',
                outline: '2px solid transparent',
                transform: 'scale(1.1)',
                background: theme.palette.background.paper,
            },
        },
        set_dummy_data_btn: {
            outline: theme.palette.mode === 'dark' ? '2px solid #2c2c2c' : '2px solid #ececec',
            padding: 8,
            transition: 'box-shadow .3s, outline .3s, transform .2s',
            color: theme.palette.primary.main,
            '&:hover': {
                boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.125)',
                outline: '2px solid transparent',
                transform: 'scale(1.1)',
                background: theme.palette.background.paper,
            },
            position: 'absolute',
            right: 30,
            bottom: 0
        },
        header_container: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 20,
            '& > button': {
                padding: 4,
                marginLeft: 20
            }
        },
        header_title: {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
                color: '#696969'
            }
        },
        stepviews_container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: '30px 0 40px 0',
        },
        stepview_level_container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 30,
            position: 'relative'
        },
        stepview_widgets_root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 140,
            marginTop: 10
        },
        stepview_widgets_container: {
            display: 'flex',
            alignItems: 'center',
            height: 110,
            borderRadius: 6,
            border: '1px solid #C0C0C0',
            width: '100%',
            padding: '15px 20px'
        },
        stepview_widget: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 80,
            width: 120,
            overflow: 'hidden',
            borderRadius: 6,
            border: '1px solid #C0C0C0',
            marginRight: 14,
            cursor: 'pointer',
            transition: 'all .2s',
            '&:hover': {
                background: theme.palette.primary.main,
                '& > *': {
                    color: '#fff'
                }
            }
        },
        stepview_preview_root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20
        },
        stepview_preview_container: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 6,
            border: '1px solid #C0C0C0',
            width: '100%',
            textAlign: 'center'
        },
        stepview_widget_view_root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            padding: '14px 14px 14px 36px',
            '& > button': {
                padding: 6,
                color: '#FF6663'
            }
        },
        stepview_widget_view_icon: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            left: 14,
            '& > hr': {
                height: '90%',
                marginLeft: 14
            }
        },
        stepview_widget_view_fields: {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '100%',
            padding: '0 20px'
        },
        condition_view_container: {
            width: '100%', 
            position: 'relative',
            marginTop: -24,
            '& > button': {
                position: 'absolute',
                top: -3,
                margin: '0 20px'
            },
            '& #addBranch': {
                right: 0,
            }
        },
        boolean_condition_labels: {
            background: theme.palette.background.paper,
            textTransform: 'uppercase',
            zIndex: 2
        },
        select_condition_field: {
            position: 'absolute',
            width: '50%',
            top: 12,
            background: theme.palette.background.paper,
            '&.left': {
                left: '-25%'
            },
            '&.right': {
                right: '-25%'
            },
            '& fieldset': {
                border: '2px solid #dadada'
            }
        },
        condition_view_fields_container: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            minHeight: 50
        },
        condition_view_fields_connect_line: {
            width: '63%',
            height: 2,
            background: theme.palette.mode === 'dark' ? '#2c2c2c' : '#ececec',
            position: 'absolute',
            top: 23
        },
        condition_view_field_down_line: {
            height: 20,
            width: 2,
            background: theme.palette.mode === 'dark' ? '#2c2c2c' : '#ececec',
            '&.boolean': {
                position: 'absolute',
                top: 24
            }
        }
    }
})

export default useWizardBuilderStyles