import ActionTypes from "config/ActionTypes";
import ImageVersionState from "./interface/ImageVersionState";
import { ImageVersionListSuccess, ImageVersionReducerAction } from "./types/ImageVersion";
import ImageVersion from "api/override/ImageVersionModel";

const initialState: ImageVersionState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    }
};

export function ImageVersionReducers(state = initialState, action: ImageVersionReducerAction): ImageVersionState {
    switch (action.type) {
        case ActionTypes.API_IMAGE_VERSION_LIST:
        case ActionTypes.API_IMAGE_VERSION_HISTORY_LIST:
            return {
                ...state,
                selectedObject: undefined
            };

        case ActionTypes.API_IMAGE_VERSION_LIST_SUCCESS:
            const result = action as ImageVersionListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<ImageVersion>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                }
            };

        case ActionTypes.API_IMAGE_VERSION_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_IMAGE_VERSION_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as ImageVersion
            };

        default:
            return state;
    }
}