import React, { Fragment, useEffect, useState } from 'react';
import { Divider, IconButton, LinearProgress } from '@mui/material';
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NCNotification from 'models/NCNotification';
import {format} from "date-fns";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useProcessProgressStyles from 'styles/ProcessProgressStyles';


interface ProcessProgressProps {
    notifications: NCNotification[];
    onFinish?: (process: NCNotification) => void;
}

const ProcessProgress = (props: ProcessProgressProps) => {
 
    const { t } = useTranslation();
    const {classes} = useProcessProgressStyles();
    const { notifications, onFinish } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const lastNotification: NCNotification = notifications.bbLast();
    const progressStep: number = 100 / lastNotification.params.data?.total_steps
    const currentStep: NCNotification = lastNotification
    const isFinished: boolean = currentStep.params?.data?.current_step === currentStep.params.data?.total_steps
    const entityName: string = lastNotification.params?.data?.meta.name ?? ''
    const processName: string = lastNotification.params?.data?.process_name
    const hasError: boolean = currentStep.params?.status === 'error'
    const [didFinish, setDidFinish] = useState(false)

    const setProgress = (): number => {
        if (isFinished && !didFinish) {
            setDidFinish(true)
        }
        return (lastNotification.params?.data?.current_step * progressStep)
    }
    const getTimestamp = (timestamp: Date): string => {
        return format(timestamp, 'H:mm:ss')
    }
    const getStatusIcon = (status: string): JSX.Element => {
        switch (status) {
            case "info":
                return <InfoIcon className={`${classes.status_icon} info`}/>
            case "success":
                return <CheckCircleIcon className={`${classes.status_icon} success`}/>
            case "warning":
                return <WarningIcon className={`${classes.status_icon} warning`}/>
            case "error":
                return <ErrorIcon className={`${classes.status_icon} error`}/>
            default:
                break;
        }
    }

    const handleFinish = () => {
        if (didFinish) {
            onFinish(currentStep)
        }
    }
    
    // eslint-disable-next-line
    useEffect(handleFinish, [didFinish])

    return (
        <div>
            <Divider/>
            {isFinished ? 
                    <>
                        <h2 className={classes.progress_header}>{t(`node.${processName}`, {entity: entityName})} {t('global.process.finished')}</h2>
                    </>
                    :
                    <h2 className={classes.progress_header}>{t(`node.${processName}`, {entity: entityName})}</h2>
            }
            <div className={classes.current_notification_container}>
                <p className={classes.notification}>
                    {getStatusIcon(currentStep.params?.status)} [{getTimestamp(currentStep.params?.data?.timestamp)}]: {currentStep.params?.message}
                </p>
                <IconButton
                    aria-label="expand"
                    onClick={() => setIsExpanded(!isExpanded)}
                    size="large">
                    {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </IconButton>
            </div>
            <LinearProgress 
                variant="determinate" 
                className={[classes.progress_linear_progress, isFinished ? 'finished' : '', hasError ? 'error' : ''].join(' ')}
                value={setProgress()} 
            />
            {isExpanded &&
                notifications.map((notification, i) => { 
                    return ( 
                        <Fragment key={`progress_step${i}`}>
                            <p className={classes.notification}>
                                {getStatusIcon(notification.params?.status)} [{getTimestamp(notification.params?.data?.timestamp)}]: {notification.params?.message}
                            </p>
                        </Fragment> 
                    );
                })
            }
        </div>
    );
};

export default ProcessProgress;