/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    InstanceAuthenticateModel,
    InstanceAuthenticateModelFromJSON,
    InstanceAuthenticateModelToJSON,
} from '../models';

export interface InstanceAuthenticateCreateTokenRequest {
    uuid: string;
    instanceAuthenticateModel: InstanceAuthenticateModel;
}

/**
 * 
 */
export class InstanceAuthenticateApi extends runtime.BaseAPI {

    /**
     * Get a token to start sign in instance flow
     * Get a token to start sign in instance flow
     */
    async instanceAuthenticateCreateTokenRaw(requestParameters: InstanceAuthenticateCreateTokenRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceAuthenticateCreateToken.');
        }

        if (requestParameters.instanceAuthenticateModel === null || requestParameters.instanceAuthenticateModel === undefined) {
            throw new runtime.RequiredError('instanceAuthenticateModel','Required parameter requestParameters.instanceAuthenticateModel was null or undefined when calling instanceAuthenticateCreateToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance-authenticate/{uuid}/create-token`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceAuthenticateModelToJSON(requestParameters.instanceAuthenticateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Get a token to start sign in instance flow
     * Get a token to start sign in instance flow
     */
    instanceAuthenticateCreateToken = async(requestParameters: InstanceAuthenticateCreateTokenRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.instanceAuthenticateCreateTokenRaw(requestParameters);
        return await response.value();
    }

}
