/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    FormCreateSuccessModel,
    FormCreateSuccessModelFromJSON,
    FormCreateSuccessModelToJSON,
    FormListResultModel,
    FormListResultModelFromJSON,
    FormListResultModelToJSON,
    FormModel,
    FormModelFromJSON,
    FormModelToJSON,
    FormRemoveSuccessModel,
    FormRemoveSuccessModelFromJSON,
    FormRemoveSuccessModelToJSON,
    FormRetrieveListModel,
    FormRetrieveListModelFromJSON,
    FormRetrieveListModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface FormCreateRequest {
    formModel: FormModel;
}

export interface FormDeleteRequest {
    id: number;
}

export interface FormHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface FormListRequest {
    formRetrieveListModel: FormRetrieveListModel;
}

export interface FormRetrieveRequest {
    id: number;
}

export interface FormUpdateRequest {
    id: number;
    formModel: FormModel;
}

/**
 * 
 */
export class FormApi extends runtime.BaseAPI {

    /**
     * Create a new form
     * Create a new form
     */
    async formCreateRaw(requestParameters: FormCreateRequest): Promise<runtime.ApiResponse<FormCreateSuccessModel>> {
        if (requestParameters.formModel === null || requestParameters.formModel === undefined) {
            throw new runtime.RequiredError('formModel','Required parameter requestParameters.formModel was null or undefined when calling formCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormModelToJSON(requestParameters.formModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new form
     * Create a new form
     */
    formCreate = async(requestParameters: FormCreateRequest): Promise<FormCreateSuccessModel> => {
        const response = await this.formCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a form
     * Delete a form
     */
    async formDeleteRaw(requestParameters: FormDeleteRequest): Promise<runtime.ApiResponse<FormRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a form
     * Delete a form
     */
    formDelete = async(requestParameters: FormDeleteRequest): Promise<FormRemoveSuccessModel> => {
        const response = await this.formDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a form
     * retrieve a list of historical logs for a form
     */
    async formHistoryListRaw(requestParameters: FormHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling formHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a form
     * retrieve a list of historical logs for a form
     */
    formHistoryList = async(requestParameters: FormHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.formHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all forms
     * List all forms
     */
    async formListRaw(requestParameters: FormListRequest): Promise<runtime.ApiResponse<FormListResultModel>> {
        if (requestParameters.formRetrieveListModel === null || requestParameters.formRetrieveListModel === undefined) {
            throw new runtime.RequiredError('formRetrieveListModel','Required parameter requestParameters.formRetrieveListModel was null or undefined when calling formList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormRetrieveListModelToJSON(requestParameters.formRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormListResultModelFromJSON(jsonValue));
    }

    /**
     * List all forms
     * List all forms
     */
    formList = async(requestParameters: FormListRequest): Promise<FormListResultModel> => {
        const response = await this.formListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a form by its id
     * Retrieve a form by its id
     */
    async formRetrieveRaw(requestParameters: FormRetrieveRequest): Promise<runtime.ApiResponse<FormModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a form by its id
     * Retrieve a form by its id
     */
    formRetrieve = async(requestParameters: FormRetrieveRequest): Promise<FormModel> => {
        const response = await this.formRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a form
     * Update a form
     */
    async formUpdateRaw(requestParameters: FormUpdateRequest): Promise<runtime.ApiResponse<FormCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formUpdate.');
        }

        if (requestParameters.formModel === null || requestParameters.formModel === undefined) {
            throw new runtime.RequiredError('formModel','Required parameter requestParameters.formModel was null or undefined when calling formUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FormModelToJSON(requestParameters.formModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a form
     * Update a form
     */
    formUpdate = async(requestParameters: FormUpdateRequest): Promise<FormCreateSuccessModel> => {
        const response = await this.formUpdateRaw(requestParameters);
        return await response.value();
    }

}
