/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceSetImageVersionModelInterface {
    core_image_version_id?: number | null;
    ui_image_version_id?: number | null;
    tools_image_version_id?: number | null;
}

/**
 * 
 * @export
 * @interface InstanceSetImageVersionModel
 */
export class InstanceSetImageVersionModel extends BaseModel<InstanceSetImageVersionModelInterface> {

    /**
     * id of the core image version
     * @type {number}
     * @memberof InstanceSetImageVersionModel
     */
    core_image_version_id?: number | null;
    /**
     * id of the ui image version
     * @type {number}
     * @memberof InstanceSetImageVersionModel
     */
    ui_image_version_id?: number | null;
    /**
     * id of the tools image version
     * @type {number}
     * @memberof InstanceSetImageVersionModel
     */
    tools_image_version_id?: number | null;

    constructor(data?: InstanceSetImageVersionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSetImageVersionModel, value: StringDateInterface<InstanceSetImageVersionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSetImageVersionModelFromJSON(json: any): InstanceSetImageVersionModel {
    return InstanceSetImageVersionModelFromJSONTyped(json, false);
}

export function InstanceSetImageVersionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSetImageVersionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSetImageVersionModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSetImageVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'core_image_version_id': !exists(json, 'core_image_version_id') ? undefined : Number(json['core_image_version_id']),
        'ui_image_version_id': !exists(json, 'ui_image_version_id') ? undefined : Number(json['ui_image_version_id']),
        'tools_image_version_id': !exists(json, 'tools_image_version_id') ? undefined : Number(json['tools_image_version_id']),
    });
}

export function InstanceSetImageVersionModelToJSON(value?: InstanceSetImageVersionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSetImageVersionModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSetImageVersionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'core_image_version_id': value.core_image_version_id,
        'ui_image_version_id': value.ui_image_version_id,
        'tools_image_version_id': value.tools_image_version_id,
    } as StringDateInterface<InstanceSetImageVersionModel>;
    return createClass.toJson(value, model);
}


