/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceSecretModelInterface {
    general?: object | null;
    container?: object | null;
}

/**
 * 
 * @export
 * @interface ServiceSecretModel
 */
export class ServiceSecretModel extends BaseModel<ServiceSecretModelInterface> {

    /**
     * 
     * @type {object}
     * @memberof ServiceSecretModel
     */
    general?: object | null;
    /**
     * 
     * @type {object}
     * @memberof ServiceSecretModel
     */
    container?: object | null;

    constructor(data?: ServiceSecretModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceSecretModel, value: StringDateInterface<ServiceSecretModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceSecretModelFromJSON(json: any): ServiceSecretModel {
    return ServiceSecretModelFromJSONTyped(json, false);
}

export function ServiceSecretModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceSecretModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceSecretModel;
    try {
        let localClass = require.context('../override', true)('./ServiceSecretModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'general': !exists(json, 'general') ? undefined : json['general'],
        'container': !exists(json, 'container') ? undefined : json['container'],
    });
}

export function ServiceSecretModelToJSON(value?: ServiceSecretModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceSecretModel;
    try {
        let localClass = require.context('../override', true)('./ServiceSecretModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'general': value.general,
        'container': value.container,
    } as StringDateInterface<ServiceSecretModel>;
    return createClass.toJson(value, model);
}


