import { IconButton, Tooltip } from "@mui/material";
import i18next from "i18next";
import { PlayBoxMultipleOutline } from "mdi-material-ui";
import React from "react";
import BaseTableViewComponent, { BaseTableViewProps, BaseTableViewState } from "./base/BaseTableViewComponent";

export interface DispatchTableViewState extends BaseTableViewState {
    selected: any[];
}

export default abstract class DispatchTableViewComponent<P, S extends DispatchTableViewState = DispatchTableViewState, SS = {}> extends BaseTableViewComponent<P & BaseTableViewProps, S & DispatchTableViewState, SS> {
    abstract handleMultiRedeploy(selected: string[]): void;

    getRedeployButtons(title: string): JSX.Element {
        return (
            <Tooltip title={title != null ? title : i18next.t('global.button.redeploy')} style={{textTransform: 'capitalize'}}>
                <IconButton
                    onClick={() => this.handleMultiRedeploy(this.state.selected)}
                    style={{color: 'white', padding: 10}}
                    size="large">
                    <PlayBoxMultipleOutline/>
                </IconButton>
            </Tooltip>
        );
    }

    getRedeployView(buttonTooltip?: string): JSX.Element {
        return (
            this.state.selected.length > 0
            ? (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    maxWidth: 1232,
                    width: 'calc(100% - 62px - 56px)',
                    minHeight: 1
                }}>
                    <div style={{
                        position: 'absolute', 
                        top: -10,
                        left: 0,
                        padding: '10px 10px 5px 20px',
                        background: '#14A5FF',
                        borderRadius: 10,
                        lineHeight: '50px',
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 700,
                        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.24)'
                    }}><span style={{marginRight: 10}}>{i18next.t('global.selected_row', {count: this.state.selected.length})}</span>
                    {this.getRedeployButtons(buttonTooltip != null ? buttonTooltip : i18next.t('global.button.redeploy_selected'))}
                    </div> 
                </div>
            ) 
            : null
        );
    }

    onSelectRow(items?: any[]) {
        this.setState({selected: items ?? []})
    }

    onSelectAll? = (items: any[]) => {
        this.setState({selected: items})
    }

}