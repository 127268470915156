import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import NotificationCenter from 'services/NotificationCenter';
import { BaseAction } from 'reducers/interface/ReducerAction';
import FormActions from 'reducers/types/Form';
import FormState from 'reducers/interface/FormState';
import Form from 'api/override/FormModel';
import HideOnProd from 'components/HideOnProd';
import { FormCreateSuccessModel } from 'api/models/FormCreateSuccessModel';
import FormBuilder from './components/FormBuilder';

interface StateProps {
    appReducer: AppState;
    formReducer: FormState;
}

interface DispatchProps {
    retrieveForm: (params: number) => void;
    updateForm: (params: Form) => void;
    clearForms: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    form?: Form;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class FormBuilderView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            form: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_FORM_CREATE_FAILURE,
            ActionTypes.API_FORM_RETRIEVE_FAILURE, 
            ActionTypes.API_FORM_UPDATE_FAILURE,
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_FORM_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({form: action.params}, () => {
            this.updateTabTitle(this.state.form?.title)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const formSuccess: FormCreateSuccessModel = action.params
        this.setState({form: new Form(formSuccess.model)})
        this.props.navigate(`/forms/${formSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('forms.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('forms.model')}), {variant: 'success'})
        this.props.clearForms()
        this.reloadData()
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveForm(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.FORM_VIEW_UPDATE_HISTORY}, null);
        }
        this.loadData();
    }

    updateForm = (form: Form) => {
        this.props.updateForm(form)
    }

    createForm = (form: Form) => {
        // this.props.createForm(form) 
    }

    renderView() {
        const { classes, appReducer } = this.props;
        return (
            <HideOnProd>
                <React.Fragment>
                    <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_FORM_RETRIEVE)}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container style={{maxWidth: '94vw'}}>
                        <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <Card className={classes.root}>
                                    { this.state.form != null &&
                                        <FormBuilder 
                                            form={this.state.form}
                                            onUpdate={(form: Form) => {
                                                this.updateForm(form)
                                            }}
                                            onUpdateTitle={(form: Form) => {
                                                this.updateForm(form)
                                            }}
                                        />
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </React.Fragment>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    formReducer: state.forms
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveForm: (params: number) => dispatch(FormActions.CreateFormRetrieve(params)),
        updateForm: (params: Form) => dispatch(FormActions.CreateFormUpdate(params)),
        clearForms: () => dispatch(FormActions.CreateClearFormList(true))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(FormBuilderView, InstanceViewStyles)
            )
        )
    )
);