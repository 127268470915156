import ActionTypes from "config/ActionTypes";
import { TableParams } from "models/table/TableParams";
import { FilterActionTypes, FilterState } from "./interface/FilterState";

const initialState: FilterState = {
    params: {},
    error: undefined
}

export function FilterReducers(state = initialState, action: FilterActionTypes): FilterState {
    switch (action.type) {
        case ActionTypes.SET_FILTERS:
            window.localStorage.setItem(action.pageName, JSON.stringify(action.params.toJson()));
            return state;
        case ActionTypes.FETCH_FILTERS:
            const params = JSON.parse(window.localStorage.getItem(action.pageName) ?? '{}');
            const tableParamFromJson = TableParams.fromJson(params);
            state.params[action.pageName] = tableParamFromJson;
            
            return {
                ...state,
                params: state.params
            }
        default:
            return state;
    }
}