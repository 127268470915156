/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    MailModel,
    MailModelFromJSON,
    MailModelFromJSONTyped,
    MailModelToJSON,
} from './';


export interface MailListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<MailModel>;
}

/**
 * 
 * @export
 * @interface MailListResultModel
 */
export class MailListResultModel extends BaseModel<MailListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof MailListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof MailListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<MailModel>}
     * @memberof MailListResultModel
     */
    items?: Array<MailModel>;

    constructor(data?: MailListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MailListResultModel, value: StringDateInterface<MailListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MailListResultModelFromJSON(json: any): MailListResultModel {
    return MailListResultModelFromJSONTyped(json, false);
}

export function MailListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MailListResultModel;
    try {
        let localClass = require.context('../override', true)('./MailListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(MailModelFromJSON)),
    });
}

export function MailListResultModelToJSON(value?: MailListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MailListResultModel;
    try {
        let localClass = require.context('../override', true)('./MailListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MailModelToJSON)),
    } as StringDateInterface<MailListResultModel>;
    return createClass.toJson(value, model);
}


