/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    ServiceContainerCreateSuccessModel,
    ServiceContainerCreateSuccessModelFromJSON,
    ServiceContainerCreateSuccessModelToJSON,
    ServiceContainerModel,
    ServiceContainerModelFromJSON,
    ServiceContainerModelToJSON,
    ServiceContainerRemoveSuccessModel,
    ServiceContainerRemoveSuccessModelFromJSON,
    ServiceContainerRemoveSuccessModelToJSON,
    ServiceSecretModel,
    ServiceSecretModelFromJSON,
    ServiceSecretModelToJSON,
    ServiceSecretResponseModel,
    ServiceSecretResponseModelFromJSON,
    ServiceSecretResponseModelToJSON,
} from '../models';

export interface ServiceContainerCreateRequest {
    serviceContainerModel: ServiceContainerModel;
}

export interface ServiceContainerDeleteRequest {
    uuid: string;
}

export interface ServiceContainerHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ServiceContainerRetrieveRequest {
    uuid: string;
}

export interface ServiceContainerSecretsRetrieveRequest {
    uuid: string;
}

export interface ServiceContainerSecretsUpdateRequest {
    uuid: string;
    serviceSecretModel: ServiceSecretModel;
}

export interface ServiceContainerTasksRequest {
    uuid: string;
    task: string;
    uuids?: Array<string>;
}

export interface ServiceContainerUnlockRequest {
    uuid: string;
}

export interface ServiceContainerUpdateRequest {
    uuid: string;
    serviceContainerModel: ServiceContainerModel;
}

/**
 * 
 */
export class ServiceContainerApi extends runtime.BaseAPI {

    /**
     * Create a new service container
     * Create a new service container
     */
    async serviceContainerCreateRaw(requestParameters: ServiceContainerCreateRequest): Promise<runtime.ApiResponse<ServiceContainerCreateSuccessModel>> {
        if (requestParameters.serviceContainerModel === null || requestParameters.serviceContainerModel === undefined) {
            throw new runtime.RequiredError('serviceContainerModel','Required parameter requestParameters.serviceContainerModel was null or undefined when calling serviceContainerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceContainerModelToJSON(requestParameters.serviceContainerModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new service container
     * Create a new service container
     */
    serviceContainerCreate = async(requestParameters: ServiceContainerCreateRequest): Promise<ServiceContainerCreateSuccessModel> => {
        const response = await this.serviceContainerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an service container
     * Delete an service container
     */
    async serviceContainerDeleteRaw(requestParameters: ServiceContainerDeleteRequest): Promise<runtime.ApiResponse<ServiceContainerRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an service container
     * Delete an service container
     */
    serviceContainerDelete = async(requestParameters: ServiceContainerDeleteRequest): Promise<ServiceContainerRemoveSuccessModel> => {
        const response = await this.serviceContainerDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an service container
     * retrieve a list of historical logs for an service container
     */
    async serviceContainerHistoryListRaw(requestParameters: ServiceContainerHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling serviceContainerHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an service container
     * retrieve a list of historical logs for an service container
     */
    serviceContainerHistoryList = async(requestParameters: ServiceContainerHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.serviceContainerHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a service container by its uuid
     * Retrieve an service container by its uuid
     */
    async serviceContainerRetrieveRaw(requestParameters: ServiceContainerRetrieveRequest): Promise<runtime.ApiResponse<ServiceContainerModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a service container by its uuid
     * Retrieve an service container by its uuid
     */
    serviceContainerRetrieve = async(requestParameters: ServiceContainerRetrieveRequest): Promise<ServiceContainerModel> => {
        const response = await this.serviceContainerRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve the secrets of a service container
     * Retrieve the secrets of a service container
     */
    async serviceContainerSecretsRetrieveRaw(requestParameters: ServiceContainerSecretsRetrieveRequest): Promise<runtime.ApiResponse<ServiceSecretResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerSecretsRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}/secrets`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the secrets of a service container
     * Retrieve the secrets of a service container
     */
    serviceContainerSecretsRetrieve = async(requestParameters: ServiceContainerSecretsRetrieveRequest): Promise<ServiceSecretResponseModel> => {
        const response = await this.serviceContainerSecretsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the secrets of a service container
     * Update the secrets of a service container
     */
    async serviceContainerSecretsUpdateRaw(requestParameters: ServiceContainerSecretsUpdateRequest): Promise<runtime.ApiResponse<ServiceSecretResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerSecretsUpdate.');
        }

        if (requestParameters.serviceSecretModel === null || requestParameters.serviceSecretModel === undefined) {
            throw new runtime.RequiredError('serviceSecretModel','Required parameter requestParameters.serviceSecretModel was null or undefined when calling serviceContainerSecretsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}/secrets`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceSecretModelToJSON(requestParameters.serviceSecretModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretResponseModelFromJSON(jsonValue));
    }

    /**
     * Update the secrets of a service container
     * Update the secrets of a service container
     */
    serviceContainerSecretsUpdate = async(requestParameters: ServiceContainerSecretsUpdateRequest): Promise<ServiceSecretResponseModel> => {
        const response = await this.serviceContainerSecretsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule tasks for a service container
     * Schedule tasks for a service container
     */
    async serviceContainerTasksRaw(requestParameters: ServiceContainerTasksRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerTasks.');
        }

        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling serviceContainerTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.task !== undefined) {
            queryParameters['task'] = requestParameters.task;
        }

        if (requestParameters.uuids) {
            queryParameters['uuids[]'] = requestParameters.uuids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}/task`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks for a service container
     * Schedule tasks for a service container
     */
    serviceContainerTasks = async(requestParameters: ServiceContainerTasksRequest): Promise<ApiResponseModel> => {
        const response = await this.serviceContainerTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock servicecontainer
     * Unlock service container
     */
    async serviceContainerUnlockRaw(requestParameters: ServiceContainerUnlockRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}/unlock`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Unlock servicecontainer
     * Unlock service container
     */
    serviceContainerUnlock = async(requestParameters: ServiceContainerUnlockRequest): Promise<ApiResponseModel> => {
        const response = await this.serviceContainerUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an service container
     * Update an service container
     */
    async serviceContainerUpdateRaw(requestParameters: ServiceContainerUpdateRequest): Promise<runtime.ApiResponse<ServiceContainerCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceContainerUpdate.');
        }

        if (requestParameters.serviceContainerModel === null || requestParameters.serviceContainerModel === undefined) {
            throw new runtime.RequiredError('serviceContainerModel','Required parameter requestParameters.serviceContainerModel was null or undefined when calling serviceContainerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service-container/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceContainerModelToJSON(requestParameters.serviceContainerModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an service container
     * Update an service container
     */
    serviceContainerUpdate = async(requestParameters: ServiceContainerUpdateRequest): Promise<ServiceContainerCreateSuccessModel> => {
        const response = await this.serviceContainerUpdateRaw(requestParameters);
        return await response.value();
    }

}
