import { Theme } from '@mui/material/styles';

const DashboardStyles = ((theme: Theme) => {
    return {
        widget_container: {
            padding: '100px 40px',
            display: 'flex',
            flexWrap: 'wrap'
        },
        page_title: {
            position: 'absolute',
            top: 25,
            left: 50,
            '& > h1': {
                fontSize: '22px'
            }
        },
        smpstars: {
            position: 'fixed',
            bottom: 0,
            textAlign: "center",
            zIndex: 88888,
            left: '50%',
            transform: 'translateX(-50%)',

            '& > i': {
                display: 'inline-block',
                height: 64,
                width: 64,
                marginBottom: -100,
                marginRight: 12,
                background: 'url(\'/Mario_Party_Star.webp\');',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transitionProperty: 'margin-bottom',
                transitionDuration: '1s',

                '&.move': {
                    marginBottom: 12
                }
            }
        }
    }
})

export default DashboardStyles