import React, { PropsWithChildren } from 'react';
import BaseDialogActions, { BaseDialogActionsProps } from 'components/base/BaseDialogActions';

export interface DeleteDialogActionsProps extends BaseDialogActionsProps {
}

const DeleteDialogActions: React.FC<DeleteDialogActionsProps> = (props: PropsWithChildren<DeleteDialogActionsProps>) => {

    const {children, onModalClosePress, onConfirm} = props

    return (
        <BaseDialogActions hasCancel hasDelete onModalClosePress={onModalClosePress} onConfirm={onConfirm}>
            {children}
        </BaseDialogActions>
    );
};

export default DeleteDialogActions;