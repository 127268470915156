import ActionTypes from "config/ActionTypes";
import User from 'api/override/UserModel';
import UserState from "./interface/UserState";
import { ErrorInterface } from "./types/App";
import { UserListSuccess, UserReducerAction } from "./types/User";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";

const initialState: UserState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,

    createHasSuccess: null,
    createErrorMessage: null,

    updateHasSuccess: null,
    updateErrorMessage: null,

    deleteHasSuccess: null,
    deleteErrorMessage: null,

    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0
    },

    timestampLastList: 0
};

export function UserReducers(state = initialState, action: UserReducerAction): UserState {
    switch (action.type) {
        case ActionTypes.API_USER_CREATE:
        case ActionTypes.API_USER_RETRIEVE:
        case ActionTypes.API_USER_UPDATE:
        case ActionTypes.API_USER_DELETE:
        case ActionTypes.API_USER_LIST:
        case ActionTypes.API_USER_HISTORY_LIST:
            return {
                ...state,
                selectedObject: undefined,

                createHasSuccess: null,
                createErrorMessage: null,

                updateHasSuccess: null,
                updateErrorMessage: null,

                deleteHasSuccess: null,
                deleteErrorMessage: null,
            };

        case ActionTypes.API_USER_CREATE_SUCCESS:
            return {
                ...state,
                createHasSuccess: true,
                createErrorMessage: null
            };

        case ActionTypes.API_USER_CREATE_FAILURE:

            return {
                ...state,
                createHasSuccess: false,
                createErrorMessage: (action as ErrorInterface).data.errors
            };

        case ActionTypes.API_USER_UPDATE_SUCCESS:
            return {
                ...state,
                updateHasSuccess: true,
                updateErrorMessage: null
            };

        case ActionTypes.API_USER_UPDATE_FAILURE:
            return {
                ...state,
                updateHasSuccess: false,
                updateErrorMessage: (action as ErrorInterface).data.errors
            };

        case ActionTypes.API_USER_DELETE_SUCCESS:
            return {
                ...state,
                deleteHasSuccess: true,
                deleteErrorMessage: null
            };

        case ActionTypes.API_USER_DELETE_FAILURE:
            return {
                ...state,
                deleteHasSuccess: false,
                deleteErrorMessage: (action as ErrorInterface).data.errors.join()
            };

        case ActionTypes.API_USER_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as User,
            };

        case ActionTypes.API_USER_LIST_SUCCESS:
            const result = action as UserListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<User>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_USER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_USER_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}