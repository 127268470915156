import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import Wizard from 'api/override/WizardModel';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface WizardDetailsProps {
    wizard: Wizard;
    onDelete?: () => void;
    onEdit?: () => void;
}

const WizardDetails = (props: WizardDetailsProps) => {
    const { t } = useTranslation();
    const { wizard, onDelete, onEdit } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('wizards.model')}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={wizard?.name} />
            <DetailsField propKey={t('general.description')} propVal={wizard?.description} />
        </div>
    );
};

export default WizardDetails;