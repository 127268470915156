import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useTrialCardStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            margin: '5px 0',
            minHeight: 44,
            '&.accepted': {
                height: 140,
                marginBottom: 18
            },
            '&.open': {
                height: 44,
            }
        },
        acceptedContainer: {
            border: 'solid 2px #ececec',
            borderRadius: 5,
            padding: '10px 0 10px 10px',
        },
        chevron: {
            transition: 'transform .420s ease-in',
            '&.up': {
                transform: 'rotate(-180deg)'
            }
        },
        client_name: {
            fontSize: 18
        },
        client_name_open: {
            fontSize: 18,
            paddingLeft: 20
        },
        client_row_divider: {
            height: 40,
            '&.accepted': {
                height: 120,
                width: 1,
                alignSelf: 'center',
                marginRight: 8
            },
        },
        open_card_container: {
            border: 'solid 2px #ececec', 
            borderRadius: 5,
            margin: '5px 0',
        },
        open_card_btn_container: {
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between'
        },
        approval_btn: {
            color: '#fff', 
            fontWeight: 600, 
            height: '36%',
            '&.accept': {
                background: '#51D231'
            },
            '&.reject': {
                background: '#FF4921'
            }
        }
    }
})

export default useTrialCardStyles