import { getUnixTime } from "date-fns";

export default class TimeUtil {
    static getNowUnixTimestamp(): number {
        return getUnixTime(new Date())
    }

    static havePassedSince(seconds: number, lastTimestamp: number): boolean {
        return this.getNowUnixTimestamp() - lastTimestamp > seconds
    }
}