import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useDetailsViewHeaderStyles = makeStyles()((theme: Theme) => {
    return {  
        header: {
            display: 'flex',
            height: 50,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 40
        },        
        titles: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 5,
            '& > *': {
                margin: 0
            }
        }
    }
})

export default useDetailsViewHeaderStyles
