import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ButtonStyled from 'components/styled/ButtonStyled';
import Package from 'api/override/PackageModel';
import PackageForm from 'views/Packages/components/PackageForm';
import { retrievePackageSchema } from 'schemas/Package';


interface PackageCreateFormProps {
    onFormSubmit: (pk: Package) => void;
}

const PackageCreateForm = (props: PackageCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new Package({
                name: '',
                description: '',
                price: 0,
                services: []
            })}
            validationSchema={retrievePackageSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Package>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('packages.model')})}</h2>
                    
                    <PackageForm formik={formik} isCreate/>

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('packages.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default PackageCreateForm;