/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ConditionOptionModelInterface {
    prop?: string;
    options?: Array<string>;
}

/**
 * 
 * @export
 * @interface ConditionOptionModel
 */
export class ConditionOptionModel extends BaseModel<ConditionOptionModelInterface> {

    /**
     * The name of the variable the condition is on
     * @type {string}
     * @memberof ConditionOptionModel
     */
    prop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConditionOptionModel
     */
    options?: Array<string>;

    constructor(data?: ConditionOptionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConditionOptionModel, value: StringDateInterface<ConditionOptionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConditionOptionModelFromJSON(json: any): ConditionOptionModel {
    return ConditionOptionModelFromJSONTyped(json, false);
}

export function ConditionOptionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionOptionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConditionOptionModel;
    try {
        let localClass = require.context('../override', true)('./ConditionOptionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'prop': !exists(json, 'prop') ? undefined : json['prop'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    });
}

export function ConditionOptionModelToJSON(value?: ConditionOptionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConditionOptionModel;
    try {
        let localClass = require.context('../override', true)('./ConditionOptionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'prop': value.prop,
        'options': value.options,
    } as StringDateInterface<ConditionOptionModel>;
    return createClass.toJson(value, model);
}


