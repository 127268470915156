import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TableParams, TableParamsSorting } from '../models/table/TableParams';
import TableViewDelegate from '../interface/TableViewDelegate';
import CustomActionButton, { CustomIconButton } from 'models/table/CustomActionButton';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    LinearProgress,
    Divider
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableColumn from 'models/table/TableColumn';
import i18next from 'i18next';
import { Details } from 'mdi-material-ui';

const PREFIX = 'TableList';

const classes = {
    backdrop: `${PREFIX}-backdrop`,
    notFoundRow: `${PREFIX}-notFoundRow`,
    setTableLayout: `${PREFIX}-setTableLayout`,
    cell_styles: `${PREFIX}-cell_styles`,
    delete_row_style: `${PREFIX}-delete_row_style`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    [`& .${classes.notFoundRow}`]: {
        display: 'table-row',
        textAlign: 'center',
        backgroundColor: '#f0f0f0'
    },

    [`& .${classes.setTableLayout}`]: {
        tableLayout: "fixed"
    },

    [`& .${classes.cell_styles}`]: {
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap" 
    },

    [`& .${classes.delete_row_style}`]: {
        opacity: 0.7
    }
}));

interface Props {
    actionColumn?: boolean;
    selectableRows?: boolean;
    columns: TableColumn[];
    count: number;
    idColumn?: string;
    listItems: any[];
    tableParams?: TableParams;
    selected?: any[];
    hasEditAction?: boolean;
    hasDetailsAction?: boolean;
    hasDeleteAction?: boolean;
    isRefreshing?: boolean;
    delegate?: TableViewDelegate;
    noResultsMessage?: string;
    customActions?: CustomActionButton[];
    shouldDisableTopPagination?: boolean;
    shouldDisableBottomPagination?: boolean;
    shouldDisablePagination?: boolean;
}

const TableList: React.FC<Props> = (props) => {

    const { t } = useTranslation();
    const {
        columns,
        idColumn,
        hasEditAction,
        hasDetailsAction,
        hasDeleteAction,
        selectableRows,
        count,
        listItems,
        selected,
        isRefreshing,
        delegate,
        noResultsMessage,
        customActions,
        tableParams
    } = props;

    const idColumnToUse = idColumn ?? 'id';
    // deselect all rows on getData
    useEffect(() => {
        if(isRefreshing === true) {
            delegate?.onSelectAll?.([])
        }
    }, [isRefreshing, delegate])

    //handle page change
    const handlePageChange = (event, newPage) => {
        delegate?.onPageChanged?.(newPage, tableParams?.limit);
    };

    // handle click on Row
    const handleSelectRow = (event, id) => {
        if (selectableRows && selected) {
            event.preventDefault();
            const selectedIndex = selected.indexOf(id);

            let val: any[] = [];
            if (selectedIndex === -1) {
                val = selected.concat(id);
            } else if (selectedIndex === 0) {
                val = val.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                val = val.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                val = val.concat(
                    selected.slice(0, selectedIndex)
                ).concat(
                    selected.slice(selectedIndex + 1)
                );
            }
            delegate?.onSelectRow?.(val);
        }
    };

    // handle select all button
    const handleSelectAll = (shouldDeselect: boolean) => {
        if(shouldDeselect) {
            delegate?.onSelectAll?.([])
        } else {
            delegate?.onSelectAll?.(listItems.map((row) => row[idColumnToUse]))
        }
    };

    //handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        delegate?.onPageChanged?.(0, parseInt(event.target.value));
    };

    //handle change sorting
    const handleSorting = (columnName: string) => {
        const curSorting = tableParams?.sorting;
        if (!curSorting || curSorting.direction === 'asc') {
            const newSorting = new TableParamsSorting(
                columnName,
                (curSorting.direction === 'asc' && columnName === curSorting.column) ? 'desc' : 'asc'
            );
            delegate?.onSortingSet?.(newSorting.column, newSorting.direction);
        } else {
            delegate?.onSortingClear?.();
        }
    };

    const handleOnAction = (name: string, row: any) => {
        switch (name) {
            case 'edit':
                delegate?.onEdit?.(row)
                break
            case 'delete':
                delegate?.onDelete?.(row)
                break
            case 'details':
                delegate.onDetails(row)
                break
            default:
                delegate?.onAction?.(name, row)
                break
        }
    }

    let newCustomActions = customActions || []
    if (hasEditAction && !newCustomActions.some((value: CustomActionButton) => value.name === 'edit')) {
        newCustomActions.push(new CustomIconButton('edit', t('global.button.edit'), 'edit', handleOnAction, EditIcon))
    }
    if (hasDetailsAction && !newCustomActions.some((value: CustomActionButton) => value.name === 'details')) {
        newCustomActions.push(new CustomIconButton('details', t('global.button.details'), 'details', handleOnAction, Details))
    }
    if (hasDeleteAction && !newCustomActions.some((value: CustomActionButton) => value.name === 'delete')) {
        newCustomActions.push(new CustomIconButton('delete', t('global.button.delete'), 'delete', handleOnAction, DeleteIcon))
    }

    let noResults: string = noResultsMessage ?? i18next.t('global.title.no_results')

    const numSelected = selected?.length;

    const tablePagination: JSX.Element = !props.shouldDisablePagination ? (
        <TablePagination
            component='div'
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={tableParams?.getCurrentPage()}
            rowsPerPage={tableParams?.limit}
            rowsPerPageOptions={[10, 25, 100]}
            labelRowsPerPage={t('paging.rows_per_page')}
            style={{ width: '100%', display: 'block', padding: '10px 18px' }}
        />
    ) : (null)
    return (
        <Root>
            {!props.shouldDisableTopPagination && <>{tablePagination}</>}
            <Divider/>
            {isRefreshing && <LinearProgress color="primary" />}
            <Table className={classes.setTableLayout}>
                <TableHead>
                    <TableRow role="checkbox">
                        {selectableRows &&
                            <TableCell padding="checkbox" variant="head">
                                <Checkbox
                                    color='primary'
                                    indeterminate={numSelected > 0 && numSelected < Math.min(tableParams?.limit ?? 0, listItems.length)}
                                    checked={numSelected === Math.min(tableParams?.limit ?? 0, listItems.length) && numSelected > 0}
                                    onChange={(e) => handleSelectAll(numSelected > 0)}
                                />
                            </TableCell>
                        }
                        {columns.map(column => {
                            const currentSort = tableParams?.sorting;
                            let style: any = {textTransform: 'capitalize'}
                            if (column.width != null) {
                                style = {...style, width: column.width}
                            }
                            return (
                                <TableCell
                                    key={column.key}
                                    align={column.align ? column.align : 'left'}
                                    padding={column.disablePadding ? 'none' : 'normal'}
                                    style={style}
                                    variant="head"
                                    sortDirection={(currentSort?.column === column.key) ? currentSort?.direction : false}
                                >
                                    {(column.isSortable) ? (
                                        <TableSortLabel
                                            active={currentSort?.column === column.key}
                                            direction={currentSort?.direction ?? 'asc'}
                                            onClick={() => handleSorting(column.sortingColumn ?? column.key)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        <React.Fragment>{column.label}</React.Fragment>
                                    )}
                                </TableCell>
                            )
                        })
                        }
                        {newCustomActions.map((action) => {
                            return (
                                <TableCell padding="checkbox" variant="head" key={action.name + '-head'} />
                            )
                        })}
                    </TableRow>
                </TableHead>
                {listItems.length !== 0 && !isRefreshing &&
                    <TableBody>
                        <>{listItems.map((row, index) => {
                            const isSelected = (selected?.length > 0 && selected?.indexOf(row[idColumnToUse]) !== -1);
                            if (delegate && delegate.cellForRow) {
                                return (
                                    delegate.cellForRow(index, isSelected)
                                );
                            } else {
                                const rowStyle = row['shouldFadeRow'] === true ? classes.delete_row_style : null
                                const onRowClick = (event) => handleSelectRow(event, row[idColumnToUse]);
                                return (
                                    
                                    <TableRow
                                        hover
                                        key={`r${row[idColumnToUse]}`}
                                        selected={isSelected}
                                        className={rowStyle}
                                        color='primary'
                                    >
                                        {selectableRows &&
                                            <TableCell padding="checkbox" onClick={onRowClick}>
                                                <Checkbox checked={isSelected} color='primary'/>
                                            </TableCell>
                                        }
                                        {columns.map((r, idx) => {
                                            if (r.customValue instanceof Function) {
                                                return (<TableCell
                                                    align={r.align}
                                                    padding={r.disablePadding ? 'none' : 'normal'}
                                                    onClick={onRowClick}
                                                    key={`c${idx}`}>
                                                    <div className={classes.cell_styles}> {r.customValue(row)} </div>
                                                </TableCell>);
                                            } else {
                                                return (<TableCell
                                                    key={`c${idx}`}
                                                    align={r.align}
                                                    onClick={onRowClick}
                                                    padding={r.disablePadding ? 'none' : 'normal'}>
                                                    <div className={classes.cell_styles}> {row[r.key]} </div>
                                                </TableCell>)
                                            }
                                        })
                                        }

                                        {newCustomActions.map((action: CustomActionButton) => {

                                            return (
                                                <TableCell padding='none' key={action.name}>
                                                    {action.render(row)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            }
                        })}</>
                    </TableBody>
                }
            </Table>
            {listItems.length === 0 && !isRefreshing && <label style={{margin: 20}}>{noResults}</label> }
            {!props.shouldDisableBottomPagination && tablePagination}
        </Root>
    );
};

export default TableList;