/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ClientModel,
    ClientModelFromJSON,
    ClientModelToJSON,
} from '../models';

/**
 * 
 */
export class ClientConfigurationApi extends runtime.BaseAPI {

    /**
     * Retrieve a client\'s wizard. The response will depend on the token
     * Retrieve a client\'s wizard
     */
    async clientConfigurationWizardRaw(): Promise<runtime.ApiResponse<ClientModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client-configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a client\'s wizard. The response will depend on the token
     * Retrieve a client\'s wizard
     */
    clientConfigurationWizard = async(): Promise<ClientModel> => {
        const response = await this.clientConfigurationWizardRaw();
        return await response.value();
    }

}
