import React, { PropsWithChildren, useEffect } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogProps, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDialogStyles from 'styles/DialogStyles';
import * as MDIcons from 'mdi-material-ui';
import CloseIcon from '@mui/icons-material/Close';
export interface BaseModalProps extends Omit<DialogProps,  | 'onReset' | 'onSubmit'> {
    open: boolean,
    onModalClosePress: () => void;
    title?: string;
    subtitle?: string;
    icon?: string;
    isLoading?: boolean;
}

const BaseModal: React.FC<BaseModalProps> = (props: PropsWithChildren<BaseModalProps>) => {

    const { onModalClosePress, isLoading } = props;
    const {classes} = useDialogStyles();
    const { t } = useTranslation();
    const open = props.open || false;

    useEffect(() => {
        const onKeyDown = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                onModalClosePress();
            }
        };
        document.body.addEventListener('keydown', onKeyDown, false);
        return () => document.body.removeEventListener('keydown', onKeyDown, false);
    }, [onModalClosePress]);

    const getIcon = (): JSX.Element => {
        if(props.icon == null) {
            return <></>
        } else {
            const HeaderIcon = MDIcons[props.icon]
            return <HeaderIcon/>
        }
    }

    return (
        <Dialog
            open={open}
            onClose={ onModalClosePress }
            aria-labelledby="details-dialog"
            maxWidth={ props.maxWidth ?? 'md' }
            fullWidth={true}
            className={classes.dialog}
        >
            <DialogTitle id="details-dialog" style={{padding: '0px 20px'}}>
                <div className={classes.dialog_title_container}>
                    {getIcon()}
                    <div className={classes.dialog_title_subtitle_container}>
                        <h2>{props.title || t('general.detail')}</h2>
                        {props.subtitle != null && <p>{props.subtitle}</p>}
                    </div>
                    <IconButton
                        id="close-dialog-button"
                        onClick={onModalClosePress}
                        className={classes.dialog_close_button}
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialog_content} >
                { props.children }
            </DialogContent>
            {isLoading ? 
                <LinearProgress/>
                :
                <></>
            }
        </Dialog>
    );
};

export default BaseModal;