import { CentralInstanceListResultModel, InstanceRetrieveListModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface CentralInstanceReducerAction extends BaseReducerAction {
    errorObj?: any;
}
export interface CentralInstanceList extends BaseReducerAction {
    type: ActionTypes.API_CENTRAL_INSTANCE_LIST;
    params: InstanceRetrieveListModel;
}

export interface CentralInstanceListSuccess extends BaseSagaListResponse<CentralInstanceListResultModel> {
    type: ActionTypes.API_CENTRAL_INSTANCE_LIST_SUCCESS;
}

export interface CentralInstanceClearListParams {
    force?: boolean;
}
export interface CentralInstanceClearList extends BaseReducerAction {
    type: ActionTypes.API_CENTRAL_INSTANCE_LIST_CLEAR;
    params?: CentralInstanceClearListParams;
}

export default class CentralInstanceActions {
    
    static CreateCentralInstanceList(params: InstanceRetrieveListModel): CentralInstanceList {
        return {
            type: ActionTypes.API_CENTRAL_INSTANCE_LIST,
            params: params
        }
    }

    static CreateCentralInstanceListSuccess(params: CentralInstanceListResultModel): CentralInstanceListSuccess {
        return {
            type: ActionTypes.API_CENTRAL_INSTANCE_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearCentralInstanceList(force: boolean = false): CentralInstanceClearList {
        return {
            type: ActionTypes.API_CENTRAL_INSTANCE_LIST_CLEAR,
            params: {force: force}
        }
    }
}