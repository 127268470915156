/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelFromJSONTyped,
    ImageVersionModelToJSON,
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelFromJSONTyped,
    InstanceModelToJSON,
    ServiceConfigModel,
    ServiceConfigModelFromJSON,
    ServiceConfigModelFromJSONTyped,
    ServiceConfigModelToJSON,
    ServiceModel,
    ServiceModelFromJSON,
    ServiceModelFromJSONTyped,
    ServiceModelToJSON,
} from './';


export interface ServiceContainerModelInterface {
    uuid?: string;
    image_version_id?: number;
    image_version?: ImageVersionModel;
    instance_id?: number;
    instance_uuid?: string | null;
    service_id?: number;
    status?: string;
    api_key?: string;
    text?: string | null;
    config?: ServiceConfigModel;
    config_correct?: boolean;
    instance?: InstanceModel;
    service?: ServiceModel;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

/**
 * 
 * @export
 * @interface ServiceContainerModel
 */
export class ServiceContainerModel extends BaseModel<ServiceContainerModelInterface> {

    /**
     * uuid of the service container
     * @type {string}
     * @memberof ServiceContainerModel
     */
    uuid?: string;
    /**
     * id of the currenct image version
     * @type {number}
     * @memberof ServiceContainerModel
     */
    image_version_id?: number;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof ServiceContainerModel
     */
    image_version?: ImageVersionModel;
    /**
     * id of the instance connected to this service
     * @type {number}
     * @memberof ServiceContainerModel
     */
    instance_id?: number;
    /**
     * uuid of the instance connected to this service
     * @type {string}
     * @memberof ServiceContainerModel
     */
    instance_uuid?: string | null;
    /**
     * id of the service
     * @type {number}
     * @memberof ServiceContainerModel
     */
    service_id?: number;
    /**
     * Last known status of the container
     * @type {string}
     * @memberof ServiceContainerModel
     */
    status?: string;
    /**
     * API key for connecting to instance API
     * @type {string}
     * @memberof ServiceContainerModel
     */
    api_key?: string;
    /**
     * WYSWYG text about the connector container
     * @type {string}
     * @memberof ServiceContainerModel
     */
    text?: string | null;
    /**
     * 
     * @type {ServiceConfigModel}
     * @memberof ServiceContainerModel
     */
    config?: ServiceConfigModel;
    /**
     * A bool indicating whether the config is up to date with the parent service
     * @type {boolean}
     * @memberof ServiceContainerModel
     */
    config_correct?: boolean;
    /**
     * 
     * @type {InstanceModel}
     * @memberof ServiceContainerModel
     */
    instance?: InstanceModel;
    /**
     * 
     * @type {ServiceModel}
     * @memberof ServiceContainerModel
     */
    service?: ServiceModel;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceContainerModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceContainerModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ServiceContainerModel
     */
    deleted_at?: Date;

    constructor(data?: ServiceContainerModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceContainerModel, value: StringDateInterface<ServiceContainerModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceContainerModelFromJSON(json: any): ServiceContainerModel {
    return ServiceContainerModelFromJSONTyped(json, false);
}

export function ServiceContainerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceContainerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceContainerModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'image_version_id': !exists(json, 'image_version_id') ? undefined : Number(json['image_version_id']),
        'image_version': !exists(json, 'image_version') ? undefined : ImageVersionModelFromJSON(json['image_version']),
        'instance_id': !exists(json, 'instance_id') ? undefined : Number(json['instance_id']),
        'instance_uuid': !exists(json, 'instance_uuid') ? undefined : json['instance_uuid'],
        'service_id': !exists(json, 'service_id') ? undefined : Number(json['service_id']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'config': !exists(json, 'config') ? undefined : ServiceConfigModelFromJSON(json['config']),
        'config_correct': !exists(json, 'config_correct') ? undefined : json['config_correct'],
        'instance': !exists(json, 'instance') ? undefined : InstanceModelFromJSON(json['instance']),
        'service': !exists(json, 'service') ? undefined : ServiceModelFromJSON(json['service']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function ServiceContainerModelToJSON(value?: ServiceContainerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceContainerModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'image_version_id': value.image_version_id,
        'image_version': ImageVersionModelToJSON(value.image_version),
        'instance_id': value.instance_id,
        'instance_uuid': value.instance_uuid,
        'service_id': value.service_id,
        'status': value.status,
        'api_key': value.api_key,
        'text': value.text,
        'config': ServiceConfigModelToJSON(value.config),
        'config_correct': value.config_correct,
        'instance': InstanceModelToJSON(value.instance),
        'service': ServiceModelToJSON(value.service),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
    } as StringDateInterface<ServiceContainerModel>;
    return createClass.toJson(value, model);
}


