import ActionTypes from "config/ActionTypes";
import Constants from "config/Constants";
import TimeUtil from "utils/TimeUtil";
import Instance from '../api/override/InstanceModel';
import InstanceState from "./interface/InstanceState";
import BaseReducerAction from "./interface/ReducerAction";
import { ErrorInterface } from "./types/App";
import { InstanceListSuccess, InstanceRetrieveSettingsSuccess } from "./types/Instance";
import InstanceSetting from "api/override/InstanceSettingModel";

const initialState: InstanceState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    isFetchingConnectors: false,
    error: null,
    logs: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    connectors: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    services: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0
    },
    settings: [],
    timestampLastList: 0
};

export function InstanceReducers(state = initialState, action: BaseReducerAction): InstanceState {
    switch (action.type) {
        case ActionTypes.API_INSTANCE_UPDATE:
        case ActionTypes.API_INSTANCE_RETRIEVE:
        case ActionTypes.API_INSTANCE_LIST:
        case ActionTypes.API_INSTANCE_CREATE:
        case ActionTypes.API_INSTANCE_DELETE:
        case ActionTypes.API_INSTANCE_LOGS_LIST:
        case ActionTypes.API_INSTANCE_HISTORY_LIST:
            return {
                ...state,
                selectedObject: null,
                error: null
            };
            
        case ActionTypes.API_INSTANCE_CREATE_SUCCESS:
        case ActionTypes.API_INSTANCE_RETRIEVE_SUCCESS:
        case ActionTypes.API_INSTANCE_UPDATE_SUCCESS:
            const instance = (action.params as Instance);
            if (action.type === ActionTypes.API_INSTANCE_CREATE_SUCCESS) {
                instance.isLaunching = true;
            }
            return {
                ...state,
                selectedObject: instance,
                error: null
            };

        case ActionTypes.API_INSTANCE_LOGS_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                logs: action.params
            }
        case ActionTypes.API_INSTANCE_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                error: null,
                history: action.params
            }

        case ActionTypes.API_INSTANCE_CREATE_FAILURE:
        case ActionTypes.API_INSTANCE_DELETE_FAILURE:
        case ActionTypes.API_INSTANCE_LOGS_LIST_FAILURE:
        case ActionTypes.API_INSTANCE_HISTORY_LIST_FAILURE:
            return {
                ...state,
                selectedObject: null,
                error: (action as ErrorInterface).data?.errors
            }

        case ActionTypes.API_INSTANCE_LIST_CONNECTORS_FAILURE:
            return {
                ...state,
                isFetchingConnectors: false,
                selectedObject: null,
                error: (action as ErrorInterface).data?.errors
            }

        case ActionTypes.API_INSTANCE_DELETE_SUCCESS:
            return {
                ...state,
                selectedObject: null,
                error: null
            };    

        case ActionTypes.API_INSTANCE_LIST_SUCCESS:
            const result = action as InstanceListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Instance>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                selectedObject: null,
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_INSTANCE_LIST_CONNECTORS:
            return {
                ...state,
                isFetchingConnectors: true,
            }
        case ActionTypes.API_INSTANCE_LIST_CONNECTORS_SUCCESS:
            return {
                ...state,
                isFetchingConnectors: false,
                error: null,
                connectors: action.params
            }
        case ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS_SUCCESS:
            return {
                ...state,
                error: null,
                services: action.params
            }
        case ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: (action as InstanceRetrieveSettingsSuccess).params.data as InstanceSetting[] ?? []
            }
        case ActionTypes.INSTANCE_VIEW_CLEAR_CONNECTORS:
            return {
                ...state,
                connectors: {
                    items: [],
                    count_filtered: 0,
                    count_unfiltered: 0,
                },
                services: {
                    items: [],
                    count_filtered: 0,
                    count_unfiltered: 0,
                },
                settings: []
            }
        case ActionTypes.API_INSTANCE_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}