import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import PropertyActions, { PropertyCreate, PropertyDelete, PropertyHistoryList, PropertyList, PropertyRetrieve, PropertyUpdate } from 'reducers/types/Property';
import { PropertyListRequest, PropertyApi, PropertyUpdateRequest, PropertyRetrieveRequest, PropertyCreateRequest, PropertyDeleteRequest, PropertyHistoryListRequest, PropertyListResultModel } from 'api';
import Property from 'reducers/types/Property';
import { TableParams } from 'models/table/TableParams';

export function* PropertySagas() {
    yield takeLatest(ActionTypes.API_PROPERTY_LIST, PropertySaga.propertyListWorkerSaga);
    yield takeLatest(ActionTypes.API_PROPERTY_UPDATE, PropertySaga.propertyUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_PROPERTY_RETRIEVE, PropertySaga.propertyRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_PROPERTY_CREATE, PropertySaga.propertyCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_PROPERTY_DELETE, PropertySaga.propertyDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_PROPERTY_HISTORY_LIST, PropertySaga.propertyHistoryListWorkerSaga);
}
export class PropertySaga {
    
    static *propertyListWorkerSaga(action: PropertyList) {
        try {
            const propertiesArgs = {
                propertyRetrieveListModel: action.params
            } as PropertyListRequest

            const data = yield ApiHandler(new PropertyApi().propertyList, propertiesArgs)
            yield put(PropertyActions.CreatePropertyListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_PROPERTY_LIST_FAILURE, error as Error))
        }
    }

    static *propertyUpdateWorkerSaga(action: PropertyUpdate) {
        try {
            
            const data = yield ApiHandler(new PropertyApi().propertyUpdate, action.params as PropertyUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(PropertyActions.CreatePropertyUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_PROPERTY_UPDATE_FAILURE, error as Error))
        }
    }

    static propertyRetrieve = async(property: number): Promise<Property> => {
        return await new PropertyApi().propertyRetrieve({id: property});
    }
    
    static *propertyRetrieveWorkerSaga(action: PropertyRetrieve) {
        try {
    
            const propertiesArgs = {
                id: action.params
            } as PropertyRetrieveRequest
            const data = yield ApiHandler(new PropertyApi().propertyRetrieve, propertiesArgs)
    
            // dispatch a success action to the store with the activities
            yield put(PropertyActions.CreatePropertyRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_PROPERTY_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *propertyCreateWorkerSaga(action: PropertyCreate) {
        try {
    
            const propertiesArgs = {
                propertyModel: action.params
            } as PropertyCreateRequest
            const data = yield ApiHandler(new PropertyApi().propertyCreate, propertiesArgs)
    
            // dispatch a success action to the store with the activities
            yield put(PropertyActions.CreatePropertyCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_PROPERTY_CREATE_FAILURE, error as Error))
        }
    }
    
    static *propertyDeleteWorkerSaga(action: PropertyDelete) {
        try {
            const propertiesArgs = {
                id: action.params
            } as PropertyDeleteRequest
            const data = yield ApiHandler(new PropertyApi().propertyDelete, propertiesArgs)
    
            // dispatch a success action to the store with the activities
            yield put(PropertyActions.CreatePropertyDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_PROPERTY_DELETE_FAILURE, error as Error))
        }
    }
    
    static *propertyHistoryListWorkerSaga(action: PropertyHistoryList) {
        try{
            const propertyArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as PropertyHistoryListRequest
    
            const data = yield ApiHandler(new PropertyApi().propertyHistoryList, propertyArgs);
            yield put(PropertyActions.CreatePropertyHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_PROPERTY_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static async propertyList (tableParams?: TableParams): Promise<PropertyListResultModel> {
        const retrieveListRequest = {
            propertyRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as PropertyListRequest;
        return await new PropertyApi().propertyList(retrieveListRequest);
    }
}

