/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    PortCorruptionRequest,
    PortCorruptionRequestFromJSON,
    PortCorruptionRequestToJSON,
    PortRegistrationRequest,
    PortRegistrationRequestFromJSON,
    PortRegistrationRequestToJSON,
} from '../models';

export interface PortCorruptionOperationRequest {
    portCorruptionRequest: PortCorruptionRequest;
}

export interface PortHistoryListRequest {
    id: number;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface PortRegistrationOperationRequest {
    portRegistrationRequest: PortRegistrationRequest;
}

export interface PortRemoveRequest {
    id: number;
}

/**
 * 
 */
export class PortApi extends runtime.BaseAPI {

    /**
     * Mark a port as corrupt
     * Mark a port as corrupt
     */
    async portCorruptionRaw(requestParameters: PortCorruptionOperationRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.portCorruptionRequest === null || requestParameters.portCorruptionRequest === undefined) {
            throw new runtime.RequiredError('portCorruptionRequest','Required parameter requestParameters.portCorruptionRequest was null or undefined when calling portCorruption.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/port/corrupt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortCorruptionRequestToJSON(requestParameters.portCorruptionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Mark a port as corrupt
     * Mark a port as corrupt
     */
    portCorruption = async(requestParameters: PortCorruptionOperationRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.portCorruptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a port
     * retrieve a list of historical logs for a port
     */
    async portHistoryListRaw(requestParameters: PortHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling portHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/port/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a port
     * retrieve a list of historical logs for a port
     */
    portHistoryList = async(requestParameters: PortHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.portHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a random open port
     * Get a random open port
     */
    async portOpenRaw(): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/port/open`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Get a random open port
     * Get a random open port
     */
    portOpen = async(): Promise<ApiSuccessResponseModel> => {
        const response = await this.portOpenRaw();
        return await response.value();
    }

    /**
     * Register a port
     * Register a port
     */
    async portRegistrationRaw(requestParameters: PortRegistrationOperationRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.portRegistrationRequest === null || requestParameters.portRegistrationRequest === undefined) {
            throw new runtime.RequiredError('portRegistrationRequest','Required parameter requestParameters.portRegistrationRequest was null or undefined when calling portRegistration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/port/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortRegistrationRequestToJSON(requestParameters.portRegistrationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Register a port
     * Register a port
     */
    portRegistration = async(requestParameters: PortRegistrationOperationRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.portRegistrationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Either clears a port or informs the user the port is about to be cleared
     * Clear a port
     */
    async portRemoveRaw(requestParameters: PortRemoveRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portRemove.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/port/remove/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Either clears a port or informs the user the port is about to be cleared
     * Clear a port
     */
    portRemove = async(requestParameters: PortRemoveRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.portRemoveRaw(requestParameters);
        return await response.value();
    }

}
