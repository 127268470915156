/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    BranchModel,
    BranchModelFromJSON,
    BranchModelFromJSONTyped,
    BranchModelToJSON,
    StepviewModel,
    StepviewModelFromJSON,
    StepviewModelFromJSONTyped,
    StepviewModelToJSON,
} from './';


export interface WizardModelInterface {
    id?: number;
    name?: string;
    description?: string;
    stepviews?: Array<StepviewModel>;
    branches?: Array<BranchModel>;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface WizardModel
 */
export class WizardModel extends BaseModel<WizardModelInterface> {

    /**
     * id of the wizard
     * @type {number}
     * @memberof WizardModel
     */
    id?: number;
    /**
     * The name of the wizard
     * @type {string}
     * @memberof WizardModel
     */
    name?: string;
    /**
     * The description of the wizard
     * @type {string}
     * @memberof WizardModel
     */
    description?: string;
    /**
     * The stepviews assigned to this wizard
     * @type {Array<StepviewModel>}
     * @memberof WizardModel
     */
    stepviews?: Array<StepviewModel>;
    /**
     * The branches assigned to this wizard
     * @type {Array<BranchModel>}
     * @memberof WizardModel
     */
    branches?: Array<BranchModel>;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof WizardModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof WizardModel
     */
    updated_at?: Date;

    constructor(data?: WizardModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: WizardModel, value: StringDateInterface<WizardModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function WizardModelFromJSON(json: any): WizardModel {
    return WizardModelFromJSONTyped(json, false);
}

export function WizardModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WizardModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = WizardModel;
    try {
        let localClass = require.context('../override', true)('./WizardModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'stepviews': !exists(json, 'stepviews') ? undefined : ((json['stepviews'] as Array<any>).map(StepviewModelFromJSON)),
        'branches': !exists(json, 'branches') ? undefined : ((json['branches'] as Array<any>).map(BranchModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function WizardModelToJSON(value?: WizardModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = WizardModel;
    try {
        let localClass = require.context('../override', true)('./WizardModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'stepviews': value.stepviews === undefined ? undefined : ((value.stepviews as Array<any>).map(StepviewModelToJSON)),
        'branches': value.branches === undefined ? undefined : ((value.branches as Array<any>).map(BranchModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<WizardModel>;
    return createClass.toJson(value, model);
}


