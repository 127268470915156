import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { BranchHistoryListRequest } from "api/apis/BranchApi";
import { BranchCreateSuccessModel } from "api/models/BranchCreateSuccessModel";
import { BranchListResultModel } from "api/models/BranchListResultModel";
import { BranchRetrieveListModel } from "api/models/BranchRetrieveListModel";
import Branch from "api/override/BranchModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface BranchList extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_LIST;
    params: BranchRetrieveListModel;
}

export interface BranchListSuccess extends BaseSagaListResponse<BranchListResultModel> {
    type: ActionTypes.API_BRANCH_LIST_SUCCESS;
}

export interface BranchCreate extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_CREATE;
    params: Branch;
}

export interface BranchCreateSuccess extends BaseSagaListResponse<BranchCreateSuccessModel> {
    type: ActionTypes.API_BRANCH_CREATE_SUCCESS;
}

export interface BranchUpdateParams {
    id: number;
    branchModel: Branch;
}

export interface BranchUpdate extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_UPDATE;
    params: BranchUpdateParams;
}

export interface BranchUpdateSuccess extends BaseSagaListResponse<BranchCreateSuccessModel> {
    type: ActionTypes.API_BRANCH_UPDATE_SUCCESS;
}

export interface BranchRetrieve extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_RETRIEVE;
    params: number;
}

export interface BranchRetrieveSuccess extends BaseSagaListResponse<Branch> {
    type: ActionTypes.API_BRANCH_RETRIEVE_SUCCESS
}

export interface BranchDelete extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_DELETE;
    params: number;
}

export interface BranchDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_BRANCH_DELETE_SUCCESS;
}

export interface BranchHistoryList extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_HISTORY_LIST;
    params: BranchHistoryListRequest;
}

export interface BranchHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_BRANCH_HISTORY_LIST_SUCCESS;
}

export interface BranchClearListParams {
    force?: boolean;
}
export interface BranchClearList extends BaseReducerAction {
    type: ActionTypes.API_BRANCH_LIST_CLEAR;
    params?: BranchClearListParams;
}

export default class BranchActions {
    
    static CreateBranchList(params: BranchRetrieveListModel): BranchList {
        return {
            type: ActionTypes.API_BRANCH_LIST,
            params: params
        }
    }

    static CreateBranchListSuccess(params: BranchListResultModel): BranchListSuccess {
        return {
            type: ActionTypes.API_BRANCH_LIST_SUCCESS,
            params: params
        }
    }

    static CreateBranchCreate(params: Branch): BranchCreate {
        return {
            type: ActionTypes.API_BRANCH_CREATE,
            params: params
        }
    }

    static CreateBranchCreateSuccess(params: BranchCreateSuccessModel): BranchCreateSuccess {
        return {
            type: ActionTypes.API_BRANCH_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateBranchUpdate(params: Branch): BranchUpdate {
        return {
            type: ActionTypes.API_BRANCH_UPDATE,
            params: {
                id: params.id,
                branchModel: params
            }
        }
    }

    static CreateBranchUpdateSuccess(params: BranchCreateSuccessModel): BranchUpdateSuccess {
        return {
            type: ActionTypes.API_BRANCH_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateBranchRetrieve(params: number): BranchRetrieve {
        return {
            type: ActionTypes.API_BRANCH_RETRIEVE,
            params: params
        }
    }

    static CreateBranchRetrieveSuccess(params: Branch): BranchRetrieveSuccess {
        return {
            type: ActionTypes.API_BRANCH_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateBranchDelete(params: number): BranchDelete {
        return {
            type: ActionTypes.API_BRANCH_DELETE,
            params: params
        }
    }

    static CreateBranchDeleteSuccess(params: ApiSuccessResponseModel): BranchDeleteSuccess {
        return {
            type: ActionTypes.API_BRANCH_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateBranchHistoryList(params: BranchHistoryListRequest): BranchHistoryList {
        return {
            type: ActionTypes.API_BRANCH_HISTORY_LIST,
            params: params
        }
    }

    static CreateBranchHistoryListSuccess(params: HistoryResponseModel): BranchHistoryListSuccess {
        return {
            type: ActionTypes.API_BRANCH_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearBranchList(force: boolean = false): BranchClearList {
        return {
            type: ActionTypes.API_BRANCH_LIST_CLEAR,
            params: {force: force}
        }   
    }

}