import React from 'react'
import { Card, CardContent, Chip, Avatar } from '@mui/material'
import StorageIcon from '@mui/icons-material/Storage'
import { Doughnut } from 'react-chartjs-2';
import BaseComponent, { BaseState, ViewProps } from 'components/base/BaseComponent';
import { LoadBalancerModel } from "api";
import DonutChartable from 'models/DonutChartable';
import LoadBalancerCardStyles from 'styles/LoadBalancerCardStyles';
import { withStyles } from 'tss-react/mui';
import { withTranslation } from 'react-i18next';

interface LoadBalancerCardProps {
    data: LoadBalancerModel
}

type Props = LoadBalancerCardProps & ViewProps;

interface State extends BaseState {
    labels: string[],
    datasets: DonutChartable[],
    options: {
        [legends: string]: {
            display: boolean
        }
    },
    colorChip: string,
    colorLoad1: string,
    colorLoad5: string,
    colorLoad15: string,
    freeMemory: number,
    usedMemory: number,
    usedMemoryGB: number
}

class LoadBalancerCard extends BaseComponent<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            labels: ["Free", "Used"],
            datasets: [{
                data: [1, 1],
                backgroundColor: ['rgba(27, 27, 27, 0.9)', 'rgba(245, 177, 5, 0.9)'],
            }],
            options: {
                legends: {
                    display: false
                }
            },
            colorChip: 'white',
            colorLoad1: 'black',
            colorLoad5: 'black',
            colorLoad15: 'black',
            freeMemory: 0,
            usedMemory: 0,
            usedMemoryGB: 0,
        }
    }
    
    componentDidMount() {
        super.componentDidMount();
        // calculate used memory (the whole chart = total memory)
        let used = this.props.data.mem_total - this.props.data.mem_free
        this.setState({ freeMemory: this.props.data.mem_free})
        this.setState({ usedMemory: used})

        this.updateChartData()
        
        this.setChipColor()

        this.setLoadsColor()

        this.setChartColor()

        let usedGB =  Math.round((used / 1048576) * 10) / 10
        this.setState({usedMemoryGB: usedGB})
    }

    updateChartData(){
        this.setState({
            datasets: [{
                data: [this.props.data.mem_free, (this.props.data.mem_total - this.props.data.mem_free)]
            }]
        })
    }

    setChipColor() {
        const chipColor = {
            'MASTER': { colorChip: 'green' },
            'BACKUP': { colorChip: 'orange' },
            'FAULT':  { colorChip: 'red' }
        }
        this.setState(chipColor[this.props.data.lb_state])
    }

    setLoadsColor(){
        const loadColor = (load) => load < 1 ? 'green' : load > 1 && load < 2 ? 'orange' : 'red'
        this.setState({
            colorLoad1: loadColor(this.props.data.load_1),
            colorLoad5: loadColor(this.props.data.load_5),
            colorLoad15: loadColor(this.props.data.load_15)
        })
    }

    setChartColor(){
        let used = this.props.data.mem_total - this.props.data.mem_free
        let memoryPercentage = (100 * used) / this.props.data.mem_total
        var datasets = {...this.state.datasets}
        const memoryColor = (mem) => mem <= 60 ? 'green' : mem > 60 && mem < 85 ? 'orange' : 'red'
        datasets[0].backgroundColor = ['rgba(27, 27, 27, 0.9)', memoryColor(memoryPercentage)]
    }

    renderView() {
        const { classes } = this.props;
        return (
            <Card variant="outlined" className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.information}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3>{this.props.data.hostname}</h3>
                            <Chip label={this.props.data.lb_state} className={classes.chip} style={{ backgroundColor: this.state.colorChip}} />
                        </div>
                        <div style={{fontSize: 20}}>
                            IP: {this.props.data.ip_address}
                        </div>
                        <div style={{color: this.state.colorLoad1, fontSize: 15, marginTop: 20}}>
                            Load 1: {this.props.data.load_1}
                        </div>
                        <div style={{color: this.state.colorLoad5, fontSize: 15}}>
                            Load 5: {this.props.data.load_5}
                        </div>
                        <div style={{color: this.state.colorLoad15, fontSize: 15}}>
                            Load 15: {this.props.data.load_15}
                        </div>
                    </div>
                    <Chip 
                        avatar={<Avatar style={{ backgroundColor: "white", color: "#313131" }}><StorageIcon /></Avatar>}
                        className={classes.memoryLabel} 
                        label={`${this.state.usedMemoryGB}GB / 2GB`}
                        style={{ backgroundColor: "#333333", color: 'white' }}
                    />
                    <Doughnut 
                        data={{ datasets: this.state.datasets, labels: this.state.labels}} 
                        // options={{ legend: this.state.options.legends }} 
                    />
                </CardContent>
            </Card>
        )
    }
}

export default withTranslation()(withStyles(LoadBalancerCard, LoadBalancerCardStyles))