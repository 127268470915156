import React, { useState } from 'react';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import Instance from 'api/override/InstanceModel';
import ConnectorSingleSelect from 'components/form_controls/ConnectorSingleSelect';
import ConnectorController from 'controllers/ConnectorController';
import { retrieveConnectorContainerSchema } from 'schemas/ConnectorContainer';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import ImageVersionController from 'controllers/ImageVersionController';
import { FormikProps } from 'formik';
import ConnectorContainer from 'api/override/ConnectorContainerModel';

interface ConnectorContainerCreateDialogProps extends BaseFormModalProps<ConnectorContainer> {
    instance?: Instance
}

const ConnectorContainerCreateDialog = (props: ConnectorContainerCreateDialogProps) => {

    const { t } = useTranslation()
    const { classes } = useDialogStyles()
    const [connectorController] = useState<ConnectorController>(new ConnectorController());
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const getChosenConnectorImageId = (formik: FormikProps<ConnectorContainer>): number | undefined => {
        if(formik.values.connector_id != null) {
            return +connectorController.getCachedItemByValue(formik.values.connector_id)?.['image_id']
        }
        return undefined
    }
    return (
        <SmallFormModal {...props}  
            title={t('global.action.create_model', {model: t('connector_container.model')})}
            initialValues={new ConnectorContainer({
                instance_uuid: props.instance?.uuid,
                connector_id: 0,
                image_version_id: 0,
                image_version: null
            })}
            action={ActionTypes.API_CONNECTOR_CONTAINER_CREATE}
            validationSchema={retrieveConnectorContainerSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    
                    <ConnectorSingleSelect 
                        id="connector_id"
                        name='connector_id'
                        controller={connectorController}
                        formik={formik}
                    />

                    <ImageVersionSingleSelect
                        id='image_version'
                        name='image_version_id'
                        controller={imageVersionController}
                        formik={formik}
                        imageId={getChosenConnectorImageId(formik)}
                        disabled={formik.values.connector_id == null}
                        label={t('image_versions.model')}
                    />
                </div>
            }
        />
    )
}

export default ConnectorContainerCreateDialog