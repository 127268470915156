/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    PackageCreateSuccessModelAllOf,
    PackageCreateSuccessModelAllOfFromJSON,
    PackageCreateSuccessModelAllOfFromJSONTyped,
    PackageCreateSuccessModelAllOfToJSON,
    PackageModel,
    PackageModelFromJSON,
    PackageModelFromJSONTyped,
    PackageModelToJSON,
} from './';


export interface PackageCreateSuccessModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: PackageModel;
}

/**
 * 
 * @export
 * @interface PackageCreateSuccessModel
 */
export class PackageCreateSuccessModel extends BaseModel<PackageCreateSuccessModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof PackageCreateSuccessModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof PackageCreateSuccessModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof PackageCreateSuccessModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof PackageCreateSuccessModel
     */
    success?: boolean;
    /**
     * 
     * @type {PackageModel}
     * @memberof PackageCreateSuccessModel
     */
    model?: PackageModel;

    constructor(data?: PackageCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: PackageCreateSuccessModel, value: StringDateInterface<PackageCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function PackageCreateSuccessModelFromJSON(json: any): PackageCreateSuccessModel {
    return PackageCreateSuccessModelFromJSONTyped(json, false);
}

export function PackageCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PackageCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./PackageCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : PackageModelFromJSON(json['model']),
    });
}

export function PackageCreateSuccessModelToJSON(value?: PackageCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PackageCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./PackageCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': PackageModelToJSON(value.model),
    } as StringDateInterface<PackageCreateSuccessModel>;
    return createClass.toJson(value, model);
}


