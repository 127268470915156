import i18next from 'i18next';
import * as Yup from 'yup';

export const retrieveInstanceAdminPasswordSchema = () =>
    Yup.object().shape({
        newPassword: Yup.string()
            .required(i18next.t('global.validation_schema.required'))
            .test('match', i18next.t('global.validation_schema.should_match'), function (value) {
                const { confirmPassword } = this.parent;
                return value === confirmPassword;
            }),
        confirmPassword: Yup.string()
            .required(i18next.t('global.validation_schema.required'))
            .test('match', i18next.t('global.validation_schema.should_match'), function (value) {
                const { newPassword } = this.parent;
                return value === newPassword;
            }),
    });

