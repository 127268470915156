import { ApiSuccessResponseModel, DashboardWidgetResponseModel, RetrieveDataRequest } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface WidgetGetAvailable extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_GET_AVAILABLE;
}

export interface WidgetGetAvailableSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_WIDGET_GET_AVAILABLE_SUCCESS;
}

export interface WidgetGetData extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_GET_DATA;
    params: RetrieveDataRequest;
}

export interface WidgetGetDataSuccess extends BaseSagaListResponse<DashboardWidgetResponseModel> {
    type: ActionTypes.API_WIDGET_GET_DATA_SUCCESS;
}

export default class DashboardActions {

    static CreateWidgetGetAvailable(): WidgetGetAvailable {
        return {
            type: ActionTypes.API_WIDGET_GET_AVAILABLE
        }
    }

    static CreateWidgetGetAvailableSuccess(params: ApiSuccessResponseModel): WidgetGetAvailableSuccess {
        return {
            type: ActionTypes.API_WIDGET_GET_AVAILABLE_SUCCESS,
            params: params
        }
    }

    static CreateWidgetGetData(params: RetrieveDataRequest): WidgetGetData {
        return {
            type: ActionTypes.API_WIDGET_GET_DATA,
            params: params
        }
    }

    static CreateWidgetGetDataSuccess(params: DashboardWidgetResponseModel): WidgetGetDataSuccess {
        return {
            type: ActionTypes.API_WIDGET_GET_DATA_SUCCESS,
            params: params
        }
    }
}