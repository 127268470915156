import MultiSelectOption from "interface/MultiSelectOption";
import FilterColumn from "models/table/FilterColumn";
import { TableParams } from "models/table/TableParams";

export default class BaseController<T> {
    cachedItems: { [key: string]: MultiSelectOption[] } = {};
    lastParams: TableParams = null;
    cachedItemsOfType: {[key: string]: Array<T>} = {}

    getKey = (params?: TableParams) => {
        return [params?.limit, params?.offset, params?.search_text, params?.filters?.map((filter: FilterColumn) => filter.key + filter.inputValue)].join('_');
    }

    cacheResponse = (responseItems: MultiSelectOption[], params?: TableParams) => {
        this.cachedItems[this.getKey(params)] = responseItems;
    }

    cacheResponseOfType = (responseItems: Array<T>, params?: TableParams) => {
        this.cachedItemsOfType[this.getKey(params)] = responseItems;
    }

    cacheResponseOnProp = (responseItems: MultiSelectOption[], prop: any) => {
        this.cachedItems[prop?.toString()] = responseItems;
    }

    cacheResponseOnParamsAndProp = (responseItems: MultiSelectOption[], params?: TableParams, prop?: any) => {
        this.cachedItems[this.getKey(params) +'_'+ prop?.toString()] = responseItems;
    }

    hasItemsForRequest = (params?: TableParams): boolean => {
        return this.cachedItems[this.getKey(params)] != null;
    }

    getItemsForRequest = (params?: TableParams): MultiSelectOption[] => {
        return this.cachedItems[this.getKey(params)];
    }

    getItemsOfTypeForRequest = (params?: TableParams): Array<T> => {
        return this.cachedItemsOfType[this.getKey(params)];
    }

    getItemsForRequestOnProp = (prop: any): MultiSelectOption[] => {
        return this.cachedItems[prop];
    }

    
    getItemsForRequestAndProps = (params?: TableParams, prop?: any): MultiSelectOption[] => {
        return this.cachedItems[this.getKey(params) +'_'+ prop?.toString()];
    }

    hasItemsForRequestOnProp = (prop: any): boolean => {
        return this.cachedItems[prop] != null;
    }

    getCachedItemByValue = (val: string | number): MultiSelectOption | undefined => {
        let result = undefined
        Object.values(this.cachedItems).forEach((values: MultiSelectOption[]) => {
            const item = values.find(selectOption => selectOption.value.toString() === val.toString())
            if(item != null) {
                result = item
                return;
            }
        })
        return result
    }
}