import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import {keyframes} from "tss-react";

const wiggle = keyframes`0% { transform: rotate(.5deg); } 50% { transform: rotate(-0.5deg); } 100% { transform: rotate(.5deg); }`

const useWidgetStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            minHeight: 150,
            minWidth: 350,
            borderRadius: 10,
            border: theme.palette.mode === 'dark' ? '2px solid #2c2c2c' : '2px solid #f0f0f0',
            background: theme.palette.background.paper,
            margin: '20px 40px 20px 0',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 10,
            flexGrow: 1,
            '&.edit_mode': {
                animation: `${wiggle} .2s .5s ease-in-out`
            },
            '&.small': {
                maxWidth: 'calc(33% - 40px)',
            },
            '&.medium': {
                maxWidth: 'calc(67% - 60px)',
            },
            '&.large': {
                maxWidth: 'calc(100% - 40px)',
            },
        },
        title_container: {
            height: 50,
            padding: 20,
            marginBottom: 25,
            display: 'flex',
            justifyContent: 'space-between'
        },
        edit_toggle_container: {
            position: 'absolute',
            right: 30,
            marginTop: 20
        },        
        edit_toggle: {
            display: 'flex', 
            flexDirection: 'column', 
            color: theme.palette.mode === 'dark' ? '#fff' : '#313131', 
            fontSize: '8px', 
            fontStyle: 'normal', 
            textAlign: 'center'
        },
        divider: {
            height: 2, 
            background: theme.palette.mode === 'dark' ? '#2c2c2c' : '#f0f0f0',
            borderStyle: 'none'
        },
        row: {
            display: 'grid', 
            gridAutoFlow: 'column',
            height: 40,
            alignItems: 'center',
            position: 'relative',
            padding: '0px 30px',
            '& > *': {
                justifySelf: 'end',
            },
            '& p:first-of-type': {
                justifySelf: 'start'
            },
            '& h4:first-of-type': {
                justifySelf: 'start'
            },
            '&.no': {
                color: '#9f9f9f',
            },
            '& a': {
                color: theme.palette.primary.main
            }
        },
        status_indicator: {
            height: 5, 
            width: 5, 
            borderRadius: 10,
            position: 'absolute', 
            top: 18, 
            left: 15,
            '&.active': {
                background: '#0d0'
            },
            '&.inactive': {
                background: '#ff7b7b'
            }
        },
        template_container: {
            display: 'flex',
            flexDirection: 'row',
            padding: '100px 40px',
            flexWrap: 'wrap'
        },
        template_header: {
            width: 200,
            height: '12px  !important',
            borderRadius: '10px  !important',
            background: theme.palette.mode === 'dark' ? '#121212 !important' : '#cecece !important',
            position: 'relative',
            top: 20,
            left: 20,
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2c !important' : '#bfbfbf !important',
            }
        },
        template_row: {
            minWidth: 200,
            height: '12px  !important',
            borderRadius: '10px  !important',
            background: theme.palette.mode === 'dark' ? '#121212 !important' : '#cecece !important',
            margin: 20,
            bottom: -55,
            position: 'relative',
            '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2c !important' : '#bfbfbf !important',
            }
        },
        template_widget: {
            minHeight: 230,
            minWidth: 400,
            borderRadius: 10,
            background: theme.palette.background.paper,
            flexGrow: 1,
            margin: 20,
            display: 'flex',
            flexDirection: 'column'
        },
        paragraph_with_icon: {
            display: 'flex',
            justifyContent: 'center',
            '& svg': {
                marginRight: 10
            }
        },
        widget_edit_button: {
            height: 30, 
            width: 30, 
            position: 'relative', 
            top: -15, 
            left: 15,
            '&.remove': {
                color: '#DC4141'
            },
            '&.add': {
                color: '#2F94FF;'
            },
        }
    }
})

export default useWidgetStyles