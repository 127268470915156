export default interface MultiSelectOption {
    value: number | string;
    label: string;
}

export function isMultiSelect(val: any): boolean {
    return 'label' in val && 'value' in val;
}


export function isEmptyMultiSelect(val: any): boolean {
    if (val == null) {
        return true;
    }
    return isMultiSelect(val) && !Boolean(val.label) && !Boolean(val.value);
}