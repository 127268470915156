/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    UserModel,
    UserModelFromJSON,
    UserModelFromJSONTyped,
    UserModelToJSON,
} from './';


export interface RequestLogModelInterface {
    token?: string;
    message?: string;
    user_id?: number | null;
    user?: UserModel;
    _function?: string;
    namespace?: string;
    external_id?: number | null;
    external_uuid?: string | null;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface RequestLogModel
 */
export class RequestLogModel extends BaseModel<RequestLogModelInterface> {

    /**
     * Unique identifier that can be used in graylog
     * @type {string}
     * @memberof RequestLogModel
     */
    token?: string;
    /**
     * Message describing the URL used for the request
     * @type {string}
     * @memberof RequestLogModel
     */
    message?: string;
    /**
     * User ID of the person performing this action
     * @type {number}
     * @memberof RequestLogModel
     */
    user_id?: number | null;
    /**
     * 
     * @type {UserModel}
     * @memberof RequestLogModel
     */
    user?: UserModel;
    /**
     * Function that was used in PHP
     * @type {string}
     * @memberof RequestLogModel
     */
    _function?: string;
    /**
     * Namespace for which logs are being requested
     * @type {string}
     * @memberof RequestLogModel
     */
    namespace?: string;
    /**
     * Unique identifier for this history log's model
     * @type {number}
     * @memberof RequestLogModel
     */
    external_id?: number | null;
    /**
     * Unique identifier for this history log's model
     * @type {string}
     * @memberof RequestLogModel
     */
    external_uuid?: string | null;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof RequestLogModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof RequestLogModel
     */
    updated_at?: Date;

    constructor(data?: RequestLogModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: RequestLogModel, value: StringDateInterface<RequestLogModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function RequestLogModelFromJSON(json: any): RequestLogModel {
    return RequestLogModelFromJSONTyped(json, false);
}

export function RequestLogModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestLogModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RequestLogModel;
    try {
        let localClass = require.context('../override', true)('./RequestLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'user_id': !exists(json, 'user_id') ? undefined : Number(json['user_id']),
        'user': !exists(json, 'user') ? undefined : UserModelFromJSON(json['user']),
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'external_id': !exists(json, 'external_id') ? undefined : Number(json['external_id']),
        'external_uuid': !exists(json, 'external_uuid') ? undefined : json['external_uuid'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function RequestLogModelToJSON(value?: RequestLogModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RequestLogModel;
    try {
        let localClass = require.context('../override', true)('./RequestLogModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'token': value.token,
        'message': value.message,
        'user_id': value.user_id,
        'user': UserModelToJSON(value.user),
        'function': value._function,
        'namespace': value.namespace,
        'external_id': value.external_id,
        'external_uuid': value.external_uuid,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<RequestLogModel>;
    return createClass.toJson(value, model);
}


