/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageModel,
    ImageModelFromJSON,
    ImageModelFromJSONTyped,
    ImageModelToJSON,
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelFromJSONTyped,
    ImageVersionModelToJSON,
    InstanceCreateModelAllOf,
    InstanceCreateModelAllOfFromJSON,
    InstanceCreateModelAllOfFromJSONTyped,
    InstanceCreateModelAllOfToJSON,
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelFromJSONTyped,
    InstanceModelToJSON,
    PackageModel,
    PackageModelFromJSON,
    PackageModelFromJSONTyped,
    PackageModelToJSON,
} from './';


export interface InstanceCreateModelInterface {
    uuid?: string;
    core_image_id?: number | null;
    core_image_version_id?: number | null;
    ui_image_id?: number | null;
    ui_image_version_id?: number | null;
    core_image?: ImageModel;
    ui_image?: ImageModel;
    ui_image_version?: ImageVersionModel;
    core_image_version?: ImageVersionModel;
    tools_image_version_id?: number | null;
    tools_image_version?: ImageVersionModel;
    package_id?: number | null;
    _package?: PackageModel;
    status?: string;
    name?: string;
    domain?: string;
    properties?: object;
    has_vps?: boolean;
    has_tools?: boolean;
    has_trials?: boolean;
    database_password?: string;
    admin_email?: string;
    admin_password?: string;
    system_key?: string | null;
    api_key?: string | null;
    active?: boolean;
    deletion_reason?: string | null;
    migrate?: boolean;
    migrated?: boolean;
    launch_on_stage?: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
    skip_existing_check?: boolean;
}

/**
 * 
 * @export
 * @interface InstanceCreateModel
 */
export class InstanceCreateModel extends BaseModel<InstanceCreateModelInterface> {

    /**
     * Unique per Instance
     * @type {string}
     * @memberof InstanceCreateModel
     */
    uuid?: string;
    /**
     * id of the core image
     * @type {number}
     * @memberof InstanceCreateModel
     */
    core_image_id?: number | null;
    /**
     * id of the core image version
     * @type {number}
     * @memberof InstanceCreateModel
     */
    core_image_version_id?: number | null;
    /**
     * id of the ui image
     * @type {number}
     * @memberof InstanceCreateModel
     */
    ui_image_id?: number | null;
    /**
     * id of the ui image version
     * @type {number}
     * @memberof InstanceCreateModel
     */
    ui_image_version_id?: number | null;
    /**
     * 
     * @type {ImageModel}
     * @memberof InstanceCreateModel
     */
    core_image?: ImageModel;
    /**
     * 
     * @type {ImageModel}
     * @memberof InstanceCreateModel
     */
    ui_image?: ImageModel;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof InstanceCreateModel
     */
    ui_image_version?: ImageVersionModel;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof InstanceCreateModel
     */
    core_image_version?: ImageVersionModel;
    /**
     * id of the tools image version
     * @type {number}
     * @memberof InstanceCreateModel
     */
    tools_image_version_id?: number | null;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof InstanceCreateModel
     */
    tools_image_version?: ImageVersionModel;
    /**
     * id of the Bumbal package
     * @type {number}
     * @memberof InstanceCreateModel
     */
    package_id?: number | null;
    /**
     * 
     * @type {PackageModel}
     * @memberof InstanceCreateModel
     */
    _package?: PackageModel;
    /**
     * Last known status of the instance
     * @type {string}
     * @memberof InstanceCreateModel
     */
    status?: string;
    /**
     * Full name of this instance
     * @type {string}
     * @memberof InstanceCreateModel
     */
    name?: string;
    /**
     * DNS domain name of this instance
     * @type {string}
     * @memberof InstanceCreateModel
     */
    domain?: string;
    /**
     * 
     * @type {object}
     * @memberof InstanceCreateModel
     */
    properties?: object;
    /**
     * Instance already had a VPS
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    has_vps?: boolean;
    /**
     * Instance has tools launched
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    has_tools?: boolean;
    /**
     * Instance has trials
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    has_trials?: boolean;
    /**
     * database password
     * @type {string}
     * @memberof InstanceCreateModel
     */
    database_password?: string;
    /**
     * admin email
     * @type {string}
     * @memberof InstanceCreateModel
     */
    admin_email?: string;
    /**
     * admin password
     * @type {string}
     * @memberof InstanceCreateModel
     */
    admin_password?: string;
    /**
     * System Key
     * @type {string}
     * @memberof InstanceCreateModel
     */
    system_key?: string | null;
    /**
     * Api Key
     * @type {string}
     * @memberof InstanceCreateModel
     */
    api_key?: string | null;
    /**
     * Active
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    active?: boolean;
    /**
     * Reason for deletion of the instance
     * @type {string}
     * @memberof InstanceCreateModel
     */
    deletion_reason?: string | null;
    /**
     * Instance marked for migration
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    migrate?: boolean;
    /**
     * Instance has been moved
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    migrated?: boolean;
    /**
     * Launch instance on the stage pool
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    launch_on_stage?: boolean;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof InstanceCreateModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof InstanceCreateModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof InstanceCreateModel
     */
    deleted_at?: Date;
    /**
     * Skip checking for an existing instance with the same domain
     * @type {boolean}
     * @memberof InstanceCreateModel
     */
    skip_existing_check?: boolean;

    constructor(data?: InstanceCreateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceCreateModel, value: StringDateInterface<InstanceCreateModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceCreateModelFromJSON(json: any): InstanceCreateModel {
    return InstanceCreateModelFromJSONTyped(json, false);
}

export function InstanceCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceCreateModel;
    try {
        let localClass = require.context('../override', true)('./InstanceCreateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'core_image_id': !exists(json, 'core_image_id') ? undefined : Number(json['core_image_id']),
        'core_image_version_id': !exists(json, 'core_image_version_id') ? undefined : Number(json['core_image_version_id']),
        'ui_image_id': !exists(json, 'ui_image_id') ? undefined : Number(json['ui_image_id']),
        'ui_image_version_id': !exists(json, 'ui_image_version_id') ? undefined : Number(json['ui_image_version_id']),
        'core_image': !exists(json, 'core_image') ? undefined : ImageModelFromJSON(json['core_image']),
        'ui_image': !exists(json, 'ui_image') ? undefined : ImageModelFromJSON(json['ui_image']),
        'ui_image_version': !exists(json, 'ui_image_version') ? undefined : ImageVersionModelFromJSON(json['ui_image_version']),
        'core_image_version': !exists(json, 'core_image_version') ? undefined : ImageVersionModelFromJSON(json['core_image_version']),
        'tools_image_version_id': !exists(json, 'tools_image_version_id') ? undefined : Number(json['tools_image_version_id']),
        'tools_image_version': !exists(json, 'tools_image_version') ? undefined : ImageVersionModelFromJSON(json['tools_image_version']),
        'package_id': !exists(json, 'package_id') ? undefined : Number(json['package_id']),
        '_package': !exists(json, 'package') ? undefined : PackageModelFromJSON(json['package']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'has_vps': !exists(json, 'has_vps') ? undefined : json['has_vps'],
        'has_tools': !exists(json, 'has_tools') ? undefined : json['has_tools'],
        'has_trials': !exists(json, 'has_trials') ? undefined : json['has_trials'],
        'database_password': !exists(json, 'database_password') ? undefined : json['database_password'],
        'admin_email': !exists(json, 'admin_email') ? undefined : json['admin_email'],
        'admin_password': !exists(json, 'admin_password') ? undefined : json['admin_password'],
        'system_key': !exists(json, 'system_key') ? undefined : json['system_key'],
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'deletion_reason': !exists(json, 'deletion_reason') ? undefined : json['deletion_reason'],
        'migrate': !exists(json, 'migrate') ? undefined : json['migrate'],
        'migrated': !exists(json, 'migrated') ? undefined : json['migrated'],
        'launch_on_stage': !exists(json, 'launch_on_stage') ? undefined : json['launch_on_stage'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
        'skip_existing_check': !exists(json, 'skip_existing_check') ? undefined : json['skip_existing_check'],
    });
}

export function InstanceCreateModelToJSON(value?: InstanceCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceCreateModel;
    try {
        let localClass = require.context('../override', true)('./InstanceCreateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'core_image_id': value.core_image_id,
        'core_image_version_id': value.core_image_version_id,
        'ui_image_id': value.ui_image_id,
        'ui_image_version_id': value.ui_image_version_id,
        'core_image': ImageModelToJSON(value.core_image),
        'ui_image': ImageModelToJSON(value.ui_image),
        'ui_image_version': ImageVersionModelToJSON(value.ui_image_version),
        'core_image_version': ImageVersionModelToJSON(value.core_image_version),
        'tools_image_version_id': value.tools_image_version_id,
        'tools_image_version': ImageVersionModelToJSON(value.tools_image_version),
        'package_id': value.package_id,
        'package': PackageModelToJSON(value._package),
        'status': value.status,
        'name': value.name,
        'domain': value.domain,
        'properties': value.properties,
        'has_vps': value.has_vps,
        'has_tools': value.has_tools,
        'has_trials': value.has_trials,
        'database_password': value.database_password,
        'admin_email': value.admin_email,
        'admin_password': value.admin_password,
        'system_key': value.system_key,
        'api_key': value.api_key,
        'active': value.active,
        'deletion_reason': value.deletion_reason,
        'migrate': value.migrate,
        'migrated': value.migrated,
        'launch_on_stage': value.launch_on_stage,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
        'skip_existing_check': value.skip_existing_check,
    } as StringDateInterface<InstanceCreateModel>;
    return createClass.toJson(value, model);
}


