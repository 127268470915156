import {InstanceModel, InstanceModelInterface, InstanceCreateModelAllOfInterface, StringDateInterface} from "api";
import Historiable from "interface/Historiable";
import MultiSelectOption from "interface/MultiSelectOption";
import TableCellable from "interface/TableCellable";
import Package from "./PackageModel";
import BuildableModel from "interface/BuildableModel";
import Statuses from "interface/Statuses";

interface InstancePropsExtended extends InstanceModelInterface {
    isLaunching?: boolean;
}

export default class Instance extends InstanceModel implements TableCellable, Historiable, MultiSelectOption, InstanceModelInterface, InstanceCreateModelAllOfInterface, BuildableModel {

    shouldFadeRow: boolean;
    skipMigration: boolean = false;
    actionName: string="instance";
    reducerName: string="instances";
    launch_on_stage = false;
    isLaunching = false;

    value: string | number;
    label: string;
    
    skip_existing_check = false;
    status: string = Statuses.STATUS_BUILD_FAILED.toString();

    _package?: Package = undefined;

    constructor(data?: InstancePropsExtended) {
        super(data);
        this.shouldFadeRow = this.deleted_at != null;
        this.value = this.uuid ?? '';
        this.label = this.name ?? '';
        this.isLaunching = data?.isLaunching === true;
        this._package = data?._package as Package;
        this.status = data?.status ?? Statuses.STATUS_BUILD_FAILED.toString();
    }

    static generatePassword = (length: number, secure: boolean) => {
        let password = "",
            chars = "abcdefghijklmnopqrstuvwxyz1234567890",
            secureChars = "!@#$%^&*()";

        if(secure){
            chars += secureChars;
        }

        for(let i = 0; i < length; i++) {
            password += chars[Math.floor(Math.random() * chars.length)];
        }

        return password;
    };    

    isDeleted(): boolean {
        return Boolean(this.deleted_at)
    }

    getPrettyUrlNew(): string {
        return `${this.domain}.bumbal.eu`
    }

    getPrettyUrlOld(): string {
        return `${this.domain}.freightlive.eu`
    }

    getFullUrl(): string {
        return `https://${this.getPrettyUrlNew()}`;
    }

    getFreightliveUrl(): string {
        return `https://${this.getPrettyUrlOld()}`;
    }

    getGraylogUrl(): string {
        return `https://logging.bumbal.eu/search?q=instance%3A${this.domain}*`;
    }

    getApiUrl(): string {
        return this.migrate === true || this.has_vps === true
            ? this.getFreightliveUrl() +'/api'
            : this.getFullUrl() +'/api'
    }

    hasCore(): boolean {
        return !this.has_vps || this.migrate;
    }

    static toJson(initValue: Instance, value: StringDateInterface<Instance>): StringDateInterface<InstanceModelInterface> {
        return { 
            ...value,
            package_id: initValue['package']?.id
        };
    }

    getPasswordlessUrl(isOldRequested: boolean, token: string): string {
        if(isOldRequested) {
            return `https://${this.domain}.freightlive.eu?${token}`;
        } else {
            return `https://${this.domain}.bumbal.eu?${token}`;
        }
    }

}