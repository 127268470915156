import i18next from 'i18next';
import ImageVersionable from 'interface/ImageVersionable';
import TableColumn from 'models/table/TableColumn';

export default class ImageVersionColumn extends TableColumn {

    constructor() {
        super({ 
            key: 'image_version',
            label: i18next.t('image_versions.model'), 
            customValue: (row: ImageVersionable) => row.getImageVersionString() 
        });
    }

}