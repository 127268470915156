/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ImageModelInterface {
    id?: number;
    name?: string | null;
    description?: string | null;
    docker_image?: string | null;
    for_model?: ImageModelForModelEnum;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface ImageModel
 */
export class ImageModel extends BaseModel<ImageModelInterface> {

    /**
     * id of the Image
     * @type {number}
     * @memberof ImageModel
     */
    id?: number;
    /**
     * Name for the image that should be easily understandable
     * @type {string}
     * @memberof ImageModel
     */
    name?: string | null;
    /**
     * Description of the image
     * @type {string}
     * @memberof ImageModel
     */
    description?: string | null;
    /**
     * Docker image
     * @type {string}
     * @memberof ImageModel
     */
    docker_image?: string | null;
    /**
     * Instance, Connector, ConnectorContainer, ServiceContainer, Processor
     * @type {string}
     * @memberof ImageModel
     */
    for_model?: ImageModelForModelEnum;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ImageModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ImageModel
     */
    updated_at?: Date;

    constructor(data?: ImageModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageModel, value: StringDateInterface<ImageModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageModelFromJSON(json: any): ImageModel {
    return ImageModelFromJSONTyped(json, false);
}

export function ImageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageModel;
    try {
        let localClass = require.context('../override', true)('./ImageModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'docker_image': !exists(json, 'docker_image') ? undefined : json['docker_image'],
        'for_model': !exists(json, 'for_model') ? undefined : json['for_model'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function ImageModelToJSON(value?: ImageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageModel;
    try {
        let localClass = require.context('../override', true)('./ImageModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'docker_image': value.docker_image,
        'for_model': value.for_model,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<ImageModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum ImageModelForModelEnum {
    Instance = 'Instance',
    Connector = 'Connector',
    ConnectorContainer = 'ConnectorContainer',
    ServiceContainer = 'ServiceContainer',
    Processor = 'Processor'
}


