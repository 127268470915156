/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceFilterModelInterface {
    image?: string;
    standalone?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceFilterModel
 */
export class ServiceFilterModel extends BaseModel<ServiceFilterModelInterface> {

    /**
     * Docker image
     * @type {string}
     * @memberof ServiceFilterModel
     */
    image?: string;
    /**
     * Standalone service
     * @type {boolean}
     * @memberof ServiceFilterModel
     */
    standalone?: boolean;

    constructor(data?: ServiceFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceFilterModel, value: StringDateInterface<ServiceFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceFilterModelFromJSON(json: any): ServiceFilterModel {
    return ServiceFilterModelFromJSONTyped(json, false);
}

export function ServiceFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceFilterModel;
    try {
        let localClass = require.context('../override', true)('./ServiceFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'image': !exists(json, 'image') ? undefined : json['image'],
        'standalone': !exists(json, 'standalone') ? undefined : json['standalone'],
    });
}

export function ServiceFilterModelToJSON(value?: ServiceFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceFilterModel;
    try {
        let localClass = require.context('../override', true)('./ServiceFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'image': value.image,
        'standalone': value.standalone,
    } as StringDateInterface<ServiceFilterModel>;
    return createClass.toJson(value, model);
}


