import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import SingleSelect from 'components/form_controls/SingleSelect';
import MultiSelectOption from 'interface/MultiSelectOption';
import Setting from 'api/override/SettingModel';
import EnvUtil from 'utils/EnvUtil';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveSettingSchema } from 'schemas/Setting';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface SettingCreateFormProps {
    onFormSubmit?: (value?: Setting) => void;
}

const SettingCreateForm = (props: SettingCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const typeOptions: MultiSelectOption[] = Setting.getSettingTypes();

    return (
        <Formik
            initialValues={new Setting({
                key: '',
                value: '',
                type: null,
                group: '',
                encrypted: false,
            })}
            validationSchema={retrieveSettingSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Setting>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('settings.model')})}</h2>
                    
                    <TextFieldStyled 
                        id="key" 
                        name="key"
                        label={t('settings.key')}
                        helperText={formik.touched.key ? formik.errors.key : ""}
                        error={formik.touched.key && Boolean(formik.errors.key)}
                        value={formik.values.key}
                        onChange={formik.handleChange}
                    />

                   <TextFieldStyled 
                        id="value" 
                        name="value"
                        label={t('settings.value')}
                        helperText={formik.touched.value ? formik.errors.value : ""}
                        error={formik.touched.value && Boolean(formik.errors.value)}
                        multiline
                        value={formik.values.value}
                        onChange={formik.handleChange}
                    />

                    { !EnvUtil.isProduction() && <TextFieldStyled 
                        id="stage_value" 
                        name="stage_value"
                        label={t('settings.stage_value')}
                        multiline
                        value={formik.values.stage_value}
                        helperText={formik.touched.stage_value ? formik.errors.stage_value : ""}
                        error={formik.touched.stage_value && Boolean(formik.errors.stage_value)}
                        onChange={formik.handleChange}
                    /> }

                    <SingleSelect 
                        id="type" 
                        name="type"
                        label={t('general.type')}
                        options={typeOptions}
                        value={typeOptions.find(type => type.value === formik.values?.type)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.type && Boolean(formik.errors.type)}
                        formikHelperText={formik.touched.type ? formik.errors.type : ""}
                    />

                    <TextFieldStyled 
                        id="group" 
                        name="group"
                        label={t('settings.group')}
                        helperText={formik.touched.group ? formik.errors.group : ""}
                        error={formik.touched.group && Boolean(formik.errors.group)}
                        value={formik.values.group}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('settings.description')}
                        helperText={formik.touched.description ? formik.errors.description : ""}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.encrypted,
                            onChange: formik.handleChange,
                            id: "encrypted",
                            name: "encrypted",
                        }}
                        label={t('settings.encrypted')}
                    />

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('settings.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default SettingCreateForm;