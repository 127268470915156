import { RoleListResultModel, RoleRetrieveListModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface RoleList extends BaseReducerAction {
    type: ActionTypes.API_ROLE_LIST;
    params: RoleRetrieveListModel;
}

export interface RoleListSuccess extends BaseSagaListResponse<RoleListResultModel> {
    type: ActionTypes.API_ROLE_LIST_SUCCESS;
}

export default class RoleActions {

    static CreateRoleList(params: RoleRetrieveListModel): RoleList {
        return {
            type: ActionTypes.API_ROLE_LIST,
            params: params
        }
    }

    static CreateRoleListSuccess(params: RoleListResultModel): RoleListSuccess {
        return {
            type: ActionTypes.API_ROLE_LIST_SUCCESS,
            params: params
        }
    }
}