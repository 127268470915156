import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Service from 'api/override/ServiceModel';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import ImageController from 'controllers/ImageController';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { ImageModelForModelEnum } from 'api';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveServiceSchema } from 'schemas/Service';
import React from 'react'
import BBCheckbox from 'components/form_controls/BBCheckbox';
import DatabaseMultiSelect from 'components/form_controls/DatabaseMultiSelect';
import ServicePathForm from 'views/Services/components/ServicePathForm';

interface ServiceCreateFormProps {
    onFormSubmit?: (value?: Service) => void;
}

const ServiceCreateForm = (props: ServiceCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const [imageController] = useState<ImageController>(new ImageController())
    const copyUrlToNamespaceAndSlugify = (formik: FormikProps<Service>): void => {
        const slugifiedString = formik.values.url.bbStringToSlug()
        formik.setFieldValue('url', slugifiedString)
    }

    const handleImageChange = (formik: FormikProps<Service>) => {
        const imageName: string = imageController.getCachedItemByValue(formik.values.image_id ?? 0)?.label ?? '';
        formik.setFieldValue('name', imageName);
        formik.setFieldValue('url', imageName.bbStringToSlug())
    }

    const handleStandaloneToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        formRef.current.setFieldValue('standalone', checked);
        if (checked) {
            formRef.current.setFieldValue('has_port', true);
        }
    }

    return (
        <Formik
            initialValues={new Service({
                name: '',
                url: '',
                image: null,
                text: '',
                standalone: false,
                has_port: false,
            })}
            validationSchema={retrieveServiceSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Service>) =>
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', { model: t('service.model') })}</h2>
                    <ImageSingleSelect
                        id='image'
                        name='image_id'
                        controller={imageController}
                        formik={formik}
                        label={t('connector.image')}
                        model={[ImageModelForModelEnum.ServiceContainer]}
                        additionalActionOnImageChange={handleImageChange}
                    />

                    <TextFieldStyled
                        id="name"
                        name="name"
                        disabled={formik.values.image_id == null}
                        label={t('general.name')}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled
                        id="url"
                        name="url"
                        disabled={formik.values.image_id == null}
                        label={t('connector.url')}
                        helperText={formik.touched.url ? formik.errors.url : ""}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        onBlur={() => copyUrlToNamespaceAndSlugify(formik)}
                    />

                    <DatabaseMultiSelect formik={formik} />

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.standalone,
                            onChange: handleStandaloneToggle,
                            id: "standalone",
                            name: "standalone",
                        }}
                        label={t('service.standalone_form')}
                    />
                
                    <BBCheckbox
                        checkboxProps={{
                            disabled: formik.values.standalone,
                            checked: formik.values.has_port,
                            onChange: formik.handleChange,
                            id: "has_port",
                            name: "has_port",
                        }}
                        label={t('service.has_port_form')}
                    />

                    { formik.values.standalone ? (null) : (
                        <ServicePathForm formik={formik} />
                    )}

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', { model: t('service.model') })}
                    </ButtonStyled>
                </Form>

            }
        </Formik>
    );
};

export default ServiceCreateForm;