import React, { CSSProperties } from 'react';

interface FormErrorTextStyledProps {
    isWarning?: boolean;
    message: string;
}

const FormErrorTextStyled = (props: FormErrorTextStyledProps) => {
    const { isWarning, message } = props
    const styling: CSSProperties = {color: isWarning ? '#ff8300' : '#f33', textAlign: 'center', margin: '10px auto'} 

    return (
        <p style={styling}>{message}</p>
    )
}

export default FormErrorTextStyled
