import React from 'react';
import { useTranslation } from "react-i18next";
import ReadOnlyField from 'components/ReadOnlyField';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import Setting from 'api/override/SettingModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import EnvUtil from 'utils/EnvUtil';
import { retrieveSettingSchema } from 'schemas/Setting';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface SettingDetailsDialogProps extends BaseFormModalProps<Setting> { }

const SettingDetailsDialog = (props: SettingDetailsDialogProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const fetching = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_SETTING_RETRIEVE));
    const setting: Setting = useSelector((state: RootState) => state.settings.selectedObject)
    return (
        !fetching && 
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('settings.model')})}
            initialValues={setting}
            action={ActionTypes.API_SETTING_UPDATE}
            validationSchema={retrieveSettingSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                   <ReadOnlyField  
                        id="key" 
                        name="key"
                        label={t('settings.key')}
                        helperText={formik.touched.key ? formik.errors.key : ""}
                        error={formik.touched.key && Boolean(formik.errors.key)}
                        value={formik.values.key}
                    />

                   <TextFieldStyled  
                        id="value" 
                        name="value"
                        label={t('settings.value')}
                        helperText={formik.touched.value ? formik.errors.value : ""}
                        error={formik.touched.value && Boolean(formik.errors.value)}
                        multiline
                        value={formik.values.value}
                        onChange={formik.handleChange}
                    />

                    { !EnvUtil.isProduction() && <TextFieldStyled  
                        id="stage_value" 
                        name="stage_value"
                        label={t('settings.stage_value')}
                        helperText={formik.touched.stage_value ? formik.errors.stage_value : ""}
                        error={formik.touched.stage_value && Boolean(formik.errors.stage_value)}
                        multiline
                        value={formik.values.stage_value}
                        onChange={formik.handleChange}
                    /> }

                    <ReadOnlyField  
                        id="type" 
                        name="type"
                        label={t('general.type')}
                        helperText={formik.touched.type ? formik.errors.type : ""}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        value={formik.values.type}
                    />

                    <TextFieldStyled  
                        id="group" 
                        name="group"
                        label={t('settings.group')}
                        helperText={formik.touched.group ? formik.errors.group : ""}
                        error={formik.touched.group && Boolean(formik.errors.group)}
                        value={formik.values.group}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled  
                        id="description" 
                        name="description"
                        label={t('settings.description')}
                        helperText={formik.touched.description ? formik.errors.description : ""}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.encrypted,
                            onChange: formik.handleChange,
                            id: "encrypted",
                            name: "encrypted",
                        }}
                        label={t('settings.encrypted')}
                    />
                </div>
            }
        />
    );
};

export default SettingDetailsDialog;