import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import ImageVersionController from 'controllers/ImageVersionController';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import TokenUtil from 'utils/TokenUtil';
import ServiceContainer from 'api/override/ServiceContainerModel';
import StatusSingleSelect from 'components/form_controls/StatusSingleSelect';


interface ServiceContainerDocumentationModalProps extends BaseFormModalProps<ServiceContainer> {
    serviceContainer?: ServiceContainer;
}

const ServiceContainerDocumentationModal = (props: ServiceContainerDocumentationModalProps) => {

    const { serviceContainer } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const isAdmin: boolean = TokenUtil.getTokenUtil().isAccountManager()

    return (
        <BaseFormModal {...props} title={`${t('global.title.details_model', {model: t('service_container.model')})} (${serviceContainer?.service?.name})`}
            initialValues={new ServiceContainer({
                ...serviceContainer,
                text: serviceContainer?.text || '',
                image_version_id: serviceContainer?.image_version_id || null ,
                image_version: serviceContainer?.image_version || null
            })}
            action={ActionTypes.API_SERVICE_CONTAINER_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <StatusSingleSelect formik={formik as any} />
                    {isAdmin ? (
                        <ImageVersionSingleSelect 
                            id='image_version'
                            name='image_version_id'
                            controller={imageVersionController}
                            formik={formik}
                            imageId={+serviceContainer?.service?.image_id}
                            disabled={serviceContainer?.service?.image_id == null}
                            label={t('image_versions.title.single_select')}
                        />
                    ) : (null)}
                </div>
            }
        />
    );
};

export default ServiceContainerDocumentationModal;