import React, { PropsWithChildren } from 'react';
import EnvWrapper from './EnvWrapper';

interface HideOnStageProps {
    hide404?: boolean;
}

const HideOnStage = (props: PropsWithChildren<HideOnStageProps>) => {
    return ( <EnvWrapper hide404={props.hide404} hideOnStage> {props.children} </EnvWrapper> )
}

export default HideOnStage;