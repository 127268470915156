/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface StepviewWidgetRemoveSuccessModelInterface {
    id?: number;
    message?: string;
}

/**
 * 
 * @export
 * @interface StepviewWidgetRemoveSuccessModel
 */
export class StepviewWidgetRemoveSuccessModel extends BaseModel<StepviewWidgetRemoveSuccessModelInterface> {

    /**
     * Unique per stepview widget
     * @type {number}
     * @memberof StepviewWidgetRemoveSuccessModel
     */
    id?: number;
    /**
     * A message stating the success or error
     * @type {string}
     * @memberof StepviewWidgetRemoveSuccessModel
     */
    message?: string;

    constructor(data?: StepviewWidgetRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewWidgetRemoveSuccessModel, value: StringDateInterface<StepviewWidgetRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewWidgetRemoveSuccessModelFromJSON(json: any): StepviewWidgetRemoveSuccessModel {
    return StepviewWidgetRemoveSuccessModelFromJSONTyped(json, false);
}

export function StepviewWidgetRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewWidgetRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewWidgetRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function StepviewWidgetRemoveSuccessModelToJSON(value?: StepviewWidgetRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewWidgetRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'message': value.message,
    } as StringDateInterface<StepviewWidgetRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


