import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { TableParamsSorting } from '../../models/table/TableParams';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import { PackageModel, PackageRetrieveListModel, PackageRetrieveListModelInterface } from "api";
import FilterColumn from 'models/table/FilterColumn';
import PackageActions from 'reducers/types/Package';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { Package } from 'mdi-material-ui';
import PackageState from 'reducers/interface/PackageState';

interface StateProps {
    packageReducer: PackageState;
    appReducer: AppState;
}

interface DispatchProps {
    listPackages: (params: PackageRetrieveListModelInterface) => void;
    clearPackages: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    package?: PackageModel;
}

class PackageList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    filterColumns: FilterColumn[] = [];

    constructor(props) {
        super(props);
        this.state = {
            package: null
        };
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name')}),
            new TableColumn({ key: 'description', isSortable: true, label: i18next.t('general.description')}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_PACKAGE_LIST_FAILURE
        ])

        this.updateTabTitle(i18next.t('packages.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
    }

    onDetails = (row: PackageModel) => {
        this.props.navigate(`/packages/${row.id}`)
    }

    onCreate = () => {
        this.props.navigate("/packages/create")
    }

    getData = () => { this.reloadData() }

    getItemCount = (): number => {
        return this.props.packageReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listPackages( this.getParams().getApiFilter() )
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('packages', this.props.location)){
            this.props.clearPackages();
        } 
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if (this.getParams() == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('packages.model')}
                            headerIcon={<Package/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('packages.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('packages.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={this.props.packageReducer.collection.count_filtered}
                                    listItems={this.props.packageReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_PACKAGE_LIST)}
                                    tableParams={ this.getParams() }
                                    delegate={ this } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('name', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns;
    }

}

const mapStateToProps = (state: any) => ({
    packageReducer: state.packages,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch) => {
    return {
        listPackages: (params: PackageRetrieveListModel) => dispatch(PackageActions.CreatePackageList(params)),
        clearPackages: () => dispatch(PackageActions.CreateClearPackageList()),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(PackageList, TableStyles)
            )
        )
    )
);