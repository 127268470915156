import i18next from "i18next"
import MultiSelectOption from "interface/MultiSelectOption";
import RoleType from "interface/RoleType";
import Statuses from "interface/Statuses";

export default class Constants {

    static CONNECTOR_REDIRECT_URI = 'setup';
    static MAX_ITEMS_PER_SELECT_LIST = 25;
    
    static BUILD_STATES = () => {return { 
        'failed': i18next.t('task_monitor.state_failed'), 
        'succeeded': i18next.t('task_monitor.state_succeeded'),
        'awaiting': i18next.t('task_monitor.state_awaiting'),
        'processing': i18next.t('task_monitor.state_processing')
    }}

    static BUILD_STATUSES = (): MultiSelectOption[] => Object.values(Statuses).map((status) => {return {value: status, label: i18next.t(`global.statuses.${status}`)}})

    static EMPTY_MULTISELECT: MultiSelectOption = {
        value: 0,
        label: ''
    }

    static IMAGE_FOR_MODELS = (): MultiSelectOption[] => [
        {label: i18next.t('instance.model'), value: 'Instance'},
        {label: i18next.t('connector.model'), value: 'Connector'},
        {label: i18next.t('connector_container.model'), value: 'ConnectorContainer'},
        {label: i18next.t('service_container.model'), value: 'ServiceContainer'}
    ]

    static WIDGET_FIELD_TYPE  = (): Array<MultiSelectOption> => [
        {label: i18next.t('global.form.text_field_placeholder'), value: 'text'},
        {label: i18next.t('general.html'), value: 'html'},
        {label: i18next.t('general.bool'), value: 'bool'},
    ]

    static DEFAULT_MIGRATION_ENABLED = true;

    static TIME_TO_REFRESH = 300;

    static Roles = {
        admin: 'admin',
        account_manager: 'account_manager',
        helpdesk: 'helpdesk',
        system: 'system',
        developer_admin: 'developer_admin',
        developer: 'developer',
        manager: 'manager',
        guest: 'guest'
    }

    static getRoles = (type: RoleType): string[] => {
        const alwaysAllowed = [Constants.Roles.admin]
        const managers = [Constants.Roles.account_manager, Constants.Roles.developer_admin, Constants.Roles.manager];
        const developers = [Constants.Roles.developer_admin, Constants.Roles.developer]
        switch (type) {
            case RoleType.Admins:
                return alwaysAllowed;
            case RoleType.Managers:
                return alwaysAllowed.concat(managers);
            case RoleType.DevelopersAdmin:
                return alwaysAllowed.concat(Constants.Roles.developer_admin);
            case RoleType.Developers:
                return alwaysAllowed.concat(developers);
            case RoleType.HelpdeskAdmin:
                return alwaysAllowed.concat(Constants.Roles.account_manager)
            case RoleType.Helpdesk:
                return alwaysAllowed.concat([Constants.Roles.account_manager, Constants.Roles.helpdesk]);
            case RoleType.Guest:
                return alwaysAllowed.concat(managers).concat(developers).concat(Constants.Roles.guest);
            case RoleType.System:
                return alwaysAllowed.concat(Constants.Roles.system);
        }
    }

    static SERVICE_VOLUMES: string = '{"public": [], "private": [], "config": []}';

}

export enum Images {
    BUMBAL_CORE = 28,
    REACT_BACKOFFICE_INTERFACE_BETA = 39,
    REACT_INTERFACE = 31,
    UI_TOOLS_DEV = 65,
    UI_TOOLS_PROD = 69,

}

export enum Keys {
    CtrlLeft = 'ControlLeft',
    CtrlRight = 'ControlRight',
    U = 'KeyU',
    I = 'KeyI',
    S = 'KeyS',
    C = 'KeyC',
    H = 'KeyH',
    T = 'KeyT',
    M = 'KeyM',
}

export enum DirectionsEnum {
    TOP = 'top',
    UP = 'up',
    DOWN = 'down',
    BOTTOM = 'bottom'
}

