import { Divider } from "@mui/material";
import React from 'react';
import useWidgetStyles from 'styles/WidgetStyles';
import { LinearProgress } from '@mui/material';


export interface WidgetTemplateViewProps {
}

const WidgetTemplateView = (props: WidgetTemplateViewProps) => {
 
    const {classes} = useWidgetStyles();
    const templateArray = [1,2,3,4,5,6,7,8,9]
    return (
        <div className={classes.template_container}>
            {templateArray.map((_, index) => 
                (<div className={classes.template_widget} key={`template_widget_${index}`}>
                    <LinearProgress className={classes.template_header}/>
                    <LinearProgress className={classes.template_row}/>
                    <Divider className={classes.divider}/>
                    <LinearProgress className={classes.template_row}/>
                    <Divider className={classes.divider}/>
                    <LinearProgress className={classes.template_row}/>
                    <Divider className={classes.divider}/>
                </div>)
            )}
        </div>
    );
};

export default WidgetTemplateView;