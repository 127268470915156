import React from 'react';
import Instance from 'api/override/InstanceModel';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { AccountSupervisor, Docker, LockReset } from 'mdi-material-ui';
import ButtonStyled from 'components/styled/ButtonStyled';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';
import DetailsActionButton from 'interface/DetailsActionButton';
import TokenUtil from 'utils/TokenUtil';
import DetailsFieldIconButton from 'interface/DetailsFieldIconButton';


interface InstanceDetailsProps {
    instance: Instance;
    onInstanceDelete?: () => void;
    onInstanceUpdate?: () => void;
    onSetImageVersion?: () => void;
    onInstanceMigrate: () => void;
    onInstanceUpdateAdminPassword?: () => void;
    onInstanceAuthenticate?: () => void;
    onInterfaceClick?: () => void;
    onInterfaceButtonClick?: () => void;
    onOldInterfaceClick?: () => void;
    onOldInterfaceButtonClick?: () => void;
}

const InstanceDetails = (props: InstanceDetailsProps) => {
 
    const { instance, onSetImageVersion, onInstanceDelete, onInstanceUpdate, onInstanceUpdateAdminPassword } = props;
    const { t } = useTranslation();
    const {classes} = useDetailsTabStyles();
    // const toolsPosting = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_INSTANCE_ADD_TOOLS));
    const shouldShowMigrationButton = (): boolean => {return (instance.migrate && !instance.migrated)}
    const shouldRenderDeletedInstanceView = () => {
        return (instance?.deleted_at != null)
    }

    const getInstanceActionButtons = (): Array<DetailsActionButton> => {
        let arr: Array<DetailsActionButton> = [
            {title: t('global.action.update_model', {model: t('instance.admin_password')}), onClickCallback: onInstanceUpdateAdminPassword, icon: LockReset},
        ]
        if (TokenUtil.getTokenUtil().isAccountManager()) {
            arr = arr.concat([{title: t('instance.set_images'), onClickCallback: onSetImageVersion, icon: Docker }]);
        }

        return arr;
    }

    const urlIconButton: DetailsFieldIconButton = {
        buttonIcon: AccountSupervisor,
        tooltip: 'Sign in as a selected user of the instance',
    }

    const interfaceIconButton: DetailsFieldIconButton = {
        ...urlIconButton,
        onClick: props.onInterfaceButtonClick
    }

    const oldInterfaceIconButton: DetailsFieldIconButton = {
        ...urlIconButton,
        onClick: props.onOldInterfaceButtonClick
    }

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('instance.model')}
                onEdit={onInstanceUpdate}
                onDelete={shouldRenderDeletedInstanceView() ? undefined : onInstanceDelete}
                detailsActionButton={getInstanceActionButtons()}
            />
                {shouldRenderDeletedInstanceView() ? 
                <>
                    <DetailsField propKey={t('instance.interface')} propVal={instance?.getPrettyUrlNew()}/>
                    <DetailsField propKey={t('instance.old_interface')} propVal={instance?.getPrettyUrlOld()}/>
                </>
                :
                <>
                    <DetailsField propKey={t('instance.interface')} propVal={instance?.getPrettyUrlNew()} onClick={props.onInterfaceClick} iconButton={interfaceIconButton}/>
                    <DetailsField propKey={t('instance.old_interface')} propVal={instance?.getPrettyUrlOld()} onClick={props.onOldInterfaceClick} iconButton={oldInterfaceIconButton}/>
                </>
                }
                <DetailsField propKey={t('instance.admin_email')} propVal={instance?.admin_email}/>
                <DetailsField propKey={t('instance.api_key')} propVal={instance?.api_key}/>
                <DetailsField propKey={t('instance.ui_image_version')} propVal={instance?.ui_image_version?.version_name}/>
                <DetailsField propKey={t('instance.core_image_version')} propVal={instance?.core_image_version?.version_name}/>
                <DetailsField propKey={t('instance.ui_tools_image_version')} propVal={instance?.tools_image_version?.version_name}/>
                <DetailsField propKey={t('packages.model')} propVal={instance?._package?.name}/>
                
                {shouldRenderDeletedInstanceView() ? 
                    <DetailsField propKey={t('instance.deletion_reason')} propVal={instance?.deletion_reason}/>
                : null}
                { shouldShowMigrationButton() ?
                    <ButtonStyled 
                        disabled={instance.status !== 'live'} 
                        className={classes.migration_button} 
                        onClick={props.onInstanceMigrate}
                    >
                        {t('instance.view.migrate')}
                    </ButtonStyled> 
                : null}

        </div>
    );
};

export default InstanceDetails;