import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RoleModel } from 'api';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from 'components/form_controls/SingleSelect';
import User from 'api/override/UserModel';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveUserCreateSchema } from 'schemas/User';

interface UserCreateFormProps {
    onFormSubmit?: (value?: User) => void;
    roles: RoleModel[];
}

const UserCreateForm = (props: UserCreateFormProps) => {

    const { t } = useTranslation();
    const { roles } = props;
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const roleOptions: MultiSelectOption[] = roles.map((role: RoleModel) => {return {value: role.name, label: t(`users.roles.${role.name}`)}})

    return (
        <Formik
            initialValues={new User({
                full_name: '',
                password: '',
                email: '',
                role_name: null
            })}
            validationSchema={retrieveUserCreateSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<User>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('users.model')})}</h2>
                    
                    <TextFieldStyled 
                        id="full_name" 
                        name="full_name"
                        label={t('users.full_name')}
                        helperText={formik.touched.full_name ? formik.errors.full_name : ""}
                        error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="password" 
                        name="password"
                        type="password"
                        label={t('users.password')}
                        helperText={formik.touched.password ? formik.errors.password : ""}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled
                        id="email"
                        name="email"
                        label={t('users.email')}
                        helperText={formik.touched.email ? formik.errors.email : ""}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />

                    <SingleSelect 
                        id="role_name" 
                        name="role_name"
                        label={t('users.role_name')}
                        options={roleOptions}
                        value={roleOptions.find(role => role.value === formik.values?.role_name)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.role_name && Boolean(formik.errors.role_name)}
                        formikHelperText={formik.touched.role_name ? formik.errors.role_name : ""}
                    />

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('users.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default UserCreateForm;