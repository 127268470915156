import React, { PropsWithChildren } from 'react';
import useDetailsViewHeaderStyles from 'styles/DetailsViewHeaderStyles';

interface DetailsViewHeaderProps {
    model: string;
    title: string;
}

const DetailsViewHeader = (props: PropsWithChildren<DetailsViewHeaderProps>) => {
    const { model, title } = props;
    const {classes} = useDetailsViewHeaderStyles();
    
    return (
        <div className={classes.header}>
            <div className={classes.titles}>
                <h1>{title}</h1>
                <h5>{model}</h5>
            </div>
            {props.children}
        </div>
    );
};

export default DetailsViewHeader;