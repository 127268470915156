import React, { PropsWithChildren } from 'react';
import EnvUtil from 'utils/EnvUtil';
import { Page404 } from './Page404';

interface EnvWrapperProps {
    hideOnProd?: boolean;
    hideOnStage?: boolean;
    hide404?: boolean;
}

const EnvWrapper = (props: PropsWithChildren<EnvWrapperProps>): JSX.Element => {
    const { hideOnProd, hideOnStage } = props;

    return (EnvUtil.isProduction() && hideOnProd === true) || (EnvUtil.isStage() && hideOnStage === true)
        ? props.hide404 ? null : <Page404 />
        : (<>{props.children}</>)
}

export default EnvWrapper;