/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface SettingModelInterface {
    key?: string;
    value?: string;
    stage_value?: string | null;
    type?: SettingModelTypeEnum;
    group?: string;
    description?: string | null;
    encrypted?: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

/**
 * 
 * @export
 * @interface SettingModel
 */
export class SettingModel extends BaseModel<SettingModelInterface> {

    /**
     * Key of this setting
     * @type {string}
     * @memberof SettingModel
     */
    key?: string;
    /**
     * Value of this setting
     * @type {string}
     * @memberof SettingModel
     */
    value?: string;
    /**
     * Value of this setting for stage environments
     * @type {string}
     * @memberof SettingModel
     */
    stage_value?: string | null;
    /**
     * Type description of this entry, can be string, boolean or integer
     * @type {string}
     * @memberof SettingModel
     */
    type?: SettingModelTypeEnum;
    /**
     * Group
     * @type {string}
     * @memberof SettingModel
     */
    group?: string;
    /**
     * Description
     * @type {string}
     * @memberof SettingModel
     */
    description?: string | null;
    /**
     * Whether this value is encrypted in the database
     * @type {boolean}
     * @memberof SettingModel
     */
    encrypted?: boolean;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof SettingModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof SettingModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof SettingModel
     */
    deleted_at?: Date;

    constructor(data?: SettingModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: SettingModel, value: StringDateInterface<SettingModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function SettingModelFromJSON(json: any): SettingModel {
    return SettingModelFromJSONTyped(json, false);
}

export function SettingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SettingModel;
    try {
        let localClass = require.context('../override', true)('./SettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'stage_value': !exists(json, 'stage_value') ? undefined : json['stage_value'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'encrypted': !exists(json, 'encrypted') ? undefined : json['encrypted'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function SettingModelToJSON(value?: SettingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SettingModel;
    try {
        let localClass = require.context('../override', true)('./SettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'key': value.key,
        'value': value.value,
        'stage_value': value.stage_value,
        'type': value.type,
        'group': value.group,
        'description': value.description,
        'encrypted': value.encrypted,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
    } as StringDateInterface<SettingModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum SettingModelTypeEnum {
    String = 'string',
    Boolean = 'boolean',
    Integer = 'integer'
}


