import ActionTypes from "config/ActionTypes";
import { AppActions } from "reducers/types/App";
import { put } from "redux-saga/effects";
import { LogError } from "utils/Log";
import TokenUtil from "utils/TokenUtil";


export const ApiHandler = function*(func: Function, args?: any) {
    yield put({ type: ActionTypes.API_CALL_START })
    try {
        const data = yield args ? func(args): func()
        yield put({ type: ActionTypes.API_CALL_FINISH })
        return data
    } catch (error) {
        const jsonError = yield error['clone']?.()['json']?.();
        if (error['status'] != null && ['401', '403'].includes( ''+ error['status'] )) {
            if (jsonError.errors.includes('invalid_token') || TokenUtil.getTokenUtil().isTokenExpired()) {
                TokenUtil.getTokenUtil().clearToken()
            }
        }

        yield put(AppActions.CreateError(ActionTypes.API_CALL_FAILURE, error as Error));
                
        if(jsonError != null) {
            LogError(jsonError)
        }
        
        throw error;
    }
}