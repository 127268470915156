import ActionTypes from "config/ActionTypes";
import Stepview from 'api/override/StepviewModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import StepviewState from "./interface/StepviewState";
import { StepviewListSuccess, StepviewRetrieveSuccess } from "./types/Stepview";

const initialState: StepviewState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function StepviewReducers(state = initialState, action: BaseReducerAction): StepviewState {
    switch (action.type) {
        case ActionTypes.API_STEPVIEW_UPDATE:
        case ActionTypes.API_STEPVIEW_RETRIEVE:
        case ActionTypes.API_STEPVIEW_LIST:
        case ActionTypes.API_STEPVIEW_CREATE:
        case ActionTypes.API_STEPVIEW_DELETE:
        case ActionTypes.API_STEPVIEW_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_STEPVIEW_UPDATE_SUCCESS:
        case ActionTypes.API_STEPVIEW_CREATE_SUCCESS:
        case ActionTypes.API_STEPVIEW_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_STEPVIEW_UPDATE_FAILURE:
        case ActionTypes.API_STEPVIEW_CREATE_FAILURE:
        case ActionTypes.API_STEPVIEW_DELETE_FAILURE:
        case ActionTypes.API_STEPVIEW_LIST_FAILURE:
        case ActionTypes.API_STEPVIEW_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_STEPVIEW_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as StepviewRetrieveSuccess).params as Stepview,
                error: undefined
            };

        case ActionTypes.API_STEPVIEW_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_STEPVIEW_LIST_SUCCESS:
            const result = action as StepviewListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Stepview>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_STEPVIEW_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}