/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    HistoryFilterModel,
    HistoryFilterModelFromJSON,
    HistoryFilterModelFromJSONTyped,
    HistoryFilterModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelFromJSONTyped,
    HistoryModelRetrieveListModelToJSON,
    HistoryRetrieveListModelAllOf,
    HistoryRetrieveListModelAllOfFromJSON,
    HistoryRetrieveListModelAllOfFromJSONTyped,
    HistoryRetrieveListModelAllOfToJSON,
} from './';


export interface HistoryRetrieveListModelInterface {
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
    filters?: HistoryFilterModel;
}

/**
 * 
 * @export
 * @interface HistoryRetrieveListModel
 */
export class HistoryRetrieveListModel extends BaseModel<HistoryRetrieveListModelInterface> {

    /**
     * Offset
     * @type {number}
     * @memberof HistoryRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof HistoryRetrieveListModel
     */
    limit?: number;
    /**
     * Sort by Column
     * @type {string}
     * @memberof HistoryRetrieveListModel
     */
    sort_by?: string;
    /**
     * Sort direction
     * @type {string}
     * @memberof HistoryRetrieveListModel
     */
    sort_direction?: string;
    /**
     * 
     * @type {HistoryFilterModel}
     * @memberof HistoryRetrieveListModel
     */
    filters?: HistoryFilterModel;

    constructor(data?: HistoryRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: HistoryRetrieveListModel, value: StringDateInterface<HistoryRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function HistoryRetrieveListModelFromJSON(json: any): HistoryRetrieveListModel {
    return HistoryRetrieveListModelFromJSONTyped(json, false);
}

export function HistoryRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = HistoryRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./HistoryRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'sort_by': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sort_direction': !exists(json, 'sort_direction') ? undefined : json['sort_direction'],
        'filters': !exists(json, 'filters') ? undefined : HistoryFilterModelFromJSON(json['filters']),
    });
}

export function HistoryRetrieveListModelToJSON(value?: HistoryRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = HistoryRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./HistoryRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'offset': value.offset,
        'limit': value.limit,
        'sort_by': value.sort_by,
        'sort_direction': value.sort_direction,
        'filters': HistoryFilterModelToJSON(value.filters),
    } as StringDateInterface<HistoryRetrieveListModel>;
    return createClass.toJson(value, model);
}


