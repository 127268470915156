import React from 'react'
import { AlertCircleOutline, AlertOctagonOutline, AlertOutline } from 'mdi-material-ui';
import { Divider } from '@mui/material';
import useDialogStyles from 'styles/DialogStyles';


interface ModalInfoComponentProps {
    variant: 'warning' | 'error' | 'info';
    message: string;
}

const ModalInfoComponent = (props: ModalInfoComponentProps) => {

    const {classes} = useDialogStyles();
    const {variant, message} = props

    const getIcon = (): JSX.Element => {
        switch (variant) {
            case 'warning':
                return <AlertOutline/>
            case 'error':
                return <AlertOctagonOutline/>
            case 'info':
                return <AlertCircleOutline/>
            default:
                return <AlertCircleOutline/>
        }
    }

    return (
        <div className={`${classes.modal_info_container} ${variant === 'error' && 'error'}`}>
            {getIcon()}
            <Divider orientation='vertical'/>
            <p>{message}</p>
        </div>
    )
}

export default ModalInfoComponent
