import React from 'react';
import { useTranslation } from "react-i18next";
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import FileUpload from 'components/FileUpload';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ExtendedFile from 'interface/ExtendedFile';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import Instance from 'api/override/InstanceModel';
import Connector from 'api/override/ConnectorModel';

interface ConnectorContainerFileUploadModalProps extends BaseFormModalProps<any> {
    instance: Instance;
    connector: Connector;
}

const ConnectorContainerFileUploadModal = (props: ConnectorContainerFileUploadModalProps) => {
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    return (
        <SmallFormModal {...props} title={t('global.title.upload_model', {model: t('connector_container.model')})}
            initialValues={{
                filename: ''
            }}
            action={ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={classes.label_container}>
                        <p>{props.instance?.name} - {props.connector?.name}</p>
                    </div>
                    <TextFieldStyled 
                        key="filename"
                        id="filename"
                        name="filename"
                        label={t('connector_container.filename')} 
                        value={formik.values.filename}
                        onChange={formik.handleChange}
                    />

                    <FileUpload 
                        key="file" 
                        name="file"
                        onFileUpload={(file: ExtendedFile) => {
                            formik.setFieldValue("file", file)
                            if(formik.values.filename === '') {
                                formik.setFieldValue("filename", file?.name)
                            }
                        }}
                    />
                </div>
            }
        />
    );
};

export default ConnectorContainerFileUploadModal;