import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import Instance from 'api/override/InstanceModel';
import SmallModal from 'components/SmallModal';
import ButtonStyled from 'components/styled/ButtonStyled';
import { useDispatch, useSelector } from 'react-redux';
import InstanceActions, { InstanceUsersRetrieveSuccess } from 'reducers/types/Instance';
import { InstanceAuthenticateCreateTokenRequest, InstanceAuthenticateModel } from 'api';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import { BaseAction } from 'reducers/interface/ReducerAction';
import MultiSelectOption from 'interface/MultiSelectOption';
import InstanceUser from 'api/override/InstanceUserModel';
import SingleSelect from 'components/form_controls/SingleSelect';
import { RootState } from 'store/AppStore';

interface InstanceAuthenticateModalProps { 
    instance: Instance;
    open: boolean;
    onClose: () => void;
}

const InstanceAuthenticateModal = (props: InstanceAuthenticateModalProps) => {

    const { t } = useTranslation()
    const { instance, open, onClose } = props
    const dispatch = useDispatch();
    const { classes } = useDialogStyles()
    const [chosenUserId, setChosenUserId] = useState<number>(null)
    const [instanceUsers, setInstanceUsers] = useState<MultiSelectOption[]>([])
    const isFetchingToken = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN))

    const [listeners] = useState([
        new NCListener(ActionTypes.API_INSTANCE_USERS_RETRIEVE_SUCCESS, (action: BaseAction) => onRetrieveUsersSuccess(action as InstanceUsersRetrieveSuccess))
    ])

    const handleComponentDidMount = () => {
        NotificationCenter.default.addNCListeners(listeners)
        dispatch(InstanceActions.CreateInstanceUsersRetrieve({uuid: instance?.uuid}))
        return () => {NotificationCenter.default.removeListeners(listeners)}
    }
    // eslint-disable-next-line
    useEffect(handleComponentDidMount, [])  

    const onRetrieveUsersSuccess = (action: InstanceUsersRetrieveSuccess) => {
        const users: InstanceUser[] = action.params.data as InstanceUser[]
        setInstanceUsers(users as MultiSelectOption[])
    }

    const fetchToken = () => {
        const createTokenParams: InstanceAuthenticateCreateTokenRequest = {
            uuid: instance.uuid,
            instanceAuthenticateModel: {
                user_id: chosenUserId
            } as InstanceAuthenticateModel
        }
        dispatch(InstanceActions.CreateInstanceAuthenticateCreateToken(createTokenParams))
    }

    return (
        <SmallModal open={open} onModalClosePress={onClose} title={t('instance.authenticate.dialog_title')} isLoading={isFetchingToken}> 
            <div className={classes.fields_container} style={{margin: '20px 0 10px 0'}}>
                <>
                    <SingleSelect
                        id="user" 
                        name="user"
                        label={t('instance.users')}
                        options={instanceUsers as MultiSelectOption[]}
                        value={instanceUsers?.find(user => user.value === chosenUserId)}
                        onChange={(e) => setChosenUserId(e.target?.['value'])}
                    />
                    <ButtonStyled onClick={fetchToken} disabled={isFetchingToken}>
                        {t('instance.authenticate.create_token_and_sign_in')}
                    </ButtonStyled>
                </>
            </div>
        </SmallModal>
    )
}

export default InstanceAuthenticateModal