import React from 'react';
import { Divider } from '@mui/material';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import Connector from 'api/override/ConnectorModel';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface ConnectorDetailsProps {
    connector: Connector;
    onConnectorDelete?: () => void;
    onConnectorEdit?: () => void;
}

const ConnectorDetails = (props: ConnectorDetailsProps) => {
    const { t } = useTranslation();
    const { connector, onConnectorDelete, onConnectorEdit } = props;
    const {classes} = useDetailsTabStyles();

    return (

        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('connector.model')}
                onEdit={onConnectorEdit}
                onDelete={onConnectorDelete}
            />
            <DetailsField propKey={t('connector.name')} propVal={connector?.name} />
            <DetailsField propKey={t('connector.url')} propVal={connector?.url} />
            <DetailsField propKey={t('connector.namespace')} propVal={connector?.namespace} />
            <DetailsField propKey={t('connector.image')} propVal={connector?.image_name} />
            <DetailsField propKey={t('connector.redirect_uri')} propVal={connector?.redirect_uri} />
            <DetailsField propKey={t('connector.redirect_image')} propVal={connector?.redirect_image_name} />
            <DetailsField propKey={t('connector.redirect_image_version')} propVal={connector?.redirect_image_version_name} />
            {connector?.text != null && 
                <>
                    <Divider className={classes.divider}/>
                    <p className={classes.documentation_title}>{t('connector.documentation')}</p>
                    <div dangerouslySetInnerHTML={{ __html: connector?.text}} style={{margin: '0 30px'}}/>
                </>
            }   
        </div>
    );
};

export default ConnectorDetails;