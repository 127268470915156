/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface FormFieldModelInterface {
    id?: number;
    property_id?: number;
    form_id?: number;
    name?: string;
    label?: string;
    type?: FormFieldModelTypeEnum;
    options?: string;
    require?: boolean;
    position?: number;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface FormFieldModel
 */
export class FormFieldModel extends BaseModel<FormFieldModelInterface> {

    /**
     * id of the form field
     * @type {number}
     * @memberof FormFieldModel
     */
    id?: number;
    /**
     * Property linked to this form field
     * @type {number}
     * @memberof FormFieldModel
     */
    property_id?: number;
    /**
     * Form this form field is linked to
     * @type {number}
     * @memberof FormFieldModel
     */
    form_id?: number;
    /**
     * Name of the input
     * @type {string}
     * @memberof FormFieldModel
     */
    name?: string;
    /**
     * Label of the input
     * @type {string}
     * @memberof FormFieldModel
     */
    label?: string;
    /**
     * Type of property
     * @type {string}
     * @memberof FormFieldModel
     */
    type?: FormFieldModelTypeEnum;
    /**
     * For selects or radio buttons
     * @type {string}
     * @memberof FormFieldModel
     */
    options?: string;
    /**
     * Whether this input is required
     * @type {boolean}
     * @memberof FormFieldModel
     */
    require?: boolean;
    /**
     * Position of this field in the form
     * @type {number}
     * @memberof FormFieldModel
     */
    position?: number;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof FormFieldModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof FormFieldModel
     */
    updated_at?: Date;

    constructor(data?: FormFieldModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: FormFieldModel, value: StringDateInterface<FormFieldModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function FormFieldModelFromJSON(json: any): FormFieldModel {
    return FormFieldModelFromJSONTyped(json, false);
}

export function FormFieldModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormFieldModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FormFieldModel;
    try {
        let localClass = require.context('../override', true)('./FormFieldModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'property_id': !exists(json, 'property_id') ? undefined : Number(json['property_id']),
        'form_id': !exists(json, 'form_id') ? undefined : Number(json['form_id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'require': !exists(json, 'require') ? undefined : json['require'],
        'position': !exists(json, 'position') ? undefined : Number(json['position']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function FormFieldModelToJSON(value?: FormFieldModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FormFieldModel;
    try {
        let localClass = require.context('../override', true)('./FormFieldModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'property_id': value.property_id,
        'form_id': value.form_id,
        'name': value.name,
        'label': value.label,
        'type': value.type,
        'options': value.options,
        'require': value.require,
        'position': value.position,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<FormFieldModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum FormFieldModelTypeEnum {
    Checkbox = 'checkbox',
    Date = 'date',
    DatetimeLocal = 'datetime-local',
    Email = 'email',
    Number = 'number',
    Password = 'password',
    Radio = 'radio',
    Tel = 'tel',
    Text = 'text',
    Time = 'time',
    Url = 'url',
    Textarea = 'textarea',
    Select = 'select'
}


