import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import ServiceState from "./interface/ServiceState";
import { ServiceListInstancesSuccess, ServiceListSuccess } from "./types/Service";
import { ErrorInterface } from "./types/App";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import Instance from "api/override/InstanceModel";
import Service from "api/override/ServiceModel";

const initialState: ServiceState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    error: null,
    isFetchingInstances: false,
    instances: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    secrets: null,
    logs: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function ServiceReducers(state = initialState, action: BaseReducerAction): ServiceState {
    switch (action.type) {
        case ActionTypes.API_SERVICE_UPDATE:
        case ActionTypes.API_SERVICE_RETRIEVE:
        case ActionTypes.API_SERVICE_LIST:
        case ActionTypes.API_SERVICE_CREATE:
        case ActionTypes.API_SERVICE_DELETE:
        case ActionTypes.API_SERVICE_LOGS_LIST:
        case ActionTypes.API_SERVICE_HISTORY_LIST:
        case ActionTypes.API_SERVICE_UPDATE_SECRETS:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_SERVICE_UPDATE_SUCCESS:
        case ActionTypes.API_SERVICE_CREATE_SUCCESS:
        case ActionTypes.API_SERVICE_DELETE_SUCCESS:
            return {
                ...state,
            };
            
        case ActionTypes.API_SERVICE_CREATE_FAILURE:
        case ActionTypes.API_SERVICE_DELETE_FAILURE:
        case ActionTypes.API_SERVICE_LIST_FAILURE:
        case ActionTypes.API_SERVICE_UPDATE_FAILURE:
        case ActionTypes.API_SERVICE_RETRIEVE_FAILURE:
            return {
                ...state,
                error: (action as ErrorInterface).data.errors
            }

        case ActionTypes.API_SERVICE_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Service,
                error: null
            };

        case ActionTypes.API_SERVICE_LIST_SUCCESS:
            const result = action as ServiceListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Service[],
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_SERVICE_LIST_INSTANCES:
            return {
                ...state,
                isFetchingInstances: true,
                error: null
            }
        
        case ActionTypes.API_SERVICE_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_SERVICE_LIST_INSTANCES_SUCCESS:
            const resultInstances = action as ServiceListInstancesSuccess
            return {
                ...state,
                isFetchingInstances: false,
                instances: {
                    items: resultInstances.params.items as Instance[],
                    count_filtered: resultInstances.params.count_filtered,
                    count_unfiltered: resultInstances.params.count_unfiltered
                }
            }
        
        case ActionTypes.API_SERVICE_LIST_INSTANCES_FAILURE:
            return {
                ...state,
                isFetchingInstances: false,
                error: (action as ErrorInterface).data?.errors
            }
        case ActionTypes.API_SERVICE_RETRIEVE_SECRETS:
            return {
                ...state,
                secrets: null,
                error: null
            }
        case ActionTypes.API_SERVICE_RETRIEVE_SECRETS_SUCCESS:
            return {
                ...state,
                secrets: action.params?.data
            }
        case ActionTypes.API_SERVICE_LOGS_LIST_SUCCESS: {
            return {
                ...state,
                logs: action.params
            }
        }
            
        case ActionTypes.SERVICE_VIEW_CLEAR_INSTANCES:
            return {
                ...state,
                isFetchingInstances: false,
                instances: initialState.instances

            }

        case ActionTypes.CLEAR_SERVICE:
            return {
                ...state,
                secrets: null,
                selectedObject: null
            }

        case ActionTypes.API_SERVICE_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }
    
        default:
            return state;
    }
}