import React from 'react';
import { IconButton, LinearProgress, Menu, MenuItem } from '@mui/material';
import {useTranslation} from "react-i18next";
import useProcessPanelStyles from 'styles/ProcessPanelStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GlobalProcessProgressNotification from 'models/GlobalProcessProgressNotification';
import NCNotificationModel from 'models/NCNotificationModel';
import { useNavigate } from "react-router-dom";
import ProcessLogModal from './ProcessLogModal';


interface GlobalProcessProps {
    globalProcess: GlobalProcessProgressNotification
    onDismiss: (key: string) => void;
}

const GlobalProcess = (props: GlobalProcessProps) => {
 
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {classes} = useProcessPanelStyles();
    const { globalProcess } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [logModalOpen, setLogModalOpen] = React.useState(false)
    const open = Boolean(anchorEl);
    const options = ['Logs', 'Redirect', 'Dismiss'];
    const currentStep: NCNotificationModel = globalProcess.notifications.bbLast();
    const progressStep: number = 100 / currentStep?.params.data?.total_steps
    const processName: string = currentStep.params?.data?.process_name
    const entityName: string = currentStep.params?.data?.meta.name ?? ''
    const isFinished: boolean = currentStep.params?.data?.current_step === currentStep.params.data?.total_steps
    const hasError: boolean = currentStep.params?.status === 'error'

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (option) => {
        switch (option) {
            case 'Logs':
                setLogModalOpen(true)
                break;
            case 'Redirect':
                navigate(currentStep.getRedirect())
                break;
            case 'Dismiss':
                props.onDismiss(currentStep.getKey())
                break;
        }
    }

    const setProgress = (): number => {
        return (currentStep?.params?.data?.current_step * progressStep)
    }

    return (
        !globalProcess.isDismissed &&
        <div>
            <div className={classes.expanded_panel_content_top}>
                <p className={classes.global_progress_header}>{t(`node.${processName}`, {entity: entityName})}{isFinished ? ' ' + t('global.process.finished') : ''}</p>
                <div>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{padding: 5, marginRight: 20}}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        style={{zIndex: 1299}}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: 'auto'
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} onClick={() => {handleItemClick(option)}}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
            <LinearProgress 
                variant="determinate" 
                className={[classes.global_progress_linear_progress, isFinished ? 'finished' : '', hasError ? 'error' : ''].join(' ')}
                value={setProgress()} 
            />
            <ProcessLogModal
                open={logModalOpen}
                notifications={globalProcess.notifications}
                onModalClosePress={() => setLogModalOpen(false)}
                title={t('global.process.logs')}
            />
        </div>
    );
};

export default GlobalProcess;