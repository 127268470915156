import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import Widget from 'api/override/WidgetModel';
import WidgetActions, { WidgetCreate, WidgetDelete, WidgetHistoryList, WidgetList, WidgetRetrieve, WidgetUpdate } from 'reducers/types/Widget';
import { WidgetListRequest, WidgetApi, WidgetUpdateRequest, WidgetRetrieveRequest, WidgetCreateRequest, WidgetDeleteRequest, WidgetHistoryListRequest } from 'api/apis/WidgetApi';
import { TableParams } from 'models/table/TableParams';
import { WidgetListResultModel } from 'api';

export function* WidgetSagas() {
    yield takeLatest(ActionTypes.API_WIDGET_LIST, WidgetSaga.widgetListWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_UPDATE, WidgetSaga.widgetUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_RETRIEVE, WidgetSaga.widgetRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_CREATE, WidgetSaga.widgetCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_DELETE, WidgetSaga.widgetDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_HISTORY_LIST, WidgetSaga.widgetHistoryListWorkerSaga);
}

export class WidgetSaga {
    
    static *widgetListWorkerSaga(action: WidgetList) {
        try {
            const widgetsArgs = {
                widgetRetrieveListModel: action.params
            } as WidgetListRequest

            const data = yield ApiHandler(new WidgetApi().widgetList, widgetsArgs)
            yield put(WidgetActions.CreateWidgetListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_WIDGET_LIST_FAILURE, error as Error))
        }
    }

    static *widgetUpdateWorkerSaga(action: WidgetUpdate) {
        try {
            
            const data = yield ApiHandler(new WidgetApi().widgetUpdate, action.params as WidgetUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(WidgetActions.CreateWidgetUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIDGET_UPDATE_FAILURE, error as Error))
        }
    }

    static widgetRetrieve = async(widget: number): Promise<Widget> => {
        return await new WidgetApi().widgetRetrieve({id: widget}) as Widget;
    }
    
    static *widgetRetrieveWorkerSaga(action: WidgetRetrieve) {
        try {
    
            const widgetsArgs = {
                id: action.params
            } as WidgetRetrieveRequest
            const data = yield ApiHandler(new WidgetApi().widgetRetrieve, widgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WidgetActions.CreateWidgetRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIDGET_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *widgetCreateWorkerSaga(action: WidgetCreate) {
        try {
    
            const widgetsArgs = {
                widgetModel: action.params
            } as WidgetCreateRequest
            const data = yield ApiHandler(new WidgetApi().widgetCreate, widgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WidgetActions.CreateWidgetCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIDGET_CREATE_FAILURE, error as Error))
        }
    }
    
    static *widgetDeleteWorkerSaga(action: WidgetDelete) {
        try {
            const widgetsArgs = {
                id: action.params
            } as WidgetDeleteRequest
            const data = yield ApiHandler(new WidgetApi().widgetDelete, widgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WidgetActions.CreateWidgetDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIDGET_DELETE_FAILURE, error as Error))
        }
    }
    
    static *widgetHistoryListWorkerSaga(action: WidgetHistoryList) {
        try{
            const widgetArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as WidgetHistoryListRequest
    
            const data = yield ApiHandler(new WidgetApi().widgetHistoryList, widgetArgs);
            yield put(WidgetActions.CreateWidgetHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_WIDGET_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static async widgetList (tableParams?: TableParams): Promise<WidgetListResultModel> {
        const retrieveListRequest = {
            widgetRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as WidgetListRequest;
        return await new WidgetApi().widgetList(retrieveListRequest);
    }
}

