import i18next from "i18next";
import Service from 'api/override/ServiceModel';
import { CustomLinkIconButton } from 'models/table/CustomActionButton';
import StorageIcon from '@mui/icons-material/Storage';

export default class ServiceOverviewButton extends CustomLinkIconButton {

    constructor(onClick: (name?: string, row?: any) => void) {
        super(
            'serviceInstancesLink', 
            i18next.t('service.service_instances'), 
            'redir-service-instances',
            onClick,
            StorageIcon,
            '/services/', 
            'uuid', 
            '/instances'
        )
    }

    render(row?: Service): JSX.Element {
        if (row.standalone) {
            return (null)
        }
        return super.render(row);
    }

}