import React, { useEffect, useRef } from 'react';
import BaseModal, { BaseModalProps } from './BaseModal';
import { Form, Formik, FormikProps, FormikConfig, FormikHelpers } from 'formik';
import useDialogStyles from 'styles/DialogStyles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import ActionTypes from 'config/ActionTypes';
import SaveDialogActions from 'components/dialog_actions/SaveDialogActions';

export interface BaseFormModalProps<T> extends Omit<BaseModalProps, 'children' | 'innerRef' | 'onReset' | 'onSubmit'> {
    onModalSubmit?: (value?: T) => void;
    errors?: string[];
}
export interface ThisProps<T> extends BaseFormModalProps<T>, Omit<FormikConfig<T>, 'component'> {
    renderForm: (props: FormikProps<T>) => JSX.Element;
    errors?: string[];
    action: ActionTypes;
}

function BaseFormModal<T>(props: ThisProps<T>): JSX.Element {

    const {classes} = useDialogStyles();
    const formRef: React.Ref<FormikProps<T>> = useRef();
    const appReducer = useSelector((state: RootState) => state.app)
    const { component, ...rest } = props;
    useEffect(() => {
        formRef.current?.setSubmitting(appReducer.isFetchingData.get(props.action))
    }, [appReducer.isFetchingData, props.action])

    const shouldSubmit = (values: T, formikHelpers: FormikHelpers<T>): void | Promise<any> => {
        if (!formRef.current?.isSubmitting || !appReducer.isFetchingData.get(props.action)) {
            formRef.current?.setSubmitting(appReducer.isFetchingData.get(props.action))
            props.onSubmit( values, formikHelpers );
        }
    }

    return (
        <BaseModal open={props.open} onModalClosePress={props.onModalClosePress} title={props.title} {...props}>
            
            <Formik {...rest} innerRef={formRef} onSubmit={ shouldSubmit }>
            {(formikProps: FormikProps<T>) => (
                <Form className={classes.form_container}>
                    <div className={classes.error_container}>
                        {props.errors?.map?.((error) => (
                            <React.Fragment key={error}>
                                {error}<br/>
                            </React.Fragment>
                        ))}
                    </div>
                    {props.renderForm(formikProps)}
                    <SaveDialogActions onModalClosePress={props.onModalClosePress}/>
                </Form>)}
            </Formik>
        </BaseModal>
    );
};

export default BaseFormModal;