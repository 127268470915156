import React from 'react';
import useFormBuilderStyles from 'styles/FormBuilderStyles';
import { useTranslation } from 'react-i18next';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import SingleSelect from 'components/form_controls/SingleSelect';
import { Plus } from 'mdi-material-ui';
import TextareaStyled from 'components/styled/TextareaStyled';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface InputPaneProps {
    onAddInput: (input: string) => void;
}

interface FormBuilderInputObject {
    name: string,
    input: JSX.Element
}

const InputPane = (props: InputPaneProps) => {
    const { t } = useTranslation();
    const {classes} = useFormBuilderStyles();

    const inputElements: Array<FormBuilderInputObject> = [
        {name: 'Text', input: <TextFieldStyled placeholder={t('global.form.text_field_placeholder')} />},
        {name: 'Select', input: <SingleSelect value={undefined} label={t('global.form.single_select_placeholder')} name={'dropdown_menu'} onChange={() => {}} autocompleteStyle={{width: '100%'}}/>},
        {name: 'Textarea', input: <TextareaStyled/>},
        {name: 'Checkbox', input: <BBCheckbox checkboxProps={{checked: true}} label={t('forms.checkbox')} />},
        {name: 'Radio', input: <FormControl component="fieldset" style={{margin: '10px 0'}}>
            <FormLabel component="legend" style={{marginBottom: 5}}>{t('forms.radio_input')}</FormLabel>
            <RadioGroup aria-label="options" name="options1" className={classes.radio_group}>
                <FormControlLabel value="opt1" control={<Radio />} label="Option 1" />
                <FormControlLabel value="opt2" control={<Radio />} label="Option 2" />
                <FormControlLabel value="opt3" control={<Radio />} label="Option 3" />
            </RadioGroup>
        </FormControl>}
    ]

    return (
        <div className={classes.input_pane}>
            {inputElements.map((inputObj: FormBuilderInputObject, index: number) => 
                <div className={classes.template_input_container} key={`input_${index}`}>
                    {inputObj.input}
                    <IconButton
                        onClick={() => props.onAddInput(inputObj.name)}
                        key={`input_add_btn_${index}`}
                        className={classes.add_input_btn}
                        size="large">
                        <Plus />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default InputPane;