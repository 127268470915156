import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {CentralInstanceApi,  CentralInstanceListRequest } from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import CentralInstanceActions, { CentralInstanceList } from 'reducers/types/CentralInstance';

export function* CentralInstanceSagas() {
    yield takeLatest(ActionTypes.API_CENTRAL_INSTANCE_LIST, CentralInstanceSaga.centralInstanceListWorkerSaga);
}

export class CentralInstanceSaga {
    static *centralInstanceListWorkerSaga(action: CentralInstanceList) {

        try {
            const centralInstanceArgs = {
                instanceRetrieveListModel: action.params
            } as CentralInstanceListRequest
            const data = yield ApiHandler(new CentralInstanceApi().centralInstanceList, centralInstanceArgs);
    
            yield put(CentralInstanceActions.CreateCentralInstanceListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CENTRAL_INSTANCE_LIST_FAILURE, error as Error))
        }
    }
}

