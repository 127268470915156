import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import SingleSelect from "components/form_controls/SingleSelect";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import ServiceContainerController from "controllers/ServiceContainerController";
import ServiceContainer from "api/override/ServiceContainerModel";

interface ServiceContainerSelectProps {
    serviceUuid: string;
    onContainerSelect?: (sc: ServiceContainer) => void;
    chosenValue?: ServiceContainer;
}

const ServiceContainerSelect = (props: ServiceContainerSelectProps) => {
    const { onContainerSelect, serviceUuid, chosenValue } = props
    const { t } = useTranslation();
    const [serviceContainerController] = useState<ServiceContainerController>(new ServiceContainerController())
    const [params] = useState(TableParams.getIncrementingSelectParams());

    const getServiceContainers = serviceContainerController.serviceContainerToMultiselect;
    const setServiceContainer = (_, val: MultiSelectOption) => {
        onContainerSelect(val as ServiceContainer)
    }
    return (
        <div style={{padding: '5px 150px'}}>
            <SingleSelect
                label={t('service_container.model')}
                value={chosenValue}
                name="service-container-select"
                onChange={setServiceContainer}
                getAsyncValuesOnSearch
                tableParams={params}
                getAsyncOptions={(params: TableParams) => getServiceContainers(serviceUuid, params)}
            />
        </div>
    );
}

export default ServiceContainerSelect;