import { BaseAPI, ConfigInputModel, FileUploadModel, PostConfigModel, ServiceContainerApi } from "api";
import ConfigData from "api/override/ConfigDataModel";
import ServiceContainer from "api/override/ServiceContainerModel";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import { ServiceSaga } from "sagas/ServiceSagas";
import Log from "utils/Log";
import { InstanceSaga } from "sagas/InstanceSagas";
import DispatchController from "./DispatchController";

export default class ServiceContainerController extends DispatchController<ServiceContainer> {

    modelName: string = 'serviceContainer';
    getApi(): BaseAPI {
        return new ServiceContainerApi();
    }

    prepareFiles = ( config: ConfigData, postValues: object ): FileUploadModel[] => {
        const fileInputs = config.allowed_input.filter((val: ConfigInputModel) => val.type === 'file');
        let returnValues: FileUploadModel[] = [];
        fileInputs.forEach(element => {
            if (element.type === 'file' && postValues[element.name].base64 != null && postValues[element.name].base64 !== '') {
                returnValues = returnValues.concat(
                    new FileUploadModel({
                        file: element.name + '.php',
                        base64: postValues[element.name].base64
                    })
                );
            }
        });
        return returnValues;
    }

    prepareConfig = (config: ConfigData, postValues: object): object => {
        const fileKeys = config.allowed_input
            .filter((val: ConfigInputModel) => val.type === 'file')
            .map((input: ConfigInputModel) => input.name);
        const filteredValues = Object.keys(postValues).filter((key: string) => !fileKeys.includes(key))
        let returnValues = {};
        filteredValues.forEach((key: string) => {
            if (config.allowed_input.find((input: ConfigInputModel) => input.name === key).type === 'dictionary') {
                returnValues[key] = postValues[key].bbMultiSelectToRecord();
            } else {
                returnValues[key] = postValues[key]
            }
        })
        return returnValues;
    }

    filterUnchanged = (unfilteredConfig: object, reducerConfig: ConfigData): PostConfigModel => {
        const filterUnchangedConfig: PostConfigModel = new PostConfigModel({})
        for (const [key, value] of Object.entries(unfilteredConfig)) {
            if(value !== reducerConfig?.config[key]) {
                filterUnchangedConfig[key] = value
            }
        }
        return filterUnchangedConfig;
    }

    serviceContainerToMultiselect = async (uuid: string, params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequestOnProp(uuid)) {
                const response = await ServiceSaga.serviceContainersList(uuid, params);
                const responseToMultiSelect: MultiSelectOption[] = (response['items'] as ServiceContainer[]).map((item: ServiceContainer) => {
                    item.label = item.instance.name
                    return item
                });

                this.cacheResponseOnParamsAndProp(responseToMultiSelect, params, uuid);
            }
            return this.getItemsForRequestAndProps(params, uuid);

        } catch (e) {
            Log(e)
        }
        return [];
    }

    instanceContainerToMultiselect = async (uuid: string, params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequestOnProp(uuid)) {
                const response = await InstanceSaga.serviceContainersList(uuid, params);
                const responseToMultiSelect: MultiSelectOption[] = (response['items'] as ServiceContainer[]).map((item: ServiceContainer) => {
                    item.label = item.service.name
                    return item
                });

                this.cacheResponseOnParamsAndProp(responseToMultiSelect, params, uuid);
            }
            return this.getItemsForRequestAndProps(params, uuid);

        } catch (e) {
            Log(e)
        }
        return [];
    }

}