
import { PropertyModel, PropertyModelTypeEnum } from "api/models/PropertyModel";
import PropertyController from "controllers/PropertyController";
import Historiable from "interface/Historiable";
import { TableParams, TableParamsSorting } from "models/table/TableParams";

export default class Property extends PropertyModel implements Historiable {

    actionName: string = "property"
    reducerName: string = "properties"

    static getClientProperties = async(): Promise<Array<Property>> => {
        const clientProps = await (new PropertyController()).listProperties(new TableParams(100, 0, TableParamsSorting.getDefault(), '', []));
        return clientProps;
    }

    static prepareCache = async() => {
        Property.getClientProperties()
    }

    getDefaultValue = () => {
        switch (this.type) {
            case PropertyModelTypeEnum.Checkbox:
                return false;
            case PropertyModelTypeEnum.Radio:
                return [];
            default:
                return '';
        }
    }

}