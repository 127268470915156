import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Wizard from 'api/override/WizardModel';
import { retrieveWizardSchema } from 'schemas/Wizard';


interface WizardDetailsDialogProps extends BaseFormModalProps<Wizard> {
    wizard?: Wizard;
}

const WizardDetailsDialog = (props: WizardDetailsDialogProps) => {

    const { wizard } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={wizard != null ? t('global.title.details_model', {model: t('wizards.model')}) : t('global.action.create_model', {model: t('wizards.model')})}
            initialValues={wizard ?? new Wizard({name: '', description: ''})}
            validationSchema={retrieveWizardSchema()}
            action={wizard != null ? ActionTypes.API_WIZARD_UPDATE : ActionTypes.API_WIZARD_CREATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="name" 
                        name="name"
                        label={t('general.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('general.description')}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />
                    
                </div>
            }
        />
    );
};

export default WizardDetailsDialog;