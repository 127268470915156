import React, { useState } from 'react';
import { ImageModelForModelEnum } from 'api';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import ImageVersionController from 'controllers/ImageVersionController';
import ImageController from 'controllers/ImageController';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import Instance from 'api/override/InstanceModel';
import EnvUtil from 'utils/EnvUtil';
import { Images } from 'config/Constants';

interface InstanceSetImageVersionDialogProps extends BaseFormModalProps<Instance> { 
    instance: Instance
}

const InstanceSetImageVersionDialog = (props: InstanceSetImageVersionDialogProps) => {

    const { t } = useTranslation()
    const { instance } = props
    const { classes } = useDialogStyles()
    const [imageController] = useState<ImageController>(new ImageController())
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())

    const getToolsImageId = (): number => {
        if(EnvUtil.isDevelopment()) {
            return Images.UI_TOOLS_DEV
        } 
        else if(EnvUtil.isProduction()) {
            return Images.UI_TOOLS_PROD
        }
        return 0
    }
    
    return (
        <SmallFormModal {...props}  
            title={instance?.name}
            subtitle={t('global.action.update_model', {model: t('image_versions.model')})}
            icon={'Docker'}
            initialValues={instance}
            action={ActionTypes.API_INSTANCE_SET_IMAGE_VERSION}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    
                    <ImageSingleSelect 
                        id='ui_image'
                        name='ui_image_id'
                        controller={imageController}
                        formik={formik}
                        label={t('instance.ui_image')}
                        model={[ImageModelForModelEnum.Instance]}
                    />

                    <ImageVersionSingleSelect
                        id='ui_image_version'
                        name='ui_image_version_id'
                        controller={imageVersionController}
                        formik={formik}
                        imageId={formik.values?.ui_image_id}
                        label={t('instance.ui_image_version')}
                        disabled={formik.values?.ui_image_id == null}
                    />

                    {formik.values.hasCore() &&
                        <>
                            <ImageSingleSelect 
                                id='core_image'
                                name='core_image_id'
                                controller={imageController}
                                formik={formik}
                                label={t('instance.core_image')}
                                model={[ImageModelForModelEnum.Instance]}
                            />
                            <ImageVersionSingleSelect 
                                id="core_image_version"
                                name='core_image_version_id'
                                controller={imageVersionController}
                                formik={formik}
                                imageId={formik.values?.core_image_id}
                                disabled={formik.values?.core_image_id == null}
                                label={t('instance.core_image_version')}
                            />
                        </>
                    }

                    <ImageVersionSingleSelect 
                        id="tools_image_version"
                        name='tools_image_version_id'
                        controller={imageVersionController}
                        formik={formik}
                        imageId={getToolsImageId()}
                        label={t('instance.tools_image_version')}
                    />
                </div>
            }
        />
    )
}

export default InstanceSetImageVersionDialog