/* eslint-disable no-unused-vars */
import { withSnackbar } from 'notistack';
import BaseComponent, { BaseState } from './base/BaseComponent';
import { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import NotificationCenter from 'services/NotificationCenter';
import NodeNotification from 'models/NodeNotification';
import ActionTypes from 'config/ActionTypes';

interface State extends BaseState {
    isConnected: boolean;
    failCount: number;
    loggedFail: boolean;
}
class Notifier extends BaseComponent<ViewProps, State> {
    
    constructor(props) {
        super(props)
        this.state = {
            isConnected: false,
            failCount: 0,
            loggedFail: false
        }
    }

    componentDidMount() {
        // listen to window active
        window.addEventListener('focus', this.connectToWebsocket)
        window.addEventListener("blur", () => {
            this.setState({
                isConnected: false,
                failCount: 0
            })
        });
        this.connectToWebsocket();
    }

    connectToWebsocket = () => {
        if (this.state.isConnected) {
            return;
        }
        if (!document.hasFocus()) {
            return;
        }
        if (this.state.failCount > 10) {
            // display error connecting to ws?
            if (!this.state.loggedFail) {
                this.setState({
                    loggedFail: true
                }, () => {
                    this.log('stopped attempting to connect due to repeated failure');
                })
            }
            return;
        }
        let user = JSON.parse(localStorage.getItem('user'));

        this.log(user);

        if(user){
            let ws = new WebSocket('wss://backoffice.bumbal.eu:32767/'+user.uuid, localStorage.getItem('jwt'));

            ws.onopen = this.websocketOnOpen
            ws.onmessage = this.websocketOnMessage
            ws.onclose = this.websocketOnClose
            ws.onerror = this.websocketOnError
        }
    }

    websocketOnOpen = (event) => {
        this.setState({
            isConnected: true,
            failCount: 0
        })
    }

    websocketOnMessage = (evt) => {
        // post through app with notification center
        try {
            let obj: NodeNotification = JSON.parse(evt.data);
            obj.data.timestamp = new Date(obj.data.timestamp);
            this.log('message', obj);

            if (obj.message !== '') {
                const notificationCenterDidSend = NotificationCenter.default.sendNotification({type: obj.data.process_name as ActionTypes, params: obj}, null)
                if (!notificationCenterDidSend) {
                    this.props.enqueueSnackbar(obj.message, {variant: obj.status ?? 'info'});
                }
            }
        }
        catch(err) {
            this.log('error', err);
        }
    }

    websocketOnClose = () => {
        this.log('disconnected');
        this.setState({
            isConnected: false
        }, this.connectToWebsocket)
    }

    websocketOnError = () => {
        this.setState({
            failCount: this.state.failCount + 1
        });
    }

    renderView() {
        return null;
    }
}

export default componentConnect(
    () => {},
    () => {return {}},
    withSnackbar(Notifier)
);