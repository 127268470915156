/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    WizardCreateSuccessModel,
    WizardCreateSuccessModelFromJSON,
    WizardCreateSuccessModelToJSON,
    WizardListResultModel,
    WizardListResultModelFromJSON,
    WizardListResultModelToJSON,
    WizardModel,
    WizardModelFromJSON,
    WizardModelToJSON,
    WizardRemoveSuccessModel,
    WizardRemoveSuccessModelFromJSON,
    WizardRemoveSuccessModelToJSON,
    WizardRetrieveListModel,
    WizardRetrieveListModelFromJSON,
    WizardRetrieveListModelToJSON,
} from '../models';

export interface WizardCreateRequest {
    wizardModel: WizardModel;
}

export interface WizardDeleteRequest {
    id: number;
}

export interface WizardHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface WizardListRequest {
    wizardRetrieveListModel: WizardRetrieveListModel;
}

export interface WizardRetrieveRequest {
    id: number;
}

export interface WizardUpdateRequest {
    id: number;
    wizardModel: WizardModel;
}

/**
 * 
 */
export class WizardApi extends runtime.BaseAPI {

    /**
     * Create a new wizard
     * Create a new wizard
     */
    async wizardCreateRaw(requestParameters: WizardCreateRequest): Promise<runtime.ApiResponse<WizardCreateSuccessModel>> {
        if (requestParameters.wizardModel === null || requestParameters.wizardModel === undefined) {
            throw new runtime.RequiredError('wizardModel','Required parameter requestParameters.wizardModel was null or undefined when calling wizardCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WizardModelToJSON(requestParameters.wizardModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new wizard
     * Create a new wizard
     */
    wizardCreate = async(requestParameters: WizardCreateRequest): Promise<WizardCreateSuccessModel> => {
        const response = await this.wizardCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an wizard
     * Delete an wizard
     */
    async wizardDeleteRaw(requestParameters: WizardDeleteRequest): Promise<runtime.ApiResponse<WizardRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling wizardDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an wizard
     * Delete an wizard
     */
    wizardDelete = async(requestParameters: WizardDeleteRequest): Promise<WizardRemoveSuccessModel> => {
        const response = await this.wizardDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an wizard
     * retrieve a list of historical logs for an wizard
     */
    async wizardHistoryListRaw(requestParameters: WizardHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling wizardHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling wizardHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an wizard
     * retrieve a list of historical logs for an wizard
     */
    wizardHistoryList = async(requestParameters: WizardHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.wizardHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all wizards
     * List all wizards
     */
    async wizardListRaw(requestParameters: WizardListRequest): Promise<runtime.ApiResponse<WizardListResultModel>> {
        if (requestParameters.wizardRetrieveListModel === null || requestParameters.wizardRetrieveListModel === undefined) {
            throw new runtime.RequiredError('wizardRetrieveListModel','Required parameter requestParameters.wizardRetrieveListModel was null or undefined when calling wizardList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WizardRetrieveListModelToJSON(requestParameters.wizardRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardListResultModelFromJSON(jsonValue));
    }

    /**
     * List all wizards
     * List all wizards
     */
    wizardList = async(requestParameters: WizardListRequest): Promise<WizardListResultModel> => {
        const response = await this.wizardListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a wizard by its id
     * Retrieve an wizard by its id
     */
    async wizardRetrieveRaw(requestParameters: WizardRetrieveRequest): Promise<runtime.ApiResponse<WizardModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling wizardRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a wizard by its id
     * Retrieve an wizard by its id
     */
    wizardRetrieve = async(requestParameters: WizardRetrieveRequest): Promise<WizardModel> => {
        const response = await this.wizardRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an wizard
     * Update an wizard
     */
    async wizardUpdateRaw(requestParameters: WizardUpdateRequest): Promise<runtime.ApiResponse<WizardCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling wizardUpdate.');
        }

        if (requestParameters.wizardModel === null || requestParameters.wizardModel === undefined) {
            throw new runtime.RequiredError('wizardModel','Required parameter requestParameters.wizardModel was null or undefined when calling wizardUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/wizard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WizardModelToJSON(requestParameters.wizardModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WizardCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an wizard
     * Update an wizard
     */
    wizardUpdate = async(requestParameters: WizardUpdateRequest): Promise<WizardCreateSuccessModel> => {
        const response = await this.wizardUpdateRaw(requestParameters);
        return await response.value();
    }

}
