import Instance from "api/override/InstanceModel";
import MenuButton from "components/MenuButton";
import Tasks from "config/Tasks";
import { DotsVertical } from "mdi-material-ui";
import { CustomMenuButton } from "models/table/CustomActionButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import InstanceActions from "reducers/types/Instance";
import Constants from "config/Constants";
import InstanceMenuable from "interface/InstanceMenuable";
import SettingManager from "utils/SettingManager";

export interface InstanceMenuProps {
    instance: Instance;
    additionalEntity?: InstanceMenuable;
}

const InstanceMenu = (props: InstanceMenuProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [isMigrationEnabled, setIsMigrationEnabled] = useState<boolean>(Constants.DEFAULT_MIGRATION_ENABLED);
    const navigate = useNavigate();
    const location = useLocation();
    let options: string[] = [];
    if (props.additionalEntity == null) {
        options = options.concat([
            t('global.button.trigger.create'), 
            t('global.button.trigger.delete'),
            t('global.button.trigger.cleanup'),
            // t('global.button.trigger.backup'),
            t('global.action.open_in_graylog')
        ])
    }
    if (isMigrationEnabled && props.additionalEntity == null && (props.instance.migrate && !props.instance.migrated)) {
        options = options.concat(t('global.button.trigger.migrate'));
    }
    if (props.instance.has_vps) {
        options = options.concat(t('global.button.trigger.upgrade'));
    }
    options = options.concat([
        props.additionalEntity == null ? t('global.button.trigger.redeploy') : undefined, 
        props.additionalEntity == null ? t('global.button.trigger.backend-redeploy') : undefined, 
        props.additionalEntity == null ? t('global.button.trigger.frontend-redeploy') : undefined, 
        props.additionalEntity != null && props.additionalEntity.getFullUrl == null ? undefined : t('connector_container.view.copy_url'),
        props.additionalEntity == null ? t('instance.view.copy_api_url') : undefined,
        props.additionalEntity != null ? undefined : t('instance.view.toggle_maintenance_mode')
    ]).filter(Boolean);
    if(!location.pathname.includes(props.instance.uuid)) {
        options = options.concat(t('global.button.redirect'))
    }

    const onMount = () => {
        setIsMigrationEnabled(SettingManager.default.getProdEnableMigrations().getJsonValue())
    }
    useEffect(onMount, []);

    const createTriggerJob = (taskType: Tasks) => InstanceActions.CreateInstanceTriggerJob({uuid: props.instance.uuid, task: taskType, deleted: props.instance.isDeleted()})

    // global.action.open_in_graylog
    const handleClick = (option: string) => {
        switch (option) {
            case t('global.button.trigger.create'):
                dispatch(createTriggerJob(Tasks.CREATE))
                break;
            case t('global.button.trigger.delete'):
                dispatch(createTriggerJob(Tasks.DELETE))
                break;
            case t('global.button.trigger.migrate'):
                dispatch(createTriggerJob(Tasks.MIGRATION))
                break;
            case t('global.button.trigger.backup'):
                dispatch(createTriggerJob(Tasks.BACKUP))
                break;
            case t('global.button.trigger.cleanup'):
                dispatch(createTriggerJob(Tasks.CLEANUP))
                break;
            case t('global.button.trigger.redeploy'):
                dispatch(createTriggerJob(Tasks.REDEPLOY))
                break;
            case t('global.button.trigger.backend-redeploy'):
                dispatch(createTriggerJob(Tasks.BACKEND_REDEPLOY))
                break;
            case t('global.button.trigger.frontend-redeploy'):
                dispatch(createTriggerJob(Tasks.FRONTEND_REDEPLOY))
                break;
            case t('global.button.trigger.upgrade'):
                dispatch(createTriggerJob(Tasks.UPGRADE))
                break;
            case t('connector_container.view.copy_url'):
                navigator.clipboard.writeText( props.additionalEntity != null
                    ? props.additionalEntity?.getFullUrl(props.instance)
                    : props.instance.getFullUrl() 
                )
                enqueueSnackbar(t('global.action.url_added_clipboard'), {variant: 'info'})
                break;
            case t('global.action.open_in_graylog'):
                window.open(props.instance.getGraylogUrl())
                break
            case t('instance.view.copy_api_url'):
                navigator.clipboard.writeText(props.instance.getApiUrl())
                enqueueSnackbar(t('global.action.url_added_clipboard'), {variant: 'info'})
                break;
            case t('instance.view.toggle_maintenance_mode'):
                dispatch(InstanceActions.CreateInstanceToggleMaintenance(props.instance.uuid))
                break;
            case t('global.button.redirect'):
                navigate([
                    `/instances/${props.instance.uuid}`, 
                    props.additionalEntity?.getInstanceDetailsUrl?.()
                ].filter(Boolean).join('/'))
                break;
            default:
                throw new Error('How did this happen');
        }
    }

    return (
        <MenuButton
            options={options}
            onClick={ handleClick }
            buttonIcon={ DotsVertical }
        />
    )
}

export class InstanceTableMenuButton extends CustomMenuButton {
    
    constructor(additionalEntity?: InstanceMenuable) {
        super('menu', 'instanceMenu', 'instance-menu', null, [], null)
        this.additionalEntity = additionalEntity;
    }

    render(row: any) {
        return (
            <InstanceMenu
                instance={'instance' in row ? row.instance : row}
                additionalEntity={ this.additionalEntity }
            />
        )
    }

}

export default InstanceMenu;