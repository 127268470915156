import { takeLatest, put } from 'redux-saga/effects';

import ActionTypes from "../config/ActionTypes";
import {SystemApi} from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import SystemStatusActions, { LoadBalancerStatus, RancherStatus } from 'reducers/types/SystemStatus';

export function* SystemStatusSagas() {
    yield takeLatest(ActionTypes.API_LOAD_BALANCER_STATUS, SystemStatusSaga.loadBalancerStatusWorkerSaga);
    yield takeLatest(ActionTypes.API_RANCHER_STATUS, SystemStatusSaga.rancherStatusWorkerSaga);
    yield takeLatest(ActionTypes.API_REDEPLOY_BACKOFFICE, SystemStatusSaga.redeployBackofficeWorkerSaga);
    yield takeLatest(ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE, SystemStatusSaga.redeployBackofficeStageWorkerSaga);
}

export class SystemStatusSaga {
    static *loadBalancerStatusWorkerSaga(action: LoadBalancerStatus) {
        try {
    
            // no arguments provided for this function
            const data = yield ApiHandler(new SystemApi().systemRetrieveLoadBalancerStatus);
    
            // dispatch a success action to the store with the activities
            yield put(SystemStatusActions.CreateLoadBalancerStatusSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_LOAD_BALANCER_STATUS_FAILURE, error as Error))
        }
    }
    
    static *rancherStatusWorkerSaga(action: RancherStatus) {
        try {
    
            // no arguments provided for this function
            const data = yield ApiHandler(new SystemApi().systemRetrieveRancherStatus);
    
            // dispatch a success action to the store with the activities
            yield put(SystemStatusActions.CreateRancherStatusSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_RANCHER_STATUS_FAILURE, error as Error))
        }
    }

    static *redeployBackofficeWorkerSaga() {
        try {
            // no arguments provided for this function
            const data = yield ApiHandler(new SystemApi().backofficeRedeploy);
    
            // dispatch a success action to the store with the activities
            yield put(SystemStatusActions.CreateRedeployBackofficeSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_REDEPLOY_BACKOFFICE_FAILURE, error as Error))
        }
    }

    static *redeployBackofficeStageWorkerSaga() {
        try {
            // no arguments provided for this function
            const data = yield ApiHandler(new SystemApi().backofficeStageRedeploy);
    
            // dispatch a success action to the store with the activities
            yield put(SystemStatusActions.CreateRedeployBackofficeStageSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE_FAILURE, error as Error))
        }
    }
}

