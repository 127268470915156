import React from 'react';
import AuthenticateActions from 'reducers/types/Authenticate';
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import { withTranslation } from 'react-i18next';
import SignInStyles from 'styles/SignInStyles';
import NotificationCenter from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import { withSnackbar } from 'notistack';
import i18next from 'i18next';
import TokenUtil from 'utils/TokenUtil';
import AppState from 'reducers/interface/AppState';
import Auth from 'api/override/AuthenticateModel';
import SignInForm from './components/SignInForm';
import { Navigate } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { withStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import EnvUtil from 'utils/EnvUtil';

interface StateProps {
    appReducer: AppState;
}

interface DispatchProps {
    signIn: (params: Auth) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State {
    youFuckedUp: boolean
}

class SignIn extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            youFuckedUp: false
        };
    }

    componentDidMount() {
        super.componentDidMount()
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this,
            ActionTypes.API_SIGN_IN_FAILURE,
            () => {this.props.enqueueSnackbar(i18next.t('global.notification.wrong_credentials'), {variant: 'error'}); this.setState({youFuckedUp: true})}
        )
    }

    handleSignIn = (values: Auth) => {
        this.props.signIn(values);
    };

    shouldSignOut = (): boolean => {
        return false
    }

    renderView() {
        const { classes } = this.props;
        const isLoggedIn = TokenUtil.getTokenUtil().isTokenExpired() === false;
        if(isLoggedIn) {
            return (<Navigate replace to="/dashboard"/>)
        } else {
            return (
                <Grid container className={classes.root} >
                    <div className={classes.image_section}>
                        <img src={EnvUtil.isDevelopment() ?
                            this.state.youFuckedUp ?
                                "/youdonefuckedup.jpg"
                                :
                                "/log-in-skeleton.webp" 
                            : 
                            "/auth-img.svg"} alt='nl-flag' style={EnvUtil.isDevelopment() ? {height: '100%'} : {}}/>
                    </div>
                    <div className={classes.form_section}>
                        <img width="140" src="/logo-bumbal.svg" alt="bumbal-logo"/>
                        <h1>{i18next.t('general.backoffice')}</h1>
                        <h3>{i18next.t('general.login')}</h3>
                        <SignInForm onFormSubmit={this.handleSignIn}/>
                    </div>
                </Grid>
            )
        }
    };
}

const mapStateToProps = (state: any) => ({
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (params: Auth) => dispatch((AuthenticateActions.CreateSignIn(params)))
    };
};

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(SignIn, SignInStyles)
            )
        )
    )
);
