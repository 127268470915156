import { ApiResponseModel, ApiSuccessResponseModel, HistoryResponseModel, InstanceConnectorListRequest, InstanceConnectorListResultModel, InstanceCreateSuccessModel, InstanceDeleteRequest, InstanceHistoryListRequest, InstanceListResultModel, InstanceRemoveSuccessModel, InstanceRetrieveListModel, InstanceSetImageVersionRequest, ProcessListResultModel, InstanceUpdateRequest, InstanceServiceListRequest, ServiceContainerListResultModel, ServiceContainerRetrieveListModel, InstanceCreateModelInterface, InstanceSettingsListResultModel, InstanceSettingsUpdateRequest, InstanceSettingsPostModel, InstanceAdminUpdateRequest, InstanceAuthenticateCreateTokenRequest, InstanceUsersRetrieveRequest, InstanceUsersListResultModel } from "api";
import Instance from "api/override/InstanceModel";
import InstanceSetting from "api/override/InstanceSettingModel";
import ActionTypes from "config/ActionTypes";
import { TableParams } from "models/table/TableParams";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface InstanceList extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_LIST;
    params: InstanceRetrieveListModel;
}

export interface InstanceListSuccess extends BaseSagaListResponse<InstanceListResultModel> {
    type: ActionTypes.API_INSTANCE_LIST_SUCCESS;
}

export interface InstanceRetrieve extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_RETRIEVE;
    params: string;
}

export interface InstanceRetrieveSuccess extends BaseSagaListResponse<Instance> {
    type: ActionTypes.API_INSTANCE_RETRIEVE_SUCCESS;
}

export interface InstanceCreate extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_CREATE;
    params: InstanceCreateModelInterface;
}

export interface InstanceCreateSuccess extends BaseSagaListResponse<InstanceCreateSuccessModel> {
    type: ActionTypes.API_INSTANCE_CREATE_SUCCESS;
}

export interface InstanceCreateFailureDomain {
    type: ActionTypes.API_INSTANCE_CREATE_FAILURE_DOMAIN;
    data: object;
}

export interface InstanceDelete extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_DELETE;
    params: InstanceDeleteRequest;
}

export interface InstanceDeleteSuccess extends BaseSagaListResponse<InstanceRemoveSuccessModel> {
    type: ActionTypes.API_INSTANCE_DELETE_SUCCESS;
}

export interface InstanceLogRequest {
    uuid: string;
    tableParams: TableParams;
}

export interface InstanceLogsRetrieve extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_LOGS_LIST;
    params: InstanceLogRequest;
}

export interface InstanceLogsRetrieveSuccess extends BaseSagaListResponse<ProcessListResultModel> {
    type: ActionTypes.API_INSTANCE_LOGS_LIST_SUCCESS;
}

export interface InstanceConnectorList extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_LIST_CONNECTORS;
    params: InstanceConnectorListRequest;
}

export interface InstanceConnectorListSuccess extends BaseSagaListResponse<InstanceConnectorListResultModel> {
    type: ActionTypes.API_INSTANCE_LIST_CONNECTORS_SUCCESS;
}

export interface InstanceServiceContainerList extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS;
    params: InstanceServiceListRequest;
}

export interface InstanceServiceContainerListSuccess extends BaseSagaListResponse<ServiceContainerListResultModel> {
    type: ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS_SUCCESS;
}

export interface InstanceTriggerJobParams {
    uuid: string;
    task: string;
    deleted?: boolean;
    uuids?: string[];
}

export interface InstanceTriggerJob extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_TRIGGER_JOB;
    params: InstanceTriggerJobParams;
}

export interface InstanceTriggerJobSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_INSTANCE_TRIGGER_JOB_SUCCESS;
}

export interface InstanceSetImageVersion extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_SET_IMAGE_VERSION;
    params: InstanceSetImageVersionRequest;
}

export interface InstanceUpdate extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_UPDATE;
    params: InstanceUpdateRequest;
}

export interface InstanceUpdateSuccess extends BaseSagaListResponse<InstanceCreateSuccessModel> {
    type: ActionTypes.API_INSTANCE_UPDATE_SUCCESS;
}

export interface InstanceSetImageVersionSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_SUCCESS;
}

export interface InstanceHistoryList extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_HISTORY_LIST;
    params: InstanceHistoryListRequest;
}

export interface InstanceHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_INSTANCE_HISTORY_LIST_SUCCESS;
}

export interface InstanceRetrieveSettings extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS;
    params: string;
}

export interface InstanceRetrieveSettingsSuccess extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS_SUCCESS;
    params: InstanceSettingsListResultModel;
}

export interface InstanceUpdateSettings extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_UPDATE_SETTINGS;
    params: InstanceSettingsUpdateRequest;
}

export interface InstanceUpdateSettingsSuccess extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_UPDATE_SETTINGS_SUCCESS;
    params: InstanceSettingsListResultModel;
}

export interface InstanceUpdateAdminPassword extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD;
    params: InstanceAdminUpdateRequest;
}

export interface InstanceUpdateAdminPasswordSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD_SUCCESS;
}

export interface InstanceAuthenticateCreateToken extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN;
    params: InstanceAuthenticateCreateTokenRequest;
}

export interface InstanceAuthenticateCreateTokenSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_SUCCESS;
}

export interface InstanceUsersRetrieve extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_USERS_RETRIEVE;
    params: InstanceUsersRetrieveRequest;
}

export interface InstanceUsersRetrieveSuccess extends BaseSagaListResponse<InstanceUsersListResultModel> {
    type: ActionTypes.API_INSTANCE_USERS_RETRIEVE_SUCCESS;
}

export interface InstanceClearListParams {
    force?: boolean;
}
export interface InstanceClearList extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_LIST_CLEAR;
    params?: InstanceClearListParams;
}

export interface InstanceToggleMaintenance extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE;
    params: string;
}

export interface InstanceToggleMaintenanceSuccess extends BaseReducerAction {
    type: ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE_SUCCESS;
    params: ApiSuccessResponseModel;
}

export default class InstanceActions {

    static CreateInstanceList(params: InstanceRetrieveListModel): InstanceList {
        return {
            type: ActionTypes.API_INSTANCE_LIST,
            params: params
        }
    }

    static CreateInstanceListSuccess(params: InstanceListResultModel): InstanceListSuccess {
        return {
            type: ActionTypes.API_INSTANCE_LIST_SUCCESS,
            params: params
        }
    }

    static CreateInstanceRetrieve(params: string): InstanceRetrieve {
        return {
            type: ActionTypes.API_INSTANCE_RETRIEVE,
            params: params
        }
    }

    static CreateInstanceRetrieveSuccess(params: Instance): InstanceRetrieveSuccess {
        return {
            type: ActionTypes.API_INSTANCE_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateInstanceCreate(params: Instance): InstanceCreate {
        return {
            type: ActionTypes.API_INSTANCE_CREATE,
            params: params
        }
    }

    static CreateInstanceCreateSuccess(params: InstanceCreateSuccessModel): InstanceCreateSuccess {
        return {
            type: ActionTypes.API_INSTANCE_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateInstanceCreateFailure(params: object): InstanceCreateFailureDomain {
        return {
            type: ActionTypes.API_INSTANCE_CREATE_FAILURE_DOMAIN,
            data: params
        }
    }

    static CreateInstanceUpdate(params: InstanceUpdateRequest): InstanceUpdate {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE,
            params: params
        }
    }

    static CreateInstanceUpdateSuccess(params: InstanceCreateSuccessModel): InstanceUpdateSuccess {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateInstanceDelete(params: InstanceDeleteRequest): InstanceDelete {
        return {
            type: ActionTypes.API_INSTANCE_DELETE,
            params: params
        }
    }

    static CreateInstanceDeleteSuccess(params: InstanceRemoveSuccessModel): InstanceDeleteSuccess {
        return {
            type: ActionTypes.API_INSTANCE_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateInstanceLogsRetrieve(uuid: string, params: TableParams): InstanceLogsRetrieve {
        return {
            type: ActionTypes.API_INSTANCE_LOGS_LIST,
            params: {
                uuid: uuid,
                tableParams: params
            }
        }
    }

    static CreateInstanceLogsRetrieveSuccess(params: ProcessListResultModel): InstanceLogsRetrieveSuccess {
        return {
            type: ActionTypes.API_INSTANCE_LOGS_LIST_SUCCESS,
            params: params
        }
    }

    static CreateInstanceConnectorList(params: InstanceConnectorListRequest): InstanceConnectorList {
        return {
            type: ActionTypes.API_INSTANCE_LIST_CONNECTORS,
            params: params
        }
    }

    static CreateInstanceConnectorListSuccess(params: InstanceConnectorListResultModel): InstanceConnectorListSuccess {
        return {
            type: ActionTypes.API_INSTANCE_LIST_CONNECTORS_SUCCESS,
            params: params
        }
    }
  

    static CreateInstanceServiceContainerList(params: ServiceContainerRetrieveListModel, instanceUuid: string): InstanceServiceContainerList {
        return {
            type: ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS,
            params: {
                uuid: instanceUuid,
                serviceContainerRetrieveListModel: params
            }
        }
    }

    static CreateInstanceServiceContainerListSuccess(params: ServiceContainerListResultModel): InstanceServiceContainerListSuccess {
        return {
            type: ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS_SUCCESS,
            params: params
        }
    }
    static CreateInstanceTriggerJob(params: InstanceTriggerJobParams): InstanceTriggerJob {
        return {
            type: ActionTypes.API_INSTANCE_TRIGGER_JOB,
            params: params
        }
    }

    static CreateInstanceTriggerJobSuccess(params: ApiResponseModel): InstanceTriggerJobSuccess {
        return {
            type: ActionTypes.API_INSTANCE_TRIGGER_JOB_SUCCESS,
            params: params
        }
    }

    static CreateClearConnectors(): BaseReducerAction {
        return {
            type: ActionTypes.INSTANCE_VIEW_CLEAR_CONNECTORS
        }
    }

    static CreateInstanceSetImageVersion(params: InstanceSetImageVersionRequest): InstanceSetImageVersion {
        return {
            type: ActionTypes.API_INSTANCE_SET_IMAGE_VERSION,
            params: params
        }
    }

    static CreateInstanceSetImageVersionSuccess(params: ApiSuccessResponseModel): InstanceSetImageVersionSuccess {
        return {
            type: ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_SUCCESS,
            params: params
        }
    }

    static CreateInstanceHistoryList(params: InstanceHistoryListRequest): InstanceHistoryList {
        return {
            type: ActionTypes.API_INSTANCE_HISTORY_LIST,
            params: params
        }
    }

    static CreateInstanceHistoryListSuccess(params: HistoryResponseModel): InstanceHistoryListSuccess {
        return {
            type: ActionTypes.API_INSTANCE_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearInstanceList(force: boolean = false): InstanceClearList {
        return {
            type: ActionTypes.API_INSTANCE_LIST_CLEAR,
            params: {force: force}
        }   
    }

    static CreateInstanceRetrieveSettings(params: string): InstanceRetrieveSettings {
        return {
            type: ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS,
            params: params
        }
    }

    static CreateInstanceRetrieveSettingsSuccess(params: InstanceSettingsListResultModel): InstanceRetrieveSettingsSuccess {
        return {
            type: ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS_SUCCESS,
            params: params
        }
    }

    static CreateInstanceUpdateSettings(instance: Instance, settings: InstanceSetting[]): InstanceUpdateSettings {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE_SETTINGS,
            params: {
                uuid: instance.uuid,
                instanceSettingsPostModel: new InstanceSettingsPostModel({
                    items: settings
                })
            }
        }
    }

    static CreateInstanceUpdateSettingsSuccess(params: InstanceSettingsListResultModel): InstanceUpdateSettingsSuccess {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE_SETTINGS_SUCCESS,
            params: params
        }
    }

    static CreateInstanceUpdateAdminPassword(params: InstanceAdminUpdateRequest): InstanceUpdateAdminPassword {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD,
            params: params
        }
    }

    static CreateInstanceUpdateAdminPasswordSuccess(params: ApiSuccessResponseModel): InstanceUpdateAdminPasswordSuccess {
        return {
            type: ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD_SUCCESS,
            params: params
        }
    }

    static CreateInstanceAuthenticateCreateToken(params: InstanceAuthenticateCreateTokenRequest): InstanceAuthenticateCreateToken {
        return {
            type: ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN,
            params: params
        }
    }

    static CreateInstanceAuthenticateCreateTokenSuccess(params: ApiSuccessResponseModel): InstanceAuthenticateCreateTokenSuccess {
        return {
            type: ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_SUCCESS,
            params: params
        }
    }

    static CreateInstanceUsersRetrieve(params: InstanceUsersRetrieveRequest): InstanceUsersRetrieve {
        return {
            type: ActionTypes.API_INSTANCE_USERS_RETRIEVE,
            params: params
        }
    }

    static CreateInstanceUsersRetrieveSuccess(params: InstanceUsersListResultModel): InstanceUsersRetrieveSuccess {
        return {
            type: ActionTypes.API_INSTANCE_USERS_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateInstanceToggleMaintenance(uuid: string): InstanceToggleMaintenance {
        return {
            type: ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE,
            params: uuid
        }
    }

    static CreateInstanceToggleMaintenanceSuccess(params: ApiSuccessResponseModel): InstanceToggleMaintenanceSuccess {
        return {
            type: ActionTypes.API_INSTANCE_TOGGLE_MAINTENANCE_MODE_SUCCESS,
            params: params
        }
    }

}