import React from 'react';
import MultiSelect from 'components/form_controls/MultiSelect';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import MultiSelectOption from 'interface/MultiSelectOption';
import SettingManager from 'utils/SettingManager';

interface DatabaseMultiSelectProps<T> {
    formik: FormikProps<T>;
}

const DatabaseMultiSelect = <T,>(props: DatabaseMultiSelectProps<T>) => {
    const currentDatabases = props.formik.values['json_databases'] as MultiSelectOption[] ?? [];
    const { t } = useTranslation()

    const setDatabases = (_, val: MultiSelectOption[]) => {
        props.formik.setFieldValue('json_databases', val);
    }

    const usableDatabases = SettingManager.default.getUsableDbs();
    const databases = (usableDatabases.getJsonValue() as string[]).map(val => { return { value: val, label: val } }) as MultiSelectOption[];

    return (
        <MultiSelect
            label={t('service.databases')}
            name="json_databases"
            value={currentDatabases}
            options={databases}
            chipProps={{ color: 'primary' }}
            onChange={setDatabases}
        />
    );
}

export default DatabaseMultiSelect;