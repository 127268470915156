import {ServiceSecretModel, ServiceSecretModelInterface, StringDateInterface} from "api";
import { Buffer } from "buffer";

export type FormattedSecret = {key: string, value: string};

export default class ServiceSecret extends ServiceSecretModel {

    actionName: string = "service_secret"
    reducerName: string = "service_secrets"

    general_formatted: Array<FormattedSecret>
    container_formatted: Array<FormattedSecret>

    constructor(data?: ServiceSecretModelInterface) {
        super(data);

        this.general_formatted = this.formatData('general')
        this.container_formatted = this.formatData('container')
    }

    formatData(objectName: string): Array<FormattedSecret> {
        return (Object.entries(this[objectName] ?? {}) as any).map(([key, value]) => {
            return {key: key, value: Buffer.from(value, 'base64').toString()}
        })
    }

    static toJson(initValue: ServiceSecret, value: StringDateInterface<ServiceSecret>): StringDateInterface<ServiceSecretModelInterface> {
        let generalData = {}
        let containerData = {}
        for (const formattedSecret of initValue.general_formatted) {
            generalData[formattedSecret.key] = Buffer.from(formattedSecret.value).toString('base64')
        }
        for (const formattedSecret of initValue.container_formatted) {
            containerData[formattedSecret.key] = Buffer.from(formattedSecret.value).toString('base64')
        }
        return { 
            ...value,
            general: generalData,
            container: containerData
        };
    }

}
