import React from "react";
import { PropsWithChildren } from "react"
import BaseModal, { BaseModalProps } from "./base/BaseModal"


const SmallModal: React.FC<BaseModalProps> = (props: PropsWithChildren<BaseModalProps>) => {

    return (
        <BaseModal
            {...props}
            maxWidth='sm'
        />
    )
}

export default SmallModal;