import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {SettingApi, SettingCreateRequest, SettingDeleteRequest, SettingHistoryListRequest, SettingListRequest, SettingRetrieveRequest, SettingUpdateRequest} from "api";
import { ApiHandler } from './APIHandler';
import SettingActions, { SettingCreate, SettingDelete, SettingHistoryList, SettingList, SettingRetrieve, SettingUpdate } from 'reducers/types/Setting';
import { AppActions } from 'reducers/types/App';
import Setting from 'api/override/SettingModel';

export function* SettingSagas() {
    yield takeLatest(ActionTypes.API_SETTING_UPDATE, SettingSaga.settingUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_SETTING_RETRIEVE, SettingSaga.settingRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_SETTING_LIST, SettingSaga.settingListWorkerSaga);
    yield takeLatest(ActionTypes.API_SETTING_CREATE, SettingSaga.settingCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_SETTING_DELETE, SettingSaga.settingDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_SETTING_HISTORY_LIST, SettingSaga.settingHistoryListWorkerSaga);
    
}
export class SettingSaga {
    static *settingUpdateWorkerSaga(action: SettingUpdate) {
        try {
            
            const data = yield ApiHandler(new SettingApi().settingUpdate, action.params as SettingUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(SettingActions.CreateSettingUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_SETTING_UPDATE_FAILURE, error as Error))
        }
    }

    static settingRetrieve = async(setting: string): Promise<Setting> => {
        return await new SettingApi().settingRetrieve({key: setting}) as Setting;
    }
    
    static *settingRetrieveWorkerSaga(action: SettingRetrieve) {
        try {
    
            const settingsArgs = {
                key: action.params
            } as SettingRetrieveRequest
            const data = yield ApiHandler(new SettingApi().settingRetrieve, settingsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(SettingActions.CreateSettingRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_SETTING_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *settingListWorkerSaga(action: SettingList) {
        try {
    
            const settingsArgs = {
                settingRetrieveListModel: action.params
            } as SettingListRequest
            const data = yield ApiHandler(new SettingApi().settingList, settingsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(SettingActions.CreateSettingListSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_SETTING_LIST_FAILURE, error as Error))
        }
    }
    
    static *settingCreateWorkerSaga(action: SettingCreate) {
        try {
    
            const settingsArgs = {
                settingModel: action.params
            } as SettingCreateRequest
            const data = yield ApiHandler(new SettingApi().settingCreate, settingsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(SettingActions.CreateSettingCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_SETTING_CREATE_FAILURE, error as Error))
        }
    }
    
    static *settingDeleteWorkerSaga(action: SettingDelete) {
        try {
            const settingsArgs = {
                key: action.params
            } as SettingDeleteRequest
            const data = yield ApiHandler(new SettingApi().settingDelete, settingsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(SettingActions.CreateSettingDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_SETTING_DELETE_FAILURE, error as Error))
        }
    }
    
    static *settingHistoryListWorkerSaga(action: SettingHistoryList) {
        try{
            const settingArgs = {
                key: action.params?.key,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as SettingHistoryListRequest
    
            const data = yield ApiHandler(new SettingApi().settingHistoryList, settingArgs);
            yield put(SettingActions.CreateSettingHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_USER_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

