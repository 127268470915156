import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Widget from 'api/override/WidgetModel';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveWidgetSchema } from 'schemas/Widget';
import WidgetForm from './WidgetForm';
import WidgetField from 'api/override/WidgetFieldModel';

interface WidgetCreateFormProps {
    onFormSubmit?: (value?: Widget) => void;
}

const WidgetCreateForm = (props: WidgetCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new Widget({
                name: '',
                icon: '',
                fields: [new WidgetField()]
            })}
            validationSchema={retrieveWidgetSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Widget>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('widgets.model')})}</h2>

                    <WidgetForm formik={formik}/>

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('widgets.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default WidgetCreateForm;