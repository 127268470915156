import React, { useState } from 'react';
import useFormBuilderStyles from 'styles/FormBuilderStyles';
import { useTranslation } from 'react-i18next';
import Form from 'api/override/FormModel';
import { CardContent, CardHeader, Divider, Grid, IconButton } from '@mui/material';
import { FormSelect } from 'mdi-material-ui';
import * as MDIcons from 'mdi-material-ui';
import { FormFieldModelTypeEnum } from 'api';
import PaneTitle from './PaneTitle';
import InputsPane from './InputsPane';
import PreviewPane from './PreviewPane';
import OptionsPane from './OptionsPane';
import FormField from 'api/override/FormFieldModel';
import { DirectionsEnum } from 'config/Constants';
import { Edit } from '@mui/icons-material';
import FormDetailsDialog from 'views/Dialog/FormDetailsDialog';
import ButtonStyled from 'components/styled/ButtonStyled';

interface FormBuilderProps {
    form: Form;
    onUpdate?: (form: Form) => void;
    onCreate?: (form: Form) => void;
    onUpdateTitle?: (form: Form) => void;
}

const FormBuilder = (props: FormBuilderProps) => {

    const { t } = useTranslation();
    const { form } = props;
    const {classes} = useFormBuilderStyles();

    const initialFormFields: Array<FormField> = form.id != null ? form.fields : []

    const [formInputs, setFormInputs] = useState<Array<FormField>>(initialFormFields)
    const [selectedFormField, setSelectedFormField] = useState<FormField | undefined>(undefined)
    const [isEditFormModalOpen, setIsEditFormModalOpen] = useState<boolean>(false)

    const cardHeaderTitleComponent: JSX.Element = (
        <div className={classes.header_container}>
            <div className={classes.header_title}>
                {form.id != null ? <h1>{form.title}</h1> : <h1>{t('general.title')}</h1>}
                {form.id != null ? <p>{form.description}</p> : <p>{t('general.description')}</p>}
            </div>
            <IconButton
                onClick={() => {setIsEditFormModalOpen(true)}}
                color="primary"
                size="large">
                <Edit/>
            </IconButton>
        </div>)
    
    const getHeaderIcon = (): JSX.Element => {
        const Icon = MDIcons[form.icon];
        return form.id != null ? <Icon/> : <FormSelect/>
    }
    const getHeaderAction = (): JSX.Element => form.id != null ? <ButtonStyled onClick={handleUpdateForm} className={classes.form_save_btn}>{t('global.button.save_changes')}</ButtonStyled> : <ButtonStyled onClick={handleCreateForm} className={classes.form_save_btn}>{t('global.button.create')}</ButtonStyled>

    const handleCreateForm = () => {
        props.onCreate(new Form())
    }

    const handleUpdateForm = () => {
        props.onUpdate(new Form({...form, fields: formInputs}))
    }

    const reassignPositionOnIndex = (arr: Array<FormField>): Array<FormField> => {
        arr.forEach((_, index) => {
            arr[index].position = index + 1
        })
        return arr;
    }

    const reorderInput = (direction: DirectionsEnum) => {
        const index: number = formInputs.findIndex(ff => ff.input_field_uuid === selectedFormField.input_field_uuid)
        const copyArr: Array<FormField> = formInputs.map(fi => fi.copy());
        let newArr: Array<FormField> = []
        switch (direction) {
            case DirectionsEnum.TOP:
                newArr = copyArr.bbRemoveAtIndex(index)
                newArr = [formInputs[index], ...newArr]
                break;
            case DirectionsEnum.UP:
                newArr =  copyArr.bbRemoveAtIndex(index)
                newArr = [
                    ...newArr.slice(0, index - 1),
                    formInputs[index],
                    ...newArr.slice(index - 1)
                ]
                break;
            case DirectionsEnum.DOWN:
                newArr =  copyArr.bbRemoveAtIndex(index)
                newArr = [
                    ...newArr.slice(0, index + 1),
                    formInputs[index],
                    ...newArr.slice(index + 1)
                ]
                break;
            case DirectionsEnum.BOTTOM:
                newArr = copyArr.bbRemoveAtIndex(index)
                newArr = newArr.concat([formInputs[index]])
                break;
            default:
                break;
        }
        const finalArr: Array<FormField> = reassignPositionOnIndex(newArr);
        setFormInputs(finalArr)
        setSelectedFormField(finalArr[ finalArr.findIndex(ff => ff.input_field_uuid === selectedFormField.input_field_uuid) ])
    }


    const onAddInput = (input: string) => {
        setFormInputs(formInputs.concat(new FormField({type: FormFieldModelTypeEnum[input], require: false, position: formInputs.length+1})))
    }

    const onSelectedInput = (field: FormField) => {
        setSelectedFormField(field)
    }

    const onRemoveInput = (fieldToRemove: FormField) => {
        const fieldIndex: number = formInputs.findIndex((formField: FormField) => formField.input_field_uuid === fieldToRemove.input_field_uuid)
        const newArr: Array<FormField> = formInputs.map(fi => fi.copy());
        setFormInputs(newArr.bbRemoveAtIndex(fieldIndex))
        if(selectedFormField.input_field_uuid === fieldToRemove.input_field_uuid) {
            setSelectedFormField(undefined) 
        }
    }

    const handleFieldChange = (newField: FormField) => {
        const fieldIndex: number = formInputs.findIndex((formField: FormField) => formField.input_field_uuid === newField?.input_field_uuid)
        const newArr: Array<FormField> = formInputs.map(fi => fi.copy());
        newArr[fieldIndex] = newField
        setFormInputs(newArr)
        setSelectedFormField(newField)
    }

    const closeModal = () => {
        setIsEditFormModalOpen(false)
    }

    const handleFormTitleUpdate = (newForm: Form) => {
        const formUpdated: Form = new Form({...form, title: newForm.title, description: newForm.description})
        props.onUpdateTitle(formUpdated)
        closeModal();
    }

    return (
        <>
            <CardHeader
                className={classes.header}
                avatar={getHeaderIcon()}
                title={cardHeaderTitleComponent}
                action={getHeaderAction()}
            />
            <CardContent>
                <Grid container direction="row" spacing={4} className={classes.builder_container} justifyContent='center'>
                    <Grid container item md={3} justifyContent='flex-start' direction='column'> 
                        <PaneTitle pane={'inputs'}/>
                        <InputsPane 
                            onAddInput={(input) => onAddInput(input)}
                        />
                        <Divider orientation="vertical" style={{marginLeft: 20}}/>
                    </Grid>
                    <Grid container item md={6} justifyContent='flex-start' direction='column'> 
                        <PaneTitle pane={'preview'}/>
                        <PreviewPane 
                            fields={formInputs} 
                            onSelectInput={onSelectedInput} 
                            onRemoveInput={onRemoveInput}
                        />
                        <Divider orientation="vertical"/>
                    </Grid>
                    <Grid container item md={3} justifyContent='flex-start' direction='column'>
                        <PaneTitle pane={'options'}/>
                        {selectedFormField != null &&
                            <OptionsPane 
                                field={selectedFormField} 
                                onChangeField={handleFieldChange} 
                                onReorder={reorderInput} 
                                formInputsLength={formInputs?.length ?? 0}
                            />
                        }
                    </Grid>
                </Grid>
            </CardContent>
            <FormDetailsDialog
                form={form}
                open={isEditFormModalOpen}
                onModalClosePress={closeModal}
                onModalSubmit={handleFormTitleUpdate}
            />
        </>
    );
};

export default FormBuilder;