import ActionTypes from "config/ActionTypes";
import Widget from 'api/override/WidgetModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import WidgetState from "./interface/WidgetState";
import { WidgetListSuccess, WidgetRetrieveSuccess } from "./types/Widget";

const initialState: WidgetState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0,
    isAjaxListLoading: false
};

export function WidgetReducers(state = initialState, action: BaseReducerAction): WidgetState {
    switch (action.type) {
        case ActionTypes.API_WIDGET_UPDATE:
        case ActionTypes.API_WIDGET_RETRIEVE:
        case ActionTypes.API_WIDGET_LIST:
        case ActionTypes.API_WIDGET_CREATE:
        case ActionTypes.API_WIDGET_DELETE:
        case ActionTypes.API_WIDGET_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_WIDGET_UPDATE_SUCCESS:
        case ActionTypes.API_WIDGET_CREATE_SUCCESS:
        case ActionTypes.API_WIDGET_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_WIDGET_UPDATE_FAILURE:
        case ActionTypes.API_WIDGET_CREATE_FAILURE:
        case ActionTypes.API_WIDGET_DELETE_FAILURE:
        case ActionTypes.API_WIDGET_LIST_FAILURE:
        case ActionTypes.API_WIDGET_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_WIDGET_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as WidgetRetrieveSuccess).params as Widget,
                error: undefined
            };

        case ActionTypes.API_WIDGET_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_WIDGET_LIST_SUCCESS:
            const result = action as WidgetListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Widget>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_WIDGET_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
            
        case ActionTypes.API_WIDGET_AJAX_LIST:
        case ActionTypes.API_WIDGET_AJAX_LIST_SUCCESS:
            return {
                ...state,
                isAjaxListLoading: !action.type.toString().includes('SUCCESS')
            }

        default:
            return state;
    }
}