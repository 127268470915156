/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConditionOptionModel,
    ConditionOptionModelFromJSON,
    ConditionOptionModelFromJSONTyped,
    ConditionOptionModelToJSON,
} from './';


export interface ConditionModelInterface {
    id?: number;
    stepview_id?: number;
    model?: string;
    condition_on?: string;
    is_bool?: boolean;
    option?: ConditionOptionModel;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface ConditionModel
 */
export class ConditionModel extends BaseModel<ConditionModelInterface> {

    /**
     * id of the condition
     * @type {number}
     * @memberof ConditionModel
     */
    id?: number;
    /**
     * The stepview id this condition is bound to
     * @type {number}
     * @memberof ConditionModel
     */
    stepview_id?: number;
    /**
     * The model this condition is checking
     * @type {string}
     * @memberof ConditionModel
     */
    model?: string;
    /**
     * The variable that needs checking of the model
     * @type {string}
     * @memberof ConditionModel
     */
    condition_on?: string;
    /**
     * The condition is a boolean condition
     * @type {boolean}
     * @memberof ConditionModel
     */
    is_bool?: boolean;
    /**
     * 
     * @type {ConditionOptionModel}
     * @memberof ConditionModel
     */
    option?: ConditionOptionModel;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConditionModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConditionModel
     */
    updated_at?: Date;

    constructor(data?: ConditionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConditionModel, value: StringDateInterface<ConditionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConditionModelFromJSON(json: any): ConditionModel {
    return ConditionModelFromJSONTyped(json, false);
}

export function ConditionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConditionModel;
    try {
        let localClass = require.context('../override', true)('./ConditionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'stepview_id': !exists(json, 'stepview_id') ? undefined : Number(json['stepview_id']),
        'model': !exists(json, 'model') ? undefined : json['model'],
        'condition_on': !exists(json, 'condition_on') ? undefined : json['condition_on'],
        'is_bool': !exists(json, 'is_bool') ? undefined : json['is_bool'],
        'option': !exists(json, 'option') ? undefined : ConditionOptionModelFromJSON(json['option']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function ConditionModelToJSON(value?: ConditionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConditionModel;
    try {
        let localClass = require.context('../override', true)('./ConditionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'stepview_id': value.stepview_id,
        'model': value.model,
        'condition_on': value.condition_on,
        'is_bool': value.is_bool,
        'option': ConditionOptionModelToJSON(value.option),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<ConditionModel>;
    return createClass.toJson(value, model);
}


