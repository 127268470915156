import ActionTypes from "config/ActionTypes";
import Process from 'api/override/ProcessModel';
import BaseReducerAction from "./interface/ReducerAction";
import TaskMonitorState from "./interface/TaskMonitorState";
import { ErrorInterface } from "./types/App";
import { TaskMonitorListSuccess } from "./types/TaskMonitor";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";

const initialState: TaskMonitorState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    error: null,
    timestampLastList: 0
};

export function TaskMonitorReducers(state = initialState, action: BaseReducerAction) {
    switch (action.type) {
        case ActionTypes.API_TASK_MONITOR_LIST_TASKS:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_TASK_MONITOR_LIST_TASKS_SUCCESS:
            return {
                ...state,
                collection: {
                    items: (action as TaskMonitorListSuccess).params.items as Process[],
                    count_unfiltered: action.params.count_unfiltered,
                    count_filtered: action.params.count_filtered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_TASK_MONITOR_UPDATE_SUCCESS:
            return {
                ...state,
                error: null
            };
            
        case ActionTypes.API_TASK_MONITOR_LIST_TASKS_FAILURE: 
            return {
                ...state,
                error: (action as ErrorInterface).params
            }
        
        case ActionTypes.API_TASK_MONITOR_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Process,
                error: null
            };
            
        case ActionTypes.API_TASK_LIST_CLEAR:
            if(TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)){
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }
        
        default:
            return state;
    }
}