/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ClusterCapacityModelInterface {
    cpu?: string | null;
    memory?: string | null;
    pods?: string | null;
}

/**
 * 
 * @export
 * @interface ClusterCapacityModel
 */
export class ClusterCapacityModel extends BaseModel<ClusterCapacityModelInterface> {

    /**
     * value describing CPU
     * @type {string}
     * @memberof ClusterCapacityModel
     */
    cpu?: string | null;
    /**
     * value describing Memory
     * @type {string}
     * @memberof ClusterCapacityModel
     */
    memory?: string | null;
    /**
     * value describing Pods
     * @type {string}
     * @memberof ClusterCapacityModel
     */
    pods?: string | null;

    constructor(data?: ClusterCapacityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ClusterCapacityModel, value: StringDateInterface<ClusterCapacityModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ClusterCapacityModelFromJSON(json: any): ClusterCapacityModel {
    return ClusterCapacityModelFromJSONTyped(json, false);
}

export function ClusterCapacityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterCapacityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ClusterCapacityModel;
    try {
        let localClass = require.context('../override', true)('./ClusterCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'memory': !exists(json, 'memory') ? undefined : json['memory'],
        'pods': !exists(json, 'pods') ? undefined : json['pods'],
    });
}

export function ClusterCapacityModelToJSON(value?: ClusterCapacityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ClusterCapacityModel;
    try {
        let localClass = require.context('../override', true)('./ClusterCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'cpu': value.cpu,
        'memory': value.memory,
        'pods': value.pods,
    } as StringDateInterface<ClusterCapacityModel>;
    return createClass.toJson(value, model);
}


