/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    InstanceSettingModel,
    InstanceSettingModelFromJSON,
    InstanceSettingModelFromJSONTyped,
    InstanceSettingModelToJSON,
    InstanceSettingsListResultModelAllOf,
    InstanceSettingsListResultModelAllOfFromJSON,
    InstanceSettingsListResultModelAllOfFromJSONTyped,
    InstanceSettingsListResultModelAllOfToJSON,
} from './';


export interface InstanceSettingsListResultModelInterface {
    message?: string;
    code?: number;
    data?: Array<InstanceSettingModel>;
    success?: boolean;
}

/**
 * 
 * @export
 * @interface InstanceSettingsListResultModel
 */
export class InstanceSettingsListResultModel extends BaseModel<InstanceSettingsListResultModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof InstanceSettingsListResultModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof InstanceSettingsListResultModel
     */
    code?: number;
    /**
     * 
     * @type {Array<InstanceSettingModel>}
     * @memberof InstanceSettingsListResultModel
     */
    data?: Array<InstanceSettingModel>;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof InstanceSettingsListResultModel
     */
    success?: boolean;

    constructor(data?: InstanceSettingsListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSettingsListResultModel, value: StringDateInterface<InstanceSettingsListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSettingsListResultModelFromJSON(json: any): InstanceSettingsListResultModel {
    return InstanceSettingsListResultModelFromJSONTyped(json, false);
}

export function InstanceSettingsListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSettingsListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSettingsListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InstanceSettingModelFromJSON)),
        'success': !exists(json, 'success') ? undefined : json['success'],
    });
}

export function InstanceSettingsListResultModelToJSON(value?: InstanceSettingsListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSettingsListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InstanceSettingModelToJSON)),
        'success': value.success,
    } as StringDateInterface<InstanceSettingsListResultModel>;
    return createClass.toJson(value, model);
}


