import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import { TrialListRequest, TrialApi, TrialHistoryListRequest, TrialCancelRequest, TrialUpdateRequest } from 'api';
import TrialActions, { TrialHistoryList, TrialList, TrialUpdate, TrialCancel } from 'reducers/types/Trial';

export function* TrialSagas() {
    yield takeLatest(ActionTypes.API_TRIAL_LIST, TrialSaga.trialListWorkerSaga);
    yield takeLatest(ActionTypes.API_TRIAL_HISTORY_LIST, TrialSaga.trialHistoryListWorkerSaga);
    yield takeLatest(ActionTypes.API_TRIAL_UPDATE, TrialSaga.trialUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_TRIAL_CANCEL, TrialSaga.trialCancelWorkerSaga);
}

export class TrialSaga {
    
    static *trialListWorkerSaga(action: TrialList) {
        try {
            const trialsArgs = {
                trialRetrieveListModel: action.params
            } as TrialListRequest

            const data = yield ApiHandler(new TrialApi().trialList, trialsArgs)
            yield put(TrialActions.CreateTrialListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_TRIAL_LIST_FAILURE, error as Error))
        }
    }
    
    static *trialHistoryListWorkerSaga(action: TrialHistoryList) {
        try{
            const trialArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as TrialHistoryListRequest
    
            const data = yield ApiHandler(new TrialApi().trialHistoryList, trialArgs);
            yield put(TrialActions.CreateTrialHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_TRIAL_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static *trialCancelWorkerSaga(action: TrialCancel) {
        try{
            const trialArgs = {
                id: action.params?.id
            } as TrialCancelRequest
    
            const data = yield ApiHandler(new TrialApi().trialCancel, trialArgs);
            yield put(TrialActions.CreateTrialCancelSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_TRIAL_CANCEL_FAILURE, error as Error))
        }
    }

    static *trialUpdateWorkerSaga(action: TrialUpdate) {
        try{
            const trialArgs = {
                id: action.params?.id
            } as TrialUpdateRequest
    
            const data = yield ApiHandler(new TrialApi().trialUpdate, trialArgs);
            yield put(TrialActions.CreateTrialUpdateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_TRIAL_UPDATE_FAILURE, error as Error))
        }
    }
}

