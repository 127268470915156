/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageModel,
    ImageModelFromJSON,
    ImageModelFromJSONTyped,
    ImageModelToJSON,
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelFromJSONTyped,
    ImageVersionModelToJSON,
} from './';


export interface ConnectorModelInterface {
    id?: number;
    status?: string;
    uuid?: string;
    name?: string;
    url?: string;
    namespace?: string;
    image_id?: string;
    image?: ImageModel;
    redirect_uri?: string | null;
    redirect_image_id?: number | null;
    redirect_image?: ImageModel;
    redirect_image_version_id?: number | null;
    redirect_image_version?: ImageVersionModel;
    text?: string | null;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface ConnectorModel
 */
export class ConnectorModel extends BaseModel<ConnectorModelInterface> {

    /**
     * id of the Connector
     * @type {number}
     * @memberof ConnectorModel
     */
    id?: number;
    /**
     * Last known status of the connector
     * @type {string}
     * @memberof ConnectorModel
     */
    status?: string;
    /**
     * Unique per connector
     * @type {string}
     * @memberof ConnectorModel
     */
    uuid?: string;
    /**
     * Name of this connector
     * @type {string}
     * @memberof ConnectorModel
     */
    name?: string;
    /**
     * Url of this connector
     * @type {string}
     * @memberof ConnectorModel
     */
    url?: string;
    /**
     * Namespace of the connector
     * @type {string}
     * @memberof ConnectorModel
     */
    namespace?: string;
    /**
     * id of the image
     * @type {string}
     * @memberof ConnectorModel
     */
    image_id?: string;
    /**
     * 
     * @type {ImageModel}
     * @memberof ConnectorModel
     */
    image?: ImageModel;
    /**
     * Redirect URI for OAuth
     * @type {string}
     * @memberof ConnectorModel
     */
    redirect_uri?: string | null;
    /**
     * Id of the redirect model
     * @type {number}
     * @memberof ConnectorModel
     */
    redirect_image_id?: number | null;
    /**
     * 
     * @type {ImageModel}
     * @memberof ConnectorModel
     */
    redirect_image?: ImageModel;
    /**
     * Id of the redirect image version model
     * @type {number}
     * @memberof ConnectorModel
     */
    redirect_image_version_id?: number | null;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof ConnectorModel
     */
    redirect_image_version?: ImageVersionModel;
    /**
     * WYSWYG text about the connector
     * @type {string}
     * @memberof ConnectorModel
     */
    text?: string | null;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConnectorModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConnectorModel
     */
    updated_at?: Date;

    constructor(data?: ConnectorModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorModel, value: StringDateInterface<ConnectorModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorModelFromJSON(json: any): ConnectorModel {
    return ConnectorModelFromJSONTyped(json, false);
}

export function ConnectorModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'image_id': !exists(json, 'image_id') ? undefined : json['image_id'],
        'image': !exists(json, 'image') ? undefined : ImageModelFromJSON(json['image']),
        'redirect_uri': !exists(json, 'redirect_uri') ? undefined : json['redirect_uri'],
        'redirect_image_id': !exists(json, 'redirect_image_id') ? undefined : Number(json['redirect_image_id']),
        'redirect_image': !exists(json, 'redirect_image') ? undefined : ImageModelFromJSON(json['redirect_image']),
        'redirect_image_version_id': !exists(json, 'redirect_image_version_id') ? undefined : Number(json['redirect_image_version_id']),
        'redirect_image_version': !exists(json, 'redirect_image_version') ? undefined : ImageVersionModelFromJSON(json['redirect_image_version']),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function ConnectorModelToJSON(value?: ConnectorModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'status': value.status,
        'uuid': value.uuid,
        'name': value.name,
        'url': value.url,
        'namespace': value.namespace,
        'image_id': value.image_id,
        'image': ImageModelToJSON(value.image),
        'redirect_uri': value.redirect_uri,
        'redirect_image_id': value.redirect_image_id,
        'redirect_image': ImageModelToJSON(value.redirect_image),
        'redirect_image_version_id': value.redirect_image_version_id,
        'redirect_image_version': ImageVersionModelToJSON(value.redirect_image_version),
        'text': value.text,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<ConnectorModel>;
    return createClass.toJson(value, model);
}


