import ActionTypes from "config/ActionTypes";
import Mail from 'api/override/MailModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import MailState from "./interface/MailState";
import { MailListSuccess, MailRetrieveSuccess } from "./types/Mail";

const initialState: MailState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function MailReducers(state = initialState, action: BaseReducerAction): MailState {
    switch (action.type) {
        case ActionTypes.API_MAIL_UPDATE:
        case ActionTypes.API_MAIL_RETRIEVE:
        case ActionTypes.API_MAIL_LIST:
        case ActionTypes.API_MAIL_CREATE:
        case ActionTypes.API_MAIL_DELETE:
        case ActionTypes.API_MAIL_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_MAIL_UPDATE_SUCCESS:
        case ActionTypes.API_MAIL_CREATE_SUCCESS:
        case ActionTypes.API_MAIL_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_MAIL_UPDATE_FAILURE:
        case ActionTypes.API_MAIL_CREATE_FAILURE:
        case ActionTypes.API_MAIL_DELETE_FAILURE:
        case ActionTypes.API_MAIL_LIST_FAILURE:
        case ActionTypes.API_MAIL_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_MAIL_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as MailRetrieveSuccess).params as Mail,
                error: undefined
            };

        case ActionTypes.API_MAIL_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_MAIL_LIST_SUCCESS:
            const result = action as MailListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Mail>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_MAIL_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}