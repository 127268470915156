import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import { HistoryListRequest } from 'api';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import CustomActionButton from 'models/table/CustomActionButton';
import ReloadDataButton from 'components/ReloadDataButton';
import HistoryActions from 'reducers/types/History';
import HistoryState from 'reducers/interface/HistoryState';
import { History } from 'mdi-material-ui';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import RequestLog from 'api/override/RequestLogModel';
import TableHeader from 'components/TableHeader';
import SystemController from 'controllers/SystemController';

interface StateProps {
    historyReducer: HistoryState;
    appReducer: AppState;
}

interface DispatchProps {
    listHistory: (params: HistoryListRequest) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    history?: RequestLog;
}

class HistoryList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customButtons: CustomActionButton[];
    filterColumns: FilterColumn[] = [];
    isFetchingFilters = false;
    
    constructor(props) {
        super(props);

        this.state = {
            history: null
        };
        this.configFilters();
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'message', isSortable: false, label: i18next.t('history.message')}),
            new TableColumn({ key: 'user', isSortable: true, label: i18next.t('users.model'), customValue: (row: RequestLog) =>  row?.user?.full_name}),
            new TableColumn({ key: 'namespace', isSortable: true, label: i18next.t('history.namespace') }),
            new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('connector.date'), customValue: (row: RequestLog) => row?.createdDate()})
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_HISTORY_LIST_FAILURE
        ]);
        this.updateTabTitle(i18next.t('history.model'));
        this.configFilters();
    }

    configFilters = async() => {
        if (!this.isFetchingFilters) {
            this.isFetchingFilters = true;
            let namespaceFilters: Record<string, string> = {};
            for (const mso of await new SystemController().fetchNamespaces()) {
                namespaceFilters[ mso.value ] = mso.label;
            } 
            this.filterColumns = [
                FilterColumn.makeFilter('namespace', FilterTypes.ENUM, i18next.t('history.namespace'), namespaceFilters)
            ]
            this.setFilters([]);
            this.isFetchingFilters = false;
            this.forceUpdate();
        }
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns
    }

    getData = () => {
        if(this.getParams() != null) {
            this.props.listHistory({ historyRetrieveListModel: this.getParams().getApiFilter() });
        }
    }

    getItemCount = (): number => {
        return this.props.historyReducer.collection.items.length
    }
    
    onDetails = (item: RequestLog) => {
        window.open(`https://logging.bumbal.eu/search?q=%22${item.token}%22&rangetype=relative&relative=0`) //open link in a new tab
    }

    renderView() {
        const { classes, appReducer, historyReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.getData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('history.model')}
                            headerIcon={<History/>}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('history.model')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('history.model')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.getFilterColumns() } 
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={historyReducer.collection.count_filtered}
                                    listItems={historyReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_HISTORY_LIST)}
                                    delegate={ this }
                                    tableParams={this.getParams()}
                                    customActions={this.customButtons}
                                    idColumn={ 'token' } /> 
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    historyReducer: state.history,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listHistory: (params: HistoryListRequest) => dispatch(HistoryActions.CreateHistoryList(params)),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(HistoryList, TableStyles)
            )
        )
    )
);