import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import Wizard from 'api/override/WizardModel';
import WizardActions, { WizardCreate, WizardDelete, WizardHistoryList, WizardList, WizardRetrieve, WizardUpdate } from 'reducers/types/Wizard';
import { WizardListRequest, WizardApi, WizardUpdateRequest, WizardRetrieveRequest, WizardCreateRequest, WizardDeleteRequest, WizardHistoryListRequest } from 'api/apis/WizardApi';

export function* WizardSagas() {
    yield takeLatest(ActionTypes.API_WIZARD_LIST, WizardSaga.wizardListWorkerSaga);
    yield takeLatest(ActionTypes.API_WIZARD_UPDATE, WizardSaga.wizardUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_WIZARD_RETRIEVE, WizardSaga.wizardRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_WIZARD_CREATE, WizardSaga.wizardCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_WIZARD_DELETE, WizardSaga.wizardDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_WIZARD_HISTORY_LIST, WizardSaga.wizardHistoryListWorkerSaga);
}

export class WizardSaga {
    
    static *wizardListWorkerSaga(action: WizardList) {
        try {
            const wizardsArgs = {
                wizardRetrieveListModel: action.params
            } as WizardListRequest

            const data = yield ApiHandler(new WizardApi().wizardList, wizardsArgs)
            yield put(WizardActions.CreateWizardListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_WIZARD_LIST_FAILURE, error as Error))
        }
    }

    static *wizardUpdateWorkerSaga(action: WizardUpdate) {
        try {
            
            const data = yield ApiHandler(new WizardApi().wizardUpdate, action.params as WizardUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(WizardActions.CreateWizardUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIZARD_UPDATE_FAILURE, error as Error))
        }
    }

    static wizardRetrieve = async(wizard: number): Promise<Wizard> => {
        return await new WizardApi().wizardRetrieve({id: wizard}) as Wizard;
    }
    
    static *wizardRetrieveWorkerSaga(action: WizardRetrieve) {
        try {
    
            const wizardsArgs = {
                id: action.params
            } as WizardRetrieveRequest
            const data = yield ApiHandler(new WizardApi().wizardRetrieve, wizardsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WizardActions.CreateWizardRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIZARD_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *wizardCreateWorkerSaga(action: WizardCreate) {
        try {
    
            const wizardsArgs = {
                wizardModel: action.params
            } as WizardCreateRequest
            const data = yield ApiHandler(new WizardApi().wizardCreate, wizardsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WizardActions.CreateWizardCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIZARD_CREATE_FAILURE, error as Error))
        }
    }
    
    static *wizardDeleteWorkerSaga(action: WizardDelete) {
        try {
            const wizardsArgs = {
                id: action.params
            } as WizardDeleteRequest
            const data = yield ApiHandler(new WizardApi().wizardDelete, wizardsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(WizardActions.CreateWizardDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_WIZARD_DELETE_FAILURE, error as Error))
        }
    }
    
    static *wizardHistoryListWorkerSaga(action: WizardHistoryList) {
        try{
            const wizardArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as WizardHistoryListRequest
    
            const data = yield ApiHandler(new WizardApi().wizardHistoryList, wizardArgs);
            yield put(WizardActions.CreateWizardHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_WIZARD_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

