/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    HistoryRetrieveListModel,
    HistoryRetrieveListModelFromJSON,
    HistoryRetrieveListModelToJSON,
} from '../models';

export interface HistoryListRequest {
    historyRetrieveListModel: HistoryRetrieveListModel;
}

/**
 * 
 */
export class HistoryApi extends runtime.BaseAPI {

    /**
     * retrieve a list of historical logs
     * retrieve a list of historical logs
     */
    async historyListRaw(requestParameters: HistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.historyRetrieveListModel === null || requestParameters.historyRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyRetrieveListModel','Required parameter requestParameters.historyRetrieveListModel was null or undefined when calling historyList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/history/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryRetrieveListModelToJSON(requestParameters.historyRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs
     * retrieve a list of historical logs
     */
    historyList = async(requestParameters: HistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.historyListRaw(requestParameters);
        return await response.value();
    }

}
