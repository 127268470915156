import { IconButton } from '@mui/material';
import { ChevronDown } from 'mdi-material-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTrialSectionHeaderStyles from 'styles/TrialSectionHeaderStyles';

interface TrialSectionHeaderProps {
    onExpand: () => void;
    isOpen?: boolean;
}

const TrialSectionHeader = (props: TrialSectionHeaderProps) => {

    const { t } = useTranslation();
    const { onExpand, isOpen } = props;
    const {classes} = useTrialSectionHeaderStyles();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const handleCollapseClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsCollapsed(!isCollapsed);
        onExpand()
    }
    
    return (
        <div className={`${classes.root} ${isOpen === true ? 'open' : 'accepted'}`}>
            <p>{isOpen === true ? t('general.open') : t('general.accepted')}</p>
            <IconButton onClick={handleCollapseClick} size="large">
                <ChevronDown className={`${classes.chevron} ${isCollapsed !== true && 'up'}`}/>
            </IconButton>
        </div>
    );
};

export default TrialSectionHeader;
