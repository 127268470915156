import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Trial from 'api/override/TrialModel';
import Client from 'api/override/ClientModel';
import useTrialCardStyles from 'styles/TrialCardStyles';
import { Divider, Grid, IconButton } from '@mui/material';
import { Details, EmailArrowRightOutline, Web } from 'mdi-material-ui';
import { useNavigate } from "react-router-dom";
import DetailsField from 'components/DetailsField';
import format from 'date-fns/format';
import { differenceInHours } from 'date-fns';
import SettingManager from 'utils/SettingManager';

interface AcceptedTrialCardProps {
    client: Client;
}

const AcceptedTrialCard = (props: AcceptedTrialCardProps) => {
    const { t } = useTranslation();
    const { client } = props;
    const navigate = useNavigate();
    const {classes} = useTrialCardStyles();
    const trial: Trial = client.getActiveTrial()
    const [bccEmail, setBccEmail] = useState<string>('')
    const [subjectEmail, setSubjectEmail] = useState<string>('')
    useEffect(() => {
        setBccEmail(SettingManager.default.getClientEmailBcc().value)
        setSubjectEmail(SettingManager.default.getClientEmailSubject().value)
    }, [])
    const formatDate = (date: Date): string => format(date, 'do MMM yyyy')

    const calculateDaysDifference = (startDate: Date): number => {
        return Math.floor(differenceInHours(new Date(), startDate)/24)
    }

    const handleClick = (actionName: string) => { // so not elegant, would have been MUCH MORE ELEGANT with a curried function, but Ted said and I quote "NO!"
        switch (actionName) {
            case 'details':
                navigate(`/instances/${trial.instance_uuid}`)
                break;
            case 'email':
                document.location.href = `mailto:${client.email}?bcc=${bccEmail}&subject=${subjectEmail}`
                break;
            case 'website':
                window.open(client.getWebsiteUrl())
                break;
            default:
                break;
        }
    }

    const marginStyle = {margin: 0}
    const alignEndStyle = {...marginStyle, padding: 0, alignItems: 'flex-end'}
    // Grid complains about putting justify on elements not marked as container
    const justifyStart = {justifyContent: 'flex-start'}
    const justifyEnd = {justifyContent: 'flex-end'}
    const justifySpaceBetween = {justifyContent: 'space-between'}

    return (
        <Grid item xs={12} sm={12} md={6} lg={12} style={justifySpaceBetween} className={`${classes.root} accepted`}>
            <Grid container className={classes.acceptedContainer}>
                <Grid container item xs={10} spacing={1} direction='column' justifyContent='center'>
                    <Grid container item>
                        <Grid item xs={12} style={justifyStart}>
                            <p className={classes.client_name}>{client.name}</p>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={4} style={justifyStart}>
                            <DetailsField propKey={t('clients.phone')} propVal={client.phone} containerStyles={marginStyle}/>
                        </Grid>
                        <Grid item xs={4} style={justifyEnd}>
                            <DetailsField propKey={t('trials.started')} propVal={formatDate(client.requested_at)} containerStyles={alignEndStyle}/>
                        </Grid>
                        <Grid item xs={4} style={justifyEnd}>
                            <DetailsField propKey={t('trials.ends')} propVal={formatDate(client.requested_at.bbAddDays(30))} containerStyles={alignEndStyle}/>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={12} style={justifyStart}>
                            <DetailsField 
                                propKey={t('trials.progress')} 
                                propVal={`${calculateDaysDifference(client.requested_at)}/30`} 
                                progress={{value: calculateDaysDifference(client.requested_at), total: 30}} 
                                containerStyles={marginStyle} 
                            />
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid container item xs={2} justifyContent='flex-end'>
                    <Divider className={`${classes.client_row_divider} accepted`}/>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <IconButton onClick={() => handleClick('details')} size="large">
                            <Details/>
                        </IconButton>
                        <IconButton onClick={() => handleClick('email')} size="large">
                            <EmailArrowRightOutline />
                        </IconButton>
                        <IconButton onClick={() => handleClick('website')} size="large">
                            <Web/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AcceptedTrialCard;
