import { ServiceContainerApi, ServiceContainerCreateRequest, ServiceContainerDeleteRequest, ServiceContainerRetrieveRequest, ServiceContainerSecretsRetrieveRequest, ServiceContainerSecretsUpdateRequest, ServiceContainerTasksRequest, ServiceContainerUpdateRequest } from 'api/apis/ServiceContainerApi';
import { AppActions } from 'reducers/types/App';
import ServiceContainerActions, { ServiceContainerRetrieve, ServiceContainerCreate, ServiceContainerDelete, ServiceContainerUpdate, ServiceContainerHistoryList, ServiceContainerTriggerJob, ServiceContainerUpdateSecrets, ServiceContainerRetrieveSecrets } from 'reducers/types/ServiceContainer';
import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';

export function* ServiceContainerSagas() {
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_RETRIEVE, ServiceContainerSaga.serviceContainerRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_CREATE, ServiceContainerSaga.serviceContainerCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_DELETE, ServiceContainerSaga.serviceContainerDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_UPDATE, ServiceContainerSaga.serviceContainerUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST, ServiceContainerSaga.serviceContainerHistoryListWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB, ServiceContainerSaga.serviceContainerTriggerJobWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS, ServiceContainerSaga.serviceContainerRetrieveSecretsWorkerSaga);
    yield takeLatest(ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS, ServiceContainerSaga.serviceContainerUpdateSecretsWorkerSaga);
}

class ServiceContainerSaga {
    static *serviceContainerRetrieveWorkerSaga(action: ServiceContainerRetrieve) {
        try {

            const servicesArgs = {
                uuid: action.params
            } as ServiceContainerRetrieveRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerRetrieve, servicesArgs)

            // dispatch a success action to the store with the activities
            yield put(ServiceContainerActions.CreateServiceContainerRetrieveSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_FAILURE, error as Error))
        }
    }

    static *serviceContainerCreateWorkerSaga(action: ServiceContainerCreate) {

        try {
            const servicesArgs = {
                serviceContainerModel: action.params
            } as ServiceContainerCreateRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerCreate, servicesArgs)

            // dispatch a success action to the store with the activities
            yield put(ServiceContainerActions.CreateServiceContainerCreateSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_CREATE_FAILURE, error as Error))
        }
    }

    static *serviceContainerRetrieveSecretsWorkerSaga(action: ServiceContainerRetrieveSecrets) {
        try {
            const servicesArgs = {
                uuid: action.params.uuid
            } as ServiceContainerSecretsRetrieveRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerSecretsRetrieve, servicesArgs)
            
            // dispatch a success action to the store with the activities
            yield put(ServiceContainerActions.CreateServiceContainerRetrieveSecretsSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS_FAILURE, error as Error))
        }
    }

    static *serviceContainerUpdateSecretsWorkerSaga(action: ServiceContainerUpdateSecrets) {
        try {
            const servicesArgs = {
                uuid: action.params.uuid,
                serviceSecretModel: action.params.serviceSecretModel
            } as ServiceContainerSecretsUpdateRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerSecretsUpdate, servicesArgs)

            yield put(ServiceContainerActions.CreateServiceContainerUpdateSecretsSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS_FAILURE, error as Error))
        }
    }

    static *serviceContainerDeleteWorkerSaga(action: ServiceContainerDelete) {
        try {
            const servicesArgs = {
                uuid: action.params
            } as ServiceContainerDeleteRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerDelete, servicesArgs)

            // dispatch a success action to the store with the activities
            yield put(ServiceContainerActions.CreateServiceContainerDeleteSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_DELETE_FAILURE, error as Error))
        }
    }

    static *serviceContainerUpdateWorkerSaga(action: ServiceContainerUpdate) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                serviceContainerModel: action.params
            } as ServiceContainerUpdateRequest

            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerUpdate, ccArgs)
            yield put(ServiceContainerActions.CreateServiceContainerUpdateSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_UPDATE_FAILURE, error as Error))
        }
    }

    static * serviceContainerHistoryListWorkerSaga(action: ServiceContainerHistoryList) {
        try {
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerHistoryList, action.params);
            yield put(ServiceContainerActions.CreateServiceContainerHistoryListSuccess(data))
        }
        catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static *serviceContainerTriggerJobWorkerSaga(action: ServiceContainerTriggerJob) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                task: action.params.task,
                uuids: action.params.uuids?.bbIsEmpty() ? undefined : action.params.uuids
            } as ServiceContainerTasksRequest
            const data = yield ApiHandler(new ServiceContainerApi().serviceContainerTasks, ccArgs)
            yield put(ServiceContainerActions.CreateServiceContainerTriggerJobSuccess(data))
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB_FAILURE, error as Error))
        }
    }
   
}