import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import { FormikProps } from 'formik';
import Condition from 'api/override/ConditionModel';
import BranchConditionForm from './BranchConditionForm';

interface BranchConditionUpdateDialogProps extends BaseFormModalProps<Condition> {
    condition: Condition
}

const BranchConditionUpdateDialog = (props: BranchConditionUpdateDialogProps) => {

    const {condition} = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props}
            title={t('global.action.update_model', {model: t('branches.model')})}
            subtitle={t('conditions.set')}
            icon={'SourceBranchPlus'}
            initialValues={condition}
            action={ActionTypes.API_CONDITION_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik: FormikProps<Condition>) => 
                <div className={classes.fields_container}>
                    <BranchConditionForm formik={formik} />
                </div>
            }
        />
    )
};

export default BranchConditionUpdateDialog;