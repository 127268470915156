import React, { ReactNode } from 'react'
import { TextFieldProps, TextField} from '@mui/material'
import useDialogStyles from 'styles/DialogStyles';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

interface Props<T> {
    shouldShowNameHelperText?: boolean,
    propertyName?: string,
    formik?: FormikProps<T>
}

export type TextFieldStyledProps<T> = Props<T> & TextFieldProps

const TextFieldStyled = <T, >(props: TextFieldStyledProps<T>) => {
    const {classes} = useDialogStyles();
    const {t} = useTranslation();

    const { shouldShowNameHelperText, propertyName, formik, ...textFieldProps } = props;

    const getHelperText = (): ReactNode | string => {
        if (Boolean(props.helperText)) {
            return props.helperText;
        } else if (shouldShowNameHelperText) {
            return t('general.name_field_helper_text');
        } else {
            return undefined;
        }
    }

    const getPropsByPropName = (): Object => {
        if (propertyName === undefined) {
            return {}
        }
        const propObject = {
            id: propertyName,
            name: propertyName,
            value: formik.values?.[`${propertyName}`],
            error: formik.touched?.[`${propertyName}`] && Boolean(formik.errors?.[`${propertyName}`]),
            helperText: formik.touched?.[`${propertyName}`] ? formik.errors?.[`${propertyName}`] : "",
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
        }
        return propObject
    }
    
    return (
        <TextField 
            variant="outlined" 
            color="primary" 
            InputProps={{ inputProps: {'data-lpignore': true, ...props.inputProps} }} 
            className={classes.text_field} 
            helperText={getHelperText()}
            {...getPropsByPropName()}
            {...textFieldProps}
        />
    )
}

export default React.memo(TextFieldStyled)