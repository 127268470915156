import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import ClientActions, { ClientCreate, ClientDelete, ClientHistoryList, ClientList, ClientRetrieve, ClientStatusUpdate, ClientUpdate } from 'reducers/types/Client';
import { ClientListRequest, ClientApi, ClientUpdateRequest, ClientRetrieveRequest, ClientCreateRequest, ClientDeleteRequest, ClientHistoryListRequest, ClientStatusUpdateRequest } from 'api';
import Client from 'reducers/types/Client';

export function* ClientSagas() {
    yield takeLatest(ActionTypes.API_CLIENT_LIST, ClientSaga.clientListWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_UPDATE, ClientSaga.clientUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_STATUS_UPDATE, ClientSaga.clientStatusUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_RETRIEVE, ClientSaga.clientRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_CREATE, ClientSaga.clientCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_DELETE, ClientSaga.clientDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_CLIENT_HISTORY_LIST, ClientSaga.clientHistoryListWorkerSaga);
}
export class ClientSaga {
    
    static *clientListWorkerSaga(action: ClientList) {
        try {
            const clientsArgs = {
                clientRetrieveListModel: action.params
            } as ClientListRequest

            const data = yield ApiHandler(new ClientApi().clientList, clientsArgs)
            yield put(ClientActions.CreateClientListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_LIST_FAILURE, error as Error))
        }
    }

    static *clientUpdateWorkerSaga(action: ClientUpdate) {
        try {
            
            const data = yield ApiHandler(new ClientApi().clientUpdate, action.params as ClientUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(ClientActions.CreateClientUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_UPDATE_FAILURE, error as Error))
        }
    }

    static *clientStatusUpdateWorkerSaga(action: ClientStatusUpdate) {
        try {

            const clientsArgs = {
                id: action.params.id,
                clientStatusModel: action.params.clientStatusModel
            } as ClientStatusUpdateRequest
            
            const data = yield ApiHandler(new ClientApi().clientStatusUpdate, clientsArgs);
    
            // dispatch a success action to the store with the activities
            yield put(ClientActions.CreateClientStatusUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_STATUS_UPDATE_FAILURE, error as Error))
        }
    }

    static clientRetrieve = async(client: number): Promise<Client> => {
        return await new ClientApi().clientRetrieve({id: client});
    }
    
    static *clientRetrieveWorkerSaga(action: ClientRetrieve) {
        try {
    
            const clientsArgs = {
                id: action.params
            } as ClientRetrieveRequest
            const data = yield ApiHandler(new ClientApi().clientRetrieve, clientsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ClientActions.CreateClientRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *clientCreateWorkerSaga(action: ClientCreate) {
        try {
    
            const clientsArgs = {
                clientModel: action.params
            } as ClientCreateRequest
            const data = yield ApiHandler(new ClientApi().clientCreate, clientsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ClientActions.CreateClientCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_CREATE_FAILURE, error as Error))
        }
    }
    
    static *clientDeleteWorkerSaga(action: ClientDelete) {
        try {
            const clientsArgs = {
                id: action.params
            } as ClientDeleteRequest
            const data = yield ApiHandler(new ClientApi().clientDelete, clientsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(ClientActions.CreateClientDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CLIENT_DELETE_FAILURE, error as Error))
        }
    }
    
    static *clientHistoryListWorkerSaga(action: ClientHistoryList) {
        try{
            const clientArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ClientHistoryListRequest
    
            const data = yield ApiHandler(new ClientApi().clientHistoryList, clientArgs);
            yield put(ClientActions.CreateClientHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CLIENT_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

