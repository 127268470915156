import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import StepviewWidget from 'api/override/StepviewWidgetModel';
import StepviewWidgetActions, { StepviewWidgetCreate, StepviewWidgetDelete, StepviewWidgetHistoryList, StepviewWidgetList, StepviewWidgetRetrieve, StepviewWidgetUpdate } from 'reducers/types/StepviewWidget';
import { StepviewWidgetListRequest, StepviewWidgetApi, StepviewWidgetUpdateRequest, StepviewWidgetRetrieveRequest, StepviewWidgetCreateRequest, StepviewWidgetDeleteRequest, StepviewWidgetHistoryListRequest } from 'api/apis/StepviewWidgetApi';
import { TableParams } from 'models/table/TableParams';
import { StepviewWidgetListResultModel } from 'api';

export function* StepviewWidgetSagas() {
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_LIST, StepviewWidgetSaga.stepviewWidgetListWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_UPDATE, StepviewWidgetSaga.stepviewWidgetUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE, StepviewWidgetSaga.stepviewWidgetRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_CREATE, StepviewWidgetSaga.stepviewWidgetCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_DELETE, StepviewWidgetSaga.stepviewWidgetDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST, StepviewWidgetSaga.stepviewWidgetHistoryListWorkerSaga);
}

export class StepviewWidgetSaga {
    
    static *stepviewWidgetListWorkerSaga(action: StepviewWidgetList) {
        try {
            const stepviewWidgetsArgs = {
                stepviewWidgetRetrieveListModel: action.params
            } as StepviewWidgetListRequest

            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetList, stepviewWidgetsArgs)
            yield put(StepviewWidgetActions.CreateStepviewWidgetListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_LIST_FAILURE, error as Error))
        }
    }

    static *stepviewWidgetUpdateWorkerSaga(action: StepviewWidgetUpdate) {
        try {
            
            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetUpdate, action.params as StepviewWidgetUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(StepviewWidgetActions.CreateStepviewWidgetUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_UPDATE_FAILURE, error as Error))
        }
    }

    static stepviewWidgetRetrieve = async(stepviewWidget: number): Promise<StepviewWidget> => {
        return await new StepviewWidgetApi().stepviewWidgetRetrieve({id: stepviewWidget}) as StepviewWidget;
    }
    
    static *stepviewWidgetRetrieveWorkerSaga(action: StepviewWidgetRetrieve) {
        try {
    
            const stepviewWidgetsArgs = {
                id: action.params
            } as StepviewWidgetRetrieveRequest
            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetRetrieve, stepviewWidgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewWidgetActions.CreateStepviewWidgetRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *stepviewWidgetCreateWorkerSaga(action: StepviewWidgetCreate) {
        try {
    
            const stepviewWidgetsArgs = {
                stepviewWidgetModel: action.params
            } as StepviewWidgetCreateRequest
            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetCreate, stepviewWidgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewWidgetActions.CreateStepviewWidgetCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_CREATE_FAILURE, error as Error))
        }
    }
    
    static *stepviewWidgetDeleteWorkerSaga(action: StepviewWidgetDelete) {
        try {
            const stepviewWidgetsArgs = {
                id: action.params
            } as StepviewWidgetDeleteRequest
            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetDelete, stepviewWidgetsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(StepviewWidgetActions.CreateStepviewWidgetDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_DELETE_FAILURE, error as Error))
        }
    }
    
    static *stepviewWidgetHistoryListWorkerSaga(action: StepviewWidgetHistoryList) {
        try{
            const stepviewWidgetArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as StepviewWidgetHistoryListRequest
    
            const data = yield ApiHandler(new StepviewWidgetApi().stepviewWidgetHistoryList, stepviewWidgetArgs);
            yield put(StepviewWidgetActions.CreateStepviewWidgetHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static async stepviewWidgetList (tableParams?: TableParams): Promise<StepviewWidgetListResultModel> {
        const retrieveListRequest = {
            stepviewWidgetRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as StepviewWidgetListRequest;
        return await new StepviewWidgetApi().stepviewWidgetList(retrieveListRequest);
    }
}

