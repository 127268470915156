import { ApiSuccessResponseModel, ConnectorInstanceListResultModel, ConnectorInstanceRetrieveListModel, GetConfigModel, FileUploadModel, PostConfigModel, ConnectorContainerCreateSuccessModel, ConnectorContainerRemoveSuccessModel, ApiResponseModel, ConnectorContainerHistoryListRequest, HistoryResponseModel, ConnectorContainerUpdateModelInterface } from "api";
import ConnectorContainer from "api/override/ConnectorContainerModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface ConnectorContainerList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_LIST;
    params: ConnectorInstanceRetrieveListModel;
}

export interface ConnectorContainerListSuccess extends BaseSagaListResponse<ConnectorInstanceListResultModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_LIST_SUCCESS;
}

export interface ConnectorContainerCreate extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_CREATE;
    params: ConnectorContainer;
}

export interface ConnectorContainerCreateSuccess extends BaseSagaListResponse<ConnectorContainerCreateSuccessModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_CREATE_SUCCESS;
}

export interface ConnectorContainerRetrieve extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE;
    params: string;
}

export interface ConnectorContainerRetrieveSuccess extends BaseSagaListResponse<ConnectorContainer> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS
}

export interface ConnectorContainerDelete extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_DELETE;
    params: string;
}

export interface ConnectorContainerSetConfigParams {
    uuid: string;
    config: PostConfigModel;
}

export interface ConnectorContainerSetConfig extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG;
    params: ConnectorContainerSetConfigParams
}

export interface ConnectorContainerSetConfigSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS;
}

export interface ConnectorContainerGetConfig extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG;
    params: string
}

export interface ConnectorContainerGetConfigSuccess extends BaseSagaListResponse<GetConfigModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS;
}

export interface ConnectorContainerDeleteSuccess extends BaseSagaListResponse<ConnectorContainerRemoveSuccessModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_DELETE_SUCCESS;
}

export interface SetConfigFilesParams {
    uuid: string;
    fileUploadModel: FileUploadModel;
}
export interface ConnectorContainerSetConfigFiles extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES;
    params: SetConfigFilesParams;
}

export interface ConnectorContainerSetConfigFilesSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_SUCCESS;
}

export interface ConnectorContainerTriggerJobParams {
    uuid: string;
    task: string;
    uuids?: string[];
    dbName?: string;
}

export interface ConnectorContainerTriggerJob extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB;
    params: ConnectorContainerTriggerJobParams;
}

export interface ConnectorContainerTriggerJobSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB_SUCCESS;
}

export interface ConnectorContainerUpdateParams extends ConnectorContainerUpdateModelInterface {
    uuid: string;
}

export interface ConnectorContainerUpdate extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_UPDATE;
    params: ConnectorContainerUpdateParams
}

export interface ConnectorContainerUpdateSuccess extends BaseSagaListResponse<ConnectorContainerCreateSuccessModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_SUCCESS;
}

export interface ConnectorContainerHistoryList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST;
    params: ConnectorContainerHistoryListRequest;
}

export interface ConnectorContainerHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST_SUCCESS;
}

export interface ConnectorContainerReset extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_RESET;
    params: string;
}

export interface ConnectorContainerResetSuccess extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CONTAINER_RESET_SUCCESS
}

export default class ConnectorContainerActions {
    
    static CreateConnectorContainerList(params: ConnectorInstanceRetrieveListModel): ConnectorContainerList {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_LIST,
            params: params
        }
    }

    static CreateConnectorContainerListSuccess(params: ConnectorInstanceListResultModel): ConnectorContainerListSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_LIST_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerCreate(params: ConnectorContainer): ConnectorContainerCreate {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_CREATE,
            params: params
        }
    }

    static CreateConnectorContainerCreateSuccess(params: ConnectorContainerCreateSuccessModel): ConnectorContainerCreateSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerRetrieve(params: string): ConnectorContainerRetrieve {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE,
            params: params
        }
    }

    static CreateConnectorContainerRetrieveSuccess(params: ConnectorContainer): ConnectorContainerRetrieveSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerDelete(params: string): ConnectorContainerDelete {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_DELETE,
            params: params
        }
    }

    static CreateConnectorContainerDeleteSuccess(params: ConnectorContainerRemoveSuccessModel): ConnectorContainerDeleteSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerGetConfig(uuid: string): ConnectorContainerGetConfig {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG,
            params: uuid
        }
    }

    static CreateConnectorContainerGetConfigSuccess(params: GetConfigModel): ConnectorContainerGetConfigSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerSetConfig(params: ConnectorContainerSetConfigParams): ConnectorContainerSetConfig {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG,
            params: params
        }
    }

    static CreateConnectorContainerSetConfigSuccess(params: ApiSuccessResponseModel): ConnectorContainerSetConfigSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerSetConfigFiles(params: SetConfigFilesParams): ConnectorContainerSetConfigFiles {
        return {
            type:  ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES,
            params: params
        }
    }

    static CreateConnectorContainerSetConfigFilesSuccess(params: ApiSuccessResponseModel): ConnectorContainerSetConfigFilesSuccess {
        return {
            type:  ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerTriggerJob(params: ConnectorContainerTriggerJobParams): ConnectorContainerTriggerJob {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB,
            params: params
        }
    }

    static CreateConnectorContainerTriggerJobSuccess(params: ApiResponseModel): ConnectorContainerTriggerJobSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerUpdate(params: ConnectorContainerUpdateParams): ConnectorContainerUpdate {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_UPDATE,
            params: params
        }
    }

    static CreateConnectorContainerUpdateSuccess(params: ConnectorContainerCreateSuccessModel): ConnectorContainerUpdateSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerHistoryList(params: ConnectorContainerHistoryListRequest): ConnectorContainerHistoryList {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST,
            params: params
        }
    }

    static CreateConnectorContainerHistoryListSuccess(params: HistoryResponseModel): ConnectorContainerHistoryListSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateConnectorContainerReset(params: string): ConnectorContainerReset {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_RESET,
            params: params
        }
    }

    static CreateConnectorContainerResetSuccess(): ConnectorContainerResetSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CONTAINER_RESET_SUCCESS
        }
    }

}