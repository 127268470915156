import Widget from "api/override/WidgetModel";
import { TableParams } from "models/table/TableParams";
import { WidgetSaga } from "sagas/WidgetSagas";
import Log from "utils/Log";
import BaseController from "./BaseController";

export default class WidgetController extends BaseController<Widget> {

    listWidgets = async (params?: TableParams): Promise<Array<Widget>> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await WidgetSaga.widgetList(params);
                this.cacheResponseOfType(response.items as Array<Widget> ?? [], params);
            }
            return this.getItemsOfTypeForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }
}