import { FormLabel, Grid, IconButton } from '@mui/material';
import Service from 'api/override/ServiceModel';
import ArrayGridItem from 'components/ArrayGridItem';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { Minus, Plus } from 'mdi-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDialogStyles from 'styles/DialogStyles';
import useServiceStyles from 'styles/ServiceStyles';

export interface ServicePathFormProps {
    formik: FormikProps<Service>;
}

const ServicePathForm = (props: ServicePathFormProps) => {
    const {classes} = useServiceStyles();
    const dialogStyles = useDialogStyles().classes;
    const {t} = useTranslation();
    const {formik} = props;
    const btn = (arrayHelpers: FieldArrayRenderProps) => (
        <IconButton
            className={dialogStyles.add_arr_object_button}
            onClick={() => arrayHelpers.push('')}
            size="large">
            <Plus />
        </IconButton>
    )
    const renderRow = (type: string, arrayHelpers: FieldArrayRenderProps) => (volume: string, index: number) => {
        const fieldName = `volumeModel.${type}[${index}]`;
        return (
            <div key={`${type}_${index}`} style={{display: 'flex', alignItems: 'center'}}>
                <TextFieldStyled
                    placeholder='/path'
                    name={fieldName}
                    value={volume}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <IconButton
                    className={dialogStyles.remove_arr_item_button}
                    onClick={() => arrayHelpers.remove(index)}
                    size="large">
                    <Minus/>
                </IconButton>
            </div>
        )
    }
    return (
        <Grid container className={classes.volume_container}>
            <FormLabel>{t('service.view.path_registrations')}</FormLabel>
            <ArrayGridItem title={t('service.view.public')}>
                <FieldArray name="volumeModel.publicVolumes" render={arrayHelpers => (
                    <div>
                        {props.formik.values.volumeModel.publicVolumes.map(renderRow('publicVolumes', arrayHelpers))}
                        {btn(arrayHelpers)}
                    </div>
                )} />
            </ArrayGridItem>
            <ArrayGridItem title={t('service.view.private')}>
                <FieldArray name="volumeModel.privateVolumes" render={arrayHelpers => (
                    <div>
                        {props.formik.values.volumeModel.privateVolumes.map(renderRow('privateVolumes', arrayHelpers))}
                        {btn(arrayHelpers)}
                    </div>
                )} />
            </ArrayGridItem>
            <ArrayGridItem title={t('service.view.config')}>
                <FieldArray name="volumeModel.configVolumes" render={arrayHelpers => (
                    <div>
                        {props.formik.values.volumeModel.configVolumes.map(renderRow('configVolumes', arrayHelpers))}
                        {btn(arrayHelpers)}
                    </div>
                )} />
            </ArrayGridItem>
        </Grid>
    )
}

export default ServicePathForm;