import { FormFieldModel, FormFieldModelInterface } from "api/models/FormFieldModel";
import { nanoid } from "nanoid";

interface FFMI extends FormFieldModelInterface {
    input_field_uuid?: string;
}

export default class FormField extends FormFieldModel {

    input_field_uuid: string

    constructor(data?: FFMI) {
        super(data);
        this.input_field_uuid = data?.input_field_uuid ?? nanoid()
    }
    
    public copy(): FormField {
        return new FormField({
            ...this
        })
    }

    getOptions(): Array<string> {
        return Boolean(this.options) ? JSON.parse(this.options) : ['']
    }

}