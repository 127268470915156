import { PaletteMode } from '@mui/material';
import LocalStorageManager from 'utils/LocalStorageManager';


const getPrimary = (): string => {
    if(LocalStorageManager.getThemeAccentPrimary() != null) {
        return LocalStorageManager.getThemeAccentPrimary()
    }
    return '#14A5FF'
}

const getSecondary = (): string => {
    if(LocalStorageManager.getThemeAccentSecondary() != null) {
        return LocalStorageManager.getThemeAccentSecondary()
    }
    return '#ffcb08'
}

const getBorderAccentEnabled = (): boolean => {
    if (LocalStorageManager.getBorderAccentEnabled() === 'on'){
        return true
    }
    return false
}


export const getDesignTokens = (mode: PaletteMode) => {
    if (mode.toString() === 'light') {
        return {
            palette: {
                mode,
                primary: {
                    main: getPrimary(),
                    contrastText: '#fff'
                },
                secondary: {
                    main: getSecondary(),
                    dark: '#0776E8',
                    contrastText: '#fff'
                },
                warning: {
                    main: '#ffa633',
                    dark: '#ea7900',
                    contrastText: '#fff'
                },
                error: {
                    main: '#FF6663',
                    dark: '#C43E3B',
                    contrastText: '#fff'
                },
                success: {
                    main: '#00c80a',
                    dark: '#17a41d',
                    contrastText: '#fff'
                },
                background: {
                    paper: '#fafafa'
                },
            },
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: '#ffffff91',
                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                            border: getBorderAccentEnabled() ? `2px solid ${getPrimary()}59` : '2px solid rgba(236, 236, 236, 1)',
                            backdropFilter: 'blur(5px)',
                            borderRadius: 10,
                        }
                    }
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            boxShadow: getBorderAccentEnabled() ? `0 1px ${getPrimary()}59` : '0 1px rgba(236, 236, 236, 1)',
                        }
                    }
                },
            }
        }
    } else {
        return {
            palette: {
                mode,
                primary: {
                    main: getPrimary(),
                    contrastText: '#fff'
                },
                secondary: {
                    main: getSecondary(),
                    dark: '#0776E8',
                    contrastText: '#fff'
                },
                warning: {
                    main: '#ffa633',
                    dark: '#ea7900',
                    contrastText: '#fff'
                },
                error: {
                    main: '#ff1f1b',
                    dark: '#b4100d',
                    contrastText: '#fff'
                },
                success: {
                    main: '#00c80a',
                    dark: '#17a41d',
                    contrastText: '#fff'
                },
                background: {
                    paper: '#1d1d1d',
                },
                                
            },
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: '#2a2a2a63',
                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                            border: getBorderAccentEnabled() ? `2px solid ${getPrimary()}59` : '2px solid #313131',
                            backdropFilter: 'blur(5px)',
                            borderRadius: 10,
                        }
                    }
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            boxShadow: getBorderAccentEnabled() ? `0 1px ${getPrimary()}59` : `0 1px #313131`,
                        }
                    }
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        input: {
                            '&:-webkit-autofill': {
                                boxShadow: `0 0 0 100px #2c2c2c inset`
                            }
                        }
                    }
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            backgroundImage: 'linear-gradient(rgb(255 255 255 / 6%), rgb(255 255 255 / 4%))',
                            backgroundColor: '#1a1a1a'
                        }
                    }
                },
                MenuDrawer: {
                    styleOverrides: {
                        paper: {
                            backgroundImage: 'none',
                            backgroundColor: '#1a1a1a'
                        }
                    }
                }
            }
        }
    }
}

const themeBackOffice = {
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontFamily: 'Open Sans',
        fontSize: 12
    },
    zIndex: {
        drawer: 1500,
        modal: 1300,
        snackbar: 1400,
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#2c2c2c'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    '&.Mui-selected': {
                        color: getPrimary(),
                        fontWeight: 700
                    }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    textTransform: 'capitalize',
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#0090B2',
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                select: {
                    paddingTop: 7,
                    paddingBottom: 6,
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    marginTop: 0,
                    marginBottom: 2,
                    marginLeft: 0,
                    marginRight: 0
                },
                avatar: {
                    marginRight: 10
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.01)'
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#f1f5fd'
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#edf3ff'
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#f1f5fd'
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#edf3ff'
                    }
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root:{
                    marginTop: 30,
                    padding: '12px 4px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginBottom: 80
                }
            }
        }
    },
};

export default themeBackOffice