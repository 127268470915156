import { makeStyles } from 'tss-react/mui';
import {keyframes} from "tss-react";
import { Theme } from '@mui/material/styles';

const moveTruck = keyframes`
    0% { left: -400px; } 
    40% { transform: rotate(0deg); } 
    60% { left: 220px; top: 70px; } 
    85% { transform: rotate(60deg); } 
    100% { left: 250px; top: 400px; }
`

const use404PageStyles = makeStyles()((theme: Theme) => {
    return {
        container: {
            minHeight: '90vh',
            minWidth: '90wv',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > h1': {
                fontSize: 40
            }
        },
        animation_container: {
            position: 'relative',
            height: 270,
            width: 400,
            overflow: 'hidden',
            padding: '20px 0',
            background: '#ffdec6',
            borderRadius: 10,
            boxShadow: '0 0 14px 4px rgba(0, 0, 0, 0.08)',
            marginTop: 30,
            transition: 'background .5s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > button': {
                display: 'none',
                zIndex: 200,
                fontSize: 16
            },
            '&:hover': {
                transition: 'filter .5s',
                background: '#e7e2dd',
                '& #truck': {
                    transition: 'filter .5s',
                    filter: 'grayscale(80%)',
                },
                '& #cliff': {
                    transition: 'filter .5s',
                    filter: 'grayscale(80%)',
                },
                '& #sun': {
                    transition: 'filter .5s',
                    filter: 'grayscale(80%)',
                },
                '& > button': {
                    display: 'block',
                }
            }
        },
        sun: {
            height: 150,
            width: 150,
            borderRadius: 150,
            background: '#ffd481',
            position: 'absolute',
            top: -35,
            right: -55
        },
        cliff: {
            position: 'absolute',
            bottom: -39,
            left: 0
        },
        truck: {
            position: 'absolute',
            top: 70,
            left: -400,
            animation: `${moveTruck} 3s infinite 0.5s ease-in-out`,
        }
    }
})

export default use404PageStyles
