import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import ImageVersion from 'api/override/ImageVersionModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ReadOnlyField from 'components/ReadOnlyField';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface ImageVersionDetailsDialogProps extends BaseFormModalProps<ImageVersion> {
    imageVersion?: ImageVersion;
}

const ImageVersionDetailsDialog = (props: ImageVersionDetailsDialogProps) => {

    const { imageVersion } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('image_versions.model')})}
            initialValues={new ImageVersion({
                ...imageVersion
            })}
            action={ActionTypes.API_IMAGE_VERSION_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="version_name" 
                        name="version_name"
                        label={t('image_versions.version_name')}
                        value={formik.values.version_name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('image_versions.description')}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                    <ReadOnlyField 
                        id="version_tag" 
                        name="version_tag"
                        label={t('image_versions.version_tag')}
                        value={formik.values.version_tag}
                        onChange={formik.handleChange}
                    />

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.latest,
                            onChange: formik.handleChange,
                            id: 'latest',
                            name: 'latest'
                        }}
                        label={t('image_versions.latest')}
                    />

                </div>
            }
        />
    );
};

export default ImageVersionDetailsDialog;