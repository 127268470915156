import React, { PropsWithChildren } from 'react';
import {useTranslation} from "react-i18next";
import SmallModal from 'components/SmallModal';
import DeleteDialogActions from 'components/dialog_actions/DeleteDialogActions';

interface ConfirmDeleteDialogProps {
    open: boolean;
    onModalClosePress: () => void;
    onConfirm: () => void;
    title?: string
}

const ConfirmDeleteDialog = (props: PropsWithChildren<ConfirmDeleteDialogProps>) => {
    const { t } = useTranslation();
    const { onModalClosePress, open, onConfirm, title } = props;

    return (
        <SmallModal open={open} onModalClosePress={onModalClosePress} title={title ?? t('global.title.delete_confirm')}>
            <DeleteDialogActions onModalClosePress={props.onModalClosePress} onConfirm={onConfirm}/>
        </SmallModal>
    );
};

export default ConfirmDeleteDialog;