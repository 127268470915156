export default class TerminalParams {

    readonly command: string;
    params: Record<string, string | boolean>

    constructor(command: string) {
        this.command = command;
        let params = {};
        params['main'] = command.split(' -').shift().trim();
        let splitCommand = command.split(' -').splice(1, command.split(' -').length - 1).map(str => ` -${str}`).join('').trim().split(' ');
        let flags = [];
        for (let i = 0; i < splitCommand.length; ++i) {
            // cast boolean if one is filled in
            if (splitCommand[i] === 'true' || splitCommand[i] === 'false') {
                flags = flags.concat(splitCommand[i] === 'true');
            } else {
                flags = flags.concat(splitCommand[i]);
            }
            if (splitCommand[i].toString().charAt(0) === '-') { // check if we have a value or if it is a boolean flag
                if (splitCommand[i + 1] == null || splitCommand[i + 1]?.toString().charAt(0) === '-') {
                    flags = flags.concat(true);
                }
            }
        }
        for (let i = 0; i < flags.length; i+=2) {
            const key = flags[i].replace('-', '');
            const value = flags[i + 1];
            params[ key ] = typeof value === 'string' ? value.trim() : value;
        }
        this.params = params;
        if (!Boolean(this.main())) {
            throw new Error('No main set!');
        }
    }

    public get(key: string, fallback?: string | boolean): string | boolean {
        return this.params[key] || fallback;
    }

    public string(key: string, fallback?: string): string {
        return this.get(key, fallback) as string;
    }

    public boolean(key: string, fallback: boolean = false): boolean {
        return Boolean(this.get(key, fallback));
    }

    public number(key: string, fallback?: number): number {
        return parseInt(this.string(key, ''+ fallback));
    }

    public main(): string {
        return this.string('main');
    }

    public mainPath(): string {
        return this.string('main').split(' ').shift();
    }

    public mainParam(): string {
        return this.string('main')
            .split(' ')
            .splice(1, this.string('main').split(' ').length - 1)
            .join(' ')
    }

    public hasMainParam(): boolean {
        return Boolean(this.mainParam()) && this.mainParam() !== 'help';
    }

    public hasValue(key: string): boolean {
        return Boolean(this.get(key, false));
    }

}