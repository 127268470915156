/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ConnectorListFilterModelInterface {
    image?: string;
}

/**
 * 
 * @export
 * @interface ConnectorListFilterModel
 */
export class ConnectorListFilterModel extends BaseModel<ConnectorListFilterModelInterface> {

    /**
     * Docker image
     * @type {string}
     * @memberof ConnectorListFilterModel
     */
    image?: string;

    constructor(data?: ConnectorListFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorListFilterModel, value: StringDateInterface<ConnectorListFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorListFilterModelFromJSON(json: any): ConnectorListFilterModel {
    return ConnectorListFilterModelFromJSONTyped(json, false);
}

export function ConnectorListFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorListFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorListFilterModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'image': !exists(json, 'image') ? undefined : json['image'],
    });
}

export function ConnectorListFilterModelToJSON(value?: ConnectorListFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorListFilterModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'image': value.image,
    } as StringDateInterface<ConnectorListFilterModel>;
    return createClass.toJson(value, model);
}


