import React, { createRef, useState } from 'react';
import MultiSelectOption, { isEmptyMultiSelect } from 'interface/MultiSelectOption';
import SingleSelect from './SingleSelect';
import { FormikProps } from 'formik';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ImageVersionController from 'controllers/ImageVersionController';
import { BaseSelectRef } from 'components/base/BaseSelect';
import ImageVersion from 'api/override/ImageVersionModel';

interface ImageVersionSingleSelectProps<T> {
    id: string;
    currentImageVersion?: MultiSelectOption;
    controller: ImageVersionController;
    formik: FormikProps<T>;
    name: string;
    imageId: number;
    core?: boolean;
    label?: string;
    disabled?: boolean;
}

function ImageVersionSingleSelect<T>(props: ImageVersionSingleSelectProps<T>) {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const formikVal = props.formik.values[`${props.name}`];
    const [initImageId] = React.useState<number>(props.imageId)
    const [chosenImageVersion, setChosenImageVersion] = React.useState<MultiSelectOption>({
        value: props.formik.values[`${props.id}`]?.value,
        label: props.formik.values[`${props.id}`]?.label
    });
    const [initVersion, setInitVersion] = React.useState<MultiSelectOption | undefined>(props.formik.values[`${props.id}`] != null ? {value: props.formik.values[`${props.id}`]?.value, label: props.formik.values[`${props.id}`]?.label} : undefined)
    const getImageVersions = props.controller.imageVersionToMultiselect;
    const parentRef = createRef<BaseSelectRef>();
    const handleCurrentImageVersionChange = () => {
        if (props.currentImageVersion != null) {
            setImageVersion(null, props.currentImageVersion);
        }
    }
    const onImageChange = () => {
        if (props.imageId === initImageId) {
            return;
        }
        if (props.imageId !== initImageId) {
            setInitVersion(null)
        }
        setChosenImageVersion({value: undefined, label: undefined});
        params.setOffsetForPage(0, 15)
        getImageVersions(props.imageId, params).then((imgVersions?: ImageVersion[]) => {
            setImageVersion(null, imgVersions?.find(imgVer => imgVer.latest) ?? imgVersions[0])
        })
        parentRef.current?.clearOptions();
    }
    // eslint-disable-next-line
    useEffect(onImageChange, [props.imageId])
    // eslint-disable-next-line
    useEffect(handleCurrentImageVersionChange, [props.currentImageVersion])
    useEffect(() => {
        if (formikVal == null) {
            setChosenImageVersion({value: undefined, label: undefined})
            return;
        }
        const newItem = props.controller.getCachedItemByValue(formikVal);
        if (newItem != null) {
            setChosenImageVersion(newItem)
        } else {
            setChosenImageVersion({value: undefined, label: undefined})
        }
    }, [formikVal, props.controller])

    const setImageVersion = (_, val: MultiSelectOption) => {
        setChosenImageVersion(val);
        setInitVersion(null);
        props.formik.setFieldValue(`${props.name}`, isEmptyMultiSelect(val) ? null : `${val.value}`);
    }
    return (
        <SingleSelect
            ref={parentRef}
            label={props.label != null ? props.label : t('image_versions.title.single_select')}
            name={props.name}
            tableParams={params}
            value={initVersion != null ? initVersion : chosenImageVersion}
            getAsyncValuesOnSearch
            getAsyncOptions={(params: TableParams) => getImageVersions(props.imageId, params)}
            onChange={setImageVersion}
            disabled={props.disabled}
            formikError={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])}
            formikHelperText={props.formik.touched[props.name] ? props.formik.errors[props.name] : ""}
        />
    )
}

export default ImageVersionSingleSelect;
