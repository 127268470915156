import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {ProcessMonitorApi, ProcessMonitorListProcessesRequest} from "api";
import { ApiHandler } from './APIHandler';
import TaskMonitorActions, { TaskMonitorList } from 'reducers/types/TaskMonitor';
import { AppActions } from 'reducers/types/App';

export function* TaskMonitorSagas() {
    yield takeLatest(ActionTypes.API_TASK_MONITOR_LIST_TASKS, TaskMonitorSaga.taskMonitorListTasksWorkerSaga);
}

export class TaskMonitorSaga {
    static *taskMonitorListTasksWorkerSaga(action: TaskMonitorList) {
        try {
    
            const taskMonitorArgs = {
                processRetrieveListModel: action.params
            } as ProcessMonitorListProcessesRequest
    
            const data = yield ApiHandler(new ProcessMonitorApi().processMonitorListProcesses, taskMonitorArgs)
            // dispatch a success action to the store with the activities
            yield put(TaskMonitorActions.CreateTaskMonitorListSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_TASK_MONITOR_LIST_TASKS_FAILURE, error as Error))
        }
    }
}

