/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    TransferRequestModelAllOf,
    TransferRequestModelAllOfFromJSON,
    TransferRequestModelAllOfFromJSONTyped,
    TransferRequestModelAllOfToJSON,
    TransferVerifyRequestModel,
    TransferVerifyRequestModelFromJSON,
    TransferVerifyRequestModelFromJSONTyped,
    TransferVerifyRequestModelToJSON,
} from './';


export interface TransferRequestModelInterface {
    database_from?: string;
    server_to?: string;
    database_to?: string;
    preserve_existing?: boolean;
    instance_id?: number;
    backoffice?: boolean;
}

/**
 * 
 * @export
 * @interface TransferRequestModel
 */
export class TransferRequestModel extends BaseModel<TransferRequestModelInterface> {

    /**
     * Database that needs to be copied
     * @type {string}
     * @memberof TransferRequestModel
     */
    database_from?: string;
    /**
     * Server to transfer to
     * @type {string}
     * @memberof TransferRequestModel
     */
    server_to?: string;
    /**
     * Database name to transfer to
     * @type {string}
     * @memberof TransferRequestModel
     */
    database_to?: string;
    /**
     * Preserve the original 3 users of the database being transferred to
     * @type {boolean}
     * @memberof TransferRequestModel
     */
    preserve_existing?: boolean;
    /**
     * id of the chosen instance
     * @type {number}
     * @memberof TransferRequestModel
     */
    instance_id?: number;
    /**
     * Whether this instance originates from the backoffice or central
     * @type {boolean}
     * @memberof TransferRequestModel
     */
    backoffice?: boolean;

    constructor(data?: TransferRequestModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TransferRequestModel, value: StringDateInterface<TransferRequestModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TransferRequestModelFromJSON(json: any): TransferRequestModel {
    return TransferRequestModelFromJSONTyped(json, false);
}

export function TransferRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TransferRequestModel;
    try {
        let localClass = require.context('../override', true)('./TransferRequestModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'database_from': !exists(json, 'database_from') ? undefined : json['database_from'],
        'server_to': !exists(json, 'server_to') ? undefined : json['server_to'],
        'database_to': !exists(json, 'database_to') ? undefined : json['database_to'],
        'preserve_existing': !exists(json, 'preserve_existing') ? undefined : json['preserve_existing'],
        'instance_id': !exists(json, 'instance_id') ? undefined : Number(json['instance_id']),
        'backoffice': !exists(json, 'backoffice') ? undefined : json['backoffice'],
    });
}

export function TransferRequestModelToJSON(value?: TransferRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TransferRequestModel;
    try {
        let localClass = require.context('../override', true)('./TransferRequestModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'database_from': value.database_from,
        'server_to': value.server_to,
        'database_to': value.database_to,
        'preserve_existing': value.preserve_existing,
        'instance_id': value.instance_id,
        'backoffice': value.backoffice,
    } as StringDateInterface<TransferRequestModel>;
    return createClass.toJson(value, model);
}


