import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {PackageApi, PackageCreateRequest, PackageDeleteRequest, PackageHistoryListRequest, PackageListRequest, PackageRetrieveRequest, PackageListResultModel, PackageRetrieveListModel} from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import PackageActions, { PackageCreate, PackageDelete, PackageHistoryList, PackageList, PackageRetrieve, PackageUpdate } from 'reducers/types/Package';
import { TableParams } from 'models/table/TableParams';

export function* PackageSagas() {
    yield takeLatest(ActionTypes.API_PACKAGE_CREATE, PackageSaga.packageCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_PACKAGE_RETRIEVE, PackageSaga.packageRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_PACKAGE_UPDATE, PackageSaga.packageUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_PACKAGE_DELETE, PackageSaga.packageDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_PACKAGE_LIST, PackageSaga.packageListWorkerSaga);
    yield takeLatest(ActionTypes.API_PACKAGE_HISTORY_LIST, PackageSaga.packageHistoryListWorkerSaga);
}

export class PackageSaga {
    static *packageCreateWorkerSaga(action: PackageCreate) {
        try {
    
            const packageArgs = {
                packagePostModel: action.params
            } as PackageCreateRequest
            const data = yield ApiHandler(new PackageApi().packageCreate, packageArgs)
    
            // dispatch a success action to the store with the activities
            yield put(PackageActions.CreatePackageCreateSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_CREATE_FAILURE, error as Error))
        }
    }
    
    static *packageRetrieveWorkerSaga(action: PackageRetrieve) {
        try {
    
            const packageArgs = {
                id: action.params
            } as PackageRetrieveRequest
            const data = yield ApiHandler(new PackageApi().packageRetrieve, packageArgs)
    
            // dispatch a success action to the store with the activities
            yield put(PackageActions.CreatePackageRetrieveSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *packageUpdateWorkerSaga(action: PackageUpdate) {
        try {
            const data = yield ApiHandler(new PackageApi().packageUpdate, {id: action.params.id, packagePostModel: action.params})
    
            yield put(PackageActions.CreatePackageUpdateSuccess(data));
        } catch(error) {
    
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *packageDeleteWorkerSaga(action: PackageDelete) {
        try {
    
            const packageArgs = {
                id: action.params
            } as PackageDeleteRequest
            const data = yield ApiHandler(new PackageApi().packageDelete, packageArgs)
    
            yield put(PackageActions.CreatePackageDeleteSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_DELETE_FAILURE, error as Error))
    
        }
    }
    
    static *packageListWorkerSaga(action: PackageList) {
        try {
    
            const packageArgs = {
                packageRetrieveListModel: action.params
            } as PackageListRequest
            const data = yield ApiHandler(new PackageApi().packageList, packageArgs);
    
            yield put(PackageActions.CreatePackageListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_LIST_FAILURE, error as Error))
        }
    }

    static async packageList(input: string): Promise<PackageListResultModel> {
        const retrieveListRequest = {
            packageRetrieveListModel: {
                search_text: input
            }
        } as PackageListRequest;
        return await new PackageApi().packageList(retrieveListRequest);
    }

    static* packageHistoryListWorkerSaga(action: PackageHistoryList) {
        try{
            const packageArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as PackageHistoryListRequest

            const data = yield ApiHandler(new PackageApi().packageHistoryList, packageArgs);
            yield put(PackageActions.CreatePackageHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_PACKAGE_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static async packagesList (tableParams?: TableParams): Promise<PackageListResultModel> {
        const retrieveListRequest = {
            packageRetrieveListModel: new PackageRetrieveListModel(tableParams.getApiFilter() as any)
        } as PackageListRequest;
        return await new PackageApi().packageList(retrieveListRequest);
        
    }
}
