import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps, componentConnect } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import NotificationCenter from 'services/NotificationCenter';
import { BaseAction } from 'reducers/interface/ReducerAction';
import WizardActions from 'reducers/types/Wizard';
import WizardState from 'reducers/interface/WizardState';
import Wizard from 'api/override/WizardModel';
import HideOnProd from 'components/HideOnProd';
import { WizardCreateSuccessModel } from 'api/models/WizardCreateSuccessModel';
import WizardBuilder from './components/WizardBuilder';

interface StateProps {
    appReducer: AppState;
    wizardReducer: WizardState;
}

interface DispatchProps {
    retrieveWizard: (params: number) => void;
    updateWizard: (params: Wizard) => void;
    createWizard: (params: Wizard) => void;
    clearWizards: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    wizard?: Wizard;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class WizardBuilderView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            wizard: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_WIZARD_CREATE_FAILURE,
            ActionTypes.API_WIZARD_RETRIEVE_FAILURE, 
            ActionTypes.API_WIZARD_UPDATE_FAILURE,
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_UPDATE_SUCCESS, 
            this.onUpdateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({wizard: action.params}, () => {
            this.updateTabTitle(this.state.wizard?.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const wizardSuccess: WizardCreateSuccessModel = action.params
        this.setState({wizard: new Wizard(wizardSuccess.model)})
        this.props.navigate(`/wizards/${wizardSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
        this.props.clearWizards()
        this.reloadData()
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveWizard(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.WIZARD_VIEW_UPDATE_HISTORY}, null);
        }
        this.loadData();
    }

    updateWizard = (wizard: Wizard) => {
        this.props.updateWizard(wizard)
    }

    createWizard = (wizard: Wizard) => {
        this.props.createWizard(wizard)
    }

    renderView() {
        const { classes, appReducer } = this.props;
        return (
            <HideOnProd>
                <React.Fragment>
                    <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_WIZARD_RETRIEVE)}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Container style={{maxWidth: '94vw'}}>
                        <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <Card className={classes.root}>
                                    { this.state.wizard != null &&
                                        <WizardBuilder 
                                            wizard={this.state.wizard}
                                            onSaveChanges={(wizard: Wizard) => {
                                                this.updateWizard(wizard)
                                            }}
                                            onUpdateTitle={(wizard: Wizard) => {
                                                this.updateWizard(wizard)
                                            }}
                                        />
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </React.Fragment>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    wizardReducer: state.wizards
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveWizard: (params: number) => dispatch(WizardActions.CreateWizardRetrieve(params)),
        updateWizard: (params: Wizard) => dispatch(WizardActions.CreateWizardUpdate(params)),
        createWizard: (params: Wizard) => dispatch(WizardActions.CreateWizardCreate(params)),
        clearWizards: () => dispatch(WizardActions.CreateClearWizardList(true))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(WizardBuilderView, InstanceViewStyles)
            )
        )
    )
);