import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import ButtonStyled from 'components/styled/ButtonStyled';
import Auth from 'api/override/AuthenticateModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { retrieveAuthSchema } from 'schemas/Auth';
import PasswordTextField from 'components/form_controls/PasswordTextField';

interface SignInFormProps {
    onFormSubmit: (value: Auth) => void;
}

const SignInForm = (props: SignInFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new Auth({
                email: '',
                password: ''
            })}
            validationSchema={retrieveAuthSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Auth>) => 
                <Form className={classes.fields_container} style={{width: '100%'}}>

                    <TextFieldStyled 
                        id="email" 
                        name="email"
                        label={t('users.email')}
                        helperText={formik.touched.email ? formik.errors.email : ""}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />

                    <PasswordTextField 
                        id="password" 
                        name="password"
                        label={t('users.password')}
                        helperText={formik.touched.password ? formik.errors.password : ""}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />

                    <ButtonStyled type="submit" className={`${classes.button} signin`}>
                        {t('sign-in.button')}
                    </ButtonStyled>
                </Form>
            }
        </Formik>
    );
};

export default SignInForm;