import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';
import Widget from 'api/override/WidgetModel';
import WidgetField from 'api/override/WidgetFieldModel';
import { Divider } from '@mui/material';



interface WidgetDetailsProps {
    widget: Widget;
    onDelete?: () => void;
    onEdit?: () => void;
}

const WidgetDetails = (props: WidgetDetailsProps) => {
    const { t } = useTranslation();
    const { widget, onDelete, onEdit } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('widgets.model')}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={widget?.name} />
            <DetailsField propKey={t('widgets.icon')} propVal={widget?.icon} />
            <Divider style={{margin: '20px 0 5px 0'}}/>
            <h3>{t('widgets.widget_field_plural')}</h3>
            {(widget.fields as Array<WidgetField>).map((field: WidgetField, i: number) => 
                <DetailsField key={`${field.field}_${i}`} propKey={field.type} propVal={field.title}/>
            )}    
        </div>

    );
};

export default WidgetDetails;