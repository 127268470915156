/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface HistoryModelRetrieveListModelInterface {
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
}

/**
 * 
 * @export
 * @interface HistoryModelRetrieveListModel
 */
export class HistoryModelRetrieveListModel extends BaseModel<HistoryModelRetrieveListModelInterface> {

    /**
     * Offset
     * @type {number}
     * @memberof HistoryModelRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof HistoryModelRetrieveListModel
     */
    limit?: number;
    /**
     * Sort by Column
     * @type {string}
     * @memberof HistoryModelRetrieveListModel
     */
    sort_by?: string;
    /**
     * Sort direction
     * @type {string}
     * @memberof HistoryModelRetrieveListModel
     */
    sort_direction?: string;

    constructor(data?: HistoryModelRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: HistoryModelRetrieveListModel, value: StringDateInterface<HistoryModelRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function HistoryModelRetrieveListModelFromJSON(json: any): HistoryModelRetrieveListModel {
    return HistoryModelRetrieveListModelFromJSONTyped(json, false);
}

export function HistoryModelRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryModelRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = HistoryModelRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./HistoryModelRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'sort_by': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sort_direction': !exists(json, 'sort_direction') ? undefined : json['sort_direction'],
    });
}

export function HistoryModelRetrieveListModelToJSON(value?: HistoryModelRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = HistoryModelRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./HistoryModelRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'offset': value.offset,
        'limit': value.limit,
        'sort_by': value.sort_by,
        'sort_direction': value.sort_direction,
    } as StringDateInterface<HistoryModelRetrieveListModel>;
    return createClass.toJson(value, model);
}


