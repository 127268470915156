import React, { CSSProperties } from 'react';
import { Navigate, Route } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens } from './config/theme';
import 'typeface-open-sans';
import Notifier from './components/Notifier';
import AppDrawer from './views/Main/AppDrawer';
import SignIn from './views/Authentication/SignIn';
import SignOut from './views/Authentication/SignOut';
import UserList from './views/Users/UserList';
import InstanceList from './views/Instances/InstanceList';
import InstanceView from './views/Instances/InstanceView';
import SettingList from './views/Settings/SettingList';
import LoadBalancers from './views/SystemStatus/LoadBalancers';
import RancherPage from './views/SystemStatus/RancherPage';
import Repositories from './views/SystemStatus/Repositories';
import UptimeMonitor from "./views/SystemStatus/UptimeMonitor";
import TaskMonitorList from './views/TaskMonitor/TaskMonitorList';
import { SnackbarProvider } from 'notistack';
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import ConnectorView from 'views/Connectors/ConnectorView';
import ConnectorList from 'views/Connectors/ConnectorList';
import NotificationCenter from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import NCNotification from 'models/NCNotification';
import ProcessPanel from 'views/Main/ProcessPanel';
import GlobalProcessProgressNotification from 'models/GlobalProcessProgressNotification';
import NCNotificationModel from 'models/NCNotificationModel';
import TokenUtil from 'utils/TokenUtil';
import Unauthorized from 'views/Authentication/Unauthorized';
import i18next from 'i18next';
import ImageList from 'views/Images/ImageList';
import ImageView from 'views/Images/ImageView';
import UserView from 'views/Users/UserView';
import SettingView from 'views/Settings/SettingView';
import HistoryList from 'views/History/HistoryList';
import { Page404 } from 'components/Page404';
import PythonCronView from 'views/Tool/PythonCronView';
import DatabaseTransferView from 'views/Tool/DatabaseTransferView';
import Dashboard from 'views/Dashboard/Dashboard';
import ClientList from 'views/Clients/ClientList';
import ClientView from 'views/Clients/ClientView';
import PropertyList from 'views/Properties/PropertyList';
import PropertyView from 'views/Properties/PropertyView';
import FormList from 'views/Forms/FormList';
import FormView from 'views/Forms/FormView';
import TrialsDashboard from 'views/Trials/TrialsDashboard';
import Property from 'api/override/PropertyModel';
import * as Sentry from "@sentry/react";
import MailList from 'views/Mails/MailList';
import MailView from 'views/Mails/MailView';
import MailLogList from 'views/MailLogs/MailLogList';
import MailLogView from 'views/MailLogs/MailLogView';
import ServiceList from 'views/Services/ServiceList';
import ServiceView from 'views/Services/ServiceView';
import YupUtil from 'utils/YupUtil';
import WizardList from 'views/Wizard/WizardList';
import WizardBuilderView from 'views/Wizard/WizardBuilderView';
import WidgetView from 'views/Widget/WidgetView';
import WidgetList from 'views/Widget/WidgetList';
import WizardView from 'views/Wizard/WizardView';
import FormBuilderView from 'views/Forms/FormBuilderView';
import SentryRoutes from 'components/SentryRoutes';
import Pages from 'utils/Pages';
import { Page403 } from 'components/Page403';
import RoleRoute from 'components/RoleRoute';
import Constants from 'config/Constants';
import RoleType from 'interface/RoleType';
import BackOfficeTerminal from 'views/AdminTerminal/BackOfficeTerminal';
import {hotkeys} from 'react-keyboard-shortcuts'
import LocalStorageManager from 'utils/LocalStorageManager';
import PackageList from 'views/Packages/PackageList';
import PackageView from 'views/Packages/PackageView';
import { PaletteMode } from '@mui/material';
import themeBackOffice from './config/theme';
import SettingController from 'controllers/SettingController';
import LoadingView from 'views/Main/LoadingView';
import CentralInstanceList from 'views/CentralInstances/CentralInstanceList';
import InstanceManageView from 'views/Instances/InstanceManageView';

interface AppProps {
    
}

interface DispatchProps {}

type Props = AppProps & DispatchProps & ViewProps;


interface State {
    notifRecord: Record<string, GlobalProcessProgressNotification>;
    shouldReconnect: boolean;
    online: boolean;
    isTerminalVisible: boolean;
    themeMode: PaletteMode;
    hasFetchedSettings: boolean;
}

class App extends BasePageComponent<Props, State> {

    private optionPressed = false;

    constructor(props) {
        super(props)

        this.state = {
            notifRecord: {},
            shouldReconnect: true,
            online: navigator.onLine,
            isTerminalVisible: false,
            themeMode: LocalStorageManager.getThemeMode() as PaletteMode ?? 'light',
            hasFetchedSettings: false,
        };
        new YupUtil().registerExtraTypes();
    }

    hot_keys = {
        'alt+.': {
            priority: 1,
            handler: (event) => this.setState({isTerminalVisible: !this.state.isTerminalVisible})
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.loadCacheData()
        window.addEventListener('online', () => this.setState({online: true}));
        window.addEventListener('offline', () => this.setState({online: false}));
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListeners(
            this, 
            Object.keys(ActionTypes).filter(action => action.startsWith('NODE')).map((action: string) => ActionTypes[action]),
            (action: NCNotification) => {
                const newNotif = NCNotificationModel.makeNotification(action)
                if(this.state.notifRecord[newNotif.getKey()] != null) {
                    this.setState({
                        notifRecord: {
                            ...this.state.notifRecord,
                            [newNotif.getKey()]: {
                                ...this.state.notifRecord[newNotif.getKey()],
                                notifications: this.state.notifRecord[newNotif.getKey()].notifications.concat(newNotif)
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        notifRecord: {
                            ...this.state.notifRecord,
                            [newNotif.getKey()]: {
                                isDismissed: false,
                                notifications: [newNotif]
                            }
                        }
                    })
                }
            }
        );
        NotificationCenter.default.addListener(
            this,
            ActionTypes.API_CALL_FAILURE,
            (action) => {
                if((action.params?.body?.code === 401 || action.params?.body?.code === 403) && action.params?.body?.errors[0] !== "Invalid credentials"){
                    this.props.navigate('/unauthorized')
                }
            }
        );
        NotificationCenter.default.addListener(
            this,
            ActionTypes.API_SIGN_IN_SUCCESS,
            this.loadCacheData
        );
        NotificationCenter.default.addListeners(
            this,
            [ActionTypes.API_SETTING_CREATE_SUCCESS, ActionTypes.API_SETTING_DELETE_SUCCESS, ActionTypes.API_SETTING_UPDATE_SUCCESS],
            this.initSettingManager
        );
    }

    componentWillUnmount(): void {}


    onDismiss = (key: string) => {
        this.setState({
            notifRecord: {
                ...this.state.notifRecord,
                [key]: {
                    ...this.state.notifRecord[key],
                    isDismissed: true
                }
            }
        })
    }

    initSettingManager = () => {
        if (!TokenUtil.getTokenUtil().isTokenExpired()) {
            let shouldCancelFetch = false;
            window.addEventListener('beforeunload', () => {
                shouldCancelFetch = true;
            });
            new SettingController().setupSettings()
                .then(_ => {
                    this.setState({hasFetchedSettings: true});
                })
                .catch(_ => {
                    if (!shouldCancelFetch) {
                        this.setState({hasFetchedSettings: false});
                        LocalStorageManager.clearLogin();
                        window.location.href = '/sign-in';
                    }
                });
        } else {
            this.setState({hasFetchedSettings: true});
        }
    }

    loadCacheData = () => {
        if(TokenUtil.getTokenUtil().isTokenExpired() === false) {
            Property.prepareCache();
        }
        this.initSettingManager();
    }

    unauthorisedCheck = () => {
        if(TokenUtil.getTokenUtil().getRole() !== 'admin' && this.props.location?.pathname.startsWith('/settings')){
            this.props.navigate('/unauthorized')
        }
    }

    offlineMessage = (): string => {
        return i18next.isInitialized
            ? i18next.t('global.title.offline')
            : 'You are offline';
    }

    renderView() {
        return <></>
    }

    updateThemeMode = () => {
        if(LocalStorageManager.isThemeLight()) {
            this.setState({themeMode: 'dark'})
            LocalStorageManager.setThemeMode('dark')
        } else {
            this.setState({themeMode: 'light'})
            LocalStorageManager.setThemeMode('light')
        }
    }

    getBackground = (): string => {
        if (!LocalStorageManager.isThemeLight()) {
            if (['simeon@bumbal.eu'].includes(LocalStorageManager.getUser()?.email ?? '')) {
                return 'url("/dark-background-simeon2.png")'
            } else if (['ted@bumbal.eu'].includes(LocalStorageManager.getUser()?.email ?? '')) {
                return 'url("/dark-background-ted.png")'
            } else {
                return 'url("/dark-background.png")'
            }
        } else {
            return 'url("/background.png")'
        }
    }

    render() {
        const { online } = this.state;
        const appTheme = createTheme({...themeBackOffice, ...getDesignTokens(this.state.themeMode)}, [this.state.themeMode]);
        const isLoggedIn = TokenUtil.getTokenUtil().isTokenExpired() === false;
        let background = {backgroundImage: this.getBackground(), backgroundSize: 'cover'};
        const importantMessageStyle = {
            position: 'fixed',
            top: 0,
            left: 'calc(50% - 120px)',
            color: 'red',
            width: 240,
            zIndex: 1,
            textTransform: 'uppercase',
            padding: 2,
            fontWeight: 1000,
            fontSize: 20
        } as CSSProperties
        return (
            this.state.hasFetchedSettings ? 
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={appTheme}>
                    <>
                    {this.unauthorisedCheck()}
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            preventDuplicate>
                            <CssBaseline />
                            <div style={!online ? { filter: 'grayscale(80%)', height: '100vh'} : {}}>
                            { isLoggedIn && LocalStorageManager.getUser()?.email === 'denis@bumbal.eu' ? (
                                <div style={importantMessageStyle}>THIS IS {process.env.REACT_APP_DEPLOY_ENV.trim()}</div>
                            ) : (null)}
                            { (isLoggedIn) && <AppDrawer onTerminalClick={() => {this.setState({isTerminalVisible: !this.state.isTerminalVisible})}} onChangeThemeMode={this.updateThemeMode}/> }
                                { (isLoggedIn && Object.values(this.state.notifRecord).filter(notif => !notif.isDismissed).length !== 0) && 
                                    <ProcessPanel onDismiss={this.onDismiss} notifRecord={this.state.notifRecord}/>
                                }
                                <div style={{position: 'absolute', top: 0, right: 0, bottom:0, left: 56, opacity: 0.420, ...background}}></div>
                                <div style={{position: 'absolute', top: 0, right: 0, bottom:0, left: isLoggedIn ? 56 : 0, overflow: 'auto', ...background}}>
                                    { TokenUtil.getTokenUtil().isAdmin() ? 
                                        <BackOfficeTerminal 
                                            isVisible={this.state.isTerminalVisible} 
                                            onClose={() => this.setState({isTerminalVisible: false})}/>
                                        :
                                        <></>
                                    }
                                    <SentryRoutes>
                                        <Route path='/sign-in' element={ <SignIn /> }/>
                                        <Route path='/sign-out' element={<SignOut />} />
                                        <Route path='/dashboard' element={
                                            <RoleRoute>
                                                <Dashboard />
                                            </RoleRoute>
                                        } />
                                        <Route path="/tools/db-transfer" element={
                                            <RoleRoute allowed_roles={Constants.getRoles(RoleType.DevelopersAdmin)}>
                                                <DatabaseTransferView />
                                            </RoleRoute>
                                        } />
                                        <Route path='/unauthorized' element={
                                            <RoleRoute>
                                                <Unauthorized />
                                            </RoleRoute>
                                        } />
                                        <Route path='/system-status/load-balancers' element={
                                            <RoleRoute>
                                                <LoadBalancers />
                                            </RoleRoute>
                                        } />
                                        <Route path='/system-status/rancher' element={
                                            <RoleRoute>
                                                <RancherPage />
                                            </RoleRoute>
                                        } />
                                        <Route path='/system-status/repositories' element={
                                            <RoleRoute>
                                                <Repositories />
                                            </RoleRoute>
                                        } />
                                        <Route path='/system-status/uptime-monitor' element={
                                            <RoleRoute>
                                                <UptimeMonitor />
                                            </RoleRoute>
                                        } />
                                        <Route path='/wizards/:slug/builder' element={
                                            <RoleRoute>
                                                <WizardBuilderView />
                                            </RoleRoute>
                                        } />
                                        <Route path='/forms/:slug/builder' element={
                                            <RoleRoute>
                                                <FormBuilderView />
                                            </RoleRoute>
                                        } />
                                        <Route path='/tools/python-cron' element={
                                            <RoleRoute>
                                                <PythonCronView />
                                            </RoleRoute>
                                        } />
                                        <Route path='/trials' element={
                                            <RoleRoute>
                                                <TrialsDashboard />
                                            </RoleRoute>
                                        } />
                                        <Route path={`/${Pages.instances}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <InstanceList />
                                                </RoleRoute>
                                            } />
                                            <Route path='instance-manage' element={
                                                <RoleRoute>
                                                    <InstanceManageView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug'  element={
                                                <RoleRoute>
                                                    <InstanceView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page'  element={
                                                <RoleRoute>
                                                    <InstanceView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.users}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <UserList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug'  element={
                                                <RoleRoute>
                                                    <UserView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page'  element={
                                                <RoleRoute>
                                                    <UserView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.packages}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <PackageList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug'  element={
                                                <RoleRoute>
                                                    <PackageView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page'  element={
                                                <RoleRoute>
                                                    <PackageView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.taskMonitor}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <TaskMonitorList />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.images}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <ImageList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <ImageView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <ImageView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.connectors}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <ConnectorList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <ConnectorView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <ConnectorView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.services}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <ServiceList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <ServiceView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <ServiceView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.settings}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <SettingList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <SettingView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <SettingView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.history}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <HistoryList />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.client}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <ClientList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <ClientView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <ClientView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.property}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <PropertyList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <PropertyView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <PropertyView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.form}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <FormList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <FormView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <FormView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.wizard}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <WizardList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <WizardView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <WizardView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.mail}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <MailList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <MailView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <MailView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.mailLog}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <MailLogList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <MailLogView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <MailLogView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.widget}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <WidgetList />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug' element={
                                                <RoleRoute>
                                                    <WidgetView />
                                                </RoleRoute>
                                            } />
                                            <Route path=':slug/:page' element={
                                                <RoleRoute>
                                                    <WidgetView />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path={`/${Pages.centralInstances}/`}>
                                            <Route index element={
                                                <RoleRoute>
                                                    <CentralInstanceList />
                                                </RoleRoute>
                                            } />
                                        </Route>
                                        <Route path='/'
                                            element={TokenUtil.getTokenUtil().isTokenExpired() 
                                                ? (<Navigate replace to='/sign-in' />) 
                                                : (<Navigate replace to='/dashboard' />) 
                                            }
                                        />
                                        <Route path='403' element={<Page403 />} />
                                        <Route path='*' element={<Page404 />} />
                                    </SentryRoutes>
                                </div>
                                <div style={{
                                    position: 'absolute', 
                                    background: '#272727', 
                                    width: 200, 
                                    color: 'white', 
                                    left: '50%', 
                                    transform: 'translateX(-50%)', 
                                    textAlign: 'center', 
                                    lineHeight: '40px', 
                                    borderRadius: '0 0 10px 10px',
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    top: online ? '-40px' : '0',
                                    transition: 'all 0.420s ease-in'
                                }}>
                                    {this.offlineMessage()}
                                </div>
                            </div>
                            { (isLoggedIn) && <Notifier/> }
                        </SnackbarProvider>
                    </>
                </ThemeProvider>
            </StyledEngineProvider>
            :
            <LoadingView/>
        );
    }
}

const mapStateToProps = state => ({
    themeType: 'state.app.theme'
});

const mapDispatchToProps = dispatch => {
    return {
        setTheme: (params) => dispatch({ type: "CHANGE_THEME",  params})
    };
};

export default componentConnect(mapStateToProps, mapDispatchToProps, Sentry.withProfiler(hotkeys(App)))