/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ServiceRemoveSuccessModelInterface {
    uuid?: string;
    message?: string;
}

/**
 * 
 * @export
 * @interface ServiceRemoveSuccessModel
 */
export class ServiceRemoveSuccessModel extends BaseModel<ServiceRemoveSuccessModelInterface> {

    /**
     * Unique per service
     * @type {string}
     * @memberof ServiceRemoveSuccessModel
     */
    uuid?: string;
    /**
     * A message stating the success or error
     * @type {string}
     * @memberof ServiceRemoveSuccessModel
     */
    message?: string;

    constructor(data?: ServiceRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceRemoveSuccessModel, value: StringDateInterface<ServiceRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceRemoveSuccessModelFromJSON(json: any): ServiceRemoveSuccessModel {
    return ServiceRemoveSuccessModelFromJSONTyped(json, false);
}

export function ServiceRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ServiceRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function ServiceRemoveSuccessModelToJSON(value?: ServiceRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ServiceRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'message': value.message,
    } as StringDateInterface<ServiceRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


