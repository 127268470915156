import React, { useEffect, useState, useCallback } from 'react';
import {useTranslation} from "react-i18next";
import i18next from 'i18next';
import useDialogStyles from 'styles/DialogStyles';
import SmallModal from 'components/SmallModal';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { Button } from '@mui/material';
import BaseDialogActions from 'components/base/BaseDialogActions';
import { DeleteEmptyOutline } from 'mdi-material-ui';

interface TypeDeleteProps {
    open: boolean;
    onModalClosePress: () => void;
    onConfirm: (deletionReason?: string) => void;
    confirmValue: string;
    title?: string;
    hasDeleteReason?: boolean;
}

const TypeDeleteDialog = (props: TypeDeleteProps) => {

    const { t } = useTranslation();
    const [value, setValue] = useState('')
    const [reason, setReason] = useState('')
    const {classes} = useDialogStyles();
    const { open, onModalClosePress, onConfirm, confirmValue, title, hasDeleteReason} = props;

    const checkValue = useCallback(() => confirmValue === value ? true : false, [confirmValue, value])
    const setTextFieldValue = (e: React.ChangeEvent<HTMLInputElement>) => {setValue(e.target.value)}
    const setReasonValue = (e: React.ChangeEvent<HTMLInputElement>) => {setReason(e.target.value)}

    useEffect(() => {
        const onKeyDown = ({ key }: KeyboardEvent) => {
            if (key === 'Enter') {
                checkValue() && onConfirm(reason);
            }
        };
        document.body.addEventListener('keydown', onKeyDown, false);
        return () => document.body.removeEventListener('keydown', onKeyDown, false);
    }, [onConfirm, checkValue, reason]);

    return (
        <SmallModal open={open} onModalClosePress={onModalClosePress} title={title ?? t('global.title.delete_confirm')}>
            <h3 className={classes.type_delete_heading}>{i18next.t('global.title.delete_to_confirm')} "{confirmValue}"</h3>
            <TextFieldStyled 
                id="key" 
                name="key"
                label={t('global.button.confirm')}
                onChange={setTextFieldValue}
            />
            {hasDeleteReason && 
                <TextFieldStyled
                    id="deletionReason" 
                    name="deletionReason"
                    label={t('global.form.reason_to_delete')}
                    onChange={setReasonValue}
                />
            }

            <BaseDialogActions onModalClosePress={onModalClosePress} hasCancel>
                <Button type="submit" disabled={!checkValue()} onClick={() => onConfirm(reason)} className={`${classes.button} error`} startIcon={<DeleteEmptyOutline/>}>
                    {t('global.button.delete')}
                </Button>
            </BaseDialogActions>
        </SmallModal>
    );
};

export default TypeDeleteDialog;