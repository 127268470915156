import ServiceContainer from "api/override/ServiceContainerModel";
import MenuButton from "components/MenuButton";
import Tasks from "config/Tasks";
import { DotsVertical } from "mdi-material-ui";
import { CustomMenuButton } from "models/table/CustomActionButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ServiceContainerActions from "reducers/types/ServiceContainer";

export interface ServiceContainerMenuProps {
    serviceContainer: ServiceContainer;
}

const ServiceContainerMenu = (props: ServiceContainerMenuProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let options: string[] = [t('global.button.trigger.create'), t('global.button.trigger.delete'), t('global.button.trigger.redeploy'), t('global.button.trigger.update'), t('global.button.redirect')]
    // if developer
    options = options.concat(t('general.goto_image'))
    //
    const handleClick = (option: string) => {
        switch (option) {
            case t('global.button.trigger.create'):
                dispatch(ServiceContainerActions.CreateServiceContainerTriggerJob({uuid: props.serviceContainer?.uuid, task: Tasks.CREATE}))
                break;
            case t('global.button.trigger.delete'):
                dispatch(ServiceContainerActions.CreateServiceContainerTriggerJob({uuid: props.serviceContainer?.uuid, task: Tasks.DELETE}))
                break;
            case t('global.button.trigger.redeploy'):
                dispatch(ServiceContainerActions.CreateServiceContainerTriggerJob({uuid: props.serviceContainer?.uuid, task: Tasks.REDEPLOY}))
                break;
            case t('global.button.trigger.update'):
                // t('node.service-container-update-configurations')
                dispatch(ServiceContainerActions.CreateServiceContainerTriggerJob({uuid: props.serviceContainer?.uuid, task: Tasks.UPDATE}))
                break;
            case t('global.button.redirect'):
                navigate(`/services/${props.serviceContainer.service?.uuid}/instances`)
                break;
            case t('general.goto_image'):
                navigate(`/images/${props.serviceContainer.service?.image?.id}`)
                break;
            default:
                throw new Error('How did this happen');
        }
    }

    return (
        <MenuButton
            options={options}
            onClick={ handleClick }
            buttonIcon={ DotsVertical }
        />
    )
}

export class ServiceContainerTableMenuButton extends CustomMenuButton {
    
    constructor() {
        super('menu', 'serviceContainerMenu', 'service-container-menu', null, [], null)
    }

    render(row: ServiceContainer) {
        return (
            <ServiceContainerMenu
                serviceContainer={row}
            />
        )
    }

}

export default ServiceContainerMenu;