import KeyValueInterface from 'interface/KeyValueInterface';

type RecordKey = string | number | symbol

declare global {
    interface Array<T> {
        /**
         * Gets the first element of the array
         * @returns the first element of the array of undefined if the array is empty
         */
        bbFirst(): T | undefined;
         /**
         * Removes the first element of the array
         * @returns the new array withouth the first item
         */
        bbRemoveFirstItem(): T[];
        /**
         * Gets the last element of the array
         * @returns the last element of the array of undefined if the array is empty
         * Note: the last element might be the first one!
         */
        bbLast(): T | undefined;
        /**
         * Transforms the array to a record
         * @param selector string | number | symbol - the seldctor that an array of objects should be mapped to.
         * @returns a record of the array with the specifies selector
         */
        bbToRecord<T extends { [K in keyof T]: any }, K extends keyof T>(selector: RecordKey): Record<T[K], T>;

        bbMultiSelectToRecord(): T;
         /**
         * Gets the last index of the array
         * @returns the last index of the array or -1 if the array is empty
         */
        bbLastIndex(): number;
        
        /**
         * Determines whether the array is empty
         * @returns true if the length of the array is > 0
         */
        bbIsEmpty(): boolean;
         /**
         * Determines whether the array has any common items with another array
         * @param objectKey string | number| symbol - if the first array is an array of objects, one can provide a key to compare
         * @returns a boolean if there is even one value that matches between the arrays
         */
        bbHasCommonItemsWith<U>(arr: U[], objectKey?: RecordKey): boolean;
        /**
         * Adds an item to the array at the specified position
         * @param index number - the position you want to add the item at
         * @param item any
         * @returns the array with the added item
         */
        bbInsertItemAt<T>(index: number, item: T): any[]; 
        /**
         * Removes an item equal to the provided value
         * @param value T - the value that has to be removed
         * @returns the array without the provided value
         */
        bbRemoveItemByValue<T>(value: T): T[];
        /**
         * Removes an object item  by specifying a property
         * @param prop key - string, char or number representing the key which we
         * @returns the array without the object
         */
        bbRemoveObjectWithProperty<T, K>(prop: K, value: T): T[];
        /**
         * Prepends the array with the provided value
         * @param value T - value to prepend the array with
         * @returns the new prepended array
         */
        bbPrepend<T>(value: T): T[];
        /**
         * Removes the item from the array at the specified index
         * @param index number - position of the item to remove
         * @returns the new array without the item at the supplied index
         */
        bbRemoveAtIndex<T>(index: number): T[];
        /**
         * Swaps two items in an array
         * @param firstIndex number - the index of the first item to swap
         * @param secondIndex number - the index of the second item to swap
         * @returns the newly ordered array
         */
        bbSwapItemsByIndex<T>(firstIndex: number, secondIndex: number): T[];
        /**
         * Group array elements by property value
         * @param propToGroupBy string - the property of the array items to be grouped by
         * @returns an object with the grouped results
         */
         bbGroupBy<T>(propToGroupBy: string): Record<any, Array<T>>;
    }
}

// eslint-disable-next-line
Array.prototype.bbFirst = function<T>(): T | undefined {
    return this?.[0];
}

// eslint-disable-next-line
Array.prototype.bbRemoveFirstItem = function<T>(): T[] {
    return this.filter((_: T, index: number) => index !== 0)
}

// eslint-disable-next-line
Array.prototype.bbLast = function<T>(): T | undefined {
    return this.length >= 1 ? this[this.length - 1] : undefined;
}

// eslint-disable-next-line
Array.prototype.bbToRecord = function<T extends { [K in keyof T]: any }, K extends keyof T>(selector: RecordKey): Record<T[K], T> {
    return this.reduce((acc, item) => {
        acc[item[selector]] = item;
        return acc;
    }, {} as Record<T[K], T>)
}


// eslint-disable-next-line
Array.prototype.bbMultiSelectToRecord = function<T extends KeyValueInterface>(): Record<string, any> {
    let record = {};
    for (const val of this as KeyValueInterface[]) {
        record[val.key] = val.value;
    }
    return record;
}

// eslint-disable-next-line
Array.prototype.bbLastIndex = function(): number {
    return this.length - 1;
}

// eslint-disable-next-line
Array.prototype.bbIsEmpty = function(): boolean {
    return this?.['length'] === 0;
}

// eslint-disable-next-line
Array.prototype.bbHasCommonItemsWith = function<U>(arr: U[], objectKey?: RecordKey): boolean {
    return this.some(item => arr.includes(objectKey == null ? item : item[objectKey]));
}

// eslint-disable-next-line
Array.prototype.bbInsertItemAt = function<T>(index: number, item: T): any[] {
    const _arr = [...this];
    _arr.splice(index, 0, item);
    return _arr;
};

// eslint-disable-next-line
Array.prototype.bbRemoveItemByValue = function<T>(value: T): T[] {
    return this.filter(el => el !== value)
} 

// eslint-disable-next-line
Array.prototype.bbRemoveObjectWithProperty = function<T, K>(prop: K, value: T): T[] {
    return this.splice(this.map(e => e[prop]).indexOf(value), 1)
}

// eslint-disable-next-line
Array.prototype.bbPrepend = function<T>(value: T): T[] {
    return [value, ...this];
}

// eslint-disable-next-line
Array.prototype.bbRemoveAtIndex = function<T>(index: number): T[] {
    let arrCopy: T[] = [...this];
    arrCopy.splice(index, 1)
    return arrCopy;
}

// eslint-disable-next-line
Array.prototype.bbSwapItemsByIndex = function<T>(firstIndex: number, secondIndex: number): T[] {
    let arrCopy: T[] = [...this];
    let tmp = arrCopy[firstIndex]
    arrCopy[firstIndex] = arrCopy[secondIndex]
    arrCopy[secondIndex] = tmp
    return arrCopy
}

// eslint-disable-next-line
Array.prototype.bbGroupBy = function<T>(propToGroupBy: string): Record<any, Array<T>> {
    let grouped = {};
    for (let i = 0; i < this.length; i++) {
        if(grouped.hasOwnProperty(this[i][propToGroupBy])) {
            grouped[ this[i][propToGroupBy] ].push(this[i])
        } else {
            grouped[ this[i][propToGroupBy] ] = [this[i]]
        }
    }
    return grouped;
}

export default Array
