import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import Client from 'api/override/ClientModel';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface ClientDetailsProps {
    client: Client;
    onDelete?: () => void;
    onEdit?: () => void;
}

const ClientDetails = (props: ClientDetailsProps) => {
    const { t } = useTranslation();
    const { client } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('clients.model')}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={client?.name} />
            <DetailsField propKey={t('clients.company_name')} propVal={client?.company_name} />
            <DetailsField propKey={t('clients.city')} propVal={client?.city} />
            <DetailsField propKey={t('clients.address')} propVal={client?.address} />
            <DetailsField propKey={t('clients.email')} propVal={client?.email} />
            <DetailsField propKey={t('clients.phone')} propVal={client?.phone} />
            <DetailsField propKey={t('clients.website')} propVal={client?.website} redirectTo={client?.getWebsiteUrl()}/>
            <DetailsField propKey={t('clients.industry')} propVal={client?.industry} />
            {Object.entries(client?.properties).map(([key, value], index: number) => {
                return (<DetailsField key={`${key}_${index}`} propKey={key} propVal={value} />)
            })}
        </div>

    );
};

export default ClientDetails;