import React, { useState } from 'react'
import { InputAdornment} from '@mui/material'
import useDialogStyles from 'styles/DialogStyles';
import TextFieldStyled, { TextFieldStyledProps } from 'components/styled/TextFieldStyled';
import { useTranslation } from 'react-i18next';
import { Eye, EyeOff } from 'mdi-material-ui';
import IconButtonStyled from 'components/styled/IconButtonStyled';

type PasswordTextFieldProps<T> = TextFieldStyledProps<T> & {
    isInitiallyVisible?: boolean;
}

const PasswordTextField = <T, >(props: PasswordTextFieldProps<T>) => {
    const {classes} = useDialogStyles();
    const {t} = useTranslation()
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(props.isInitiallyVisible ?? false)
    const handleClickShowPassword = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }
    return (
        <TextFieldStyled 
            type={isPasswordVisible ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end' className={classes.end_adornment}>
                        <IconButtonStyled className={classes.adornment_button} aria-label={t('sign-in.password-helper')} onClick={handleClickShowPassword}>
                            {isPasswordVisible ? <Eye /> : <EyeOff />}
                        </IconButtonStyled>
                    </InputAdornment>
                )
            }}
            {...props}
            formik={props.formik as any}
        />
    )
}

export default PasswordTextField