import ActionTypes from "config/ActionTypes";
import CentralInstance from "api/override/CentralInstanceModel";
import CentralInstanceState from "./interface/CentralInstanceState";
import { CentralInstanceListSuccess, CentralInstanceReducerAction } from "./types/CentralInstance";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";

const initialState: CentralInstanceState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function CentralInstanceReducers(state = initialState, action: CentralInstanceReducerAction): CentralInstanceState {
    switch (action.type) {
        case ActionTypes.API_CENTRAL_INSTANCE_LIST:
            return {
                ...state,
                selectedObject: undefined
            };

        case ActionTypes.API_CENTRAL_INSTANCE_LIST_SUCCESS:
            const result = action as CentralInstanceListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<CentralInstance>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            }

        case ActionTypes.API_CENTRAL_INSTANCE_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}