import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { Card, CardContent, Container, IconButton} from "@mui/material";
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import { withSnackbar } from 'notistack';
import Instance from 'api/override/InstanceModel';
import InstanceSingleSelect from './components/InstanceSelect';
import TableStyles from 'styles/TableStyles';
import i18next from 'i18next';
import TableHeader from 'components/TableHeader';
import { ContentCopy, LanguagePython } from 'mdi-material-ui';
import { withStyles } from 'tss-react/mui';


interface StateProps {

}

interface DispatchProps {
    
}

type Props = StateProps & DispatchProps & ViewProps

interface State {
    instance?: Instance
}

class PythonCronView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            instance: null
        }
    }

    setInstance = (instance: Instance) => {
        this.setState({
            instance: instance
        });
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(`0 0 2 * * /usr/local/bin/python /opt/main.py -host ${this.state.instance.getFullUrl()} -user ${this.state.instance.admin_email} -password <password> -key ${this.state.instance.system_key} -rows 0 -collection ${this.state.instance.domain} >> /var/log/stress-test/general.log`)
        this.props.enqueueSnackbar(i18next.t('global.action.model_copied_clipboard', {model: i18next.t('global.title.script')}), {variant: 'info'})
    }
    
    renderView() {
        const instance = this.state.instance;
        const { classes } = this.props;

        return (
            <React.Fragment>
            <Container maxWidth={"lg"}>
                <Card className={classes.root}>
                    <TableHeader
                        headerIcon={<LanguagePython/>}
                        overrideTitle={i18next.t('python-tool.title')}
                    />
                    <CardContent>
                        <InstanceSingleSelect
                            onSelectInstance={this.setInstance}
                            instance={instance}
                        />
                        { instance != null &&
                            <>
                                <h2 style={{fontSize: 20, fontWeight: 400, paddingLeft: 5}}>{i18next.t('global.title.script')}</h2>
                                <div style={{padding: '16px 40px', background: '#313131', color: '#fff', borderRadius: 4, fontWeight: 600, fontSize: 14, letterSpacing: 0.5}}>
                                <IconButton
                                    onClick={() => this.copyToClipboard()}
                                    style={{color: 'white', position: 'relative', float: 'right', top: -10, right: -35}}
                                    size="large">
                                    <ContentCopy/>
                                </IconButton>
                                <p>0 0 2 * * /usr/local/bin/python /opt/main.py -host {instance.getFullUrl()} -user {instance.admin_email} -password &lt;password&gt; -key {instance.system_key} -rows 0 -collection {instance.domain} &gt;&gt; /var/log/stress-test/general.log</p>
                                </div>
                            </>
                        }
                    </CardContent>
                </Card>
            </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appReducer: state.app
});

const mapDispatchToProps = dispatch => {
    return {
        
    }
};

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(PythonCronView, TableStyles)
            )
        )
    )
);