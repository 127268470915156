import TableViewDelegate from "interface/TableViewDelegate";
import { useEffect, useState } from "react";
import { TableParams } from 'models/table/TableParams';
import { TableCell } from "@mui/material";

interface TableDelegateHookProps {
    getData: () => void;
    onSelectAll?: (items: any[]) => void;
    onSelectRow?: (listItems?: any[]) => void;
    onEdit?: (item: any) => void;
    onDelete?: (item: any) => void;
    onDetails?: (item: any) => void;
    onAction?: (name: string, item: any) => void;
    cellForRow?: (index: number, isSelected: boolean) => typeof TableCell;
    onPageChanged?: (page: number, limit: number) => void;
    onSortingSet?: (key: string, direction: 'asc' | 'desc') => void;
    onSortingClear?: () => void;
}

export default function useTableDelegateHook(tableParams: TableParams, props: TableDelegateHookProps) {
    const [delegate] = useState<TableViewDelegate>({
        onSelectAll: (items: any[]) => props.onSelectAll?.(items),
        onSelectRow: (item: any) => props.onSelectRow?.(item),
        onEdit: (item: any) => props.onEdit?.(item),
        onDelete: (item: any) => props.onDelete?.(item),
        onAction: (name: string, item: any) => props.onAction?.(name, item),
        cellForRow: props.cellForRow != null ? function(index, isSelected) {return props.cellForRow?.(index, isSelected) } : undefined,
        onDetails: (item: any) => props.onDetails?.(item),
        onSortingSet: (key: string, dir: 'asc' | 'desc') => {
            if (props.onSortingClear != null) {
                props.onSortingClear?.()
            } else {
                tableParams.setSorting({
                    column: key,
                    direction: dir
                });
                delegate.getData();
            }
        },
        onSortingClear: () => {
            if (props.onSortingClear != null) {
                props.onSortingClear?.()
            } else {
                tableParams.setSorting({
                    column: 'id',
                    direction: 'asc'
                });
                delegate.getData();
            }
        },
        onPageChanged: (page: number, limit: number) => {
            if (props.onPageChanged != null) {
                props.onPageChanged?.(page, limit)
            } else {
                tableParams.setOffsetForPage(page, limit);
                delegate.getData();
            }
        },
        getData: () => props.getData()
    })
    useEffect(() => {
        delegate.getData()
    }, [delegate]);
    return delegate;
}