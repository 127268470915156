import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import Service from 'api/override/ServiceModel';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface ServiceDetailsProps {
    service: Service;
    onServiceDelete?: () => void;
    onServiceEdit?: () => void;
}

const ServiceDetails = (props: ServiceDetailsProps) => {
    const { t } = useTranslation();
    const { service } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('service.model')}
                onEdit={props.onServiceEdit}
                onDelete={props.onServiceDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={service.name} />
            <DetailsField propKey={t('connector.url')} propVal={service.url} />
            { service.has_port ? 
                <DetailsField propKey={t('service.view.public_url')} propVal={service.getUrlPreview()} />
            : (null) }
            <DetailsField propKey={t('image.model')} propVal={service.image_name} />
            { service.standalone ? (
                <DetailsField propKey={t('image_versions.model')} propVal={service.image_version?.version_name}/>
            ) : (null)}
            <DetailsField propKey={t('service.databases')} arrayVal={service.json_databases} arrayProp='label' />
        </div>
    );
};

export default ServiceDetails;