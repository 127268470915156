import React from 'react';
import { Navigate } from "react-router-dom";
import ActionTypes from 'config/ActionTypes';
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import TokenUtil from 'utils/TokenUtil';

interface StateProps {
    
}

interface DispatchProps {
    signOut: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State {}

class SignOut extends BasePageComponent<Props, State> {

    componentDidMount() {
        super.componentDidMount();
        this.props.signOut();
    }

    renderView() {
        const isLoggedIn = TokenUtil.getTokenUtil().isTokenExpired() === false;
        if(!isLoggedIn) {
            return (<Navigate replace to="/sign-in"/>)
        }

        return (null)
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => {
    return {
        signOut: () => dispatch({ type: ActionTypes.API_SIGN_OUT , api: 'authenticate', params: { token: localStorage.getItem('token')}, call: 'authenticateSignOut'})
    };
};

export default componentConnect(mapStateToProps, mapDispatchToProps, SignOut);