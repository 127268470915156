/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    RoleModel,
    RoleModelFromJSON,
    RoleModelFromJSONTyped,
    RoleModelToJSON,
} from './';


export interface RoleListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<RoleModel>;
}

/**
 * 
 * @export
 * @interface RoleListResultModel
 */
export class RoleListResultModel extends BaseModel<RoleListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof RoleListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof RoleListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<RoleModel>}
     * @memberof RoleListResultModel
     */
    items?: Array<RoleModel>;

    constructor(data?: RoleListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: RoleListResultModel, value: StringDateInterface<RoleListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function RoleListResultModelFromJSON(json: any): RoleListResultModel {
    return RoleListResultModelFromJSONTyped(json, false);
}

export function RoleListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RoleListResultModel;
    try {
        let localClass = require.context('../override', true)('./RoleListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(RoleModelFromJSON)),
    });
}

export function RoleListResultModelToJSON(value?: RoleListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RoleListResultModel;
    try {
        let localClass = require.context('../override', true)('./RoleListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(RoleModelToJSON)),
    } as StringDateInterface<RoleListResultModel>;
    return createClass.toJson(value, model);
}


