/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    WidgetCreateSuccessModel,
    WidgetCreateSuccessModelFromJSON,
    WidgetCreateSuccessModelToJSON,
    WidgetListResultModel,
    WidgetListResultModelFromJSON,
    WidgetListResultModelToJSON,
    WidgetModel,
    WidgetModelFromJSON,
    WidgetModelToJSON,
    WidgetRemoveSuccessModel,
    WidgetRemoveSuccessModelFromJSON,
    WidgetRemoveSuccessModelToJSON,
    WidgetRetrieveListModel,
    WidgetRetrieveListModelFromJSON,
    WidgetRetrieveListModelToJSON,
} from '../models';

export interface WidgetCreateRequest {
    widgetModel: WidgetModel;
}

export interface WidgetDeleteRequest {
    id: number;
}

export interface WidgetHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface WidgetListRequest {
    widgetRetrieveListModel: WidgetRetrieveListModel;
}

export interface WidgetRetrieveRequest {
    id: number;
}

export interface WidgetUpdateRequest {
    id: number;
    widgetModel: WidgetModel;
}

/**
 * 
 */
export class WidgetApi extends runtime.BaseAPI {

    /**
     * Create a new widget
     * Create a new widget
     */
    async widgetCreateRaw(requestParameters: WidgetCreateRequest): Promise<runtime.ApiResponse<WidgetCreateSuccessModel>> {
        if (requestParameters.widgetModel === null || requestParameters.widgetModel === undefined) {
            throw new runtime.RequiredError('widgetModel','Required parameter requestParameters.widgetModel was null or undefined when calling widgetCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetModelToJSON(requestParameters.widgetModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new widget
     * Create a new widget
     */
    widgetCreate = async(requestParameters: WidgetCreateRequest): Promise<WidgetCreateSuccessModel> => {
        const response = await this.widgetCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an widget
     * Delete an widget
     */
    async widgetDeleteRaw(requestParameters: WidgetDeleteRequest): Promise<runtime.ApiResponse<WidgetRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an widget
     * Delete an widget
     */
    widgetDelete = async(requestParameters: WidgetDeleteRequest): Promise<WidgetRemoveSuccessModel> => {
        const response = await this.widgetDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an widget
     * retrieve a list of historical logs for an widget
     */
    async widgetHistoryListRaw(requestParameters: WidgetHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling widgetHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an widget
     * retrieve a list of historical logs for an widget
     */
    widgetHistoryList = async(requestParameters: WidgetHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.widgetHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all widgets
     * List all widgets
     */
    async widgetListRaw(requestParameters: WidgetListRequest): Promise<runtime.ApiResponse<WidgetListResultModel>> {
        if (requestParameters.widgetRetrieveListModel === null || requestParameters.widgetRetrieveListModel === undefined) {
            throw new runtime.RequiredError('widgetRetrieveListModel','Required parameter requestParameters.widgetRetrieveListModel was null or undefined when calling widgetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetRetrieveListModelToJSON(requestParameters.widgetRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetListResultModelFromJSON(jsonValue));
    }

    /**
     * List all widgets
     * List all widgets
     */
    widgetList = async(requestParameters: WidgetListRequest): Promise<WidgetListResultModel> => {
        const response = await this.widgetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a widget by its id
     * Retrieve an widget by its id
     */
    async widgetRetrieveRaw(requestParameters: WidgetRetrieveRequest): Promise<runtime.ApiResponse<WidgetModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a widget by its id
     * Retrieve an widget by its id
     */
    widgetRetrieve = async(requestParameters: WidgetRetrieveRequest): Promise<WidgetModel> => {
        const response = await this.widgetRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an widget
     * Update an widget
     */
    async widgetUpdateRaw(requestParameters: WidgetUpdateRequest): Promise<runtime.ApiResponse<WidgetCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetUpdate.');
        }

        if (requestParameters.widgetModel === null || requestParameters.widgetModel === undefined) {
            throw new runtime.RequiredError('widgetModel','Required parameter requestParameters.widgetModel was null or undefined when calling widgetUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetModelToJSON(requestParameters.widgetModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an widget
     * Update an widget
     */
    widgetUpdate = async(requestParameters: WidgetUpdateRequest): Promise<WidgetCreateSuccessModel> => {
        const response = await this.widgetUpdateRaw(requestParameters);
        return await response.value();
    }

}
