import {SettingModel, SettingModelTypeEnum} from "api";
import i18next from "i18next";
import Historiable from "interface/Historiable";
import MultiSelectOption from "interface/MultiSelectOption";
import EnvUtil from "utils/EnvUtil";

export default class Setting extends SettingModel implements Historiable {

    actionName: string = "setting"
    reducerName: string = "settings"

    parseJson = () => {
        if (typeof this.value !== 'string') {
            return;
        }
        this.value = JSON.parse(this.value);
        if (this.stage_value != null) {
            this.stage_value = JSON.parse(this.stage_value)
        }
    }

    getValue(): string | undefined {
        return !EnvUtil.isProduction() && Boolean(this.stage_value) ? this.stage_value : this.value;
    }

    getJsonValue(): any {
        this.parseJson();
        return this.getValue();
    }

    // t('settings.types.string')
    // t('settings.types.boolean')
    // t('settings.types.integer')
    static getSettingTypes(): MultiSelectOption[] {
        return Object.values(SettingModelTypeEnum).map(val => {return {value: val, label: i18next.t(`settings.types.${val}`)}})
    }

    getTitle(): string {
        return this.key?.split('_').join(' ') ?? '';
    }

}