import {ConfigDataModel} from 'api';
import { ConfigDataModelInterface } from 'api/models';
import KeyValueInterface from 'interface/KeyValueInterface';
import ConfigInput from './ConfigInputModel';

export default class ConfigData extends ConfigDataModel {

    dictData: Record<string, Array<KeyValueInterface>> = {}
    allowed_input?: Array<ConfigInput> = null;
    constructor(data?: ConfigDataModelInterface) {
        super(data);
        this.allowed_input = data?.allowed_input as Array<ConfigInput> ?? [] 
        this.allowed_input?.filter((input: ConfigInput) => input.type === 'dictionary')
            .forEach((input: ConfigInput) => {
                let data: KeyValueInterface[] = [];
                Object.entries(this.config?.[input.name] ?? []).forEach(([key, value]) => {
                    data = data.concat({
                        key: key,
                        value: value
                    });
                });
                this.dictData[ input.name ] = data
            })
        for (const input of this.allowed_input) {
            this.config = this.assignKeys(this.config, input);
        }
    }

    assignKeys = (config: object, input: ConfigInput) => {
        if (config[input.name] == null) {
            switch (input.type) {
                case 'number':
                    config[input.name] = 0;
                    break;
                case 'dictionary':
                case 'arrayObjects':
                    config[input.name] = [];
                    break;
                case 'boolean':
                    config[input.name] = false;
                    break;
                default:
                    config[input.name] = '';
                    break;
            }
        }
        if (input?.inputs?.bbIsEmpty?.() === false && config[input.name]?.bbIsEmpty?.() === false) {
            let newArr = [];
            for (let child of this.config[input.name]) {
                for (const subInput of input.inputs) {
                    child = this.assignKeys(child, subInput);
                }
                newArr = newArr.concat(child)
            }
        }
        return config;
    }

}