import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {Card, CardContent, CardHeader, Container } from "@mui/material";
import i18next from "../../services/i18n";
import DnsIcon from '@mui/icons-material/Dns';
import BasePageComponent from 'components/base/BasePageComponent';
import RepositoriesStyles from 'styles/RepositoriesStyles';
import { ViewProps, componentConnect } from 'components/base/BasePageComponent';
import { withStyles } from 'tss-react/mui';



class Repositories extends BasePageComponent<ViewProps> {

    renderView() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <CardHeader
                            avatar={ <DnsIcon /> }
                            title={ i18next.t('system_status.page_title')+" - "+i18next.t('general.overview') }
                        />
                        <CardContent>
                            data
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
}

export default componentConnect(
    () => {},
    () => ({}),
    withTranslation()(
        withRouter(
            withStyles(Repositories, RepositoriesStyles)
        )
    )
);