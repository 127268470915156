/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    StepviewCreateSuccessModel,
    StepviewCreateSuccessModelFromJSON,
    StepviewCreateSuccessModelToJSON,
    StepviewListResultModel,
    StepviewListResultModelFromJSON,
    StepviewListResultModelToJSON,
    StepviewModel,
    StepviewModelFromJSON,
    StepviewModelToJSON,
    StepviewRemoveSuccessModel,
    StepviewRemoveSuccessModelFromJSON,
    StepviewRemoveSuccessModelToJSON,
    StepviewRetrieveListModel,
    StepviewRetrieveListModelFromJSON,
    StepviewRetrieveListModelToJSON,
} from '../models';

export interface StepviewCreateRequest {
    stepviewModel: StepviewModel;
}

export interface StepviewDeleteRequest {
    id: number;
}

export interface StepviewHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface StepviewListRequest {
    stepviewRetrieveListModel: StepviewRetrieveListModel;
}

export interface StepviewRetrieveRequest {
    id: number;
}

export interface StepviewUpdateRequest {
    id: number;
    stepviewModel: StepviewModel;
}

/**
 * 
 */
export class StepviewApi extends runtime.BaseAPI {

    /**
     * Create a new stepview
     * Create a new stepview
     */
    async stepviewCreateRaw(requestParameters: StepviewCreateRequest): Promise<runtime.ApiResponse<StepviewCreateSuccessModel>> {
        if (requestParameters.stepviewModel === null || requestParameters.stepviewModel === undefined) {
            throw new runtime.RequiredError('stepviewModel','Required parameter requestParameters.stepviewModel was null or undefined when calling stepviewCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewModelToJSON(requestParameters.stepviewModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new stepview
     * Create a new stepview
     */
    stepviewCreate = async(requestParameters: StepviewCreateRequest): Promise<StepviewCreateSuccessModel> => {
        const response = await this.stepviewCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an stepview
     * Delete an stepview
     */
    async stepviewDeleteRaw(requestParameters: StepviewDeleteRequest): Promise<runtime.ApiResponse<StepviewRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an stepview
     * Delete an stepview
     */
    stepviewDelete = async(requestParameters: StepviewDeleteRequest): Promise<StepviewRemoveSuccessModel> => {
        const response = await this.stepviewDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an stepview
     * retrieve a list of historical logs for an stepview
     */
    async stepviewHistoryListRaw(requestParameters: StepviewHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling stepviewHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an stepview
     * retrieve a list of historical logs for an stepview
     */
    stepviewHistoryList = async(requestParameters: StepviewHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.stepviewHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all stepviews
     * List all stepviews
     */
    async stepviewListRaw(requestParameters: StepviewListRequest): Promise<runtime.ApiResponse<StepviewListResultModel>> {
        if (requestParameters.stepviewRetrieveListModel === null || requestParameters.stepviewRetrieveListModel === undefined) {
            throw new runtime.RequiredError('stepviewRetrieveListModel','Required parameter requestParameters.stepviewRetrieveListModel was null or undefined when calling stepviewList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewRetrieveListModelToJSON(requestParameters.stepviewRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewListResultModelFromJSON(jsonValue));
    }

    /**
     * List all stepviews
     * List all stepviews
     */
    stepviewList = async(requestParameters: StepviewListRequest): Promise<StepviewListResultModel> => {
        const response = await this.stepviewListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a stepview by its id
     * Retrieve an stepview by its id
     */
    async stepviewRetrieveRaw(requestParameters: StepviewRetrieveRequest): Promise<runtime.ApiResponse<StepviewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a stepview by its id
     * Retrieve an stepview by its id
     */
    stepviewRetrieve = async(requestParameters: StepviewRetrieveRequest): Promise<StepviewModel> => {
        const response = await this.stepviewRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an stepview
     * Update an stepview
     */
    async stepviewUpdateRaw(requestParameters: StepviewUpdateRequest): Promise<runtime.ApiResponse<StepviewCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stepviewUpdate.');
        }

        if (requestParameters.stepviewModel === null || requestParameters.stepviewModel === undefined) {
            throw new runtime.RequiredError('stepviewModel','Required parameter requestParameters.stepviewModel was null or undefined when calling stepviewUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/stepview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StepviewModelToJSON(requestParameters.stepviewModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StepviewCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an stepview
     * Update an stepview
     */
    stepviewUpdate = async(requestParameters: StepviewUpdateRequest): Promise<StepviewCreateSuccessModel> => {
        const response = await this.stepviewUpdateRaw(requestParameters);
        return await response.value();
    }

}
