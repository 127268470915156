import i18next from "i18next";
import * as Yup from "yup";

export const retrieveConnectorSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    url: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .doesntMatterMuch(),
    namespace: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    image_id: Yup.number().typeError(i18next.t('global.validation_schema.select_valid_dropdown_option'))
        .required(i18next.t('global.validation_schema.required')),
    redirect_uri: Yup.string().ensure().when('redirect_image_id', {
        is: value => value == null,
        then: Yup.string().doesntMatterMuch(),
        otherwise: Yup.string().required(i18next.t('global.validation_schema.required')).doesntMatterMuch()
    }),
    redirect_image_id: Yup.number().nullable().when('redirect_uri', {
        is: value => value !== "",
        then: Yup.number().typeError(i18next.t('global.validation_schema.select_valid_dropdown_option')).required(i18next.t('global.validation_schema.required'))
    }),
}, [['redirect_uri', 'redirect_image_id']]);