import MultiSelectOption from "interface/MultiSelectOption";
import moment, { Moment } from "moment";

export enum FilterTypes {
    STRING = 'string',
    BOOLEAN = 'boolean',
    ENUM = 'enum',
    DATE_RANGE = 'date_range',
    DATETIME_RANGE = 'datetime-range',
    MULTI_SELECT = 'multi_select'
}

export interface FilterColumnVars {
    key: string;
    type: FilterTypes;
    label: string;
    inputValue: any;
}

class FilterColumn {
    key: string;
    type: FilterTypes;
    label: string;
    values?: any;
    inputValue: any;
    isAlwaysActive: boolean;

    //async variables
    isAsync: boolean = false;
    rawValues: any;
    asyncValues?: (input?: string) => Promise<any>;
    asyncValueMapper?: (value: any) => any;
    hasValuesFetched = false;
    asyncInputFetched?: string;
    private isFetchingValues = false;

    constructor(key: string, type: FilterTypes, label: string, values: any = null) {
        this.key = key;
        this.type = type;
        this.label = label;
        this.values = values;
        this.inputValue = '';        
        this.isAlwaysActive = false;
    }

    static makeFilter(key: string, type: FilterTypes, label: string, values: any = null): FilterColumn {
        switch (type) {
            case FilterTypes.DATE_RANGE:
                return new DateRangeFilter(key, type, label);
            case FilterTypes.DATETIME_RANGE:
                return new TimeRangeFilter(key, type, label);
            case FilterTypes.ENUM:
                return new EnumFilter(key, type, label, values);
            case FilterTypes.BOOLEAN:
                return new BooleanFilter(key, type, label, values);
            case FilterTypes.MULTI_SELECT:
                return new MultiSelectFilter(key, label, values);
            default:
                return new FilterColumn(key, type, label, values);
        }
    }

    setInputValue(inputValue: any): FilterColumn {
        this.inputValue = inputValue;
        return this;
    }

    setIsAlwaysActive(isAlwaysActive: boolean, initialInputValue: any): FilterColumn {
        this.isAlwaysActive = isAlwaysActive;
        this.inputValue = initialInputValue;
        return this;
    }

    configJsonInput(inputValue: any, values: any = null) {
        this.inputValue = inputValue;
        this.values = values;
    }

    static makeAsyncFilter(key: string, type: FilterTypes, label: string, asyncValues?: (input?: string) => Promise<any>, asyncValueMapper?: (value: any) => any): FilterColumn {
        const filter = FilterColumn.makeFilter(key, type, label);
        filter.asyncValues = asyncValues;
        filter.asyncValueMapper = asyncValueMapper;
        filter.isAsync = true;
        return filter;
    }

    removeFilter() {
        this.inputValue = '';
    }

    setValues(filters: any): any {
        filters[this.key] = this.inputValue;
        return filters;
    }

    hasAsync(): boolean {
        return this.asyncValues != null && this.asyncValueMapper != null;
    }

    hasInput(): boolean {
        // eslint-disable-next-line
        return this.inputValue != '';
    }

    fetchValues = async (callback?: () => void, input?: string) => {
        if (!this.isFetchingValues) {
            this.isFetchingValues = true;
            this.asyncValues?.(input)
                .then((values) => {
                    this.values = this.asyncValueMapper?.(values);
                    // eslint-disable-next-line
                    if (input == null) {
                        this.rawValues = this.asyncValueMapper?.(values); // make a copy
                    }
                    this.hasValuesFetched = true;
                    this.asyncInputFetched = input;
                    this.isFetchingValues = false;
                    callback?.()
                })
                .catch((e) => {
                    console.error('FilterColumn couldn\'t fetch asyncValues because', e);
                })
        }
    }

    toJson() {
        return {
            key: this.key,
            label: this.label,
            type: this.type,
            inputValue: this.inputValue,
            isAlwaysActive: this.isAlwaysActive
        }
    }

    static fromJson(json: FilterColumnVars): FilterColumn {
        const col = FilterColumn.makeFilter(json.key, json.type, json.label, null);
        col.configJsonInput(json.inputValue);
        return col;
    }

    merge(newColumn: FilterColumn) {
        if (this.values == null) {
            this.values = newColumn.values;
        }
        if (this.inputValue == null) {
            this.inputValue = newColumn.inputValue;
        }
        if (this.asyncValues == null) {
            this.asyncValues = newColumn.asyncValues;
        }
        if (this.asyncValueMapper == null) {
            this.asyncValueMapper = newColumn.asyncValueMapper;
        }
        this.isAlwaysActive = newColumn.isAlwaysActive;
        this.isAsync = newColumn.isAsync;
        return this;
    }

    getInputValue(): any {
        return this.inputValue;
    }

}

export class DateRangeFilter extends FilterColumn {

    protected dateFormat = 'YYYY-MM-DD HH:mm:ss';

    startValue?: Moment;
    endValue?: Moment;

    constructor(key: string, type: FilterTypes = FilterTypes.DATE_RANGE, label: string) {
        super(key, type, label, null);
        this.startValue = null;
        this.endValue = null;
    }

    setInitialValues(): DateRangeFilter {
        this.startValue = moment().subtract(1, 'hours');
        this.endValue = moment();
        return this;
    }
    
    setValues(filters: any): any {
        filters[ 'from_'+ this.key ] = this.startValue?.toDate();
        filters[ 'until_'+ this.key ] = this.endValue?.toDate();
        return filters;
    }

    hasInput(): boolean {
        return this.startValue != null && this.endValue != null
    }

    removeFilter(): void {
        this.startValue = null;
        this.endValue = null;
    }

    toJson() {
        if (this.hasInput()) {
            return {
                ...super.toJson(),
                inputValue: {
                    start: this.startValue.format( this.dateFormat ),
                    end: this.endValue.format( this.dateFormat )
                }
            }
        } else {
            return super.toJson();
        }
    }

    configJsonInput(inputValue: any, values: any = null) {
        this.values = values;
        if (inputValue.start) {
            this.startValue = moment(inputValue.start);
        }
        if (inputValue.end) {
            this.endValue = moment(inputValue.end);
        }
    }

    merge(newColumn: DateRangeFilter) {
        if (this.startValue == null) {
            this.startValue = newColumn.startValue;
        }
        if (this.endValue == null) {
            this.endValue = newColumn.endValue;
        }
        return super.merge(newColumn);
    }

    getInputValue(): string {
        return this.startValue?.toString()+'-'+this.endValue?.toString();
    }

}

export class TimeRangeFilter extends DateRangeFilter {

    setValues(filters: any): any {
        filters[ 'from_'+ this.key ] = this.startValue.format('YYYY-MM-DD HH:mm:ss');
        filters[ 'until_'+ this.key ] = this.endValue.format('YYYY-MM-DD HH:mm:ss');
        return filters;
    }

}

export class EnumFilter extends FilterColumn {

    values: Record<string, string> = {};

    constructor(key: string, type: FilterTypes, label: string, values: Record<string, string> = null) {
        super(key, type, label, values);
        this.values = values ?? {};
    }

    setValues(filters: any): any {
        filters[this.key] = [ this.inputValue ];
        return filters;
    }

    removeFilter() {
        this.inputValue = '';
    }

    hasInput(): boolean {
        return this.inputValue !== '';
    }

}

export class BooleanFilter extends FilterColumn {

    hasInput(): boolean {
        if (this.inputValue !== "") {
            return this.inputValue === true || this.inputValue === false;
        }
        return false;

    }

}

export class MultiSelectFilter extends FilterColumn {

    values: MultiSelectOption[] = [];
    inputValue: MultiSelectOption[] = [];
    rawValues: MultiSelectOption[] = [];

    constructor(key: string, label: string, values: MultiSelectOption[] = []) {
        super(key, FilterTypes.MULTI_SELECT, label, values);
        this.values = values;
        this.inputValue = [];
    }

    removeFilter() {
        this.inputValue = [];
    }

    setValues(filters: any): any {
        filters[this.key] = this.inputValue.map(option => option.value);
        return filters;
    }

}

export default FilterColumn;