import React, { PropsWithChildren } from 'react';
import BaseDialogActions, { BaseDialogActionsProps } from 'components/base/BaseDialogActions';

export interface SaveDialogActionsProps extends BaseDialogActionsProps {
}

const SaveDialogActions: React.FC<SaveDialogActionsProps> = (props: PropsWithChildren<SaveDialogActionsProps>) => {

    const {children, onModalClosePress, onConfirm} = props

    return (
        <BaseDialogActions hasCancel hasSave onModalClosePress={onModalClosePress} onConfirm={onConfirm}>
            {children}
        </BaseDialogActions>
    );
};

export default SaveDialogActions;