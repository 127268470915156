/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiTextUpdateModel,
    ApiTextUpdateModelFromJSON,
    ApiTextUpdateModelFromJSONTyped,
    ApiTextUpdateModelToJSON,
    ConnectorContainerUpdateModelAllOf,
    ConnectorContainerUpdateModelAllOfFromJSON,
    ConnectorContainerUpdateModelAllOfFromJSONTyped,
    ConnectorContainerUpdateModelAllOfToJSON,
} from './';


export interface ConnectorContainerUpdateModelInterface {
    text?: string | null;
    image_version_id?: number | null;
    status?: string;
}

/**
 * 
 * @export
 * @interface ConnectorContainerUpdateModel
 */
export class ConnectorContainerUpdateModel extends BaseModel<ConnectorContainerUpdateModelInterface> {

    /**
     * WYSWYG text
     * @type {string}
     * @memberof ConnectorContainerUpdateModel
     */
    text?: string | null;
    /**
     * id of the image version
     * @type {number}
     * @memberof ConnectorContainerUpdateModel
     */
    image_version_id?: number | null;
    /**
     * Last known status of the container
     * @type {string}
     * @memberof ConnectorContainerUpdateModel
     */
    status?: string;

    constructor(data?: ConnectorContainerUpdateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorContainerUpdateModel, value: StringDateInterface<ConnectorContainerUpdateModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorContainerUpdateModelFromJSON(json: any): ConnectorContainerUpdateModel {
    return ConnectorContainerUpdateModelFromJSONTyped(json, false);
}

export function ConnectorContainerUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorContainerUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorContainerUpdateModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerUpdateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'image_version_id': !exists(json, 'image_version_id') ? undefined : Number(json['image_version_id']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    });
}

export function ConnectorContainerUpdateModelToJSON(value?: ConnectorContainerUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorContainerUpdateModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerUpdateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'text': value.text,
        'image_version_id': value.image_version_id,
        'status': value.status,
    } as StringDateInterface<ConnectorContainerUpdateModel>;
    return createClass.toJson(value, model);
}


