import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { RoleRetrieveListModel, RoleRetrieveListModelInterface, UserCreateSuccessModel } from 'api';
import NotificationCenter from 'services/NotificationCenter';
import UserState from 'reducers/interface/UserState';
import UserActions from 'reducers/types/User';
import TabManager, { TabView } from 'components/tabs/TabManager';
import UserDetails from './components/UserDetails';
import ReloadDataButton from 'components/ReloadDataButton';
import RoleActions from 'reducers/types/Role';
import UserDetailsDialog from 'views/Dialog/UserDetailsDialog';
import UserCreateForm from 'views/CreationForms/UserCreateForm';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import User from 'api/override/UserModel';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import RoleState from 'reducers/interface/RoleState';
import DetailsViewHeader from 'components/DetailsViewHeader';

interface StateProps {
    roleReducer: RoleState;
    appReducer: AppState;
    userReducer: UserState;
}

interface DispatchProps {
    retrieveUser: (params: string) => void;
    deleteUser: (params: string) => void;
    fetchRoles: (params: RoleRetrieveListModelInterface) => void;
    createUser: (params: User) => void;
    updateUser: (params: User) => void;
    clearUsers: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    user?: User;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class UserView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_USER_UPDATE_FAILURE, 
            ActionTypes.API_USER_DELETE_FAILURE, 
            ActionTypes.API_USER_CREATE_FAILURE,
            ActionTypes.API_USER_RETRIEVE_FAILURE
        ])
        this.loadData();
        this.props.fetchRoles({});
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_USER_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_USER_UPDATE_SUCCESS, 
            this.onUpdateSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_USER_DELETE_SUCCESS, 
            this.onDeleteSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_USER_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({user: action.params}, () => {
            this.updateTabTitle(this.state.user.full_name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const userSuccess: UserCreateSuccessModel = action.params
        this.setState({user: new User(userSuccess.model)})
        this.props.navigate(`/users/${userSuccess?.model?.uuid}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('users.model')}), {variant: 'success'})
        this.props.clearUsers(true);
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('users.model')}), {variant: 'success'})
        this.props.clearUsers(true);
        this.reloadData();
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('users.model')}), {variant: 'success'})
        this.props.clearUsers(true)
        this.props.navigate('/users')
    }

    clearData = () => {}

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveUser(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_USER_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.user != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, userReducer, appReducer, roleReducer } = this.props;
        if(roleReducer.collection.items.length === 0 && appReducer.isFetchingData.get(ActionTypes.API_ROLE_LIST)) {
            return null;
        }
        return (
            <React.Fragment>
                {this.shouldRenderDetails() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_USER_RETRIEVE) || appReducer.isFetchingData.get(ActionTypes.API_ROLE_LIST)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                    <div>
                                    <DetailsViewHeader
                                        model={i18next.t('users.model')}
                                        title={this.state.user?.full_name}
                                    />
                                    <TabManager
                                        items={[
                                            {
                                                title: i18next.t('general.detail'),
                                                link: `/users/${this.state.user?.uuid}`,
                                                view: (
                                                    <UserDetails 
                                                        user={this.state.user} 
                                                        onDelete={this.onDelete}
                                                        onEdit={this.onEdit}
                                                    />
                                                )
                                            },{
                                                title:  i18next.t('history.model'),
                                                link: `/users/${this.state.user?.uuid}/history`,
                                                initialTab: this.isPathIncluded('history'),
                                                view: (
                                                    <HistoryTab 
                                                        model={this.state.user}
                                                    />
                                                )
                                            }
                                        ]}
                                        onTabSwitch={(newTab: TabView) => {
                                            if (newTab.link.includes('history')){
                                                if (userReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_USER_HISTORY_LIST)) {
                                                    this.sendNotification({type: ActionTypes.API_USER_HISTORY_UPDATE}, null);
                                                }
                                            }
                                        }}
                                    />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <UserCreateForm
                                            onFormSubmit={(user: User) => {
                                                this.props.createUser(user);
                                            }}
                                            roles={roleReducer.collection.items}
                                        /> 
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                <UserDetailsDialog
                    user={this.state.user}
                    open={this.state.isEditModalOpen}
                    roles={roleReducer.collection.items}
                    onModalClosePress={this.dialogClosed}
                    onModalSubmit={(user: User) => {
                        user.uuid = this.state.user.uuid
                        if(user.password === ""){
                            delete user.password
                        }
                        this.props.updateUser(user);
                    }}
                />

                <ConfirmDeleteDialog
                    open={this.state.isDeleteModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onConfirm={() => {
                        this.props.deleteUser(this.state.user.uuid)
                    }}
                    title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('users.model')).toLowerCase()})}
                />
                    
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    userReducer: state.users,
    roleReducer: state.roles
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveUser: (params: string) => dispatch(UserActions.CreateUserRetrieve(params)),
        deleteUser: (params: string) => dispatch(UserActions.CreateUserDelete(params)),
        fetchRoles: (params: RoleRetrieveListModelInterface) => dispatch(RoleActions.CreateRoleList(new RoleRetrieveListModel(params))),
        createUser: (params: User) => dispatch(UserActions.CreateUserCreate(params)),
        updateUser: (params: User) => dispatch(UserActions.CreateUserUpdate(params)),
        clearUsers: (params: boolean) => dispatch(UserActions.CreateClearUserList(params))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(UserView, InstanceViewStyles)
            )
        )
    )
);