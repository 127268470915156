import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { ImageModelForModelEnum } from 'api';
import { Formik, Form } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import SingleSelect from 'components/form_controls/SingleSelect';
import Image from 'api/override/ImageModel';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveImageSchema } from 'schemas/Image';
import Constants from 'config/Constants';

interface ImageCreateFormProps {
    onFormSubmit?: (value: Image) => void;
}

const ImageCreateForm = (props: ImageCreateFormProps) => {
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    return (
        <Formik
            initialValues={new Image({
                name: '',
                description: '',
                docker_image: '',
                for_model: ImageModelForModelEnum.Instance
            })}
            validationSchema={retrieveImageSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
                {(formik) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('image.model')})}</h2>
                    
                    <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('image.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('image.description')}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        helperText={formik.touched.description ? formik.errors.description : ""}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                    />

                    <TextFieldStyled 
                        id="docker_image" 
                        name="docker_image"
                        label={t('image.docker_img')}
                        placeholder='bumbal/image'
                        value={formik.values.docker_image}
                        onChange={formik.handleChange}
                        helperText={formik.touched.docker_image ? formik.errors.docker_image : ""}
                        error={formik.touched.docker_image && Boolean(formik.errors.docker_image)}
                    />

                    <SingleSelect 
                        id="for_model" 
                        name="for_model"
                        label={t('image.for_model')}
                        options={Constants.IMAGE_FOR_MODELS()}
                        value={Constants.IMAGE_FOR_MODELS().find(forModel => forModel.value === formik.values?.for_model)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.for_model && Boolean(formik.errors.for_model)}
                        formikHelperText={formik.touched.for_model ? formik.errors.for_model : ""}
                    />
                    
                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('image.model')})}
                    </ButtonStyled>

                </Form>
            }
        </Formik>
    );
};

export default ImageCreateForm;