import NotificationCenter from "./NotificationCenter";

const StateChangeMiddleware = store => next => action => {
    let prevState = store.getState();
    let result = next(action)
    let nextState = store.getState();
    setTimeout(() => NotificationCenter.default.sendStateUpdate(prevState, nextState, action), 1)
    return result;
};

export default StateChangeMiddleware;