import ActionTypes from "config/ActionTypes";
import Client from 'api/override/ClientModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import ClientState from "./interface/ClientState";
import { ClientListSuccess, ClientRetrieveSuccess } from "./types/Client";

const initialState: ClientState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function ClientReducers(state = initialState, action: BaseReducerAction): ClientState {
    switch (action.type) {
        case ActionTypes.API_CLIENT_UPDATE:
        case ActionTypes.API_CLIENT_RETRIEVE:
        case ActionTypes.API_CLIENT_LIST:
        case ActionTypes.API_CLIENT_CREATE:
        case ActionTypes.API_CLIENT_DELETE:
        case ActionTypes.API_CLIENT_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_CLIENT_UPDATE_SUCCESS:
        case ActionTypes.API_CLIENT_CREATE_SUCCESS:
        case ActionTypes.API_CLIENT_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_CLIENT_UPDATE_FAILURE:
        case ActionTypes.API_CLIENT_CREATE_FAILURE:
        case ActionTypes.API_CLIENT_DELETE_FAILURE:
        case ActionTypes.API_CLIENT_LIST_FAILURE:
        case ActionTypes.API_CLIENT_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_CLIENT_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as ClientRetrieveSuccess).params as Client,
                error: undefined
            };

        case ActionTypes.API_CLIENT_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_CLIENT_LIST_SUCCESS:
            const result = action as ClientListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Client>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_CLIENT_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}