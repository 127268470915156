import React from 'react';
import { ProcessModel } from 'api';
import BaseModal, { BaseModalProps } from 'components/base/BaseModal';
import JSONViewer from 'components/JSONViewer';


interface ProcessLogDetailsModalProps extends BaseModalProps{
    processLog?: ProcessModel;
    title?: string;
}

const ProcessLogDetailsModal = (props: ProcessLogDetailsModalProps) => {

    const { processLog, open, title, onModalClosePress } = props;

    return (
        <BaseModal open={open} onModalClosePress={onModalClosePress} title={title}>
            <JSONViewer jsonObject={processLog} />
        </BaseModal>
    );
};

export default ProcessLogDetailsModal;