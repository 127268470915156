import Log from "utils/Log";
import BaseController from "./BaseController";
import { PortApi } from "api";

export default class PortController extends BaseController<{}> {

    getOpenPort = async (): Promise<number | undefined> => {
        try {
            return (await new PortApi().portOpen()).data['port_id'];
        } catch (e) {
            Log(e)
        }
        return undefined;
    }

}