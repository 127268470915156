/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ImageRemoveSuccessModelInterface {
    id?: number;
    message?: string;
}

/**
 * 
 * @export
 * @interface ImageRemoveSuccessModel
 */
export class ImageRemoveSuccessModel extends BaseModel<ImageRemoveSuccessModelInterface> {

    /**
     * Unique per image
     * @type {number}
     * @memberof ImageRemoveSuccessModel
     */
    id?: number;
    /**
     * An important message from The System
     * @type {string}
     * @memberof ImageRemoveSuccessModel
     */
    message?: string;

    constructor(data?: ImageRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageRemoveSuccessModel, value: StringDateInterface<ImageRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageRemoveSuccessModelFromJSON(json: any): ImageRemoveSuccessModel {
    return ImageRemoveSuccessModelFromJSONTyped(json, false);
}

export function ImageRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ImageRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function ImageRemoveSuccessModelToJSON(value?: ImageRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ImageRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'message': value.message,
    } as StringDateInterface<ImageRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


