import BasePageComponent, { componentConnect, ViewProps as BaseViewProps } from 'components/base/BasePageComponent';
import { TableParamsSorting, TableParams } from '../../models/table/TableParams';
import TableViewDelegate from '../../interface/TableViewDelegate';
import _ from "lodash";
import FilterColumn from 'models/table/FilterColumn';
import PagingDelegate from 'interface/PagingDelegate';
import SearchDelegate from 'interface/SearchDelegate';
import SortingDelegate from 'interface/SortingDelegate';
import { RootState } from 'store/AppStore';
import FilterActions from 'reducers/types/Filters';
import NotificationCenter from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import { BaseAction } from 'reducers/interface/ReducerAction';
import { FilterState } from 'reducers/interface/FilterState';

interface StateProps {

}

interface DispatchProps {
    setFiltersAction?: (pageName: string, params: TableParams) => void;
    fetchFilters?: (pageName: string) => void;
}

interface ViewProps {
    delegate: TableViewDelegate;
    filterReducer: FilterState
}

export type BaseTableViewProps = StateProps & DispatchProps & ViewProps & BaseViewProps;

export interface BaseTableViewState {
    
}

export default abstract class BaseTableViewComponent<P, S extends BaseTableViewState = BaseTableViewState, SS = {}> extends BasePageComponent<P & BaseTableViewProps, S & BaseTableViewState, SS> implements SearchDelegate, PagingDelegate, SortingDelegate {

    debouncedFn?: Function = undefined;
    tableParams?: TableParams;
    private lastSearch: string;

    componentDidMount() {
        super.componentDidMount();
        this.configureColumns();
        this.props.fetchFilters?.(this.constructor.name);
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(this, ActionTypes.FETCH_FILTERS, this.configureFilters);
        NotificationCenter.default.addListener(this, ActionTypes.CHANGED_LANGUAGE, this.configureColumns);
    }

    abstract configureColumns: () => void;

    private configureFilters = () => {
        if (!Object.keys(this.props.filterReducer?.params ?? {}).some(val => val === this.constructor.name)) {
            return;
        }
        let reducerFilters: TableParams | undefined = this.props.filterReducer?.params[this.constructor.name];
        if (reducerFilters != null) {
            reducerFilters.mergeFilters(this.getFilterColumns());
        } else {
            reducerFilters = this.getBaseParams();
        }
        this.tableParams = reducerFilters;
        this.lastSearch = this.getSearchString(this.tableParams)
        this.forceUpdate();
        this.setFilters(reducerFilters.filters, reducerFilters.search_text);
    }

    setSearchTerm = (searchTerm: string) => {
        this.setFilters(this.tableParams.filters, searchTerm);
    };

    setFilters = (filters: FilterColumn[], searchText?: string) => {
        this.tableParams?.setFilters(filters).mergeFilters( this.getFilterColumns() );
        this.tableParams?.setSearchText(searchText || '');
        if (this.debouncedFn === undefined) {
            this.debouncedFn = _.debounce(() => {
                if(this.lastSearch === this.getSearchString(this.tableParams) && this.getItemCount() > 0) {
                    return;
                }
                if(this.tableParams != null) {
                    this.props.setFiltersAction?.(this.constructor.name, this.tableParams)
                }
                this.fetchData();
            }, 500);
        }
        this.debouncedFn?.();
    }

    onSortingSet = (key: string, dir: 'asc' | 'desc') => {
        this.tableParams?.setSorting({
            column: key,
            direction: dir
        });
        this.setFilters(this.tableParams.filters, this.tableParams.search_text)
    }

    onSortingClear = () => {
        this.tableParams?.setSorting({
            column: 'id',
            direction: 'asc'
        });
        this.setFilters(this.tableParams.filters, this.tableParams.search_text)
    }

    onPageChanged = (page: number, limit: number) => {
        this.tableParams?.setOffsetForPage(page, limit);
        this.fetchData();
    }

    onChange = (val: string) => {
        this.setFilters(this.tableParams?.filters ?? this.getFilterColumns(), val);
    }

    abstract getData(): void;
    abstract getItemCount(): number;

    private fetchData() {
        this.lastSearch = this.getSearchString(this.tableParams);
        this.getData();
    }

    getSearchString(tableParams: TableParams): string {
        return [
            tableParams?.limit, 
            tableParams?.offset, 
            tableParams?.sorting?.direction, 
            tableParams?.sorting?.column, 
            tableParams?.search_text,
            tableParams?.filters.sort((a, b) => a.key > b.key ? -1 : 1).map((filterCol: FilterColumn) => filterCol.getInputValue())
        ].join('');
    }

    getBaseParams(): TableParams {
        return new TableParams(
            this.getInitialLimit(), 
            this.getInitialOffset(), 
            this.getInitialSorting(), 
            this.getInitialSearch(), 
            this.getFilterColumns()
        );
    }

    getParams = (): TableParams | undefined => {
        return this.tableParams;
    }

    updateListOnDeleteProcessFinished = (action: BaseAction): void => {
        if(action.params?.status === 'finished') {
            this.fetchData()
        }
    }

    getInitialSorting(): TableParamsSorting {
        return new TableParamsSorting('id', 'desc');
    }

    getInitialLimit = (): number => {
        return 25;
    }

    getInitialOffset = (): number => {
        return 0;
    }

    getInitialSearch = (): string => {
        return '';
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.tableParams?.filters ?? [];
    }

}

export function tableConnect(mapStateToProps: (rootState: RootState) => any, mapDispatchToProps: (dispatch: Function) => Record<string, Function>, component: any) {
    return componentConnect((state: RootState) => ({
        filterReducer: state.filter,
        ...mapStateToProps(state)
    }), (dispatch: Function) => {
        return {
            setFiltersAction: (pageName: string, params: TableParams) => dispatch(FilterActions.setFilters(pageName, params)),
            fetchFilters: (pageName: string) => dispatch(FilterActions.fetchFilters(pageName)),
            ...mapDispatchToProps(dispatch)
        }
    }, component);
}