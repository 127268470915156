import React from 'react';
import {
    Card,
    CardContent,
    Container,
    Grid
} from "@mui/material";
import { withRouter } from 'utils/withRouter';
import i18next from '../../services/i18n';
import TableColumn from '../../models/table/TableColumn';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { ViewProps } from 'components/base/BasePageComponent';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import TableFilter from 'components/TableFilter';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import TaskMonitorActions from 'reducers/types/TaskMonitor';
import { ProcessRetrieveListModel } from 'api';
import ProcessMonitorDetailsDialog from 'views/Dialog/ProcessMonitorDetailsDialog';
import TableList from 'components/TableList';
import ActionTypes from 'config/ActionTypes';
import TableStyles from 'styles/TableStyles';
import Process from 'api/override/ProcessModel';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import { TableParamsSorting } from 'models/table/TableParams';
import Constants from 'config/Constants';
import TableHeader from 'components/TableHeader';
import { MonitorEye } from 'mdi-material-ui';
import { withStyles } from 'tss-react/mui';
import TaskMonitorState from 'reducers/interface/TaskMonitorState';

interface StateProps {
    appReducer: AppState;
    tasks: TaskMonitorState;
}

interface DispatchProps {
    listTasks: (params: ProcessRetrieveListModel) => void;
    clearTasks: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    isDetailsModalOpen: boolean;
    process?: Process;
}

class TaskMonitorList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    filterColumns: FilterColumn[] = [];

    constructor(props) {
        super(props);
        this.state = {
            isDetailsModalOpen: false,
            process: null
        };
    }
    
    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'user_full_name', isSortable: true, label: i18next.t('task_monitor.user')}),
            new TableColumn({ key: 'type', isSortable: true, label: i18next.t('general.type')}),
            new TableColumn({ key: 'state', isSortable: true, label: i18next.t('task_monitor.state')}),
            new TableColumn({ key: 'created_at', isSortable: true, label: i18next.t('task_monitor.scheduled_ts'), customValue: (row: Process) => row.createdDate() }),
            new TableColumn({ key: 'started_at', isSortable: true, label: i18next.t('task_monitor.started_ts'), customValue: (row: Process) => row.startedDate() }),
            new TableColumn({ key: 'finished_at', isSortable: true, label: i18next.t('task_monitor.completed_ts'), customValue: (row: Process) => row.finishedDate() })
        ];
    }
    
    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_TASK_MONITOR_LIST_TASKS_FAILURE
        ])
        this.configFilters();
        this.updateTabTitle(i18next.t('task_monitor.model_plural'))
    }

    configFilters = () => {
        this.filterColumns = [
            FilterColumn.makeFilter('type', FilterTypes.STRING, i18next.t('general.type')),
            FilterColumn.makeFilter('state', FilterTypes.ENUM, i18next.t('task_monitor.state'), Constants.BUILD_STATES()),
            FilterColumn.makeFilter('created_at', FilterTypes.DATE_RANGE, i18next.t('task_monitor.scheduled_ts')),
            FilterColumn.makeFilter('started_at', FilterTypes.DATE_RANGE, i18next.t('task_monitor.started_ts')),
            FilterColumn.makeFilter('finished_at', FilterTypes.DATE_RANGE, i18next.t('task_monitor.completed_ts'))
        ];
        // this.setFilters([]);
    }


    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.tasks.collection.items.length
    }
    
    reloadData = () => {
        if(this.getParams() != null) {
            this.props.listTasks( this.getParams().getApiFilter() );
        }
    }

    onDetails = (row: Process) => {
        this.setState({
            isDetailsModalOpen: true,
            process: row
        });
    }

    dialogClosed = () => {
        this.setState({
            isDetailsModalOpen: false
        })
    }

    getInitialSorting(): TableParamsSorting {
        return new TableParamsSorting('created_at', 'desc');
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('task-monitor', this.props.location)){
            this.props.clearTasks();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card  className={classes.root} variant="outlined">
                        <TableHeader
                            entity={i18next.t('task_monitor.model')}
                            headerIcon={<MonitorEye/>}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('task_monitor.model_plural')})  }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('task_monitor.model_plural')})  }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={this.props.tasks.collection.count_filtered}
                                    listItems={this.props.tasks.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_TASK_MONITOR_LIST_TASKS)}
                                    tableParams={ this.getParams() }
                                    delegate={ this }
                                    idColumn={ 'token' } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                <ProcessMonitorDetailsDialog
                    open={this.state.isDetailsModalOpen}
                    onModalClosePress={this.dialogClosed}
                    process={this.state.process}
                />
            </React.Fragment>
        );
    }
    
    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns;
    }

}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    tasks: state.task_monitor
});

const mapDispatchToProps = (dispatch) => {
    return {
        listTasks: (params: ProcessRetrieveListModel) => dispatch(TaskMonitorActions.CreateTaskMonitorList(params)),
        clearTasks: () => dispatch(TaskMonitorActions.CreateClearTasksList())
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(TaskMonitorList, TableStyles)
            )
        )
    )
);