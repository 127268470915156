import React from 'react';
import SmallFormModal from "components/SmallFormModal";
import { useTranslation } from 'react-i18next';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import useDialogStyles from 'styles/DialogStyles';

export interface DbUpdateRequest {
    dbName?: string;
}

interface ConnectorContainerPatchModalProps extends BaseFormModalProps<DbUpdateRequest> {}

const ConnectorContainerPatchModal = (props: ConnectorContainerPatchModalProps) => {
    const {t} = useTranslation();
    const { classes } = useDialogStyles();
    return (
        <SmallFormModal
            {...props}
            open
            title={t('global.title.patch_cc')}
            initialValues={{
                dbName: ''
            }}
            action={ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => {
                return (
                    <div className={classes.fields_container}>
                        <TextFieldStyled 
                            id="dbName" 
                            name="dbName"
                            value={formik.values.dbName}
                            label={t('connector_container.view.db_name')}
                            onChange={formik.handleChange}
                        />
                    </div>
                );
            }}
        />
    )
};

export default ConnectorContainerPatchModal;
