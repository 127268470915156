/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ConnectorInstanceRetrieveListModelInterface {
    offset?: number;
    limit?: number;
}

/**
 * 
 * @export
 * @interface ConnectorInstanceRetrieveListModel
 */
export class ConnectorInstanceRetrieveListModel extends BaseModel<ConnectorInstanceRetrieveListModelInterface> {

    /**
     * Offset
     * @type {number}
     * @memberof ConnectorInstanceRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof ConnectorInstanceRetrieveListModel
     */
    limit?: number;

    constructor(data?: ConnectorInstanceRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorInstanceRetrieveListModel, value: StringDateInterface<ConnectorInstanceRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorInstanceRetrieveListModelFromJSON(json: any): ConnectorInstanceRetrieveListModel {
    return ConnectorInstanceRetrieveListModelFromJSONTyped(json, false);
}

export function ConnectorInstanceRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorInstanceRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorInstanceRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorInstanceRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
    });
}

export function ConnectorInstanceRetrieveListModelToJSON(value?: ConnectorInstanceRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorInstanceRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorInstanceRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'offset': value.offset,
        'limit': value.limit,
    } as StringDateInterface<ConnectorInstanceRetrieveListModel>;
    return createClass.toJson(value, model);
}


