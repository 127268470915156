import React, { PropsWithChildren } from 'react';
import { Button, DialogActions, DialogActionsProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDialogStyles from 'styles/DialogStyles';
import ButtonStyled from 'components/styled/ButtonStyled';
import { CheckAll, CheckCircleOutline, CloseCircleOutline, ContentSaveOutline, DeleteEmptyOutline, PlusCircleOutline } from 'mdi-material-ui';

export interface BaseDialogActionsProps extends DialogActionsProps {
    hasCancel?: boolean;
    hasSave?: boolean;
    hasCreate?: boolean;
    hasDelete?: boolean;
    hasConfirm?: boolean;
    isYesNo?: boolean;
    onModalClosePress: () => void;
    onConfirm?: () => void;
}

const BaseDialogActions: React.FC<BaseDialogActionsProps> = (props: PropsWithChildren<BaseDialogActionsProps>) => {

    const {children, hasCancel, hasSave, hasCreate, hasDelete, hasConfirm, isYesNo, onModalClosePress, onConfirm} = props
    const {classes} = useDialogStyles();
    const { t } = useTranslation();

    return (
        <DialogActions>
            {hasCancel && 
                <Button onClick={onModalClosePress} className={`${classes.button} cancel`} startIcon={<CloseCircleOutline/>}>
                    {t('global.button.cancel')}
                </Button>
            }
            {children}
            {hasSave && 
                <ButtonStyled onClick={onConfirm} type="submit" className={`${classes.button} save`} startIcon={<ContentSaveOutline/>}>
                    {t('global.button.save')}
                </ButtonStyled>
            }
            {hasDelete && 
                <Button onClick={onConfirm} type="submit" className={`${classes.button} error`} startIcon={<DeleteEmptyOutline/>}>
                    {t('global.button.delete')}
                </Button>
            }
            {hasCreate && 
                <ButtonStyled onClick={onConfirm} type="submit" color="primary" className={`${classes.button} create`} startIcon={<PlusCircleOutline/>}>
                    {t('global.button.create')}
                </ButtonStyled>
            }
            {hasConfirm &&
                <ButtonStyled onClick={onConfirm} type="submit" color="primary" className={`${classes.button} create`} startIcon={<CheckAll/>}>
                    {t('global.button.confirm')}
                </ButtonStyled>
            }
            {isYesNo &&
                <>
                <Button onClick={onModalClosePress} className={`${classes.button} cancel`} startIcon={<CloseCircleOutline/>}>
                    {t('general.no')}
                </Button>
                <ButtonStyled onClick={onConfirm} type="submit" color="primary" className={`${classes.button} create`} startIcon={<CheckCircleOutline/>}>
                    {t('general.yes')}
                </ButtonStyled>
                </>
            }
        </DialogActions>
    );
};

export default BaseDialogActions;