import ConnectorContainer from "api/override/ConnectorContainerModel";
import MenuButton from "components/MenuButton";
import Tasks from "config/Tasks";
import { DotsVertical } from "mdi-material-ui";
import { CustomMenuButton } from "models/table/CustomActionButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnectorContainerActions from "reducers/types/ConnectorContainer";
import { useSnackbar } from 'notistack';
import ConfirmDeleteDialog from "views/Dialog/ConfirmDeleteDialog";
import ConnectorContainerPatchModal, { DbUpdateRequest } from "views/Connectors/components/ConnectorContainerPatchModal";

export interface ConnectorContainerMenuProps {
    connectorContainer: ConnectorContainer;
}

const ConnectorContainerMenu = (props: ConnectorContainerMenuProps) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isDbPatchOpen, setIsDbPatchOpen] = useState(false);
    let options: string[] = [
        t('global.button.trigger.create'), 
        t('global.button.trigger.delete'), 
        t('global.button.trigger.redeploy'), 
        t('connector_container.view.reset_creds'), 
        t('connector_container.view.copy_url'),
        props.connectorContainer.is_patched ? null : t('connector_container.view.patch_cc'), 
        t('global.button.redirect')
    ].filter(Boolean)
    // if developer
    options = options.concat(t('general.goto_image'))
    //
    const handleClick = (option: string) => {
        switch (option) {
            case t('global.button.trigger.create'):
                dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob({uuid: props.connectorContainer?.uuid, task: Tasks.CREATE}))
                break;
            case t('global.button.trigger.delete'):
                dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob({uuid: props.connectorContainer?.uuid, task: Tasks.DELETE}))
                break;
            case t('global.button.trigger.redeploy'):
                dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob({uuid: props.connectorContainer?.uuid, task: Tasks.REDEPLOY}))
                break;
            case t('connector_container.view.copy_url'):
                navigator.clipboard.writeText( props.connectorContainer.getFullUrl() )
                enqueueSnackbar(t('global.action.url_added_clipboard'), {variant: 'info'})
                break;
            case t('connector_container.view.patch_cc'):
                setIsDbPatchOpen(true);
                break;
            case t('global.button.redirect'):
                navigate(`/connectors/${props.connectorContainer.connector?.uuid}/instances`)
                break;
            case t('connector_container.view.reset_creds'):
                setIsOpen(true);
                break;
            case t('general.goto_image'):
                navigate(`/images/${props.connectorContainer.connector?.image?.id}`)
                break;
            default:
                throw new Error('How did this happen');
        }
    }
    const confirmDialog = () => {
        dispatch(ConnectorContainerActions.CreateConnectorContainerReset(props.connectorContainer?.uuid));
        closeDialog();
    }
    const confirmPatch = (request: DbUpdateRequest) => {
        dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob({uuid: props.connectorContainer?.uuid, task: Tasks.PATCH, dbName: request.dbName}));
        closeDialog();
    }
    const closeDialog = () => {
        setIsOpen(false)
        setIsDbPatchOpen(false);
    }

    return (
        <>
            <MenuButton
                options={options}
                onClick={ handleClick }
                buttonIcon={ DotsVertical }
            />
            { isOpen ? (
                <ConfirmDeleteDialog
                    open
                    onModalClosePress={closeDialog}
                    onConfirm={confirmDialog}
                    title={t('global.title.reset_config_warning')}
                />
            ) : null }
            { isDbPatchOpen ? (
                <ConnectorContainerPatchModal
                    open
                    onModalClosePress={closeDialog}
                    onModalSubmit={confirmPatch}
                />
            ) : null}
        </>
    )
}

export class ConnectorContainerTableMenuButton extends CustomMenuButton {
    
    constructor() {
        super('menu', 'connectorContainerMenu', 'connector-container-menu', null, [], null)
    }

    render(row: ConnectorContainer) {
        return (
            <ConnectorContainerMenu
                connectorContainer={row}
            />
        )
    }

}

export default ConnectorContainerMenu;