/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    AuthResultModel,
    AuthResultModelFromJSON,
    AuthResultModelToJSON,
    AuthenticateModel,
    AuthenticateModelFromJSON,
    AuthenticateModelToJSON,
    RefreshTokenResultModel,
    RefreshTokenResultModelFromJSON,
    RefreshTokenResultModelToJSON,
} from '../models';

export interface AuthenticateInvalidateTokenRequest {
    uuid: string;
}

export interface AuthenticateSignInRequest {
    authenticateModel: AuthenticateModel;
}

export interface AuthenticateSystemTokenRequest {
    uuid: string;
}

export interface ImpersonateTokenRequest {
    authenticateModel: AuthenticateModel;
}

/**
 * 
 */
export class AuthenticateApi extends runtime.BaseAPI {

    /**
     * Invalidate all JWT tokens of any given user
     * Invalidate all JWT tokens of any given user
     */
    async authenticateInvalidateTokenRaw(requestParameters: AuthenticateInvalidateTokenRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling authenticateInvalidateToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/authenticate/{uuid}/invalidate`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Invalidate all JWT tokens of any given user
     * Invalidate all JWT tokens of any given user
     */
    authenticateInvalidateToken = async(requestParameters: AuthenticateInvalidateTokenRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.authenticateInvalidateTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a fresh new JWT token
     * Refresh your JWT token
     */
    async authenticateRefreshTokenRaw(): Promise<runtime.ApiResponse<RefreshTokenResultModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/authenticate/refresh-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResultModelFromJSON(jsonValue));
    }

    /**
     * Get a fresh new JWT token
     * Refresh your JWT token
     */
    authenticateRefreshToken = async(): Promise<RefreshTokenResultModel> => {
        const response = await this.authenticateRefreshTokenRaw();
        return await response.value();
    }

    /**
     * Sign in using your username password, get a token
     */
    async authenticateSignInRaw(requestParameters: AuthenticateSignInRequest): Promise<runtime.ApiResponse<AuthResultModel>> {
        if (requestParameters.authenticateModel === null || requestParameters.authenticateModel === undefined) {
            throw new runtime.RequiredError('authenticateModel','Required parameter requestParameters.authenticateModel was null or undefined when calling authenticateSignIn.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateModelToJSON(requestParameters.authenticateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultModelFromJSON(jsonValue));
    }

    /**
     * Sign in using your username password, get a token
     */
    authenticateSignIn = async(requestParameters: AuthenticateSignInRequest): Promise<AuthResultModel> => {
        const response = await this.authenticateSignInRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a JWT token with high expiration date
     * Get a JWT token with high expiration date
     */
    async authenticateSystemTokenRaw(requestParameters: AuthenticateSystemTokenRequest): Promise<runtime.ApiResponse<AuthResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling authenticateSystemToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/authenticate/{uuid}/system-token`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultModelFromJSON(jsonValue));
    }

    /**
     * Get a JWT token with high expiration date
     * Get a JWT token with high expiration date
     */
    authenticateSystemToken = async(requestParameters: AuthenticateSystemTokenRequest): Promise<AuthResultModel> => {
        const response = await this.authenticateSystemTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a JWT token for impersonating a user by email
     * Get a JWT token for impersonating a user by email
     */
    async impersonateTokenRaw(requestParameters: ImpersonateTokenRequest): Promise<runtime.ApiResponse<AuthResultModel>> {
        if (requestParameters.authenticateModel === null || requestParameters.authenticateModel === undefined) {
            throw new runtime.RequiredError('authenticateModel','Required parameter requestParameters.authenticateModel was null or undefined when calling impersonateToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/authenticate/impersonate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateModelToJSON(requestParameters.authenticateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultModelFromJSON(jsonValue));
    }

    /**
     * Get a JWT token for impersonating a user by email
     * Get a JWT token for impersonating a user by email
     */
    impersonateToken = async(requestParameters: ImpersonateTokenRequest): Promise<AuthResultModel> => {
        const response = await this.impersonateTokenRaw(requestParameters);
        return await response.value();
    }

}
