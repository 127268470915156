import { WidgetModel, WidgetModelInterface } from "api/models/WidgetModel";
import WidgetController from "controllers/WidgetController";
import Historiable from "interface/Historiable";
import { TableParams, TableParamsSorting } from "models/table/TableParams";
import * as MDIcons from 'mdi-material-ui';
import EnvUtil from "utils/EnvUtil";
import { EmoticonPoop, HelpCircleOutline } from "mdi-material-ui";
import ActionTypes from "config/ActionTypes";
import { AppActions } from "reducers/types/App";
import AppStore from "store/AppStore";
import WidgetField from "./WidgetFieldModel";

export default class Widget extends WidgetModel implements Historiable {

    actionName: string = "widget"
    reducerName: string = "widgets"

    mdIcon: any;
    fields: Array<WidgetField> = []

    constructor(data?: WidgetModelInterface) {
        super(data);
        this.mdIcon = MDIcons[this.icon]
        this.fields = data?.fields as Array<WidgetField> ?? []
        if (this.mdIcon == null) {
            this.mdIcon = EnvUtil.isDevelopment() ? EmoticonPoop : HelpCircleOutline
        }
    }

    static getWidgets = async(): Promise<Array<Widget>> => {
        AppStore.dispatch(AppActions.CreateAction(ActionTypes.API_WIDGET_AJAX_LIST))
        const widgetProps = await (new WidgetController()).listWidgets(new TableParams(100, 0, TableParamsSorting.getDefault(), '', []));
        AppStore.dispatch(AppActions.CreateAction(ActionTypes.API_WIDGET_AJAX_LIST_SUCCESS))
        return widgetProps;
    }

    static prepareCache = async() => {
        Widget.getWidgets()
    }
}