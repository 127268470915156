import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { SettingModel } from 'api';
import EnvUtil from 'utils/EnvUtil';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface SettingDetailsProps {
    setting: SettingModel;
    onDelete?: () => void;
    onEdit?: () => void;
}

const SettingDetails = (props: SettingDetailsProps) => {
    const { t } = useTranslation();
    const { setting, onDelete, onEdit } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('settings.model')}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            <DetailsField propKey={t('settings.key')} propVal={setting?.key} />
            <DetailsField propKey={t('settings.value')} propVal={setting?.value} />
            { !EnvUtil.isProduction() && <DetailsField propKey={t('settings.stage_value')} propVal={setting?.stage_value} /> }
            <DetailsField propKey={t('settings.description')} propVal={setting?.description} />
            <DetailsField propKey={t('settings.group')} propVal={setting?.group} />
        </div>

    );
};

export default SettingDetails;