import React, { PropsWithChildren } from 'react';
import {useTranslation} from "react-i18next";
import SmallModal from './SmallModal';
import ConfirmDialogActions from './dialog_actions/ConfirmDialogActions';

interface ConfirmDialogProps {
    open: boolean;
    onModalClosePress: () => void;
    onConfirm: () => void;
    title?: string
}

const ConfirmDialog = (props: PropsWithChildren<ConfirmDialogProps>) => {
    const { t } = useTranslation();
    const { onModalClosePress, open, onConfirm, title } = props;

    return (
        <SmallModal open={open} onModalClosePress={onModalClosePress} title={title ?? t('global.title.delete_confirm')}>
            <ConfirmDialogActions isYesNo onModalClosePress={onModalClosePress} onConfirm={onConfirm}/>
        </SmallModal>
    );
};

export default ConfirmDialog;