import React, { forwardRef, PropsWithChildren } from 'react'
import {IconButton, IconButtonProps } from '@mui/material'
import { Link } from "react-router-dom";

interface IconButtonStyledProps extends PropsWithChildren<IconButtonProps> {
    url?: string
}

const IconButtonStyled = forwardRef(function IconButtonStyled(props: IconButtonStyledProps, ref: React.ForwardedRef<unknown>) {
    const { url } = props;    
    const otherProps = url != null ? {component: Link, to: url } : {}

    return (
        <IconButton {...otherProps} color="primary" {...props} size="medium">
            {props.children}
        </IconButton>
    );
})

export default IconButtonStyled