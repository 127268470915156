import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useJSONViewerStyles = makeStyles()((theme: Theme) => {
    return {  
        root: {
            margin: '20px 0',
            '& > div': {
                backgroundColor: `${theme.palette.background.paper} !important`,
                padding: 20,
                borderRadius: 10
            }
        }
    }  
})

export default useJSONViewerStyles
