import { ApiResponseModel, LoadBalancerStatusModel, RancherStatusModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface LoadBalancerStatus extends BaseReducerAction {
    type: ActionTypes.API_LOAD_BALANCER_STATUS;
}

export interface LoadBalancerStatusSuccess extends BaseSagaListResponse<LoadBalancerStatusModel> {
    type: ActionTypes.API_LOAD_BALANCER_STATUS_SUCCESS;
}

export interface RancherStatus extends BaseReducerAction {
    type: ActionTypes.API_RANCHER_STATUS;
}

export interface RancherStatusSuccess extends BaseSagaListResponse<RancherStatusModel> {
    type: ActionTypes.API_RANCHER_STATUS_SUCCESS;
}

export interface RedeployBackoffice extends BaseReducerAction {
    type: ActionTypes.API_REDEPLOY_BACKOFFICE;
}

export interface RedeployBackofficeSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_REDEPLOY_BACKOFFICE_SUCCESS;
}

export interface RedeployBackofficeStage extends BaseReducerAction {
    type: ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE;
}

export interface RedeployBackofficeStageSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE_SUCCESS;
}

export default class SystemStatusActions {

    static CreateLoadBalancerStatus(): LoadBalancerStatus {
        return {
            type: ActionTypes.API_LOAD_BALANCER_STATUS
        }
    }

    static CreateLoadBalancerStatusSuccess(params: LoadBalancerStatusModel): LoadBalancerStatusSuccess {
        return {
            type: ActionTypes.API_LOAD_BALANCER_STATUS_SUCCESS,
            params: params
        }
    }

    static CreateRancherStatus(): RancherStatus {
        return {
            type: ActionTypes.API_RANCHER_STATUS
        }
    }

    static CreateRancherStatusSuccess(params: RancherStatusModel): RancherStatusSuccess {
        return {
            type: ActionTypes.API_RANCHER_STATUS_SUCCESS,
            params: params
        }
    }

    static CreateRedeployBackoffice(): RedeployBackoffice {
        return {
            type: ActionTypes.API_REDEPLOY_BACKOFFICE
        }
    }

    static CreateRedeployBackofficeSuccess(params: ApiResponseModel): RedeployBackofficeSuccess {
        return {
            type: ActionTypes.API_REDEPLOY_BACKOFFICE_SUCCESS,
            params: params
        }
    }

    static CreateRedeployBackofficeStage(): RedeployBackofficeStage {
        return {
            type: ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE
        }
    }

    static CreateRedeployBackofficeStageSuccess(params: ApiResponseModel): RedeployBackofficeStageSuccess {
        return {
            type: ActionTypes.API_REDEPLOY_BACKOFFICE_STAGE_SUCCESS,
            params: params
        }
    }
}