import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {Card, CardHeader, Container, LinearProgress} from "@mui/material";
import i18next from "../../services/i18n";
import DnsIcon from '@mui/icons-material/Dns';
import RancherNodeCard from './components/RancherNodeCard';
import RancherGeneralInfo from './components/RancherGeneralInfo';
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import { withSnackbar } from 'notistack';
import {RancherStatusModel} from "api";
import RancherStyles from 'styles/RancherStyles';
import SystemStatusActions from 'reducers/types/SystemStatus';
import AppState from 'reducers/interface/AppState';
import ActionTypes from 'config/ActionTypes';
import { withStyles } from 'tss-react/mui';


interface StateProps {
    appReducer: AppState
    rancherStatus: RancherStatusModel;
}

interface DispatchProps {
    getRancherStatus: () => void;
}

type Props = StateProps & DispatchProps & ViewProps

interface State {
    // define state variables with type
}

class RancherPage extends BasePageComponent<Props, State> {

    componentDidMount() {
        super.componentDidMount();
        this.props.getRancherStatus()
    };

    renderView() {
        const { classes, appReducer } = this.props;
        return (
            <React.Fragment>
                <Container maxWidth={"lg"}>
                    <Card className={classes.card_container} variant="outlined">
                        <CardHeader
                            avatar={ <DnsIcon /> }
                            title={ i18next.t('system_status.page_title')+" - "+i18next.t('system_status.rancher_title') }
                        />
                    </Card>

                    {(appReducer.isFetchingData.get(ActionTypes.API_RANCHER_STATUS)) ?
                        <LinearProgress /> :
                        <RancherGeneralInfo data={this.props.rancherStatus} />
                    }

                    <div className={classes.node_card}>
                        {this.props.rancherStatus &&
                            this.props.rancherStatus.nodes.map(data => {
                                return (
                                    <div key={ 'card-'+ data.name }>
                                        <RancherNodeCard data={data} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    appReducer: state.app,
    rancherStatus: state.system_status.rancher_status
});

const mapDispatchToProps = dispatch => {
    return {
        // getRancherStatus: (params) => dispatch({ type: ActionTypes.API_RANCHER_STATUS, params: params })
        getRancherStatus: () => dispatch(SystemStatusActions.CreateRancherStatus())
    }
};

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(RancherPage, RancherStyles)
            )
        )
    )
);