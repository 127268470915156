import React, { PropsWithChildren } from 'react'
import {Button, ButtonProps } from '@mui/material'

const ButtonStyled = (props: PropsWithChildren<ButtonProps>) => {

    return (
        <Button variant="contained" color="primary" {...props}>
            {props.children}
        </Button>
    )
}

export default ButtonStyled