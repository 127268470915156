import React, { MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Divider, List, ListItem, ListItemText, Drawer, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BaseComponent, { BaseState, ViewProps } from 'components/base/BaseComponent';
import MenuDrawerStyles from 'styles/MenuDrawerStyles';
import i18next from 'i18next';
import TokenUtil from 'utils/TokenUtil';
import EnvUtil from 'utils/EnvUtil';
import { withStyles } from 'tss-react/mui';
import NotificationCenter from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import LightDarkModeSwitch from 'components/LightDarkModeSwitch';

interface MenuProps {
    open: boolean;
    toggleDrawer: (open: boolean) => () => void;
    onChangeThemeMode: () => void;
}

type Props = MenuProps & ViewProps;

interface State extends BaseState {
    open: Record<string, boolean>;
}

interface MenuSection {
    items?: MenuItem[];
    isDivider?: boolean;
}

interface MenuItem {
    url: string;
    items?: MenuItem[];
}

/*
t('mainnav.instances');
t('mainnav.instance-manage');
t('mainnav.central-instances');
t('mainnav.connectors');
t('mainnav.services');
t('mainnav.task-monitor');
t('mainnav.images');
t('mainnav.forms');
t('mainnav.clients');
t('mainnav.properties');
t('mainnav.packages');
t('mainnav.forms');
t('mainnav.trials');
t('mainnav.mails');
t('mainnav.mail-logs');
t('mainnav.wizards');
t('mainnav.widgets');
t('mainnav.settings');
t('mainnav.users');
t('mainnav.history');
t('mainnav.system-status');
t('mainnav.tools');
t('mainnav.load-balancers');
t('mainnav.rancher');
t('mainnav.repositories');
t('mainnav.uptime-monitor');
t('mainnav.graylog');
t('mainnav.python-cron');
t('mainnav.db-transfer');
t('mainnav.sign-out');
*/
class MenuDrawer extends BaseComponent<Props, State> {

    menu: MenuSection[] = [];

    constructor(props) {
        super(props);

        this.state = {
            open: {}
        };
    }

    registerNotificationCenter(): void {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(this, ActionTypes.APP_USER_CHANGED, this.createMenuItems);
        this.createMenuItems();
    }

    createMenuItems = () => {
        TokenUtil.reconstruct();
        let newMenu: MenuSection[] = [
            { items: [
                { url: 'instances', items: [
                    { url: '' },
                    { url: 'instance-manage' }
                ]},
                { url: 'central-instances'},
                { url: 'connectors' },
                { url: 'services' },
                { url: 'task-monitor' },
                { url: 'images'},
                { url: 'forms' }
            ] }
        ];
        if(!EnvUtil.isProduction()) {
            newMenu = newMenu.concat({items: [ 
                { url: 'clients' }, 
                { url: 'properties' }, 
                { url: 'forms' }, 
                { url: 'trials'}, 
                { url: 'mails'}, 
                { url: 'mail-logs'}, 
                { url: 'wizards' }, 
                { url: 'widgets'},
                { url: 'packages'}
            ]})
        } else {
            newMenu = newMenu.concat({items: [
                { url: 'packages'}
            ]})
        }
        newMenu = newMenu.concat({ isDivider: true })
        if(TokenUtil.getTokenUtil().isAdmin()) {
            newMenu = newMenu.concat({items: [ { url: 'settings' }, { url: 'users' }, { url: 'history' } ]})
        } else {
            newMenu = newMenu.concat({items: [ { url: 'users' }, { url: 'history' } ]})
        }
        newMenu = newMenu.concat(
            { isDivider: true },
            { items: [
                { url: 'system-status', items: [
                    { url: 'load-balancers' },
                    { url: 'rancher' },
                    { url: 'repositories' },
                    { url: 'uptime-monitor' },
                ] },
                { url: 'tools', items: [
                    { url: 'python-cron' },
                    TokenUtil.getTokenUtil().isAccountManager() && { url: 'db-transfer' }
                ].filter(Boolean) }
            ]},
            { isDivider: true }, 
            { items: [{url: 'sign-out'}] }
        )
        this.menu = newMenu;
        this.forceUpdate();
    }

    handleClick = (e: MouseEvent<HTMLDivElement>, itemName: string) => {
        const newRec = {
            ...this.state.open,
            [itemName]: this.state.open[itemName] != null ? !this.state.open[itemName] : true
        }
        this.setState({
            open: newRec
        });
        e.stopPropagation();
    };

    render() { // render is moved here so menu doesn't 404
        const { toggleDrawer, classes } = this.props;
        return(
            <Drawer classes={{root: classes.drawer, paper: classes.paper}} ModalProps={{keepMounted:true,disablePortal:true}} open={this.props.open} onClose={toggleDrawer(false)}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    style={{minWidth: 250}}
                >
                    { this.menu.map((section: MenuSection, index: number) => {
                        if (section.items?.length > 0) {
                            return this.renderMenuItems( section.items );
                        } else if (section.isDivider === true) {
                            return <Divider key={'menu_section_'+ index} />
                        } else {
                            return null;
                        }
                    })}
                </div>
                <LightDarkModeSwitch onChange={this.props.onChangeThemeMode}/>
            </Drawer>
        )
    }

    renderView(): React.ReactNode {
        return <></>
    }

    renderMenuItems = (items: MenuItem[], parent?: MenuItem): JSX.Element => {
        return <List dense key={ Math.random() * 10 }>
            { items.map( (menuItem: MenuItem, index: number) => {
                if (menuItem.items?.length > 0) {
                    return <React.Fragment key={'menu_item_'+ index}>
                        <ListItem button onClick={(e: MouseEvent<HTMLDivElement>) => this.handleClick(e, menuItem.url)}>
                            <ListItemText primary={i18next.t(`mainnav.${menuItem.url}`)} />
                            {this.state.open[menuItem.url] === true ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.open[menuItem.url] === true} timeout="auto" unmountOnExit className={ parent !== null ? this.props.classes.nested : '' }>
                            { this.renderMenuItems( menuItem.items, menuItem ) }
                        </Collapse>
                    </React.Fragment>
                } else {
                    const url = parent != null 
                        ? `/${parent.url}/${menuItem.url}`
                        : `/${menuItem.url}`
                    const name = parent != null && menuItem.url === ''
                        ? `mainnav.${parent.url}`
                        : `mainnav.${menuItem.url}`
                    return (
                        <ListItem button key={'menu_item_'+ index + url} to={url} component={Link}>
                            <ListItemText primary={i18next.t(name)} />
                        </ListItem>
                    )
                }
            }) }
        </List>
    }

}
export default withTranslation()(withStyles(MenuDrawer, MenuDrawerStyles));