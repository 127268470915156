/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ClientCreateSuccessModel,
    ClientCreateSuccessModelFromJSON,
    ClientCreateSuccessModelToJSON,
    ClientListResultModel,
    ClientListResultModelFromJSON,
    ClientListResultModelToJSON,
    ClientModel,
    ClientModelFromJSON,
    ClientModelToJSON,
    ClientRemoveSuccessModel,
    ClientRemoveSuccessModelFromJSON,
    ClientRemoveSuccessModelToJSON,
    ClientRetrieveListModel,
    ClientRetrieveListModelFromJSON,
    ClientRetrieveListModelToJSON,
    ClientStatusModel,
    ClientStatusModelFromJSON,
    ClientStatusModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface ClientCreateRequest {
    clientModel: ClientModel;
}

export interface ClientDeleteRequest {
    id: number;
}

export interface ClientHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ClientListRequest {
    clientRetrieveListModel: ClientRetrieveListModel;
}

export interface ClientRetrieveRequest {
    id: number;
}

export interface ClientStatusUpdateRequest {
    id: number;
    clientStatusModel: ClientStatusModel;
}

export interface ClientUpdateRequest {
    id: number;
    clientModel: ClientModel;
}

/**
 * 
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     * Create a new client
     * Create a new client
     */
    async clientCreateRaw(requestParameters: ClientCreateRequest): Promise<runtime.ApiResponse<ClientCreateSuccessModel>> {
        if (requestParameters.clientModel === null || requestParameters.clientModel === undefined) {
            throw new runtime.RequiredError('clientModel','Required parameter requestParameters.clientModel was null or undefined when calling clientCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientModelToJSON(requestParameters.clientModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new client
     * Create a new client
     */
    clientCreate = async(requestParameters: ClientCreateRequest): Promise<ClientCreateSuccessModel> => {
        const response = await this.clientCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a client
     * Delete a client
     */
    async clientDeleteRaw(requestParameters: ClientDeleteRequest): Promise<runtime.ApiResponse<ClientRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a client
     * Delete a client
     */
    clientDelete = async(requestParameters: ClientDeleteRequest): Promise<ClientRemoveSuccessModel> => {
        const response = await this.clientDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a client
     * retrieve a list of historical logs for a client
     */
    async clientHistoryListRaw(requestParameters: ClientHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling clientHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a client
     * retrieve a list of historical logs for a client
     */
    clientHistoryList = async(requestParameters: ClientHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.clientHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all clients
     * List all clients
     */
    async clientListRaw(requestParameters: ClientListRequest): Promise<runtime.ApiResponse<ClientListResultModel>> {
        if (requestParameters.clientRetrieveListModel === null || requestParameters.clientRetrieveListModel === undefined) {
            throw new runtime.RequiredError('clientRetrieveListModel','Required parameter requestParameters.clientRetrieveListModel was null or undefined when calling clientList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientRetrieveListModelToJSON(requestParameters.clientRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientListResultModelFromJSON(jsonValue));
    }

    /**
     * List all clients
     * List all clients
     */
    clientList = async(requestParameters: ClientListRequest): Promise<ClientListResultModel> => {
        const response = await this.clientListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a client by its id
     * Retrieve a client by its id
     */
    async clientRetrieveRaw(requestParameters: ClientRetrieveRequest): Promise<runtime.ApiResponse<ClientModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a client by its id
     * Retrieve a client by its id
     */
    clientRetrieve = async(requestParameters: ClientRetrieveRequest): Promise<ClientModel> => {
        const response = await this.clientRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a client status
     * Update a client status
     */
    async clientStatusUpdateRaw(requestParameters: ClientStatusUpdateRequest): Promise<runtime.ApiResponse<ClientCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientStatusUpdate.');
        }

        if (requestParameters.clientStatusModel === null || requestParameters.clientStatusModel === undefined) {
            throw new runtime.RequiredError('clientStatusModel','Required parameter requestParameters.clientStatusModel was null or undefined when calling clientStatusUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientStatusModelToJSON(requestParameters.clientStatusModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a client status
     * Update a client status
     */
    clientStatusUpdate = async(requestParameters: ClientStatusUpdateRequest): Promise<ClientCreateSuccessModel> => {
        const response = await this.clientStatusUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a client
     * Update a client
     */
    async clientUpdateRaw(requestParameters: ClientUpdateRequest): Promise<runtime.ApiResponse<ClientCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientUpdate.');
        }

        if (requestParameters.clientModel === null || requestParameters.clientModel === undefined) {
            throw new runtime.RequiredError('clientModel','Required parameter requestParameters.clientModel was null or undefined when calling clientUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientModelToJSON(requestParameters.clientModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a client
     * Update a client
     */
    clientUpdate = async(requestParameters: ClientUpdateRequest): Promise<ClientCreateSuccessModel> => {
        const response = await this.clientUpdateRaw(requestParameters);
        return await response.value();
    }

}
