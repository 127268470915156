import moment from "moment";
import { de, enGB, fr, nl } from 'date-fns/locale';
import { format as formatDate, formatDistance, parseISO } from 'date-fns';
import i18next from 'i18next';
import i18n from "services/i18n";

const DateTimeFormatter = (date: Date | string) => {
    const dateFormat = i18next.t('datetime-format');
    return moment(date).format( dateFormat !== 'datetime-format' ? dateFormat : 'llll' );
}

const DateFnsLocale = {'de': de, 'en': enGB, 'fr': fr, 'nl-NL': nl};

export const dateTimeFormatDistance = (date: Date | string, baseDate: number | Date, locale?: string, options?: {includeSeconds?: boolean; addSuffix?: boolean}): string => {
    let formattedDate = (date instanceof Date) ? date :  parseISO(date);
    return formatDistance(formattedDate, baseDate, {...options, locale: DateFnsLocale[locale ?? i18n.language]})
}

export const dateTimeFormat = (date: Date | string, format: string, locale?: string) => {
    let formattedDate = (date instanceof Date) ? date :  parseISO(date);
    return formatDate(formattedDate, format, {locale:DateFnsLocale[locale ?? i18n.language]})
}

export default DateTimeFormatter;