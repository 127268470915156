/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ProcessListFilterModelInterface {
    state?: Array<string>;
    type?: string;
    user?: string;
    from_created_at?: Date;
    until_created_at?: Date;
    from_started_at?: Date;
    until_started_at?: Date;
    from_finished_at?: Date;
    until_finished_at?: Date;
}

/**
 * 
 * @export
 * @interface ProcessListFilterModel
 */
export class ProcessListFilterModel extends BaseModel<ProcessListFilterModelInterface> {

    /**
     * String containing state
     * @type {Array<string>}
     * @memberof ProcessListFilterModel
     */
    state?: Array<string>;
    /**
     * Type of action performed
     * @type {string}
     * @memberof ProcessListFilterModel
     */
    type?: string;
    /**
     * UUID of user to query
     * @type {string}
     * @memberof ProcessListFilterModel
     */
    user?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    from_created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    until_created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    from_started_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    until_started_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    from_finished_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessListFilterModel
     */
    until_finished_at?: Date;

    constructor(data?: ProcessListFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ProcessListFilterModel, value: StringDateInterface<ProcessListFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ProcessListFilterModelFromJSON(json: any): ProcessListFilterModel {
    return ProcessListFilterModelFromJSONTyped(json, false);
}

export function ProcessListFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessListFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ProcessListFilterModel;
    try {
        let localClass = require.context('../override', true)('./ProcessListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'state': !exists(json, 'state') ? undefined : json['state'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'from_created_at': !exists(json, 'from_created_at') ? undefined : (new Date(json['from_created_at'].replace(' ', 'T'))),
        'until_created_at': !exists(json, 'until_created_at') ? undefined : (new Date(json['until_created_at'].replace(' ', 'T'))),
        'from_started_at': !exists(json, 'from_started_at') ? undefined : (new Date(json['from_started_at'].replace(' ', 'T'))),
        'until_started_at': !exists(json, 'until_started_at') ? undefined : (new Date(json['until_started_at'].replace(' ', 'T'))),
        'from_finished_at': !exists(json, 'from_finished_at') ? undefined : (new Date(json['from_finished_at'].replace(' ', 'T'))),
        'until_finished_at': !exists(json, 'until_finished_at') ? undefined : (new Date(json['until_finished_at'].replace(' ', 'T'))),
    });
}

export function ProcessListFilterModelToJSON(value?: ProcessListFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ProcessListFilterModel;
    try {
        let localClass = require.context('../override', true)('./ProcessListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'state': value.state,
        'type': value.type,
        'user': value.user,
        'from_created_at': value.from_created_at === undefined ? undefined : (value.from_created_at.toApiFormat()),
        'until_created_at': value.until_created_at === undefined ? undefined : (value.until_created_at.toApiFormat()),
        'from_started_at': value.from_started_at === undefined ? undefined : (value.from_started_at.toApiFormat()),
        'until_started_at': value.until_started_at === undefined ? undefined : (value.until_started_at.toApiFormat()),
        'from_finished_at': value.from_finished_at === undefined ? undefined : (value.from_finished_at.toApiFormat()),
        'until_finished_at': value.until_finished_at === undefined ? undefined : (value.until_finished_at.toApiFormat()),
    } as StringDateInterface<ProcessListFilterModel>;
    return createClass.toJson(value, model);
}


