import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useTrialSectionHeaderStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            height: 40,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            color: '#fff',
            fontWeight: 700,
            borderRadius: 5,
            marginBottom: 22,
            '&.open': {
                background: '#0C95F7'
            },
            '&.accepted': {
                background: '#51D231'
            },
            '& > button': {
                position: 'absolute',
                right: 0,
                padding: 10,
                color: '#fff'
            }
        },
        chevron: {
            transition: 'transform .2s ease-in',
            '&.up': {
                transform: 'rotate(-180deg)'
            }
        }
    }
})

export default useTrialSectionHeaderStyles