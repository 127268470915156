import { ServiceContainerRetrieveListModel } from "api";
import Instance from "api/override/InstanceModel";
import ServiceContainer from "api/override/ServiceContainerModel";
import TableList from "components/TableList";
import ActionTypes from "config/ActionTypes";
import useTableDelegateHook from "hooks/TableDelegateHook";
import TableColumn from 'models/table/TableColumn';
import { TableParams } from "models/table/TableParams";
import i18next from 'i18next';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceContainerActions from "reducers/types/ServiceContainer";
import { CustomIconButton } from "models/table/CustomActionButton";
import { Details } from 'mdi-material-ui';
import { RootState } from "store/AppStore";
import AppState from "reducers/interface/AppState";
import { ServiceContainerTableMenuButton } from "./ServiceContainerMenu";
import InstanceActions from "reducers/types/Instance";
import ImageVersionColumn from "components/table_columns/ImageVersionColumn";
import ConfirmDeleteDialog from "views/Dialog/ConfirmDeleteDialog";
import ServiceNameLabel from "views/Services/components/ServiceNameLabel";

interface InstanceServiceContainersListProps {
    instance: Instance;
    onRowClick: (serviceContainer: ServiceContainer) => void;
    appReducer: AppState;
}
const InstanceServiceContainersList = (props: InstanceServiceContainersListProps) => {
    const [tableParams] = React.useState(TableParams.getIncrementingSelectParams());
    const dispatch = useDispatch();
    const instanceReducer = useSelector((state: RootState) => state.instances)
    const [selectedSC, setSelectedSC] = useState(undefined);

    const delegate = useTableDelegateHook(
        tableParams, {
        getData() {
            dispatch(InstanceActions.CreateInstanceServiceContainerList(
                new ServiceContainerRetrieveListModel(tableParams.getApiFilter()),
                props.instance.uuid
            ))
        },
        onDelete(item: ServiceContainer) {
            setSelectedSC(item);
        },
    });
    const closeDialog = () => {
        setSelectedSC(undefined)
    }
    const deleteServiceContainer = () => {
        dispatch(ServiceContainerActions.CreateServiceContainerDelete(selectedSC.uuid));
        closeDialog();
    }
    const customActionButtons = [
        new CustomIconButton('documentation', i18next.t('connector_container.view.docs_img_version_tooltip'), 'documentation', (name?: string, row?: ServiceContainer) => {
           props.onRowClick(row);
        }, Details),
        new ServiceContainerTableMenuButton()
    ]
    const columns = [
        new TableColumn({ key: 'name', isSortable: false, label: i18next.t('general.name'), customValue: (row: ServiceContainer) => <ServiceNameLabel name={row.service.name} configCorrect={row.config_correct} /> }),
        new TableColumn({ key: 'status', isSortable: false, label: i18next.t('general.status') }),
        new ImageVersionColumn()
    ];
 
    return (
        <>
            <TableList
                hasDeleteAction
                delegate={delegate}
                selectableRows={true}
                columns={columns}
                count={instanceReducer.services.count_unfiltered}
                idColumn={'uuid'}
                customActions={customActionButtons}
                listItems={instanceReducer.services.items}
                isRefreshing={props.appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS)}
                tableParams={tableParams}  
            />
            <ConfirmDeleteDialog 
                title={
                    i18next.t('global.title.delete_confirm_model', { 
                        model: `${selectedSC?.getName()} on ${props.instance?.name}` 
                    })
                }
                open={selectedSC != null}
                onConfirm={deleteServiceContainer}
                onModalClosePress={closeDialog}
            />
        </>
    );
}
export default InstanceServiceContainersList;