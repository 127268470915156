import React from 'react';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import Instance from 'api/override/InstanceModel';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import PasswordTextField from 'components/form_controls/PasswordTextField';
import InstanceAdminPassword from 'models/InstanceAdminPassword';
import { retrieveInstanceAdminPasswordSchema } from 'schemas/InstanceAdminPassword';

interface InstanceAdminPasswordUpdateDialogProps extends BaseFormModalProps<InstanceAdminPassword> {
    instance?: Instance
}

const InstanceAdminPasswordUpdateDialog = (props: InstanceAdminPasswordUpdateDialogProps) => {

    const { t } = useTranslation()
    const { classes } = useDialogStyles()

    return (
        <SmallFormModal {...props}
            title={t('global.action.update_model', { model: t('instance.admin_password') })}
            initialValues={new InstanceAdminPassword()}
            action={ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD}
            validationSchema={retrieveInstanceAdminPasswordSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => (
                <div className={classes.fields_container}>

                    <PasswordTextField
                        propertyName="newPassword"
                        formik={formik}
                        label={t('instance.new_admin_password')}
                    />

                    <PasswordTextField
                        propertyName="confirmPassword"
                        formik={formik}
                        label={t('instance.confirm_new_admin_password')}
                    />
                </div>
            )}
        />
    )
}

export default InstanceAdminPasswordUpdateDialog