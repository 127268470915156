import Service from "api/override/ServiceModel";
import MenuButton from "components/MenuButton";
import Tasks from "config/Tasks";
import { DotsVertical } from "mdi-material-ui";
import { CustomMenuButton } from "models/table/CustomActionButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ServiceActions from "reducers/types/Service";

export interface ServiceMenuProps {
    service: Service;
}

const ServiceMenu = (props: ServiceMenuProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let options: string[] = props.service.standalone ? [t('global.button.trigger.create'), t('global.button.trigger.delete'), t('global.button.trigger.redeploy'), t('global.button.trigger.update')] : []
    options = options.concat(t('global.button.redirect'))
    // if developer
    options = options.concat(t('general.goto_image'))
    //
    const handleClick = (option: string) => {
        switch (option) {
            case t('global.button.trigger.create'):
                dispatch(ServiceActions.CreateServiceTriggerJob({uuid: props.service?.uuid, task: Tasks.CREATE}))
                break;
            case t('global.button.trigger.delete'):
                dispatch(ServiceActions.CreateServiceTriggerJob({uuid: props.service?.uuid, task: Tasks.DELETE}))
                break;
            case t('global.button.trigger.redeploy'):
                dispatch(ServiceActions.CreateServiceTriggerJob({uuid: props.service?.uuid, task: Tasks.REDEPLOY}))
                break;
            case t('global.button.trigger.update'):
                // t('node.service-update-configurations')
                dispatch(ServiceActions.CreateServiceTriggerJob({uuid: props.service?.uuid, task: Tasks.UPDATE}))
                break;
            case t('global.button.redirect'):
                navigate(`/services/${props.service.uuid}`)
                break;
            case t('general.goto_image'):
                navigate(`/images/${props.service.image?.id}`)
                break;
            default:
                throw new Error('How did this happen');
        }
    }

    return (
        <MenuButton
            options={options}
            onClick={ handleClick }
            buttonIcon={ DotsVertical }
        />
    )
}

export class ServiceTableMenuButton extends CustomMenuButton {
    
    constructor() {
        super('menu', 'serviceMenu', 'service-container-menu', null, [], null)
    }

    render(row: Service) {
        return (
            <ServiceMenu
                service={row}
            />
        )
    }

}

export default ServiceMenu;