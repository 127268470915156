import { WidgetFieldModel, WidgetFieldModelInterface } from "api/models"
import Constants from "config/Constants";

export default class WidgetField extends WidgetFieldModel {

    actionName: string = "widget_field"
    reducerName: string = "widget_fields"

    constructor(data?: WidgetFieldModelInterface) {
        super(data);
        this.field = data?.field ?? ''
        this.title = data?.title ?? ''
        this.type = data?.type ?? Constants.WIDGET_FIELD_TYPE().find(type => type.value === 'text')?.value.toString()
        this.optional = data?.optional ?? false
    }
}