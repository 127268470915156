/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    MailCreateSuccessModel,
    MailCreateSuccessModelFromJSON,
    MailCreateSuccessModelToJSON,
    MailListResultModel,
    MailListResultModelFromJSON,
    MailListResultModelToJSON,
    MailModel,
    MailModelFromJSON,
    MailModelToJSON,
    MailRemoveSuccessModel,
    MailRemoveSuccessModelFromJSON,
    MailRemoveSuccessModelToJSON,
    MailRetrieveListModel,
    MailRetrieveListModelFromJSON,
    MailRetrieveListModelToJSON,
} from '../models';

export interface MailCreateRequest {
    mailModel: MailModel;
}

export interface MailDeleteRequest {
    id: number;
}

export interface MailHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface MailListRequest {
    mailRetrieveListModel: MailRetrieveListModel;
}

export interface MailRetrieveRequest {
    id: number;
}

export interface MailUpdateRequest {
    id: number;
    mailModel: MailModel;
}

/**
 * 
 */
export class MailApi extends runtime.BaseAPI {

    /**
     * Create a new mail
     * Create a new mail
     */
    async mailCreateRaw(requestParameters: MailCreateRequest): Promise<runtime.ApiResponse<MailCreateSuccessModel>> {
        if (requestParameters.mailModel === null || requestParameters.mailModel === undefined) {
            throw new runtime.RequiredError('mailModel','Required parameter requestParameters.mailModel was null or undefined when calling mailCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailModelToJSON(requestParameters.mailModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new mail
     * Create a new mail
     */
    mailCreate = async(requestParameters: MailCreateRequest): Promise<MailCreateSuccessModel> => {
        const response = await this.mailCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an mail
     * Delete an mail
     */
    async mailDeleteRaw(requestParameters: MailDeleteRequest): Promise<runtime.ApiResponse<MailRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mailDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an mail
     * Delete an mail
     */
    mailDelete = async(requestParameters: MailDeleteRequest): Promise<MailRemoveSuccessModel> => {
        const response = await this.mailDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an mail
     * retrieve a list of historical logs for an mail
     */
    async mailHistoryListRaw(requestParameters: MailHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mailHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling mailHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an mail
     * retrieve a list of historical logs for an mail
     */
    mailHistoryList = async(requestParameters: MailHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.mailHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all mails
     * List all mails
     */
    async mailListRaw(requestParameters: MailListRequest): Promise<runtime.ApiResponse<MailListResultModel>> {
        if (requestParameters.mailRetrieveListModel === null || requestParameters.mailRetrieveListModel === undefined) {
            throw new runtime.RequiredError('mailRetrieveListModel','Required parameter requestParameters.mailRetrieveListModel was null or undefined when calling mailList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailRetrieveListModelToJSON(requestParameters.mailRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailListResultModelFromJSON(jsonValue));
    }

    /**
     * List all mails
     * List all mails
     */
    mailList = async(requestParameters: MailListRequest): Promise<MailListResultModel> => {
        const response = await this.mailListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a mail by its id
     * Retrieve an mail by its id
     */
    async mailRetrieveRaw(requestParameters: MailRetrieveRequest): Promise<runtime.ApiResponse<MailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mailRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a mail by its id
     * Retrieve an mail by its id
     */
    mailRetrieve = async(requestParameters: MailRetrieveRequest): Promise<MailModel> => {
        const response = await this.mailRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an mail
     * Update an mail
     */
    async mailUpdateRaw(requestParameters: MailUpdateRequest): Promise<runtime.ApiResponse<MailCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mailUpdate.');
        }

        if (requestParameters.mailModel === null || requestParameters.mailModel === undefined) {
            throw new runtime.RequiredError('mailModel','Required parameter requestParameters.mailModel was null or undefined when calling mailUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailModelToJSON(requestParameters.mailModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an mail
     * Update an mail
     */
    mailUpdate = async(requestParameters: MailUpdateRequest): Promise<MailCreateSuccessModel> => {
        const response = await this.mailUpdateRaw(requestParameters);
        return await response.value();
    }

}
