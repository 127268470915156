import React, { useState } from 'react';
import useFormBuilderStyles from 'styles/FormBuilderStyles';
import { useTranslation } from 'react-i18next';
import { FormFieldModelTypeEnum } from 'api';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import PasswordTextField from 'components/form_controls/PasswordTextField';
import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import FormField from 'api/override/FormFieldModel';
import { MinusCircle } from 'mdi-material-ui';
import _ from 'lodash';
import SingleSelect from 'components/form_controls/SingleSelect';
import TextareaStyled from 'components/styled/TextareaStyled';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface PreviewPaneProps {
    fields: Array<FormField>;
    onSelectInput: (field: FormField) => void;
    onRemoveInput: (field: FormField) => void;
}

const PreviewPane = (props: PreviewPaneProps) => {
    const { t } = useTranslation();
    const {classes} = useFormBuilderStyles();
    const [selectedField, setSelectedField] = useState<FormField>(undefined)

    const handleInputClick = (field: FormField) => {
        props.onSelectInput(field)
        setSelectedField(field)
    }

    const handleRemove = (field: FormField) => {
        props.onRemoveInput(field)
    }

    const checkIsFieldSelected = (field: FormField): boolean => selectedField?.input_field_uuid != null && selectedField?.input_field_uuid === field?.input_field_uuid
 
    const renderInput = (field: FormField, index: number): JSX.Element => {

        const inputLabel: string = Boolean(field.label) ? field.label : field.name
        const inputType = field?.type
        let defaultType, defaultPlaceholder: string;
        const inputKey = `${inputType}_${index}`

        switch (inputType) {
            case FormFieldModelTypeEnum.Checkbox:
                return(
                    <BBCheckbox
                        checkboxProps={{
                            checked: false
                        }}
                        label={inputLabel ?? t('general.label')}
                    />
                )
            case FormFieldModelTypeEnum.Radio:
                return(
                    <FormControl key={inputKey} component="fieldset" style={{marginTop: 10}}>
                        <FormLabel component="legend">{inputLabel ?? t('general.label')}</FormLabel>
                        <RadioGroup aria-label="options" name="options1" className={classes.radio_group}>
                            {field.getOptions()?.length === 0 ? 
                            <>
                                <FormControlLabel value="opt1" control={<Radio />} label="Option 1" />
                                <FormControlLabel value="opt2" control={<Radio />} label="Option 2" />
                                <FormControlLabel value="opt3" control={<Radio />} label="Option 3" />
                            </>
                            :
                            <>
                                {field.getOptions()?.map((option: string, i: number) => <FormControlLabel key={`${option}_${i}`} value="opt1" control={<Radio />} label={option} /> )}
                            </>
                            }
                        </RadioGroup>
                    </FormControl>
                )
            case FormFieldModelTypeEnum.Date:
                defaultPlaceholder = t('date-format')
                break;
            case FormFieldModelTypeEnum.Time:
                defaultPlaceholder = t('time-format')
                break;
            case FormFieldModelTypeEnum.DatetimeLocal:
                defaultPlaceholder = t('datetime-format')
                break;
            case FormFieldModelTypeEnum.Email:
                defaultType = 'email'
                break;
            case FormFieldModelTypeEnum.Number:
                defaultType = 'number'
                break;
            case FormFieldModelTypeEnum.Tel:
                defaultType = 'tel'
                break;
            case FormFieldModelTypeEnum.Password:
                return (
                    <PasswordTextField 
                        key={inputKey}
                        label={inputLabel}
                    />
                )
            case FormFieldModelTypeEnum.Select:
                return (
                    <SingleSelect 
                        value={undefined} 
                        label={inputLabel ?? t('general.label')}
                        name={inputLabel ?? t('general.label')}
                        onChange={() => {}} 
                        key={inputKey}
                        autocompleteStyle={{width: '100%'}}    
                    />
                )
            case FormFieldModelTypeEnum.Textarea:
                return (
                    <TextareaStyled
                        key={inputKey}
                        placeholder={inputLabel ?? ''}
                    />
                )
            case FormFieldModelTypeEnum.Url:
                defaultType = 'url'
                break;
            default:
                defaultType = 'text';
                defaultPlaceholder = '';
                break;
        }

        const shouldShrink: boolean = defaultPlaceholder !== ''
        return (
            <TextFieldStyled 
                placeholder={defaultPlaceholder}
                key={inputKey}
                label={inputLabel}
                type={defaultType}
                InputLabelProps={shouldShrink ? { shrink: true } : {}}
            />
        )
    }

    return (
        <div className={classes.preview_pane}>
            {_.sortBy(props.fields, 'position').map((field: FormField, index: number) => 
                <div 
                    key={`input_container_${index}`} 
                    className={`${classes.preview_input} ${checkIsFieldSelected(field) && 'selected'}`} 
                    onClick={() => handleInputClick(field)}
                >
                    {renderInput(field, index)}
                    {checkIsFieldSelected(field) && 
                        <IconButton
                            className={classes.remove_input_btn}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(field)
                            }}
                            size="large">
                            <MinusCircle/>
                        </IconButton>
                    }
                </div>
            )}
        </div>
    );
};

export default PreviewPane;