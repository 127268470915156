import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import SystemStatusState from "./interface/SystemStatusState";

const initialState: SystemStatusState = {
    load_balancer_status: null,
    rancher_status: null,
    error: null,
};

export function SystemStatusReducers(state = initialState, action: BaseReducerAction): SystemStatusState {
    switch (action.type) {
        case ActionTypes.API_LOAD_BALANCER_STATUS:
            return {
                ...state,
                load_balancer_status: null,
                error: null
            };

        case ActionTypes.API_RANCHER_STATUS:
            return {
                ...state,
                rancher_status: null,
                error: null
            };

        case ActionTypes.API_LOAD_BALANCER_STATUS_SUCCESS:
            return {
                ...state,
                load_balancer_status: action.params
            };

        case ActionTypes.API_RANCHER_STATUS_SUCCESS:
            return {
                ...state,
                rancher_status: action.params
            };

        default:
            return state;
    }
}