import { Theme } from '@mui/material/styles';
import LocalStorageManager from 'utils/LocalStorageManager';

const TableFilterStyles = ((theme: Theme) => ({
    search_field: {
        marginLeft: 16,
        width: '86%'
    },
    add_filter_button: {
        float: 'right',
        margin: '4px 0',
        marginRight: 12,
        padding: 10,
        color: theme.palette.primary.main
    },
    remove_all_button: {
        marginLeft: 'auto',
        height: 32
    },
    filter_wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        background: LocalStorageManager.isThemeLight() ? '#e7e7e754' : '#0f0f0f54',
        padding: '4px 20px',
        borderRadius: 10,
        '& #daterangepicker': {
            position: 'fixed',
        },
        '& .daterangepickercontainer ': {        
            width: '290px',
            display: 'inline-block',
            
            '& input': {
                width: '200px'
            }
        }
    },
    filter_row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '8px',
        '& .MuiInput-underline:after, & .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiSelect-icon': {
           display: 'none' 
        }
    } ,
    grid_container: {
        padding: 10,
        alignItems: 'center'
    },
    remove_filter_button: {
        padding: 2,
        marginRight: -8
    }
}));

export default TableFilterStyles