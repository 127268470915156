/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ClusterCapacityModel,
    ClusterCapacityModelFromJSON,
    ClusterCapacityModelFromJSONTyped,
    ClusterCapacityModelToJSON,
    ComponentStatusModel,
    ComponentStatusModelFromJSON,
    ComponentStatusModelFromJSONTyped,
    ComponentStatusModelToJSON,
    NodeModel,
    NodeModelFromJSON,
    NodeModelFromJSONTyped,
    NodeModelToJSON,
} from './';


export interface RancherStatusModelInterface {
    state?: string;
    allocatable?: ClusterCapacityModel;
    requested?: ClusterCapacityModel;
    component_statuses?: Array<ComponentStatusModel>;
    nodes?: Array<NodeModel>;
}

/**
 * 
 * @export
 * @interface RancherStatusModel
 */
export class RancherStatusModel extends BaseModel<RancherStatusModelInterface> {

    /**
     * State of this Rancher Cluster
     * @type {string}
     * @memberof RancherStatusModel
     */
    state?: string;
    /**
     * 
     * @type {ClusterCapacityModel}
     * @memberof RancherStatusModel
     */
    allocatable?: ClusterCapacityModel;
    /**
     * 
     * @type {ClusterCapacityModel}
     * @memberof RancherStatusModel
     */
    requested?: ClusterCapacityModel;
    /**
     * 
     * @type {Array<ComponentStatusModel>}
     * @memberof RancherStatusModel
     */
    component_statuses?: Array<ComponentStatusModel>;
    /**
     * 
     * @type {Array<NodeModel>}
     * @memberof RancherStatusModel
     */
    nodes?: Array<NodeModel>;

    constructor(data?: RancherStatusModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: RancherStatusModel, value: StringDateInterface<RancherStatusModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function RancherStatusModelFromJSON(json: any): RancherStatusModel {
    return RancherStatusModelFromJSONTyped(json, false);
}

export function RancherStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RancherStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RancherStatusModel;
    try {
        let localClass = require.context('../override', true)('./RancherStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'state': !exists(json, 'state') ? undefined : json['state'],
        'allocatable': !exists(json, 'allocatable') ? undefined : ClusterCapacityModelFromJSON(json['allocatable']),
        'requested': !exists(json, 'requested') ? undefined : ClusterCapacityModelFromJSON(json['requested']),
        'component_statuses': !exists(json, 'component_statuses') ? undefined : ((json['component_statuses'] as Array<any>).map(ComponentStatusModelFromJSON)),
        'nodes': !exists(json, 'nodes') ? undefined : ((json['nodes'] as Array<any>).map(NodeModelFromJSON)),
    });
}

export function RancherStatusModelToJSON(value?: RancherStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RancherStatusModel;
    try {
        let localClass = require.context('../override', true)('./RancherStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'state': value.state,
        'allocatable': ClusterCapacityModelToJSON(value.allocatable),
        'requested': ClusterCapacityModelToJSON(value.requested),
        'component_statuses': value.component_statuses === undefined ? undefined : ((value.component_statuses as Array<any>).map(ComponentStatusModelToJSON)),
        'nodes': value.nodes === undefined ? undefined : ((value.nodes as Array<any>).map(NodeModelToJSON)),
    } as StringDateInterface<RancherStatusModel>;
    return createClass.toJson(value, model);
}


