import Log from "utils/Log";
import BaseController from "./BaseController";
import MultiSelectOption from "interface/MultiSelectOption";
import { SystemApi } from "api";

export default class SystemController extends BaseController<MultiSelectOption> {

    fetchNamespaces = async(): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequest()) {
                const response = ((await new SystemApi().namespaceRetrieve()).data ?? []).map(ns => {
                    return {
                        label: ns,
                        value: ns
                    }
                });
                this.cacheResponse(response);
            }
            return this.getItemsForRequest();
        } catch (error) {
            Log(error);
            return [];
        }
    }

}