import { BaseAPI, ConfigInputModel, ConnectorContainerApi, FileUploadModel, PostConfigModel } from "api";
import ConfigData from "api/override/ConfigDataModel";
import ConnectorContainer from "api/override/ConnectorContainerModel";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import { InstanceSaga } from "sagas/InstanceSagas";
import Log from "utils/Log";
import DispatchController from "./DispatchController";

export default class ConnectorContainerController extends DispatchController<ConnectorContainer> {

    modelName: string = 'connectorContainer';
    getApi(): BaseAPI {
        return new ConnectorContainerApi();
    }

    prepareFiles = ( config: ConfigData, postValues: object ): FileUploadModel[] => {
        const fileInputs = config.allowed_input.filter((val: ConfigInputModel) => val.type === 'file');
        let returnValues: FileUploadModel[] = [];
        fileInputs.forEach(element => {
            if (element.type === 'file' && postValues[element.name].base64 != null && postValues[element.name].base64 !== '') {
                returnValues = returnValues.concat(
                    new FileUploadModel({
                        file: element.name + '.php',
                        base64: postValues[element.name].base64
                    })
                );
            }
        });
        return returnValues;
    }

    prepareConfig = (config: ConfigData, postValues: object): object => {
        const fileKeys = config.allowed_input
            .filter((val: ConfigInputModel) => val.type === 'file')
            .map((input: ConfigInputModel) => input.name);
        const filteredValues = Object.keys(postValues).filter((key: string) => !fileKeys.includes(key))
        let returnValues = {};
        filteredValues.forEach((key: string) => {
            let inputModel = config.allowed_input.find((input: ConfigInputModel) => input.name === key);
            if (inputModel?.type === 'dictionary') {
                returnValues[key] = postValues[key].bbMultiSelectToRecord();
            } else if (inputModel?.type === 'boolean') {
                returnValues[key] = Boolean(postValues[key]);
            } else {
                returnValues[key] = postValues[key];
            }
        })
        return returnValues;
    }

    filterUnchanged = (unfilteredConfig: object, reducerConfig: ConfigData): PostConfigModel => {
        const filterUnchangedConfig: PostConfigModel = new PostConfigModel({})
        for (const [key, value] of Object.entries(unfilteredConfig)) {
            if(value !== reducerConfig?.config[key]) {
                filterUnchangedConfig[key] = value
            }
        }
        return filterUnchangedConfig;
    }

    connectorContainerToMultiselect = async (uuid: string, params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequestOnProp(uuid)) {
                const response = await InstanceSaga.connectorContainersList(uuid, params);
                const responseToMultiSelect: MultiSelectOption[] = response['items']?.map(item => {
                    return { value: item.uuid, label: `${item.connector?.name}` }
                });
                this.cacheResponseOnParamsAndProp(responseToMultiSelect, params, uuid);
            }
            return this.getItemsForRequestAndProps(params, uuid);

        } catch (e) {
            Log(e)
        }
        return [];
    }

}