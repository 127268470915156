import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { TableParamsSorting } from '../../models/table/TableParams';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import { MailModel, MailRetrieveListModel } from "api";
import FilterColumn from 'models/table/FilterColumn';
import MailActions from 'reducers/types/Mail';
import MailState from 'reducers/interface/MailState';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { EmailOutline } from 'mdi-material-ui';
import Mail from 'api/override/MailModel';
import HideOnProd from 'components/HideOnProd';
import HideOnStage from 'components/HideOnStage';

interface StateProps {
    mailReducer: MailState;
    appReducer: AppState;
}

interface DispatchProps {
    listMails: (params: MailRetrieveListModel) => void;
    clearMails: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    mail?: Mail;
}

class MailList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    filterColumns: FilterColumn[] = [];

    constructor(props) {
        super(props);
        this.state = {
            mail: undefined
        };
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name')}),
            new TableColumn({ key: 'subject', isSortable: true, label: i18next.t('mails.subject')}),
            new TableColumn({ key: 'event', isSortable: true, label: i18next.t('mails.event')}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_MAIL_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('mails.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
    }

    onDetails = (row: MailModel) => {
        this.props.navigate(`/mails/${row.id}`)
    }

    onCreate = () => {
        this.props.navigate("/mails/create")
    }

    getData = () => { this.reloadData() }

    getItemCount = (): number => {
        return this.props.mailReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listMails( this.getParams().getApiFilter() )
        }
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('mails', this.props.location)){
            this.props.clearMails();
        } 
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if (this.getParams() == null) {
            return null;
        }
        return (
            <HideOnProd>
                <HideOnStage>
                    <React.Fragment>
                        <ReloadDataButton loadData={this.reloadData} />
                        <Container maxWidth={"lg"}>
                            <Card className={classes.root}>
                                <TableHeader
                                    entity={i18next.t('mails.model')}
                                    headerIcon={<EmailOutline/>}
                                    onCreate={this.onCreate}
                                />
                                <CardContent className={classes.table_container}>
                                    <TableFilter
                                        searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('mails.model_plural')}) }
                                        searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('mails.model_plural')}) }
                                        onFilterChange={ this.setFilters }
                                        columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                        searchText={this.tableParams?.search_text}
                                    />
                                    <Grid item xs={12} >
                                        <TableList
                                            hasDetailsAction
                                            columns={this.columns}
                                            count={this.props.mailReducer.collection.count_filtered}
                                            listItems={this.props.mailReducer.collection.items}
                                            isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_MAIL_LIST)}
                                            tableParams={ this.getParams() }
                                            delegate={ this } />
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Container>
                    </React.Fragment>
                </HideOnStage>
            </HideOnProd>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return this.filterColumns;
    }

}

const  mapStateToProps = (state: any) => ({
    mailReducer: state.mails,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch) => {
    return {
        listMails: (params: MailRetrieveListModel) => dispatch(MailActions.CreateMailList(params)),
        clearMails: () => dispatch(MailActions.CreateClearMailList()),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(MailList, TableStyles)
            )
        )
    )
);