/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConditionModel,
    ConditionModelFromJSON,
    ConditionModelFromJSONTyped,
    ConditionModelToJSON,
    StepviewWidgetModel,
    StepviewWidgetModelFromJSON,
    StepviewWidgetModelFromJSONTyped,
    StepviewWidgetModelToJSON,
} from './';


export interface StepviewModelInterface {
    id?: number;
    name?: string;
    position?: number;
    wizard_id?: number;
    next_stepview_id?: number | null;
    condition_id?: number | null;
    condition?: ConditionModel;
    stepview_widgets?: Array<StepviewWidgetModel>;
    branch_id?: number;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface StepviewModel
 */
export class StepviewModel extends BaseModel<StepviewModelInterface> {

    /**
     * id of the stepview
     * @type {number}
     * @memberof StepviewModel
     */
    id?: number;
    /**
     * The name of the stepview
     * @type {string}
     * @memberof StepviewModel
     */
    name?: string;
    /**
     * Level of de🅱th
     * @type {number}
     * @memberof StepviewModel
     */
    position?: number;
    /**
     * The id of the wizard the stepview belongs to
     * @type {number}
     * @memberof StepviewModel
     */
    wizard_id?: number;
    /**
     * The id of the stepview that follows this stepview
     * @type {number}
     * @memberof StepviewModel
     */
    next_stepview_id?: number | null;
    /**
     * The id of the condition bound to this stepview
     * @type {number}
     * @memberof StepviewModel
     */
    condition_id?: number | null;
    /**
     * 
     * @type {ConditionModel}
     * @memberof StepviewModel
     */
    condition?: ConditionModel;
    /**
     * 
     * @type {Array<StepviewWidgetModel>}
     * @memberof StepviewModel
     */
    stepview_widgets?: Array<StepviewWidgetModel>;
    /**
     * The id of the branch this stepview is on
     * @type {number}
     * @memberof StepviewModel
     */
    branch_id?: number;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof StepviewModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof StepviewModel
     */
    updated_at?: Date;

    constructor(data?: StepviewModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewModel, value: StringDateInterface<StepviewModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewModelFromJSON(json: any): StepviewModel {
    return StepviewModelFromJSONTyped(json, false);
}

export function StepviewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewModel;
    try {
        let localClass = require.context('../override', true)('./StepviewModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'position': !exists(json, 'position') ? undefined : Number(json['position']),
        'wizard_id': !exists(json, 'wizard_id') ? undefined : Number(json['wizard_id']),
        'next_stepview_id': !exists(json, 'next_stepview_id') ? undefined : Number(json['next_stepview_id']),
        'condition_id': !exists(json, 'condition_id') ? undefined : Number(json['condition_id']),
        'condition': !exists(json, 'condition') ? undefined : ConditionModelFromJSON(json['condition']),
        'stepview_widgets': !exists(json, 'stepview_widgets') ? undefined : ((json['stepview_widgets'] as Array<any>).map(StepviewWidgetModelFromJSON)),
        'branch_id': !exists(json, 'branch_id') ? undefined : Number(json['branch_id']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function StepviewModelToJSON(value?: StepviewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewModel;
    try {
        let localClass = require.context('../override', true)('./StepviewModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'position': value.position,
        'wizard_id': value.wizard_id,
        'next_stepview_id': value.next_stepview_id,
        'condition_id': value.condition_id,
        'condition': ConditionModelToJSON(value.condition),
        'stepview_widgets': value.stepview_widgets === undefined ? undefined : ((value.stepview_widgets as Array<any>).map(StepviewWidgetModelToJSON)),
        'branch_id': value.branch_id,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<StepviewModel>;
    return createClass.toJson(value, model);
}


