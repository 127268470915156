import { ServiceContainerModel, ServiceContainerModelInterface } from 'api/models';
import Historiable from 'interface/Historiable';
import ImageVersionable from 'interface/ImageVersionable';
import MultiSelectOption from 'interface/MultiSelectOption';
import ImageVersion from './ImageVersionModel';
import BuildableModel from 'interface/BuildableModel';
import Statuses from 'interface/Statuses';

export default class ServiceContainer extends ServiceContainerModel implements ImageVersionable, Historiable, MultiSelectOption, BuildableModel {

    actionName: string='service_container';
    reducerName: string='serviceContainers';

    value: number | string;
    label: string;

    status: string = Statuses.STATUS_BUILD_FAILED.toString();

    constructor(data?: ServiceContainerModelInterface) {
        super(data);
        this.value = this.uuid;
        this.label = '';
        this.status = data?.status ?? Statuses.STATUS_BUILD_FAILED.toString();
    }

    getFullUrl(): string {
        return `https://${this.service.url}.bumbal.eu/${this.instance.domain}/`;
    }

    getDeleteDialog(): string {
        return `${this.service.name.toLowerCase().includes('service') ? ' ' : ' service '}`
    }

    getImageVersionString(): string {
        return (this.image_version as ImageVersion).fullVersionString;
    }

    getName = (): string => {
        return this.service.name;
    }



}