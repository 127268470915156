/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    LoadBalancerStatusModel,
    LoadBalancerStatusModelFromJSON,
    LoadBalancerStatusModelToJSON,
    NamespaceResponseModel,
    NamespaceResponseModelFromJSON,
    NamespaceResponseModelToJSON,
    RancherStatusModel,
    RancherStatusModelFromJSON,
    RancherStatusModelToJSON,
    TransferRequestModel,
    TransferRequestModelFromJSON,
    TransferRequestModelToJSON,
    TransferVerifyRequestModel,
    TransferVerifyRequestModelFromJSON,
    TransferVerifyRequestModelToJSON,
    TransferVerifyResponseModel,
    TransferVerifyResponseModelFromJSON,
    TransferVerifyResponseModelToJSON,
} from '../models';

export interface SystemCopyDatabaseRequest {
    transferRequestModel: TransferRequestModel;
}

export interface SystemCopyDatabaseVerifyRequest {
    transferVerifyRequestModel: TransferVerifyRequestModel;
}

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     * Redeploy the backoffice
     * Redeploy the backoffice
     */
    async backofficeRedeployRaw(): Promise<runtime.ApiResponse<ApiResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/redeploy-backoffice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Redeploy the backoffice
     * Redeploy the backoffice
     */
    backofficeRedeploy = async(): Promise<ApiResponseModel> => {
        const response = await this.backofficeRedeployRaw();
        return await response.value();
    }

    /**
     * Redeploy the backoffice stage
     * Redeploy the backoffice stage
     */
    async backofficeStageRedeployRaw(): Promise<runtime.ApiResponse<ApiResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/redeploy-backoffice-stage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Redeploy the backoffice stage
     * Redeploy the backoffice stage
     */
    backofficeStageRedeploy = async(): Promise<ApiResponseModel> => {
        const response = await this.backofficeStageRedeployRaw();
        return await response.value();
    }

    /**
     * Retrieve the namespaces
     * Retrieve the namespaces
     */
    async namespaceRetrieveRaw(): Promise<runtime.ApiResponse<NamespaceResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/namespaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NamespaceResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the namespaces
     * Retrieve the namespaces
     */
    namespaceRetrieve = async(): Promise<NamespaceResponseModel> => {
        const response = await this.namespaceRetrieveRaw();
        return await response.value();
    }

    /**
     * Start copying database
     * Start copying database
     */
    async systemCopyDatabaseRaw(requestParameters: SystemCopyDatabaseRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.transferRequestModel === null || requestParameters.transferRequestModel === undefined) {
            throw new runtime.RequiredError('transferRequestModel','Required parameter requestParameters.transferRequestModel was null or undefined when calling systemCopyDatabase.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/copy-database`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferRequestModelToJSON(requestParameters.transferRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Start copying database
     * Start copying database
     */
    systemCopyDatabase = async(requestParameters: SystemCopyDatabaseRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.systemCopyDatabaseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check input for instances that match
     * Check input for instances that match
     */
    async systemCopyDatabaseVerifyRaw(requestParameters: SystemCopyDatabaseVerifyRequest): Promise<runtime.ApiResponse<TransferVerifyResponseModel>> {
        if (requestParameters.transferVerifyRequestModel === null || requestParameters.transferVerifyRequestModel === undefined) {
            throw new runtime.RequiredError('transferVerifyRequestModel','Required parameter requestParameters.transferVerifyRequestModel was null or undefined when calling systemCopyDatabaseVerify.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/copy-database/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferVerifyRequestModelToJSON(requestParameters.transferVerifyRequestModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TransferVerifyResponseModelFromJSON(jsonValue));
    }

    /**
     * Check input for instances that match
     * Check input for instances that match
     */
    systemCopyDatabaseVerify = async(requestParameters: SystemCopyDatabaseVerifyRequest): Promise<TransferVerifyResponseModel> => {
        const response = await this.systemCopyDatabaseVerifyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve load balancer status of NGINX & HAProxy
     * Retrieve load balancer status
     */
    async systemRetrieveLoadBalancerStatusRaw(): Promise<runtime.ApiResponse<LoadBalancerStatusModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/retrieve-load-balancer-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadBalancerStatusModelFromJSON(jsonValue));
    }

    /**
     * Retrieve load balancer status of NGINX & HAProxy
     * Retrieve load balancer status
     */
    systemRetrieveLoadBalancerStatus = async(): Promise<LoadBalancerStatusModel> => {
        const response = await this.systemRetrieveLoadBalancerStatusRaw();
        return await response.value();
    }

    /**
     * Retrieve status and metrics of Rancher Cluster
     * Retrieve status and metrics of Rancher Cluster
     */
    async systemRetrieveRancherStatusRaw(): Promise<runtime.ApiResponse<RancherStatusModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/retrieve-rancher-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RancherStatusModelFromJSON(jsonValue));
    }

    /**
     * Retrieve status and metrics of Rancher Cluster
     * Retrieve status and metrics of Rancher Cluster
     */
    systemRetrieveRancherStatus = async(): Promise<RancherStatusModel> => {
        const response = await this.systemRetrieveRancherStatusRaw();
        return await response.value();
    }

    /**
     * Schedule tasks
     * Schedule tasks
     */
    async systemTasksRaw(): Promise<runtime.ApiResponse<ApiResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/system/task`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks
     * Schedule tasks
     */
    systemTasks = async(): Promise<ApiResponseModel> => {
        const response = await this.systemTasksRaw();
        return await response.value();
    }

}
