import React from 'react';
import LastVisitedDetailsPage from 'interface/widgets/LastVisitedDetailsPage';
import BaseWidget, { BaseWidgetProps } from "./BaseWidget";
import WidgetRowView from './WidgetRowView';
import { OpenInApp } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import WidgetUtil from 'utils/WidgetUtil';
import useWidgetStyles from 'styles/WidgetStyles';
import IconButtonStyled from 'components/styled/IconButtonStyled';

interface LastVisitedDetailsWidgetProps extends BaseWidgetProps {}

const LastVisitedDetailsWidget = (props: LastVisitedDetailsWidgetProps) => {
 
    const { data } = props;
    const { t } = useTranslation();
    const {classes} = useWidgetStyles();

    return (
        
        <BaseWidget {...props}>
            <WidgetRowView isHeader>
                <h4>{t('dashboard.widgets.last_visited')}</h4>
            </WidgetRowView>
            {data == null ? 
                <p className={classes.row + ' no'}> {t('dashboard.widgets.no_last_visited')} </p>
            :
               (data as Array<LastVisitedDetailsPage>).map((lastPage: LastVisitedDetailsPage, index: number) => {
                    return (
                        <WidgetRowView key={`lastVisited_${lastPage.detailsPageRecord.name}_${index}`}>
                            <p className={classes.paragraph_with_icon}>
                                {WidgetUtil.getIconForEntity(lastPage.detailsPageRecord.entity)}{lastPage.detailsPageRecord.name}
                            </p>
                            <IconButtonStyled url={lastPage.url} style={{height: 30, width: 30}}>
                                <OpenInApp/>
                            </IconButtonStyled>
                        </WidgetRowView>
                    )
                })
            }
        </BaseWidget>
    );
};

export default LastVisitedDetailsWidget;
