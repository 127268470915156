/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface AuthenticateModelInterface {
    email?: string;
    password?: string;
}

/**
 * 
 * @export
 * @interface AuthenticateModel
 */
export class AuthenticateModel extends BaseModel<AuthenticateModelInterface> {

    /**
     * email address
     * @type {string}
     * @memberof AuthenticateModel
     */
    email?: string;
    /**
     * Password
     * @type {string}
     * @memberof AuthenticateModel
     */
    password?: string;

    constructor(data?: AuthenticateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: AuthenticateModel, value: StringDateInterface<AuthenticateModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function AuthenticateModelFromJSON(json: any): AuthenticateModel {
    return AuthenticateModelFromJSONTyped(json, false);
}

export function AuthenticateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = AuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./AuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    });
}

export function AuthenticateModelToJSON(value?: AuthenticateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = AuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./AuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'email': value.email,
        'password': value.password,
    } as StringDateInterface<AuthenticateModel>;
    return createClass.toJson(value, model);
}


