import Connector from "api/override/ConnectorModel";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import { ConnectorSaga } from "sagas/ConnectorSagas";
import Log from "utils/Log";
import { BaseAPI, ConnectorApi } from "api";
import DispatchController from "./DispatchController";

export default class ConnectorController extends DispatchController<Connector> {

    modelName: string = 'connector';
    getApi(): BaseAPI {
        return new ConnectorApi();
    }

    connectorToMultiselect = async (params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await ConnectorSaga.connectorList(params);
                this.cacheResponse(response.items as Connector[] ?? [], params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }

    getConnector = async (uuid: string): Promise<Connector | undefined> => {
        try {
            return await new ConnectorApi().connectorRetrieve({uuid: uuid}) as Connector;
        } catch (e) {
            Log(e)
        }
        return undefined;
    }

}