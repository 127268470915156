import React, {Component} from 'react';
import { Page404 } from 'components/Page404';
import ActionTypes from 'config/ActionTypes';
import {WithTranslation} from "react-i18next";
import { BaseAction } from 'reducers/interface/ReducerAction';
import NotificationCenter from 'services/NotificationCenter';
import Log from 'utils/Log';
import LocalStorageManager from 'utils/LocalStorageManager';
import { DetailsPageRecord } from 'interface/widgets/LastVisitedDetailsPage';
import { RoutedProps } from 'utils/withRouter';
import { Location } from 'react-router-dom';

interface BaseViewProps {
  classes?: any;
}

export type ViewProps = BaseViewProps & WithTranslation & RoutedProps;

export interface BaseState {
	viewDid404?: boolean;
}



export default abstract class BaseComponent<P = {}, S extends BaseState = {}, SS = {}> extends Component<P & ViewProps, S, SS> {

	failuresToListenFor: ActionTypes[] = []

	private exclusionCaseUrls404: Array<Array<string>> = [
		['instance', 'users'],
		['instance', 'settings']
	]

	constructor(props: P & ViewProps) {
        super(props)
        this.state = {
            ...this.state,
            viewDid404: false
        }
    }

	log = (...logItems: any[]) => {
		Log(this.constructor.name, ...logItems);
	}

	componentDidMount() {
		this.registerNotificationCenter();
	}

	registerNotificationCenter() {
		NotificationCenter.default.addListener(
            this,
            ActionTypes.API_CALL_FAILURE,
            (action) => {
                if(action.params?.status === 404 && this.should404(action)) {
					this.setState({ viewDid404: true })
                }
            }
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.CLEAR_404_ERROR, () => {
                this.setState({viewDid404: false})
            }
        )
    }

	should404(action: BaseAction): boolean {
		for (const arr of this.exclusionCaseUrls404) {
			if(arr.every(str => action.params?.['url'].includes(str))) {
				return false
			}
		}
		return true;
	}

	componentWillUnmount() {
		NotificationCenter.default.removeListener( this );
	}

	sendNotification(action: BaseAction, params: any) {
		NotificationCenter.default.sendNotification(action, params)

	}
    
	isPathIncluded(url: string): boolean {
		return this.props.location.pathname.includes(url)
	}

	isPathIncludedHistory(url: string, location: Location): boolean {
		return location.pathname.includes(url)
	}

	isCreateView(props: any): boolean {
		return props?.params?.slug === 'create'
	}

	isDetailsView(props: any): boolean {
		const url: string = props.location.pathname
		const entity: string = url.split('/')[1].slice(0, -1)
		if(!this.isCreateView(props) && props?.params?.slug != null) {
			if(url.split('/').length < 4) {
				let detailsPageRecord: DetailsPageRecord = null;
				switch (entity) {
					case 'setting':
						detailsPageRecord = {entity: entity, name: props?.[`${entity}Reducer`]?.selectedObject?.key}
						break;
					case 'user':
						detailsPageRecord = {entity: entity, name: props?.[`${entity}Reducer`]?.selectedObject?.full_name}
						break;
					default:
						detailsPageRecord = {entity: entity, name: props?.[`${entity}Reducer`]?.selectedObject?.name}
						break;
				}
				LocalStorageManager.addLastVisitedToStorage({url, detailsPageRecord})
			}
		}
		return !this.isCreateView(props) && props?.params?.slug != null
	}

	abstract renderView(): React.ReactNode;

    render() {
        if (this.state.viewDid404) {
            return <Page404 />
        }
        return this.renderView();
    }
}
