import Setting from "api/override/SettingModel";
import _ from "lodash";

export default class SettingManager {

    static default: SettingManager;

    private _data: Record<string, Setting>;

    constructor(data: Setting[]) {
        this._data = {};
        for (const setting of data) {
            this._data[setting.key!] = setting;
        }
        SettingManager.default = this;
        // this.createFns();
    }

    get = (key: string): Setting | undefined => {
        return this._data[key];
    }

    getAwxAccessToken = (): Setting => {
        return this.get('awx_access_token')!;
    }

    getAwxClientId = (): Setting => {
        return this.get('awx_client_id')!;
    }

    getAwxClientSecret = (): Setting => {
        return this.get('awx_client_secret')!;
    }

    getAwxConfig = (): Setting => {
        return this.get('awx_config')!;
    }

    getAwxHostUrl = (): Setting => {
        return this.get('awx_host_url')!;
    }

    getAwxPassword = (): Setting => {
        return this.get('awx_password')!;
    }

    getAwxRefreshToken = (): Setting => {
        return this.get('awx_refresh_token')!;
    }

    getAwxTokenExpiresAt = (): Setting => {
        return this.get('awx_token_expires_at')!;
    }

    getAwxUsername = (): Setting => {
        return this.get('awx_username')!;
    }

    getClientEmailBcc = (): Setting => {
        return this.get('client_email_bcc')!;
    }

    getClientEmailSubject = (): Setting => {
        return this.get('client_email_subject')!;
    }

    getConditionalClientModelProperties = (): Setting => {
        return this.get('conditional_client_model_properties')!;
    }

    getConditionalInstanceModelProperties = (): Setting => {
        return this.get('conditional_instance_model_properties')!;
    }

    getConnectorProjectId = (): Setting => {
        return this.get('connector_project_id')!;
    }

    getCoreImage = (): Setting => {
        return this.get('core_image')!;
    }

    getDbClusterPassword = (): Setting => {
        return this.get('db_cluster_password')!;
    }

    getDbClusterServer = (): Setting => {
        return this.get('db_cluster_server')!;
    }

    getDbClusterUsername = (): Setting => {
        return this.get('db_cluster_username')!;
    }

    getDefaultPackageId = (): Setting => {
        return this.get('default_package_id')!;
    }

    getDockerhubPassword = (): Setting => {
        return this.get('dockerhub_password')!;
    }

    getDockerhubUsername = (): Setting => {
        return this.get('dockerhub_username')!;
    }

    getEnableInstanceLaunchingOnCreate = (): Setting => {
        return this.get('enable_instance_launching_on_create')!;
    }

    getFloatingIp = (): Setting => {
        return this.get('floating_ip')!;
    }

    getGaleraDirectIp = (): Setting => {
        return this.get('galera_direct_ip')!;
    }

    getGaleraDirectPassword = (): Setting => {
        return this.get('galera_direct_password')!;
    }

    getGaleraDirectUsername = (): Setting => {
        return this.get('galera_direct_username')!;
    }

    getInstanceAllowedPortNames = (): Setting => {
        return this.get('instance_allowed_port_names')!;
    }

    getInstanceCreationRedirect = (): Setting => {
        return this.get('instance_creation_redirect')!;
    }

    getInstanceDefaultEmail = (): Setting => {
        return this.get('instance_default_email')!;
    }

    getJenkinsConfig = (): Setting => {
        return this.get('jenkins_config')!;
    }

    getJenkinsHost = (): Setting => {
        return this.get('jenkins_host')!;
    }

    getJenkinsPassword = (): Setting => {
        return this.get('jenkins_password')!;
    }

    getJenkinsUsername = (): Setting => {
        return this.get('jenkins_username')!;
    }

    getJobsCheckUrls = (): Setting => {
        return this.get('jobs_check_urls')!;
    }

    getLastpassApiKey = (): Setting => {
        return this.get('lastpass_api_key')!;
    }

    getLastpassItPw = (): Setting => {
        return this.get('lastpass_it_pw')!;
    }

    getMailEmailFrom = (): Setting => {
        return this.get('mail_email_from')!;
    }

    getMailName = (): Setting => {
        return this.get('mail_name')!;
    }

    getMailgunKey = (): Setting => {
        return this.get('mailgun_key')!;
    }

    getMongodbPassword = (): Setting => {
        return this.get('mongodb_password')!;
    }

    getMongodbServer = (): Setting => {
        return this.get('mongodb_server')!;
    }

    getMongodbUsername = (): Setting => {
        return this.get('mongodb_username')!;
    }

    getNewBumbalClusterId = (): Setting => {
        return this.get('new_bumbal_cluster_id')!;
    }

    getNewBumbalProjectId = (): Setting => {
        return this.get('new_bumbal_project_id')!;
    }

    getNewRancherSecret = (): Setting => {
        return this.get('new_rancher_secret')!;
    }

    getNewRancherToken = (): Setting => {
        return this.get('new_rancher_token')!;
    }

    getNewRancherUrl = (): Setting => {
        return this.get('new_rancher_url')!;
    }

    getProdEnableMigrations = (): Setting => {
        return this.get('prod_enable_migrations')!;
    }

    getRancherBbSecrets = (): Setting => {
        return this.get('rancher_bb_secrets')!;
    }

    getReactBetaInterface = (): Setting => {
        return this.get('react-beta-interface')!;
    }

    getReactInterface = (): Setting => {
        return this.get('react-interface')!;
    }

    getRollbackJobs = (): Setting => {
        return this.get('rollback_jobs')!;
    }

    getStageIp = (): Setting => {
        return this.get('stage_ip')!;
    }

    getSuperServerIp = (): Setting => {
        return this.get('super_server_ip')!;
    }

    getSuperServerPassword = (): Setting => {
        return this.get('super_server_password')!;
    }

    getSuperServerUsername = (): Setting => {
        return this.get('super_server_username')!;
    }

    getSystemToken = (): Setting => {
        return this.get('system_token')!;
    }

    getToolsProjectId = (): Setting => {
        return this.get('tools_project_id')!;
    }

    getTransipKey = (): Setting => {
        return this.get('transip_key')!;
    }

    getTransipUser = (): Setting => {
        return this.get('transip_user')!;
    }

    getTypesenseApiKey = (): Setting => {
        return this.get('typesense_api_key')!;
    }

    getTypesenseServer = (): Setting => {
        return this.get('typesense_server')!;
    }

    getUsableDbs = (): Setting => {
        return this.get('usable_dbs')!;
    }

    getWebappDockerImage = (): Setting => {
        return this.get('webapp_docker_image')!;
    }

    private createFns() {
        let helperFns = '';
        for (const setting of Object.values(this._data).sort((a, b) => a.getTitle() > b.getTitle() ? 1 : -1)) {
            helperFns += `

                ${_.camelCase('get ' + setting.getTitle())} = (): Setting => {
                    return this.get('${setting.key}')!;
                }`;
        }
        console.log(helperFns)
    }

}
