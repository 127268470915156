/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface TrialFilterModelInterface {
    instance_uuid?: string;
    instance_id?: number;
    status?: TrialFilterModelStatusEnum;
}

/**
 * 
 * @export
 * @interface TrialFilterModel
 */
export class TrialFilterModel extends BaseModel<TrialFilterModelInterface> {

    /**
     * UUID of the instance
     * @type {string}
     * @memberof TrialFilterModel
     */
    instance_uuid?: string;
    /**
     * Trials for a given instance
     * @type {number}
     * @memberof TrialFilterModel
     */
    instance_id?: number;
    /**
     * Active, Cancelled, Extended, Finished
     * @type {string}
     * @memberof TrialFilterModel
     */
    status?: TrialFilterModelStatusEnum;

    constructor(data?: TrialFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TrialFilterModel, value: StringDateInterface<TrialFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TrialFilterModelFromJSON(json: any): TrialFilterModel {
    return TrialFilterModelFromJSONTyped(json, false);
}

export function TrialFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrialFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TrialFilterModel;
    try {
        let localClass = require.context('../override', true)('./TrialFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'instance_uuid': !exists(json, 'instance_uuid') ? undefined : json['instance_uuid'],
        'instance_id': !exists(json, 'instance_id') ? undefined : Number(json['instance_id']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    });
}

export function TrialFilterModelToJSON(value?: TrialFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TrialFilterModel;
    try {
        let localClass = require.context('../override', true)('./TrialFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'instance_uuid': value.instance_uuid,
        'instance_id': value.instance_id,
        'status': value.status,
    } as StringDateInterface<TrialFilterModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum TrialFilterModelStatusEnum {
    Active = 'active',
    Cancelled = 'cancelled',
    Extended = 'extended',
    Finished = 'finished'
}


