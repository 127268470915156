import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import { InstanceRetrieveListModel } from "api";
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import { Docker } from 'mdi-material-ui';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import CentralInstance from 'api/override/CentralInstanceModel';
import CentralInstanceState from 'reducers/interface/CentralInstanceState';
import CentralInstanceActions from 'reducers/types/CentralInstance';
import { Link } from 'react-router-dom';


interface StateProps {
    centralInstanceReducer: CentralInstanceState;
    appReducer: AppState;
}

interface DispatchProps {
    listCentralInstances: (params: InstanceRetrieveListModel) => void;
    clearCentralInstances: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {

}

class CentralInstanceList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_CENTRAL_INSTANCE_LIST_FAILURE
        ]);
        this.updateTabTitle(i18next.t('central_instance.model_plural'))
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('instance.name')}),
            new TableColumn({ key: 'domain', isSortable: true, label: i18next.t('instance.domain'), customValue: (row: CentralInstance) => <Link className={this.props.classes.linkColor} to={row.getUrl()}>{row.getPrettyUrl()}</Link>}),
            new TableColumn({ key: 'fl_cluster_id', isSortable: true, label: i18next.t('central_instance.cluster_name'), customValue: (row: CentralInstance) => row.cluster_name}),
        ];
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.centralInstanceReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null) {
            this.props.listCentralInstances( this.getParams().getApiFilter() )
        }
    };

    onDetails = (row: CentralInstance) => {
        window.open(row.getCentralUrl(), '_blank');
    }

    renderView() {
        const { classes, appReducer, centralInstanceReducer } = this.props;
        if (this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('central_instance.model')}
                            headerIcon={<Docker/>}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('central_instance.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('central_instance.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={centralInstanceReducer.collection.count_filtered}
                                    listItems={centralInstanceReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_CENTRAL_INSTANCE_LIST)}
                                    tableParams={ this.getParams() }
                                    delegate={ this } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                
            </React.Fragment>
        );
    }

}

const  mapStateToProps = (state: any) => ({
    centralInstanceReducer: state.central_instances,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch) => {
    return {
        listCentralInstances: (params: InstanceRetrieveListModel) => dispatch(CentralInstanceActions.CreateCentralInstanceList(params)),
        clearCentralInstances: () => dispatch(CentralInstanceActions.CreateClearCentralInstanceList())
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(CentralInstanceList, TableStyles)
            )
        )
    )
);