/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface PortCorruptionRequestInterface {
    id?: number;
    retry?: boolean;
    instance?: string;
    container?: string;
    uri?: string;
    from_backoffice?: boolean;
}

/**
 * 
 * @export
 * @interface PortCorruptionRequest
 */
export class PortCorruptionRequest extends BaseModel<PortCorruptionRequestInterface> {

    /**
     * Port number
     * @type {number}
     * @memberof PortCorruptionRequest
     */
    id?: number;
    /**
     * Set to true if the request should try to retry registration
     * @type {boolean}
     * @memberof PortCorruptionRequest
     */
    retry?: boolean;
    /**
     * Name of the instance/namespace that the port relates to
     * @type {string}
     * @memberof PortCorruptionRequest
     */
    instance?: string;
    /**
     * (Deprecated) Container name the port corresponds to in Rancher
     * @type {string}
     * @memberof PortCorruptionRequest
     */
    container?: string;
    /**
     * Container name the port corresponds to in Rancher
     * @type {string}
     * @memberof PortCorruptionRequest
     */
    uri?: string;
    /**
     * Set to true if the request shouldn't ask the other environment anymore
     * @type {boolean}
     * @memberof PortCorruptionRequest
     */
    from_backoffice?: boolean;

    constructor(data?: PortCorruptionRequestInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: PortCorruptionRequest, value: StringDateInterface<PortCorruptionRequestInterface>): { [property: string]: any } {
        return value;
    }

}

export function PortCorruptionRequestFromJSON(json: any): PortCorruptionRequest {
    return PortCorruptionRequestFromJSONTyped(json, false);
}

export function PortCorruptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortCorruptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PortCorruptionRequest;
    try {
        let localClass = require.context('../override', true)('./PortCorruptionRequest');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'retry': !exists(json, 'retry') ? undefined : json['retry'],
        'instance': !exists(json, 'instance') ? undefined : json['instance'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'from_backoffice': !exists(json, 'from_backoffice') ? undefined : json['from_backoffice'],
    });
}

export function PortCorruptionRequestToJSON(value?: PortCorruptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PortCorruptionRequest;
    try {
        let localClass = require.context('../override', true)('./PortCorruptionRequest');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'retry': value.retry,
        'instance': value.instance,
        'container': value.container,
        'uri': value.uri,
        'from_backoffice': value.from_backoffice,
    } as StringDateInterface<PortCorruptionRequest>;
    return createClass.toJson(value, model);
}


