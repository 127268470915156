declare global {
    interface String {

        bbCapitalize(): string;
        /**
         * Converts a String to number
         * @returns A number or 0 if the String is undefined or empty
         */
        bbToNumber(): number;
        /**
         * Replaces all instances of the provided searchValue
         * @param searchValue string 
         * @param replaceValue string
         * @returns string with replaces value everywhere it occurs
         */
        bbReplaceAll(searchValue: string, replaceValue: string): string
        /**
         * Replaces all instances of the provided searchValue
         * @param searchValues string[] 
         * @param replaceValue string
         * @returns string with replaced multiple values everywhere they occur
         */
        bbReplaceMultiple(searchValues: string[], replaceValue: string): string
        /**
         * Replaces all underscores with camel casing
         * e.g., example_test => exampleTest
         * @returns string
         */
        bbUnderscoreToCamel(): string
        /**
         * Converts string to slug
         * @returns string
         */
        bbStringToSlug(): string;
        /**
         * Converts underscores to spaces
         * @returns string
         */
        bbUnderscoreToSpace(): string;
    }
}

// eslint-disable-next-line
String.prototype.bbCapitalize = function(): string {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

// eslint-disable-next-line
String.prototype.bbReplaceAll = function(searchValue: string, replaceValue: string): string {
    return this.split(searchValue).join(replaceValue);
}

// eslint-disable-next-line
String.prototype.bbToNumber = function(): number {
    if(this == null || this?.length === 0) {
        return 0; 
    }
    if(Number.isNaN(this)) {
        return 0;
    }
    return +this;
}

// eslint-disable-next-line
String.prototype.bbReplaceMultiple = function(searchValues: string[], replaceValue: string): string {
    let _this = this as string;
    searchValues.forEach(v => _this = _this.split(v).join(replaceValue))
    return _this;
}

// eslint-disable-next-line
String.prototype.bbUnderscoreToCamel = function(): string {
    const parts = this.split('_');
    return parts.map(p => p.charAt(0)?.toUpperCase() + p.slice(1).toLowerCase()).join('')
}

// eslint-disable-next-line
String.prototype.bbStringToSlug = function(): string {
    let _this = this.replace(/^\s+|\s+$/g, '').toLowerCase(); // trim
    
    // remove accents, swap ñ for n, etc
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length ; i < l ; i++) {
        _this = _this.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    _this = _this.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return _this;
}

// eslint-disable-next-line
String.prototype.bbUnderscoreToSpace = function(): string {
    return this.split('_').join(' ');
}

export default String