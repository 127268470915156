/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelFromJSONTyped,
    InstanceModelToJSON,
} from './';


export interface InstanceCreateSuccessModelInterface {
    uuid?: string;
    process_token?: string;
    message?: string;
    model?: InstanceModel;
}

/**
 * 
 * @export
 * @interface InstanceCreateSuccessModel
 */
export class InstanceCreateSuccessModel extends BaseModel<InstanceCreateSuccessModelInterface> {

    /**
     * Unique per Instance
     * @type {string}
     * @memberof InstanceCreateSuccessModel
     */
    uuid?: string;
    /**
     * Process Token
     * @type {string}
     * @memberof InstanceCreateSuccessModel
     */
    process_token?: string;
    /**
     * Resource Level of this Instance
     * @type {string}
     * @memberof InstanceCreateSuccessModel
     */
    message?: string;
    /**
     * 
     * @type {InstanceModel}
     * @memberof InstanceCreateSuccessModel
     */
    model?: InstanceModel;

    constructor(data?: InstanceCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceCreateSuccessModel, value: StringDateInterface<InstanceCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceCreateSuccessModelFromJSON(json: any): InstanceCreateSuccessModel {
    return InstanceCreateSuccessModelFromJSONTyped(json, false);
}

export function InstanceCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./InstanceCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'process_token': !exists(json, 'process_token') ? undefined : json['process_token'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'model': !exists(json, 'model') ? undefined : InstanceModelFromJSON(json['model']),
    });
}

export function InstanceCreateSuccessModelToJSON(value?: InstanceCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./InstanceCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'process_token': value.process_token,
        'message': value.message,
        'model': InstanceModelToJSON(value.model),
    } as StringDateInterface<InstanceCreateSuccessModel>;
    return createClass.toJson(value, model);
}


