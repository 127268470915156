import React, { PropsWithChildren } from 'react';
import { Grid, Typography } from '@mui/material';
import useDialogStyles from 'styles/DialogStyles';

interface ArrayGridItemProps {
    title?: string;
}

const ArrayGridItem = (props: PropsWithChildren<ArrayGridItemProps>) => {
    const { classes } = useDialogStyles();
    return (
        <Grid item xs={12}>
            <Grid container className={classes.add_objects_container + ' center'}>
                <Grid item xs={12}>
                    {props.title != null ? <Typography className={classes.add_object_title}>{props.title}</Typography> : (null)}
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ArrayGridItem;