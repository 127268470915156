import { Divider, IconButton } from "@mui/material";
import WidgetSizes from "config/WidgetSizes";
import { CloseCircle, PlusCircle } from "mdi-material-ui";
import React, { PropsWithChildren, useState } from 'react';
import useWidgetStyles from 'styles/WidgetStyles';
import WidgetUtil from "utils/WidgetUtil";
export interface BaseWidgetProps {
    title?: string;
    type: string;
    isActive: boolean;
    data?: Object | Array<any>;
    editView?: boolean;
    widgetSize?: WidgetSizes;
}

const BaseWidget = (props: PropsWithChildren<BaseWidgetProps>) => {
 
    const {title, editView, type, widgetSize} = props;
    const [isDisplayed, setIsDisplayed] = useState<boolean>(props.isActive)
    const {classes} = useWidgetStyles();
    
    const handleClick = () => {
        if (!isDisplayed) {
            WidgetUtil.addActiveWidget(type)
        } else {
            WidgetUtil.removeActiveWidget(type)
        }
        setIsDisplayed(!isDisplayed)
    }

    return (isDisplayed || editView) && 
    <div className={`${classes.root} ${widgetSize ?? WidgetSizes.S} ${editView ? 'edit_mode' : ''}`}>
        <div className={classes.title_container}>
            <h3>{title}</h3>
            {editView &&
                <IconButton
                    className={`${classes.widget_edit_button} ${isDisplayed ? 'remove' : 'add'}`}
                    onClick={handleClick}
                    size="large">
                    {isDisplayed ? <CloseCircle/> : <PlusCircle/> }
                </IconButton>
            }
        </div>
       <div>
            <div>
                {props.children}
            </div>
       </div>
       <Divider className={classes.divider}/>
    </div>;
};

export default BaseWidget;