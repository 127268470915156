import ActionTypes from "config/ActionTypes";
import Image from "api/override/ImageModel";
import ImageState from "./interface/ImageState";
import { ImageListSuccess, ImageReducerAction } from "./types/Image";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";

const initialState: ImageState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function ImageReducers(state = initialState, action: ImageReducerAction): ImageState {
    switch (action.type) {
        case ActionTypes.API_IMAGE_LIST:
        case ActionTypes.API_IMAGE_HISTORY_LIST:
            return {
                ...state,
                selectedObject: undefined
            };

        case ActionTypes.API_IMAGE_LIST_SUCCESS:
            const result = action as ImageListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Image>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_IMAGE_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_IMAGE_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Image
            };

        case ActionTypes.API_IMAGE_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }

        default:
            return state;
    }
}