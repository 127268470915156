/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    TrialCreateSuccessModelAllOf,
    TrialCreateSuccessModelAllOfFromJSON,
    TrialCreateSuccessModelAllOfFromJSONTyped,
    TrialCreateSuccessModelAllOfToJSON,
    TrialModel,
    TrialModelFromJSON,
    TrialModelFromJSONTyped,
    TrialModelToJSON,
} from './';


export interface TrialCreateSuccessModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: TrialModel;
}

/**
 * 
 * @export
 * @interface TrialCreateSuccessModel
 */
export class TrialCreateSuccessModel extends BaseModel<TrialCreateSuccessModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof TrialCreateSuccessModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof TrialCreateSuccessModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof TrialCreateSuccessModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof TrialCreateSuccessModel
     */
    success?: boolean;
    /**
     * 
     * @type {TrialModel}
     * @memberof TrialCreateSuccessModel
     */
    model?: TrialModel;

    constructor(data?: TrialCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TrialCreateSuccessModel, value: StringDateInterface<TrialCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TrialCreateSuccessModelFromJSON(json: any): TrialCreateSuccessModel {
    return TrialCreateSuccessModelFromJSONTyped(json, false);
}

export function TrialCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrialCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TrialCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./TrialCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : TrialModelFromJSON(json['model']),
    });
}

export function TrialCreateSuccessModelToJSON(value?: TrialCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TrialCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./TrialCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': TrialModelToJSON(value.model),
    } as StringDateInterface<TrialCreateSuccessModel>;
    return createClass.toJson(value, model);
}


