import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "reducers/interface/ReducerAction";
import SnackbarItem from "interface/SnackbarItem";

export class AppActions {

    static CreateError(type: ActionTypes, error: Error): ErrorInterface {
        return {
            type: type,
            params: error,
            data: error['body']
        }
    }

    static CreateSnackbarMessage(message: SnackbarItem): SnackbarMessageInterface {
        return {
            type: ActionTypes.SNACKBAR_MESSAGE,
            params: message
        }
    }

    static CreateAction(action: ActionTypes): BaseReducerAction {
        return {
            type: action
        }
    }

}

export interface ErrorInterface extends BaseReducerAction {
    params: Error;
    data?: any;
}

export interface SnackbarMessageInterface extends BaseReducerAction {
    params: SnackbarItem;
}