import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWidgetStyles from 'styles/WidgetStyles';

interface EditToggleProps {
    onToggle: () => void;
    isEditingEnabled: boolean;
}

const EditToggle = (props: EditToggleProps) => {
 
    const { onToggle, isEditingEnabled } = props;
    const { t } = useTranslation();
    const {classes} = useWidgetStyles();

    return (
        <div className={classes.edit_toggle_container}>
            <FormControlLabel
                className={classes.edit_toggle}
                control={
                    <Switch
                        checked={isEditingEnabled}
                        onChange={onToggle}
                        name="editing-switch"
                        color="primary"
                    />
                }
                label={isEditingEnabled ? t('dashboard.editing_enabled') : t('dashboard.editing_disabled')}
            />
        </div>
    );
};

export default EditToggle;