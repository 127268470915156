import { ServiceModel, ServiceModelInterface, StringDateInterface} from "api";
import Constants from "config/Constants";
import i18next from "i18next";
import BuildableModel from "interface/BuildableModel";
import Historiable from "interface/Historiable";
import InstanceMenuable from "interface/InstanceMenuable";
import MultiSelectOption from "interface/MultiSelectOption";
import Statuses from "interface/Statuses";
import ServiceVolume from "models/ServiceVolumes";

export default class Service extends ServiceModel implements MultiSelectOption, Historiable, InstanceMenuable, BuildableModel {

    image_name: string;
    value: number | string;
    label: string;
    actionName: string = "service";
    reducerName: string = "services";
    status: string = Statuses.STATUS_BUILD_FAILED.toString();
    json_databases: MultiSelectOption[] = [];
    volumeModel: ServiceVolume;

    constructor(data?: ServiceModelInterface) {
        super(data);
        this.image_name = this.image?.docker_image ?? '';
        this.value = this.id;
        this.label = this.name;
        this.status = data?.status ?? Statuses.STATUS_BUILD_FAILED.toString();
        this.json_databases = JSON.parse(data.databases ?? '[]').map((db: string) => { return { value: db, label: db } });
        this.volumeModel = new ServiceVolume(data?.volumes);
    }

    getFullUrl = undefined;
 
    getName = (): string => {
        return this.name;
    }

    getUrlPreview(): string {
        return this.has_port ? 
            this.standalone 
                ? `https://${this.url}.bumbal.eu/`
                : `https://<${i18next.t('clients.model').toLowerCase()}>.bumbal.eu/${this.url}/`
            : i18next.t('general.no');
    }

    getInstanceDetailsUrl = (): string => {
        return 'services';
    }

    static toJson(initValue: Service, value: StringDateInterface<Service>): StringDateInterface<ServiceModelInterface> {
        return { 
            ...value,
            databases: JSON.stringify(initValue.json_databases.map((db: MultiSelectOption) => db.value)),
            volumes: initValue.standalone ? Constants.SERVICE_VOLUMES : initValue.volumeModel.toJson()
        };
    }

}