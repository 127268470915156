enum RoleType {
    Admins,
    Managers,
    DevelopersAdmin,
    Developers,
    HelpdeskAdmin,
    Helpdesk,
    Guest,
    System
}

export default RoleType;