import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import ImageVersionController from 'controllers/ImageVersionController';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import TokenUtil from 'utils/TokenUtil';
import StatusSingleSelect from 'components/form_controls/StatusSingleSelect';
import ConnectorContainer from 'api/override/ConnectorContainerModel';


interface ConnectorContainerDocumentationModalProps extends BaseFormModalProps<ConnectorContainer> {
    connectorContainer?: ConnectorContainer
}

const ConnectorContainerDocumentationModal = (props: ConnectorContainerDocumentationModalProps) => {

    const { connectorContainer } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const isAdmin: boolean = TokenUtil.getTokenUtil().isAccountManager()

    return (
        <BaseFormModal {...props} title={`${t('global.title.details_model', {model: t('connector_container.model')})} (${connectorContainer?.connector?.name})`}
            initialValues={new ConnectorContainer({
                ...connectorContainer,
                text: connectorContainer?.text || '',
                image_version_id: connectorContainer?.image_version_id || null,
                image_version: connectorContainer?.image_version || null
            })}
            action={ActionTypes.API_CONNECTOR_CONTAINER_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <StatusSingleSelect formik={formik as any} />
                    {isAdmin ? (
                        <ImageVersionSingleSelect 
                            id='image_version'
                            name='image_version_id'
                            controller={imageVersionController}
                            formik={formik}
                            imageId={+connectorContainer?.connector?.image_id}
                            disabled={connectorContainer?.connector?.image_id == null}
                            label={t('image_versions.model')}
                        />
                    ) : (null)}
                </div>
            }
        />
    );
};

export default ConnectorContainerDocumentationModal;