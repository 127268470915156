import React from 'react';
import {useTranslation} from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import i18next from 'i18next';
import ReadOnlyField from 'components/ReadOnlyField';
import Process from 'api/override/ProcessModel';
import BaseModal from 'components/base/BaseModal';
import JSONViewer from 'components/JSONViewer';

interface ProcessMonitorDetailsDialogProps {
    open: boolean;
    onModalClosePress: () => void;
    process?: Process;
}

const ProcessMonitorDetailsDialog = (props: ProcessMonitorDetailsDialogProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const { open, onModalClosePress, process} = props;
    

    return (
        <BaseModal open={open} onModalClosePress={onModalClosePress} title={t('global.title.details_model', {model: t('task_monitor.model')})}>

            <ReadOnlyField 
                id="user_full_name"
                name="user_full_name"
                label={t('task_monitor.user')}
                value={process?.user_full_name}
            />

            <ReadOnlyField 
                id="type" 
                name="type"
                label={t('general.type')}
                value={process?.type}
            /> 

            <ReadOnlyField 
                id="state"
                name="state"
                label={t('task_monitor.state')}
                value={process?.state}
            />

            <ReadOnlyField 
                id="current_step" 
                name="current_step"
                label={t('task_monitor.current_step')}
                value={process?.current_step}
            /> 

            <ReadOnlyField 
                id="created_at"
                name="created_at"
                label={t('task_monitor.created_at')}
                value={process?.created_at}
            />

            <ReadOnlyField 
                id="started_at"
                name="started_at"
                label={t('task_monitor.started_ts')}
                value={process?.started_at}
            />

            <ReadOnlyField 
                id="finished_at"
                name="finished_at"
                label={t('task_monitor.finished_at')}
                value={process?.finished_at}
            />
            <h3>{i18next.t('task_monitor.data')}</h3>
            <div>
                <JSONViewer jsonObject={process?.data} />

            </div>
            <h3>{i18next.t('global.process.logs')}</h3>
            <div className={classes.logs_container}>
                <JSONViewer jsonObject={process?.process_logs} />
            </div>
                
        </BaseModal>
    );
};

export default ProcessMonitorDetailsDialog;