import React, { useRef } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from 'formik';
import ButtonStyled from 'components/styled/ButtonStyled';
import useToolStyles from 'styles/ToolStyles';
import { InstanceMiniModel } from 'api';

interface InstanceOptionFormProps {
    onFormSubmit?: (value?: InstanceMiniModel) => void;
    instanceOptions: Array<InstanceMiniModel>;
}

const InstanceOptionForm = (props: InstanceOptionFormProps) => {

    const { t } = useTranslation();
    const { instanceOptions } = props
    const {classes} = useToolStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new InstanceMiniModel({
                id: null
            })}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<InstanceMiniModel>) =>
                <Form>
                    {!instanceOptions.bbIsEmpty() &&
                        <div className={classes.instance_options_container}>
                            <p>{t('db-transfer-tool.options_are_available')}</p>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={classes.form_label}>{t('db-transfer-tool.instance_db_options')}</FormLabel>
                                <RadioGroup 
                                    className={classes.radio_group} 
                                    aria-label='instance_option' 
                                    name='id' 
                                    value={formik.values.id} 
                                    onChange={(e,v) => {
                                        formik.handleChange(e);
                                        const selectedInstance = instanceOptions.find(instance => instance.id === +v)
                                        formik.setValues(new InstanceMiniModel({...selectedInstance}))
                                    }}
                                >
                                    {instanceOptions.map((instance: InstanceMiniModel, index: number) => (
                                        <FormControlLabel 
                                            className={classes.form_control_label} 
                                            key={`instance_option_${instance?.id}`} 
                                            value={instance.id} 
                                            label={
                                                <div className={classes.form_control_label_span}>
                                                    <p className={classes.name_label}>{instance.name}</p>
                                                    <p className={classes.domain_label}>{instance.domain}</p>
                                                </div>
                                            } 
                                            control={<Radio/>}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    }
                    <ButtonStyled type="submit" className={classes.db_transfer_btn}>
                        {t('db-transfer-tool.transfer_database')}
                    </ButtonStyled>
                </Form>
            }
        </Formik>
    );
};

export default InstanceOptionForm;