import ActionTypes from "config/ActionTypes";
import Form from 'api/override/FormModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import FormState from "./interface/FormState";
import { FormListSuccess, FormRetrieveSuccess } from "./types/Form";

const initialState: FormState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function FormReducers(state = initialState, action: BaseReducerAction): FormState {
    switch (action.type) {
        case ActionTypes.API_FORM_UPDATE:
        case ActionTypes.API_FORM_RETRIEVE:
        case ActionTypes.API_FORM_LIST:
        case ActionTypes.API_FORM_CREATE:
        case ActionTypes.API_FORM_DELETE:
        case ActionTypes.API_FORM_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_FORM_UPDATE_SUCCESS:
        case ActionTypes.API_FORM_CREATE_SUCCESS:
        case ActionTypes.API_FORM_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_FORM_UPDATE_FAILURE:
        case ActionTypes.API_FORM_CREATE_FAILURE:
        case ActionTypes.API_FORM_DELETE_FAILURE:
        case ActionTypes.API_FORM_LIST_FAILURE:
        case ActionTypes.API_FORM_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_FORM_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as FormRetrieveSuccess).params as Form,
                error: undefined
            };

        case ActionTypes.API_FORM_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_FORM_LIST_SUCCESS:
            const result = action as FormListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Form>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_FORM_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}