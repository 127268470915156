import { Tooltip, IconButton } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from 'react-i18next';
import useDetailsTabStyles from "styles/DetailsTabStyles";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { nanoid } from "nanoid";
import DetailsActionButton from "interface/DetailsActionButton";

export interface BaseDetailsViewActionsProps {
    label: string;
    onEdit?: () => void;
    onDelete?: () => void;
    onMount?: () => (() => void | undefined);
    detailsActionButton?: Array<DetailsActionButton>;
}

const BaseDetailsViewActions: FC<PropsWithChildren<BaseDetailsViewActionsProps>> = (props: PropsWithChildren<BaseDetailsViewActionsProps>) => {
    
    const {label, onEdit, onDelete, onMount, detailsActionButton, children} = props;
    const {classes} = useDetailsTabStyles();
    const { t } = useTranslation();

    const handleComponentDidMount = () => {
        return onMount?.()
    }
    // eslint-disable-next-line
    React.useEffect(handleComponentDidMount, [])

    const renderDetailsActionButtons = (): JSX.Element => 
        <>
            {detailsActionButton?.map((btn: DetailsActionButton) => 
                <Tooltip title={btn.title} key={nanoid()}>
                    <IconButton
                        onClick={btn.onClickCallback}
                        disabled={btn.disabled}
                        className={`${classes.action_button} ${btn.classNameModifier ?? 'primary'}`}
                        aria-label={btn.title}
                        size="large">
                        <btn.icon/>
                    </IconButton>
                </Tooltip>
            )}
        </>

    return (
        <div className={classes.actions_container}>
            <h5>{t('global.action.model_action_plural', {model: label})}</h5>
            <div className={classes.base_action_buttons_container}>
                {renderDetailsActionButtons()}
                {onEdit != null &&
                    <Tooltip title={t('global.button.edit')}>
                        <IconButton
                            aria-label='edit'
                            onClick={onEdit}
                            className={`${classes.action_button} primary`}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                }
                {onDelete != null &&
                    <Tooltip title={t('global.button.delete')}>
                        <IconButton
                            aria-label='delete'
                            onClick={onDelete}
                            className={`${classes.action_button} error`}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                }
            </div>
            {children}
        </div>
    );
}

export default BaseDetailsViewActions
