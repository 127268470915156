import { ServiceContainerListResultModel, ServiceContainerRetrieveListModel, ServiceContainerCreateSuccessModel, ServiceContainerRemoveSuccessModel, ApiResponseModel,  HistoryResponseModel, ServiceSecretResponseModel, ServiceSecretsRetrieveRequest } from "api";
import { ServiceContainerHistoryListRequest, ServiceContainerSecretsRetrieveRequest} from "api/apis/ServiceContainerApi";
import ServiceContainer from "api/override/ServiceContainerModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";
import { ServiceUpdateSecretsParams } from "./Service";


export interface ServiceContainerList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_LIST;
    params: ServiceContainerRetrieveListModel;
}

export interface ServiceContainerListSuccess extends BaseSagaListResponse<ServiceContainerListResultModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_LIST_SUCCESS;
}

export interface ServiceContainerRetrieveSecrets extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS;
    params: ServiceContainerSecretsRetrieveRequest;
}

export interface ServiceContainerRetrieveSecretsSuccess extends BaseSagaListResponse<ServiceSecretResponseModel>{
    type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS_SUCCESS;
}

export interface ServiceContainerUpdateSecrets extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS;
    params: ServiceUpdateSecretsParams;
}

export interface ServiceContainerUpdateSecretsSuccess extends BaseSagaListResponse<ServiceSecretResponseModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS_SUCCESS;
}

export interface ServiceContainerCreate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_CREATE;
    params: ServiceContainer;
}

export interface ServiceContainerCreateSuccess extends BaseSagaListResponse<ServiceContainerCreateSuccessModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_CREATE_SUCCESS;
}

export interface ServiceContainerUpdateSuccess extends BaseSagaListResponse<ServiceContainerCreateSuccessModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS;
}

export interface ServiceContainerRetrieve extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE;
    params: string;
}

export interface ServiceContainerRetrieveSuccess extends BaseSagaListResponse<ServiceContainer> {
    type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SUCCESS
}

export interface ServiceContainerDelete extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_DELETE;
    params: string;
}

export interface ServiceContainerDeleteSuccess extends BaseSagaListResponse<ServiceContainerRemoveSuccessModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_DELETE_SUCCESS;
}

export interface ServiceContainerTriggerJobParams {
    uuid: string;
    task: string;
    uuids?: string[];
}

export interface ServiceContainerTriggerJob extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB;
    params: ServiceContainerTriggerJobParams;
}

export interface ServiceContainerTriggerJobSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB_SUCCESS;
}

export interface ServiceContainerUpdateParams {
    uuid: string;
    text?: string;
    image_version_id?: number | null;
}

export interface ServiceContainerUpdate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_UPDATE;
    params: ServiceContainerUpdateParams
}

export interface ServiceContainerUpdateSuccess extends BaseSagaListResponse<ServiceContainerCreateSuccessModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS;
}

export interface ServiceContainerHistoryList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST;
    params: ServiceContainerHistoryListRequest;
}

export interface ServiceContainerHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST_SUCCESS;
}



export default class ServiceContainerActions {
    
    static CreateServiceContainerList(params: ServiceContainerRetrieveListModel): ServiceContainerList {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_LIST,
            params: params
        }
    }

    static CreateServiceContainerListSuccess(params: ServiceContainerListResultModel): ServiceContainerListSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_LIST_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerCreate(params: ServiceContainer): ServiceContainerCreate {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_CREATE,
            params: params
        }
    }

    static CreateServiceContainerCreateSuccess(params: ServiceContainerCreateSuccessModel): ServiceContainerCreateSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerRetrieve(params: string): ServiceContainerRetrieve {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE,
            params: params
        }
    }

    static CreateServiceContainerRetrieveSuccess(params: ServiceContainer): ServiceContainerRetrieveSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerDelete(params: string): ServiceContainerDelete {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_DELETE,
            params: params
        }
    }

    static CreateServiceContainerDeleteSuccess(params: ServiceContainerRemoveSuccessModel): ServiceContainerDeleteSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerRetrieveSecrets(params: ServiceSecretsRetrieveRequest): ServiceContainerRetrieveSecrets {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS,
            params: params
        }
    }

    static CreateServiceContainerRetrieveSecretsSuccess(params: ServiceSecretResponseModel): ServiceContainerRetrieveSecretsSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerUpdateSecrets(params: ServiceUpdateSecretsParams): ServiceContainerUpdateSecrets {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS,
            params: {
                uuid: params.uuid,
                serviceSecretModel: params.serviceSecretModel
            }
        }
    }

    static CreateServiceContainerUpdateSecretsSuccess(params: ServiceSecretResponseModel): ServiceContainerUpdateSecretsSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerTriggerJob(params: ServiceContainerTriggerJobParams): ServiceContainerTriggerJob {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB,
            params: params
        }
    }

    static CreateServiceContainerTriggerJobSuccess(params: ApiResponseModel): ServiceContainerTriggerJobSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerUpdate(params: ServiceContainerUpdateParams): ServiceContainerUpdate {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_UPDATE,
            params: params
        }
    }

    static CreateServiceContainerUpdateSuccess(params: ServiceContainerCreateSuccessModel): ServiceContainerUpdateSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceContainerHistoryList(params: ServiceContainerHistoryListRequest): ServiceContainerHistoryList {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST,
            params: params
        }
    }

    static CreateServiceContainerHistoryListSuccess(params: HistoryResponseModel): ServiceContainerHistoryListSuccess {
        return {
            type: ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

}