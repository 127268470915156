import React from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Property from 'api/override/PropertyModel';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import ActionTypes from 'config/ActionTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import MultiSelectOption from 'interface/MultiSelectOption';
import { PropertyModelTypeEnum } from 'api';
import SingleSelect from 'components/form_controls/SingleSelect';
import EnumUtil from 'utils/EnumUtil';
import { retrievePropertySchema } from 'schemas/Property';

interface PropertyDetailsDialogProps extends BaseFormModalProps<Property> { }

const PropertyDetailsDialog = (props: PropertyDetailsDialogProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const fetching = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_PROPERTY_RETRIEVE));
    const property: Property = useSelector((state: RootState) => state.properties.selectedObject)
    const typeOptions: Array<MultiSelectOption> = EnumUtil.enumToMultiSelectOptionArrayFromValues(PropertyModelTypeEnum)

    return (
        !fetching && 
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('properties.model')})}
            initialValues={property}
            action={ActionTypes.API_PROPERTY_UPDATE}
            validationSchema={retrievePropertySchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    
                    <TextFieldStyled 
                        id="name" 
                        name="name"
                        label={t('general.name')}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <SingleSelect 
                        id="type" 
                        name="type"
                        label={t('general.type')}
                        options={typeOptions}
                        value={typeOptions.find(type => type.value === formik.values?.type)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.type && Boolean(formik.errors.type)}
                        formikHelperText={formik.touched.type ? formik.errors.type : ""}
                    />

                </div>
            }
        />
    );
};

export default PropertyDetailsDialog;