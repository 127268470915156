import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ButtonStyled from 'components/styled/ButtonStyled';
import Property from 'api/override/PropertyModel';
import { PropertyModelTypeEnum } from 'api';
import SingleSelect from 'components/form_controls/SingleSelect';
import MultiSelectOption from 'interface/MultiSelectOption';
import EnumUtil from 'utils/EnumUtil';
import { retrievePropertySchema } from 'schemas/Property';


interface PropertyCreateFormProps {
    onFormSubmit: (value: Property) => void;
}

const PropertyCreateForm = (props: PropertyCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const typeOptions: Array<MultiSelectOption> = EnumUtil.enumToMultiSelectOptionArrayFromValues(PropertyModelTypeEnum)

    return (
        <Formik
            initialValues={new Property({
                name: '',
                type: PropertyModelTypeEnum.Text,
            })}
            validationSchema={retrievePropertySchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Property>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('properties.model')})}</h2>
                    
                    <TextFieldStyled
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('general.name')}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <SingleSelect 
                        id="type" 
                        name="type"
                        label={t('general.type')}
                        options={typeOptions}
                        value={typeOptions.find(type => type.value === formik.values?.type)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.type && Boolean(formik.errors.type)}
                        formikHelperText={formik.touched.type ? formik.errors.type : ""}
                    />

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('properties.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default PropertyCreateForm;