/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiErrorModel,
    ApiErrorModelFromJSON,
    ApiErrorModelFromJSONTyped,
    ApiErrorModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelFromJSONTyped,
    ApiResponseModelToJSON,
    InstanceMiniModel,
    InstanceMiniModelFromJSON,
    InstanceMiniModelFromJSONTyped,
    InstanceMiniModelToJSON,
    TransferVerifyDataResponseModelAllOf,
    TransferVerifyDataResponseModelAllOfFromJSON,
    TransferVerifyDataResponseModelAllOfFromJSONTyped,
    TransferVerifyDataResponseModelAllOfToJSON,
} from './';


export interface TransferVerifyDataResponseModelInterface {
    message?: string;
    code?: number;
    warnings?: Array<ApiErrorModel>;
    instances?: Array<InstanceMiniModel>;
}

/**
 * 
 * @export
 * @interface TransferVerifyDataResponseModel
 */
export class TransferVerifyDataResponseModel extends BaseModel<TransferVerifyDataResponseModelInterface> {

    /**
     * Message
     * @type {string}
     * @memberof TransferVerifyDataResponseModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof TransferVerifyDataResponseModel
     */
    code?: number;
    /**
     * 
     * @type {Array<ApiErrorModel>}
     * @memberof TransferVerifyDataResponseModel
     */
    warnings?: Array<ApiErrorModel>;
    /**
     * 
     * @type {Array<InstanceMiniModel>}
     * @memberof TransferVerifyDataResponseModel
     */
    instances?: Array<InstanceMiniModel>;

    constructor(data?: TransferVerifyDataResponseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TransferVerifyDataResponseModel, value: StringDateInterface<TransferVerifyDataResponseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TransferVerifyDataResponseModelFromJSON(json: any): TransferVerifyDataResponseModel {
    return TransferVerifyDataResponseModelFromJSONTyped(json, false);
}

export function TransferVerifyDataResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferVerifyDataResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TransferVerifyDataResponseModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyDataResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'warnings': !exists(json, 'warnings') ? undefined : ((json['warnings'] as Array<any>).map(ApiErrorModelFromJSON)),
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(InstanceMiniModelFromJSON)),
    });
}

export function TransferVerifyDataResponseModelToJSON(value?: TransferVerifyDataResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TransferVerifyDataResponseModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyDataResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'warnings': value.warnings === undefined ? undefined : ((value.warnings as Array<any>).map(ApiErrorModelToJSON)),
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(InstanceMiniModelToJSON)),
    } as StringDateInterface<TransferVerifyDataResponseModel>;
    return createClass.toJson(value, model);
}


