/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    FormCreateSuccessModel,
    FormCreateSuccessModelFromJSON,
    FormCreateSuccessModelToJSON,
    FormModel,
    FormModelFromJSON,
    FormModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface FormSubmissionHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface FormSubmissionSubmitRequest {
    formModel: FormModel;
}

/**
 * 
 */
export class FormSubmissionApi extends runtime.BaseAPI {

    /**
     * retrieve a list of historical logs for a form submission
     * retrieve a list of historical logs for a form submission
     */
    async formSubmissionHistoryListRaw(requestParameters: FormSubmissionHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formSubmissionHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling formSubmissionHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form-submission/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a form submission
     * retrieve a list of historical logs for a form submission
     */
    formSubmissionHistoryList = async(requestParameters: FormSubmissionHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.formSubmissionHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Submit a form
     * Submit a form
     */
    async formSubmissionSubmitRaw(requestParameters: FormSubmissionSubmitRequest): Promise<runtime.ApiResponse<FormCreateSuccessModel>> {
        if (requestParameters.formModel === null || requestParameters.formModel === undefined) {
            throw new runtime.RequiredError('formModel','Required parameter requestParameters.formModel was null or undefined when calling formSubmissionSubmit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/form-submission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormModelToJSON(requestParameters.formModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FormCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Submit a form
     * Submit a form
     */
    formSubmissionSubmit = async(requestParameters: FormSubmissionSubmitRequest): Promise<FormCreateSuccessModel> => {
        const response = await this.formSubmissionSubmitRaw(requestParameters);
        return await response.value();
    }

}
