
import ActionTypes from 'config/ActionTypes';
import ApiState from './interface/ApiState';
import BaseReducerAction from './interface/ReducerAction';
import TokenUtil from 'utils/TokenUtil';
import LocalStorageManager from 'utils/LocalStorageManager';

// reducer with initial state
const initialState: ApiState = {
    token: localStorage.getItem('token'),
    error: null,
    isFetchingData: false,
    jwtToken: TokenUtil.getTokenUtil().getDecodedToken()
};

export function ApiReducers(state = initialState, action: BaseReducerAction): ApiState {
    switch (action.type) {

    /* Generic Api call failure reducer */
        case ActionTypes.API_CALL_FAILURE:
            if (action.params['status'] && ['401', '403'].includes( ''+ action.params['status'] )) {
                return { ...state };
            } else {
                return { ...state, error: action.params };
            }

    /* Authentication Reducers */
        case ActionTypes.API_SIGN_IN:
        case ActionTypes.API_IMPERSONATE:
            return {...state, isFetchingData: true};

        case ActionTypes.API_SIGN_IN_SUCCESS:
            localStorage.setItem('user', JSON.stringify(action.params.user));
            localStorage.setItem('jwt', action.params.token);
            TokenUtil.getTokenUtil().setToken(TokenUtil.getTokenUtil().getDecodedToken());
            return {...state, isFetchingData: false, jwtToken: TokenUtil.getTokenUtil().getDecodedToken() };

        case ActionTypes.API_IMPERSONATE_SUCCESS:
            LocalStorageManager.onImpersonate(action.params.token, JSON.stringify(action.params.user));
            TokenUtil.getTokenUtil().setToken(TokenUtil.getTokenUtil().getDecodedToken());
            return {...state, isFetchingData: false, jwtToken: TokenUtil.getTokenUtil().getDecodedToken() };

        case ActionTypes.API_SIGN_IN_FAILURE:
        case ActionTypes.API_IMPERSONATE_FAILURE:
            return {...state, isFetchingData: false };

        case ActionTypes.API_SIGN_OUT:
        case ActionTypes.API_SIGN_OUT_ALWAYS:
            if (LocalStorageManager.onExitImpersonate()) {
                return {
                    ...state,
                    jwtToken: TokenUtil.getTokenUtil().getDecodedToken() 
                }
            } else {
                TokenUtil.getTokenUtil().clearToken()
                return {...state, token: null, jwtToken: null };
            }

        default:
            return state;
    }
}