import { takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../config/ActionTypes";
import {AuthenticateApi, AuthenticateSignInRequest, BaseAPI, ImpersonateTokenRequest} from "api";
import { ApiHandler } from "./APIHandler";
import { AppActions } from "reducers/types/App";
import AuthenticateActions, { AuthenticateInvalidateToken, AuthenticateSystemToken, Impersonate, SignIn } from "reducers/types/Authenticate";

export function* AuthenticateSagas() {
    yield takeLatest(ActionTypes.API_SIGN_IN, AuthenticateSaga.signInWorkerSaga);
    yield takeLatest(ActionTypes.API_IMPERSONATE, AuthenticateSaga.impersonateWorkerSaga);
    yield takeLatest(ActionTypes.API_INVALIDATE_TOKEN, AuthenticateSaga.authenticateInvalidateTokenSaga);
    yield takeLatest(ActionTypes.API_SYSTEM_TOKEN, AuthenticateSaga.authenticateSystemTokenSaga);
}

export class AuthenticateSaga {
    static *signInWorkerSaga(action: SignIn) {
        try {
            const authArgs = {
                authenticateModel: {
                    email: action.params.email, 
                    password: action.params.password
                }
            } as AuthenticateSignInRequest
            const data = yield ApiHandler(new AuthenticateApi().authenticateSignIn, authArgs);
            BaseAPI.setConfig(
                BaseAPI.config.basePath,
                'bearer ' + data.token
            )
            // dispatch a success action to the store with the activities
            yield put(AuthenticateActions.CreateSignInSuccess(data));
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SIGN_IN_FAILURE, error as Error))
        }
    }

    static *impersonateWorkerSaga(action: Impersonate) {
        try {
            const authArgs = {
                authenticateModel: {
                    email: action.params.email, 
                    password: action.params.password
                }
            } as ImpersonateTokenRequest
            const data = yield ApiHandler(new AuthenticateApi().impersonateToken, authArgs);
            yield put(AuthenticateActions.CreateImpersonateSuccess(data));
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMPERSONATE_FAILURE, error as Error))
        }
    }

    static *authenticateInvalidateTokenSaga(action: AuthenticateInvalidateToken) {
        try {
            const args = {
                uuid: action.uuid
            }
            const data = yield ApiHandler(new AuthenticateApi().authenticateInvalidateToken, args);
            // dispatch a success action to the store with the activities
            yield put(AuthenticateActions.authenticateInvalidateTokenSuccess(data));
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_INVALIDATE_TOKEN_FAILURE, error as Error))
        }
    }

    static *authenticateSystemTokenSaga(action: AuthenticateSystemToken) {
        try {
            const args = {
                uuid: action.uuid
            }
            const data = yield ApiHandler(new AuthenticateApi().authenticateSystemToken, args);
            // dispatch a success action to the store with the activities
            yield put(AuthenticateActions.authenticateSystemTokenSuccess(data));
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_SYSTEM_TOKEN_FAILURE, error as Error))
        }
    }
}

