import i18next from "i18next";
import * as Yup from "yup";

export const retrieveUserCreateSchema = () => Yup.object().shape({
    role_name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    full_name: Yup.string()
        .min(2, i18next.t('global.validation_schema.too_short'))
        .max(50, i18next.t('global.validation_schema.too_long'))
        .required(i18next.t('global.validation_schema.required')),
    password: Yup.string()
        .min(2, i18next.t('global.validation_schema.too_short'))
        .max(50, i18next.t('global.validation_schema.too_long'))
        .required(i18next.t('global.validation_schema.required')),
    email: Yup.string()
        .email(i18next.t('global.validation_schema.invalid_email'))
        .required(i18next.t('global.validation_schema.required')),
});

export const retrieveUserUpdateSchema = () => Yup.object().shape({
    role_name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    full_name: Yup.string()
        .min(2, i18next.t('global.validation_schema.too_short'))
        .max(50, i18next.t('global.validation_schema.too_long'))
        .required(i18next.t('global.validation_schema.required')),
    password: Yup.string()
        .min(2, i18next.t('global.validation_schema.too_short'))
        .max(50, i18next.t('global.validation_schema.too_long'))
});