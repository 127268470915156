import MultiSelectOption from "interface/MultiSelectOption";
import Package from "api/override/PackageModel";
import { TableParams } from "models/table/TableParams";
import { PackageSaga } from "sagas/PackageSagas";
import Log from "utils/Log";
import BaseController from "./BaseController";

export default class PackageController extends BaseController<Package> {

    packageToMultiselect = async (params?: TableParams): Promise<MultiSelectOption[]> => {
        try {
            if (!this.hasItemsForRequest(params)) {
                const response = await PackageSaga.packagesList(params);
                const responseToMultiSelect: MultiSelectOption[] = response['items'] as Package[];
                this.cacheResponse(responseToMultiSelect, params);
            }
            return this.getItemsForRequest(params);

        } catch (e) {
            Log(e)
        }
        return [];
    }
}