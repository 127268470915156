import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import {ImageApi, ImageCreateRequest, ImageDeleteRequest, ImageHistoryListRequest, ImageListRequest, ImageListResultModel, ImageRetrieveRequest, ImageUpdateRequest } from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import ImageActions, { ImageCreate, ImageDelete, ImageHistoryList, ImageList, ImageRetrieve, ImageUpdate } from 'reducers/types/Image';
import { TableParams } from 'models/table/TableParams';

export function* ImageSagas() {
    yield takeLatest(ActionTypes.API_IMAGE_LIST, ImageSaga.imageListWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_RETRIEVE, ImageSaga.imageRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_CREATE, ImageSaga.imageCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_UPDATE, ImageSaga.imageUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_DELETE, ImageSaga.imageDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_HISTORY_LIST, ImageSaga.imageHistoryListWorkerSaga);
}

export class ImageSaga {
    static *imageListWorkerSaga(action: ImageList) {
        try {
            const imageArgs = {
                imageRetrieveListModel: action.params
            } as ImageListRequest
            const data = yield ApiHandler(new ImageApi().imageList, imageArgs);
    
            yield put(ImageActions.CreateImageListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_LIST_FAILURE, error as Error))
        }
    }
    
    static *imageRetrieveWorkerSaga(action: ImageRetrieve) {
        try {
            const imageArgs = {
                id: action.params
            } as ImageRetrieveRequest
            const data = yield ApiHandler(new ImageApi().imageRetrieve, imageArgs);
    
            yield put(ImageActions.CreateImageRetrieveSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *imageCreateWorkerSaga(action: ImageCreate) {
        try {
            const imageArgs = {
                imageModel: action.params
            } as ImageCreateRequest
            const data = yield ApiHandler(new ImageApi().imageCreate, imageArgs);
    
            yield put(ImageActions.CreateImageCreateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_CREATE_FAILURE, error as Error))
        }
    }
    
    static *imageUpdateWorkerSaga(action: ImageUpdate) {
        try {
            const imageArgs = {
                id: action.params?.id,
                imageModel: action.params?.imageModel
            } as ImageUpdateRequest
            const data = yield ApiHandler(new ImageApi().imageUpdate, imageArgs);
    
            yield put(ImageActions.CreateImageUpdateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *imageDeleteWorkerSaga(action: ImageDelete) {
        try {
            const imageArgs = {
                id: action.params
            } as ImageDeleteRequest
            const data = yield ApiHandler(new ImageApi().imageDelete, imageArgs);
    
            yield put(ImageActions.CreateImageDeleteSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_DELETE_FAILURE, error as Error))
        }
    }
    
    static *imageHistoryListWorkerSaga(action: ImageHistoryList) {
        try {
            const imageArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ImageHistoryListRequest
    
            const data = yield ApiHandler(new ImageApi().imageHistoryList, imageArgs);
            yield put(ImageActions.CreateImageHistoryListSuccess(data))
        } catch(error){
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_HISTORY_LIST_FAILURE, error as Error))
        }
    }
    
    static async imageList (tableParams?: TableParams): Promise<ImageListResultModel> {
        const retrieveListRequest = {
            imageRetrieveListModel: {
                ...tableParams.getApiFilter()
            }
        } as ImageListRequest;
        return await new ImageApi().imageList(retrieveListRequest);
    }
}

