/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceListFilterModelInterface {
    include_removed?: boolean;
    active?: boolean;
    domain?: string;
}

/**
 * 
 * @export
 * @interface InstanceListFilterModel
 */
export class InstanceListFilterModel extends BaseModel<InstanceListFilterModelInterface> {

    /**
     * Include removed instances
     * @type {boolean}
     * @memberof InstanceListFilterModel
     */
    include_removed?: boolean;
    /**
     * Include inactive instances
     * @type {boolean}
     * @memberof InstanceListFilterModel
     */
    active?: boolean;
    /**
     * The domain of the instance
     * @type {string}
     * @memberof InstanceListFilterModel
     */
    domain?: string;

    constructor(data?: InstanceListFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceListFilterModel, value: StringDateInterface<InstanceListFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceListFilterModelFromJSON(json: any): InstanceListFilterModel {
    return InstanceListFilterModelFromJSONTyped(json, false);
}

export function InstanceListFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceListFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceListFilterModel;
    try {
        let localClass = require.context('../override', true)('./InstanceListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'include_removed': !exists(json, 'include_removed') ? undefined : json['include_removed'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
    });
}

export function InstanceListFilterModelToJSON(value?: InstanceListFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceListFilterModel;
    try {
        let localClass = require.context('../override', true)('./InstanceListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'include_removed': value.include_removed,
        'active': value.active,
        'domain': value.domain,
    } as StringDateInterface<InstanceListFilterModel>;
    return createClass.toJson(value, model);
}


