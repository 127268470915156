/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ProcessLogModel,
    ProcessLogModelFromJSON,
    ProcessLogModelFromJSONTyped,
    ProcessLogModelToJSON,
} from './';


export interface ProcessModelInterface {
    token?: string;
    user_full_name?: string;
    type?: string;
    state?: ProcessModelStateEnum;
    current_step?: string | null;
    data?: { [key: string]: object; };
    process_logs?: Array<ProcessLogModel>;
    process_name?: string;
    created_at?: Date;
    started_at?: Date | null;
    finished_at?: Date | null;
}

/**
 * 
 * @export
 * @interface ProcessModel
 */
export class ProcessModel extends BaseModel<ProcessModelInterface> {

    /**
     * UUID which identifies this Process
     * @type {string}
     * @memberof ProcessModel
     */
    token?: string;
    /**
     * Full name of the user attached to this process
     * @type {string}
     * @memberof ProcessModel
     */
    user_full_name?: string;
    /**
     * Type task
     * @type {string}
     * @memberof ProcessModel
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessModel
     */
    state?: ProcessModelStateEnum;
    /**
     * Current Step of the Process
     * @type {string}
     * @memberof ProcessModel
     */
    current_step?: string | null;
    /**
     * array with data
     * @type {{ [key: string]: object; }}
     * @memberof ProcessModel
     */
    data?: { [key: string]: object; };
    /**
     * /ProcessMonitor/ProcessLogModel[]
     * @type {Array<ProcessLogModel>}
     * @memberof ProcessModel
     */
    process_logs?: Array<ProcessLogModel>;
    /**
     * Name of the process
     * @type {string}
     * @memberof ProcessModel
     */
    process_name?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ProcessModel
     */
    created_at?: Date;
    /**
     * UTC date time string, which can be null
     * @type {Date}
     * @memberof ProcessModel
     */
    started_at?: Date | null;
    /**
     * UTC date time string, which can be null
     * @type {Date}
     * @memberof ProcessModel
     */
    finished_at?: Date | null;

    constructor(data?: ProcessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ProcessModel, value: StringDateInterface<ProcessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ProcessModelFromJSON(json: any): ProcessModel {
    return ProcessModelFromJSONTyped(json, false);
}

export function ProcessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ProcessModel;
    try {
        let localClass = require.context('../override', true)('./ProcessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user_full_name': !exists(json, 'user_full_name') ? undefined : json['user_full_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'current_step': !exists(json, 'current_step') ? undefined : json['current_step'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'process_logs': !exists(json, 'process_logs') ? undefined : ((json['process_logs'] as Array<any>).map(ProcessLogModelFromJSON)),
        'process_name': !exists(json, 'process_name') ? undefined : json['process_name'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'started_at': !exists(json, 'started_at') ? undefined : (json['started_at'] === null ? null : new Date(json['started_at'].replace(' ', 'T'))),
        'finished_at': !exists(json, 'finished_at') ? undefined : (json['finished_at'] === null ? null : new Date(json['finished_at'].replace(' ', 'T'))),
    });
}

export function ProcessModelToJSON(value?: ProcessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ProcessModel;
    try {
        let localClass = require.context('../override', true)('./ProcessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'token': value.token,
        'user_full_name': value.user_full_name,
        'type': value.type,
        'state': value.state,
        'current_step': value.current_step,
        'data': value.data,
        'process_logs': value.process_logs === undefined ? undefined : ((value.process_logs as Array<any>).map(ProcessLogModelToJSON)),
        'process_name': value.process_name,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'started_at': value.started_at === undefined ? undefined : (value.started_at === null ? null : value.started_at.toApiFormat()),
        'finished_at': value.finished_at === undefined ? undefined : (value.finished_at === null ? null : value.finished_at.toApiFormat()),
    } as StringDateInterface<ProcessModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum ProcessModelStateEnum {
    Awaiting = 'awaiting',
    Processing = 'processing',
    Succeeded = 'succeeded',
    Failed = 'failed'
}


