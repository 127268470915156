import ActionTypes from "config/ActionTypes";
import Trial from 'api/override/TrialModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import TrialState from "./interface/TrialState";
import { TrialListSuccess } from "./types/Trial";

const initialState: TrialState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function TrialReducers(state = initialState, action: BaseReducerAction): TrialState {
    switch (action.type) {
        case ActionTypes.API_TRIAL_UPDATE:
        case ActionTypes.API_TRIAL_LIST:
        case ActionTypes.API_TRIAL_HISTORY_LIST:
        case ActionTypes.API_TRIAL_CANCEL:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_TRIAL_UPDATE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_TRIAL_UPDATE_FAILURE:
        case ActionTypes.API_TRIAL_CANCEL_FAILURE:
        case ActionTypes.API_TRIAL_LIST_FAILURE:
            return {
                ...state,
                error: action.params
            }
        
        case ActionTypes.API_TRIAL_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_TRIAL_LIST_SUCCESS:
            const result = action as TrialListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Trial>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_TRIAL_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}