import React, { useState } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from './SingleSelect';
import { FormikProps } from 'formik';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ConnectorController from 'controllers/ConnectorController';
import ConnectorContainer from 'api/override/ConnectorContainerModel';

interface ConnectorSingleSelectProps {
    id: string;
    currentConnector?: MultiSelectOption;
    controller: ConnectorController;
    formik: FormikProps<ConnectorContainer>;
    name: string;
}

const ConnectorSingleSelect = (props: ConnectorSingleSelectProps) => {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams());

    const [chosenConnector, setChosenConnector] = React.useState<MultiSelectOption>(props.currentConnector);
    const getConnectors = props.controller.connectorToMultiselect;

    const handleCurrentConnectorChange = () => {
        if (props.currentConnector != null) {
            setConnector(null, props.currentConnector);
        }
    }
    // eslint-disable-next-line
    useEffect(handleCurrentConnectorChange, [props.currentConnector])

    const setConnector = (_, val: MultiSelectOption) => {
        setChosenConnector(val);
        props.formik.setFieldValue(props.name, `${val.value}`);
    }

    return (
        <SingleSelect
            label={t('connector.id_field_title')}
            name={props.name}
            tableParams={params}
            value={chosenConnector}
            getAsyncValuesOnSearch
            getAsyncOptions={getConnectors}
            onChange={setConnector}
            formikError={props.formik.touched[props.id] && Boolean(props.formik.errors[props.id])}
            formikHelperText={props.formik.touched[props.id] ? props.formik.errors[props.id] : ""}
        />
    )
}

export default ConnectorSingleSelect;
