import { IconButton, Menu, MenuItem, SvgIcon } from "@mui/material";
import React, { useState } from "react";

export interface MenuButtonProps {
    options: string[];
    onClick: (name?: string) => void;
    buttonIcon: typeof SvgIcon;
}

const MenuButton = (props: MenuButtonProps) => {
    const {
        options,
        onClick
    } = props;
    const [anchorElem, setAnchorElem] = useState<HTMLElement | null>( null );
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElem( event.currentTarget );
    }
    const handleClose = () => {
        setAnchorElem(null);
    }
    const handleMenuItem = (option: string) => {
        if (onClick == null) {
            console.error( 'OnClick not configured for MenuButton', anchorElem );
        }
        onClick?.(option);
        handleClose();
    }
    
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={ handleOpen }
                size='small'
                color='primary'
            >
                <props.buttonIcon />
            </IconButton>
            <Menu
                className='long-menu'
                anchorEl={anchorElem}
                style={{zIndex: 1599}}
                keepMounted
                open={anchorElem != null}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={ () => handleMenuItem( option ) }>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default MenuButton;