import { ProcessRetrieveListModel, ProcessListResultModel } from "api";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface TaskMonitorList extends BaseReducerAction {
    type: ActionTypes.API_TASK_MONITOR_LIST_TASKS;
    params: ProcessRetrieveListModel;
}

export interface TaskMonitorListSuccess extends BaseSagaListResponse<ProcessListResultModel> {
    type: ActionTypes.API_TASK_MONITOR_LIST_TASKS_SUCCESS;
}

export default class TaskMonitorActions {
    static CreateTaskMonitorList(params: ProcessRetrieveListModel): TaskMonitorList {
        return {
            type: ActionTypes.API_TASK_MONITOR_LIST_TASKS,
            params: params
        }
    }

    static CreateTaskMonitorListSuccess(params: ProcessListResultModel): TaskMonitorListSuccess {
        return {
            type: ActionTypes.API_TASK_MONITOR_LIST_TASKS_SUCCESS,
            params: params
        }
    }

    static CreateClearTasksList() {
        return {
            type: ActionTypes.API_TASK_LIST_CLEAR
        }
    }
}