import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {
    Card,
    Container,
    Grid,
    CardContent
} from "@mui/material";
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import NotificationCenter from 'services/NotificationCenter';
import ReloadDataButton from 'components/ReloadDataButton';
import Mail from 'api/override/MailModel';
import DetailsViewHeader from 'components/DetailsViewHeader';
import MailLog from 'api/override/MailLogModel';
import MailLogState from 'reducers/interface/MailLogState';
import MailLogActions from 'reducers/types/MailLog';
import { RootState } from 'store/AppStore';
import MailLogDetails from './components/MailLogDetails';
import { withStyles } from 'tss-react/mui';
import { BaseAction } from 'reducers/interface/ReducerAction';


interface StateProps {
    appReducer: AppState;
    mailLogReducer: MailLogState;
}

interface DispatchProps {
    retrieveMailLog: (params: number) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    mail?: Mail;
    mailLog?: MailLog;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class MailLogView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            mailLog: undefined,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_MAIL_LOG_RETRIEVE_FAILURE
        ])
        this.loadData();
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_MAIL_LOG_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({mailLog: action.params}, () => {
            this.updateTabTitle(this.state.mailLog.mail?.name)
        })
    }

    clearData = () => {}

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveMailLog(this.props.params.slug);
        }
    }

    reloadData = () => {
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.mailLog != null
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, appReducer } = this.props;

        return (
            <React.Fragment>
                {this.shouldRenderDetails() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_MAIL_LOG_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                    <div>
                                        <DetailsViewHeader
                                            model={i18next.t('mail_logs.model')}
                                            title={this.state.mailLog?.mail.name}
                                        />
                                        <CardContent>
                                            <MailLogDetails
                                                mailLog={this.state.mailLog} 
                                            />
                                        </CardContent>
                                    </div>
                                }
                            
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                    
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: RootState) => ({
    appReducer: state.app,
    mailLogReducer: state.mail_logs,
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveMailLog: (params: number) => dispatch(MailLogActions.CreateMailLogRetrieve(params)),        
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(MailLogView, InstanceViewStyles)
            )
        )
    )
);