/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    PackageCreateSuccessModel,
    PackageCreateSuccessModelFromJSON,
    PackageCreateSuccessModelToJSON,
    PackageListResultModel,
    PackageListResultModelFromJSON,
    PackageListResultModelToJSON,
    PackageModel,
    PackageModelFromJSON,
    PackageModelToJSON,
    PackagePostModel,
    PackagePostModelFromJSON,
    PackagePostModelToJSON,
    PackageRemoveSuccessModel,
    PackageRemoveSuccessModelFromJSON,
    PackageRemoveSuccessModelToJSON,
    PackageRetrieveListModel,
    PackageRetrieveListModelFromJSON,
    PackageRetrieveListModelToJSON,
} from '../models';

export interface PackageCreateRequest {
    packagePostModel: PackagePostModel;
}

export interface PackageDeleteRequest {
    id: number;
}

export interface PackageHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface PackageListRequest {
    packageRetrieveListModel: PackageRetrieveListModel;
}

export interface PackageRetrieveRequest {
    id: number;
}

export interface PackageUpdateRequest {
    id: number;
    packagePostModel: PackagePostModel;
}

/**
 * 
 */
export class PackageApi extends runtime.BaseAPI {

    /**
     * Create a new package
     * Create a new package
     */
    async packageCreateRaw(requestParameters: PackageCreateRequest): Promise<runtime.ApiResponse<PackageCreateSuccessModel>> {
        if (requestParameters.packagePostModel === null || requestParameters.packagePostModel === undefined) {
            throw new runtime.RequiredError('packagePostModel','Required parameter requestParameters.packagePostModel was null or undefined when calling packageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackagePostModelToJSON(requestParameters.packagePostModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new package
     * Create a new package
     */
    packageCreate = async(requestParameters: PackageCreateRequest): Promise<PackageCreateSuccessModel> => {
        const response = await this.packageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an package
     * Delete an package
     */
    async packageDeleteRaw(requestParameters: PackageDeleteRequest): Promise<runtime.ApiResponse<PackageRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling packageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an package
     * Delete an package
     */
    packageDelete = async(requestParameters: PackageDeleteRequest): Promise<PackageRemoveSuccessModel> => {
        const response = await this.packageDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an package
     * retrieve a list of historical logs for an package
     */
    async packageHistoryListRaw(requestParameters: PackageHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling packageHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling packageHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an package
     * retrieve a list of historical logs for an package
     */
    packageHistoryList = async(requestParameters: PackageHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.packageHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all packages
     * List all packages
     */
    async packageListRaw(requestParameters: PackageListRequest): Promise<runtime.ApiResponse<PackageListResultModel>> {
        if (requestParameters.packageRetrieveListModel === null || requestParameters.packageRetrieveListModel === undefined) {
            throw new runtime.RequiredError('packageRetrieveListModel','Required parameter requestParameters.packageRetrieveListModel was null or undefined when calling packageList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PackageRetrieveListModelToJSON(requestParameters.packageRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageListResultModelFromJSON(jsonValue));
    }

    /**
     * List all packages
     * List all packages
     */
    packageList = async(requestParameters: PackageListRequest): Promise<PackageListResultModel> => {
        const response = await this.packageListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a package by its id
     * Retrieve an package by its id
     */
    async packageRetrieveRaw(requestParameters: PackageRetrieveRequest): Promise<runtime.ApiResponse<PackageModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling packageRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a package by its id
     * Retrieve an package by its id
     */
    packageRetrieve = async(requestParameters: PackageRetrieveRequest): Promise<PackageModel> => {
        const response = await this.packageRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an package
     * Update an package
     */
    async packageUpdateRaw(requestParameters: PackageUpdateRequest): Promise<runtime.ApiResponse<PackageCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling packageUpdate.');
        }

        if (requestParameters.packagePostModel === null || requestParameters.packagePostModel === undefined) {
            throw new runtime.RequiredError('packagePostModel','Required parameter requestParameters.packagePostModel was null or undefined when calling packageUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/package/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PackagePostModelToJSON(requestParameters.packagePostModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PackageCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an package
     * Update an package
     */
    packageUpdate = async(requestParameters: PackageUpdateRequest): Promise<PackageCreateSuccessModel> => {
        const response = await this.packageUpdateRaw(requestParameters);
        return await response.value();
    }

}
