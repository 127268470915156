import React, { createRef, forwardRef, useImperativeHandle } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import BaseSelect, { BaseSelectProps, BaseSelectRef } from '../base/BaseSelect';
import i18next from 'i18next';

interface SingleSelectProps extends BaseSelectProps {
    value: MultiSelectOption;
    onChange: (name: React.ChangeEvent<{}>, value: MultiSelectOption) => void;
    placeholder?: MultiSelectOption
}

const SingleSelect = forwardRef((props: SingleSelectProps, ref) => {
    const placeholder: MultiSelectOption = {value: '', label: i18next.t('global.form.single_select_placeholder')}
    const handleOnChange = (event: React.ChangeEvent<{}>, value: MultiSelectOption) => {
        props.onChange(event, value)
    }
    const parentRef = createRef<BaseSelectRef>();
    useImperativeHandle(ref, () => ({
        clearOptions: parentRef.current?.clearOptions
    }));
    return (
        <BaseSelect
            {...props}
            ref={parentRef}
            multiple={false}
            onChange={handleOnChange}
            value={props.value}
            placeholderValue={props.placeholder ?? placeholder}
        />
    )
});

export default SingleSelect;