/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    StepviewModel,
    StepviewModelFromJSON,
    StepviewModelFromJSONTyped,
    StepviewModelToJSON,
} from './';


export interface StepviewListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<StepviewModel>;
}

/**
 * 
 * @export
 * @interface StepviewListResultModel
 */
export class StepviewListResultModel extends BaseModel<StepviewListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof StepviewListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof StepviewListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<StepviewModel>}
     * @memberof StepviewListResultModel
     */
    items?: Array<StepviewModel>;

    constructor(data?: StepviewListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewListResultModel, value: StringDateInterface<StepviewListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewListResultModelFromJSON(json: any): StepviewListResultModel {
    return StepviewListResultModelFromJSONTyped(json, false);
}

export function StepviewListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewListResultModel;
    try {
        let localClass = require.context('../override', true)('./StepviewListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(StepviewModelFromJSON)),
    });
}

export function StepviewListResultModelToJSON(value?: StepviewListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewListResultModel;
    try {
        let localClass = require.context('../override', true)('./StepviewListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(StepviewModelToJSON)),
    } as StringDateInterface<StepviewListResultModel>;
    return createClass.toJson(value, model);
}


