import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import Property from 'api/override/PropertyModel';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface PropertyDetailsProps {
    property: Property;
    onDelete?: () => void;
    onEdit?: () => void;
}

const PropertyDetails = (props: PropertyDetailsProps) => {
    const { t } = useTranslation();
    const { property, onDelete, onEdit } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('properties.model')}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={property?.name} />
            <DetailsField propKey={t('general.type')} propVal={property?.type?.toString()} />
        </div>

    );
};

export default PropertyDetails;