import React, { CSSProperties } from "react";
import { IconButton } from '@mui/material';
import useReloadDataButtonStyles from "styles/ReloadDataButtonStyles";
import { Refresh } from "mdi-material-ui";

interface ReloadDataButtonProps {
    loadData: () => void;
    style?: CSSProperties
}

const ReloadDataButton = (props: ReloadDataButtonProps) => {
    const {loadData} = props
    const {classes} = useReloadDataButtonStyles();
    return (
        <div className={classes.buttonContainer} style={props.style}>
            <IconButton onClick={loadData} className={classes.reloadButton} size="large">
                <Refresh/>
            </IconButton>
        </div>
    );
}

export default ReloadDataButton;