/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface FormRemoveSuccessModelInterface {
    id?: number;
    message?: string;
}

/**
 * 
 * @export
 * @interface FormRemoveSuccessModel
 */
export class FormRemoveSuccessModel extends BaseModel<FormRemoveSuccessModelInterface> {

    /**
     * Unique per form
     * @type {number}
     * @memberof FormRemoveSuccessModel
     */
    id?: number;
    /**
     * An important message from The System
     * @type {string}
     * @memberof FormRemoveSuccessModel
     */
    message?: string;

    constructor(data?: FormRemoveSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: FormRemoveSuccessModel, value: StringDateInterface<FormRemoveSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function FormRemoveSuccessModelFromJSON(json: any): FormRemoveSuccessModel {
    return FormRemoveSuccessModelFromJSONTyped(json, false);
}

export function FormRemoveSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormRemoveSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FormRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./FormRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    });
}

export function FormRemoveSuccessModelToJSON(value?: FormRemoveSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FormRemoveSuccessModel;
    try {
        let localClass = require.context('../override', true)('./FormRemoveSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'message': value.message,
    } as StringDateInterface<FormRemoveSuccessModel>;
    return createClass.toJson(value, model);
}


