import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import Widget from 'api/override/WidgetModel';
import { retrieveWidgetSchema } from 'schemas/Widget';
import WidgetForm from 'views/Widget/components/WidgetForm';

interface WidgetDetailsDialogProps extends BaseFormModalProps<Widget> {
    widget: Widget;
}

const WidgetDetailsDialog = (props: WidgetDetailsDialogProps) => {

    const { widget } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('widgets.model')})}
            initialValues={widget}
            validationSchema={retrieveWidgetSchema()}
            action={ActionTypes.API_WIDGET_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <WidgetForm formik={formik}/>
                </div>
            }
        />
    );
};

export default WidgetDetailsDialog;