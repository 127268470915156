import React from 'react';
import use404PageStyles from 'styles/404PageStyles';

export const Page403 = () => {

    const {classes} = use404PageStyles();

    return (
        <div className={classes.container}>
            <h1>403</h1>
            <img style={{height: '50vh'}} alt="nah" src="/403.jpg" />
        </div>
    );
}
