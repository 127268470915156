import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import { MailListRequest, MailApi, MailUpdateRequest, MailRetrieveRequest, MailCreateRequest, MailDeleteRequest, MailHistoryListRequest } from 'api';
import Mail from 'api/override/MailModel';
import MailActions, { MailCreate, MailDelete, MailHistoryList, MailList, MailRetrieve, MailUpdate } from 'reducers/types/Mail';

export function* MailSagas() {
    yield takeLatest(ActionTypes.API_MAIL_LIST, MailSaga.mailListWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_UPDATE, MailSaga.mailUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_RETRIEVE, MailSaga.mailRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_CREATE, MailSaga.mailCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_DELETE, MailSaga.mailDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_HISTORY_LIST, MailSaga.mailHistoryListWorkerSaga);
}

export class MailSaga {
    
    static *mailListWorkerSaga(action: MailList) {
        try {
            const mailsArgs = {
                mailRetrieveListModel: action.params
            } as MailListRequest

            const data = yield ApiHandler(new MailApi().mailList, mailsArgs)
            yield put(MailActions.CreateMailListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_LIST_FAILURE, error as Error))
        }
    }

    static *mailUpdateWorkerSaga(action: MailUpdate) {
        try {
            
            const data = yield ApiHandler(new MailApi().mailUpdate, action.params as MailUpdateRequest);
    
            // dispatch a success action to the store with the activities
            yield put(MailActions.CreateMailUpdateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_UPDATE_FAILURE, error as Error))
        }
    }

    static mailRetrieve = async(mail: number): Promise<Mail> => {
        return await new MailApi().mailRetrieve({id: mail}) as Mail;
    }
    
    static *mailRetrieveWorkerSaga(action: MailRetrieve) {
        try {
    
            const mailsArgs = {
                id: action.params
            } as MailRetrieveRequest
            const data = yield ApiHandler(new MailApi().mailRetrieve, mailsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(MailActions.CreateMailRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *mailCreateWorkerSaga(action: MailCreate) {
        try {
    
            const mailsArgs = {
                mailModel: action.params
            } as MailCreateRequest
            const data = yield ApiHandler(new MailApi().mailCreate, mailsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(MailActions.CreateMailCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_CREATE_FAILURE, error as Error))
        }
    }
    
    static *mailDeleteWorkerSaga(action: MailDelete) {
        try {
            const mailsArgs = {
                id: action.params
            } as MailDeleteRequest
            const data = yield ApiHandler(new MailApi().mailDelete, mailsArgs)
    
            // dispatch a success action to the store with the activities
            yield put(MailActions.CreateMailDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_DELETE_FAILURE, error as Error))
        }
    }
    
    static *mailHistoryListWorkerSaga(action: MailHistoryList) {
        try{
            const mailArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as MailHistoryListRequest
    
            const data = yield ApiHandler(new MailApi().mailHistoryList, mailArgs);
            yield put(MailActions.CreateMailHistoryListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_MAIL_HISTORY_LIST_FAILURE, error as Error))
        }
    }
}

