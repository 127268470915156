import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useDialogStyles = makeStyles()((theme: Theme) => {
    return {
        text_field: {
            width: '100%',
            margin: '5px 0',
            '& label': {
                textTransform: 'capitalize'
            }
        },
        backdrop: {
            color: '#fff',
        },
        dialog: {
            alignItems: 'center',
            '& .MuiPaper-rounded': {
                borderRadius: 10
            }
        },
        dialog_content: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'start',
            padding: '8px 18px'
        },
        fields_container: {
            display: 'flex',
            flexDirection: 'column'
        },
        button: {
            marginTop: 10,
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 600,
            '&.save': {
                color: '#fff'
            },
            '&.cancel': {
                color: theme.palette.mode === 'dark' ? '#cecece' : '#696969'
            },
            '&.error': {
                color: '#fff',
                background: theme.palette.error.main
            },
            '&.signin': {
                marginTop: 50
            }
        },
        form_container: {
            width: '100%'
        },
        error_container: {
            paddingBottom: 20
        },
        type_delete_heading: {
            alignSelf: 'flex-start',
        },
        select: {
            width: '100%'
        },
        end_adornment: {
            width: '3em'
        },
        adornment_button: {
            color: theme.palette.primary.main,
            minWidth: '1em',
            '&:not(:last-child)': {
                marginRight: 4
            }
        },
        logs_container: {
            maxHeight: 1,
            maxWidth: 500
        },
        error_message: {
            margin: '-5px 0 10px 5px',
            color: 'red',
            fontSize: 10
        },
        dictionary_heading: {
            margin: '15px auto',
            fontSize: 15,   
            fontWeight: 'lighter'
        },
        label_container: {
            padding: 10
        },
        advanced_opt_button: {
            marginBottom: 10
        },
        documentation_title: {
            textAlign: 'center', 
            fontSize: 14, 
            fontWeight: 300
        },
        documentation_edit_button: {
            width: 'auto', 
            position: 'absolute', 
            right: 50, 
            top: 150
        },
        documentation_preview: {
            margin: '0 30px'
        },
        select_error: {
            border: '1px solid #d64c49'
        },
        add_object_title: {
            textAlign: 'center', 
            fontSize: 14, 
            fontWeight: 300, 
            textTransform: 'capitalize'
        },
        add_objects_container: {
            background: theme.palette.mode === 'dark' ? '#202020' : '#ebf2fb', 
            padding: '10px 20px', 
            borderRadius: 10, 
            display: 'flex', 
            flexDirection: 'column',
            margin: '10px 0 20px 0',

            '&.center': {
                textAlign: 'center'
            }
        },
        add_arr_object_button: {
            position: 'relative', 
            top: 23, 
            width: 31, 
            height: 31, 
            alignSelf: 'center', 
            background: theme.palette.primary.main, 
            color: 'white',
            '&:hover':{
                backgroundColor: theme.palette.primary.dark,
            }
        },
        remove_arr_object_button: {
            position: 'relative',
            float: 'right',
            height: 21,
            width: 21,
            right: 5,
            background: theme.palette.error.main, 
            color: 'white',
            '&:hover':{
                backgroundColor: theme.palette.error.dark,
            }
        },
        remove_arr_item_button: {
            position: 'relative',
            height: 21,
            width: 21,
            marginLeft: 10,
            background: theme.palette.error.main, 
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            }
        },
        dialog_close_button: {
            padding: 10,
            transition: 'transform 0.3s, background 0.3s',
            position: 'absolute',
            top: 10,
            right: 10,
            '&:hover': {
                color: theme.palette.error.main,
                transform: 'rotate(90deg)',
                background: theme.palette.background.paper
            }
        },
        dialog_title_container: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 40
        },
        eva_adornment: {
            '& p': {
                fontSize: 40, 
                fontWeight: 900,
                color: 'red'
            }
        },
        cc_config_title_divider: {
            width: '100%',
            position: 'relative',
            margin: '20px auto',
            display: 'flex',
            justifyContent: 'center',
            '& h3': {
                background: '#fafafa',
                textAlign: 'center',
                width: 'fit-content',
                position: 'absolute',
                top: -15,
                padding: '0 10px'
            },
            '& hr': {
                height: 3,
                width: '96%',
                backgroundColor: '#f2f2f2'
            }
        },
        dialog_title_subtitle_container: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 4,
            '& h2': {
                padding: 0,
                marginBottom: 0
            },
            '& p': {
                color: '#696969',
                fontSize: 12
            }

        },
        modal_info_container: {
            borderRadius: 4,
            border: '1px solid #DADADA',
            display: 'flex',
            padding: 10,
            minHeight: 50,
            marginBottom: 10,
            alignItems: 'center',
            '& > svg': {
                color: '#696969'
            },
            '& > hr': {
                width: 1,
                minWidth: 1,
                background: '#DADADA',
                margin: '0 7px',
                height: 44
            },
            '& > p': {
                color: '#696969'
            },
            '&.error': {
                border: `1px solid ${theme.palette.error.main}`,
                '& > hr': {
                    background: theme.palette.error.main,
                },
                '& > svg': {
                    color: theme.palette.error.main
                }
            }

        },
        widget_fields_section: {
            textAlign: 'center'
        },
        widget_field_container_header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
            '& > button': {
                padding: 0,
                background: theme.palette.error.main, 
                color: 'white',
                '&:hover':{
                    backgroundColor: theme.palette.error.dark,
                }
            }
        },
        add_new_field_btn: {
            color: 'white',
            background: theme.palette.primary.main,
            padding: 5,
            transition: 'all 0.3s',
            margin: '-5px auto',
            '&:hover': {
                color: theme.palette.primary.main,
                background: '#fafafa',
                boxShadow: '0 0 7px 2px rgba(0,0,0,0.07)',
                transform: 'rotate(90deg)'
            }
        }
}})

export default useDialogStyles