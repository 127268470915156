/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface FileUploadModelInterface {
    file?: string;
    base64?: string;
}

/**
 * 
 * @export
 * @interface FileUploadModel
 */
export class FileUploadModel extends BaseModel<FileUploadModelInterface> {

    /**
     * Name of the uploaded file
     * @type {string}
     * @memberof FileUploadModel
     */
    file?: string;
    /**
     * Base64 representation of the uploaded file
     * @type {string}
     * @memberof FileUploadModel
     */
    base64?: string;

    constructor(data?: FileUploadModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: FileUploadModel, value: StringDateInterface<FileUploadModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function FileUploadModelFromJSON(json: any): FileUploadModel {
    return FileUploadModelFromJSONTyped(json, false);
}

export function FileUploadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FileUploadModel;
    try {
        let localClass = require.context('../override', true)('./FileUploadModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'file': !exists(json, 'file') ? undefined : json['file'],
        'base64': !exists(json, 'base64') ? undefined : json['base64'],
    });
}

export function FileUploadModelToJSON(value?: FileUploadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FileUploadModel;
    try {
        let localClass = require.context('../override', true)('./FileUploadModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'file': value.file,
        'base64': value.base64,
    } as StringDateInterface<FileUploadModel>;
    return createClass.toJson(value, model);
}


