import { ConfigInputModel, ConfigInputModelInterface } from "api/models";
import i18next from "i18next";

export default class ConfigInput extends ConfigInputModel {

    inputs: ConfigInput[] = []

    constructor(data?: ConfigInputModelInterface) {
        super(data);
        this.inputs = data.inputs as ConfigInput[]
    }

    getTitle = (): string => {
        return this.title ?? this.name.bbUnderscoreToSpace()
    }

    getKeyTitle = (): string => {
        return this.key_name ?? i18next.t('general.key')
    }

    getValueTitle = (): string => {
        return this.value_name ?? i18next.t('general.value')
    }
}