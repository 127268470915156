import { ApiSuccessResponseModel, HistoryResponseModel, PropertyCreateSuccessModel, PropertyHistoryListRequest, PropertyListResultModel, PropertyRetrieveListModel } from "api";
import Property from "api/override/PropertyModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface PropertyList extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_LIST;
    params: PropertyRetrieveListModel;
}

export interface PropertyListSuccess extends BaseSagaListResponse<PropertyListResultModel> {
    type: ActionTypes.API_PROPERTY_LIST_SUCCESS;
}

export interface PropertyCreate extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_CREATE;
    params: Property;
}

export interface PropertyCreateSuccess extends BaseSagaListResponse<PropertyCreateSuccessModel> {
    type: ActionTypes.API_PROPERTY_CREATE_SUCCESS;
}

export interface PropertyUpdateParams {
    id: number;
    propertyModel: Property;
}

export interface PropertyUpdate extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_UPDATE;
    params: PropertyUpdateParams;
}

export interface PropertyUpdateSuccess extends BaseSagaListResponse<PropertyCreateSuccessModel> {
    type: ActionTypes.API_PROPERTY_UPDATE_SUCCESS;
}

export interface PropertyRetrieve extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_RETRIEVE;
    params: number;
}

export interface PropertyRetrieveSuccess extends BaseSagaListResponse<Property> {
    type: ActionTypes.API_PROPERTY_RETRIEVE_SUCCESS
}

export interface PropertyDelete extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_DELETE;
    params: number;
}

export interface PropertyDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_PROPERTY_DELETE_SUCCESS;
}

export interface PropertyHistoryList extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_HISTORY_LIST;
    params: PropertyHistoryListRequest;
}

export interface PropertyHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_PROPERTY_HISTORY_LIST_SUCCESS;
}

export interface PropertyClearListParams {
    force?: boolean;
}
export interface PropertyClearList extends BaseReducerAction {
    type: ActionTypes.API_PROPERTY_LIST_CLEAR;
    params?: PropertyClearListParams;
}

export default class PropertyActions {
    
    static CreatePropertyList(params: PropertyRetrieveListModel): PropertyList {
        return {
            type: ActionTypes.API_PROPERTY_LIST,
            params: params
        }
    }

    static CreatePropertyListSuccess(params: PropertyListResultModel): PropertyListSuccess {
        return {
            type: ActionTypes.API_PROPERTY_LIST_SUCCESS,
            params: params
        }
    }

    static CreatePropertyCreate(params: Property): PropertyCreate {
        return {
            type: ActionTypes.API_PROPERTY_CREATE,
            params: params
        }
    }

    static CreatePropertyCreateSuccess(params: PropertyCreateSuccessModel): PropertyCreateSuccess {
        return {
            type: ActionTypes.API_PROPERTY_CREATE_SUCCESS,
            params: params
        }
    }

    static CreatePropertyUpdate(params: Property): PropertyUpdate {
        return {
            type: ActionTypes.API_PROPERTY_UPDATE,
            params: {
                id: params.id,
                propertyModel: params
            }
        }
    }

    static CreatePropertyUpdateSuccess(params: PropertyCreateSuccessModel): PropertyUpdateSuccess {
        return {
            type: ActionTypes.API_PROPERTY_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreatePropertyRetrieve(params: number): PropertyRetrieve {
        return {
            type: ActionTypes.API_PROPERTY_RETRIEVE,
            params: params
        }
    }

    static CreatePropertyRetrieveSuccess(params: Property): PropertyRetrieveSuccess {
        return {
            type: ActionTypes.API_PROPERTY_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreatePropertyDelete(params: number): PropertyDelete {
        return {
            type: ActionTypes.API_PROPERTY_DELETE,
            params: params
        }
    }

    static CreatePropertyDeleteSuccess(params: ApiSuccessResponseModel): PropertyDeleteSuccess {
        return {
            type: ActionTypes.API_PROPERTY_DELETE_SUCCESS,
            params: params
        }
    }

    static CreatePropertyHistoryList(params: PropertyHistoryListRequest): PropertyHistoryList {
        return {
            type: ActionTypes.API_PROPERTY_HISTORY_LIST,
            params: params
        }
    }

    static CreatePropertyHistoryListSuccess(params: HistoryResponseModel): PropertyHistoryListSuccess {
        return {
            type: ActionTypes.API_PROPERTY_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearPropertyList(force: boolean = false): PropertyClearList {
        return {
            type: ActionTypes.API_PROPERTY_LIST_CLEAR,
            params: {force: force}
        }   
    }

}