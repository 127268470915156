import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useInstanceDetailsStyles = makeStyles()((theme: Theme) => {
    return {
        settingContainer: {
            marginTop: 8,
            padding: 16
        },
        settingsForm: {
            width: '100%'
        },
        submitButtonContainer: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            marginBottom: 20
        },
    }    
})

export default useInstanceDetailsStyles