import RequestLog from "api/override/RequestLogModel";
import ActionTypes from "config/ActionTypes";
import HistoryState from "./interface/HistoryState";
import BaseReducerAction from "./interface/ReducerAction";
import { HistoryListSuccess } from "./types/History";

const initialState: HistoryState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
};

export function HistoryReducers(state = initialState, action: BaseReducerAction): HistoryState {
    switch (action.type) {
        case ActionTypes.API_HISTORY_LIST:
            return {
                ...state,
                selectedObject: undefined
            };

        case ActionTypes.API_HISTORY_LIST_SUCCESS:
            const result = action as HistoryListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<RequestLog>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                }
            };

        default:
            return state;
    }
}