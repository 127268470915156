import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import { FormikProps } from 'formik';
import Branch from 'api/override/BranchModel';

interface DummyDataSetDialogProps extends BaseFormModalProps<Branch> {
    branch: Branch
}

const DummyDataSetDialog = (props: DummyDataSetDialogProps) => {

    const {branch} = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props}
            title={t('global.action.update_model', {model: t('branches.model')})}
            subtitle={t('branches.set_dummy_data')}
            icon={'CodeJson'}
            initialValues={branch}
            action={ActionTypes.API_BRANCH_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik: FormikProps<Branch>) => 
                <div className={classes.fields_container}>
                    {/* dummy data single select */}
                </div>
            }
        />
    )
};

export default DummyDataSetDialog;