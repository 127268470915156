import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Branch from "api/override/BranchModel";
import Condition from "api/override/ConditionModel";
import Stepview from "api/override/StepviewModel";
import Wizard from "api/override/WizardModel";
import { t } from "i18next";
import MultiSelectOption from "interface/MultiSelectOption";
import { Plus, SourceBranchPlus } from "mdi-material-ui";
import React from "react";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import ConditionView from "./ConditionView";

interface StepviewWizardCardProps {
    wizard: Wizard;
    stepview: Stepview;
    onEdit: (id: number) => void;
    onDelete: () => void;
    onAddStepview: () => void;
    onAddBranch: (id: number) => void;
    onConditionUpdate: (condition: Condition) => void;
    onConditionValueUpdate: (newVal: MultiSelectOption, br: Branch) => void;
    onAddMoreThanTwoBranches: (condition: Condition) => void;
    onSetDummyData?: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

const StepviewWizardCard = (props: StepviewWizardCardProps) => {
    
    const {wizard, stepview, onEdit, onDelete, onAddStepview, onAddBranch, isFirst, isLast, onConditionUpdate, onConditionValueUpdate, onAddMoreThanTwoBranches} = props
    const { classes } = useWizardBuilderStyles()

    return <>
    <div className={classes.stepview_card_container}>
        {isFirst && <div className={classes.stepview_card_line}></div>}
        <div className={`${classes.stepview_card}`}>
            <h5>{stepview?.name}</h5>
            <IconButton
                className={`${classes.stepview_card_btn} delete`}
                onClick={onDelete}
                size="large">
                <DeleteIcon/>
            </IconButton>
            <IconButton
                className={`${classes.stepview_card_btn} edit`}
                onClick={() => onEdit(stepview.id)}
                size="large">
                <EditIcon/>
            </IconButton>
        </div>
        <div className={`${classes.stepview_card_line}${isLast ? ' last' : ''}`}></div>
       
        {isLast && 
            <>
            <Tooltip title={t('global.action.add_model', {model: t('stepviews.model')}) ?? ''}>
                <IconButton className={classes.stepview_add_btn} onClick={onAddStepview} size="large">
                    <Plus/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('global.action.add_model', {model: t('branches.model')}) ?? ''}>
                <IconButton
                    className={classes.add_branch_button}
                    onClick={() => onAddBranch(stepview.id)}
                    size="large">
                    <SourceBranchPlus/>
                </IconButton>
            </Tooltip>
            {/* TODO: Implement dummy data single select */}
            {/* <Tooltip title={t('branches.set_dummy_data') ?? ''}>
                <IconButton className={classes.set_dummy_data_btn} onClick={() => onSetDummyData()} disabled> 
                    <CodeJson/>
                </IconButton>
            </Tooltip> */}
            </>
        }
    </div>
        {(stepview.condition != null) && 
            <ConditionView 
                wizard={wizard}
                condition={stepview.condition as Condition}
                onConditionValueUpdate={onConditionValueUpdate}
                onConditionUpdate={() => {onConditionUpdate(stepview.condition as Condition)}}
                onAddBranch={() => onAddMoreThanTwoBranches(stepview.condition)}/>
        }
    </>;
}

export default StepviewWizardCard;