import React from 'react';
import { useTranslation } from "react-i18next";
import Client from 'api/override/ClientModel';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import ActionTypes from 'config/ActionTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import { retrieveClientSchema } from 'schemas/Client';
import { FormikProps } from 'formik';
import ClientForm from 'views/Clients/components/ClientForm';


interface ClientDetailsDialogProps extends BaseFormModalProps<Client> { }

const ClientDetailsDialog = (props: ClientDetailsDialogProps) => {

    const { t } = useTranslation();
    const isFetchingClient = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_CLIENT_RETRIEVE))
    const isFetchingProperties =  useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_PROPERTY_LIST));
    const isFetching = isFetchingClient || isFetchingProperties
    const client: Client = useSelector((state: RootState) => state.clients.selectedObject)

    return (
        !isFetching && 
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('clients.model')})}
            initialValues={client}
            action={ActionTypes.API_CLIENT_UPDATE}
            validationSchema={retrieveClientSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik: FormikProps<Client>) => 
                <ClientForm formik={formik}/>
            }
        />
    );
};

export default ClientDetailsDialog;