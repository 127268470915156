/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    LoadBalancerModel,
    LoadBalancerModelFromJSON,
    LoadBalancerModelFromJSONTyped,
    LoadBalancerModelToJSON,
} from './';


export interface LoadBalancerStatusModelInterface {
    servers?: Array<LoadBalancerModel>;
}

/**
 * 
 * @export
 * @interface LoadBalancerStatusModel
 */
export class LoadBalancerStatusModel extends BaseModel<LoadBalancerStatusModelInterface> {

    /**
     * 
     * @type {Array<LoadBalancerModel>}
     * @memberof LoadBalancerStatusModel
     */
    servers?: Array<LoadBalancerModel>;

    constructor(data?: LoadBalancerStatusModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: LoadBalancerStatusModel, value: StringDateInterface<LoadBalancerStatusModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function LoadBalancerStatusModelFromJSON(json: any): LoadBalancerStatusModel {
    return LoadBalancerStatusModelFromJSONTyped(json, false);
}

export function LoadBalancerStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadBalancerStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = LoadBalancerStatusModel;
    try {
        let localClass = require.context('../override', true)('./LoadBalancerStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'servers': !exists(json, 'servers') ? undefined : ((json['servers'] as Array<any>).map(LoadBalancerModelFromJSON)),
    });
}

export function LoadBalancerStatusModelToJSON(value?: LoadBalancerStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = LoadBalancerStatusModel;
    try {
        let localClass = require.context('../override', true)('./LoadBalancerStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'servers': value.servers === undefined ? undefined : ((value.servers as Array<any>).map(LoadBalancerModelToJSON)),
    } as StringDateInterface<LoadBalancerStatusModel>;
    return createClass.toJson(value, model);
}


