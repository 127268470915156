import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyledComponentsStyles = makeStyles()((theme: Theme) => {
    return {
        textarea: {
            margin: '5px 0',
            background: '#fff0',
            padding: '10px 13px',
            border: '1px solid #c0c0c0',
            borderRadius: 3,
            fontFamily: 'inherit',
            fontSize: '0.857143rem',
            outline: 'none',
            minHeight: 42,
            width: '100%',
            resize: 'vertical',
            '&:active, &:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
            }
        }
    }
})

export default useStyledComponentsStyles