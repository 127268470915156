import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Connector from 'api/override/ConnectorModel';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import ImageController from 'controllers/ImageController';
import ImageVersionController from 'controllers/ImageVersionController';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { ImageModelForModelEnum } from 'api';
import ButtonStyled from 'components/styled/ButtonStyled';
import { retrieveConnectorSchema } from 'schemas/Connector';
import Constants from 'config/Constants';
import React from 'react'

interface ConnectorCreateFormProps {
    onFormSubmit?: (value?: Connector) => void;
}

const ConnectorCreateForm = (props: ConnectorCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const [imageController] = useState<ImageController>(new ImageController())
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const copyUrlToNamespaceAndSlugify = (formik: FormikProps<Connector>): void => {
        const slugifiedString = formik.values.url.bbStringToSlug()
        formik.setFieldValue('url', slugifiedString)
        formik.setFieldValue('namespace', slugifiedString)
    }

    const handleImageChange = (formik: FormikProps<Connector>) => {
        const imageName: string = imageController.getCachedItemByValue(formik.values.image_id ?? 0)?.label ?? '';
        formik.setFieldValue('name', imageName);
        formik.setFieldValue('url', imageName.bbStringToSlug())
        formik.setFieldValue('namespace', imageName.bbStringToSlug())
        if(formik.values.redirect_image_id != null && !Boolean(formik.values.redirect_uri)) {
            formik.setFieldValue('redirect_uri', Constants.CONNECTOR_REDIRECT_URI)
        }
    }

    return (
        <Formik
            initialValues={new Connector({
                name: '',
                url: '',
                namespace: '',
                image_id: null,
                image: null,
                redirect_uri: '',
                redirect_image_id: null,
                redirect_image: null,
                redirect_image_version_id: null,
                redirect_image_version: null
            })}
            validationSchema={retrieveConnectorSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Connector>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('connector.model')})}</h2>
                    
                    <ImageSingleSelect 
                        id='image'
                        name='image_id'
                        controller={imageController}
                        formik={formik}
                        label={t('connector.image')}
                        model={[ImageModelForModelEnum.Connector, ImageModelForModelEnum.ConnectorContainer]}
                        additionalActionOnImageChange={handleImageChange}
                    />

                    <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        disabled={formik.values.image_id == null}
                        label={t('connector.name')}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="url" 
                        name="url"
                        disabled={formik.values.image_id == null}
                        label={t('connector.url')}
                        helperText={formik.touched.url ? formik.errors.url : ""}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        onBlur={() => copyUrlToNamespaceAndSlugify(formik)}
                    />

                    <TextFieldStyled 
                        id="namespace" 
                        name="namespace"
                        disabled={formik.values.image_id == null}
                        label={t('connector.namespace')}
                        helperText={formik.touched.namespace ? formik.errors.namespace : ""}
                        error={formik.touched.namespace && Boolean(formik.errors.namespace)}
                        value={formik.values.namespace}
                        onChange={formik.handleChange}
                    />

                    <ImageSingleSelect 
                        id='redirect_image'
                        name='redirect_image_id'
                        disabled={formik.values.image_id == null}
                        controller={imageController}
                        additionalActionOnImageChange={handleImageChange}
                        formik={formik}
                        label={t('connector.redirect_image')}
                        model={[ImageModelForModelEnum.Connector, ImageModelForModelEnum.ConnectorContainer]}
                    />

                    <ImageVersionSingleSelect 
                        id='redirect_image_version'
                        name='redirect_image_version_id'
                        controller={imageVersionController}
                        formik={formik}
                        imageId={formik.values.redirect_image_id}
                        disabled={formik.values.redirect_image_id == null}
                        label={t('connector.redirect_image_version')}
                    />

                    <TextFieldStyled 
                        id="redirect_uri" 
                        name="redirect_uri"
                        disabled={formik.values.redirect_image_id == null}
                        label={t('connector.redirect_uri')}
                        helperText={formik.touched.redirect_uri ? formik.errors.redirect_uri : ""}
                        error={formik.touched.redirect_uri && Boolean(formik.errors.redirect_uri)}
                        value={formik.values.redirect_uri}
                        onChange={formik.handleChange}
                    />

                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('connector.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default ConnectorCreateForm;