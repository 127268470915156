import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import DashboardState from "./interface/DashboardState";

const initialState: DashboardState = {
    available: [],
    widgetData: Object,
};

export function DashboardReducers(state = initialState, action: BaseReducerAction): DashboardState {
    switch (action.type) {
        case ActionTypes.API_WIDGET_GET_AVAILABLE:
            return {
                ...state,
                error: null
            };   
        
        case ActionTypes.API_WIDGET_GET_AVAILABLE_SUCCESS:
            return {
                ...state,
                error: null,
                available: action.params.data
            };   

        case ActionTypes.API_WIDGET_GET_DATA:
            return {
                ...state,
                error: null,
                widgetData: {}
            };

        case ActionTypes.API_WIDGET_GET_DATA_SUCCESS:
            return {
                ...state,
                error: null,
                widgetData: action.params.widgets
            };   
        default:
            return state;
    }
}