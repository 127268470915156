/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    FormFieldModel,
    FormFieldModelFromJSON,
    FormFieldModelFromJSONTyped,
    FormFieldModelToJSON,
} from './';


export interface FormModelInterface {
    id?: number;
    title?: string;
    description?: string;
    icon?: string;
    system?: boolean;
    form_url?: string;
    fields?: Array<FormFieldModel>;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface FormModel
 */
export class FormModel extends BaseModel<FormModelInterface> {

    /**
     * id of the form
     * @type {number}
     * @memberof FormModel
     */
    id?: number;
    /**
     * Name of the form
     * @type {string}
     * @memberof FormModel
     */
    title?: string;
    /**
     * Description of the form
     * @type {string}
     * @memberof FormModel
     */
    description?: string;
    /**
     * Icon of the form in the backoffice
     * @type {string}
     * @memberof FormModel
     */
    icon?: string;
    /**
     * Form made by the system
     * @type {boolean}
     * @memberof FormModel
     */
    system?: boolean;
    /**
     * Url to post to
     * @type {string}
     * @memberof FormModel
     */
    form_url?: string;
    /**
     * 
     * @type {Array<FormFieldModel>}
     * @memberof FormModel
     */
    fields?: Array<FormFieldModel>;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof FormModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof FormModel
     */
    updated_at?: Date;

    constructor(data?: FormModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: FormModel, value: StringDateInterface<FormModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function FormModelFromJSON(json: any): FormModel {
    return FormModelFromJSONTyped(json, false);
}

export function FormModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = FormModel;
    try {
        let localClass = require.context('../override', true)('./FormModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'system': !exists(json, 'system') ? undefined : json['system'],
        'form_url': !exists(json, 'form_url') ? undefined : json['form_url'],
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(FormFieldModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function FormModelToJSON(value?: FormModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = FormModel;
    try {
        let localClass = require.context('../override', true)('./FormModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'icon': value.icon,
        'system': value.system,
        'form_url': value.form_url,
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(FormFieldModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<FormModel>;
    return createClass.toJson(value, model);
}


