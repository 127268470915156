/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    PropertyCreateSuccessModel,
    PropertyCreateSuccessModelFromJSON,
    PropertyCreateSuccessModelToJSON,
    PropertyListResultModel,
    PropertyListResultModelFromJSON,
    PropertyListResultModelToJSON,
    PropertyModel,
    PropertyModelFromJSON,
    PropertyModelToJSON,
    PropertyRemoveSuccessModel,
    PropertyRemoveSuccessModelFromJSON,
    PropertyRemoveSuccessModelToJSON,
    PropertyRetrieveListModel,
    PropertyRetrieveListModelFromJSON,
    PropertyRetrieveListModelToJSON,
} from '../models';

export interface PropertyCreateRequest {
    propertyModel: PropertyModel;
}

export interface PropertyDeleteRequest {
    id: number;
}

export interface PropertyHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface PropertyListRequest {
    propertyRetrieveListModel: PropertyRetrieveListModel;
}

export interface PropertyRetrieveRequest {
    id: number;
}

export interface PropertyUpdateRequest {
    id: number;
    propertyModel: PropertyModel;
}

/**
 * 
 */
export class PropertyApi extends runtime.BaseAPI {

    /**
     * Create a new property
     * Create a new property
     */
    async propertyCreateRaw(requestParameters: PropertyCreateRequest): Promise<runtime.ApiResponse<PropertyCreateSuccessModel>> {
        if (requestParameters.propertyModel === null || requestParameters.propertyModel === undefined) {
            throw new runtime.RequiredError('propertyModel','Required parameter requestParameters.propertyModel was null or undefined when calling propertyCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyModelToJSON(requestParameters.propertyModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new property
     * Create a new property
     */
    propertyCreate = async(requestParameters: PropertyCreateRequest): Promise<PropertyCreateSuccessModel> => {
        const response = await this.propertyCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a property
     * Delete a property
     */
    async propertyDeleteRaw(requestParameters: PropertyDeleteRequest): Promise<runtime.ApiResponse<PropertyRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling propertyDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a property
     * Delete a property
     */
    propertyDelete = async(requestParameters: PropertyDeleteRequest): Promise<PropertyRemoveSuccessModel> => {
        const response = await this.propertyDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a property
     * retrieve a list of historical logs for a property
     */
    async propertyHistoryListRaw(requestParameters: PropertyHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling propertyHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling propertyHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a property
     * retrieve a list of historical logs for a property
     */
    propertyHistoryList = async(requestParameters: PropertyHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.propertyHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all properties
     * List all properties
     */
    async propertyListRaw(requestParameters: PropertyListRequest): Promise<runtime.ApiResponse<PropertyListResultModel>> {
        if (requestParameters.propertyRetrieveListModel === null || requestParameters.propertyRetrieveListModel === undefined) {
            throw new runtime.RequiredError('propertyRetrieveListModel','Required parameter requestParameters.propertyRetrieveListModel was null or undefined when calling propertyList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyRetrieveListModelToJSON(requestParameters.propertyRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyListResultModelFromJSON(jsonValue));
    }

    /**
     * List all properties
     * List all properties
     */
    propertyList = async(requestParameters: PropertyListRequest): Promise<PropertyListResultModel> => {
        const response = await this.propertyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a property by its id
     * Retrieve a property by its id
     */
    async propertyRetrieveRaw(requestParameters: PropertyRetrieveRequest): Promise<runtime.ApiResponse<PropertyModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling propertyRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a property by its id
     * Retrieve a property by its id
     */
    propertyRetrieve = async(requestParameters: PropertyRetrieveRequest): Promise<PropertyModel> => {
        const response = await this.propertyRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a property
     * Update a property
     */
    async propertyUpdateRaw(requestParameters: PropertyUpdateRequest): Promise<runtime.ApiResponse<PropertyCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling propertyUpdate.');
        }

        if (requestParameters.propertyModel === null || requestParameters.propertyModel === undefined) {
            throw new runtime.RequiredError('propertyModel','Required parameter requestParameters.propertyModel was null or undefined when calling propertyUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/property/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyModelToJSON(requestParameters.propertyModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update a property
     * Update a property
     */
    propertyUpdate = async(requestParameters: PropertyUpdateRequest): Promise<PropertyCreateSuccessModel> => {
        const response = await this.propertyUpdateRaw(requestParameters);
        return await response.value();
    }

}
