import { Grid, LinearProgress } from '@mui/material';
import Instance from 'api/override/InstanceModel';
import InstanceSetting from 'api/override/InstanceSettingModel';
import SingleSelect from 'components/form_controls/SingleSelect';
import ButtonStyled from 'components/styled/ButtonStyled';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ActionTypes from 'config/ActionTypes';
import { Form, Formik, FormikProps } from 'formik';
import MultiSelectOption from 'interface/MultiSelectOption';
import { ContentSaveOutline } from 'mdi-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import InstanceActions from 'reducers/types/Instance';
import { RootState } from 'store/AppStore';
import useInstanceDetailsStyles from 'styles/InstanceDetailsStyles';

interface InstanceSettingsProps {
    instance: Instance;
}

interface SettingsForm {
    settings: InstanceSetting[];
}

const InstanceSettings = (props: InstanceSettingsProps) => {
    const dispatch = useDispatch();
    const settings = useSelector((state: RootState) => state.instances.settings);
    const { classes } = useInstanceDetailsStyles();
    const { t } = useTranslation();
    const isFetchingSettings = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS))

    const onSubmit = (values: SettingsForm) => {
        const changedSettings = values.settings.filter((setting, index) => {
            return setting.value !== settings[index].value;
        })
        dispatch(InstanceActions.CreateInstanceUpdateSettings(props.instance, changedSettings))
    }

    const handleComponentDidMount = () => {
        if (settings.bbIsEmpty()) {
            dispatch(InstanceActions.CreateInstanceRetrieveSettings(props.instance.uuid))
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleComponentDidMount, []);

    return (
        isFetchingSettings ? (
            <LinearProgress />
        ) 
        :
        settings.bbIsEmpty() ? <></> :
        (
        <Grid container className={classes.settingContainer} spacing={1}>
            <Grid item xs={12}>
                    <Formik
                        initialValues={{ settings: settings }}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<SettingsForm>) => (
                            <Form>
                                <div className={classes.submitButtonContainer}>
                                    <h2>{t('instance.settings')}</h2>
                                    <ButtonStyled type="submit" onClick={formik.submitForm} startIcon={<ContentSaveOutline/>}>
                                        {t('global.button.save_changes')}
                                    </ButtonStyled>
                                </div>
                                <Grid container spacing={1}>
                                    {formik.values.settings.map((setting, index) => {
                                        const inputName = `settings[${index}].value`;
                                        const sharedProps = {
                                            label: setting.key,
                                            name: inputName
                                        }
                                        return (
                                            <Grid item key={setting.id} xs={12}>
                                                {setting.value_options.bbIsEmpty() ? (
                                                    <TextFieldStyled
                                                        {...sharedProps}
                                                        value={setting.value}
                                                        onChange={formik.handleChange}
                                                    />
                                                ) : (
                                                    <SingleSelect
                                                        {...sharedProps}
                                                        value={setting.getValueAsMultiSelect()}
                                                        options={setting.value_options}
                                                        onChange={(ev: React.ChangeEvent<{}>, value: MultiSelectOption) => {
                                                            formik.setFieldValue(inputName, value.value);
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Form>
                        )}
                    </Formik>
            </Grid>
        </Grid>
        )
    );
}

export default InstanceSettings;