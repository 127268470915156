import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Service from 'api/override/ServiceModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/AppStore';
import { Form, Formik, FormikProps } from 'formik';
import ServiceSecret from 'api/override/ServiceSecretModel';
import { ContentSaveOutline } from 'mdi-material-ui';
import ButtonStyled from 'components/styled/ButtonStyled';
import useServiceStyles from 'styles/ServiceStyles';
import { LinearProgress } from '@mui/material';
import ServiceContainerSelect from './ServiceContainerSelect';
import ServiceContainerActions from 'reducers/types/ServiceContainer';
import ServiceSecretForm from './ServiceSecretForm';
import ActionTypes from 'config/ActionTypes';
import ServiceContainer from 'api/override/ServiceContainerModel';

interface ServiceContainerSecretsTabProps {
    service: Service;
    onSaveChanges: (conatinerUuid: string, serviceSecrets: ServiceSecret) => void;
    selectedServiceContainer: ServiceContainer | undefined;
    onSelectServiceContainer: (sc: ServiceContainer) => void;
}

const ServiceContainerSecretsTab = (props: ServiceContainerSecretsTabProps) => {
    const { t } = useTranslation();
    const { service, onSaveChanges, onSelectServiceContainer, selectedServiceContainer } = props;
    const {classes} = useServiceStyles();

    const formRef = useRef<FormikProps<ServiceSecret>>(null);
    const dispatch = useDispatch();
    const serviceContainerSecrets = useSelector((state: RootState) => state.serviceContainers.secrets)
    const isFetchingSecrets = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS))

    const retrieveSecrets = () => {
        if(selectedServiceContainer != null) {
            dispatch(ServiceContainerActions.CreateServiceContainerRetrieveSecrets({uuid: selectedServiceContainer.uuid}))
        }
    }
    // eslint-disable-next-line
    React.useEffect(retrieveSecrets, [selectedServiceContainer])
    
    return (
        <div className={classes.card_content}>
            <ServiceContainerSelect
                chosenValue={selectedServiceContainer}
                serviceUuid={service?.uuid}
                onContainerSelect={(sc: ServiceContainer) => {onSelectServiceContainer(sc)}}
            />
            {serviceContainerSecrets != null ?
                <Formik
                initialValues={serviceContainerSecrets}
                onSubmit={(values: ServiceSecret) => onSaveChanges(selectedServiceContainer.uuid, values)}
                innerRef={formRef}>
                    {(formik: FormikProps<ServiceSecret>) => 
                    <Form>
                        <>
                        <div className={classes.ss_header}>
                            <h2>{t('service.secrets')}</h2>
                            <ButtonStyled type="submit" startIcon={<ContentSaveOutline/>}>
                                {t('global.button.save')}
                            </ButtonStyled>
                        </div>
                        <ServiceSecretForm formik={formik} />
                        </>
                    </Form>
                    }
                </Formik>
            : isFetchingSecrets ?
                <LinearProgress className={classes.ss_linear_progress}/>
                :
                <></>
            }
            
        </div>
       
    );
};

export default ServiceContainerSecretsTab;