import MultiSelectOption from "interface/MultiSelectOption";

export default class EnumUtil {

    static enumToMultiSelectOptionArrayFromEntries(enumertaion: { [k: string]: string}): Array<MultiSelectOption> {
        const returnVal: Array<MultiSelectOption> = Object.entries(enumertaion).map(([enumKey, enumVal]) => {return {label: enumKey, value: enumVal}})
        return returnVal
    }

    static enumToMultiSelectOptionArrayFromKeys(enumertaion: { [k: string]: string}): Array<MultiSelectOption> {
        const returnVal: Array<MultiSelectOption> = Object.keys(enumertaion).map((enumKey) => {return {label: enumKey, value: enumKey}})
        return returnVal
    }

    static enumToMultiSelectOptionArrayFromValues(enumertaion: { [k: string]: string}): Array<MultiSelectOption> {
        const returnVal: Array<MultiSelectOption> = Object.values(enumertaion).map((enumVal) => {return {label: enumVal, value: enumVal}})
        return returnVal
    }

    static enumToArrayFromValues(enumertaion: { [k: string]: string}): Array<string> {
        const returnVal: Array<string> = Object.values(enumertaion).map((enumVal) => enumVal)
        return returnVal
    }

}