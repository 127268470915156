/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    RequestLogModel,
    RequestLogModelFromJSON,
    RequestLogModelFromJSONTyped,
    RequestLogModelToJSON,
} from './';


export interface HistoryResponseModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<RequestLogModel>;
}

/**
 * 
 * @export
 * @interface HistoryResponseModel
 */
export class HistoryResponseModel extends BaseModel<HistoryResponseModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof HistoryResponseModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof HistoryResponseModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<RequestLogModel>}
     * @memberof HistoryResponseModel
     */
    items?: Array<RequestLogModel>;

    constructor(data?: HistoryResponseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: HistoryResponseModel, value: StringDateInterface<HistoryResponseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function HistoryResponseModelFromJSON(json: any): HistoryResponseModel {
    return HistoryResponseModelFromJSONTyped(json, false);
}

export function HistoryResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = HistoryResponseModel;
    try {
        let localClass = require.context('../override', true)('./HistoryResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(RequestLogModelFromJSON)),
    });
}

export function HistoryResponseModelToJSON(value?: HistoryResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = HistoryResponseModel;
    try {
        let localClass = require.context('../override', true)('./HistoryResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(RequestLogModelToJSON)),
    } as StringDateInterface<HistoryResponseModel>;
    return createClass.toJson(value, model);
}


