import React, { useState } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from './SingleSelect';
import { FormikProps } from 'formik';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import PackageController from 'controllers/PackageController';

interface PackageSingleSelectProps<T> {
    id: string;
    controller: PackageController;
    formik: FormikProps<T>;
    name: string;
}

const PackageSingleSelect = <T, >(props: PackageSingleSelectProps<T>) => {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const chosenPackage = props.formik.values[props.name]

    const getPackages = props.controller.packageToMultiselect;

    const handleComponentDidMount = () => {
        if(props.formik.values[props.name]?.value === 0){
            getPackages(params).then((packages?: MultiSelectOption[]) => {
                setPackage(null, packages?.find(_package => _package.label === 'Default') ?? packages[0])
            })
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleComponentDidMount, [])

    const setPackage = (_, val: MultiSelectOption) => {
        props.formik.setFieldValue(props.name, val);
    }

    return (
        <SingleSelect
            label={t('packages.model')}
            name={props.name}
            tableParams={params}
            value={chosenPackage}
            getAsyncValuesOnSearch
            getAsyncOptions={getPackages}
            onChange={setPackage}
            formikError={props.formik.touched[props.id] && Boolean(props.formik.errors[props.id])}
            formikHelperText={props.formik.touched[props.id] ? props.formik.errors[props.id] : ""}
        />
    )
}

export default PackageSingleSelect;
