import { ApiSuccessResponseModel, HistoryResponseModel, FormCreateSuccessModel, FormHistoryListRequest, FormListResultModel, FormRetrieveListModel } from "api";
import Form from "api/override/FormModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface FormList extends BaseReducerAction {
    type: ActionTypes.API_FORM_LIST;
    params: FormRetrieveListModel;
}

export interface FormListSuccess extends BaseSagaListResponse<FormListResultModel> {
    type: ActionTypes.API_FORM_LIST_SUCCESS;
}

export interface FormCreate extends BaseReducerAction {
    type: ActionTypes.API_FORM_CREATE;
    params: Form;
}

export interface FormCreateSuccess extends BaseSagaListResponse<FormCreateSuccessModel> {
    type: ActionTypes.API_FORM_CREATE_SUCCESS;
}

export interface FormUpdateParams {
    id: number;
    formModel: Form;
}

export interface FormUpdate extends BaseReducerAction {
    type: ActionTypes.API_FORM_UPDATE;
    params: FormUpdateParams;
}

export interface FormUpdateSuccess extends BaseSagaListResponse<FormCreateSuccessModel> {
    type: ActionTypes.API_FORM_UPDATE_SUCCESS;
}

export interface FormRetrieve extends BaseReducerAction {
    type: ActionTypes.API_FORM_RETRIEVE;
    params: number;
}

export interface FormRetrieveSuccess extends BaseSagaListResponse<Form> {
    type: ActionTypes.API_FORM_RETRIEVE_SUCCESS
}

export interface FormDelete extends BaseReducerAction {
    type: ActionTypes.API_FORM_DELETE;
    params: number;
}

export interface FormDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_FORM_DELETE_SUCCESS;
}

export interface FormHistoryList extends BaseReducerAction {
    type: ActionTypes.API_FORM_HISTORY_LIST;
    params: FormHistoryListRequest;
}

export interface FormHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_FORM_HISTORY_LIST_SUCCESS;
}

export interface FormClearListParams {
    force?: boolean;
}
export interface FormClearList extends BaseReducerAction {
    type: ActionTypes.API_FORM_LIST_CLEAR;
    params?: FormClearListParams;
}

export default class FormActions {
    
    static CreateFormList(params: FormRetrieveListModel): FormList {
        return {
            type: ActionTypes.API_FORM_LIST,
            params: params
        }
    }

    static CreateFormListSuccess(params: FormListResultModel): FormListSuccess {
        return {
            type: ActionTypes.API_FORM_LIST_SUCCESS,
            params: params
        }
    }

    static CreateFormCreate(params: Form): FormCreate {
        return {
            type: ActionTypes.API_FORM_CREATE,
            params: params
        }
    }

    static CreateFormCreateSuccess(params: FormCreateSuccessModel): FormCreateSuccess {
        return {
            type: ActionTypes.API_FORM_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateFormUpdate(params: Form): FormUpdate {
        return {
            type: ActionTypes.API_FORM_UPDATE,
            params: {
                id: params.id,
                formModel: params
            }
        }
    }

    static CreateFormUpdateSuccess(params: FormCreateSuccessModel): FormUpdateSuccess {
        return {
            type: ActionTypes.API_FORM_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateFormRetrieve(params: number): FormRetrieve {
        return {
            type: ActionTypes.API_FORM_RETRIEVE,
            params: params
        }
    }

    static CreateFormRetrieveSuccess(params: Form): FormRetrieveSuccess {
        return {
            type: ActionTypes.API_FORM_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateFormDelete(params: number): FormDelete {
        return {
            type: ActionTypes.API_FORM_DELETE,
            params: params
        }
    }

    static CreateFormDeleteSuccess(params: ApiSuccessResponseModel): FormDeleteSuccess {
        return {
            type: ActionTypes.API_FORM_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateFormHistoryList(params: FormHistoryListRequest): FormHistoryList {
        return {
            type: ActionTypes.API_FORM_HISTORY_LIST,
            params: params
        }
    }

    static CreateFormHistoryListSuccess(params: HistoryResponseModel): FormHistoryListSuccess {
        return {
            type: ActionTypes.API_FORM_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearFormList(force: boolean = false): FormClearList {
        return {
            type: ActionTypes.API_FORM_LIST_CLEAR,
            params: {force: force}
        }   
    }

}