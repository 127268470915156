import React, { MouseEvent } from 'react';
import Form from 'api/override/FormModel';
import { useNavigate } from "react-router-dom";
import useFormCardStyles from 'styles/FormCardStyles';
import { Wordpress, Monitor, EmoticonPoop, HelpCircleOutline } from 'mdi-material-ui';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import EnvUtil from 'utils/EnvUtil';

interface SystemFormCardProps {
    form: Form;
}

const SystemFormCard = (props: SystemFormCardProps) => {
    const { form } = props;
    const navigate = useNavigate();
    const {classes} = useFormCardStyles();

    const getAssignedIcon = (): JSX.Element => {
        let AssignedIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = null;
        switch (form.icon) {
            case 'Wordpress':
                AssignedIcon = Wordpress
                break;
            case 'HumanMaleBoardPoll':
                AssignedIcon = Monitor
                break;
            default:
                AssignedIcon = EnvUtil.isDevelopment() ? EmoticonPoop : HelpCircleOutline
                break;
        }
        return <AssignedIcon/>
    }

    const getStyling = (): string => {
        let styling = '';
        switch (form.icon) {
            case 'Wordpress':
                styling = 'wp'
                break;
            case 'HumanMaleBoardPoll':
                styling = 'onboarding'
                break;
            default:
                break;
        }
        return styling
    }

    const handleOnCardClick = (e: MouseEvent<HTMLDivElement>) => {
        navigate(`/forms/${form.id?.toString()}`)
    }

    return (
        <div className={`${classes.root} ${getStyling()}`} onClick={handleOnCardClick}>
            {getAssignedIcon()}
            <h2>{form.title}</h2>
            <p>{form.description}</p>
        </div>
    );
};

export default SystemFormCard;