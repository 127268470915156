import React from 'react';
import { useTranslation } from "react-i18next";
import { RoleModel } from 'api';
import ReadOnlyField from 'components/ReadOnlyField';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from 'components/form_controls/SingleSelect';
import User from 'api/override/UserModel';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { retrieveUserUpdateSchema } from 'schemas/User';
import BBCheckbox from 'components/form_controls/BBCheckbox';


interface UserDetailsDialogProps extends BaseFormModalProps<User> {
    user?: User;
    roles: RoleModel[];
}

const UserDetailsDialog = (props: UserDetailsDialogProps) => {

    const { user, roles } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const roleOptions: MultiSelectOption[] = roles.map((role: RoleModel) => {return {value: role.name, label: t(`users.roles.${role.name}`)}})

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('users.model')})}
            initialValues={new User({
                ...user,
                full_name: user?.full_name || '',
                password: user?.password || '',
                email: user?.email || '',
                role_name: user?.role_name,
                active: user?.active,
            })}
            validationSchema={retrieveUserUpdateSchema()}
            action={ActionTypes.API_USER_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="full_name" 
                        name="full_name"
                        label={t('users.full_name')}
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                        helperText={formik.touched.full_name ? formik.errors.full_name : ""}
                    />

                    <TextFieldStyled 
                        id="password" 
                        name="password"
                        label={t('users.password')}
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password ? formik.errors.password : ""}
                    />

                    <ReadOnlyField
                        id="email"
                        label={t('users.email')}
                        value={formik.values.email}
                    />

                    <SingleSelect 
                        id="role_name" 
                        name="role_name"
                        label={t('users.role_name')}
                        options={roleOptions}
                        value={roleOptions.find(role => role.value === formik.values?.role_name)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.role_name && Boolean(formik.errors.role_name)}
                        formikHelperText={formik.touched.role_name ? formik.errors.role_name : ""}
                    />

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.active,
                            onChange: formik.handleChange,
                            id: "active",
                            name: "active",
                        }}
                        label={t('users.active')}
                    />
                </div>
            }
        />
    );
};

export default UserDetailsDialog;