/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ImageVersionFilterModel,
    ImageVersionFilterModelFromJSON,
    ImageVersionFilterModelFromJSONTyped,
    ImageVersionFilterModelToJSON,
} from './';


export interface ImageVersionRetrieveListModelInterface {
    filters?: ImageVersionFilterModel;
    search_text?: string | null;
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
}

/**
 * 
 * @export
 * @interface ImageVersionRetrieveListModel
 */
export class ImageVersionRetrieveListModel extends BaseModel<ImageVersionRetrieveListModelInterface> {

    /**
     * 
     * @type {ImageVersionFilterModel}
     * @memberof ImageVersionRetrieveListModel
     */
    filters?: ImageVersionFilterModel;
    /**
     * Search String
     * @type {string}
     * @memberof ImageVersionRetrieveListModel
     */
    search_text?: string | null;
    /**
     * Offset
     * @type {number}
     * @memberof ImageVersionRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof ImageVersionRetrieveListModel
     */
    limit?: number;
    /**
     * Sort by Column
     * @type {string}
     * @memberof ImageVersionRetrieveListModel
     */
    sort_by?: string;
    /**
     * Sort direction
     * @type {string}
     * @memberof ImageVersionRetrieveListModel
     */
    sort_direction?: string;

    constructor(data?: ImageVersionRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageVersionRetrieveListModel, value: StringDateInterface<ImageVersionRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageVersionRetrieveListModelFromJSON(json: any): ImageVersionRetrieveListModel {
    return ImageVersionRetrieveListModelFromJSONTyped(json, false);
}

export function ImageVersionRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVersionRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageVersionRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'filters': !exists(json, 'filters') ? undefined : ImageVersionFilterModelFromJSON(json['filters']),
        'search_text': !exists(json, 'search_text') ? undefined : json['search_text'],
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
        'sort_by': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sort_direction': !exists(json, 'sort_direction') ? undefined : json['sort_direction'],
    });
}

export function ImageVersionRetrieveListModelToJSON(value?: ImageVersionRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageVersionRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'filters': ImageVersionFilterModelToJSON(value.filters),
        'search_text': value.search_text,
        'offset': value.offset,
        'limit': value.limit,
        'sort_by': value.sort_by,
        'sort_direction': value.sort_direction,
    } as StringDateInterface<ImageVersionRetrieveListModel>;
    return createClass.toJson(value, model);
}


