import ActionTypes from "config/ActionTypes";
import Property from 'api/override/PropertyModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import PropertyState from "./interface/PropertyState";
import { PropertyListSuccess, PropertyRetrieveSuccess } from "./types/Property";


const initialState: PropertyState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function PropertyReducers(state = initialState, action: BaseReducerAction): PropertyState {
    switch (action.type) {
        case ActionTypes.API_PROPERTY_UPDATE:
        case ActionTypes.API_PROPERTY_RETRIEVE:
        case ActionTypes.API_PROPERTY_LIST:
        case ActionTypes.API_PROPERTY_CREATE:
        case ActionTypes.API_PROPERTY_DELETE:
        case ActionTypes.API_PROPERTY_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_PROPERTY_UPDATE_SUCCESS:
        case ActionTypes.API_PROPERTY_CREATE_SUCCESS:
        case ActionTypes.API_PROPERTY_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_PROPERTY_UPDATE_FAILURE:
        case ActionTypes.API_PROPERTY_CREATE_FAILURE:
        case ActionTypes.API_PROPERTY_DELETE_FAILURE:
        case ActionTypes.API_PROPERTY_LIST_FAILURE:
        case ActionTypes.API_PROPERTY_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_PROPERTY_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as PropertyRetrieveSuccess).params as Property,
                error: undefined
            };

        case ActionTypes.API_PROPERTY_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_PROPERTY_LIST_SUCCESS:
            const result = action as PropertyListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Property>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_PROPERTY_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}