import { ApiSuccessResponseModel, HistoryResponseModel, MailCreateSuccessModel, MailHistoryListRequest, MailListResultModel, MailRetrieveListModel } from "api";
import Mail from "api/override/MailModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface MailList extends BaseReducerAction {
    type: ActionTypes.API_MAIL_LIST;
    params: MailRetrieveListModel;
}

export interface MailListSuccess extends BaseSagaListResponse<MailListResultModel> {
    type: ActionTypes.API_MAIL_LIST_SUCCESS;
}

export interface MailCreate extends BaseReducerAction {
    type: ActionTypes.API_MAIL_CREATE;
    params: Mail;
}

export interface MailCreateSuccess extends BaseSagaListResponse<MailCreateSuccessModel> {
    type: ActionTypes.API_MAIL_CREATE_SUCCESS;
}

export interface MailUpdateParams {
    id: number;
    mailModel: Mail;
}

export interface MailUpdate extends BaseReducerAction {
    type: ActionTypes.API_MAIL_UPDATE;
    params: MailUpdateParams;
}

export interface MailUpdateSuccess extends BaseSagaListResponse<MailCreateSuccessModel> {
    type: ActionTypes.API_MAIL_UPDATE_SUCCESS;
}

export interface MailRetrieve extends BaseReducerAction {
    type: ActionTypes.API_MAIL_RETRIEVE;
    params: number;
}

export interface MailRetrieveSuccess extends BaseSagaListResponse<Mail> {
    type: ActionTypes.API_MAIL_RETRIEVE_SUCCESS
}

export interface MailDelete extends BaseReducerAction {
    type: ActionTypes.API_MAIL_DELETE;
    params: number;
}

export interface MailDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_MAIL_DELETE_SUCCESS;
}

export interface MailHistoryList extends BaseReducerAction {
    type: ActionTypes.API_MAIL_HISTORY_LIST;
    params: MailHistoryListRequest;
}

export interface MailHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_MAIL_HISTORY_LIST_SUCCESS;
}

export interface MailClearListParams {
    force?: boolean;
}
export interface MailClearList extends BaseReducerAction {
    type: ActionTypes.API_MAIL_LIST_CLEAR;
    params?: MailClearListParams;
}

export default class MailActions {
    
    static CreateMailList(params: MailRetrieveListModel): MailList {
        return {
            type: ActionTypes.API_MAIL_LIST,
            params: params
        }
    }

    static CreateMailListSuccess(params: MailListResultModel): MailListSuccess {
        return {
            type: ActionTypes.API_MAIL_LIST_SUCCESS,
            params: params
        }
    }

    static CreateMailCreate(params: Mail): MailCreate {
        return {
            type: ActionTypes.API_MAIL_CREATE,
            params: params
        }
    }

    static CreateMailCreateSuccess(params: MailCreateSuccessModel): MailCreateSuccess {
        return {
            type: ActionTypes.API_MAIL_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateMailUpdate(params: Mail): MailUpdate {
        return {
            type: ActionTypes.API_MAIL_UPDATE,
            params: {
                id: params.id,
                mailModel: params
            }
        }
    }

    static CreateMailUpdateSuccess(params: MailCreateSuccessModel): MailUpdateSuccess {
        return {
            type: ActionTypes.API_MAIL_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateMailRetrieve(params: number): MailRetrieve {
        return {
            type: ActionTypes.API_MAIL_RETRIEVE,
            params: params
        }
    }

    static CreateMailRetrieveSuccess(params: Mail): MailRetrieveSuccess {
        return {
            type: ActionTypes.API_MAIL_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateMailDelete(params: number): MailDelete {
        return {
            type: ActionTypes.API_MAIL_DELETE,
            params: params
        }
    }

    static CreateMailDeleteSuccess(params: ApiSuccessResponseModel): MailDeleteSuccess {
        return {
            type: ActionTypes.API_MAIL_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateMailHistoryList(params: MailHistoryListRequest): MailHistoryList {
        return {
            type: ActionTypes.API_MAIL_HISTORY_LIST,
            params: params
        }
    }

    static CreateMailHistoryListSuccess(params: HistoryResponseModel): MailHistoryListSuccess {
        return {
            type: ActionTypes.API_MAIL_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearMailList(force: boolean = false): MailClearList {
        return {
            type: ActionTypes.API_MAIL_LIST_CLEAR,
            params: {force: force}
        }   
    }

}