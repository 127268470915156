import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ImageVersionApi, ImageVersionCreateRequest, ImageVersionDeleteRequest, ImageVersionHistoryListRequest, ImageVersionListRequest, ImageVersionListResultModel, ImageVersionRetrieveRequest, ImageVersionUpdateRequest } from "api";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';

import ImageVersionActions, { ImageVersionCreate, ImageVersionDelete, ImageVersionHistoryList, ImageVersionList, ImageVersionRetrieve, ImageVersionUpdate } from 'reducers/types/ImageVersion';
import { TableParams } from 'models/table/TableParams';

export function* ImageVersionSagas() {
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_LIST, ImageVersionSaga.imageVersionListWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_RETRIEVE, ImageVersionSaga.imageVersionRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_CREATE, ImageVersionSaga.imageVersionCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_UPDATE, ImageVersionSaga.imageVersionUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_DELETE, ImageVersionSaga.imageVersionDeleteWorkerSaga)
    yield takeLatest(ActionTypes.API_IMAGE_VERSION_HISTORY_LIST, ImageVersionSaga.imageVersionHistoryListWorkerSaga)
}

export class ImageVersionSaga {
    static *imageVersionListWorkerSaga(action: ImageVersionList) {
        try {
            const imageVersionArgs = {
                imageVersionRetrieveListModel: action.params
            } as ImageVersionListRequest
            const data = yield ApiHandler(new ImageVersionApi().imageVersionList, imageVersionArgs);
    
            yield put(ImageVersionActions.CreateImageVersionListSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_VERSION_LIST_FAILURE, error as Error))
        }
    }
    
    static *imageVersionRetrieveWorkerSaga(action: ImageVersionRetrieve) {
        try {
            const imageVersionArgs = {
                id: action.params
            } as ImageVersionRetrieveRequest
            const data = yield ApiHandler(new ImageVersionApi().imageVersionRetrieve, imageVersionArgs);
    
            yield put(ImageVersionActions.CreateImageVersionRetrieveSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_VERSION_RETRIEVE_FAILURE, error as Error))
        }
    }
    
    static *imageVersionCreateWorkerSaga(action: ImageVersionCreate) {
        try {
            const imageVersionArgs = {
                imageVersionModel: action.params
            } as ImageVersionCreateRequest
            const data = yield ApiHandler(new ImageVersionApi().imageVersionCreate, imageVersionArgs);
    
            yield put(ImageVersionActions.CreateImageVersionCreateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_VERSION_CREATE_FAILURE, error as Error))
        }
    }
    
    static *imageVersionUpdateWorkerSaga(action: ImageVersionUpdate) {
        try {
            const imageVersionArgs = {
                id: action.params?.id,
                imageVersionModel: action.params?.imageVersionModel
            } as ImageVersionUpdateRequest
            const data = yield ApiHandler(new ImageVersionApi().imageVersionUpdate, imageVersionArgs);
    
            yield put(ImageVersionActions.CreateImageVersionUpdateSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_VERSION_UPDATE_FAILURE, error as Error))
        }
    }
    
    static *imageVersionDeleteWorkerSaga(action: ImageVersionDelete) {
        try {
            const imageVersionArgs = {
                id: action.params
            } as ImageVersionDeleteRequest
            const data = yield ApiHandler(new ImageVersionApi().imageVersionDelete, imageVersionArgs);
    
            yield put(ImageVersionActions.CreateImageVersionDeleteSuccess(data));
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_VERSION_DELETE_FAILURE, error as Error))
        }
    }
    
    static *imageVersionHistoryListWorkerSaga(action: ImageVersionHistoryList) {
        try {
            const imageVersionArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ImageVersionHistoryListRequest
    
            const data = yield ApiHandler(new ImageVersionApi().imageVersionHistoryList, imageVersionArgs);
            yield put(ImageVersionActions.CreateImageVersionHistoryListSuccess(data))
        } catch(error){
            yield put(AppActions.CreateError(ActionTypes.API_IMAGE_HISTORY_LIST_FAILURE, error as Error))
        }
    }
    
    static async imageVersionList (imageId: number, tableParams?: TableParams): Promise<ImageVersionListResultModel> {
        const retrieveListRequest = {
            imageVersionRetrieveListModel: {
                ...tableParams.getApiFilter(),
                filters: {
                    image_id: imageId
                }
            }
        } as ImageVersionListRequest;
        return await new ImageVersionApi().imageVersionList(retrieveListRequest);
    }
    
}

