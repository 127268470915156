/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    InstanceSettingsValueOptionModel,
    InstanceSettingsValueOptionModelFromJSON,
    InstanceSettingsValueOptionModelFromJSONTyped,
    InstanceSettingsValueOptionModelToJSON,
    InstanceUserModel,
    InstanceUserModelFromJSON,
    InstanceUserModelFromJSONTyped,
    InstanceUserModelToJSON,
} from './';


export interface InstanceSettingModelInterface {
    id?: number;
    settings_group_id?: InstanceSettingModelSettingsGroupIdEnum;
    settings_group_name?: InstanceSettingModelSettingsGroupNameEnum;
    key?: string;
    value?: string;
    value_options?: Array<InstanceSettingsValueOptionModel>;
    obscured?: boolean;
    setting_updated_at?: Date;
    setting_updated_by?: number;
    setting_updated_by_user?: InstanceUserModel;
}

/**
 * 
 * @export
 * @interface InstanceSettingModel
 */
export class InstanceSettingModel extends BaseModel<InstanceSettingModelInterface> {

    /**
     * 
     * @type {number}
     * @memberof InstanceSettingModel
     */
    id?: number;
    /**
     * SettingsGroup id of this setting. Possible values: 1: general, 2: address, 3: package, 4: activity, 5: equipment, 6: note, 7: optimisation, 8: filters
     * @type {number}
     * @memberof InstanceSettingModel
     */
    settings_group_id?: InstanceSettingModelSettingsGroupIdEnum;
    /**
     * SettingsGroup name of this setting
     * @type {string}
     * @memberof InstanceSettingModel
     */
    settings_group_name?: InstanceSettingModelSettingsGroupNameEnum;
    /**
     * Unique string key for setting identification
     * @type {string}
     * @memberof InstanceSettingModel
     */
    key?: string;
    /**
     * Set value for setting
     * @type {string}
     * @memberof InstanceSettingModel
     */
    value?: string;
    /**
     * 
     * @type {Array<InstanceSettingsValueOptionModel>}
     * @memberof InstanceSettingModel
     */
    value_options?: Array<InstanceSettingsValueOptionModel>;
    /**
     * If the return value is obscured because it is sensitive data
     * @type {boolean}
     * @memberof InstanceSettingModel
     */
    obscured?: boolean;
    /**
     * updated_at date time
     * @type {Date}
     * @memberof InstanceSettingModel
     */
    setting_updated_at?: Date;
    /**
     * updated_by user id
     * @type {number}
     * @memberof InstanceSettingModel
     */
    setting_updated_by?: number;
    /**
     * 
     * @type {InstanceUserModel}
     * @memberof InstanceSettingModel
     */
    setting_updated_by_user?: InstanceUserModel;

    constructor(data?: InstanceSettingModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSettingModel, value: StringDateInterface<InstanceSettingModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSettingModelFromJSON(json: any): InstanceSettingModel {
    return InstanceSettingModelFromJSONTyped(json, false);
}

export function InstanceSettingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSettingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSettingModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'settings_group_id': !exists(json, 'settings_group_id') ? undefined : Number(json['settings_group_id']),
        'settings_group_name': !exists(json, 'settings_group_name') ? undefined : json['settings_group_name'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'value_options': !exists(json, 'value_options') ? undefined : ((json['value_options'] as Array<any>).map(InstanceSettingsValueOptionModelFromJSON)),
        'obscured': !exists(json, 'obscured') ? undefined : json['obscured'],
        'setting_updated_at': !exists(json, 'setting_updated_at') ? undefined : (new Date(json['setting_updated_at'].replace(' ', 'T'))),
        'setting_updated_by': !exists(json, 'setting_updated_by') ? undefined : Number(json['setting_updated_by']),
        'setting_updated_by_user': !exists(json, 'setting_updated_by_user') ? undefined : InstanceUserModelFromJSON(json['setting_updated_by_user']),
    });
}

export function InstanceSettingModelToJSON(value?: InstanceSettingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSettingModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'settings_group_id': value.settings_group_id,
        'settings_group_name': value.settings_group_name,
        'key': value.key,
        'value': value.value,
        'value_options': value.value_options === undefined ? undefined : ((value.value_options as Array<any>).map(InstanceSettingsValueOptionModelToJSON)),
        'obscured': value.obscured,
        'setting_updated_at': value.setting_updated_at === undefined ? undefined : (value.setting_updated_at.toApiFormat()),
        'setting_updated_by': value.setting_updated_by,
        'setting_updated_by_user': InstanceUserModelToJSON(value.setting_updated_by_user),
    } as StringDateInterface<InstanceSettingModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum InstanceSettingModelSettingsGroupIdEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8
}
/**
* @export
* @enum {string}
*/
export enum InstanceSettingModelSettingsGroupNameEnum {
    General = 'general',
    Address = 'address',
    Package = 'package',
    Activity = 'activity',
    Equipment = 'equipment',
    Note = 'note',
    Optimisation = 'optimisation',
    Filters = 'filters'
}


