/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    InstanceAdminUpdate,
    InstanceAdminUpdateFromJSON,
    InstanceAdminUpdateToJSON,
    InstanceConnectorListResultModel,
    InstanceConnectorListResultModelFromJSON,
    InstanceConnectorListResultModelToJSON,
    InstanceConnectorRetrieveListModel,
    InstanceConnectorRetrieveListModelFromJSON,
    InstanceConnectorRetrieveListModelToJSON,
    InstanceCreateModel,
    InstanceCreateModelFromJSON,
    InstanceCreateModelToJSON,
    InstanceCreateSuccessModel,
    InstanceCreateSuccessModelFromJSON,
    InstanceCreateSuccessModelToJSON,
    InstanceListResultModel,
    InstanceListResultModelFromJSON,
    InstanceListResultModelToJSON,
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelToJSON,
    InstanceRemoveSuccessModel,
    InstanceRemoveSuccessModelFromJSON,
    InstanceRemoveSuccessModelToJSON,
    InstanceRetrieveListModel,
    InstanceRetrieveListModelFromJSON,
    InstanceRetrieveListModelToJSON,
    InstanceSetImageVersionModel,
    InstanceSetImageVersionModelFromJSON,
    InstanceSetImageVersionModelToJSON,
    InstanceSettingsListResultModel,
    InstanceSettingsListResultModelFromJSON,
    InstanceSettingsListResultModelToJSON,
    InstanceSettingsPostModel,
    InstanceSettingsPostModelFromJSON,
    InstanceSettingsPostModelToJSON,
    InstanceUsersListResultModel,
    InstanceUsersListResultModelFromJSON,
    InstanceUsersListResultModelToJSON,
    ProcessListResultModel,
    ProcessListResultModelFromJSON,
    ProcessListResultModelToJSON,
    ServiceContainerListResultModel,
    ServiceContainerListResultModelFromJSON,
    ServiceContainerListResultModelToJSON,
    ServiceContainerRetrieveListModel,
    ServiceContainerRetrieveListModelFromJSON,
    ServiceContainerRetrieveListModelToJSON,
} from '../models';

export interface InstanceAdminUpdateRequest {
    uuid: string;
    instanceAdminUpdate: InstanceAdminUpdate;
}

export interface InstanceConnectorListRequest {
    uuid: string;
    instanceConnectorRetrieveListModel: InstanceConnectorRetrieveListModel;
}

export interface InstanceCreateRequest {
    instanceCreateModel: InstanceCreateModel;
    skipMigration?: boolean;
}

export interface InstanceDeleteRequest {
    uuid: string;
    deletionReason: string;
}

export interface InstanceHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface InstanceListRequest {
    instanceRetrieveListModel: InstanceRetrieveListModel;
}

export interface InstanceLogsRetrieveRequest {
    uuid: string;
    limit: number;
    offset: number;
    deleted?: boolean;
    sortBy?: string;
    sortDir?: string;
    searchText?: string;
}

export interface InstanceRetrieveRequest {
    uuid: string;
    deleted?: boolean;
}

export interface InstanceServiceListRequest {
    uuid: string;
    serviceContainerRetrieveListModel: ServiceContainerRetrieveListModel;
}

export interface InstanceSetImageVersionRequest {
    uuid: string;
    instanceSetImageVersionModel: InstanceSetImageVersionModel;
}

export interface InstanceSettingsRetrieveRequest {
    uuid: string;
}

export interface InstanceSettingsUpdateRequest {
    uuid: string;
    instanceSettingsPostModel: InstanceSettingsPostModel;
}

export interface InstanceTasksRequest {
    uuid: string;
    task: string;
    deleted?: boolean;
    migrate?: boolean;
    uuids?: Array<string>;
}

export interface InstanceToggleMaintenanceRequest {
    uuid: string;
}

export interface InstanceUnlockRequest {
    uuid: string;
}

export interface InstanceUpdateRequest {
    uuid: string;
    instanceModel: InstanceModel;
    deleted?: boolean;
}

export interface InstanceUsersRetrieveRequest {
    uuid: string;
}

/**
 * 
 */
export class InstanceApi extends runtime.BaseAPI {

    /**
     * Update the admin password for an instance
     * Update the admin password for an instance
     */
    async instanceAdminUpdateRaw(requestParameters: InstanceAdminUpdateRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceAdminUpdate.');
        }

        if (requestParameters.instanceAdminUpdate === null || requestParameters.instanceAdminUpdate === undefined) {
            throw new runtime.RequiredError('instanceAdminUpdate','Required parameter requestParameters.instanceAdminUpdate was null or undefined when calling instanceAdminUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/update-admin`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceAdminUpdateToJSON(requestParameters.instanceAdminUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Update the admin password for an instance
     * Update the admin password for an instance
     */
    instanceAdminUpdate = async(requestParameters: InstanceAdminUpdateRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.instanceAdminUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all connectors of an instance
     * List all connectors of an instance
     */
    async instanceConnectorListRaw(requestParameters: InstanceConnectorListRequest): Promise<runtime.ApiResponse<InstanceConnectorListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceConnectorList.');
        }

        if (requestParameters.instanceConnectorRetrieveListModel === null || requestParameters.instanceConnectorRetrieveListModel === undefined) {
            throw new runtime.RequiredError('instanceConnectorRetrieveListModel','Required parameter requestParameters.instanceConnectorRetrieveListModel was null or undefined when calling instanceConnectorList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/connectors`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceConnectorRetrieveListModelToJSON(requestParameters.instanceConnectorRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceConnectorListResultModelFromJSON(jsonValue));
    }

    /**
     * List all connectors of an instance
     * List all connectors of an instance
     */
    instanceConnectorList = async(requestParameters: InstanceConnectorListRequest): Promise<InstanceConnectorListResultModel> => {
        const response = await this.instanceConnectorListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new Instance
     * Create a new Instance
     */
    async instanceCreateRaw(requestParameters: InstanceCreateRequest): Promise<runtime.ApiResponse<InstanceCreateSuccessModel>> {
        if (requestParameters.instanceCreateModel === null || requestParameters.instanceCreateModel === undefined) {
            throw new runtime.RequiredError('instanceCreateModel','Required parameter requestParameters.instanceCreateModel was null or undefined when calling instanceCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.skipMigration !== undefined) {
            queryParameters['skip_migration'] = requestParameters.skipMigration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceCreateModelToJSON(requestParameters.instanceCreateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new Instance
     * Create a new Instance
     */
    instanceCreate = async(requestParameters: InstanceCreateRequest): Promise<InstanceCreateSuccessModel> => {
        const response = await this.instanceCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an Instance, given its UUID
     * Delete an Instance
     */
    async instanceDeleteRaw(requestParameters: InstanceDeleteRequest): Promise<runtime.ApiResponse<InstanceRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceDelete.');
        }

        if (requestParameters.deletionReason === null || requestParameters.deletionReason === undefined) {
            throw new runtime.RequiredError('deletionReason','Required parameter requestParameters.deletionReason was null or undefined when calling instanceDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deletionReason !== undefined) {
            queryParameters['deletion_reason'] = requestParameters.deletionReason;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an Instance, given its UUID
     * Delete an Instance
     */
    instanceDelete = async(requestParameters: InstanceDeleteRequest): Promise<InstanceRemoveSuccessModel> => {
        const response = await this.instanceDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an instance
     * retrieve a list of historical logs for an instance
     */
    async instanceHistoryListRaw(requestParameters: InstanceHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling instanceHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an instance
     * retrieve a list of historical logs for an instance
     */
    instanceHistoryList = async(requestParameters: InstanceHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.instanceHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List instances with filters
     * List instances
     */
    async instanceListRaw(requestParameters: InstanceListRequest): Promise<runtime.ApiResponse<InstanceListResultModel>> {
        if (requestParameters.instanceRetrieveListModel === null || requestParameters.instanceRetrieveListModel === undefined) {
            throw new runtime.RequiredError('instanceRetrieveListModel','Required parameter requestParameters.instanceRetrieveListModel was null or undefined when calling instanceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceRetrieveListModelToJSON(requestParameters.instanceRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceListResultModelFromJSON(jsonValue));
    }

    /**
     * List instances with filters
     * List instances
     */
    instanceList = async(requestParameters: InstanceListRequest): Promise<InstanceListResultModel> => {
        const response = await this.instanceListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve instance logs
     * Retrieve instance logs
     */
    async instanceLogsRetrieveRaw(requestParameters: InstanceLogsRetrieveRequest): Promise<runtime.ApiResponse<ProcessListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceLogsRetrieve.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling instanceLogsRetrieve.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling instanceLogsRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDir !== undefined) {
            queryParameters['sort_dir'] = requestParameters.sortDir;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/logs`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessListResultModelFromJSON(jsonValue));
    }

    /**
     * Retrieve instance logs
     * Retrieve instance logs
     */
    instanceLogsRetrieve = async(requestParameters: InstanceLogsRetrieveRequest): Promise<ProcessListResultModel> => {
        const response = await this.instanceLogsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Redeploy all instances\' backends
     * Redeploy all instances\' backends
     */
    async instanceRedeployBackendRaw(): Promise<runtime.ApiResponse<ApiResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/redeploy/backend`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Redeploy all instances\' backends
     * Redeploy all instances\' backends
     */
    instanceRedeployBackend = async(): Promise<ApiResponseModel> => {
        const response = await this.instanceRedeployBackendRaw();
        return await response.value();
    }

    /**
     * Redeploy all instances\' frontends
     * Redeploy all instances\' frontends
     */
    async instanceRedeployFrontendRaw(): Promise<runtime.ApiResponse<ApiResponseModel>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/redeploy/frontend`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Redeploy all instances\' frontends
     * Redeploy all instances\' frontends
     */
    instanceRedeployFrontend = async(): Promise<ApiResponseModel> => {
        const response = await this.instanceRedeployFrontendRaw();
        return await response.value();
    }

    /**
     * Retrieve an instance
     * Retrieve an instance
     */
    async instanceRetrieveRaw(requestParameters: InstanceRetrieveRequest): Promise<runtime.ApiResponse<InstanceModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an instance
     * Retrieve an instance
     */
    instanceRetrieve = async(requestParameters: InstanceRetrieveRequest): Promise<InstanceModel> => {
        const response = await this.instanceRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all services of an instance
     * List all services of an instance
     */
    async instanceServiceListRaw(requestParameters: InstanceServiceListRequest): Promise<runtime.ApiResponse<ServiceContainerListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceServiceList.');
        }

        if (requestParameters.serviceContainerRetrieveListModel === null || requestParameters.serviceContainerRetrieveListModel === undefined) {
            throw new runtime.RequiredError('serviceContainerRetrieveListModel','Required parameter requestParameters.serviceContainerRetrieveListModel was null or undefined when calling instanceServiceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/services`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceContainerRetrieveListModelToJSON(requestParameters.serviceContainerRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerListResultModelFromJSON(jsonValue));
    }

    /**
     * List all services of an instance
     * List all services of an instance
     */
    instanceServiceList = async(requestParameters: InstanceServiceListRequest): Promise<ServiceContainerListResultModel> => {
        const response = await this.instanceServiceListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set the image version for an instance
     * Set the image version for an instance
     */
    async instanceSetImageVersionRaw(requestParameters: InstanceSetImageVersionRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceSetImageVersion.');
        }

        if (requestParameters.instanceSetImageVersionModel === null || requestParameters.instanceSetImageVersionModel === undefined) {
            throw new runtime.RequiredError('instanceSetImageVersionModel','Required parameter requestParameters.instanceSetImageVersionModel was null or undefined when calling instanceSetImageVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/set-image-version`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceSetImageVersionModelToJSON(requestParameters.instanceSetImageVersionModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Set the image version for an instance
     * Set the image version for an instance
     */
    instanceSetImageVersion = async(requestParameters: InstanceSetImageVersionRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.instanceSetImageVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an instance\'s settings
     * Retrieve an instance\'s settings
     */
    async instanceSettingsRetrieveRaw(requestParameters: InstanceSettingsRetrieveRequest): Promise<runtime.ApiResponse<InstanceSettingsListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceSettingsRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/settings`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceSettingsListResultModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an instance\'s settings
     * Retrieve an instance\'s settings
     */
    instanceSettingsRetrieve = async(requestParameters: InstanceSettingsRetrieveRequest): Promise<InstanceSettingsListResultModel> => {
        const response = await this.instanceSettingsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update settings for an instance
     * Update settings for an instance
     */
    async instanceSettingsUpdateRaw(requestParameters: InstanceSettingsUpdateRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceSettingsUpdate.');
        }

        if (requestParameters.instanceSettingsPostModel === null || requestParameters.instanceSettingsPostModel === undefined) {
            throw new runtime.RequiredError('instanceSettingsPostModel','Required parameter requestParameters.instanceSettingsPostModel was null or undefined when calling instanceSettingsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/settings`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceSettingsPostModelToJSON(requestParameters.instanceSettingsPostModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Update settings for an instance
     * Update settings for an instance
     */
    instanceSettingsUpdate = async(requestParameters: InstanceSettingsUpdateRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.instanceSettingsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule tasks for an instance
     * Schedule tasks for an instance
     */
    async instanceTasksRaw(requestParameters: InstanceTasksRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceTasks.');
        }

        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling instanceTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.task !== undefined) {
            queryParameters['task'] = requestParameters.task;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.migrate !== undefined) {
            queryParameters['migrate'] = requestParameters.migrate;
        }

        if (requestParameters.uuids) {
            queryParameters['uuids[]'] = requestParameters.uuids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/task`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks for an instance
     * Schedule tasks for an instance
     */
    instanceTasks = async(requestParameters: InstanceTasksRequest): Promise<ApiResponseModel> => {
        const response = await this.instanceTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Toggle maintenance for an instance
     * Toggle maintenance for an instance
     */
    async instanceToggleMaintenanceRaw(requestParameters: InstanceToggleMaintenanceRequest): Promise<runtime.ApiResponse<InstanceRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceToggleMaintenance.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/toggle-maintenance`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Toggle maintenance for an instance
     * Toggle maintenance for an instance
     */
    instanceToggleMaintenance = async(requestParameters: InstanceToggleMaintenanceRequest): Promise<InstanceRemoveSuccessModel> => {
        const response = await this.instanceToggleMaintenanceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock instance
     * Unlock instance
     */
    async instanceUnlockRaw(requestParameters: InstanceUnlockRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/unlock`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Unlock instance
     * Unlock instance
     */
    instanceUnlock = async(requestParameters: InstanceUnlockRequest): Promise<ApiResponseModel> => {
        const response = await this.instanceUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an instance
     * Update an instance
     */
    async instanceUpdateRaw(requestParameters: InstanceUpdateRequest): Promise<runtime.ApiResponse<InstanceCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceUpdate.');
        }

        if (requestParameters.instanceModel === null || requestParameters.instanceModel === undefined) {
            throw new runtime.RequiredError('instanceModel','Required parameter requestParameters.instanceModel was null or undefined when calling instanceUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceModelToJSON(requestParameters.instanceModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an instance
     * Update an instance
     */
    instanceUpdate = async(requestParameters: InstanceUpdateRequest): Promise<InstanceCreateSuccessModel> => {
        const response = await this.instanceUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve an instance\'s users
     * Retrieve an instance\'s users
     */
    async instanceUsersRetrieveRaw(requestParameters: InstanceUsersRetrieveRequest): Promise<runtime.ApiResponse<InstanceUsersListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling instanceUsersRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/instance/{uuid}/users`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceUsersListResultModelFromJSON(jsonValue));
    }

    /**
     * Retrieve an instance\'s users
     * Retrieve an instance\'s users
     */
    instanceUsersRetrieve = async(requestParameters: InstanceUsersRetrieveRequest): Promise<InstanceUsersListResultModel> => {
        const response = await this.instanceUsersRetrieveRaw(requestParameters);
        return await response.value();
    }

}
