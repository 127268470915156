import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { PackageModel } from 'api';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface PackageDetailsProps {
    pk: PackageModel;
    onDelete?: () => void;
    onEdit?: () => void;
}

const PackageDetails = (props: PackageDetailsProps) => {
    const { t } = useTranslation();
    const { pk } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('packages.model')}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
            >
            </BaseDetailsViewActions>
            <DetailsField propKey={t('general.name')} propVal={pk.name} />
            <DetailsField propKey={t('general.description')} propVal={pk.description} />
            <DetailsField propKey={t('package.price')} propVal={pk.price} />
            <DetailsField propKey={t('service.model_plural')} arrayVal={pk.services} arrayProp='name' />
        </div>
    );
};

export default PackageDetails;