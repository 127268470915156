import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Client from 'api/override/ClientModel';
import { FormikProps } from 'formik';
import { PropertyModelTypeEnum } from 'api';
import PasswordTextField from 'components/form_controls/PasswordTextField';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import useDialogStyles from 'styles/DialogStyles';
import Property from 'api/override/PropertyModel';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface ClientFormProps {
    formik: FormikProps<Client>;
    isCreate?: boolean;
}

const ClientForm = (props: ClientFormProps) => {
    const { t } = useTranslation();
    const { formik, isCreate } = props;
    const {classes} = useDialogStyles();
    const [properties, setProperties] = useState<Array<Property>>([])

    const getInput = (name: string, index: number): JSX.Element => {
        const propertyType: string = properties.find((prop: Property) => prop.name === name)?.type // find the type of the property through its name
        const inputKey: string = `properties_${name}_${index}]`
        const inputName: string = `properties[${name}]`
        let defaultType, defaultPlaceholder: string;

        switch (propertyType) {
            case PropertyModelTypeEnum.Checkbox:
                return (
                    <BBCheckbox
                        key={inputKey}
                        checkboxProps={{
                            checked: formik.values.properties?.[`${name}`],
                            onChange: formik.handleChange,
                            id: inputName,
                            name: inputName
                        }}
                        label={name}
                    />
                )
            case PropertyModelTypeEnum.Date:
                defaultPlaceholder = t('date-format')
                break;
            case PropertyModelTypeEnum.Time:
                defaultPlaceholder = t('time-format')
                break;
            case PropertyModelTypeEnum.DatetimeLocal:
                defaultPlaceholder = t('datetime-format')
                break;
            case PropertyModelTypeEnum.Email:
                defaultType = 'email'
                break;
            case PropertyModelTypeEnum.Number:
                defaultType = 'number'
                break;
            case PropertyModelTypeEnum.Tel:
                defaultType = 'tel'
                break;
            case PropertyModelTypeEnum.Password:
                return (
                    <PasswordTextField 
                        key={inputKey}
                        id={inputName} 
                        name={inputName}
                        label={name}
                        helperText={formik.touched.properties?.[`${name}`] ? formik.errors.properties?.[`${name}`] : ""}
                        error={formik.touched.properties?.[`${name}`] && Boolean(formik.errors.properties?.[`${name}`])}
                        value={formik.values.properties?.[`${name}`] ?? ''}
                        onChange={formik.handleChange}
                    />
                )
            case PropertyModelTypeEnum.Radio:
                break;
            case PropertyModelTypeEnum.Url:
                defaultType = 'url'
                break;
            default:
                defaultType = 'text';
                defaultPlaceholder = '';
                break;
        }

        const shouldShrink: boolean = defaultPlaceholder !== ''
        return (
            <TextFieldStyled 
                key={inputKey}
                id={inputName} 
                name={inputName}
                placeholder={defaultPlaceholder}
                label={name}
                type={defaultType}
                helperText={formik.touched?.properties?.[`${name}`] ? formik.errors?.properties?.[`${name}`] : ""}
                error={formik.touched?.properties?.[`${name}`] && Boolean(formik.errors?.properties?.[`${name}`])}
                value={formik.values.properties?.[`${name}`] ?? ''}
                onChange={formik.handleChange}
                InputLabelProps={shouldShrink ? { shrink: true } : {}}
            />
        )
    }

    const componentDidMount = () => {
        Property.getClientProperties().then(assignProperties);
    }
    const assignProperties = (properties: Array<Property>) => {
        setProperties(properties)
        formik.setFieldValue('properties', formik.values.prepareProperties(properties).properties);
    }
    // eslint-disable-next-line
    useEffect(componentDidMount, []);

    return (
        <div className={classes.fields_container}>
            
            <TextFieldStyled
                shouldShowNameHelperText
                id="name" 
                name="name"
                label={t('general.name')}
                helperText={formik.touched.name ? formik.errors.name : ""}
                error={formik.touched.name && Boolean(formik.errors.name)}
                value={formik.values.name}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="company_name" 
                name="company_name"
                label={t('clients.company_name')}
                helperText={formik.touched.company_name ? formik.errors.company_name : ""}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                value={formik.values.company_name}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="city" 
                name="city"
                label={t('clients.city')}
                helperText={formik.touched.city ? formik.errors.city : ""}
                error={formik.touched.city && Boolean(formik.errors.city)}
                value={formik.values.city}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="address" 
                name="address"
                label={t('clients.address')}
                helperText={formik.touched.address ? formik.errors.address : ""}
                error={formik.touched.address && Boolean(formik.errors.address)}
                value={formik.values.address}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="email" 
                name="email"
                label={t('clients.email')}
                helperText={formik.touched.email ? formik.errors.email : ""}
                error={formik.touched.email && Boolean(formik.errors.email)}
                value={formik.values.email}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="phone" 
                name="phone"
                label={t('clients.phone')}
                helperText={formik.touched.phone ? formik.errors.phone : ""}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                value={formik.values.phone}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="website" 
                name="website"
                label={t('clients.website')}
                helperText={formik.touched.website ? formik.errors.website : ""}
                error={formik.touched.website && Boolean(formik.errors.website)}
                value={formik.values.website}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="industry" 
                name="industry"
                label={t('clients.industry')}
                helperText={formik.touched.industry ? formik.errors.industry : ""}
                error={formik.touched.industry && Boolean(formik.errors.industry)}
                value={formik.values.industry}
                onChange={formik.handleChange}
            />

            {isCreate === true &&
                <BBCheckbox
                    checkboxProps={{
                        checked: formik.values.request === 'backoffice',
                        onChange: (e) => {
                            if(formik.values.request === 'backoffice') {
                                formik.setFieldValue('request', 'open')
                            } else {
                                formik.setFieldValue('request', 'backoffice')
                            }
                        },
                        id: 'request',
                        name: 'request'
                    }}
                    label={t('clients.skip_approval')}
                />
            }

            {Object.keys(formik.values.properties).map( getInput )}

        </div>

    );
};

export default React.memo(ClientForm);