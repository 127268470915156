/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface UserModelInterface {
    uuid?: string;
    email?: string | null;
    password?: string | null;
    role_name?: UserModelRoleNameEnum;
    full_name?: string;
    active?: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date | null;
}

/**
 * 
 * @export
 * @interface UserModel
 */
export class UserModel extends BaseModel<UserModelInterface> {

    /**
     * Unique per user
     * @type {string}
     * @memberof UserModel
     */
    uuid?: string;
    /**
     * email address
     * @type {string}
     * @memberof UserModel
     */
    email?: string | null;
    /**
     * write-only password
     * @type {string}
     * @memberof UserModel
     */
    password?: string | null;
    /**
     * Name of this user's role
     * @type {string}
     * @memberof UserModel
     */
    role_name?: UserModelRoleNameEnum;
    /**
     * User's full name
     * @type {string}
     * @memberof UserModel
     */
    full_name?: string;
    /**
     * Determines id user account is active
     * @type {boolean}
     * @memberof UserModel
     */
    active?: boolean;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof UserModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof UserModel
     */
    updated_at?: Date;
    /**
     * UTC date time string, which can be null
     * @type {Date}
     * @memberof UserModel
     */
    deleted_at?: Date | null;

    constructor(data?: UserModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: UserModel, value: StringDateInterface<UserModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function UserModelFromJSON(json: any): UserModel {
    return UserModelFromJSONTyped(json, false);
}

export function UserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserModel;
    try {
        let localClass = require.context('../override', true)('./UserModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role_name': !exists(json, 'role_name') ? undefined : json['role_name'],
        'full_name': !exists(json, 'full_name') ? undefined : json['full_name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function UserModelToJSON(value?: UserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserModel;
    try {
        let localClass = require.context('../override', true)('./UserModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'email': value.email,
        'password': value.password,
        'role_name': value.role_name,
        'full_name': value.full_name,
        'active': value.active,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at === null ? null : value.deleted_at.toApiFormat()),
    } as StringDateInterface<UserModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum UserModelRoleNameEnum {
    Admin = 'admin',
    AccountManager = 'account_manager',
    Helpdesk = 'helpdesk'
}


