import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import { Container, Card, CardContent, Grid } from "@mui/material";
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import { tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import FilterColumn from 'models/table/FilterColumn';
import { ConnectorRetrieveListModel } from 'api';
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import ConnectorActions, { ConnectorTriggerJobParams } from 'reducers/types/Connector';
import AppState from 'reducers/interface/AppState';
import CustomActionButton, { CustomLinkIconButton } from 'models/table/CustomActionButton';
import { Details } from 'mdi-material-ui';
import { ConnectorTableMenuButton } from 'views/Instances/components/ConnectorMenu';
import StorageIcon from '@mui/icons-material/Storage';
import ReloadDataButton from 'components/ReloadDataButton';
import Connector from 'api/override/ConnectorModel';
import DispatchTableViewComponent, { DispatchTableViewState } from 'components/DispatchTableViewComponent';
import Tasks from 'config/Tasks';
import { Connection } from 'mdi-material-ui';
import TableHeader from 'components/TableHeader';
import NotificationCenter from 'services/NotificationCenter';
import { BaseAction } from 'reducers/interface/ReducerAction';
import { withStyles } from 'tss-react/mui';
import ConnectorState from 'reducers/interface/ConnectorState';

interface StateProps {
    connectors: ConnectorState;
    appReducer: AppState;
}

interface DispatchProps {
    listConnectors: (params: ConnectorRetrieveListModel) => void;
    triggerTasks: (params: ConnectorTriggerJobParams) => void;
    clearConnectors: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends DispatchTableViewState {
    connector?: Connector;
}

class ConnectorList extends DispatchTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customButtons: CustomActionButton[];

    constructor(props) {
        super(props);

        this.state = {
            selected: []
        }

        this.customButtons = [
            new CustomLinkIconButton('connectorInstancesLink', i18next.t('connector.connector_instances'), 'redir-connector-instances', (name?: string, row?: Connector) => {
                this.onInstances(row)
            }, StorageIcon, '/connectors/', 'uuid', '/instances'),
            new CustomLinkIconButton('detailsLink', i18next.t('global.button.details'), 'redir-connector', null, Details, '/connectors/', 'uuid'),
            new ConnectorTableMenuButton()
        ]

        this.setFailuresToListenTo([
            ActionTypes.API_CONNECTOR_TRIGGER_JOB_FAILURE,
            ActionTypes.API_CONNECTOR_LIST_FAILURE
        ]);
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('connector.name') }),
            new TableColumn({ key: 'image', isSortable: true, label: i18next.t('connector.image'), customValue: (row: Connector) => {return row?.image_name}}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateTabTitle(i18next.t('connector.model_plural'))
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.NODE_CONNECTOR_DELETE,
            (action: BaseAction) => {
                this.updateListOnDeleteProcessFinished(action)
            }
        );
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.connectors.collection.items.length ?? 0
    }

    reloadData = () => {
        if(this.getParams() != null) {
            this.props.listConnectors( this.getParams().getApiFilter() );
        }
    }

    onDetails = (row: Connector) => {
        this.props.navigate(`/connectors/${row.uuid}`)
    }

    onCreate = () => {
        this.props.navigate("/connectors/create")
    }

    onInstances = (row: Connector) => {
        this.props.navigate(`/connectors/${row.uuid}/instances`)
    }

    handleMultiRedeploy(selected: string[]): void {
        this.props.triggerTasks({uuid: selected.bbFirst(), task: Tasks.MULTI_REDEPLOY, uuids: selected.bbRemoveFirstItem()})
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('connector', this.props.location)){
            this.props.clearConnectors();
        }
    }

    renderView() {
        const { classes, appReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('connector.model')}
                            headerIcon={<Connection/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('connector.model')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('connector.model')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() } 
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    selectableRows={true}
                                    selected={this.state.selected}
                                    columns={this.columns}
                                    count={this.props.connectors.collection.count_filtered}
                                    listItems={this.props.connectors.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_CONNECTOR_LIST)}
                                    delegate={ this }
                                    customActions={this.customButtons}
                                    tableParams={this.getParams()}
                                    idColumn={ 'uuid' } /> 
                            </Grid>
                        </CardContent>
                        { this.getRedeployView(i18next.t('global.action.trigger_model_redeploy', {model: i18next.t('connector.model_plural')})) }
                    </Card>
                </Container>
            </React.Fragment>
        );
    }

    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('name', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [];
    }

}

const  mapStateToProps = (state: any) => ({
    connectors: state.connectors,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listConnectors: (params: ConnectorRetrieveListModel) => dispatch(ConnectorActions.CreateConnectorList(params)),
        triggerTasks: (params: ConnectorTriggerJobParams) => dispatch(ConnectorActions.CreateConnectorTriggerJob(params)),
        clearConnectors: () => dispatch(ConnectorActions.CreateClearConnectorList())
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ConnectorList, TableStyles)
            )
        )
    )
);