/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    MailLogListResultModel,
    MailLogListResultModelFromJSON,
    MailLogListResultModelToJSON,
    MailLogModel,
    MailLogModelFromJSON,
    MailLogModelToJSON,
    MailLogRetrieveListModel,
    MailLogRetrieveListModelFromJSON,
    MailLogRetrieveListModelToJSON,
} from '../models';

export interface MailLogListRequest {
    mailLogRetrieveListModel: MailLogRetrieveListModel;
}

export interface MailLogRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class MailLogApi extends runtime.BaseAPI {

    /**
     * List all mail logs
     * List all mail logs
     */
    async mailLogListRaw(requestParameters: MailLogListRequest): Promise<runtime.ApiResponse<MailLogListResultModel>> {
        if (requestParameters.mailLogRetrieveListModel === null || requestParameters.mailLogRetrieveListModel === undefined) {
            throw new runtime.RequiredError('mailLogRetrieveListModel','Required parameter requestParameters.mailLogRetrieveListModel was null or undefined when calling mailLogList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail-log/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailLogRetrieveListModelToJSON(requestParameters.mailLogRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailLogListResultModelFromJSON(jsonValue));
    }

    /**
     * List all mail logs
     * List all mail logs
     */
    mailLogList = async(requestParameters: MailLogListRequest): Promise<MailLogListResultModel> => {
        const response = await this.mailLogListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a mail log by its id
     * Retrieve an mail log by its id
     */
    async mailLogRetrieveRaw(requestParameters: MailLogRetrieveRequest): Promise<runtime.ApiResponse<MailLogModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mailLogRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/mail-log/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailLogModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a mail log by its id
     * Retrieve an mail log by its id
     */
    mailLogRetrieve = async(requestParameters: MailLogRetrieveRequest): Promise<MailLogModel> => {
        const response = await this.mailLogRetrieveRaw(requestParameters);
        return await response.value();
    }

}
