import Constants from "config/Constants";
import i18next from "i18next";
import MultiSelectOption from "interface/MultiSelectOption";
import * as Yup from "yup";

const widgetFieldSchema = () => Yup.object().shape({
    field: Yup.string().required(),
    title: Yup.string().required(),
    type: Yup.mixed().oneOf(
        Constants.WIDGET_FIELD_TYPE().map((fieldType: MultiSelectOption) => fieldType.value)
    ).required()
})

export const retrieveWidgetSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    icon: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    fields: Yup.array().of(widgetFieldSchema()).required()
});