/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ConfigInputModelInterface {
    name?: string;
    title?: string | null;
    key_name?: string | null;
    value_name?: string | null;
    type?: string;
    admin_only?: boolean;
    optional?: boolean;
    options?: object;
    inputs?: Array<ConfigInputModel>;
}

/**
 * 
 * @export
 * @interface ConfigInputModel
 */
export class ConfigInputModel extends BaseModel<ConfigInputModelInterface> {

    /**
     * Configuration name
     * @type {string}
     * @memberof ConfigInputModel
     */
    name?: string;
    /**
     * Proper title for the user
     * @type {string}
     * @memberof ConfigInputModel
     */
    title?: string | null;
    /**
     * Name for the keys of certain types
     * @type {string}
     * @memberof ConfigInputModel
     */
    key_name?: string | null;
    /**
     * Name for the values of certain types
     * @type {string}
     * @memberof ConfigInputModel
     */
    value_name?: string | null;
    /**
     * Type of input
     * @type {string}
     * @memberof ConfigInputModel
     */
    type?: string;
    /**
     * Bool indicating whether this config can only be altered by an admin
     * @type {boolean}
     * @memberof ConfigInputModel
     */
    admin_only?: boolean;
    /**
     * Bool indicating whether this input can be left empty
     * @type {boolean}
     * @memberof ConfigInputModel
     */
    optional?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ConfigInputModel
     */
    options?: object;
    /**
     * 
     * @type {Array<ConfigInputModel>}
     * @memberof ConfigInputModel
     */
    inputs?: Array<ConfigInputModel>;

    constructor(data?: ConfigInputModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConfigInputModel, value: StringDateInterface<ConfigInputModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConfigInputModelFromJSON(json: any): ConfigInputModel {
    return ConfigInputModelFromJSONTyped(json, false);
}

export function ConfigInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConfigInputModel;
    try {
        let localClass = require.context('../override', true)('./ConfigInputModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'key_name': !exists(json, 'key_name') ? undefined : json['key_name'],
        'value_name': !exists(json, 'value_name') ? undefined : json['value_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'admin_only': !exists(json, 'adminOnly') ? undefined : json['adminOnly'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'inputs': !exists(json, 'inputs') ? undefined : ((json['inputs'] as Array<any>).map(ConfigInputModelFromJSON)),
    });
}

export function ConfigInputModelToJSON(value?: ConfigInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConfigInputModel;
    try {
        let localClass = require.context('../override', true)('./ConfigInputModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'title': value.title,
        'key_name': value.key_name,
        'value_name': value.value_name,
        'type': value.type,
        'adminOnly': value.admin_only,
        'optional': value.optional,
        'options': value.options,
        'inputs': value.inputs === undefined ? undefined : ((value.inputs as Array<any>).map(ConfigInputModelToJSON)),
    } as StringDateInterface<ConfigInputModel>;
    return createClass.toJson(value, model);
}


