/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ComponentStatusModelInterface {
    name?: string;
    status?: string;
}

/**
 * 
 * @export
 * @interface ComponentStatusModel
 */
export class ComponentStatusModel extends BaseModel<ComponentStatusModelInterface> {

    /**
     * name of the component
     * @type {string}
     * @memberof ComponentStatusModel
     */
    name?: string;
    /**
     * status of the component
     * @type {string}
     * @memberof ComponentStatusModel
     */
    status?: string;

    constructor(data?: ComponentStatusModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ComponentStatusModel, value: StringDateInterface<ComponentStatusModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ComponentStatusModelFromJSON(json: any): ComponentStatusModel {
    return ComponentStatusModelFromJSONTyped(json, false);
}

export function ComponentStatusModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentStatusModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ComponentStatusModel;
    try {
        let localClass = require.context('../override', true)('./ComponentStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    });
}

export function ComponentStatusModelToJSON(value?: ComponentStatusModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ComponentStatusModel;
    try {
        let localClass = require.context('../override', true)('./ComponentStatusModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'status': value.status,
    } as StringDateInterface<ComponentStatusModel>;
    return createClass.toJson(value, model);
}


