import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { withSnackbar } from 'notistack';
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import TableColumn from '../../models/table/TableColumn';
import TableFilter from 'components/TableFilter';
import { ImageModel, ImageRetrieveListModel } from "api";
import TableList from 'components/TableList';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ImageState from 'reducers/interface/ImageState';
import ImageActions from 'reducers/types/Image';
import { Docker } from 'mdi-material-ui';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import Image from 'api/override/ImageModel';


interface StateProps {
    imageReducer: ImageState;
    appReducer: AppState;
}

interface DispatchProps {
    listImages: (params: ImageRetrieveListModel) => void;
    createImage: (params: ImageModel) => void;
    clearImages: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {

}

class ImageList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_IMAGE_LIST_FAILURE
        ]);
        this.updateTabTitle(i18next.t('image.model_plural'))
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('image.name')}),
            new TableColumn({ key: 'docker_image', isSortable: true, label: i18next.t('image.docker_img')}),
            new TableColumn({ key: 'description', isSortable: true, label: i18next.t('image.description')}),
        ];
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.imageReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null) {
            this.props.listImages( this.getParams().getApiFilter() )
        }
    };

    onCreate = () => {
        this.props.navigate("/images/create")
    }

    onDetails = (row: ImageModel) => {
        this.props.navigate(`/images/${row.id}`)
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('image', this.props.location)){
            this.props.clearImages();
        }
    }

    renderView() {
        const { classes, appReducer, imageReducer } = this.props;
        
        if (this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <TableHeader
                            entity={i18next.t('image.model')}
                            headerIcon={<Docker/>}
                            onCreate={this.onCreate}
                        />
                        <CardContent className={classes.table_container}>
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('image.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('image.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={imageReducer.collection.count_filtered}
                                    listItems={imageReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_IMAGE_LIST)}
                                    tableParams={ this.getParams() }
                                    delegate={ this } />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
                
            </React.Fragment>
        );
    }

}

const  mapStateToProps = (state: any) => ({
    imageReducer: state.images,
    appReducer: state.app,
});

const mapDispatchToProps = (dispatch) => {
    return {
        listImages: (params: ImageRetrieveListModel) => dispatch(ImageActions.CreateImageList(params)),
        createImage: (params: Image) => dispatch(ImageActions.CreateImageCreate(params)),
        clearImages: () => dispatch(ImageActions.CreateClearImageList())
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ImageList, TableStyles)
            )
        )
    )
);