import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { Form, Formik, FormikProps } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ButtonStyled from 'components/styled/ButtonStyled';
import Mail from 'api/override/MailModel';
import { retrieveMailSchema } from 'schemas/Mail';

interface MailCreateFormProps {
    onFormSubmit?: (value?: Mail) => void;
}

const MailCreateForm = (props: MailCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);

    return (
        <Formik
            initialValues={new Mail({
                name: '',
                subject: '',
                event: '',
                description: ''
            })}
            validationSchema={retrieveMailSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<Mail>) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('mails.model')})}</h2>
                    
                    <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('general.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="subject" 
                        name="subject"
                        label={t('mails.subject')}
                        type="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="event" 
                        name="event"
                        label={t('mails.event')}
                        type="event"
                        value={formik.values.event}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('general.description')}
                        type="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />


                    <ButtonStyled type="submit" className={classes.button} startIcon={<AddCircleOutlineIcon />}>
                        {t('global.action.create_model', {model: t('mails.model')})}
                    </ButtonStyled>
                </Form>
                
            }
        </Formik>
    );
};

export default MailCreateForm;