
const InputMapper = {
    'text': 'string',
    'number': 'number',
    'boolean': 'boolean',
    'file': 'object',
    'dictionary': 'string',
    'password': 'string',
    'select': 'string'
}
export default InputMapper;