import React, { useEffect } from 'react';
import { ReactTerminal } from 'react-terminal';
import useTerminalStyles from 'styles/TerminalStyles';
import { useTranslation } from 'react-i18next';
import IconButtonStyled from 'components/styled/IconButtonStyled';
import { Close } from 'mdi-material-ui';
import EnvUtil from 'utils/EnvUtil';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthenticateActions from 'reducers/types/Authenticate';
import Auth from 'api/override/AuthenticateModel';
import LocalStorageManager from 'utils/LocalStorageManager';
import SystemStatusActions from 'reducers/types/SystemStatus';
import InstanceActions from 'reducers/types/Instance';
import BackOfficeTerminalUtil from 'utils/BackOfficeTerminalUtil';

interface BackOfficeTerminalProps {
    isVisible?: boolean;
    onClose: () => void;
}

const BackOfficeTerminal = (props: BackOfficeTerminalProps) => {

    const {classes} = useTerminalStyles();
    const {isVisible, onClose} = props
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const util = new BackOfficeTerminalUtil(dispatch);
    useEffect(() => {
        const onKeyDown = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                onClose();
            }
        };
        document.body.addEventListener('keydown', onKeyDown, false);
        return () => document.body.removeEventListener('keydown', onKeyDown, false);
    }, [onClose]);

    const redirectToUrl = (url: string) => {
        navigate(`/${url}`)
        return `Navigating to ${url}`
    }

    const impersonateUser = (email: string) => {
        dispatch(AuthenticateActions.CreateImpersonate(new Auth({email: email})));
        return `Attempting impersonation on ${email}`
    }

    const clearCache = (itemKey?: string) => {
        LocalStorageManager.onClearCache(itemKey)
        return `Deleted key ${itemKey}`
    }

    const simulateProductionEnv = () => {
        EnvUtil.setShouldSimulateProduction(!EnvUtil.getShouldSimulateProduction())
        return `Simulating production: ${EnvUtil.getShouldSimulateProduction().toString()}`;
    }

    const redeployBackoffice = () => {
        dispatch(SystemStatusActions.CreateRedeployBackoffice());
        return 'Redeploying BackOffice'
    }

    const redeployBackofficeStage = () => {
        dispatch(SystemStatusActions.CreateRedeployBackofficeStage());
        return 'Redeploying staging BackOffice'
    }

    const redeployWeb = () => {
        dispatch(InstanceActions.CreateInstanceTriggerJob({
            uuid: 'e5bdc1e2-0640-4d24-a53e-d84111cf089e',
            task: 'redeploy'
        }));
        return 'Redeploying stage.bumbal.eu';
    }

    const setPrimaryAccentColor = (hexColor: string) => {
        LocalStorageManager.setThemeAccentPrimary(hexColor)
        return `Setting hexColor ${hexColor} for primary accent`
    }

    const setSecondaryAccentColor = (hexColor: string) => {
        LocalStorageManager.setThemeAccentSecondary(hexColor)
        return `Setting hexColor ${hexColor} for secondary accent`
    }

    const updateBorderColor = () => {
        if(LocalStorageManager.getBorderAccentEnabled() == null || LocalStorageManager.getBorderAccentEnabled() === 'off'){
            LocalStorageManager.setBorderAccentEnabled('on')
            return 'Turning border coloring on'
        } else {
            LocalStorageManager.setBorderAccentEnabled('off')
            return 'Turning border coloring off'
        }
    }

    const commands = {
        help: (
            <span>
                TERMINAL EXPLANATION<br />
                Welcome to the BackOffice terminal. Here you can find various helper functions to make the BackOffice easier to operate as developer.<br />
                Any command that accepts flags, expects this after the parameters, e.g. command input -flag value -booleanFlag<br />
                Flags shown with a question mark means they are optional and can be left out while filling in a command<br />
                <br />
                TERMINAL COMMANDS<br/>
                ----------------------<br/>
                $ help                              - Show terminal manual <br/>
                $ clear                             - Clear the terminal <br/>
                $ exit                              - Close the terminal <br/>
                $ impersonate [valid user email]    - Log in as the user whose email has been provided <br/>
                $ goto [page]                       - Redirect to a page in the BackOffice<br/>
                $ rmcache [local storage item key]  - Remove the value for a local storage item. Not providing an argument will clear all local storage items.<br/>
                $ prod                              - Simulate production environment<br/>
                $ redeploy                          - Redeploy the BackOffice<br/>
                $ redeployStage                     - Redeploy the BackOffice Stage<br/>
                $ redeployWeb                       - Redeploy stage.bumbal.eu<br/>
                $ create [model]                    - Create a instance, connector, connector container, service or service container. Write help after the model for more info<br />
                $ unlock [model] [uuid]             - Unlock a model by uuid <br/>
                $ port                              - Get an open port<br/>
                <br/>
                STYLING OPTIONS<br/>
                ----------------------<br/>
                <span style={{color: 'red', fontWeight: 800}}>PROVIDING AN INVALID HEX VALUE WILL BREAK THE APP, REQUIRE MANUAL RESOLUTION AND RESULT IN GETTING BANNED FROM PLAYING AROUND WITH THE THEME 😡</span><br/>
                $ pAcc [valid hex value] (e.g. #231F20)           - Set primary accent color <br/>
                $ sAcc [valid hex value] (e.g. #231F20)           - Set secondary accent color <br/>
                $ bAcc                                           - Make the border of the cards match the accent color <br/><br/>
        
                KEYBOARD SHORTCUTS<br/>
                ----------------------<br/>
                / + .                         - Open/Close the terminal<br/>
            </span>
        ),
        exit: onClose,
        goto: redirectToUrl,
        impersonate: impersonateUser,
        rmcache: clearCache,
        prod: simulateProductionEnv,
        redeploy: redeployBackoffice,
        redeployStage: redeployBackofficeStage,
        redeployWeb: redeployWeb,
        pAcc: setPrimaryAccentColor,
        sAcc: setSecondaryAccentColor,
        bAcc: updateBorderColor,
        create: util.create,
        port: util.port,
        unlock: util.unlock
    }

    const welcomeMessage = (
        <span>
            Hello, {LocalStorageManager.getUser()?.full_name}!<br/>
            Type "help" to get a list of all of the commands available.<br/><br/>
        </span>
    )

    return (
            <div className={`${classes.root} ${isVisible && 'show'}`}>
                <IconButtonStyled onClick={() => onClose()}>
                    <Close/>
                </IconButtonStyled>
                <ReactTerminal 
                    theme="dracula"
                    commands={commands}
                    welcomeMessage={welcomeMessage}
                    prompt={`${EnvUtil.isProduction() ? '$' : '🦄'}`}
                    errorMessage={t('terminal.command_not_found')} // 😑 Oops! This command does not exist. Type 'help' for a list of available commands.
                    showControlBar={false}/>
            </div>
    );
};

export default BackOfficeTerminal;