import React from 'react';
import { useTranslation } from 'react-i18next';
import Package from 'api/override/PackageModel';
import { FormikProps } from 'formik';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import useDialogStyles from 'styles/DialogStyles';
import ServiceMultiSelect from 'components/form_controls/ServiceMultiSelect';

interface PackageFormProps {
    formik: FormikProps<Package>;
    isCreate?: boolean;
}

const PackageForm = (props: PackageFormProps) => {
    const { t } = useTranslation();
    const { formik } = props;
    const {classes} = useDialogStyles();

    return (
        <div className={classes.fields_container}>

            <TextFieldStyled
                formik={formik as any}
                propertyName='name'
                label={t('general.name')}
            />

            <TextFieldStyled
                formik={formik as any}
                propertyName='description'
                label={t('general.description')}
            />

            <TextFieldStyled
                formik={formik as any}
                propertyName='price'
                label={t('packages.price')}
            />

            <ServiceMultiSelect
                formik={formik}
            />

        </div>

    );
};

export default React.memo(PackageForm);