import React from 'react';
import { useTranslation } from 'react-i18next';
import use404PageStyles from 'styles/404PageStyles';
import { useNavigate } from "react-router-dom";
import ButtonStyled from './styled/ButtonStyled';
import NotificationCenter from 'services/NotificationCenter';
import { AppActions } from "reducers/types/App";
import ActionTypes from 'config/ActionTypes';

export const Page404 = () => {

    const {classes} = use404PageStyles();
    const { t } = useTranslation()
    const navigate = useNavigate();

    const onBackPress = () => {
        navigate('/');
        NotificationCenter.default.sendNotification(
            AppActions.CreateAction( ActionTypes.CLEAR_404_ERROR )
        )
    }

    return (
        <div className={classes.container}>
            <h1>404</h1>
            <p>{t('global.title.path_leads_nowhere')}</p>
            <div className={classes.animation_container}>
                <ButtonStyled onClick={onBackPress}>{t('global.action.back_to_dashboard')}</ButtonStyled>
                <div id="sun" className={classes.sun}></div>
                <img id="truck" src='../../truck.svg' alt='' className={classes.truck}/>
                <img id="cliff" src='../../cliff.svg' alt='' className={classes.cliff}/>
            </div>
        </div>
    );
}
