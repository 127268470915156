import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Client from 'api/override/ClientModel';
import { useNavigate } from "react-router-dom";
import useTrialCardStyles from 'styles/TrialCardStyles';
import { Button, Divider, Grid, IconButton } from '@mui/material';
import { ChevronDown, Details, EmailArrowRightOutline } from 'mdi-material-ui';
import { format } from 'date-fns';
import DetailsField from 'components/DetailsField';
import SettingManager from 'utils/SettingManager';

interface OpenTrialCardProps {
    client: Client;
    onAcceptClientRequest: (client: Client) => void;
    onRejectClientRequest: (client: Client) => void;
}

const OpenTrialCard = (props: OpenTrialCardProps) => {
    const { t } = useTranslation();
    const { client, onAcceptClientRequest, onRejectClientRequest } = props;
    const navigate = useNavigate();
    const {classes} = useTrialCardStyles();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [bccEmail, setBccEmail] = useState<string>('')
    const [subjectEmail, setSubjectEmail] = useState<string>('')
    
    useEffect(() => {
        setBccEmail(SettingManager.default.getClientEmailBcc().value)
        setSubjectEmail(SettingManager.default.getClientEmailSubject().value)
    }, [])

    const formatDate = (date: Date): string => format(date, 'do MMM yyyy')

    const handleCollapseClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsCollapsed(!isCollapsed)
    }

    const emailTo = () => {
        document.location.href = `mailto:${client.email}?bcc=${bccEmail}&subject=${subjectEmail}`
    }

    const handleClick = (actionName: string) => { // so not elegant, would have been MUCH MORE ELEGANT with a curried function, but Ted said and I quote "NO!"
        switch (actionName) {
            case 'details':
                navigate(`/clients/${client.id}`)
                break;
            case 'email':
                emailTo()
                break;
            default:
                break;
        }
    }

    const marginStyle = { margin: 0 }

    return (
        <Grid container direction='column' className={classes.open_card_container}>
            <div className={`${classes.root} open`}>
                <Grid container item>
                    <Grid container item xs={2} alignItems='center'>
                        <p className={classes.client_name_open}>{client.name}</p>
                    </Grid>
                    <Grid container item xs={3} justifyContent='flex-start'>
                        <Divider orientation="vertical" className={classes.client_row_divider}  />
                        <DetailsField propKey={t('clients.email')} propVal={client.email} containerStyles={{padding: '0 10px', ...marginStyle}} onClick={emailTo}/>
                    </Grid>
                    <Grid container item xs={2} justifyContent='flex-start'>
                        <DetailsField propKey={t('clients.website')} propVal={client.website} containerStyles={marginStyle} redirectTo={client.getWebsiteUrl()}/>
                    </Grid>
                    <Grid container item xs={2} justifyContent='flex-end'>
                        <DetailsField propKey={t('clients.request')} propVal={client.properties?.['requested']} containerStyles={marginStyle} />
                    </Grid>
                    <Grid container item xs={2} justifyContent='flex-end'>
                        <DetailsField propKey={t('trials.submitted')} propVal={formatDate(client.requested_at)} containerStyles={marginStyle} />
                    </Grid>
                    <Grid container item xs={1} justifyContent='flex-end'>
                        <Divider orientation="vertical" className={classes.client_row_divider} />
                        <IconButton onClick={handleCollapseClick} style={{ padding: 10 }} size="large">
                            <ChevronDown className={`${classes.chevron} ${isCollapsed === true && 'up'}`} />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            {isCollapsed &&
                <Grid container item style={{ padding: '8px 0' }}>
                    <Grid container item xs={5}>
                        <DetailsField propKey={t('clients.extra_info')} propVal={client.properties?.['extraInfo']} containerStyles={{ paddingLeft: 20, ...marginStyle }} />
                    </Grid>
                    <Grid container item xs={3} direction='column' justifyContent='space-between'>
                        <DetailsField propKey={t('clients.city')} propVal={client.city} containerStyles={marginStyle} />
                        <DetailsField propKey={t('clients.industry')} propVal={client.industry} labelStyles={{ marginTop: 5 }} containerStyles={marginStyle} />
                    </Grid>
                    <Grid container item xs={3} direction='column' justifyContent='space-evenly'>
                        <Button onClick={() => onAcceptClientRequest(client)} className={`${classes.approval_btn} accept`}>{t('trials.accept')}</Button>
                        <Button onClick={() => onRejectClientRequest(client)} className={`${classes.approval_btn} reject`}>{t('trials.reject')}</Button>
                    </Grid>
                    <Grid container item xs={1} justifyContent='flex-end' >
                        <div className={classes.open_card_btn_container}>
                            <IconButton
                                onClick={() => handleClick('details')}
                                style={{ padding: 11 }}
                                size="large">
                                <Details />
                            </IconButton>
                            <IconButton onClick={() => handleClick('email')} style={{ padding: 11 }} size="large">
                                <EmailArrowRightOutline />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default OpenTrialCard;
