import {ImageModel, ImageModelForModelEnum} from "api";
import Historiable from "interface/Historiable";

export default class Image extends ImageModel implements Historiable {

    actionName: string = "image"
    reducerName: string = "images"

    isForConnectors(): boolean {
        return [ImageModelForModelEnum.Connector, ImageModelForModelEnum.ConnectorContainer].includes(this.for_model)
    }

    isForServices(): boolean {
        return this.for_model === ImageModelForModelEnum.ServiceContainer;
    }

}