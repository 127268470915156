/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    RoleListResultModel,
    RoleListResultModelFromJSON,
    RoleListResultModelToJSON,
    RoleRetrieveListModel,
    RoleRetrieveListModelFromJSON,
    RoleRetrieveListModelToJSON,
} from '../models';

export interface RoleListRequest {
    roleRetrieveListModel: RoleRetrieveListModel;
}

/**
 * 
 */
export class RoleApi extends runtime.BaseAPI {

    /**
     * List all user roles known within this system
     * List all roles
     */
    async roleListRaw(requestParameters: RoleListRequest): Promise<runtime.ApiResponse<RoleListResultModel>> {
        if (requestParameters.roleRetrieveListModel === null || requestParameters.roleRetrieveListModel === undefined) {
            throw new runtime.RequiredError('roleRetrieveListModel','Required parameter requestParameters.roleRetrieveListModel was null or undefined when calling roleList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/role/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleRetrieveListModelToJSON(requestParameters.roleRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleListResultModelFromJSON(jsonValue));
    }

    /**
     * List all user roles known within this system
     * List all roles
     */
    roleList = async(requestParameters: RoleListRequest): Promise<RoleListResultModel> => {
        const response = await this.roleListRaw(requestParameters);
        return await response.value();
    }

}
