import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useProcessProgressStyles = makeStyles()((theme: Theme) => {
    return {
        notification: {
            display: 'flex', 
            alignItems: 'flex-end'
        } ,
        current_notification_container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        } ,
        status_icon: {
            height: 15,
            '&.info': {
                color: '#0071b5'
            },
            '&.warning': {
                color: '#ff9429'
            },
            '&.success': {
                color: '#0a8400'
            },
            '&.error': {
                color: '#ff2d29'
            },
        },
        progress_header: {
            margin: 0,
            padding: '10px 20px 0 20px',
            textTransform: 'capitalize'
        },
        progress_linear_progress: {
            background: '#75b9ff',
            '& > *': {
                background: '#0071b5'
            },
            height: 7,
            width: '100%',
            '&.finished': {
                background: '#75ff7c',
                '& > *': {
                    background: '#13ba1b'
                },
            },
            '&.error': {
                background: '#ffa09e',
                '& > *': {
                    background: '#ff2d29'
                },
            }
        }
    }
})

export default useProcessProgressStyles
