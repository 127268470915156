/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    StepviewWidgetCreateSuccessModelAllOf,
    StepviewWidgetCreateSuccessModelAllOfFromJSON,
    StepviewWidgetCreateSuccessModelAllOfFromJSONTyped,
    StepviewWidgetCreateSuccessModelAllOfToJSON,
    StepviewWidgetModel,
    StepviewWidgetModelFromJSON,
    StepviewWidgetModelFromJSONTyped,
    StepviewWidgetModelToJSON,
} from './';


export interface StepviewWidgetCreateSuccessModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: StepviewWidgetModel;
}

/**
 * 
 * @export
 * @interface StepviewWidgetCreateSuccessModel
 */
export class StepviewWidgetCreateSuccessModel extends BaseModel<StepviewWidgetCreateSuccessModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof StepviewWidgetCreateSuccessModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof StepviewWidgetCreateSuccessModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof StepviewWidgetCreateSuccessModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof StepviewWidgetCreateSuccessModel
     */
    success?: boolean;
    /**
     * 
     * @type {StepviewWidgetModel}
     * @memberof StepviewWidgetCreateSuccessModel
     */
    model?: StepviewWidgetModel;

    constructor(data?: StepviewWidgetCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewWidgetCreateSuccessModel, value: StringDateInterface<StepviewWidgetCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewWidgetCreateSuccessModelFromJSON(json: any): StepviewWidgetCreateSuccessModel {
    return StepviewWidgetCreateSuccessModelFromJSONTyped(json, false);
}

export function StepviewWidgetCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewWidgetCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewWidgetCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : StepviewWidgetModelFromJSON(json['model']),
    });
}

export function StepviewWidgetCreateSuccessModelToJSON(value?: StepviewWidgetCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewWidgetCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': StepviewWidgetModelToJSON(value.model),
    } as StringDateInterface<StepviewWidgetCreateSuccessModel>;
    return createClass.toJson(value, model);
}


