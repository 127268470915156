import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useReloadDataButtonStyles = makeStyles()((theme: Theme) => {
    return {  
        buttonContainer: {
            position: 'fixed',
            top: 0,
            left: 56,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        reloadButton: {
            padding: 6, 
            top: 18, 
            color: theme.palette.primary.main, 
            background: theme.palette.background.paper,
            '&:hover': {
                transition: 'transform 0.3s',
                transform: 'rotate(360deg)',
                background: theme.palette.background.paper,
            }
        }
    }  
})

export default useReloadDataButtonStyles
