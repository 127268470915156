import { JWTToken } from "reducers/interface/ApiState";
import jwtDecode from 'jwt-decode';
import Log from "./Log";
import LocalStorageManager from "./LocalStorageManager";

export default class TokenUtil {

    private static default: TokenUtil;

    private token?: JWTToken;
    private role: string;

    private constructor() {
        this.token = this.getDecodedToken()
    }

    static getTokenUtil(): TokenUtil {
        if (TokenUtil.default == null) {
            TokenUtil.default = new TokenUtil();
        }
        return TokenUtil.default;
    }

    static reconstruct() {
        TokenUtil.default = new TokenUtil();
    }
    
    getRole(): string {
        return this.token?.role;
    }

    setToken(decodedToken: JWTToken): TokenUtil {
        this.token = decodedToken;
        this.role = decodedToken.role;
        return this;
    }

    getDecodedToken(): JWTToken | null {
        try {
            if(localStorage.getItem('jwt') != null) {
                this.refreshToken();
                if (this.isTokenExpired()) {
                    this.clearToken();
                    return null;
                }
            } else {
                this.clearToken()
            }
        } catch (e) {
            Log(e)
            this.clearToken();
        }
        return this.token
    }

    private refreshToken() {
        this.setToken(jwtDecode(localStorage.getItem('jwt')))
    }

    clearToken() {
        this.token = null;
        this.role = null;
        LocalStorageManager.clearLogin();
    }

    isAdmin(): boolean {
        return this.getRole() === 'admin'
    }

    isAccountManager(): boolean {
        if (this.isAdmin()) {
            return true;
        }
        return this.getRole() === 'account_manager'
    }

    isTokenExpired(): boolean {
        try {
            this.refreshToken();
            return Math.floor(Date.now()/1000) > this.token?.exp;
        } catch (e) {
            Log(e)
            return true;
        }
    }

}
