/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    ServiceContainerCreateSuccessModelAllOf,
    ServiceContainerCreateSuccessModelAllOfFromJSON,
    ServiceContainerCreateSuccessModelAllOfFromJSONTyped,
    ServiceContainerCreateSuccessModelAllOfToJSON,
    ServiceContainerModel,
    ServiceContainerModelFromJSON,
    ServiceContainerModelFromJSONTyped,
    ServiceContainerModelToJSON,
} from './';


export interface ServiceContainerCreateSuccessModelInterface {
    message?: string;
    code?: number;
    data?: object | null;
    success?: boolean;
    model?: ServiceContainerModel;
}

/**
 * 
 * @export
 * @interface ServiceContainerCreateSuccessModel
 */
export class ServiceContainerCreateSuccessModel extends BaseModel<ServiceContainerCreateSuccessModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof ServiceContainerCreateSuccessModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof ServiceContainerCreateSuccessModel
     */
    code?: number;
    /**
     * Ambigeous data
     * @type {object}
     * @memberof ServiceContainerCreateSuccessModel
     */
    data?: object | null;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof ServiceContainerCreateSuccessModel
     */
    success?: boolean;
    /**
     * 
     * @type {ServiceContainerModel}
     * @memberof ServiceContainerCreateSuccessModel
     */
    model?: ServiceContainerModel;

    constructor(data?: ServiceContainerCreateSuccessModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ServiceContainerCreateSuccessModel, value: StringDateInterface<ServiceContainerCreateSuccessModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ServiceContainerCreateSuccessModelFromJSON(json: any): ServiceContainerCreateSuccessModel {
    return ServiceContainerCreateSuccessModelFromJSONTyped(json, false);
}

export function ServiceContainerCreateSuccessModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceContainerCreateSuccessModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ServiceContainerCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
        'model': !exists(json, 'model') ? undefined : ServiceContainerModelFromJSON(json['model']),
    });
}

export function ServiceContainerCreateSuccessModelToJSON(value?: ServiceContainerCreateSuccessModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ServiceContainerCreateSuccessModel;
    try {
        let localClass = require.context('../override', true)('./ServiceContainerCreateSuccessModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
        'model': ServiceContainerModelToJSON(value.model),
    } as StringDateInterface<ServiceContainerCreateSuccessModel>;
    return createClass.toJson(value, model);
}


