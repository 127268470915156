import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import ConnectorState from "./interface/ConnectorState";
import { ConnectorListInstancesSuccess, ConnectorListSuccess } from "./types/Connector";
import { ErrorInterface } from "./types/App";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import Instance from "api/override/InstanceModel";
import Connector from "api/override/ConnectorModel";

const initialState: ConnectorState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    error: null,
    isFetchingInstances: false,
    instances: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    logs: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function ConnectorReducers(state = initialState, action: BaseReducerAction): ConnectorState {
    switch (action.type) {
        case ActionTypes.API_CONNECTOR_UPDATE:
        case ActionTypes.API_CONNECTOR_RETRIEVE:
        case ActionTypes.API_CONNECTOR_LIST:
        case ActionTypes.API_CONNECTOR_CREATE:
        case ActionTypes.API_CONNECTOR_DELETE:
        case ActionTypes.API_CONNECTOR_LOGS_LIST:
        case ActionTypes.API_CONNECTOR_HISTORY_LIST:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_CONNECTOR_UPDATE_SUCCESS:
        case ActionTypes.API_CONNECTOR_CREATE_SUCCESS:
        case ActionTypes.API_CONNECTOR_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_CONNECTOR_UPDATE_FAILURE:
        case ActionTypes.API_CONNECTOR_CREATE_FAILURE:
        case ActionTypes.API_CONNECTOR_DELETE_FAILURE:
        case ActionTypes.API_CONNECTOR_LIST_FAILURE:
        case ActionTypes.API_CONNECTOR_RETRIEVE_FAILURE:
            return {
                ...state,
                error: (action as ErrorInterface).data.errors
            }

        case ActionTypes.API_CONNECTOR_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Connector,
                error: null
            };

        case ActionTypes.API_CONNECTOR_LIST_SUCCESS:
            const result = action as ConnectorListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Connector>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };

        case ActionTypes.API_CONNECTOR_LIST_INSTANCES:
            return {
                ...state,
                isFetchingInstances: true,
                error: null
            }
        
        case ActionTypes.API_CONNECTOR_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        case ActionTypes.API_CONNECTOR_LIST_INSTANCES_SUCCESS:
            const resultInstances = action as ConnectorListInstancesSuccess
            return {
                ...state,
                isFetchingInstances: false,
                instances: {
                    items: resultInstances.params.items as Array<Instance>,
                    count_filtered: resultInstances.params.count_filtered,
                    count_unfiltered: resultInstances.params.count_unfiltered
                }
            }
        
        case ActionTypes.API_CONNECTOR_LIST_INSTANCES_FAILURE:
            return {
                ...state,
                isFetchingInstances: false,
                error: (action as ErrorInterface).data?.errors
            }
        
        case ActionTypes.API_CONNECTOR_LOGS_LIST_SUCCESS: {
            return {
                ...state,
                logs: action.params
            }
        }
            
        case ActionTypes.CONNECTOR_VIEW_CLEAR_INSTANCES:
            return {
                ...state,
                isFetchingInstances: false,
                instances: initialState.instances

            }

        case ActionTypes.API_CONNECTOR_LIST_CLEAR:
            if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state
                }
            }
    
        default:
            return state;
    }
}