import { LinearProgress, Tooltip, IconButton } from '@mui/material';
import DetailsFieldIconButton from 'interface/DetailsFieldIconButton';
import React, { CSSProperties } from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';

type ProgressProp = {
    value: number;
    total?: number;
}

interface DetailsFieldProps {
    propKey: string;
    propVal?: number | string;
    arrayVal?: Array<any>;
    arrayProp?: string;
    redirectTo?: string;
    containerStyles?: CSSProperties;
    labelStyles?: CSSProperties;
    progress?: ProgressProp;
    onClick?: () => void;
    iconButton?: DetailsFieldIconButton;
}

const DetailsField = (props: DetailsFieldProps) => {
    const { propKey, propVal, arrayVal, arrayProp, redirectTo, containerStyles, labelStyles, progress, iconButton } = props;
    const {classes} = useDetailsTabStyles();
    const propValue: JSX.Element = (
        <>
        {propVal != null ? 
            <p>{propVal?.toString()}</p>
            :
            <p className={classes.property_nan}> N/A </p>
        }
        </>
    )

    const calculateProgress = (): number => {
        if(progress.total != null) {
            if(progress.value > progress.total) {
              return 100  
            } else {
                return (progress.value * 100) / progress.total
            }
        } else {
            return (progress.value * 100) / 100
        }
    }

    const prepareArrayString = (): string => {
        let result: string = ''
        arrayVal.map((item: any, index: number) => index === 0 ? result = result.concat(item?.[`${arrayProp}`]) : result = result.concat(', ', item?.[`${arrayProp}`]))
        return result
    }

    const handleClick = () => {props.onClick?.()}

    return (
        <div className={classes.details_field_container} style={props.onClick != null ? {cursor: 'pointer', ...containerStyles} : containerStyles}>
            <label className={classes.details_field_label} style={props.onClick != null ? {cursor: 'pointer', ...labelStyles} : labelStyles}>{propKey}</label>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={handleClick}>
                    {arrayVal != null ? 
                        <>
                            {prepareArrayString()}
                        </>
                    :
                    (Boolean(propVal) && redirectTo != null) ? 
                        <a href={`${redirectTo}`} target="_blank" rel="noopener noreferrer" className={classes.link_text}>{propVal?.toString()}</a>
                        :
                        propValue
                    }
                    {progress != null &&
                        <LinearProgress value={calculateProgress()} variant="determinate" style={{width: '88%', borderRadius: 10}}/>
                    }
                </div>
                {iconButton != null ? 
                    <Tooltip title={iconButton.tooltip}>
                        <IconButton 
                            className={classes.details_field_icon_button}
                            onClick={iconButton.onClick}>
                            <iconButton.buttonIcon/>
                        </IconButton> 
                    </Tooltip>
                : 
                    <></>
                }
            </div>
        </div>
    )
}

export default DetailsField;