import ActionTypes from "config/ActionTypes";
import Condition from 'api/override/ConditionModel';
import BaseReducerAction from "./interface/ReducerAction";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";
import ConditionState from "./interface/ConditionState";
import { ConditionListSuccess, ConditionRetrieveSuccess } from "./types/Condition";

const initialState: ConditionState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function ConditionReducers(state = initialState, action: BaseReducerAction): ConditionState {
    switch (action.type) {
        case ActionTypes.API_CONDITION_UPDATE:
        case ActionTypes.API_CONDITION_RETRIEVE:
        case ActionTypes.API_CONDITION_LIST:
        case ActionTypes.API_CONDITION_CREATE:
        case ActionTypes.API_CONDITION_DELETE:
        case ActionTypes.API_CONDITION_HISTORY_LIST:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_CONDITION_UPDATE_SUCCESS:
        case ActionTypes.API_CONDITION_CREATE_SUCCESS:
        case ActionTypes.API_CONDITION_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_CONDITION_UPDATE_FAILURE:
        case ActionTypes.API_CONDITION_CREATE_FAILURE:
        case ActionTypes.API_CONDITION_DELETE_FAILURE:
        case ActionTypes.API_CONDITION_LIST_FAILURE:
        case ActionTypes.API_CONDITION_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_CONDITION_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: (action as ConditionRetrieveSuccess).params as Condition,
                error: undefined
            };

        case ActionTypes.API_CONDITION_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                history: action.params,
            };

        case ActionTypes.API_CONDITION_LIST_SUCCESS:
            const result = action as ConditionListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Condition>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_CONDITION_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }

        default:
            return state;
    }
}