import React, { FormEvent, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from 'formik';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import SingleSelect from 'components/form_controls/SingleSelect';
import MultiSelectOption from 'interface/MultiSelectOption';
import ButtonStyled from 'components/styled/ButtonStyled';
import { CheckboxMarkedCircleOutline } from 'mdi-material-ui';
import useToolStyles from 'styles/ToolStyles';
import { DatabaseExport } from 'mdi-material-ui';
import { DatabaseTransferViewState } from '../DatabaseTransferView';
import { TransferRequestModel, TransferVerifyRequestModel } from 'api';
import { retrieveTransferToolSchema } from 'schemas/TransferToolSchema';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface DatabaseTransferFormProps {
    onFormSubmit?: (value?: TransferVerifyRequestModel) => void;
    onFormChange: (state: Partial<DatabaseTransferViewState>) => void;
    isValid: boolean;
}

const DatabaseTransferForm = (props: DatabaseTransferFormProps) => {

    const { t } = useTranslation();
    const { isValid, onFormChange } = props
    const {classes} = useToolStyles();
    const formRef = useRef(null);
    const serverOptions: MultiSelectOption[] = [
        {value: 'galera', label: 'galera'},
        {value: 'pool-a', label: 'pool-a'},
        {value: 'pool-b', label: 'pool-b'},
        {value: 'pool-e', label: 'pool-e'},
        {value: 'pool-f', label: 'pool-f'},
        {value: 'pool-g', label: 'pool-g'},
        {value: 'pool-h', label: 'pool-h'},
        {value: 'pool-i', label: 'pool-i'},
        {value: 'pool-j', label: 'pool-j'},
        {value: 'pool-k', label: 'pool-k'},
        {value: 'pool-m', label: 'pool-m'},
        {value: 'pool-n', label: 'pool-n'},
        {value: 'pool-o', label: 'pool-o'},
        {value: 'pool-u', label: 'pool-u'},
        {value: 'pool-demo', label: 'pool-demo'},
        {value: 'pool-stage', label: 'pool-stage'},
        {value: 'pool-scenario', label: 'pool-scenario'}
    ];

    const onFormChanged = (event: FormEvent<HTMLFormElement>) => {
        if(isValid && event.target['name'] === 'preserve_existing') {
            onFormChange({ databaseTransferObject: new TransferRequestModel({preserve_existing: event.target['checked']}) })
        } else if (isValid && event.target['name'] !== 'preserve_existing') {
            onFormChange({ isValidated: false })
        }
    }
    return (
        <Formik
            initialValues={new TransferVerifyRequestModel({
                database_from: '',
                server_to: serverOptions[0].value.toString(),
                database_to: '',
                preserve_existing: false,
            })}
            validationSchema={retrieveTransferToolSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
            {(formik: FormikProps<TransferVerifyRequestModel>) =>
                <Form className={classes.db_transfer_form} onChange={onFormChanged}>
                    <div className={classes.db_transfer_fields_container}>
                        <TextFieldStyled 
                            id="database_from" 
                            name="database_from"
                            label={t('db-transfer-tool.database_from')}
                            error={formik.touched.database_from && Boolean(formik.errors.database_from)}
                            value={formik.values.database_from}
                            onChange={formik.handleChange}
                            className={classes.db_transfer_text_field}
                            onBlur={formik.handleBlur}
                            required
                        />

                        <DatabaseExport className={classes.db_transfer_icon}/>
                        <div className={classes.db_transfer_fields_to}>
                            <SingleSelect 
                                id="server_to" 
                                name="server_to"
                                label={t('db-transfer-tool.server_to')}
                                options={serverOptions}
                                value={serverOptions.find(server => server.value === formik.values?.server_to)}
                                onChange={(e, val) => {
                                    if(isValid) {
                                        onFormChange({ isValidated: false })
                                    }
                                    return formik.setFieldValue(e.target['name'], val.value);
                                }}
                                formikError={formik.touched.server_to && Boolean(formik.errors.server_to)}
                            />

                            <TextFieldStyled 
                                id="database_to" 
                                name="database_to"
                                label={t('db-transfer-tool.database_to')}
                                error={formik.touched.database_to && Boolean(formik.errors.database_to)}
                                value={formik.values.database_to}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                            />

                            <BBCheckbox
                                checkboxProps={{
                                    checked: formik.values.preserve_existing,
                                    onChange: formik.handleChange,
                                    id: "preserve_existing",
                                    name: "preserve_existing",
                                }}
                                className={classes.db_transfer_checkbox}
                                label={t('db-transfer-tool.preserve_existing')}
                            />
                        </div>
                    </div>
                    
                    <ButtonStyled type="submit" className={classes.db_transfer_validate_btn} startIcon={<CheckboxMarkedCircleOutline />} disabled={isValid}>
                        {t('global.action.validate')}
                    </ButtonStyled>
                    
                </Form>
            }
        </Formik>
    );
};

export default DatabaseTransferForm;