import { Theme } from '@mui/material/styles';

const AppDrawerStyles = ((theme: Theme) => {
    return {
        drawer: {
            background: theme.palette.mode === 'dark' ? '#151515' : theme.palette.secondary.main,
            width: 56,
            overflow: 'hidden',
            borderRight: 'none',
            zIndex: theme.zIndex.drawer
        },
        logo: {
            width: 40,
            height: 40,
            margin: 8,
            marginTop: 16,
        },
        list_item_icon: {
            margin: 0,
            maxWidth: 'auto',
            minWidth: 'auto',
            color: theme.palette.mode === 'dark' ? '#808080' : '#2c2c2c',
            '& > svg': {
                width: 25,
                height: 25
            }
        },
        list_item: {
            height: 40,
            width: 40,
            margin: '10px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
            transition: 'all .4s',
            '& > div': {
                justifyContent: 'center'
            },
            '&.selected': {
                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : '#2c2c2c',
                boxShadow: '0px 0px 4px 4px #0000000d',
                '& div > div': {
                    color: theme.palette.background.paper
                }
            },
        },
        item_container: {
            height: 57,
            display: 'flex',
            alignItems: 'center',
            '&.selected': {
                // boxShadow: '0 -2px 0 #fff inset, 0 2px 0 #fff inset, inset 0 0 7px 3px #eebc00',
                boxShadow: '4px 0 0 0 #fff inset, inset 0 0 7px 30px #ffc20b',
            },
        },
        grid: {
            height: '100%'
        }
    }
})

export default AppDrawerStyles