/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface PostConfigModelInterface {
    items?: object;
}

/**
 * 
 * @export
 * @interface PostConfigModel
 */
export class PostConfigModel extends BaseModel<PostConfigModelInterface> {

    /**
     * To convince OpenAPI this object exists
     * @type {object}
     * @memberof PostConfigModel
     */
    items?: object;

    constructor(data?: PostConfigModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: PostConfigModel, value: StringDateInterface<PostConfigModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function PostConfigModelFromJSON(json: any): PostConfigModel {
    return PostConfigModelFromJSONTyped(json, false);
}

export function PostConfigModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostConfigModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PostConfigModel;
    try {
        let localClass = require.context('../override', true)('./PostConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'items': !exists(json, 'items') ? undefined : json['items'],
    });
}

export function PostConfigModelToJSON(value?: PostConfigModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PostConfigModel;
    try {
        let localClass = require.context('../override', true)('./PostConfigModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'items': value.items,
    } as StringDateInterface<PostConfigModel>;
    return createClass.toJson(value, model);
}


