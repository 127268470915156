import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { StepviewWidgetHistoryListRequest } from "api/apis/StepviewWidgetApi";
import { StepviewWidgetCreateSuccessModel } from "api/models/StepviewWidgetCreateSuccessModel";
import { StepviewWidgetListResultModel } from "api/models/StepviewWidgetListResultModel";
import { StepviewWidgetRetrieveListModel } from "api/models/StepviewWidgetRetrieveListModel";
import StepviewWidget from "api/override/StepviewWidgetModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface StepviewWidgetList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_LIST;
    params: StepviewWidgetRetrieveListModel;
}

export interface StepviewWidgetListSuccess extends BaseSagaListResponse<StepviewWidgetListResultModel> {
    type: ActionTypes.API_STEPVIEW_WIDGET_LIST_SUCCESS;
}

export interface StepviewWidgetCreate extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_CREATE;
    params: StepviewWidget;
}

export interface StepviewWidgetCreateSuccess extends BaseSagaListResponse<StepviewWidgetCreateSuccessModel> {
    type: ActionTypes.API_STEPVIEW_WIDGET_CREATE_SUCCESS;
}

export interface StepviewWidgetUpdateParams {
    id: number;
    stepviewWidgetModel: StepviewWidget;
}

export interface StepviewWidgetUpdate extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_UPDATE;
    params: StepviewWidgetUpdateParams;
}

export interface StepviewWidgetUpdateSuccess extends BaseSagaListResponse<StepviewWidgetCreateSuccessModel> {
    type: ActionTypes.API_STEPVIEW_WIDGET_UPDATE_SUCCESS;
}

export interface StepviewWidgetRetrieve extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE;
    params: number;
}

export interface StepviewWidgetRetrieveSuccess extends BaseSagaListResponse<StepviewWidget> {
    type: ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE_SUCCESS
}

export interface StepviewWidgetDelete extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_DELETE;
    params: number;
}

export interface StepviewWidgetDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_STEPVIEW_WIDGET_DELETE_SUCCESS;
}

export interface StepviewWidgetHistoryList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST;
    params: StepviewWidgetHistoryListRequest;
}

export interface StepviewWidgetHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST_SUCCESS;
}

export interface StepviewWidgetClearListParams {
    force?: boolean;
}
export interface StepviewWidgetClearList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_WIDGET_LIST_CLEAR;
    params?: StepviewWidgetClearListParams;
}

export default class StepviewWidgetActions {
    
    static CreateStepviewWidgetList(params: StepviewWidgetRetrieveListModel): StepviewWidgetList {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_LIST,
            params: params
        }
    }

    static CreateStepviewWidgetListSuccess(params: StepviewWidgetListResultModel): StepviewWidgetListSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_LIST_SUCCESS,
            params: params
        }
    }

    static CreateStepviewWidgetCreate(params: StepviewWidget): StepviewWidgetCreate {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_CREATE,
            params: params
        }
    }

    static CreateStepviewWidgetCreateSuccess(params: StepviewWidgetCreateSuccessModel): StepviewWidgetCreateSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewWidgetUpdate(params: StepviewWidget): StepviewWidgetUpdate {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_UPDATE,
            params: {
                id: params.id,
                stepviewWidgetModel: params
            }
        }
    }

    static CreateStepviewWidgetUpdateSuccess(params: StepviewWidgetCreateSuccessModel): StepviewWidgetUpdateSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewWidgetRetrieve(params: number): StepviewWidgetRetrieve {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE,
            params: params
        }
    }

    static CreateStepviewWidgetRetrieveSuccess(params: StepviewWidget): StepviewWidgetRetrieveSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewWidgetDelete(params: number): StepviewWidgetDelete {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_DELETE,
            params: params
        }
    }

    static CreateStepviewWidgetDeleteSuccess(params: ApiSuccessResponseModel): StepviewWidgetDeleteSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewWidgetHistoryList(params: StepviewWidgetHistoryListRequest): StepviewWidgetHistoryList {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST,
            params: params
        }
    }

    static CreateStepviewWidgetHistoryListSuccess(params: HistoryResponseModel): StepviewWidgetHistoryListSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearStepviewWidgetList(force: boolean = false): StepviewWidgetClearList {
        return {
            type: ActionTypes.API_STEPVIEW_WIDGET_LIST_CLEAR,
            params: {force: force}
        }   
    }

}