import React from 'react';
import TableList from 'components/TableList';
import Service from 'api/override/ServiceModel';
import TableColumn from 'models/table/TableColumn';
import { useTranslation } from 'react-i18next';

interface ServicePathTableProps {
    service: Service;
}

const ServicePathTable = (props: ServicePathTableProps) => {
    const {t} = useTranslation();
    const data = props.service.volumeModel.toTableData();
    return (
        <TableList
            shouldDisablePagination
            columns={[
                new TableColumn({ key: 'type', label: t('service.view.parent_folder')}),
                new TableColumn({ key: 'path', label: t('service.view.sub_folder_name')})
            ]}
            listItems={data}
            count={data.length}
        />
    );
}

export default ServicePathTable;