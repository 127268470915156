enum ActionTypes {

    /* App Setting Related */
    CHANGED_THEME = 'CHANGED_THEME',
    CHANGED_LANGUAGE = 'CHANGED_LANGUAGE',
    SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE',
    CLEAR_404_ERROR = 'CLEAR_404_ERROR',

    APP_USER_CHANGED = 'APP_USER_CHANGED',

    /* API Related */
    API_CALL_FAILURE = 'API_CALL_FAILURE',
    API_CALL_START = 'API_CALL_START',
    API_CALL_FINISH = 'API_CALL_FINISH',

    /* Auth Related */
    API_SIGN_IN = 'API_SIGN_IN',
    API_SIGN_IN_SUCCESS = 'API_SIGN_IN_SUCCESS',
    API_SIGN_IN_FAILURE = 'API_SIGN_IN_FAILURE',
    API_IMPERSONATE = 'API_IMPERSONATE',
    API_IMPERSONATE_SUCCESS = 'API_IMPERSONATE_SUCCESS',
    API_IMPERSONATE_FAILURE = 'API_IMPERSONATE_FAILURE',
    API_SIGN_OUT = 'API_SIGN_OUT',
    API_SIGN_OUT_ALWAYS = 'API_SIGN_OUT_ALWAYS',
    API_INVALIDATE_TOKEN = 'API_INVALIDATE_TOKEN',
    API_INVALIDATE_TOKEN_SUCCESS = 'API_INVALIDATE_TOKEN_SUCCESS',
    API_INVALIDATE_TOKEN_FAILURE = 'API_INVALIDATE_TOKEN_FAILURE',
    API_SYSTEM_TOKEN = 'API_SYSTEM_TOKEN',
    API_SYSTEM_TOKEN_SUCCESS = 'API_SYSTEM_TOKEN_SUCCESS',
    API_SYSTEM_TOKEN_FAILURE = 'API_SYSTEM_TOKEN_FAILURE',

    /* User Related */
    API_USER_LIST = 'API_USER_LIST',
    API_USER_LIST_SUCCESS = 'API_USER_LIST_SUCCESS',
    API_USER_LIST_FAILURE = 'API_USER_LIST_FAILURE',
    API_USER_LIST_CLEAR = 'API_USER_LIST_CLEAR',
    API_USER_CREATE = 'API_USER_CREATE',
    API_USER_CREATE_SUCCESS = 'API_USER_CREATE_SUCCESS',
    API_USER_CREATE_FAILURE = 'API_USER_CREATE_FAILURE',
    API_USER_RETRIEVE = 'API_USER_RETRIEVE',
    API_USER_RETRIEVE_SUCCESS = 'API_USER_RETRIEVE_SUCCESS',
    API_USER_RETRIEVE_FAILURE = 'API_USER_RETRIEVE_FAILURE',
    API_USER_UPDATE = 'API_USER_UPDATE',
    API_USER_UPDATE_SUCCESS = 'API_USER_UPDATE_SUCCESS',
    API_USER_UPDATE_FAILURE = 'API_USER_UPDATE_FAILURE',
    API_USER_DELETE = 'API_USER_DELETE',
    API_USER_DELETE_SUCCESS = 'API_USER_DELETE_SUCCESS',
    API_USER_DELETE_FAILURE = 'API_USER_DELETE_FAILURE',
    API_USER_HISTORY_LIST = 'API_USER_HISTORY_LIST',
    API_USER_HISTORY_UPDATE = 'API_USER_HISTORY_UPDATE',
    API_USER_HISTORY_LIST_SUCCESS = 'API_USER_HISTORY_LIST_SUCCESS',
    API_USER_HISTORY_LIST_FAILURE = 'API_USER_HISTORY_LIST_FAILURE',
    USER_VIEW_UPDATE_HISTORY = 'USER_VIEW_UPDATE_HISTORY',

    /* Instance Related */
    API_INSTANCE_LIST = 'API_INSTANCE_LIST',
    API_INSTANCE_LIST_SUCCESS = 'API_INSTANCE_LIST_SUCCESS',
    API_INSTANCE_LIST_FAILURE = 'API_INSTANCE_LIST_FAILURE',
    API_INSTANCE_LIST_CLEAR = 'API_INSTANCE_LIST_CLEAR',
    API_INSTANCE_CREATE = 'API_INSTANCE_CREATE',
    API_INSTANCE_CREATE_SUCCESS = 'API_INSTANCE_CREATE_SUCCESS',
    API_INSTANCE_CREATE_FAILURE = 'API_INSTANCE_CREATE_FAILURE',
    API_INSTANCE_CREATE_FAILURE_DOMAIN = 'API_INSTANCE_CREATE_FAILURE_DOMAIN',
    API_INSTANCE_RETRIEVE = 'API_INSTANCE_RETRIEVE',
    API_INSTANCE_RETRIEVE_SUCCESS = 'API_INSTANCE_RETRIEVE_SUCCESS',
    API_INSTANCE_RETRIEVE_FAILURE = 'API_INSTANCE_RETRIEVE_FAILURE',
    API_INSTANCE_UPDATE = 'API_INSTANCE_UPDATE',
    API_INSTANCE_UPDATE_SUCCESS = 'API_INSTANCE_UPDATE_SUCCESS',
    API_INSTANCE_UPDATE_FAILURE = 'API_INSTANCE_UPDATE_FAILURE',
    API_INSTANCE_DELETE = 'API_INSTANCE_DELETE',
    API_INSTANCE_DELETE_SUCCESS = 'API_INSTANCE_DELETE_SUCCESS',
    API_INSTANCE_DELETE_FAILURE = 'API_INSTANCE_DELETE_FAILURE',
    API_INSTANCE_LOGS_LIST = 'API_INSTANCE_LOGS_LIST',
    API_INSTANCE_LOGS_LIST_SUCCESS = 'API_INSTANCE_LOGS_LIST_SUCCESS',
    API_INSTANCE_LOGS_LIST_FAILURE = 'API_INSTANCE_LOGS_LIST_FAILURE',
    API_INSTANCE_LIST_CONNECTORS = 'API_INSTANCE_LIST_CONNECTORS',
    API_INSTANCE_LIST_CONNECTORS_SUCCESS = 'API_INSTANCE_LIST_CONNECTORS_SUCCESS',
    API_INSTANCE_LIST_CONNECTORS_FAILURE = 'API_INSTANCE_LIST_CONNECTORS_FAILURE',
    API_INSTANCE_LIST_SERVICE_CONTAINERS = 'API_INSTANCE_LIST_SERVICE_CONTAINERS',
    API_INSTANCE_LIST_SERVICE_CONTAINERS_SUCCESS = 'API_INSTANCE_LIST_SERVICE_CONTAINERS_SUCCESS',
    API_INSTANCE_LIST_SERVICE_CONTAINERS_FAILURE = 'API_INSTANCE_LIST_SERVICE_CONTAINERS_FAILURE',
    API_INSTANCE_TRIGGER_JOB = 'API_INSTANCE_TRIGGER_JOB',
    API_INSTANCE_TRIGGER_JOB_SUCCESS = 'API_INSTANCE_TRIGGER_JOB_SUCCESS',
    API_INSTANCE_TRIGGER_JOB_FAILURE = 'API_INSTANCE_TRIGGER_JOB_FAILURE',
    INSTANCE_VIEW_CLEAR_CONNECTORS = 'INSTANCE_VIEW_CLEAR_CONNECTORS',
    API_INSTANCE_SET_IMAGE_VERSION = 'API_INSTANCE_SET_IMAGE_VERSION',
    API_INSTANCE_SET_IMAGE_VERSION_SUCCESS = 'API_INSTANCE_SET_IMAGE_VERSION_SUCCESS',
    API_INSTANCE_SET_IMAGE_VERSION_FAILURE = 'API_INSTANCE_SET_IMAGE_VERSION_FAILURE',
    API_INSTANCE_RETRIEVE_SETTINGS = 'API_INSTANCE_RETRIEVE_SETTINGS',
    API_INSTANCE_RETRIEVE_SETTINGS_SUCCESS = 'API_INSTANCE_RETRIEVE_SETTINGS_SUCCESS',
    API_INSTANCE_RETRIEVE_SETTINGS_FAILURE = 'API_INSTANCE_RETRIEVE_SETTINGS_FAILURE',
    API_INSTANCE_UPDATE_SETTINGS = 'API_INSTANCE_UPDATE_SETTINGS',
    API_INSTANCE_UPDATE_SETTINGS_SUCCESS = 'API_INSTANCE_UPDATE_SETTINGS_SUCCESS',
    API_INSTANCE_UPDATE_SETTINGS_FAILURE = 'API_INSTANCE_UPDATE_SETTINGS_FAILURE',
    API_INSTANCE_HISTORY_LIST = 'API_INSTANCE_HISTORY_LIST',
    API_INSTANCE_HISTORY_UPDATE = 'API_INSTANCE_HISTORY_UPDATE',
    API_INSTANCE_HISTORY_LIST_SUCCESS = 'API_INSTANCE_HISTORY_LIST_SUCCESS',
    API_INSTANCE_HISTORY_LIST_FAILURE = 'API_INSTANCE_HISTORY_LIST_FAILURE',
    API_INSTANCE_UPDATE_ADMIN_PASSWORD = 'API_INSTANCE_UPDATE_ADMIN_PASSWORD',
    API_INSTANCE_UPDATE_ADMIN_PASSWORD_SUCCESS = 'API_INSTANCE_UPDATE_ADMIN_PASSWORD_SUCCESS',
    API_INSTANCE_UPDATE_ADMIN_PASSWORD_FAILURE = 'API_INSTANCE_UPDATE_ADMIN_PASSWORD_FAILURE',
    INSTANCE_VIEW_UPDATE_LOGS = 'INSTANCE_VIEW_UPDATE_LOGS',
    API_INSTANCE_TOGGLE_MAINTENANCE_MODE = 'API_INSTANCE_TOGGLE_MAINTENANCE_MODE',
    API_INSTANCE_TOGGLE_MAINTENANCE_MODE_SUCCESS = 'API_INSTANCE_TOGGLE_MAINTENANCE_MODE_SUCCESS',
    API_INSTANCE_TOGGLE_MAINTENANCE_MODE_FAILURE = 'API_INSTANCE_TOGGLE_MAINTENANCE_MODE_FAILURE',

    /* Central Instance Related */
    API_CENTRAL_INSTANCE_LIST = 'API_CENTRAL_INSTANCE_LIST',
    API_CENTRAL_INSTANCE_LIST_SUCCESS = 'API_CENTRAL_INSTANCE_LIST_SUCCESS',
    API_CENTRAL_INSTANCE_LIST_FAILURE = 'API_CENTRAL_INSTANCE_LIST_FAILURE',
    API_CENTRAL_INSTANCE_LIST_CLEAR = 'API_CENTRAL_INSTANCE_LIST_CLEAR',

    /* Auth Instance Related */
    API_INSTANCE_AUTHENTICATE_CREATE_TOKEN = 'API_INSTANCE_AUTHENTICATE_CREATE_TOKEN',
    API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_SUCCESS = 'API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_SUCCESS',
    API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_FAILURE = 'API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_FAILURE',
    API_INSTANCE_USERS_RETRIEVE = 'API_INSTANCE_USERS_RETRIEVE',
    API_INSTANCE_USERS_RETRIEVE_SUCCESS = 'API_INSTANCE_USERS_RETRIEVE_SUCCESS',
    API_INSTANCE_USERS_RETRIEVE_FAILURE = 'API_INSTANCE_USERS_RETRIEVE_FAILURE',

    /* Task List Related */
    API_TASK_MONITOR_LIST_TASKS = 'API_TASK_MONITOR_LIST_TASKS',
    API_TASK_MONITOR_LIST_TASKS_SUCCESS = 'API_TASK_MONITOR_LIST_TASKS_SUCCESS',
    API_TASK_MONITOR_LIST_TASKS_FAILURE = 'API_TASK_MONITOR_LIST_TASKS_FAILURE',
    API_TASK_LIST_CLEAR = 'API_TASK_LIST_CLEAR',
    API_TASK_MONITOR_RETRIEVE = 'API_TASK_MONITOR_RETRIEVE',
    API_TASK_MONITOR_RETRIEVE_SUCCESS = 'API_TASK_MONITOR_RETRIEVE_SUCCESS',
    API_TASK_MONITOR_UPDATE = 'API_TASK_MONITOR_UPDATE',
    API_TASK_MONITOR_UPDATE_SUCCESS = 'API_TASK_MONITOR_UPDATE_SUCCESS',

    /* Setting Related */
    API_SETTING_LIST = 'API_SETTING_LIST',
    API_SETTING_LIST_SUCCESS = 'API_SETTING_LIST_SUCCESS',
    API_SETTING_LIST_FAILURE = 'API_SETTING_LIST_FAILURE',
    API_SETTING_LIST_CLEAR = 'API_SETTING_LIST_CLEAR',
    API_SETTING_RETRIEVE = 'API_SETTING_RETRIEVE',
    API_SETTING_RETRIEVE_SUCCESS = 'API_SETTING_RETRIEVE_SUCCESS',
    API_SETTING_RETRIEVE_FAILURE = 'API_SETTING_RETRIEVE_FAILURE',
    API_SETTING_UPDATE = 'API_SETTING_UPDATE',
    API_SETTING_UPDATE_SUCCESS = 'API_SETTING_UPDATE_SUCCESS',
    API_SETTING_UPDATE_FAILURE = 'API_SETTING_UPDATE_FAILURE',
    API_SETTING_CREATE = 'API_SETTING_CREATE',
    API_SETTING_CREATE_SUCCESS = 'API_SETTING_CREATE_SUCCESS',
    API_SETTING_CREATE_FAILURE = 'API_SETTING_CREATE_FAILURE',
    API_SETTING_DELETE = 'API_SETTING_DELETE',
    API_SETTING_DELETE_SUCCESS = 'API_SETTING_DELETE_SUCCESS',
    API_SETTING_DELETE_FAILURE = 'API_SETTING_DELETE_FAILURE',
    API_SETTING_HISTORY_LIST = 'API_SETTING_HISTORY_LIST',
    API_SETTING_HISTORY_UPDATE = 'API_SETTING_HISTORY_UPDATE',
    API_SETTING_HISTORY_LIST_SUCCESS = 'API_SETTING_HISTORY_LIST_SUCCESS',
    API_SETTING_HISTORY_LIST_FAILURE = 'API_SETTING_HISTORY_LIST_FAILURE',
    SETTING_VIEW_UPDATE_HISTORY = 'SETTING_VIEW_UPDATE_HISTORY',

    /* Client Related */
    API_CLIENT_LIST = 'API_CLIENT_LIST',
    API_CLIENT_LIST_SUCCESS = 'API_CLIENT_LIST_SUCCESS',
    API_CLIENT_LIST_FAILURE = 'API_CLIENT_LIST_FAILURE',
    API_CLIENT_LIST_CLEAR = 'API_CLIENT_LIST_CLEAR',
    API_CLIENT_RETRIEVE = 'API_CLIENT_RETRIEVE',
    API_CLIENT_RETRIEVE_SUCCESS = 'API_CLIENT_RETRIEVE_SUCCESS',
    API_CLIENT_RETRIEVE_FAILURE = 'API_CLIENT_RETRIEVE_FAILURE',
    API_CLIENT_UPDATE = 'API_CLIENT_UPDATE',
    API_CLIENT_UPDATE_SUCCESS = 'API_CLIENT_UPDATE_SUCCESS',
    API_CLIENT_UPDATE_FAILURE = 'API_CLIENT_UPDATE_FAILURE',
    API_CLIENT_STATUS_UPDATE = 'API_CLIENT_STATUS_UPDATE',
    API_CLIENT_STATUS_UPDATE_SUCCESS = 'API_CLIENT_STATUS_UPDATE_SUCCESS',
    API_CLIENT_STATUS_UPDATE_FAILURE = 'API_CLIENT_STATUS_UPDATE_FAILURE',
    API_CLIENT_CREATE = 'API_CLIENT_CREATE',
    API_CLIENT_CREATE_SUCCESS = 'API_CLIENT_CREATE_SUCCESS',
    API_CLIENT_CREATE_FAILURE = 'API_CLIENT_CREATE_FAILURE',
    API_CLIENT_DELETE = 'API_CLIENT_DELETE',
    API_CLIENT_DELETE_SUCCESS = 'API_CLIENT_DELETE_SUCCESS',
    API_CLIENT_DELETE_FAILURE = 'API_CLIENT_DELETE_FAILURE',
    API_CLIENT_HISTORY_LIST = 'API_CLIENT_HISTORY_LIST',
    API_CLIENT_HISTORY_UPDATE = 'API_CLIENT_HISTORY_UPDATE',
    API_CLIENT_HISTORY_LIST_SUCCESS = 'API_CLIENT_HISTORY_LIST_SUCCESS',
    API_CLIENT_HISTORY_LIST_FAILURE = 'API_CLIENT_HISTORY_LIST_FAILURE',
    CLIENT_VIEW_UPDATE_HISTORY = 'CLIENT_VIEW_UPDATE_HISTORY',

    /* Property Related */
    API_PROPERTY_LIST = 'API_PROPERTY_LIST',
    API_PROPERTY_LIST_SUCCESS = 'API_PROPERTY_LIST_SUCCESS',
    API_PROPERTY_LIST_FAILURE = 'API_PROPERTY_LIST_FAILURE',
    API_PROPERTY_LIST_CLEAR = 'API_PROPERTY_LIST_CLEAR',
    API_PROPERTY_RETRIEVE = 'API_PROPERTY_RETRIEVE',
    API_PROPERTY_RETRIEVE_SUCCESS = 'API_PROPERTY_RETRIEVE_SUCCESS',
    API_PROPERTY_RETRIEVE_FAILURE = 'API_PROPERTY_RETRIEVE_FAILURE',
    API_PROPERTY_UPDATE = 'API_PROPERTY_UPDATE',
    API_PROPERTY_UPDATE_SUCCESS = 'API_PROPERTY_UPDATE_SUCCESS',
    API_PROPERTY_UPDATE_FAILURE = 'API_PROPERTY_UPDATE_FAILURE',
    API_PROPERTY_CREATE = 'API_PROPERTY_CREATE',
    API_PROPERTY_CREATE_SUCCESS = 'API_PROPERTY_CREATE_SUCCESS',
    API_PROPERTY_CREATE_FAILURE = 'API_PROPERTY_CREATE_FAILURE',
    API_PROPERTY_DELETE = 'API_PROPERTY_DELETE',
    API_PROPERTY_DELETE_SUCCESS = 'API_PROPERTY_DELETE_SUCCESS',
    API_PROPERTY_DELETE_FAILURE = 'API_PROPERTY_DELETE_FAILURE',
    API_PROPERTY_HISTORY_LIST = 'API_PROPERTY_HISTORY_LIST',
    API_PROPERTY_HISTORY_UPDATE = 'API_PROPERTY_HISTORY_UPDATE',
    API_PROPERTY_HISTORY_LIST_SUCCESS = 'API_PROPERTY_HISTORY_LIST_SUCCESS',
    API_PROPERTY_HISTORY_LIST_FAILURE = 'API_PROPERTY_HISTORY_LIST_FAILURE',
    PROPERTY_VIEW_UPDATE_HISTORY = 'PROPERTY_VIEW_UPDATE_HISTORY',

    /* Form Related */
    API_FORM_LIST = 'API_FORM_LIST',
    API_FORM_LIST_SUCCESS = 'API_FORM_LIST_SUCCESS',
    API_FORM_LIST_FAILURE = 'API_FORM_LIST_FAILURE',
    API_FORM_LIST_CLEAR = 'API_FORM_LIST_CLEAR',
    API_FORM_RETRIEVE = 'API_FORM_RETRIEVE',
    API_FORM_RETRIEVE_SUCCESS = 'API_FORM_RETRIEVE_SUCCESS',
    API_FORM_RETRIEVE_FAILURE = 'API_FORM_RETRIEVE_FAILURE',
    API_FORM_UPDATE = 'API_FORM_UPDATE',
    API_FORM_UPDATE_SUCCESS = 'API_FORM_UPDATE_SUCCESS',
    API_FORM_UPDATE_FAILURE = 'API_FORM_UPDATE_FAILURE',
    API_FORM_CREATE = 'API_FORM_CREATE',
    API_FORM_CREATE_SUCCESS = 'API_FORM_CREATE_SUCCESS',
    API_FORM_CREATE_FAILURE = 'API_FORM_CREATE_FAILURE',
    API_FORM_DELETE = 'API_FORM_DELETE',
    API_FORM_DELETE_SUCCESS = 'API_FORM_DELETE_SUCCESS',
    API_FORM_DELETE_FAILURE = 'API_FORM_DELETE_FAILURE',
    API_FORM_HISTORY_LIST = 'API_FORM_HISTORY_LIST',
    API_FORM_HISTORY_UPDATE = 'API_FORM_HISTORY_UPDATE',
    API_FORM_HISTORY_LIST_SUCCESS = 'API_FORM_HISTORY_LIST_SUCCESS',
    API_FORM_HISTORY_LIST_FAILURE = 'API_FORM_HISTORY_LIST_FAILURE',
    FORM_VIEW_UPDATE_HISTORY = 'FORM_VIEW_UPDATE_HISTORY',

    /* Wizard Related */
    API_WIZARD_LIST = 'API_WIZARD_LIST',
    API_WIZARD_LIST_SUCCESS = 'API_WIZARD_LIST_SUCCESS',
    API_WIZARD_LIST_FAILURE = 'API_WIZARD_LIST_FAILURE',
    API_WIZARD_UPDATE = 'API_WIZARD_UPDATE',
    API_WIZARD_UPDATE_SUCCESS = 'API_WIZARD_UPDATE_SUCCESS',
    API_WIZARD_UPDATE_FAILURE = 'API_WIZARD_UPDATE_FAILURE',
    API_WIZARD_RETRIEVE = 'API_WIZARD_RETRIEVE',
    API_WIZARD_RETRIEVE_SUCCESS = 'API_WIZARD_RETRIEVE_SUCCESS',
    API_WIZARD_RETRIEVE_FAILURE = 'API_WIZARD_RETRIEVE_FAILURE',
    API_WIZARD_CREATE = 'API_WIZARD_CREATE',
    API_WIZARD_CREATE_SUCCESS = 'API_WIZARD_CREATE_SUCCESS',
    API_WIZARD_CREATE_FAILURE = 'API_WIZARD_CREATE_FAILURE',
    API_WIZARD_DELETE = 'API_WIZARD_DELETE',
    API_WIZARD_DELETE_SUCCESS = 'API_WIZARD_DELETE_SUCCESS',
    API_WIZARD_DELETE_FAILURE = 'API_WIZARD_DELETE_FAILURE',
    API_WIZARD_HISTORY_LIST = 'API_WIZARD_HISTORY_LIST',
    API_WIZARD_HISTORY_LIST_SUCCESS = 'API_WIZARD_HISTORY_LIST_SUCCESS',
    API_WIZARD_HISTORY_LIST_FAILURE = 'API_WIZARD_HISTORY_LIST_FAILURE',
    WIZARD_VIEW_UPDATE_HISTORY = 'WIZARD_VIEW_UPDATE_HISTORY',
    API_WIZARD_LIST_CLEAR = 'API_WIZARD_LIST_CLEAR',

    /* Stepview Related */
    API_STEPVIEW_LIST = 'API_STEPVIEW_LIST',
    API_STEPVIEW_LIST_SUCCESS = 'API_STEPVIEW_LIST_SUCCESS',
    API_STEPVIEW_LIST_FAILURE = 'API_STEPVIEW_LIST_FAILURE',
    API_STEPVIEW_UPDATE = 'API_STEPVIEW_UPDATE',
    API_STEPVIEW_UPDATE_SUCCESS = 'API_STEPVIEW_UPDATE_SUCCESS',
    API_STEPVIEW_UPDATE_FAILURE = 'API_STEPVIEW_UPDATE_FAILURE',
    API_STEPVIEW_RETRIEVE = 'API_STEPVIEW_RETRIEVE',
    API_STEPVIEW_RETRIEVE_SUCCESS = 'API_STEPVIEW_RETRIEVE_SUCCESS',
    API_STEPVIEW_RETRIEVE_FAILURE = 'API_STEPVIEW_RETRIEVE_FAILURE',
    API_STEPVIEW_CREATE = 'API_STEPVIEW_CREATE',
    API_STEPVIEW_CREATE_SUCCESS = 'API_STEPVIEW_CREATE_SUCCESS',
    API_STEPVIEW_CREATE_FAILURE = 'API_STEPVIEW_CREATE_FAILURE',
    API_STEPVIEW_DELETE = 'API_STEPVIEW_DELETE',
    API_STEPVIEW_DELETE_SUCCESS = 'API_STEPVIEW_DELETE_SUCCESS',
    API_STEPVIEW_DELETE_FAILURE = 'API_STEPVIEW_DELETE_FAILURE',
    API_STEPVIEW_HISTORY_LIST = 'API_STEPVIEW_HISTORY_LIST',
    API_STEPVIEW_HISTORY_LIST_SUCCESS = 'API_STEPVIEW_HISTORY_LIST_SUCCESS',
    API_STEPVIEW_HISTORY_LIST_FAILURE = 'API_STEPVIEW_HISTORY_LIST_FAILURE',
    STEPVIEW_VIEW_UPDATE_HISTORY = 'STEPVIEW_VIEW_UPDATE_HISTORY',
    API_STEPVIEW_LIST_CLEAR = 'API_STEPVIEW_LIST_CLEAR',

    /* Trial Related */
    API_TRIAL_LIST = 'API_TRIAL_LIST',
    API_TRIAL_LIST_SUCCESS = 'API_TRIAL_LIST_SUCCESS',
    API_TRIAL_LIST_FAILURE = 'API_TRIAL_LIST_FAILURE',
    API_TRIAL_CANCEL = 'API_TRIAL_CANCEL',
    API_TRIAL_CANCEL_SUCCESS = 'API_TRIAL_CANCEL_SUCCESS',
    API_TRIAL_CANCEL_FAILURE = 'API_TRIAL_CANCEL_FAILURE',
    API_TRIAL_UPDATE = 'API_TRIAL_UPDATE',
    API_TRIAL_UPDATE_SUCCESS = 'API_TRIAL_UPDATE_SUCCESS',
    API_TRIAL_UPDATE_FAILURE = 'API_TRIAL_UPDATE_FAILURE',
    API_TRIAL_HISTORY_LIST = 'API_TRIAL_HISTORY_LIST',
    API_TRIAL_HISTORY_UPDATE = 'API_TRIAL_HISTORY_UPDATE',
    API_TRIAL_HISTORY_LIST_SUCCESS = 'API_TRIAL_HISTORY_LIST_SUCCESS',
    API_TRIAL_HISTORY_LIST_FAILURE = 'API_TRIAL_HISTORY_LIST_FAILURE',
    API_TRIAL_LIST_CLEAR = 'API_TRIAL_LIST_CLEAR',
    TRIAL_UPDATE_LIST = 'TRIAL_UPDATE_LIST',

    /* System Related */
    API_LOAD_BALANCER_STATUS = 'API_LOAD_BALANCER_STATUS',
    API_LOAD_BALANCER_STATUS_SUCCESS = 'API_LOAD_BALANCER_STATUS_SUCCESS',
    API_LOAD_BALANCER_STATUS_FAILURE = 'API_LOAD_BALANCER_STATUS_FAILURE',
    API_RANCHER_STATUS = 'API_RANCHER_STATUS',
    API_RANCHER_STATUS_SUCCESS = 'API_RANCHER_STATUS_SUCCESS',
    API_RANCHER_STATUS_FAILURE = 'API_RANCHER_STATUS_FAILURE',
    API_REDEPLOY_BACKOFFICE = 'API_REDEPLOY_BACKOFFICE',
    API_REDEPLOY_BACKOFFICE_SUCCESS = 'API_REDEPLOY_BACKOFFICE_SUCCESS',
    API_REDEPLOY_BACKOFFICE_FAILURE = 'API_REDEPLOY_BACKOFFICE_FAILURE',
    API_REDEPLOY_BACKOFFICE_STAGE = 'API_REDEPLOY_BACKOFFICE_STAGE',
    API_REDEPLOY_BACKOFFICE_STAGE_SUCCESS = 'API_REDEPLOY_BACKOFFICE_STAGE_SUCCESS',
    API_REDEPLOY_BACKOFFICE_STAGE_FAILURE = 'API_REDEPLOY_BACKOFFICE_STAGE_FAILURE',

    /* Connector Related */
    API_CONNECTOR_LIST = 'API_CONNECTOR_LIST',
    API_CONNECTOR_LIST_SUCCESS = 'API_CONNECTOR_LIST_SUCCESS',
    API_CONNECTOR_LIST_FAILURE = 'API_CONNECTOR_LIST_FAILURE',
    API_CONNECTOR_LIST_CLEAR = 'API_CONNECTOR_LIST_CLEAR',
    API_CONNECTOR_RETRIEVE = 'API_CONNECTOR_RETRIEVE',
    API_CONNECTOR_RETRIEVE_SUCCESS = 'API_CONNECTOR_RETRIEVE_SUCCESS',
    API_CONNECTOR_RETRIEVE_FAILURE = 'API_CONNECTOR_RETRIEVE_FAILURE',
    API_CONNECTOR_UPDATE = 'API_CONNECTOR_UPDATE',
    API_CONNECTOR_UPDATE_SUCCESS = 'API_CONNECTOR_UPDATE_SUCCESS',
    API_CONNECTOR_UPDATE_FAILURE = 'API_CONNECTOR_UPDATE_FAILURE',
    API_CONNECTOR_CREATE = 'API_CONNECTOR_CREATE',
    API_CONNECTOR_CREATE_SUCCESS = 'API_CONNECTOR_CREATE_SUCCESS',
    API_CONNECTOR_CREATE_FAILURE = 'API_CONNECTOR_CREATE_FAILURE',
    API_CONNECTOR_DELETE = 'API_CONNECTOR_DELETE',
    API_CONNECTOR_DELETE_SUCCESS = 'API_CONNECTOR_DELETE_SUCCESS',
    API_CONNECTOR_DELETE_FAILURE = 'API_CONNECTOR_DELETE_FAILURE',
    API_CONNECTOR_LIST_INSTANCES = 'API_CONNECTOR_LIST_INSTANCES',
    API_CONNECTOR_LIST_INSTANCES_SUCCESS = 'API_CONNECTOR_LIST_INSTANCES_SUCCESS',
    API_CONNECTOR_LIST_INSTANCES_FAILURE = 'API_CONNECTOR_LIST_INSTANCES_FAILURE',
    API_CONNECTOR_TRIGGER_JOB = 'API_CONNECTOR_TRIGGER_JOB',
    API_CONNECTOR_TRIGGER_JOB_SUCCESS = 'API_CONNECTOR_TRIGGER_JOB_SUCCESS',
    API_CONNECTOR_TRIGGER_JOB_FAILURE = 'API_CONNECTOR_TRIGGER_JOB_FAILURE',
    CONNECTOR_VIEW_CLEAR_INSTANCES = 'CONNECTOR_VIEW_CLEAR_INSTANCES',
    API_CONNECTOR_LOGS_LIST = 'API_CONNECTOR_LOGS_LIST',
    API_CONNECTOR_LOGS_LIST_SUCCESS = 'API_CONNECTOR_LOGS_LIST_SUCCESS',
    API_CONNECTOR_LOGS_LIST_FAILURE = 'API_CONNECTOR_LOGS_LIST_FAILURE',
    CONNECTOR_VIEW_UPDATE_LOGS = 'CONNECTOR_VIEW_UPDATE_LOGS',
    API_CONNECTOR_HISTORY_LIST = 'API_CONNECTOR_HISTORY_LIST',
    API_CONNECTOR_HISTORY_UPDATE = 'API_CONNECTOR_HISTORY_UPDATE',
    API_CONNECTOR_HISTORY_LIST_SUCCESS = 'API_CONNECTOR_HISTORY_LIST_SUCCESS',
    API_CONNECTOR_HISTORY_LIST_FAILURE = 'API_CONNECTOR_HISTORY_LIST_FAILURE',


    /* Connector container Related */
    API_CONNECTOR_CONTAINER_LIST = 'API_CONNECTOR_CONTAINER_LIST',
    API_CONNECTOR_CONTAINER_LIST_SUCCESS = 'API_CONNECTOR_CONTAINER_LIST_SUCCESS',
    API_CONNECTOR_CONTAINER_LIST_FAILURE = 'API_CONNECTOR_CONTAINER_LIST_FAILURE',
    API_CONNECTOR_CONTAINER_RETRIEVE = 'API_CONNECTOR_CONTAINER_RETRIEVE',
    API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS = 'API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS',
    API_CONNECTOR_CONTAINER_RETRIEVE_FAILURE = 'API_CONNECTOR_CONTAINER_RETRIEVE_FAILURE',
    API_CONNECTOR_CONTAINER_UPDATE = 'API_CONNECTOR_CONTAINER_UPDATE',
    API_CONNECTOR_CONTAINER_UPDATE_SUCCESS = 'API_CONNECTOR_CONTAINER_UPDATE_SUCCESS',
    API_CONNECTOR_CONTAINER_UPDATE_FAILURE = 'API_CONNECTOR_CONTAINER_UPDATE_FAILURE',
    API_CONNECTOR_CONTAINER_CREATE = 'API_CONNECTOR_CONTAINER_CREATE',
    API_CONNECTOR_CONTAINER_CREATE_SUCCESS = 'API_CONNECTOR_CONTAINER_CREATE_SUCCESS',
    API_CONNECTOR_CONTAINER_CREATE_FAILURE = 'API_CONNECTOR_CONTAINER_CREATE_FAILURE',
    API_CONNECTOR_CONTAINER_DELETE = 'API_CONNECTOR_CONTAINER_DELETE',
    API_CONNECTOR_CONTAINER_DELETE_SUCCESS = 'API_CONNECTOR_CONTAINER_DELETE_SUCCESS',
    API_CONNECTOR_CONTAINER_DELETE_FAILURE = 'API_CONNECTOR_CONTAINER_DELETE_FAILURE',
    API_CONNECTOR_CONTAINER_GET_CONFIG = 'API_CONNECTOR_CONTAINER_GET_CONFIG',
    API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS = 'API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS',
    API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE = 'API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE',
    API_CONNECTOR_CONTAINER_SET_CONFIG = 'API_CONNECTOR_CONTAINER_SET_CONFIG',
    API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS = 'API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS',
    API_CONNECTOR_CONTAINER_SET_CONFIG_FAILURE = 'API_CONNECTOR_CONTAINER_SET_CONFIG_FAILURE',
    API_CONNECTOR_CONTAINER_SET_CONFIG_FILES = 'API_CONNECTOR_CONTAINER_SET_CONFIG_FILES',
    API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_SUCCESS = 'API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_SUCCESS',
    API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_FAILURE = 'API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_FAILURE',
    API_CONNECTOR_CONTAINER_TRIGGER_JOB = 'API_CONNECTOR_CONTAINER_TRIGGER_JOB',
    API_CONNECTOR_CONTAINER_TRIGGER_JOB_SUCCESS = 'API_CONNECTOR_CONTAINER_TRIGGER_JOB_SUCCESS',
    API_CONNECTOR_CONTAINER_TRIGGER_JOB_FAILURE = 'API_CONNECTOR_CONTAINER_TRIGGER_JOB_FAILURE',
    API_CONNECTOR_CONTAINER_HISTORY_LIST = 'API_CONNECTOR_CONTAINER_HISTORY_LIST',
    API_CONNECTOR_CONTAINER_HISTORY_UPDATE = 'API_CONNECTOR_CONTAINER_HISTORY_UPDATE',
    API_CONNECTOR_CONTAINER_HISTORY_LIST_SUCCESS = 'API_CONNECTOR_CONTAINER_HISTORY_LIST_SUCCESS',
    API_CONNECTOR_CONTAINER_HISTORY_LIST_FAILURE = 'API_CONNECTOR_CONTAINER_HISTORY_LIST_FAILURE',
    API_CONNECTOR_CONTAINER_RESET = 'API_CONNECTOR_CONTAINER_RESET',
    API_CONNECTOR_CONTAINER_RESET_SUCCESS = 'API_CONNECTOR_CONTAINER_RESET_SUCCESS',
    API_CONNECTOR_CONTAINER_RESET_FAILURE = 'API_CONNECTOR_CONTAINER_RESET_FAILURE',

    /* Role Related */
    API_ROLE_LIST = 'API_ROLE_LIST',
    API_ROLE_LIST_SUCCESS = 'API_ROLE_LIST_SUCCESS',
    API_ROLE_LIST_FAILURE = 'API_ROLE_LIST_FAILURE',

    /* Image Related */
    API_IMAGE_LIST = 'API_IMAGE_LIST',
    API_IMAGE_LIST_SUCCESS = 'API_IMAGE_LIST_SUCCESS',
    API_IMAGE_LIST_FAILURE = 'API_IMAGE_LIST_FAILURE',
    API_IMAGE_LIST_CLEAR = 'API_IMAGE_LIST_CLEAR',
    API_IMAGE_RETRIEVE = 'API_IMAGE_RETRIEVE',
    API_IMAGE_RETRIEVE_SUCCESS = 'API_IMAGE_RETRIEVE_SUCCESS',
    API_IMAGE_RETRIEVE_FAILURE = 'API_IMAGE_RETRIEVE_FAILURE',
    API_IMAGE_UPDATE = 'API_IMAGE_UPDATE',
    API_IMAGE_UPDATE_SUCCESS = 'API_IMAGE_UPDATE_SUCCESS',
    API_IMAGE_UPDATE_FAILURE = 'API_IMAGE_UPDATE_FAILURE',
    API_IMAGE_CREATE = 'API_IMAGE_CREATE',
    API_IMAGE_CREATE_SUCCESS = 'API_IMAGE_CREATE_SUCCESS',
    API_IMAGE_CREATE_FAILURE = 'API_IMAGE_CREATE_FAILURE',
    API_IMAGE_DELETE = 'API_IMAGE_DELETE',
    API_IMAGE_DELETE_SUCCESS = 'API_IMAGE_DELETE_SUCCESS',
    API_IMAGE_DELETE_FAILURE = 'API_IMAGE_DELETE_FAILURE',
    API_IMAGE_LIST_INSTANCES = 'API_IMAGE_LIST_INSTANCES',
    API_IMAGE_LIST_INSTANCES_SUCCESS = 'API_IMAGE_LIST_INSTANCES_SUCCESS',
    API_IMAGE_LIST_INSTANCES_FAILURE = 'API_IMAGE_LIST_INSTANCES_FAILURE',
    API_IMAGE_TRIGGER_JOB = 'API_IMAGE_TRIGGER_JOB',
    API_IMAGE_TRIGGER_JOB_SUCCESS = 'API_IMAGE_TRIGGER_JOB_SUCCESS',
    API_IMAGE_TRIGGER_JOB_FAILURE = 'API_IMAGE_TRIGGER_JOB_FAILURE',
    API_IMAGE_HISTORY_LIST = 'API_IMAGE_HISTORY_LIST',
    API_IMAGE_HISTORY_UPDATE = 'API_IMAGE_HISTORY_UPDATE',
    API_IMAGE_HISTORY_LIST_SUCCESS = 'API_IMAGE_HISTORY_LIST_SUCCESS',
    API_IMAGE_HISTORY_LIST_FAILURE = 'API_IMAGE_HISTORY_LIST_FAILURE',

    /* Image Version Related */
    API_IMAGE_VERSION_LIST = 'API_IMAGE_VERSION_LIST',
    API_IMAGE_VERSION_LIST_SUCCESS = 'API_IMAGE_VERSION_LIST_SUCCESS',
    API_IMAGE_VERSION_LIST_FAILURE = 'API_IMAGE_VERSION_LIST_FAILURE',
    API_IMAGE_VERSION_RETRIEVE = 'API_IMAGE_VERSION_RETRIEVE',
    API_IMAGE_VERSION_RETRIEVE_SUCCESS = 'API_IMAGE_VERSION_RETRIEVE_SUCCESS',
    API_IMAGE_VERSION_RETRIEVE_FAILURE = 'API_IMAGE_VERSION_RETRIEVE_FAILURE',
    API_IMAGE_VERSION_UPDATE = 'API_IMAGE_VERSION_UPDATE',
    API_IMAGE_VERSION_UPDATE_SUCCESS = 'API_IMAGE_VERSION_UPDATE_SUCCESS',
    API_IMAGE_VERSION_UPDATE_FAILURE = 'API_IMAGE_VERSION_UPDATE_FAILURE',
    API_IMAGE_VERSION_CREATE = 'API_IMAGE_VERSION_CREATE',
    API_IMAGE_VERSION_CREATE_SUCCESS = 'API_IMAGE_VERSION_CREATE_SUCCESS',
    API_IMAGE_VERSION_CREATE_FAILURE = 'API_IMAGE_VERSION_CREATE_FAILURE',
    API_IMAGE_VERSION_DELETE = 'API_IMAGE_VERSION_DELETE',
    API_IMAGE_VERSION_DELETE_SUCCESS = 'API_IMAGE_VERSION_DELETE_SUCCESS',
    API_IMAGE_VERSION_DELETE_FAILURE = 'API_IMAGE_VERSION_DELETE_FAILURE',
    API_IMAGE_VERSION_LIST_INSTANCES = 'API_IMAGE_VERSION_LIST_INSTANCES',
    API_IMAGE_VERSION_LIST_INSTANCES_SUCCESS = 'API_IMAGE_VERSION_LIST_INSTANCES_SUCCESS',
    API_IMAGE_VERSION_LIST_INSTANCES_FAILURE = 'API_IMAGE_VERSION_LIST_INSTANCES_FAILURE',
    API_IMAGE_VERSION_TRIGGER_JOB = 'API_IMAGE_VERSION_TRIGGER_JOB',
    API_IMAGE_VERSION_TRIGGER_JOB_SUCCESS = 'API_IMAGE_VERSION_TRIGGER_JOB_SUCCESS',
    API_IMAGE_VERSION_TRIGGER_JOB_FAILURE = 'API_IMAGE_VERSION_TRIGGER_JOB_FAILURE',
    API_IMAGE_VERSION_HISTORY_LIST = 'API_IMAGE_VERSION_HISTORY_LIST',
    API_IMAGE_VERSION_HISTORY_UPDATE = 'API_IMAGE_VERSION_HISTORY_UPDATE',
    API_IMAGE_VERSION_HISTORY_LIST_SUCCESS = 'API_IMAGE_VERSION_HISTORY_LIST_SUCCESS',
    API_IMAGE_VERSION_HISTORY_LIST_FAILURE = 'API_IMAGE_VERSION_HISTORY_LIST_FAILURE',

    /* History Related */
    API_HISTORY_LIST = 'API_HISTORY_LIST',
    API_HISTORY_LIST_SUCCESS = 'API_HISTORY_LIST_SUCCESS',
    API_HISTORY_LIST_FAILURE = 'API_HISTORY_LIST_FAILURE',

    /* Port Related */
    API_PORT_LIST = 'API_PORT_LIST',
    API_PORT_LIST_SUCCESS = 'API_PORT_LIST_SUCCESS',
    API_PORT_LIST_FAILURE = 'API_PORT_LIST_FAILURE',
    API_PORT_HISTORY_LIST = 'API_PORT_HISTORY_LIST',
    API_PORT_HISTORY_LIST_SUCCESS = 'API_PORT_HISTORY_LIST_SUCCESS',
    API_PORT_HISTORY_LIST_FAILURE = 'API_PORT_HISTORY_LIST_FAILURE',
    API_PORT_CORRUPTION = 'API_PORT_CORRUPTION',
    API_PORT_CORRUPTION_SUCCESS = 'API_PORT_CORRUPTION_SUCCESS',
    API_PORT_CORRUPTION_FAILURE = 'API_PORT_CORRUPTION_FAILURE',
    API_PORT_REGISTRATION = 'API_PORT_REGISTRATION',
    API_PORT_REGISTRATION_SUCCESS = 'API_PORT_REGISTRATION_SUCCESS',
    API_PORT_REGISTRATION_FAILURE = 'API_PORT_REGISTRATION_FAILURE',
    API_PORT_REMOVE = 'API_PORT_REMOVE',
    API_PORT_REMOVE_SUCCESS = 'API_PORT_REMOVE_SUCCESS',
    API_PORT_REMOVE_FAILURE = 'API_PORT_REMOVE_FAILURE',

    /* Mail Related */
    API_MAIL_LIST = 'API_MAIL_LIST',
    API_MAIL_LIST_SUCCESS = 'API_MAIL_LIST_SUCCESS',
    API_MAIL_LIST_FAILURE = 'API_MAIL_LIST_FAILURE',
    API_MAIL_UPDATE = 'API_MAIL_UPDATE',
    API_MAIL_UPDATE_SUCCESS = 'API_MAIL_UPDATE_SUCCESS',
    API_MAIL_UPDATE_FAILURE = 'API_MAIL_UPDATE_FAILURE',
    API_MAIL_RETRIEVE = 'API_MAIL_RETRIEVE',
    API_MAIL_RETRIEVE_SUCCESS = 'API_MAIL_RETRIEVE_SUCCESS',
    API_MAIL_RETRIEVE_FAILURE = 'API_MAIL_RETRIEVE_FAILURE',
    API_MAIL_CREATE = 'API_MAIL_CREATE',
    API_MAIL_CREATE_SUCCESS = 'API_MAIL_CREATE_SUCCESS',
    API_MAIL_CREATE_FAILURE = 'API_MAIL_CREATE_FAILURE',
    API_MAIL_DELETE = 'API_MAIL_DELETE',
    API_MAIL_DELETE_SUCCESS = 'API_MAIL_DELETE_SUCCESS',
    API_MAIL_DELETE_FAILURE = 'API_MAIL_DELETE_FAILURE',
    API_MAIL_HISTORY_LIST = 'API_MAIL_HISTORY_LIST',
    API_MAIL_HISTORY_LIST_SUCCESS = 'API_MAIL_HISTORY_LIST_SUCCESS',
    API_MAIL_HISTORY_LIST_FAILURE = 'API_MAIL_HISTORY_LIST_FAILURE',
    API_MAIL_LIST_CLEAR = 'API_MAIL_LIST_CLEAR',
    API_MAIL_HISTORY_UPDATE = 'API_MAIL_HISTORY_UPDATE',

    /* Widget Related */
    API_WIDGET_LIST = 'API_WIDGET_LIST',
    API_WIDGET_LIST_SUCCESS = 'API_WIDGET_LIST_SUCCESS',
    API_WIDGET_LIST_FAILURE = 'API_WIDGET_LIST_FAILURE',
    API_WIDGET_UPDATE = 'API_WIDGET_UPDATE',
    API_WIDGET_UPDATE_SUCCESS = 'API_WIDGET_UPDATE_SUCCESS',
    API_WIDGET_UPDATE_FAILURE = 'API_WIDGET_UPDATE_FAILURE',
    API_WIDGET_RETRIEVE = 'API_WIDGET_RETRIEVE',
    API_WIDGET_RETRIEVE_SUCCESS = 'API_WIDGET_RETRIEVE_SUCCESS',
    API_WIDGET_RETRIEVE_FAILURE = 'API_WIDGET_RETRIEVE_FAILURE',
    API_WIDGET_CREATE = 'API_WIDGET_CREATE',
    API_WIDGET_CREATE_SUCCESS = 'API_WIDGET_CREATE_SUCCESS',
    API_WIDGET_CREATE_FAILURE = 'API_WIDGET_CREATE_FAILURE',
    API_WIDGET_DELETE = 'API_WIDGET_DELETE',
    API_WIDGET_DELETE_SUCCESS = 'API_WIDGET_DELETE_SUCCESS',
    API_WIDGET_DELETE_FAILURE = 'API_WIDGET_DELETE_FAILURE',
    API_WIDGET_HISTORY_LIST = 'API_WIDGET_HISTORY_LIST',
    API_WIDGET_HISTORY_LIST_SUCCESS = 'API_WIDGET_HISTORY_LIST_SUCCESS',
    API_WIDGET_HISTORY_LIST_FAILURE = 'API_WIDGET_HISTORY_LIST_FAILURE',
    API_WIDGET_LIST_CLEAR = 'API_WIDGET_LIST_CLEAR',
    API_WIDGET_HISTORY_UPDATE = 'API_WIDGET_HISTORY_UPDATE',

    API_WIDGET_AJAX_LIST = 'API_WIDGET_AJAX_LIST',
    API_WIDGET_AJAX_LIST_SUCCESS = 'API_WIDGET_AJAX_LIST_SUCCESS',

    /* Branch Related */
    API_BRANCH_LIST = 'API_BRANCH_LIST',
    API_BRANCH_LIST_SUCCESS = 'API_BRANCH_LIST_SUCCESS',
    API_BRANCH_LIST_FAILURE = 'API_BRANCH_LIST_FAILURE',
    API_BRANCH_UPDATE = 'API_BRANCH_UPDATE',
    API_BRANCH_UPDATE_SUCCESS = 'API_BRANCH_UPDATE_SUCCESS',
    API_BRANCH_UPDATE_FAILURE = 'API_BRANCH_UPDATE_FAILURE',
    API_BRANCH_RETRIEVE = 'API_BRANCH_RETRIEVE',
    API_BRANCH_RETRIEVE_SUCCESS = 'API_BRANCH_RETRIEVE_SUCCESS',
    API_BRANCH_RETRIEVE_FAILURE = 'API_BRANCH_RETRIEVE_FAILURE',
    API_BRANCH_CREATE = 'API_BRANCH_CREATE',
    API_BRANCH_CREATE_SUCCESS = 'API_BRANCH_CREATE_SUCCESS',
    API_BRANCH_CREATE_FAILURE = 'API_BRANCH_CREATE_FAILURE',
    API_BRANCH_DELETE = 'API_BRANCH_DELETE',
    API_BRANCH_DELETE_SUCCESS = 'API_BRANCH_DELETE_SUCCESS',
    API_BRANCH_DELETE_FAILURE = 'API_BRANCH_DELETE_FAILURE',
    API_BRANCH_HISTORY_LIST = 'API_BRANCH_HISTORY_LIST',
    API_BRANCH_HISTORY_LIST_SUCCESS = 'API_BRANCH_HISTORY_LIST_SUCCESS',
    API_BRANCH_HISTORY_LIST_FAILURE = 'API_BRANCH_HISTORY_LIST_FAILURE',
    API_BRANCH_LIST_CLEAR = 'API_BRANCH_LIST_CLEAR',
    API_BRANCH_HISTORY_UPDATE = 'API_BRANCH_HISTORY_UPDATE',

    /*  StepviewWidget Related */
    API_STEPVIEW_WIDGET_LIST = 'API_STEPVIEW_WIDGET_LIST',
    API_STEPVIEW_WIDGET_LIST_SUCCESS = 'API_STEPVIEW_WIDGET_LIST_SUCCESS',
    API_STEPVIEW_WIDGET_LIST_FAILURE = 'API_STEPVIEW_WIDGET_LIST_FAILURE',
    API_STEPVIEW_WIDGET_UPDATE = 'API_STEPVIEW_WIDGET_UPDATE',
    API_STEPVIEW_WIDGET_UPDATE_SUCCESS = 'API_STEPVIEW_WIDGET_UPDATE_SUCCESS',
    API_STEPVIEW_WIDGET_UPDATE_FAILURE = 'API_STEPVIEW_WIDGET_UPDATE_FAILURE',
    API_STEPVIEW_WIDGET_RETRIEVE = 'API_STEPVIEW_WIDGET_RETRIEVE',
    API_STEPVIEW_WIDGET_RETRIEVE_SUCCESS = 'API_STEPVIEW_WIDGET_RETRIEVE_SUCCESS',
    API_STEPVIEW_WIDGET_RETRIEVE_FAILURE = 'API_STEPVIEW_WIDGET_RETRIEVE_FAILURE',
    API_STEPVIEW_WIDGET_CREATE = 'API_STEPVIEW_WIDGET_CREATE',
    API_STEPVIEW_WIDGET_CREATE_SUCCESS = 'API_STEPVIEW_WIDGET_CREATE_SUCCESS',
    API_STEPVIEW_WIDGET_CREATE_FAILURE = 'API_STEPVIEW_WIDGET_CREATE_FAILURE',
    API_STEPVIEW_WIDGET_DELETE = 'API_STEPVIEW_WIDGET_DELETE',
    API_STEPVIEW_WIDGET_DELETE_SUCCESS = 'API_STEPVIEW_WIDGET_DELETE_SUCCESS',
    API_STEPVIEW_WIDGET_DELETE_FAILURE = 'API_STEPVIEW_WIDGET_DELETE_FAILURE',
    API_STEPVIEW_WIDGET_HISTORY_LIST = 'API_STEPVIEW_WIDGET_HISTORY_LIST',
    API_STEPVIEW_WIDGET_HISTORY_LIST_SUCCESS = 'API_STEPVIEW_WIDGET_HISTORY_LIST_SUCCESS',
    API_STEPVIEW_WIDGET_HISTORY_LIST_FAILURE = 'API_STEPVIEW_WIDGET_HISTORY_LIST_FAILURE',
    API_STEPVIEW_WIDGET_LIST_CLEAR = 'API_STEPVIEW_WIDGET_LIST_CLEAR',
    API_STEPVIEW_WIDGET_HISTORY_UPDATE = 'API_STEPVIEW_WIDGET_HISTORY_UPDATE',

    /* Condition Related */
    API_CONDITION_LIST = 'API_CONDITION_LIST',
    API_CONDITION_LIST_SUCCESS = 'API_CONDITION_LIST_SUCCESS',
    API_CONDITION_LIST_FAILURE = 'API_CONDITION_LIST_FAILURE',
    API_CONDITION_UPDATE = 'API_CONDITION_UPDATE',
    API_CONDITION_UPDATE_SUCCESS = 'API_CONDITION_UPDATE_SUCCESS',
    API_CONDITION_UPDATE_FAILURE = 'API_CONDITION_UPDATE_FAILURE',
    API_CONDITION_RETRIEVE = 'API_CONDITION_RETRIEVE',
    API_CONDITION_RETRIEVE_SUCCESS = 'API_CONDITION_RETRIEVE_SUCCESS',
    API_CONDITION_RETRIEVE_FAILURE = 'API_CONDITION_RETRIEVE_FAILURE',
    API_CONDITION_CREATE = 'API_CONDITION_CREATE',
    API_CONDITION_CREATE_SUCCESS = 'API_CONDITION_CREATE_SUCCESS',
    API_CONDITION_CREATE_FAILURE = 'API_CONDITION_CREATE_FAILURE',
    API_CONDITION_DELETE = 'API_CONDITION_DELETE',
    API_CONDITION_DELETE_SUCCESS = 'API_CONDITION_DELETE_SUCCESS',
    API_CONDITION_DELETE_FAILURE = 'API_CONDITION_DELETE_FAILURE',
    API_CONDITION_HISTORY_LIST = 'API_CONDITION_HISTORY_LIST',
    API_CONDITION_HISTORY_LIST_SUCCESS = 'API_CONDITION_HISTORY_LIST_SUCCESS',
    API_CONDITION_HISTORY_LIST_FAILURE = 'API_CONDITION_HISTORY_LIST_FAILURE',
    API_CONDITION_LIST_CLEAR = 'API_CONDITION_LIST_CLEAR',
    API_CONDITION_HISTORY_UPDATE = 'API_CONDITION_HISTORY_UPDATE',

    /* Mail History Related */
    API_MAIL_LOG_LIST = 'API_MAIL_LOG_LIST',
    API_MAIL_LOG_LIST_SUCCESS = 'API_MAIL_LOG_LIST_SUCCESS',
    API_MAIL_LOG_LIST_FAILURE = 'API_MAIL_LOG_LIST_FAILURE',
    API_MAIL_LOG_RETRIEVE = 'API_MAIL_LOG_RETRIEVE',
    API_MAIL_LOG_RETRIEVE_SUCCESS = 'API_MAIL_LOG_RETRIEVE_SUCCESS',
    API_MAIL_LOG_RETRIEVE_FAILURE = 'API_MAIL_LOG_RETRIEVE_FAILURE',
    API_MAIL_LOG_LIST_CLEAR = 'API_MAIL_LOG_LIST_CLEAR',

    /* Log Related */
    API_LOG_LIST = 'API_LOG_LIST',
    API_LOG_LIST_SUCCESS = 'API_LOG_LIST_SUCCESS',
    API_LOG_LIST_FAILURE = 'API_LOG_LIST_FAILURE',
    API_LOG_INSTANCE = 'API_LOG_INSTANCE',
    API_LOG_INSTANCE_SUCCESS = 'API_LOG_INSTANCE_SUCCESS',
    API_LOG_INSTANCE_FAILURE = 'API_LOG_INSTANCE_FAILURE',

    /* Dashboard Related */
    API_WIDGET_GET_AVAILABLE = 'API_WIDGET_GET_AVAILABLE',
    API_WIDGET_GET_AVAILABLE_SUCCESS = 'API_WIDGET_GET_AVAILABLE_SUCCESS',
    API_WIDGET_GET_AVAILABLE_FAILURE = 'API_WIDGET_GET_AVAILABLE_FAILURE',
    API_WIDGET_GET_DATA = 'API_WIDGET_GET_DATA',
    API_WIDGET_GET_DATA_SUCCESS = 'API_WIDGET_GET_DATA_SUCCESS',
    API_WIDGET_GET_DATA_FAILURE = 'API_WIDGET_GET_DATA_FAILURE',

    /* Database Transfer Related */
    API_DB_TRANSFER_VALIDATE = 'API_DB_TRANSFER_VALIDATE',
    API_DB_TRANSFER_VALIDATE_SUCCESS = 'API_DB_TRANSFER_VALIDATE_SUCCESS',
    API_DB_TRANSFER_VALIDATE_FAILURE = 'API_DB_TRANSFER_VALIDATE_FAILURE',
    API_DB_TRANSFER_START = 'API_DB_TRANSFER_START',
    API_DB_TRANSFER_START_SUCCESS = 'API_DB_TRANSFER_START_SUCCESS',
    API_DB_TRANSFER_START_FAILURE = 'API_DB_TRANSFER_START_FAILURE',

    /* Node Related */
    NODE_INSTANCE_CREATE = 'instance-create',
    NODE_INSTANCE_UPDATE = 'instance-update',
    NODE_INSTANCE_DELETE = 'instance-delete',
    NODE_INSTANCE_MIGRATE = 'instance-migrate',
    NODE_CONNECTOR_CREATE = 'connector-create',
    NODE_CONNECTOR_UPDATE = 'connector-update',
    NODE_CONNECTOR_DELETE = 'connector-delete',
    NODE_CONNECTOR_CONTAINER_CREATE = 'connector-container-create',
    NODE_CONNECTOR_CONTAINER_UPDATE = 'connector-container-update',
    NODE_CONNECTOR_CONTAINER_DELETE = 'connector-container-delete',
    NODE_DATABASE_TRANSFER = 'transfer-database',
    NODE_SERVICE_DELETE = 'service-delete',
    NODE_SERVICE_CREATE = 'service-create',
    NODE_SERVICE_UPDATE = 'service-update',
    NODE_SERVICE_CONTAINER_CREATE = 'service-container-create',
    NODE_SERVICE_CONTAINER_UPDATE = 'service-container-update',
    NODE_SERVICE_CONTAINER_DELETE = 'service-container-delete',
    NODE_INSTANCE_CLEANUP = 'instance-cleanup',
    NODE_SERVICE_UPDATE_CONFIGURATION = 'service-update-configurations',
    NODE_SERVICE_CONTAINER_UPDATE_CONFIGURATION = 'service-container-update-configurations',

    ON_NAVIGATE_AWAY_FROM_DETAILS = 'ON_NAVIGATE_AWAY_FROM_DETAILS',

    /* Filter Related */
    SET_FILTERS = 'SET_FILTERS',
    FETCH_FILTERS = 'FETCH_FILTERS',

    /* Sevice Related */
    API_SERVICE_LIST = 'API_SERVICE_LIST',
    API_SERVICE_LIST_SUCCESS = 'API_SERVICE_LIST_SUCCESS',
    API_SERVICE_LIST_FAILURE = 'API_SERVICE_LIST_FAILURE',
    API_SERVICE_RETRIEVE = 'API_SERVICE_RETRIEVE',
    API_SERVICE_RETRIEVE_SUCCESS = 'API_SERVICE_RETRIEVE_SUCCESS',
    API_SERVICE_RETRIEVE_FAILURE = 'API_SERVICE_RETRIEVE_FAILURE',
    API_SERVICE_UPDATE = 'API_SERVICE_UPDATE',
    API_SERVICE_UPDATE_SUCCESS = 'API_SERVICE_UPDATE_SUCCESS',
    API_SERVICE_UPDATE_FAILURE = 'API_SERVICE_UPDATE_FAILURE',
    API_SERVICE_CREATE = 'API_SERVICE_CREATE',
    API_SERVICE_CREATE_SUCCESS = 'API_SERVICE_CREATE_SUCCESS',
    API_SERVICE_CREATE_FAILURE = 'API_SERVICE_CREATE_FAILURE',
    API_SERVICE_DELETE = 'API_SERVICE_DELETE',
    API_SERVICE_DELETE_SUCCESS = 'API_SERVICE_DELETE_SUCCESS',
    API_SERVICE_DELETE_FAILURE = 'API_SERVICE_DELETE_FAILURE',
    API_SERVICE_LIST_INSTANCES = 'API_SERVICE_LIST_INSTANCES',
    API_SERVICE_LIST_INSTANCES_SUCCESS = 'API_SERVICE_LIST_INSTANCES_SUCCESS',
    API_SERVICE_LIST_INSTANCES_FAILURE = 'API_SERVICE_LIST_INSTANCES_FAILURE',
    API_SERVICE_RETRIEVE_SECRETS = 'API_SERVICE_RETRIEVE_SECRETS',
    API_SERVICE_RETRIEVE_SECRETS_SUCCESS = 'API_SERVICE_RETRIEVE_SECRETS_SUCCESS',
    API_SERVICE_RETRIEVE_SECRETS_FAILURE = 'API_SERVICE_RETRIEVE_SECRETS_FAILURE',
    API_SERVICE_UPDATE_SECRETS = 'API_SERVICE_UPDATE_SECRETS',
    API_SERVICE_UPDATE_SECRETS_SUCCESS = 'API_SERVICE_UPDATE_SECRETS_SUCCESS',
    API_SERVICE_UPDATE_SECRETS_FAILURE = 'API_SERVICE_UPDATE_SECRETS_FAILURE',
    API_SERVICE_TRIGGER_JOB = 'API_SERVICE_TRIGGER_JOB',
    API_SERVICE_TRIGGER_JOB_SUCCESS = 'API_SERVICE_TRIGGER_JOB_SUCCESS',
    API_SERVICE_TRIGGER_JOB_FAILURE = 'API_SERVICE_TRIGGER_JOB_FAILURE',
    SERVICE_VIEW_CLEAR_INSTANCES = 'SERVICE_VIEW_CLEAR_INSTANCES',
    API_SERVICE_LOGS_LIST = 'API_SERVICE_LOGS_LIST',
    API_SERVICE_LOGS_LIST_SUCCESS = 'API_SERVICE_LOGS_LIST_SUCCESS',
    API_SERVICE_LOGS_LIST_FAILURE = 'API_SERVICE_LOGS_LIST_FAILURE',
    SERVICE_VIEW_UPDATE_LOGS = 'SERVICE_VIEW_UPDATE_LOGS',
    API_SERVICE_HISTORY_LIST = 'API_SERVICE_HISTORY_LIST',
    API_SERVICE_HISTORY_UPDATE = 'API_SERVICE_HISTORY_UPDATE',
    API_SERVICE_HISTORY_LIST_SUCCESS = 'API_SERVICE_HISTORY_LIST_SUCCESS',
    API_SERVICE_HISTORY_LIST_FAILURE = 'API_SERVICE_HISTORY_LIST_FAILURE',
    API_SERVICE_LIST_CLEAR = 'API_SERVICE_LIST_CLEAR',
    CLEAR_SERVICE = 'CLEAR_SERVICE',


    /* Service container Related */
    API_SERVICE_CONTAINER_LIST = 'API_SERVICE_CONTAINER_LIST',
    API_SERVICE_CONTAINER_LIST_SUCCESS = 'API_SERVICE_CONTAINER_LIST_SUCCESS',
    API_SERVICE_CONTAINER_LIST_FAILURE = 'API_SERVICE_CONTAINER_LIST_FAILURE',
    API_SERVICE_CONTAINER_RETRIEVE = 'API_SERVICE_CONTAINER_RETRIEVE',
    API_SERVICE_CONTAINER_RETRIEVE_SUCCESS = 'API_SERVICE_CONTAINER_RETRIEVE_SUCCESS',
    API_SERVICE_CONTAINER_RETRIEVE_FAILURE = 'API_SERVICE_CONTAINER_RETRIEVE_FAILURE',
    API_SERVICE_CONTAINER_UPDATE = 'API_SERVICE_CONTAINER_UPDATE',
    API_SERVICE_CONTAINER_UPDATE_SUCCESS = 'API_SERVICE_CONTAINER_UPDATE_SUCCESS',
    API_SERVICE_CONTAINER_UPDATE_FAILURE = 'API_SERVICE_CONTAINER_UPDATE_FAILURE',
    API_SERVICE_CONTAINER_CREATE = 'API_SERVICE_CONTAINER_CREATE',
    API_SERVICE_CONTAINER_CREATE_SUCCESS = 'API_SERVICE_CONTAINER_CREATE_SUCCESS',
    API_SERVICE_CONTAINER_CREATE_FAILURE = 'API_SERVICE_CONTAINER_CREATE_FAILURE',
    API_SERVICE_CONTAINER_DELETE = 'API_SERVICE_CONTAINER_DELETE',
    API_SERVICE_CONTAINER_DELETE_SUCCESS = 'API_SERVICE_CONTAINER_DELETE_SUCCESS',
    API_SERVICE_CONTAINER_DELETE_FAILURE = 'API_SERVICE_CONTAINER_DELETE_FAILURE',
    API_SERVICE_CONTAINER_RETRIEVE_SECRETS = 'API_SERVICE_CONTAINER_RETRIEVE_SECRETS',
    API_SERVICE_CONTAINER_RETRIEVE_SECRETS_SUCCESS = 'API_SERVICE_CONTAINER_RETRIEVE_SECRETS_SUCCESS',
    API_SERVICE_CONTAINER_RETRIEVE_SECRETS_FAILURE = 'API_SERVICE_CONTAINER_RETRIEVE_SECRETS_FAILURE',
    API_SERVICE_CONTAINER_UPDATE_SECRETS = 'API_SERVICE_CONTAINER_UPDATE_SECRETS',
    API_SERVICE_CONTAINER_UPDATE_SECRETS_SUCCESS = 'API_SERVICE_CONTAINER_UPDATE_SECRETS_SUCCESS',
    API_SERVICE_CONTAINER_UPDATE_SECRETS_FAILURE = 'API_SERVICE_CONTAINER_UPDATE_SECRETS_FAILURE',
    API_SERVICE_CONTAINER_TRIGGER_JOB = 'API_SERVICE_CONTAINER_TRIGGER_JOB',
    API_SERVICE_CONTAINER_TRIGGER_JOB_SUCCESS = 'API_SERVICE_CONTAINER_TRIGGER_JOB_SUCCESS',
    API_SERVICE_CONTAINER_TRIGGER_JOB_FAILURE = 'API_SERVICE_CONTAINER_TRIGGER_JOB_FAILURE',
    API_SERVICE_CONTAINER_HISTORY_LIST = 'API_SERVICE_CONTAINER_HISTORY_LIST',
    API_SERVICE_CONTAINER_HISTORY_UPDATE = 'API_SERVICE_CONTAINER_HISTORY_UPDATE',
    API_SERVICE_CONTAINER_HISTORY_LIST_SUCCESS = 'API_SERVICE_CONTAINER_HISTORY_LIST_SUCCESS',
    API_SERVICE_CONTAINER_HISTORY_LIST_FAILURE = 'API_SERVICE_CONTAINER_HISTORY_LIST_FAILURE',
    CLEAR_SERVICE_CONTAINER = 'CLEAR_SERVICE_CONTAINER',

    /* Package Related */
    API_PACKAGE_LIST = 'API_PACKAGE_LIST',
    API_PACKAGE_LIST_SUCCESS = 'API_PACKAGE_LIST_SUCCESS',
    API_PACKAGE_LIST_FAILURE = 'API_PACKAGE_LIST_FAILURE',
    API_PACKAGE_LIST_CLEAR = 'API_PACKAGE_LIST_CLEAR',
    API_PACKAGE_CREATE = 'API_PACKAGE_CREATE',
    API_PACKAGE_CREATE_SUCCESS = 'API_PACKAGE_CREATE_SUCCESS',
    API_PACKAGE_CREATE_FAILURE = 'API_PACKAGE_CREATE_FAILURE',
    API_PACKAGE_RETRIEVE = 'API_PACKAGE_RETRIEVE',
    API_PACKAGE_RETRIEVE_SUCCESS = 'API_PACKAGE_RETRIEVE_SUCCESS',
    API_PACKAGE_RETRIEVE_FAILURE = 'API_PACKAGE_RETRIEVE_FAILURE',
    API_PACKAGE_UPDATE = 'API_PACKAGE_UPDATE',
    API_PACKAGE_UPDATE_SUCCESS = 'API_PACKAGE_UPDATE_SUCCESS',
    API_PACKAGE_UPDATE_FAILURE = 'API_PACKAGE_UPDATE_FAILURE',
    API_PACKAGE_DELETE = 'API_PACKAGE_DELETE',
    API_PACKAGE_DELETE_SUCCESS = 'API_PACKAGE_DELETE_SUCCESS',
    API_PACKAGE_DELETE_FAILURE = 'API_PACKAGE_DELETE_FAILURE',
    API_PACKAGE_HISTORY_LIST = 'API_PACKAGE_HISTORY_LIST',
    API_PACKAGE_HISTORY_UPDATE = 'API_PACKAGE_HISTORY_UPDATE',
    API_PACKAGE_HISTORY_LIST_SUCCESS = 'API_PACKAGE_HISTORY_LIST_SUCCESS',
    API_PACKAGE_HISTORY_LIST_FAILURE = 'API_PACKAGE_HISTORY_LIST_FAILURE',
    PACKAGE_VIEW_UPDATE_HISTORY = 'PACKAGE_VIEW_UPDATE_HISTORY',

}

export default ActionTypes;