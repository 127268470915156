import React, { useState } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from './SingleSelect';
import { FormikProps } from 'formik';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ServiceController from 'controllers/ServiceControllers';
import ServiceContainer from 'api/override/ServiceContainerModel';

interface ServiceSingleSelectProps {
    id: string;
    currentService?: MultiSelectOption;
    controller: ServiceController;
    formik: FormikProps<ServiceContainer>;
    name: string;
}

const ServiceSingleSelect = (props: ServiceSingleSelectProps) => {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams());

    const [chosenService, setChosenService] = React.useState<MultiSelectOption>(props.currentService);
    const getServices = props.controller.serviceToMultiselect;

    const handleCurrentServiceChange = () => {
        if (props.currentService != null) {
            setService(null, props.currentService);
        }
    }
    // eslint-disable-next-line
    useEffect(handleCurrentServiceChange, [props.currentService])

    const setService = (_, val: MultiSelectOption) => {
        setChosenService(val);
        props.formik.setFieldValue(props.name, `${val.value}`);
    }

    return (
        <SingleSelect
            label={t('service.id_field_title')}
            name={props.name}
            tableParams={params}
            value={chosenService}
            getAsyncValuesOnSearch
            getAsyncOptions={getServices}
            onChange={setService}
            formikError={props.formik.touched[props.id] && Boolean(props.formik.errors[props.id])}
            formikHelperText={props.formik.touched[props.id] ? props.formik.errors[props.id] : ""}
        />
    )
}

export default ServiceSingleSelect;
