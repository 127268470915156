import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import SingleSelect from 'components/form_controls/SingleSelect';
import Image from 'api/override/ImageModel';
import ReadOnlyField from 'components/ReadOnlyField';
import Constants from 'config/Constants';

interface ImageDetailsDialogProps extends BaseFormModalProps<Image> {
    image?: Image;
}

const ImageDetailsDialog = (props: ImageDetailsDialogProps) => {

    const { image } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('image.model')})}
            initialValues={new Image({
                ...image,
            })}
            action={ActionTypes.API_IMAGE_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>

                    <TextFieldStyled
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('image.name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('image.description')}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                    <ReadOnlyField 
                        id="docker_image" 
                        name="docker_image"
                        label={t('image.docker_img')}
                        value={formik.values.docker_image}
                        onChange={formik.handleChange}
                        disabled
                    />

                    <SingleSelect 
                        id="for_model" 
                        name="for_model"
                        label={t('image.for_model')}
                        options={Constants.IMAGE_FOR_MODELS()}
                        value={Constants.IMAGE_FOR_MODELS().find(forModel => forModel.value === formik.values?.for_model)}
                        onChange={formik.handleChange}
                        formikError={formik.touched.for_model && Boolean(formik.errors.for_model)}
                        formikHelperText={formik.touched.for_model ? formik.errors.for_model : ""}
                        disabled
                    />

                </div>
            }
        />
    );
};

export default ImageDetailsDialog;