import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import SingleSelect from "components/form_controls/SingleSelect";
import MultiSelectOption from "interface/MultiSelectOption";
import ServiceContainerController from "controllers/ServiceContainerController";
import { TableParams } from "models/table/TableParams";

interface ServiceContainerHistorySelectProps {
    instanceUuid: string;
    onContainerSelect?: (ccUuid: string) => void;
}

const InstanceServiceContainerHistorySelect = (props: ServiceContainerHistorySelectProps) => {
    const { onContainerSelect, instanceUuid } = props
    const { t } = useTranslation();
    const [serviceContainerController] = useState<ServiceContainerController>(new ServiceContainerController())
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const [chosenServiceContainer, setChosenServiceContainer] = React.useState<MultiSelectOption>(null);
    const getServiceContainers = serviceContainerController.instanceContainerToMultiselect;
    const setServiceContainer = (_, val: MultiSelectOption) => {
        setChosenServiceContainer(val);
        onContainerSelect(val.value.toString())
    }
    return (
        <div style={{padding: '5px 150px'}}>
            <SingleSelect
                label={t('service_container.model')}
                value={chosenServiceContainer}
                name="service-container-select"
                onChange={setServiceContainer}
                getAsyncValuesOnSearch
                tableParams={params}
                getAsyncOptions={(params: TableParams) => getServiceContainers(instanceUuid, params)}
            />
        </div>
    );
}

export default InstanceServiceContainerHistorySelect;