import React, { useRef, useState, useEffect } from 'react';
import { Button, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import uuid from 'react-uuid'
import useDialogStyles from 'styles/DialogStyles';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SaveIcon from '@mui/icons-material/Save';
import { Form, Formik } from 'formik';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ImageVersionSingleSelect from 'components/form_controls/ImageVersionSingleSelect';
import ImageVersionController from 'controllers/ImageVersionController';
import Instance from 'api/override/InstanceModel';
import Constants, { Images } from 'config/Constants';
import ImageSingleSelect from 'components/form_controls/ImageSingleSelect';
import ImageController from 'controllers/ImageController';
import { ImageModelForModelEnum } from 'api';
import { Eye, EyeOff } from 'mdi-material-ui';
import EnvUtil from 'utils/EnvUtil';
import ButtonStyled from 'components/styled/ButtonStyled';
import LocalStorageManager from 'utils/LocalStorageManager';
import { retrieveInstanceSchema } from 'schemas/Instance';
import ActionTypes from 'config/ActionTypes';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import { BaseAction } from 'reducers/interface/ReducerAction';
import { Link } from 'react-router-dom';
import Pages from 'utils/Pages';
import FormErrorTextStyled from 'components/styled/FormErrorTextStyled';
import PackageSingleSelect from 'components/form_controls/PackageSingleSelect';
import PackageController from 'controllers/PackageController';
import Package from 'api/override/PackageModel';
import SettingManager from 'utils/SettingManager';
import BBCheckbox from 'components/form_controls/BBCheckbox';


interface InstanceCreateFormProps {
    onFormSubmit?: (value?: Instance) => void;
}

const InstanceCreateForm = (props: InstanceCreateFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const formRef = useRef(null);
    const autofill = (key: string) => {formRef.current.setFieldValue(key, uuid())}
    const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState<boolean>(false)
    const [imageVersionController] = useState<ImageVersionController>(new ImageVersionController())
    const isMigrationEnabled = SettingManager.default.getProdEnableMigrations().getJsonValue();
    const [imageController] = useState<ImageController>(new ImageController())
    const [packageController] = useState<PackageController>(new PackageController())
    const [showPassword, setShowPassword] = useState(false);
    const [existingUuid, setExistingUuid] = useState('');

    const onInstanceError = (error: BaseAction) => {
        const data = error['data'];
        if (data != null) {
            setExistingUuid(data['instance_uuid'])
        }
    }
    const [listeners] = useState([
        new NCListener(ActionTypes.API_INSTANCE_CREATE_FAILURE_DOMAIN, onInstanceError)
    ])
    const onMount = () => {
        NotificationCenter.default.addNCListeners(listeners);
        return () => NotificationCenter.default.removeListeners(listeners);
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }
    // eslint-disable-next-line
    useEffect(onMount, []);

    const getToolsImageId = (): number => {
        if(EnvUtil.isDevelopment()) {
            return Images.UI_TOOLS_DEV
        } 
        else if(EnvUtil.isProduction()) {
            return Images.UI_TOOLS_PROD
        }
        return 0
    }
    return (
        <Formik
            initialValues={
                new Instance({
                    name: '',
                    domain: '',
                    admin_email: '',
                    admin_password: uuid(),
                    has_vps: false,
                    database_password: uuid(),
                    migrate: false,
                    launch_on_stage: false,
                    isLaunching: true,
                    _package: Constants.EMPTY_MULTISELECT as Package
                })
            }
            validationSchema={retrieveInstanceSchema()}
            onSubmit={props.onFormSubmit}
            innerRef={formRef}>
                {(formik) => 
                <Form className={classes.fields_container}>
                    <h2>{t('global.action.create_model', {model: t('instance.model')})}</h2>
                   <TextFieldStyled 
                        shouldShowNameHelperText
                        id="name" 
                        name="name"
                        label={t('instance.name')}
                        helperText={formik.touched.name ? formik.errors.name : ""}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    
                    <TextFieldStyled 
                        id="domain" 
                        name="domain"
                        label={t('instance.domain')}
                        helperText={formik.touched.domain ? formik.errors.domain : ""}
                        error={formik.touched.domain && Boolean(formik.errors.domain)}
                        value={formik.values.domain}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: <InputAdornment position='end' className={(LocalStorageManager.getUser()?.email === 'eva@bumbal.eu' || Math.floor(Math.random() * 420) === 69 || formik.errors.domain?.includes('Only client name needed')) ? classes.eva_adornment : ''}> .bumbal.eu </InputAdornment>
                        }}
                    />

                    <PackageSingleSelect
                        id='_package'
                        name='_package'
                        formik={formik}
                        controller={packageController}
                    />

                    <TextFieldStyled 
                        id="admin_email" 
                        name="admin_email"
                        label={t('instance.admin_email')}
                        helperText={formik.touched.admin_email ? formik.errors.admin_email : ""}
                        error={formik.touched.admin_email && Boolean(formik.errors.admin_email)}
                        value={formik.values.admin_email}
                        onChange={formik.handleChange}
                    />

                   <TextFieldStyled 
                        id="admin_password" 
                        name="admin_password"
                        label={t('instance.admin_password')}
                        type={showPassword ? 'text' : 'password'}
                        helperText={formik.touched.admin_password ? formik.errors.admin_password : ""}
                        error={formik.touched.admin_password && Boolean(formik.errors.admin_password)}
                        value={formik.values.admin_password}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end' className={classes.end_adornment}>
                                    <Button className={classes.adornment_button} onClick={() => autofill('admin_password')}><SettingsBackupRestoreIcon /></Button>
                                    <Button className={classes.adornment_button} aria-label={t('sign-in.password-helper')} onClick={handleClickShowPassword}>
                                        {showPassword ? <Eye /> : <EyeOff />}
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />

                    { isMigrationEnabled && <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.migrate,
                            onChange: (event: React.ChangeEvent<{}>) => {
                                formik.setFieldValue('has_vps', false);
                                formik.handleChange(event)
                            },
                            id: "migrate",
                            name: "migrate",
                            disabled: formik.values.has_vps
                        }}
                        label={t('instance.view.form_migrate')}
                    /> }

                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.has_vps,
                            onChange: (event: React.ChangeEvent<{}>) => {
                                formik.setFieldValue('migrate', false);
                                formik.handleChange(event)
                            },
                            id: "has_vps",
                            name: "has_vps",
                            disabled: formik.values.migrate
                        }}
                        label={t('instance.has_vps')}
                    />

                    <ButtonStyled  className={classes.advanced_opt_button} onClick={() => setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen)} startIcon={isAdvancedOptionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                        {t('global.button.advanced_options')}
                    </ButtonStyled>
                    {isAdvancedOptionsOpen &&
                        <>
                            <ImageSingleSelect 
                                id='ui_image'
                                name='ui_image_id'
                                controller={imageController}
                                formik={formik}
                                label={t('instance.ui_image')}
                                model={[ImageModelForModelEnum.Instance]}
                            />

                            <ImageVersionSingleSelect 
                                id="ui_image_version"
                                name='ui_image_version_id'
                                controller={imageVersionController}
                                formik={formik}
                                imageId={formik.values.ui_image_id}
                                disabled={formik.values?.ui_image_id == null}
                                label={t('instance.ui_image_version')}
                            />
                        {!formik.values.has_vps &&
                            <>
                                <ImageSingleSelect 
                                    id='core_image'
                                    name='core_image_id'
                                    controller={imageController}
                                    formik={formik}
                                    label={t('instance.core_image')}
                                    model={[ImageModelForModelEnum.Instance]}
                                />
                                <ImageVersionSingleSelect 
                                    id="core_image_version"
                                    name='core_image_version_id'
                                    controller={imageVersionController}
                                    formik={formik}
                                    imageId={formik.values.core_image_id}
                                    disabled={formik.values?.core_image_id == null}
                                    label={t('instance.core_image_version')}
                                />
                            </>
                        }
                        <ImageVersionSingleSelect 
                            id="tools_image_version"
                            name='tools_image_version_id'
                            controller={imageVersionController}
                            formik={formik}
                            imageId={getToolsImageId()}
                            label={t('instance.tools_image_version')}
                        />
                        {(formik.values.migrate || formik.values.has_vps) ? (
                            <>
                                <TextFieldStyled 
                                    id="system_key" 
                                    name="system_key"
                                    label={t('instance.system_key')}
                                    helperText={formik.touched.system_key ? formik.errors.system_key : ""}
                                    error={formik.touched.system_key && Boolean(formik.errors.system_key)}
                                    value={formik.values.system_key}
                                    onChange={formik.handleChange}
                                    InputProps={{
                                        endAdornment: 
                                            <InputAdornment position='end' className={classes.end_adornment}>
                                                <IconButton className={classes.adornment_button} color="primary" onClick={() => autofill('system_key')}>
                                                    <SettingsBackupRestoreIcon />
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />

                                <TextFieldStyled 
                                    id="api_key" 
                                    name="api_key"
                                    label={t('instance.api_key')}
                                    helperText={formik.touched.api_key ? formik.errors.api_key : ""}
                                    error={formik.touched.api_key && Boolean(formik.errors.api_key)}
                                    value={formik.values.api_key}
                                    onChange={formik.handleChange}
                                    InputProps={{
                                        endAdornment: 
                                            <InputAdornment position='end' className={classes.end_adornment}>
                                                <IconButton className={classes.adornment_button} color="primary" onClick={() => autofill('api_key')}>
                                                    <SettingsBackupRestoreIcon />
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </>
                        ) : (null)}
                        { !EnvUtil.isStage() ? (
                            <BBCheckbox
                                checkboxProps={{
                                    checked: formik.values.launch_on_stage,
                                    onChange: formik.handleChange,
                                    id: "launch_on_stage",
                                    name: "launch_on_stage"
                                }}
                                label={t('instance.launch_on_stage')}
                            />
                        ) : (null)}
                        </>
                    }   

                    { existingUuid === '' ? (
                        <ButtonStyled type="submit" className={classes.button} startIcon={<SaveIcon />}>
                            {t('global.button.save')}
                        </ButtonStyled>
                    ) : (
                        <>
                            <FormErrorTextStyled message={t('instance.errors.existing_config_found')} />
                            <Link to={`/${Pages.instances}/${existingUuid}`}>{t('instance.view.go-to-existing')}</Link>
                            <BBCheckbox
                                checkboxProps={{
                                    checked: formik.values.skip_existing_check,
                                    onChange: formik.handleChange,
                                    id: "skip_existing_check",
                                    name: "skip_existing_check"
                                }}
                                label={t('instance.skip_existing_check')}
                            />
                            <ButtonStyled type="submit" className={classes.button} startIcon={<SaveIcon />}>
                                {t('instance.view.force_create')}
                            </ButtonStyled>
                        </>
                    )}
                </Form>
                
            }
        </Formik>
    );
};

export default InstanceCreateForm;