import React from "react";
import { TextFieldProps } from '@mui/material';
import TextFieldStyled from "./styled/TextFieldStyled";

const ReadOnlyField = (props: TextFieldProps) => {
    return (
        <TextFieldStyled
            InputProps={{
                readOnly: true,
            }}
            disabled
            {...props}
        />
    )
}

export default ReadOnlyField;