import { Theme } from '@mui/material/styles';

const TrialsDashboardViewStyles = ((theme: Theme) => ({
    root: {
        minWidth: 275,
        marginTop: '6em',
        padding: '10px 50px',
    },
    open_container: {
        width: '100%'
    },
    accepted_container: {
        width: '100%'
    },
    page_title: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        margin: '30px 0',
        padding: '0 58px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > div > h1': {
            fontSize: '22px'
        }
    } ,
    loading_bar: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 6
    } 
}))

export default TrialsDashboardViewStyles
