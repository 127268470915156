import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import ServiceContainerState from "./interface/ServiceContainerState";
import { ErrorInterface } from "./types/App";
import ServiceContainer from "api/override/ServiceContainerModel";
import { ServiceContainerListSuccess } from "./types/ServiceContainer";

const initialState: ServiceContainerState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    error: null,
    secrets: null,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    }
};

export function ServiceContainerReducers(state = initialState, action: BaseReducerAction): ServiceContainerState {
    switch (action.type) {
        case ActionTypes.API_SERVICE_CONTAINER_UPDATE:
        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE:
        case ActionTypes.API_SERVICE_CONTAINER_LIST:
        case ActionTypes.API_SERVICE_CONTAINER_CREATE:
        case ActionTypes.API_SERVICE_CONTAINER_DELETE:
        case ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST:
        case ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS:
        case ActionTypes.API_SERVICE_CONTAINER_CREATE_SUCCESS:
        case ActionTypes.API_SERVICE_CONTAINER_DELETE_SUCCESS:
        case ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_SERVICE_CONTAINER_UPDATE_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_CREATE_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_DELETE_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_LIST_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS_FAILURE:
        case ActionTypes.API_SERVICE_CONTAINER_UPDATE_SECRETS_FAILURE:
            return {
                ...state,
                error: (action as ErrorInterface).data?.errors
            }

        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as ServiceContainer,
                error: null
            };
        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS:
            return {
                ...state,
                secrets: null,
                error: null
            }
        case ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SECRETS_SUCCESS:
            return {
                ...state,
                secrets: action.params?.data
            }

        case ActionTypes.API_SERVICE_CONTAINER_LIST_SUCCESS:
            const result = action as ServiceContainerListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as ServiceContainer[],
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                }
            };

        case ActionTypes.CLEAR_SERVICE_CONTAINER:
            return {
                ...state,
                secrets: null,
                selectedObject: null
            }

        case ActionTypes.API_SERVICE_CONTAINER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: undefined,
                history: action.params
            };

        default:
            return state;
    }
}