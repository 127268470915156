import React from 'react';
import { Grid, InputAdornment, Menu, MenuItem, IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterIcon from '@mui/icons-material/FilterList';
import Close from '@mui/icons-material/Close';
import i18next from 'i18next';
import BaseComponent, { BaseState } from './base/BaseComponent';
import FilterColumn, { FilterTypes, DateRangeFilter } from 'models/table/FilterColumn';
import { withTranslation } from 'react-i18next';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment';
import TableFilterStyles from 'styles/TableFilterStyles';
import TextFieldStyled from './styled/TextFieldStyled';
import { withStyles } from 'tss-react/mui';
import ButtonStyled from './styled/ButtonStyled';
import IconButtonStyled from './styled/IconButtonStyled';

export type TableFilterProps = {
    searchLabel: string;
    searchPlaceholder: string;
    onFilterChange: (filters: FilterColumn[], searchText?: string) => void;
    columns: FilterColumn[];
    searchText: string;
}

interface ColumnState extends BaseState {
    selectedColumns: FilterColumn[];
    hasColumns: boolean;
    hasMenuItems: boolean;
}

interface State extends ColumnState {
    searchValue: string;
    openDropdown: boolean;
    anchorEl?: HTMLElement;
}

class TableFilter extends BaseComponent<TableFilterProps, State> {

    static ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '3 Days': [moment().subtract(3, 'days'), moment()],
        'Last Month': [moment().subtract(1, 'months'), moment()]
    }

    constructor(props) {
        super(props);
        this.state = {
            searchValue: this.props.searchText ?? '',
            openDropdown: false,
            ...this.configColumnState( props )
        }
    }

    componentDidUpdate(previousProps: TableFilterProps, previousState) {
        const newColumns = this.props.columns
            .sort((a, b) => a.key > b.key ? -1 : 1)
            .map(f => f.key)
            .join('');
        const prevColumns = previousProps.columns
            .sort((a, b) => a.key > b.key ? -1 : 1)
            .map(f => f.key)
            .join('');
        if (newColumns !== prevColumns) {
            const newState = this.configColumnState( this.props as TableFilterProps );
            this.setState((state: State) => {
                return {
                    selectedColumns: this.props.columns.filter(f => f.hasInput()).concat(state.selectedColumns),
                    ...newState
                }
            })
        }
    }

    updateFilters = () => {
        this.props.onFilterChange(this.state.selectedColumns, this.state.searchValue);
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateFilters();
    }

    renderView() {
        const {
            searchLabel,
            searchPlaceholder,
            classes
        } = this.props;
        return (
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' className={classes.grid_container}>
                <Grid item xs={3}>
                    <TextFieldStyled
                        className={classes.search_field}
                        label={ searchLabel }
                        placeholder={ searchPlaceholder }
                        value={this.state.searchValue}
                        size='small'
                        onChange={ e => {
                            let val = e.target?.value || '';
                            this.setState({
                                    searchValue: val
                                }, this.updateFilters
                            );
                        } }
                        InputProps={{
                            endAdornment: 
                                this.state.searchValue !== '' ?  
                                <InputAdornment 
                                    position='end' 
                                    onClick={() => this.setState({ searchValue: ''}, this.updateFilters)}
                                    style={{cursor: 'pointer'}}
                                >
                                    <ClearIcon />
                                </InputAdornment> 
                                : 
                                <InputAdornment position='end'>
                                    <SearchIcon />
                                </InputAdornment>
                        }}
                    />
                </Grid>
                { this.state.hasColumns && this.renderFilterViews() }
                { this.state.hasMenuItems && this.renderFilterButton() }
            </Grid>
        );
    }

    renderFilterViews() {
        const {
            classes
        } = this.props;
        let hasSelectedColumns = this.state.selectedColumns.length === 0;
        return hasSelectedColumns 
            ? (
                <Grid item xs={8} />
            ) : (
                <Grid item xs={8} className={ classes.filter_wrapper }>
                    { this.renderFilters() }
                    <ButtonStyled variant="outlined" className={classes.remove_all_button} onClick={this.removeAll}> {i18next.t('general.remove_all')} </ButtonStyled>
                </Grid>
            )
    }

    renderFilterButton() {
        const {
            classes
        } = this.props;

        const {
            openDropdown,
            anchorEl
        } = this.state
        return (
            <Grid item xs={1}> 
                <Tooltip title={ i18next.t('filter.add') }>
                    <IconButtonStyled
                        onClick={e => this.setState({ openDropdown: !openDropdown, anchorEl: e.currentTarget })}
                        className={ classes.add_filter_button }
                        size="large">
                        <FilterIcon />
                    </IconButtonStyled>
                </Tooltip>
                <Menu
                    id='filter-menu'
                    anchorEl={ anchorEl }
                    onClose={ () => this.setState({ openDropdown: false }) }
                    open={ openDropdown }>   
                    { this.renderDropdown() }
                </Menu>
            </Grid>
        );
    }

    renderFilters() {
        const {
            classes
        } = this.props;
        return this.state.selectedColumns.map((col: FilterColumn) => {
            switch (col.type) {
                case FilterTypes.STRING:
                case FilterTypes.DATE_RANGE:
                    return (
                        <TextFieldStyled
                            label={ col.label }
                            key={ col.key }
                            size="small"
                            onChange={ e => {
                                if (col.type === FilterTypes.DATE_RANGE) {
                                    if (e.target?.value !== '') {
                                        (col as DateRangeFilter).startValue = moment(e.target?.value + ' 00:00');
                                        (col as DateRangeFilter).endValue = moment(e.target?.value + ' 23:59');   
                                    } else {
                                        col.removeFilter()
                                    }
                                } else {
                                    col.inputValue = e.target?.value || '';
                                }
                                this.updateFilters();
                            } }
                            className={ classes.filter_row }
                            type={ col.type === FilterTypes.STRING ? 'text' : 'date' }
                            InputProps={{
                                endAdornment: col.isAlwaysActive ? null : <InputAdornment position='end'><IconButton className={classes.remove_filter_button} onClick={() => this.removeFilter(col)}><Close /></IconButton></InputAdornment>
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                case FilterTypes.ENUM:
                case FilterTypes.BOOLEAN:
                    if (col.type === FilterTypes.BOOLEAN && !col.values) {
                        col.values = {
                            'true': i18next.t('general.yes'),
                            'false': i18next.t('general.no')
                        };
                    }
                    const selectValue = col.inputValue === '' ? 'hiddenNoneValue' : col.inputValue;
                    return (
                        <TextFieldStyled
                            select
                            label={ col.label }
                            key={ col.key }
                            size='small'
                            value={ selectValue }
                            className={classes.filter_row}
                            onChange={e => {
                                let val = e.target.value as string;
                                col.inputValue = val === 'hiddenNoneValue' ? '' : val;
                                if (col.type === 'boolean' && !(val === 'hiddenNoneValue')) {
                                    col.inputValue = val === 'true';
                                }
                                this.updateFilters();
                            }}
                            InputProps={{
                                endAdornment: col.isAlwaysActive ? null : <InputAdornment position='end'><IconButton className={classes.remove_filter_button} onClick={() => this.removeFilter(col)}><Close /></IconButton></InputAdornment>
                            }}>
                            <MenuItem key={'hiddenNoneValue'} value={ 'hiddenNoneValue' }>{i18next.t('general.none')}</MenuItem>
                            { Object.keys(col.values ?? []).map(k => {
                                return (<MenuItem key={k} value={ k }>{ col.values[k] }</MenuItem>)
                            })}
                        </TextFieldStyled>
                    );
                case FilterTypes.DATETIME_RANGE:
                    if (!(col instanceof DateRangeFilter)) {
                        return (
                            <div key={ col.key }>{col.type} for field {col.label} must be of type DateRangeFilter</div>
                        );
                    }
                    if (col.startValue == null && col.endValue == null) {
                        col.setInitialValues();
                    }
                    let momentFormat = 'DD-MM-YY HH:mm';
                    return (
                        <DateTimeRangeContainer 
                            ranges={ TableFilter.ranges } 
                            local={{
                                'format': momentFormat,
                                'sundayFirst' : false
                            }}
                            applyCallback={ (startDate, endDate) => {
                                if (col instanceof DateRangeFilter) {
                                    col.startValue = startDate;
                                    col.endValue = endDate;
                                }
                                this.updateFilters();
                            } }
                            key={ col.key }
                            start={ (col as DateRangeFilter).startValue }
                            end={ (col as DateRangeFilter).endValue } >
                            <TextFieldStyled
                                label={ col.label }
                                size='small'
                                className={ classes.filter_row }
                                value={ col.startValue.format(momentFormat) +' - '+ col.endValue.format(momentFormat) }
                                InputProps={{
                                    endAdornment: col.isAlwaysActive ? null : <InputAdornment position='end'><IconButton className={classes.remove_filter_button} onClick={() => this.removeFilter(col)}><Close /></IconButton></InputAdornment>
                                }}
                            />
                        </DateTimeRangeContainer>
                    );
                default:
                    return (
                        <div key={ col.key }>{col.type} for field {col.label} not yet supported</div>
                    )
            }
        });
    }

    renderDropdown() {
        return this.props.columns.filter(col => !col.isAlwaysActive).map((col: FilterColumn) => (
            <MenuItem color="primary" key={col.key} style={{textTransform: 'capitalize'}} onClick={ () => {
                this.setState({ openDropdown: !this.state.openDropdown });
                if (this.state.selectedColumns.some((item) => item.key === col.key)) {
                    return;
                }
                this.setState(state => {
                    return {
                        selectedColumns: state.selectedColumns.concat( col )
                    }
                });
            } }>{ col.label }</MenuItem>
        ));
    }

    removeAll = () => {
        const alwaysActiveFilters = this.props.columns.filter(col => col.isAlwaysActive);
        for (const filter of alwaysActiveFilters) {
            filter.removeFilter();
        }
        for (const filter of this.state.selectedColumns) {
            filter.removeFilter()
        }
        this.setState({
            selectedColumns: alwaysActiveFilters
        }, this.updateFilters);
    }

    removeFilter = (col: FilterColumn) => {
        col.removeFilter();
        this.setState(state => {
            return {
                selectedColumns: state.selectedColumns.filter( item => item.key !== col.key )
            }
        }, this.updateFilters);
    }

    private configColumnState = (props: TableFilterProps): ColumnState => {
        return {
            selectedColumns: props.columns.filter((column: FilterColumn) => column.isAlwaysActive || column.hasInput()),
            hasColumns: this.props.columns?.length > 0,
            hasMenuItems: this.props.columns?.filter(col => !col.isAlwaysActive).length > 0
        }
    }

};

export default withTranslation()(withStyles(TableFilter, TableFilterStyles));