import { makeStyles } from 'tss-react/mui';
import {keyframes} from "tss-react";
import { Theme } from '@mui/material/styles';
import LocalStorageManager from 'utils/LocalStorageManager';

const growShrink = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.3) rotate(15deg); }
    100% { transform: scale(1) rotate(0deg); }
`

const useLoadingViewStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            background: LocalStorageManager.isThemeLight() ? '#cecece' : '#1f1f1f',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        logo: {
            height: 200,
            width: 200,
            animation: `${growShrink} 2s infinite 0.5s ease-in-out`,
        },
    }
})

export default useLoadingViewStyles
