/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConfigInputModel,
    ConfigInputModelFromJSON,
    ConfigInputModelFromJSONTyped,
    ConfigInputModelToJSON,
} from './';


export interface ConfigDataModelInterface {
    config?: object;
    allowed_input?: Array<ConfigInputModel>;
}

/**
 * 
 * @export
 * @interface ConfigDataModel
 */
export class ConfigDataModel extends BaseModel<ConfigDataModelInterface> {

    /**
     * 
     * @type {object}
     * @memberof ConfigDataModel
     */
    config?: object;
    /**
     * 
     * @type {Array<ConfigInputModel>}
     * @memberof ConfigDataModel
     */
    allowed_input?: Array<ConfigInputModel>;

    constructor(data?: ConfigDataModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConfigDataModel, value: StringDateInterface<ConfigDataModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConfigDataModelFromJSON(json: any): ConfigDataModel {
    return ConfigDataModelFromJSONTyped(json, false);
}

export function ConfigDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConfigDataModel;
    try {
        let localClass = require.context('../override', true)('./ConfigDataModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'config': !exists(json, 'config') ? undefined : json['config'],
        'allowed_input': !exists(json, 'allowed_input') ? undefined : ((json['allowed_input'] as Array<any>).map(ConfigInputModelFromJSON)),
    });
}

export function ConfigDataModelToJSON(value?: ConfigDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConfigDataModel;
    try {
        let localClass = require.context('../override', true)('./ConfigDataModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'config': value.config,
        'allowed_input': value.allowed_input === undefined ? undefined : ((value.allowed_input as Array<any>).map(ConfigInputModelToJSON)),
    } as StringDateInterface<ConfigDataModel>;
    return createClass.toJson(value, model);
}


