import { Theme } from '@mui/material/styles';

const TableStyles = ((theme: Theme) => {
    return {
        root: {
            minWidth: 275,
            marginTop: '6em',
        },
        table_container: {
            padding: '20px 0'
        },
        form_card_container: {
            display: 'flex', 
            justifyContent: 'center', 
            marginBottom: 30
        },
        formPage: {
            '& #tableAddButton': {
                display: 'none'
            }
        },
        mail_log_status_icon: {
            '&.read': {
                color: theme.palette.primary.main    
            },
            '&.sent': {
                color: theme.palette.success.main
            },
            '&.not_sent': {
                color: theme.palette.warning.main
            },
            '&.error': {
                color: theme.palette.error.main
            }
        },
        linkColor: {
            color: theme.palette.primary.main,
        },
        indicator: {

            '&.success': {
                color: theme.palette.success.main
            },
            '&.error': {
                color: theme.palette.error.main
            },
            '&.inactive': {
                color: '#696969'
            }
        }
    }
})

export default TableStyles