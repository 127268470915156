import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useServiceStyles = makeStyles()((theme: Theme) => {
    return {
        card_content: {
            margin: 40
        },
        ss_header: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            margin: '30px 0 10px 0'
        },
        ss_remove_btn: {
            padding: 10,
            alignSelf: 'center',
            color: theme.palette.error.main
        },
        ss_add_btn: {
            color: theme.palette.primary.main
        },
        ss_linear_progress: {
            position: 'absolute',
            bottom: -40,
            left: 0,
            width: '100%'
        },
        volume_container: {
            marginTop: 30
        }
    }
});

export default useServiceStyles
