/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    BranchCreateSuccessModel,
    BranchCreateSuccessModelFromJSON,
    BranchCreateSuccessModelToJSON,
    BranchListResultModel,
    BranchListResultModelFromJSON,
    BranchListResultModelToJSON,
    BranchModel,
    BranchModelFromJSON,
    BranchModelToJSON,
    BranchRemoveSuccessModel,
    BranchRemoveSuccessModelFromJSON,
    BranchRemoveSuccessModelToJSON,
    BranchRetrieveListModel,
    BranchRetrieveListModelFromJSON,
    BranchRetrieveListModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface BranchCreateRequest {
    branchModel: BranchModel;
}

export interface BranchDeleteRequest {
    id: number;
}

export interface BranchHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface BranchListRequest {
    branchRetrieveListModel: BranchRetrieveListModel;
}

export interface BranchRetrieveRequest {
    id: number;
}

export interface BranchUpdateRequest {
    id: number;
    branchModel: BranchModel;
}

/**
 * 
 */
export class BranchApi extends runtime.BaseAPI {

    /**
     * Create a new branch
     * Create a new branch
     */
    async branchCreateRaw(requestParameters: BranchCreateRequest): Promise<runtime.ApiResponse<BranchCreateSuccessModel>> {
        if (requestParameters.branchModel === null || requestParameters.branchModel === undefined) {
            throw new runtime.RequiredError('branchModel','Required parameter requestParameters.branchModel was null or undefined when calling branchCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BranchModelToJSON(requestParameters.branchModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new branch
     * Create a new branch
     */
    branchCreate = async(requestParameters: BranchCreateRequest): Promise<BranchCreateSuccessModel> => {
        const response = await this.branchCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an branch
     * Delete an branch
     */
    async branchDeleteRaw(requestParameters: BranchDeleteRequest): Promise<runtime.ApiResponse<BranchRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling branchDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an branch
     * Delete an branch
     */
    branchDelete = async(requestParameters: BranchDeleteRequest): Promise<BranchRemoveSuccessModel> => {
        const response = await this.branchDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an branch
     * retrieve a list of historical logs for an branch
     */
    async branchHistoryListRaw(requestParameters: BranchHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling branchHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling branchHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an branch
     * retrieve a list of historical logs for an branch
     */
    branchHistoryList = async(requestParameters: BranchHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.branchHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all branches
     * List all branches
     */
    async branchListRaw(requestParameters: BranchListRequest): Promise<runtime.ApiResponse<BranchListResultModel>> {
        if (requestParameters.branchRetrieveListModel === null || requestParameters.branchRetrieveListModel === undefined) {
            throw new runtime.RequiredError('branchRetrieveListModel','Required parameter requestParameters.branchRetrieveListModel was null or undefined when calling branchList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BranchRetrieveListModelToJSON(requestParameters.branchRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchListResultModelFromJSON(jsonValue));
    }

    /**
     * List all branches
     * List all branches
     */
    branchList = async(requestParameters: BranchListRequest): Promise<BranchListResultModel> => {
        const response = await this.branchListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a branch by its id
     * Retrieve an branch by its id
     */
    async branchRetrieveRaw(requestParameters: BranchRetrieveRequest): Promise<runtime.ApiResponse<BranchModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling branchRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a branch by its id
     * Retrieve an branch by its id
     */
    branchRetrieve = async(requestParameters: BranchRetrieveRequest): Promise<BranchModel> => {
        const response = await this.branchRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an branch
     * Update an branch
     */
    async branchUpdateRaw(requestParameters: BranchUpdateRequest): Promise<runtime.ApiResponse<BranchCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling branchUpdate.');
        }

        if (requestParameters.branchModel === null || requestParameters.branchModel === undefined) {
            throw new runtime.RequiredError('branchModel','Required parameter requestParameters.branchModel was null or undefined when calling branchUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/branch/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BranchModelToJSON(requestParameters.branchModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BranchCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an branch
     * Update an branch
     */
    branchUpdate = async(requestParameters: BranchUpdateRequest): Promise<BranchCreateSuccessModel> => {
        const response = await this.branchUpdateRaw(requestParameters);
        return await response.value();
    }

}
