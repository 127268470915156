/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceAuthenticateModelInterface {
    user_id?: number | null;
}

/**
 * 
 * @export
 * @interface InstanceAuthenticateModel
 */
export class InstanceAuthenticateModel extends BaseModel<InstanceAuthenticateModelInterface> {

    /**
     * Id of the user to sign in
     * @type {number}
     * @memberof InstanceAuthenticateModel
     */
    user_id?: number | null;

    constructor(data?: InstanceAuthenticateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceAuthenticateModel, value: StringDateInterface<InstanceAuthenticateModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceAuthenticateModelFromJSON(json: any): InstanceAuthenticateModel {
    return InstanceAuthenticateModelFromJSONTyped(json, false);
}

export function InstanceAuthenticateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceAuthenticateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceAuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./InstanceAuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'user_id': !exists(json, 'user_id') ? undefined : Number(json['user_id']),
    });
}

export function InstanceAuthenticateModelToJSON(value?: InstanceAuthenticateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceAuthenticateModel;
    try {
        let localClass = require.context('../override', true)('./InstanceAuthenticateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'user_id': value.user_id,
    } as StringDateInterface<InstanceAuthenticateModel>;
    return createClass.toJson(value, model);
}


