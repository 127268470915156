import React, { PropsWithChildren } from 'react';
import BaseDialogActions, { BaseDialogActionsProps } from 'components/base/BaseDialogActions';

export interface ConfirmDialogActionsProps extends BaseDialogActionsProps {
    onModalClosePress: () => void;
    onConfirm: () => void;
}

const ConfirmDialogActions: React.FC<ConfirmDialogActionsProps> = (props: PropsWithChildren<ConfirmDialogActionsProps>) => {

    const {children, onModalClosePress, onConfirm} = props

    return (
        <BaseDialogActions isYesNo onModalClosePress={onModalClosePress} onConfirm={onConfirm}>
            {children}
        </BaseDialogActions>
    );
};

export default ConfirmDialogActions;