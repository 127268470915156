import i18next from "i18next";
import * as Yup from "yup";

export const retrieveServiceSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    url: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .doesntMatterMuch(),
    image_id: Yup.number().typeError(i18next.t('global.validation_schema.select_valid_dropdown_option'))
        .required(i18next.t('global.validation_schema.required')),
});