import ActionTypes from "config/ActionTypes";
import AppState from "./interface/AppState";
import BaseReducerAction from "./interface/ReducerAction";
import { LogError } from "utils/Log";
import FetchMap from "models/FetchMap";


const initialState: AppState = {
    theme: 'light',
    lang: localStorage.getItem("i18nextLng"),
    error: new Map<ActionTypes, any[]>(),
    isFetchingData: new FetchMap<ActionTypes>()
};

export function AppReducers(state = initialState, action: BaseReducerAction): AppState {
    let newState = state;
    let fetchingAction: string = action.type.replace('_ERROR', '').replace('_FAILURE', '').replace('_SUCCESS', '');
    if(action.type.includes('API')) {
        newState = {
            ...newState,
            isFetchingData: newState.isFetchingData.set(ActionTypes[fetchingAction], !(action.type.includes('_ERROR') || action.type.includes('_FAILURE') || action.type.includes('_SUCCESS')))
        }
    }

    if(action.type.includes('FAILURE') || action.type.includes('ERROR')) {
        if(newState.error.has(action.type)) {
            newState = {
                ...newState, 
                error: newState.error.set(action.type, newState.error.get(action.type).concat(action))
            }
        } else {
            newState = {
                ...newState, 
                error: newState.error.set(action.type, [action])
            }
        }
        if(action.type !== ActionTypes.API_CALL_FAILURE) { // removes duplicaiton
            LogError(action.type, action.params);
        }
    }

    switch (action.type) {
        case ActionTypes.CHANGED_THEME:
            return { ...newState, theme: action.params };
        default:
            return newState;
    }
}