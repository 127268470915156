/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    WidgetFieldModel,
    WidgetFieldModelFromJSON,
    WidgetFieldModelFromJSONTyped,
    WidgetFieldModelToJSON,
} from './';


export interface WidgetModelInterface {
    id?: number;
    name?: string;
    icon?: string;
    fields?: Array<WidgetFieldModel>;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface WidgetModel
 */
export class WidgetModel extends BaseModel<WidgetModelInterface> {

    /**
     * id of the widget
     * @type {number}
     * @memberof WidgetModel
     */
    id?: number;
    /**
     * The name of the widget
     * @type {string}
     * @memberof WidgetModel
     */
    name?: string;
    /**
     * The icon of the widget for the stepview builder
     * @type {string}
     * @memberof WidgetModel
     */
    icon?: string;
    /**
     * The fields that can be filled in for this widget
     * @type {Array<WidgetFieldModel>}
     * @memberof WidgetModel
     */
    fields?: Array<WidgetFieldModel>;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof WidgetModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof WidgetModel
     */
    updated_at?: Date;

    constructor(data?: WidgetModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: WidgetModel, value: StringDateInterface<WidgetModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function WidgetModelFromJSON(json: any): WidgetModel {
    return WidgetModelFromJSONTyped(json, false);
}

export function WidgetModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = WidgetModel;
    try {
        let localClass = require.context('../override', true)('./WidgetModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(WidgetFieldModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function WidgetModelToJSON(value?: WidgetModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = WidgetModel;
    try {
        let localClass = require.context('../override', true)('./WidgetModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'icon': value.icon,
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(WidgetFieldModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<WidgetModel>;
    return createClass.toJson(value, model);
}


