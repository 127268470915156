import { Button } from '@mui/material';
import ActionTypes from 'config/ActionTypes';
import i18next from 'i18next';
import { SnackbarKey } from 'notistack';
import React, { Fragment} from 'react';
import {WithTranslation} from "react-i18next";
import { connect } from 'react-redux';
import {RouteProps} from 'react-router-dom';
import ApiState from 'reducers/interface/ApiState';
import { ErrorInterface, SnackbarMessageInterface } from 'reducers/types/App';
import NotificationCenter from 'services/NotificationCenter';
import { RootState } from 'store/AppStore';
import EnvUtil from 'utils/EnvUtil';
import TokenUtil from 'utils/TokenUtil';
import BaseComponent, { ViewProps as BaseProps } from './BaseComponent';
import { ProviderContext } from 'notistack';

interface BasePageProps {
  api: ApiState;
  signOut: () => void;
}

export type ViewProps = BasePageProps & BaseProps & WithTranslation & RouteProps & ProviderContext;

export default abstract class BasePageComponent<P extends ViewProps, S = {}, SS = {}> extends BaseComponent<P & ViewProps, S, SS> {

	failuresToListenFor: ActionTypes[] = []

	log = (...logItems: any[]) => {
		if (process.env.NODE_ENV === "development") {
			console.log(this.constructor.name, ...logItems);
		}
	}

	componentDidMount() {
        super.componentDidMount();
        if(this.shouldSignOut()){
            this.props.signOut()
        }
	}

    registerNotificationCenter() {
        super.registerNotificationCenter()
        NotificationCenter.default.addListeners(
            this,
            this.failuresToListenFor,
            (action) => this.onFailure(action as ErrorInterface)
        )
        NotificationCenter.default.addListener(
            this, ActionTypes.SNACKBAR_MESSAGE, this.displaySnackbar
        )
    }

    displaySnackbar = (action) => {
        const snackbar = action as SnackbarMessageInterface;
        this.props.enqueueSnackbar(
            snackbar.params.message, {action: this.dismissSnackbar, ...snackbar.params.props}
        );
    }

	dismissSnackbar = (key: SnackbarKey) => (
        <Fragment>
            <Button onClick={() => { this.props.closeSnackbar(key) }} style={{color: 'white'}}>
                {i18next.t('global.button.dismiss')}
            </Button>
        </Fragment>
    )

    onFailure = async(error: ErrorInterface) => {
        const json = await error.params['clone']?.()['json']?.();
        (json?.errors ?? []).forEach((error: string) => {
            this.props.enqueueSnackbar(error, {anchorOrigin: {vertical: 'bottom', horizontal: 'center'}, variant: 'error', persist: true, action: this.dismissSnackbar})
        })
    }

	setFailuresToListenTo(actions: ActionTypes[]) {
		this.failuresToListenFor = actions
        this.registerNotificationCenter()
	}

    shouldSignOut = (): boolean => {
        return TokenUtil.getTokenUtil().isTokenExpired()
    }

	onApiFailure() {
        throw new Error('View has to override this function');
    }

    updateTabTitle(tabName: string) {
        document.title = `${tabName} ${EnvUtil.isDevelopment() ? '🔥' : '|'} BackOffice${EnvUtil.isStage() ? ' Stage' : ''}`
    }
    
}

export function componentConnect(stateToPropsFn: (state: RootState) => any, dispatchFn: (fn: Function) => Record<string, Function>, comp: any) {
    return connect((state: RootState) => ({
        api: state.api,
        ...stateToPropsFn(state)
    }), (dispatch: Function) => {
        return {
            signOut: () => dispatch({type: "API_SIGN_OUT_ALWAYS"}), 
            ...dispatchFn(dispatch),
        }
    })(comp)
}
