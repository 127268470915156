import Condition from "api/override/ConditionModel";
import SingleSelect from "components/form_controls/SingleSelect";
import ModalInfoComponent from "components/ModalInfoComponent";
import { FormikProps } from "formik";
import ConditionalProperty from "interface/ConditionalProperty";
import MultiSelectOption from "interface/MultiSelectOption";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SettingManager from "utils/SettingManager";


interface BranchConditionFormProps {
    formik: FormikProps<Condition>
}

const BranchConditionForm = (props: BranchConditionFormProps) => {
    
    const { t } = useTranslation()
    const { formik } = props
    const [clientProps, setClientProps] = useState<Array<ConditionalProperty>>([])
    const [instProps, setInstProps] = useState<Array<ConditionalProperty>>([])
    
    const conditionModels: Array<MultiSelectOption> = [
        {label: t('instance.model'), value: 'Instance'},
        {label: t('clients.model'), value: 'Client'},
    ]
    
    const getConditionOnOptions = (model: string): Array<MultiSelectOption> => {
        if (model === 'Instance') {
            return instProps.map((instProp: ConditionalProperty) => {return {label: instProp.prop, value: instProp.prop}})
        } else if (model === 'Client') {
            return clientProps.map((clientProp: ConditionalProperty) => {return {label: clientProp.prop, value: clientProp.prop}})
        } else {
            return []
        }
    }

    const handleComponentDidMount = () => {
        setInstProps(SettingManager.default.getConditionalInstanceModelProperties().getJsonValue() as Array<ConditionalProperty>)
        setClientProps(SettingManager.default.getConditionalClientModelProperties().getJsonValue() as Array<ConditionalProperty>)
    }

    useEffect(handleComponentDidMount, [])

    return (
        <>
            <ModalInfoComponent 
                variant='info' 
                message={t('conditions.create_info_message')}/>
                {/* Select the model and the property which belongs to the model to indicate what is going to be responsible for determining which branch of the wizard the user follows */}

            <SingleSelect 
                id="model" 
                name="model"
                label={t('conditions.model')}
                options={conditionModels}
                value={conditionModels.find(condition => condition.value === formik.values?.model)}
                onChange={formik.handleChange}
                formikError={formik.touched.model && Boolean(formik.errors.model)}
            />

            <SingleSelect 
                id="condition_on" 
                name="condition_on"
                label={t('conditions.condition_on')}
                options={getConditionOnOptions(formik.values.model)}
                value={getConditionOnOptions(formik.values.model).find(condition => condition.value === formik.values?.condition_on)}
                onChange={formik.handleChange}
                formikError={formik.touched.condition_on && Boolean(formik.errors.condition_on)}
                disabled={formik.values.model == null}
            />
        </>
    )
}


export default BranchConditionForm