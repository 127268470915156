import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { WidgetHistoryListRequest } from "api/apis/WidgetApi";
import { WidgetCreateSuccessModel } from "api/models/WidgetCreateSuccessModel";
import { WidgetListResultModel } from "api/models/WidgetListResultModel";
import { WidgetRetrieveListModel } from "api/models/WidgetRetrieveListModel";
import Widget from "api/override/WidgetModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface WidgetList extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_LIST;
    params: WidgetRetrieveListModel;
}

export interface WidgetListSuccess extends BaseSagaListResponse<WidgetListResultModel> {
    type: ActionTypes.API_WIDGET_LIST_SUCCESS;
}

export interface WidgetCreate extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_CREATE;
    params: Widget;
}

export interface WidgetCreateSuccess extends BaseSagaListResponse<WidgetCreateSuccessModel> {
    type: ActionTypes.API_WIDGET_CREATE_SUCCESS;
}

export interface WidgetUpdateParams {
    id: number;
    widgetModel: Widget;
}

export interface WidgetUpdate extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_UPDATE;
    params: WidgetUpdateParams;
}

export interface WidgetUpdateSuccess extends BaseSagaListResponse<WidgetCreateSuccessModel> {
    type: ActionTypes.API_WIDGET_UPDATE_SUCCESS;
}

export interface WidgetRetrieve extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_RETRIEVE;
    params: number;
}

export interface WidgetRetrieveSuccess extends BaseSagaListResponse<Widget> {
    type: ActionTypes.API_WIDGET_RETRIEVE_SUCCESS
}

export interface WidgetDelete extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_DELETE;
    params: number;
}

export interface WidgetDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_WIDGET_DELETE_SUCCESS;
}

export interface WidgetHistoryList extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_HISTORY_LIST;
    params: WidgetHistoryListRequest;
}

export interface WidgetHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_WIDGET_HISTORY_LIST_SUCCESS;
}

export interface WidgetClearListParams {
    force?: boolean;
}
export interface WidgetClearList extends BaseReducerAction {
    type: ActionTypes.API_WIDGET_LIST_CLEAR;
    params?: WidgetClearListParams;
}

export default class WidgetActions {
    
    static CreateWidgetList(params: WidgetRetrieveListModel): WidgetList {
        return {
            type: ActionTypes.API_WIDGET_LIST,
            params: params
        }
    }

    static CreateWidgetListSuccess(params: WidgetListResultModel): WidgetListSuccess {
        return {
            type: ActionTypes.API_WIDGET_LIST_SUCCESS,
            params: params
        }
    }

    static CreateWidgetCreate(params: Widget): WidgetCreate {
        return {
            type: ActionTypes.API_WIDGET_CREATE,
            params: params
        }
    }

    static CreateWidgetCreateSuccess(params: WidgetCreateSuccessModel): WidgetCreateSuccess {
        return {
            type: ActionTypes.API_WIDGET_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateWidgetUpdate(params: Widget): WidgetUpdate {
        return {
            type: ActionTypes.API_WIDGET_UPDATE,
            params: {
                id: params.id,
                widgetModel: params
            }
        }
    }

    static CreateWidgetUpdateSuccess(params: WidgetCreateSuccessModel): WidgetUpdateSuccess {
        return {
            type: ActionTypes.API_WIDGET_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateWidgetRetrieve(params: number): WidgetRetrieve {
        return {
            type: ActionTypes.API_WIDGET_RETRIEVE,
            params: params
        }
    }

    static CreateWidgetRetrieveSuccess(params: Widget): WidgetRetrieveSuccess {
        return {
            type: ActionTypes.API_WIDGET_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateWidgetDelete(params: number): WidgetDelete {
        return {
            type: ActionTypes.API_WIDGET_DELETE,
            params: params
        }
    }

    static CreateWidgetDeleteSuccess(params: ApiSuccessResponseModel): WidgetDeleteSuccess {
        return {
            type: ActionTypes.API_WIDGET_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateWidgetHistoryList(params: WidgetHistoryListRequest): WidgetHistoryList {
        return {
            type: ActionTypes.API_WIDGET_HISTORY_LIST,
            params: params
        }
    }

    static CreateWidgetHistoryListSuccess(params: HistoryResponseModel): WidgetHistoryListSuccess {
        return {
            type: ActionTypes.API_WIDGET_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearWidgetList(force: boolean = false): WidgetClearList {
        return {
            type: ActionTypes.API_WIDGET_LIST_CLEAR,
            params: {force: force}
        }   
    }

}