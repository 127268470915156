/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface TransferVerifyRequestModelInterface {
    database_from?: string;
    server_to?: string;
    database_to?: string;
    preserve_existing?: boolean;
}

/**
 * 
 * @export
 * @interface TransferVerifyRequestModel
 */
export class TransferVerifyRequestModel extends BaseModel<TransferVerifyRequestModelInterface> {

    /**
     * Database that needs to be copied
     * @type {string}
     * @memberof TransferVerifyRequestModel
     */
    database_from?: string;
    /**
     * Server to transfer to
     * @type {string}
     * @memberof TransferVerifyRequestModel
     */
    server_to?: string;
    /**
     * Database name to transfer to
     * @type {string}
     * @memberof TransferVerifyRequestModel
     */
    database_to?: string;
    /**
     * Preserve the original 3 users of the database being transferred to
     * @type {boolean}
     * @memberof TransferVerifyRequestModel
     */
    preserve_existing?: boolean;

    constructor(data?: TransferVerifyRequestModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: TransferVerifyRequestModel, value: StringDateInterface<TransferVerifyRequestModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function TransferVerifyRequestModelFromJSON(json: any): TransferVerifyRequestModel {
    return TransferVerifyRequestModelFromJSONTyped(json, false);
}

export function TransferVerifyRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferVerifyRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = TransferVerifyRequestModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyRequestModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'database_from': !exists(json, 'database_from') ? undefined : json['database_from'],
        'server_to': !exists(json, 'server_to') ? undefined : json['server_to'],
        'database_to': !exists(json, 'database_to') ? undefined : json['database_to'],
        'preserve_existing': !exists(json, 'preserve_existing') ? undefined : json['preserve_existing'],
    });
}

export function TransferVerifyRequestModelToJSON(value?: TransferVerifyRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = TransferVerifyRequestModel;
    try {
        let localClass = require.context('../override', true)('./TransferVerifyRequestModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'database_from': value.database_from,
        'server_to': value.server_to,
        'database_to': value.database_to,
        'preserve_existing': value.preserve_existing,
    } as StringDateInterface<TransferVerifyRequestModel>;
    return createClass.toJson(value, model);
}


