/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface UserListFilterModelInterface {
    include_removed?: boolean;
    user_role?: Array<string>;
    active?: boolean;
}

/**
 * 
 * @export
 * @interface UserListFilterModel
 */
export class UserListFilterModel extends BaseModel<UserListFilterModelInterface> {

    /**
     * Include removed users
     * @type {boolean}
     * @memberof UserListFilterModel
     */
    include_removed?: boolean;
    /**
     * Array containing Roles
     * @type {Array<string>}
     * @memberof UserListFilterModel
     */
    user_role?: Array<string>;
    /**
     * Boolean active
     * @type {boolean}
     * @memberof UserListFilterModel
     */
    active?: boolean;

    constructor(data?: UserListFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: UserListFilterModel, value: StringDateInterface<UserListFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function UserListFilterModelFromJSON(json: any): UserListFilterModel {
    return UserListFilterModelFromJSONTyped(json, false);
}

export function UserListFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = UserListFilterModel;
    try {
        let localClass = require.context('../override', true)('./UserListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'include_removed': !exists(json, 'include_removed') ? undefined : json['include_removed'],
        'user_role': !exists(json, 'user_role') ? undefined : json['user_role'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    });
}

export function UserListFilterModelToJSON(value?: UserListFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = UserListFilterModel;
    try {
        let localClass = require.context('../override', true)('./UserListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'include_removed': value.include_removed,
        'user_role': value.user_role,
        'active': value.active,
    } as StringDateInterface<UserListFilterModel>;
    return createClass.toJson(value, model);
}


