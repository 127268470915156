import React from 'react';
import HttpIcon from '@mui/icons-material/Http';
import i18next from "i18next";
import CustomActionButton from "models/table/CustomActionButton";
import ConnectorContainer from 'api/override/ConnectorContainerModel';
import { IconButton } from '@mui/material';

export default class ConnectorContainerURLButton extends CustomActionButton {

    constructor() {
        super(
            'cc_redirect', 
            i18next.t('connector_container.view.cc_redirect_tooltip'), 
            'cc_redirect'
        )
    }

    render(row?: ConnectorContainer): JSX.Element {
        const url: string = row.getFullUrl()
        return (
            <a href={url} target='_blank' rel='noopener noreferrer'>
                <IconButton size='small'>
                    <HttpIcon color='primary'/>
                </IconButton>
            </a>
        )
    }

}