import i18next from "i18next";
import * as Yup from "yup";

export default class YupUtil {

    registerExtraTypes = () => {
        Yup.addMethod<Yup.StringSchema>(Yup.string, 'doesntMatterMuch', function() {            
            return this.test('whiteSpaceCheck', i18next.t('global.validation_schema.no_whitespace'), value => !value?.includes(' '))
        })
    }

}