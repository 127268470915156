import { ApiResponseModel, ServiceHistoryListRequest,  ServiceListResultModel, ServiceRemoveSuccessModel, ServiceRetrieveListModel,  HistoryResponseModel, ProcessListResultModel, InstanceListResultModel, ApiSuccessResponseModel, ServiceCreateSuccessModel, ServiceSecretsRetrieveRequest, ServiceSecretResponseModel } from "api";
import Service from "api/override/ServiceModel";
import ServiceSecret from "api/override/ServiceSecretModel";
import ActionTypes from "config/ActionTypes";
import Constants from "config/Constants";
import MultiSelectOption from "interface/MultiSelectOption";
import { TableParams } from "models/table/TableParams";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface ServiceList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_LIST;
    params: ServiceRetrieveListModel;
}

export interface ServiceListSuccess extends BaseSagaListResponse<ServiceListResultModel> {
    type: ActionTypes.API_SERVICE_LIST_SUCCESS;
}

export interface ServiceListInstancesParams {
    uuid: string;
    tableParams: TableParams;
}
export interface ServiceListInstances extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_LIST_INSTANCES;
    params: ServiceListInstancesParams;
}

export interface ServiceListInstancesSuccess extends BaseSagaListResponse<InstanceListResultModel>{
    type: ActionTypes.API_SERVICE_LIST_INSTANCES_SUCCESS;
}

export interface ServiceRetrieveSecrets extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_RETRIEVE_SECRETS;
    params: ServiceSecretsRetrieveRequest;
}

export interface ServiceRetrieveSecretsSuccess extends BaseSagaListResponse<ServiceSecretResponseModel>{
    type: ActionTypes.API_SERVICE_RETRIEVE_SECRETS_SUCCESS;
}

export interface ServiceUpdateSecretsParams {
    uuid: string;
    serviceSecretModel: ServiceSecret;
}

export interface ServiceUpdateSecrets extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_UPDATE_SECRETS;
    params: ServiceUpdateSecretsParams;
}

export interface ServiceUpdateSecretsSuccess extends BaseSagaListResponse<ServiceSecretResponseModel> {
    type: ActionTypes.API_SERVICE_UPDATE_SECRETS_SUCCESS;
}

export interface ServiceCreate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_CREATE;
    params: Service;
}

export interface ServiceCreateSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_SERVICE_CREATE_SUCCESS;
}

export interface ServiceUpdateParams {
    uuid: string;
    serviceModel: Service;
}

export interface ServiceUpdate extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_UPDATE;
    params: ServiceUpdateParams;
}

export interface ServiceUpdateSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_SERVICE_UPDATE_SUCCESS;
}

export interface ServiceRetrieve extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_RETRIEVE;
    params: string;
}

export interface ServiceRetrieveSuccess extends BaseSagaListResponse<Service> {
    type: ActionTypes.API_SERVICE_RETRIEVE_SUCCESS
}

export interface ServiceDelete extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_DELETE;
    params: string;
}

export interface ServiceDeleteSuccess extends BaseSagaListResponse<ServiceRemoveSuccessModel> {
    type: ActionTypes.API_SERVICE_DELETE_SUCCESS;
}

export interface ServiceTriggerJobParams {
    uuid: string;
    task: string;
    uuids?: string[];
}

export interface ServiceTriggerJob extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_TRIGGER_JOB;
    params: ServiceTriggerJobParams;
}

export interface ServiceTriggerJobSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_SERVICE_TRIGGER_JOB_SUCCESS;
}
export interface ServiceLogRequest {
    uuid: string;
    tableParams: TableParams;
}
export interface ServiceLogsList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_LOGS_LIST;
    params: ServiceLogRequest;
}

export interface ServiceLogsListSuccess extends BaseSagaListResponse<ProcessListResultModel> {
    type: ActionTypes.API_SERVICE_LOGS_LIST_SUCCESS;
}

export interface ServiceHistoryList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_HISTORY_LIST;
    params: ServiceHistoryListRequest;
}

export interface ServiceHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_SERVICE_HISTORY_LIST_SUCCESS;
}

export interface ServiceClearListParams {
    force?: boolean;
}
export interface ServiceClearList extends BaseReducerAction {
    type: ActionTypes.API_SERVICE_LIST_CLEAR;
    params?: ServiceClearListParams;
}

export default class ServiceActions {
    
    static CreateServiceList(params: ServiceRetrieveListModel): ServiceList {
        return {
            type: ActionTypes.API_SERVICE_LIST,
            params: params
        }
    }

    static CreateServiceListSuccess(params: ServiceListResultModel): ServiceListSuccess {
        return {
            type: ActionTypes.API_SERVICE_LIST_SUCCESS,
            params: params
        }
    }

    static CreateServiceListInstances(params: ServiceListInstancesParams): ServiceListInstances {
        return {
            type: ActionTypes.API_SERVICE_LIST_INSTANCES,
            params: params
        }
    }

    static CreateServiceListInstancesSuccess(params: InstanceListResultModel): ServiceListInstancesSuccess {
        return {
            type: ActionTypes.API_SERVICE_LIST_INSTANCES_SUCCESS,
            params: params
        }
    }

    static CreateServiceRetrieveSecrets(params: ServiceSecretsRetrieveRequest): ServiceRetrieveSecrets {
        return {
            type: ActionTypes.API_SERVICE_RETRIEVE_SECRETS,
            params: params
        }
    }

    static CreateServiceRetrieveSecretsSuccess(params: InstanceListResultModel): ServiceRetrieveSecretsSuccess {
        return {
            type: ActionTypes.API_SERVICE_RETRIEVE_SECRETS_SUCCESS,
            params: params
        }
    }

    static CreateServiceUpdateSecrets(params: ServiceUpdateSecretsParams): ServiceUpdateSecrets {
        return {
            type: ActionTypes.API_SERVICE_UPDATE_SECRETS,
            params: {
                uuid: params.uuid,
                serviceSecretModel: params.serviceSecretModel
            }
        }
    }

    static CreateServiceUpdateSecretsSuccess(params: InstanceListResultModel): ServiceUpdateSecretsSuccess {
        return {
            type: ActionTypes.API_SERVICE_UPDATE_SECRETS_SUCCESS,
            params: params
        }
    }

    static CreateServiceCreate(params: Service): ServiceCreate {
        params.databases = JSON.stringify(params.json_databases.map((db: MultiSelectOption) => db.value));
        params.volumes = params.standalone ? Constants.SERVICE_VOLUMES : params.volumeModel.toJson();
        return {
            type: ActionTypes.API_SERVICE_CREATE,
            params: params
        }
    }

    static CreateServiceCreateSuccess(params: ServiceCreateSuccessModel): ServiceCreateSuccess {
        return {
            type: ActionTypes.API_SERVICE_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceUpdate(params: Service): ServiceUpdate {
        params.databases = JSON.stringify(params.json_databases.map((db: MultiSelectOption) => db.value));
        params.volumes = params.standalone ? Constants.SERVICE_VOLUMES : params.volumeModel.toJson();
        return {
            type: ActionTypes.API_SERVICE_UPDATE,
            params: {
                uuid: params.uuid,
                serviceModel: params
            }
        }
    }

    static CreateServiceUpdateSuccess(params: ServiceCreateSuccessModel): ServiceUpdateSuccess {
        return {
            type: ActionTypes.API_SERVICE_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateServiceRetrieve(params: string): ServiceRetrieve {
        return {
            type: ActionTypes.API_SERVICE_RETRIEVE,
            params: params
        }
    }

    static CreateServiceRetrieveSuccess(params: Service): ServiceRetrieveSuccess {
        return {
            type: ActionTypes.API_SERVICE_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateServiceDelete(params: string): ServiceDelete {
        return {
            type: ActionTypes.API_SERVICE_DELETE,
            params: params
        }
    }

    static CreateServiceDeleteSuccess(params: ServiceRemoveSuccessModel): ServiceDeleteSuccess {
        return {
            type: ActionTypes.API_SERVICE_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateServiceTriggerJob(params: ServiceTriggerJobParams): ServiceTriggerJob {
        return {
            type: ActionTypes.API_SERVICE_TRIGGER_JOB,
            params: params
        }
    }

    static CreateServiceTriggerJobSuccess(params: ApiResponseModel): ServiceTriggerJobSuccess {
        return {
            type: ActionTypes.API_SERVICE_TRIGGER_JOB_SUCCESS,
            params: params
        }
    }

    static CreateClearInstances(): BaseReducerAction {
        return {
            type: ActionTypes.SERVICE_VIEW_CLEAR_INSTANCES
        }
    }

    static CreateServiceLogsList(params: ServiceLogRequest): ServiceLogsList {
        return {
            type: ActionTypes.API_SERVICE_LOGS_LIST,
            params: params
        }
    }

    static CreateServiceLogsListSuccess(params: ProcessListResultModel): ServiceLogsListSuccess {
        return {
            type: ActionTypes.API_SERVICE_LOGS_LIST_SUCCESS,
            params: params
        }
    }

    static CreateServiceHistoryList(params: ServiceHistoryListRequest): ServiceHistoryList {
        return {
            type: ActionTypes.API_SERVICE_HISTORY_LIST,
            params: params
        }
    }

    static CreateServiceHistoryListSuccess(params: HistoryResponseModel): ServiceHistoryListSuccess {
        return {
            type: ActionTypes.API_SERVICE_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearServiceList(force: boolean = false): ServiceClearList {
        return {
            type: ActionTypes.API_SERVICE_LIST_CLEAR,
            params: {force: force}
        }   
    }
}