import React from 'react';
import { PlusCircleOutline } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import BaseWidget, { BaseWidgetProps } from "./BaseWidget";
import WidgetRowView from './WidgetRowView';
import QuickAction from 'interface/widgets/QuickAction';
import useWidgetStyles from 'styles/WidgetStyles';
import WidgetUtil from 'utils/WidgetUtil';
import IconButtonStyled from 'components/styled/IconButtonStyled';
import Pages from 'utils/Pages';

interface QuickActionsWidgetProps extends BaseWidgetProps {
}

const QuickActionsWidget = (props: QuickActionsWidgetProps) => {
 
    const {classes} = useWidgetStyles();
    const { t } = useTranslation();
    const quickActions: Array<QuickAction> = [
        {entity: t('instance.model'), url: '/instances/create'},
        {entity: t('users.model'), url: '/users/create'},
        {entity: t('connector.model'), url: '/connectors/create'},
        {entity: t('service.model'), url: `/${Pages.services}/create`},
        {entity: t('image.model'), url: '/image/create'},
        {entity: t('clients.model'), url: '/clients/create'},
        {entity: t('properties.model'), url: '/properties/create'},
        {entity: t('settings.model'), url: '/settings/create'},
    ];

    return (
        <BaseWidget {...props}>
            <WidgetRowView isHeader>
                <h4>{t('global.button.create')}</h4>
            </WidgetRowView>
            {quickActions.map((action: QuickAction) => {
                return (
                    <WidgetRowView key={`quickActions_${action.url}`}>
                        <p className={classes.paragraph_with_icon}>{WidgetUtil.getIconForEntity(action.url.split('/')[1])}{action.entity}</p>
                        <IconButtonStyled url={action.url} style={{height: 30, width: 30}}>
                            <PlusCircleOutline/>
                        </IconButtonStyled>
                    </WidgetRowView>
                )
            })}
        </BaseWidget>
    );
};

export default QuickActionsWidget;