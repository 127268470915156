import React, { PropsWithChildren } from 'react';
import EnvWrapper from './EnvWrapper';

interface HideOnProdProps {
    hide404?: boolean;
}

const HideOnProd = (props: PropsWithChildren<HideOnProdProps>) => {
    return ( <EnvWrapper hide404={props.hide404} hideOnProd> {props.children} </EnvWrapper> )
}

export default HideOnProd;