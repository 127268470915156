import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useFormCardStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            height: 200,
            width: '48%',
            background: '#313131',
            color: '#fff',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 4,
            cursor: 'pointer',
            '&:hover': {
                '&.wp': {
                    background: 'linear-gradient(153deg, rgba(98,132,255,1) 0%, rgba(129,98,255,1) 100%, rgba(0,212,255,1) 100%)'
                },
                '&.onboarding': {
                    background: 'linear-gradient(153deg, rgba(186,98,255,1) 0%, rgba(167,98,255,1) 100%, rgba(0,212,255,1) 100%)'
                }
            },
            '& h2': {
                margin: 0,
                fontSize: 26
            },
            '& svg': {
                height: 50,
                width: 50
            }
        }
    }
})

export default useFormCardStyles
