import { MailLogListResultModel, MailLogRetrieveListModel } from "api";
import MailLog from "api/override/MailLogModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface MailLogList extends BaseReducerAction {
    type: ActionTypes.API_MAIL_LOG_LIST;
    params: MailLogRetrieveListModel;
}

export interface MailLogListSuccess extends BaseSagaListResponse<MailLogListResultModel> {
    type: ActionTypes.API_MAIL_LOG_LIST_SUCCESS;
}

export interface MailLogRetrieve extends BaseReducerAction {
    type: ActionTypes.API_MAIL_LOG_RETRIEVE;
    params: number;
}

export interface MailLogRetrieveSuccess extends BaseSagaListResponse<MailLog> {
    type: ActionTypes.API_MAIL_LOG_RETRIEVE_SUCCESS
}

export interface MailLogClearListParams {
    force?: boolean;
}
export interface MailLogClearList extends BaseReducerAction {
    type: ActionTypes.API_MAIL_LOG_LIST_CLEAR;
    params?: MailLogClearListParams;
}

export default class MailLogActions {
    
    static CreateMailLogList(params: MailLogRetrieveListModel): MailLogList {
        return {
            type: ActionTypes.API_MAIL_LOG_LIST,
            params: params
        }
    }

    static CreateMailLogListSuccess(params: MailLogListResultModel): MailLogListSuccess {
        return {
            type: ActionTypes.API_MAIL_LOG_LIST_SUCCESS,
            params: params
        }
    }

    static CreateMailLogRetrieve(params: number): MailLogRetrieve {
        return {
            type: ActionTypes.API_MAIL_LOG_RETRIEVE,
            params: params
        }
    }

    static CreateMailLogRetrieveSuccess(params: MailLog): MailLogRetrieveSuccess {
        return {
            type: ActionTypes.API_MAIL_LOG_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateClearMailLogList(force: boolean = false): MailLogClearList {
        return {
            type: ActionTypes.API_MAIL_LOG_LIST_CLEAR,
            params: {force: force}
        }   
    }

}