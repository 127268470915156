import { ConditionModel } from "api/models/ConditionModel";
import Historiable from "interface/Historiable";
import MultiSelectOption from "interface/MultiSelectOption";

export default class Condition extends ConditionModel implements Historiable {
    actionName: string = "condition"
    reducerName: string = "conditions"

    getMultiSelectOptions(): Array<MultiSelectOption> {
        const multiSelectOptions: Array<MultiSelectOption> = this.option.options.map((option: string) => {return {label: option, value: option}})
        return multiSelectOptions;
    }
}