/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    ImageVersionCreateSuccessModel,
    ImageVersionCreateSuccessModelFromJSON,
    ImageVersionCreateSuccessModelToJSON,
    ImageVersionListResultModel,
    ImageVersionListResultModelFromJSON,
    ImageVersionListResultModelToJSON,
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelToJSON,
    ImageVersionRemoveSuccessModel,
    ImageVersionRemoveSuccessModelFromJSON,
    ImageVersionRemoveSuccessModelToJSON,
    ImageVersionRetrieveListModel,
    ImageVersionRetrieveListModelFromJSON,
    ImageVersionRetrieveListModelToJSON,
} from '../models';

export interface ImageVersionCreateRequest {
    imageVersionModel: ImageVersionModel;
}

export interface ImageVersionDeleteRequest {
    id: number;
}

export interface ImageVersionHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ImageVersionListRequest {
    imageVersionRetrieveListModel: ImageVersionRetrieveListModel;
}

export interface ImageVersionRetrieveRequest {
    id: number;
}

export interface ImageVersionUpdateRequest {
    id: number;
    imageVersionModel: ImageVersionModel;
}

/**
 * 
 */
export class ImageVersionApi extends runtime.BaseAPI {

    /**
     * Create a new image version
     * Create a new image version
     */
    async imageVersionCreateRaw(requestParameters: ImageVersionCreateRequest): Promise<runtime.ApiResponse<ImageVersionCreateSuccessModel>> {
        if (requestParameters.imageVersionModel === null || requestParameters.imageVersionModel === undefined) {
            throw new runtime.RequiredError('imageVersionModel','Required parameter requestParameters.imageVersionModel was null or undefined when calling imageVersionCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImageVersionModelToJSON(requestParameters.imageVersionModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageVersionCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new image version
     * Create a new image version
     */
    imageVersionCreate = async(requestParameters: ImageVersionCreateRequest): Promise<ImageVersionCreateSuccessModel> => {
        const response = await this.imageVersionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an image version
     * Delete an image version
     */
    async imageVersionDeleteRaw(requestParameters: ImageVersionDeleteRequest): Promise<runtime.ApiResponse<ImageVersionRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageVersionDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageVersionRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an image version
     * Delete an image version
     */
    imageVersionDelete = async(requestParameters: ImageVersionDeleteRequest): Promise<ImageVersionRemoveSuccessModel> => {
        const response = await this.imageVersionDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an image version
     * retrieve a list of historical logs for an image version
     */
    async imageVersionHistoryListRaw(requestParameters: ImageVersionHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageVersionHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling imageVersionHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an image version
     * retrieve a list of historical logs for an image version
     */
    imageVersionHistoryList = async(requestParameters: ImageVersionHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.imageVersionHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all image versions
     * List all image versions
     */
    async imageVersionListRaw(requestParameters: ImageVersionListRequest): Promise<runtime.ApiResponse<ImageVersionListResultModel>> {
        if (requestParameters.imageVersionRetrieveListModel === null || requestParameters.imageVersionRetrieveListModel === undefined) {
            throw new runtime.RequiredError('imageVersionRetrieveListModel','Required parameter requestParameters.imageVersionRetrieveListModel was null or undefined when calling imageVersionList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImageVersionRetrieveListModelToJSON(requestParameters.imageVersionRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageVersionListResultModelFromJSON(jsonValue));
    }

    /**
     * List all image versions
     * List all image versions
     */
    imageVersionList = async(requestParameters: ImageVersionListRequest): Promise<ImageVersionListResultModel> => {
        const response = await this.imageVersionListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a image version by its id
     * Retrieve an image version by its id
     */
    async imageVersionRetrieveRaw(requestParameters: ImageVersionRetrieveRequest): Promise<runtime.ApiResponse<ImageVersionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageVersionRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageVersionModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a image version by its id
     * Retrieve an image version by its id
     */
    imageVersionRetrieve = async(requestParameters: ImageVersionRetrieveRequest): Promise<ImageVersionModel> => {
        const response = await this.imageVersionRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an image version
     * Update an image version
     */
    async imageVersionUpdateRaw(requestParameters: ImageVersionUpdateRequest): Promise<runtime.ApiResponse<ImageVersionCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageVersionUpdate.');
        }

        if (requestParameters.imageVersionModel === null || requestParameters.imageVersionModel === undefined) {
            throw new runtime.RequiredError('imageVersionModel','Required parameter requestParameters.imageVersionModel was null or undefined when calling imageVersionUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImageVersionModelToJSON(requestParameters.imageVersionModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageVersionCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an image version
     * Update an image version
     */
    imageVersionUpdate = async(requestParameters: ImageVersionUpdateRequest): Promise<ImageVersionCreateSuccessModel> => {
        const response = await this.imageVersionUpdateRaw(requestParameters);
        return await response.value();
    }

}
