import {ImageVersionModel, ImageVersionModelInterface} from "api";
import Historiable from "interface/Historiable";
import MultiSelectOption from "interface/MultiSelectOption";

export default class ImageVersion extends ImageVersionModel implements Historiable, MultiSelectOption {

    actionName: string = "image_version"
    reducerName: string = "image_versions"

    value: number | string;
    label: string;

    parentImageName: string
    fullVersionString: string;
    
    constructor(data?: ImageVersionModelInterface) {
        super(data);
        this.parentImageName = this.image?.docker_image
        this.fullVersionString = [this.parentImageName, this.version_tag].join(':');
        this.value = this.id;
        this.label = `${this.version_name} (${this.fullVersionString})`;
    }

}