import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { SettingCreateSuccessModel, SettingHistoryListRequest } from 'api';
import TabManager, { TabView } from 'components/tabs/TabManager';
import SettingState from 'reducers/interface/SettingState';
import SettingActions from 'reducers/types/Setting';
import SettingDetails from './components/SettingDetails';
import NotificationCenter from 'services/NotificationCenter';
import SettingDetailsDialog from 'views/Dialog/SettingDetailsDialog';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import SettingCreateForm from 'views/CreationForms/SettingCreateForm';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import ReloadDataButton from 'components/ReloadDataButton';
import Setting from 'api/override/SettingModel';
import DetailsViewHeader from 'components/DetailsViewHeader';

interface StateProps {
    appReducer: AppState;
    settingReducer: SettingState;
}

interface DispatchProps {
    retrieveSetting: (params: string) => void;
    updateSetting: (params: Setting) => void;
    createSetting: (params: Setting) => void;
    deleteSetting: (params: string) => void;
    clearSettings: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    setting?: Setting;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class SettingView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            setting: null,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_SETTING_UPDATE_FAILURE, 
            ActionTypes.API_SETTING_DELETE_FAILURE, 
            ActionTypes.API_SETTING_RETRIEVE_FAILURE, 
            ActionTypes.API_SETTING_CREATE_FAILURE
        ])
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_SETTING_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SETTING_UPDATE_SUCCESS,
            this.onUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SETTING_DELETE_SUCCESS, 
            this.onDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SETTING_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({setting: action.params}, () => {
            this.updateTabTitle(this.state.setting.key)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const settingSuccess: SettingCreateSuccessModel = action.params
        this.setState({setting: new Setting(settingSuccess.model)})
        this.props.navigate(`/settings/${settingSuccess?.model?.key}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('settings.model')}), {variant: 'success'})
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('settings.model')}), {variant: 'success'})
        this.props.clearSettings(true)
        this.reloadData()
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('settings.model')}), {variant: 'success'})
        this.props.clearSettings(true)
        this.props.navigate('/settings')
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveSetting(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_SETTING_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.setting != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.props.retrieveSetting(this.props.params.slug)
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, appReducer, settingReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_SETTING_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                            {this.shouldRenderDetails() &&
                                    <div>
                                    <DetailsViewHeader
                                        model={i18next.t('settings.model')}
                                        title={this.state.setting?.key}
                                    />
                                    <TabManager
                                        items={[
                                            {
                                                title: i18next.t('general.detail'),
                                                link: `/settings/${this.state.setting?.key}`,
                                                view: (
                                                    <SettingDetails 
                                                        setting={this.state.setting} 
                                                        onDelete={this.onDelete}
                                                        onEdit={this.onEdit}
                                                    />
                                                )
                                            },{
                                                title:  i18next.t('history.model'),
                                                link: `/settings/${this.state.setting?.key}/history`,
                                                initialTab: this.isPathIncluded('history'),
                                                view: (
                                                    <HistoryTab
                                                        model={this.state.setting}
                                                    />
                                                )
                                            }
                                        ]}
                                        onTabSwitch={(newTab: TabView) => {
                                            if (newTab.link.includes('history')){
                                                if (settingReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_SETTING_HISTORY_LIST)) {
                                                    this.sendNotification({type: ActionTypes.API_SETTING_HISTORY_UPDATE}, null);
                                                }
                                            }
                                        }}
                                    />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <SettingCreateForm
                                            onFormSubmit={(setting: Setting) => {
                                                this.props.createSetting(setting);
                                            }}
                                        /> 
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                <SettingDetailsDialog
                    open={this.state.isEditModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onModalSubmit={(setting: Setting) => {
                        this.props.updateSetting(setting)
                    }}
                />

                <ConfirmDeleteDialog
                    open={this.state.isDeleteModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onConfirm={() => {
                        this.props.deleteSetting(this.state.setting?.key)
                    }}
                    title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('settings.model')).toLowerCase()})}
                />
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    settingReducer: state.settings
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveSetting: (params: string) => dispatch(SettingActions.CreateSettingRetrieve(params)),
        listSettingHistory: (params: SettingHistoryListRequest) => dispatch(SettingActions.CreateSettingHistoryList(params)),
        updateSetting: (params: Setting) => dispatch(SettingActions.CreateSettingUpdate(params)),
        createSetting: (params: Setting) => dispatch(SettingActions.CreateSettingCreate(params)),
        deleteSetting: (params: string) => dispatch(SettingActions.CreateSettingDelete(params)),
        clearSettings: (params: boolean) => dispatch(SettingActions.CreateClearSettingList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(SettingView, InstanceViewStyles)
            )
        )
    )
);