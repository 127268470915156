import { InstanceSettingsValueOptionModel, InstanceSettingsValueOptionModelInterface } from "api";
import i18next from "i18next";
import MultiSelectOption from "interface/MultiSelectOption";


export default class InstanceSettingsValueOption extends InstanceSettingsValueOptionModel implements MultiSelectOption {

    label = this.name;
    value: string = '';

    constructor(data: InstanceSettingsValueOptionModelInterface) {
        super(data);
        this.label = data.name ?? i18next.t('general.empty');
        this.value = data.value ?? '0';
    }

}