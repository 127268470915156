import React, { useState } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import Instance from 'api/override/InstanceModel';
import InstanceController from 'controllers/InstanceController';
import SingleSelect from 'components/form_controls/SingleSelect';


interface InstanceSingleSelectProps {
    instance?: Instance;
    onSelectInstance: (instance: Instance) => void;
}

function InstanceSingleSelect(props: InstanceSingleSelectProps) {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const [chosenInstance, setChosenInstance] = useState<MultiSelectOption | undefined>(props.instance);
    const [instanceController] = useState(new InstanceController());

    const setInstance = (_, val: MultiSelectOption) => {
        props.onSelectInstance( instanceController.getCachedItemByValue(val.value) as Instance );
        setChosenInstance(val);
    }

    return (
        <SingleSelect
            name={'instance'}
            label={t('instance.model')}
            value={chosenInstance}
            tableParams={params}
            getAsyncOptions={(params: TableParams) => instanceController.instanceToMultiselect(params)}
            onChange={setInstance}
        />
    )
}

export default InstanceSingleSelect;
