import React from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import { Card, CardHeader, Container, LinearProgress} from "@mui/material";
import i18next from "../../services/i18n";
import DnsIcon from '@mui/icons-material/Dns';
import LoadBalancerCard from './components/LoadBalancerCard'
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import { withSnackbar } from 'notistack';
import {LoadBalancerStatusModel} from "api";
import SystemStatusActions from 'reducers/types/SystemStatus';
import LoadBalancerPageStyles from 'styles/LoadBalancerPageStyles';
import AppState from 'reducers/interface/AppState';
import ActionTypes from 'config/ActionTypes';
import { withStyles } from 'tss-react/mui';

interface StateProps {
    appReducer: AppState;
    loadBalancerStatus: LoadBalancerStatusModel;
}

interface DispatchProps {
    getLoadBalancerStatus: () => void;
}

type Props = StateProps & DispatchProps & ViewProps

interface State{

}

class LoadBalancers extends BasePageComponent<Props, State> {

    componentDidMount() {
        super.componentDidMount();
        this.props.getLoadBalancerStatus()
    };

    renderView() {
        const { classes, appReducer } = this.props;
        return (
            <React.Fragment>
                <Container maxWidth={"lg"}>
                    <Card className={classes.root}>
                        <CardHeader
                            avatar={<DnsIcon />}
                            title={i18next.t('system_status.page_title') + " - " + i18next.t('system_status.load_balancer_title')}
                        />
                    </Card>
                    {(appReducer.isFetchingData.get(ActionTypes.API_LOAD_BALANCER_STATUS)) &&
                        <LinearProgress />
                    }
                    <div className={classes.card_container}>
                        {(this.props.loadBalancerStatus) &&
                            this.props.loadBalancerStatus.servers.map(data => {
                                return (
                                    <div key={data.hostname}>
                                        <LoadBalancerCard data={data} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appReducer: state.app,
    loadBalancerStatus: state.system_status.load_balancer_status
});

const mapDispatchToProps = dispatch => {
    return {
        getLoadBalancerStatus: () => dispatch(SystemStatusActions.CreateLoadBalancerStatus())
    }
};

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(LoadBalancers, LoadBalancerPageStyles)
            )
        )
    )
);