import React from 'react';
import SingleSelect from './SingleSelect';
import { useTranslation } from 'react-i18next';
import Constants from 'config/Constants';
import { FormikProps } from 'formik';
import BuildableModel from 'interface/BuildableModel';

export interface StatusSingleSelectProps {
    formik: FormikProps<BuildableModel>;
}

const StatusSingleSelect = (props: StatusSingleSelectProps) => {
    const {t} = useTranslation();
    const statuses = Constants.BUILD_STATUSES();
    const {formik} = props;
    return (
        <SingleSelect
            id="status" 
            name="status"
            label={t('instance.status')}
            options={statuses}
            value={statuses.find(status => status.value === formik.values?.status)}
            onChange={formik.handleChange}
            formikError={formik.touched.status && Boolean(formik.errors.status)}
            formikHelperText={formik.touched.status ? formik.errors.status : ""}
        />
    );
};

export default StatusSingleSelect;