import EnvUtil from "./EnvUtil";

// Central place to log & easy way to disable logging in production
const Log = (...logItems: any[]) => {
    if (EnvUtil.isDevelopment() || EnvUtil.isStage()) {
        console.log(...logItems);
    }
}

export const LogError = (...logItems: any[]) => {
    if(EnvUtil.isDevelopment() || EnvUtil.isStage()) {
        console.error(...logItems)
    }
}
export default Log;