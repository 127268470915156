/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MailLogFilterModelInterface {
    client_id?: number;
    mail_id?: number | null;
    status?: MailLogFilterModelStatusEnum;
}

/**
 * 
 * @export
 * @interface MailLogFilterModel
 */
export class MailLogFilterModel extends BaseModel<MailLogFilterModelInterface> {

    /**
     * The id of the client to receive the mail
     * @type {number}
     * @memberof MailLogFilterModel
     */
    client_id?: number;
    /**
     * The id of the mail template used
     * @type {number}
     * @memberof MailLogFilterModel
     */
    mail_id?: number | null;
    /**
     * Not sent, Sent, Read, Error
     * @type {Array<string>}
     * @memberof MailLogFilterModel
     */
    status?: MailLogFilterModelStatusEnum;

    constructor(data?: MailLogFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MailLogFilterModel, value: StringDateInterface<MailLogFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MailLogFilterModelFromJSON(json: any): MailLogFilterModel {
    return MailLogFilterModelFromJSONTyped(json, false);
}

export function MailLogFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailLogFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MailLogFilterModel;
    try {
        let localClass = require.context('../override', true)('./MailLogFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'client_id': !exists(json, 'client_id') ? undefined : Number(json['client_id']),
        'mail_id': !exists(json, 'mail_id') ? undefined : Number(json['mail_id']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    });
}

export function MailLogFilterModelToJSON(value?: MailLogFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MailLogFilterModel;
    try {
        let localClass = require.context('../override', true)('./MailLogFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'client_id': value.client_id,
        'mail_id': value.mail_id,
        'status': value.status,
    } as StringDateInterface<MailLogFilterModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum MailLogFilterModelStatusEnum {
}


