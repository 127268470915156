/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface RoleModelInterface {
    name?: string;
    description?: string;
}

/**
 * 
 * @export
 * @interface RoleModel
 */
export class RoleModel extends BaseModel<RoleModelInterface> {

    /**
     * Unique per user
     * @type {string}
     * @memberof RoleModel
     */
    name?: string;
    /**
     * Unique per user
     * @type {string}
     * @memberof RoleModel
     */
    description?: string;

    constructor(data?: RoleModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: RoleModel, value: StringDateInterface<RoleModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function RoleModelFromJSON(json: any): RoleModel {
    return RoleModelFromJSONTyped(json, false);
}

export function RoleModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = RoleModel;
    try {
        let localClass = require.context('../override', true)('./RoleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    });
}

export function RoleModelToJSON(value?: RoleModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = RoleModel;
    try {
        let localClass = require.context('../override', true)('./RoleModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'description': value.description,
    } as StringDateInterface<RoleModel>;
    return createClass.toJson(value, model);
}


