import React from "react";
import { useTranslation } from "react-i18next";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import Condition from "api/override/ConditionModel";
import { Cog, SourceBranchPlus } from "mdi-material-ui";
import { IconButton } from "@mui/material";
import Wizard from "api/override/WizardModel";
import Branch from "api/override/BranchModel";
import SingleSelect from "components/form_controls/SingleSelect";
import MultiSelectOption from "interface/MultiSelectOption";
import HideOnProd from "components/HideOnProd";

interface ConditionViewProps {
    wizard: Wizard;
    condition: Condition
    onConditionUpdate: () => void;
    onConditionValueUpdate: (newVal: MultiSelectOption, br: Branch) => void;
    onAddBranch: () => void;
}

const ConditionView = (props: ConditionViewProps) => {
    const {wizard, condition, onConditionUpdate, onConditionValueUpdate, onAddBranch} = props
    const { classes } = useWizardBuilderStyles()
    const {t} = useTranslation()


    const updateConditionValue = (_, value: MultiSelectOption, br: Branch) => {
        onConditionValueUpdate(value, br)
    }

    const getConditionFieldsForMultiBranch = (): JSX.Element => {
        const branches: Array<Branch> = wizard.getBranchByIds(
            wizard.getBranchByIds(
                [wizard.getStepviewByCondition(condition).branch_id]
            )[0].next_ids
        )
        return (
            <>
                <div className={classes.condition_view_fields_connect_line}></div>
                {branches.map((br: Branch, index: number) => {
                    const isDisabled: boolean = index === 1
                    const selectValue = isDisabled ? {value: '', label: t('conditions.all_others')} : {value: br.value, label: br.value}
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} key={`condition_${br.id}`}>
                            <SingleSelect 
                                value={selectValue} 
                                onChange={(_, val) => updateConditionValue(_, val, br)} 
                                label={condition.condition_on} 
                                options={condition.getMultiSelectOptions() ?? []}
                                name={condition.condition_on} 
                                size={'small'} 
                                disabled={isDisabled}
                            />
                            <div className={classes.condition_view_field_down_line}></div>
                        </div>
                    )
                })}
            </>
        )
    }

    const getConditionValues = (): JSX.Element => {
        if(condition.is_bool) {
            return (
                <>
                <div className={classes.condition_view_fields_connect_line}></div>
                <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <p className={classes.boolean_condition_labels + ' left'}>{t('general.true')}</p>
                        <div className={classes.condition_view_field_down_line + ' boolean'}></div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <p className={classes.boolean_condition_labels + ' right'}>{t('general.false')}</p>
                        <div className={classes.condition_view_field_down_line + ' boolean'}></div>
                    </div>
                </div>
                </>
            )
        } else {
            return (
                <>
                    {getConditionFieldsForMultiBranch()}
                </>
            )   
        }
    }

    return (
        <div className={classes.condition_view_container}>
            <div className={classes.condition_view_fields_container}>
                {getConditionValues()}
            </div>
            <HideOnProd>
                <IconButton onClick={() => onConditionUpdate()} id='settings' size="large">
                    <Cog/>
                </IconButton>
            </HideOnProd>
            <IconButton
                onClick={() => onAddBranch()}
                disabled={condition.is_bool}
                id='addBranch'
                size="large">
                <SourceBranchPlus/>
            </IconButton>
        </div>
    );
}

export default ConditionView;