import { ConnectorModel, InstanceModel, ConnectorModelInterface } from "api";
import BuildableModel from "interface/BuildableModel";
import Historiable from "interface/Historiable";
import InstanceMenuable from "interface/InstanceMenuable";
import MultiSelectOption from "interface/MultiSelectOption";
import Statuses from "interface/Statuses";

export default class Connector extends ConnectorModel implements MultiSelectOption, Historiable, InstanceMenuable, BuildableModel {

    image_name: string;
    redirect_image_name?: string;
    redirect_image_version_name?: string;
    value: number | string;
    label: string;
    actionName: string = "connector";
    reducerName: string = "connectors";
    status: string = Statuses.STATUS_BUILD_FAILED.toString();

    constructor(data?: ConnectorModelInterface) {
        super(data);
        this.image_name = this.image?.docker_image ?? '';
        this.redirect_image_name = this.redirect_image?.docker_image;
        this.redirect_image_version_name = this.redirect_image_version?.version_name ?? undefined;
        this.value = this.id;
        this.label = this.name;
        this.status = data?.status ?? Statuses.STATUS_BUILD_FAILED.toString();
    }

    getFullUrl = (forInstance: InstanceModel): string => {
        return `https://${this.url}.bumbal.eu/${forInstance.domain}/`;
    }

    hasRedirect = (): boolean => {
        return Boolean(this.redirect_uri) && Boolean(this.redirect_image)
    }

    getInstanceDetailsUrl = (): string => {
        return 'connectors';
    }
 
}