import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Client from 'api/override/ClientModel';
import { ClientStatusModel, ClientStatusModelRequestEnum } from 'api';
import { InputAdornment } from '@mui/material';

interface ClientAcceptRequestDialogProps extends BaseFormModalProps<ClientStatusModel> {
    client: Client;
}

const ClientAcceptRequestDialog = (props: ClientAcceptRequestDialogProps) => {

    const { t } = useTranslation();
    const { client } = props;
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={t('clients.accept_trial')}
            initialValues={new ClientStatusModel({
                request: ClientStatusModelRequestEnum.Accepted,
                instance_name: client?.name ?? '',
                instance_domain: `${(client?.name ?? '').bbStringToSlug()}`
            })}
            action={ActionTypes.API_CLIENT_STATUS_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="instance_name" 
                        name="instance_name"
                        label={t('clients.instance_name')}
                        value={formik.values.instance_name}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="instance_domain" 
                        name="instance_domain"
                        label={t('clients.instance_domain')}
                        value={formik.values.instance_domain}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'> .bumbal.eu </InputAdornment>
                        }}
                    />
                </div>
            }
        />
    );
};

export default ClientAcceptRequestDialog;