/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ProcessListResultModel,
    ProcessListResultModelFromJSON,
    ProcessListResultModelToJSON,
    ProcessModel,
    ProcessModelFromJSON,
    ProcessModelToJSON,
    ProcessRetrieveListModel,
    ProcessRetrieveListModelFromJSON,
    ProcessRetrieveListModelToJSON,
} from '../models';

export interface ProcessMonitorDetailsProcessesRequest {
    token: string;
}

export interface ProcessMonitorListProcessesRequest {
    processRetrieveListModel: ProcessRetrieveListModel;
}

/**
 * 
 */
export class ProcessMonitorApi extends runtime.BaseAPI {

    /**
     * Retrieve details of a process
     * Retrieve details of a process
     */
    async processMonitorDetailsProcessesRaw(requestParameters: ProcessMonitorDetailsProcessesRequest): Promise<runtime.ApiResponse<ProcessModel>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling processMonitorDetailsProcesses.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/process-monitor/details/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessModelFromJSON(jsonValue));
    }

    /**
     * Retrieve details of a process
     * Retrieve details of a process
     */
    processMonitorDetailsProcesses = async(requestParameters: ProcessMonitorDetailsProcessesRequest): Promise<ProcessModel> => {
        const response = await this.processMonitorDetailsProcessesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a a list of processes
     * Retrieve a a list of processes
     */
    async processMonitorListProcessesRaw(requestParameters: ProcessMonitorListProcessesRequest): Promise<runtime.ApiResponse<ProcessListResultModel>> {
        if (requestParameters.processRetrieveListModel === null || requestParameters.processRetrieveListModel === undefined) {
            throw new runtime.RequiredError('processRetrieveListModel','Required parameter requestParameters.processRetrieveListModel was null or undefined when calling processMonitorListProcesses.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/process-monitor/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProcessRetrieveListModelToJSON(requestParameters.processRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessListResultModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a a list of processes
     * Retrieve a a list of processes
     */
    processMonitorListProcesses = async(requestParameters: ProcessMonitorListProcessesRequest): Promise<ProcessListResultModel> => {
        const response = await this.processMonitorListProcessesRaw(requestParameters);
        return await response.value();
    }

}
