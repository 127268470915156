import { ApiSuccessResponseModel, HistoryResponseModel, SettingCreateSuccessModel, SettingHistoryListRequest, SettingListResultModel, SettingRetrieveListModel } from "api";
import Setting from "api/override/SettingModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface SettingList extends BaseReducerAction {
    type: ActionTypes.API_SETTING_LIST;
    params: SettingRetrieveListModel;
}

export interface SettingListSuccess extends BaseSagaListResponse<SettingListResultModel> {
    type: ActionTypes.API_SETTING_LIST_SUCCESS;
}

export interface SettingCreate extends BaseReducerAction {
    type: ActionTypes.API_SETTING_CREATE;
    params: Setting;
}

export interface SettingCreateSuccess extends BaseSagaListResponse<SettingCreateSuccessModel> {
    type: ActionTypes.API_SETTING_CREATE_SUCCESS;
}

export interface SettingUpdateParams {
    key: string;
    settingModel: Setting;
}

export interface SettingUpdate extends BaseReducerAction {
    type: ActionTypes.API_SETTING_UPDATE;
    params: SettingUpdateParams;
}

export interface SettingUpdateSuccess extends BaseSagaListResponse<SettingCreateSuccessModel> {
    type: ActionTypes.API_SETTING_UPDATE_SUCCESS;
}

export interface SettingRetrieve extends BaseReducerAction {
    type: ActionTypes.API_SETTING_RETRIEVE;
    params: string;
}

export interface SettingRetrieveSuccess extends BaseSagaListResponse<Setting> {
    type: ActionTypes.API_SETTING_RETRIEVE_SUCCESS
}

export interface SettingDelete extends BaseReducerAction {
    type: ActionTypes.API_SETTING_DELETE;
    params: string;
}

export interface SettingDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_SETTING_DELETE_SUCCESS;
}

export interface SettingHistoryList extends BaseReducerAction {
    type: ActionTypes.API_SETTING_HISTORY_LIST;
    params: SettingHistoryListRequest;
}

export interface SettingHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_SETTING_HISTORY_LIST_SUCCESS;
}

export interface SettingClearListParams {
    force?: boolean;
}
export interface SettingClearList extends BaseReducerAction {
    type: ActionTypes.API_SETTING_LIST_CLEAR;
    params?: SettingClearListParams;
}

export default class SettingActions {
    
    static CreateSettingList(params: SettingRetrieveListModel): SettingList {
        return {
            type: ActionTypes.API_SETTING_LIST,
            params: params
        }
    }

    static CreateSettingListSuccess(params: SettingListResultModel): SettingListSuccess {
        return {
            type: ActionTypes.API_SETTING_LIST_SUCCESS,
            params: params
        }
    }

    static CreateSettingCreate(params: Setting): SettingCreate {
        return {
            type: ActionTypes.API_SETTING_CREATE,
            params: params
        }
    }

    static CreateSettingCreateSuccess(params: SettingCreateSuccessModel): SettingCreateSuccess {
        return {
            type: ActionTypes.API_SETTING_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateSettingUpdate(params: Setting): SettingUpdate {
        return {
            type: ActionTypes.API_SETTING_UPDATE,
            params: {
                key: params.key,
                settingModel: params
            }
        }
    }

    static CreateSettingUpdateSuccess(params: SettingCreateSuccessModel): SettingUpdateSuccess {
        return {
            type: ActionTypes.API_SETTING_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateSettingRetrieve(params: string): SettingRetrieve {
        return {
            type: ActionTypes.API_SETTING_RETRIEVE,
            params: params
        }
    }

    static CreateSettingRetrieveSuccess(params: Setting): SettingRetrieveSuccess {
        return {
            type: ActionTypes.API_SETTING_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateSettingDelete(params: string): SettingDelete {
        return {
            type: ActionTypes.API_SETTING_DELETE,
            params: params
        }
    }

    static CreateSettingDeleteSuccess(params: ApiSuccessResponseModel): SettingDeleteSuccess {
        return {
            type: ActionTypes.API_SETTING_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateSettingHistoryList(params: SettingHistoryListRequest): SettingHistoryList {
        return {
            type: ActionTypes.API_SETTING_HISTORY_LIST,
            params: params
        }
    }

    static CreateSettingHistoryListSuccess(params: HistoryResponseModel): SettingHistoryListSuccess {
        return {
            type: ActionTypes.API_SETTING_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearSettingList(force: boolean = false): SettingClearList {
        return {
            type: ActionTypes.API_SETTING_LIST_CLEAR,
            params: {force: force}
        }   
    }

}