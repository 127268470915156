import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import FilterColumn from 'models/table/FilterColumn';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import { AutoFix } from 'mdi-material-ui';
import EditIcon from '@mui/icons-material/Edit';
import WizardActions from 'reducers/types/Wizard';
import WizardState from 'reducers/interface/WizardState';
import Wizard from 'api/override/WizardModel';
import TableFilter from 'components/TableFilter';
import TableList from 'components/TableList';
import HideOnProd from 'components/HideOnProd';
import EnvUtil from 'utils/EnvUtil';
import { WizardRetrieveListModel } from 'api/models/WizardRetrieveListModel';
import CustomActionButton, { CustomLinkIconButton } from 'models/table/CustomActionButton';
import WizardDetailsDialog from './components/WizardDetailsDialog';
import { BaseAction } from 'reducers/interface/ReducerAction';
import NotificationCenter from 'services/NotificationCenter';

interface StateProps {
    wizardReducer: WizardState;
    appReducer: AppState;
}

interface DispatchProps {
    listWizards: (params: WizardRetrieveListModel) => void;
    createWizard: (params: Wizard) => void;
    clearWizards: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    wizard: Wizard;
    isCreateWizardModalOpen: boolean;
}

class WizardList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customButtons: CustomActionButton[];

    constructor(props) {
        super(props);

        this.state = {
            wizard: undefined,
            isCreateWizardModalOpen: false
        };

        this.customButtons = [
            new CustomLinkIconButton('wizardBuilderLink', i18next.t('wizards.builder'), 'redir-wizard-builder', (_, row?: Wizard) => {
                this.onWizardBuilder(row)
            }, EditIcon, '/wizards/', 'id', '/builder')
        ]
    }

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_WIZARD_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onWizardBuilder = (row: Wizard) => {
        this.props.navigate(`/wizards/${row.id}/builder`)
    }

    onCreateSuccess = (action: BaseAction) => {
        // const wizardSuccess: WizardCreateSuccessModel = action.params
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('wizards.model')}), {variant: 'success'})
        this.reloadData()
        this.dialogClosed()
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: true, label: i18next.t('general.name')}),
            new TableColumn({ key: 'description', isSortable: true, label: i18next.t('general.description')}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_WIZARD_LIST_FAILURE,
            ActionTypes.API_WIZARD_CREATE_FAILURE,
        ])
        this.updateTabTitle(i18next.t('wizards.model_plural'))
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.wizardReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listWizards( this.getParams().getApiFilter() )
        }
    }

    onDetails = (row?: Wizard) => {
        this.props.navigate(`/wizards/${row?.id}`)
    }

    onCreate = () => {
        this.setState({ isCreateWizardModalOpen: true })
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('wizards', this.props.location)){
            this.props.clearWizards();
        }
    }

    dialogClosed = () => {
        this.setState({
            isCreateWizardModalOpen: false
        })
    }

    renderView() {
        const { classes, appReducer, wizardReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <HideOnProd>
                <React.Fragment>
                    <ReloadDataButton loadData={this.reloadData} />
                    <Container maxWidth={"lg"}>
                        <Card className={`${classes.root} ${(EnvUtil.isProduction() || EnvUtil.isStage()) && classes.wizardPage}`}>
                            <TableHeader
                                entity={i18next.t('wizards.model')}
                                headerIcon={<AutoFix/>}
                                onCreate={this.onCreate}
                            />
                            
                            <CardContent className={classes.table_container}>
                                <TableFilter
                                    searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('wizards.model_plural')}) }
                                    searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('wizards.model_plural')}) }
                                    onFilterChange={ this.setFilters }
                                    columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                    searchText={this.tableParams?.search_text}
                                />
                                <Grid item xs={12} >
                                    <TableList
                                        hasDetailsAction
                                        columns={this.columns}
                                        count={wizardReducer.collection.count_filtered}
                                        listItems={wizardReducer.collection.items}
                                        isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_WIZARD_LIST)}
                                        customActions={this.customButtons}
                                        tableParams={ this.getParams() }
                                        delegate={ this } />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                    <WizardDetailsDialog
                        open={this.state.isCreateWizardModalOpen}
                        onModalClosePress={this.dialogClosed}
                        onModalSubmit={(newWiz: Wizard) => {this.props.createWizard(newWiz)}}
                    />
                </React.Fragment>
            </HideOnProd>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            
        ]
    }

}

const  mapStateToProps = (state: any) => ({
    wizardReducer: state.wizards,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listWizards: (params: WizardRetrieveListModel) => dispatch(WizardActions.CreateWizardList(params)),
        clearWizards: (params: boolean) => dispatch(WizardActions.CreateClearWizardList(params)),
        createWizard: (params: Wizard) => dispatch(WizardActions.CreateWizardCreate(params)),
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(WizardList, TableStyles)
            )
        )
    )
);