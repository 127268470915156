import { Divider } from "@mui/material";
import React, { PropsWithChildren } from 'react';
import useWidgetStyles from 'styles/WidgetStyles';

export interface WidgetRowViewProps {
    isHeader?: boolean;
    customStyle?: React.CSSProperties;
}

const WidgetRowView = (props: PropsWithChildren<WidgetRowViewProps>) => {
 
    const {classes} = useWidgetStyles();
    const {isHeader, customStyle} = props
    return (
        <>
            <Divider className={classes.divider} style={isHeader && {display: 'none'}}/>
            <div className={classes.row} style={{...customStyle}}>
                {props.children}
            </div>
        </>
    );
};

export default WidgetRowView;