/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface ImageVersionFilterModelInterface {
    image_id?: number | null;
}

/**
 * 
 * @export
 * @interface ImageVersionFilterModel
 */
export class ImageVersionFilterModel extends BaseModel<ImageVersionFilterModelInterface> {

    /**
     * The ID of the image this version belongs to
     * @type {number}
     * @memberof ImageVersionFilterModel
     */
    image_id?: number | null;

    constructor(data?: ImageVersionFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ImageVersionFilterModel, value: StringDateInterface<ImageVersionFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ImageVersionFilterModelFromJSON(json: any): ImageVersionFilterModel {
    return ImageVersionFilterModelFromJSONTyped(json, false);
}

export function ImageVersionFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVersionFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ImageVersionFilterModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'image_id': !exists(json, 'image_id') ? undefined : Number(json['image_id']),
    });
}

export function ImageVersionFilterModelToJSON(value?: ImageVersionFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ImageVersionFilterModel;
    try {
        let localClass = require.context('../override', true)('./ImageVersionFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'image_id': value.image_id,
    } as StringDateInterface<ImageVersionFilterModel>;
    return createClass.toJson(value, model);
}


