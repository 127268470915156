import React from 'react';
import { CardHeader, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTableHeaderStyles from 'styles/TableHeaderStyles';
import { Plus } from 'mdi-material-ui';
import IconButtonStyled from './styled/IconButtonStyled';

interface TableHeaderProps {
    onCreate?: () => void;
    entity?: string;
    headerIcon: JSX.Element;
    overrideTitle?: string;
}

const TableHeader = (props: TableHeaderProps) => {
    const { onCreate, headerIcon, entity, overrideTitle } = props;
    const { t } = useTranslation();
    const {classes} = useTableHeaderStyles();
    
    return (
        <CardHeader
            className={classes.header}
            avatar={headerIcon}
            title={overrideTitle != null ? <h1>{overrideTitle}</h1> : <h1>{entity} {t('general.list')}</h1>}
            action={ onCreate != null ?
                <Tooltip title={t('global.action.create_model', {model: entity})}>
                    <IconButtonStyled
                        onClick={onCreate}
                        className={classes.create_button}
                        id="tableAddButton"
                        size="large">
                        <Plus/>
                    </IconButtonStyled>
                </Tooltip>
                :
                null
            }
        />
    );
};

export default TableHeader;