import Constants from 'config/Constants';
import ApiFilter from 'interface/ApiFilter';
import FilterColumn, { FilterColumnVars } from './FilterColumn';

interface TableParamsVar {
    limit?: number;
    offset?: number;
    sorting?: TableParamsSorting;
    filters?: FilterColumnVars[];
    search?: string;
}

export class TableParams {

    limit: number;
    offset: number;
    sorting?: TableParamsSorting;
    search_text: string;
    filters: FilterColumn[];
    didLastSetFilter: boolean;

    constructor(limit: number, offset: number, sorting: TableParamsSorting, searchText: string, filters: FilterColumn[]) {
        this.limit = limit;
        this.offset = offset;
        this.sorting = sorting;
        this.filters = filters;
        this.search_text = searchText || '';
    }

    copy(newItem: TableParamsVar): TableParams {
        return new TableParams(
            newItem.limit || this.limit,
            newItem.offset || this.offset,
            newItem.sorting || this.sorting,
            newItem.search || this.search_text,
            this.filters
        )
    }

    getApiFilter(): ApiFilter {
        let finalFilters = {};
        (this.filters || []).forEach((el: FilterColumn) => {
            if (el.hasInput()) {
                el.setValues(finalFilters);
            }
        });
        return {
            limit: this.limit,
            offset: this.offset,
            sort_by: this.sorting?.column || 'id',
            sort_direction: this.sorting?.direction || 'desc',
            search_text: this.search_text,
            filters: {
                ...finalFilters
            }
        }
    }

    toJson(): TableParamsVar {
        return {
            limit: this.limit,
            sorting: this.sorting,
            search: this.search_text,
            filters: this.filters?.map(filter => filter.toJson())
        };
    }

    static fromJson(json?: TableParamsVar) {
        if (json == null) {
            return null
        }
        if (!json.limit && !json.sorting && !json.filters) {
            return null;
        }
        return new TableParams(
            json.limit,
            0, {
            column: json.sorting?.column, direction: json.sorting?.direction
        },
            json.search,
            json.filters?.map(filter => FilterColumn.fromJson(filter))
        )
    }

    mergeFilters(configFilters: FilterColumn[]): TableParams {
        let newFilters: FilterColumn[] = []
        configFilters.forEach((filter: FilterColumn) => {
            const existingFilter = this.filters.find(f => f.key === filter.key);
            if (existingFilter != null) {
                newFilters.push(existingFilter.merge(filter))
            } else {
                newFilters.push(filter)
            }
        });
        this.filters = newFilters;
        return this;
    }

    setFilters(newFilters: FilterColumn[]): TableParams {
        this.filters = newFilters;
        this.didLastSetFilter = true;
        this.offset = 0;
        return this;
    }

    setSorting(sorting?: TableParamsSorting): TableParams {
        this.sorting = sorting;
        this.offset = 0;
        this.didLastSetFilter = false;
        return this;
    }

    setSearchText(text: string): TableParams {
        this.search_text = text;
        this.offset = 0;
        this.didLastSetFilter = true;
        return this;
    }

    setOffset(offset: number, limit: number): TableParams {
        this.offset = offset;
        this.limit = limit;
        this.didLastSetFilter = false;
        return this;
    }

    increment() {
        this.setOffset(this.getTotalOffset(), this.limit);
    }

    getTotalOffset() {
        return this.offset + this.limit;
    }

    setOffsetForPage(page: number, limit: number): TableParams {
        return this.setOffset(page * limit, limit);
    }

    getCurrentPage(): number {
        return this.offset / this.limit;
    }

    static getIncrementingSelectParams(filters?: FilterColumn[]) {
        return new TableParams(Constants.MAX_ITEMS_PER_SELECT_LIST, 0, TableParamsSorting.getDefault(), '', filters ?? [])
    }

}

export class TableParamsSorting {

    column: string;
    direction: 'asc' | 'desc';

    static getDefault = () => {
        return new TableParamsSorting('id', 'asc');
    }

    constructor(column: string, direction: 'asc' | 'desc') {
        this.column = column;
        this.direction = direction;
    }

}