import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useTableHeaderStyles = makeStyles()((theme: Theme) => {
    return {  
        create_button: {
            color: 'white',
            background: theme.palette.primary.main,
            padding: 5,
            transition: 'all 0.3s',
            '&:hover': {
                background: theme.palette.primary.dark,
                boxShadow: '0 0 7px 2px rgba(0,0,0,0.07)',
                transform: 'rotate(90deg)'
            }
        },
        header: {
            padding: '20px 26px',
            '& .MuiCardHeader-avatar': {
                height: 20
            },
            '& .MuiCardHeader-action': {
                alignSelf: 'center'
            }
        }
    }
})

export default useTableHeaderStyles
