import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, Select, MenuItem, FormHelperText, Tooltip, Divider } from '@mui/material';
import FileUpload from 'components/FileUpload';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import * as yup from 'yup';
import ExtendedFile from 'interface/ExtendedFile';
import InputMapper from 'config/InputMapper';
import { PostConfigModelInterface } from 'api';
import { ErrorMessage, FieldArray, FormikErrors, FormikProps } from 'formik';
import KeyValueInterface from 'interface/KeyValueInterface';
import ConfigData from 'api/override/ConfigDataModel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ActionTypes from 'config/ActionTypes';
import DeleteIcon from '@mui/icons-material/Delete';
import ConnectorContainer from 'api/override/ConnectorContainerModel';
import { useSnackbar } from 'notistack';
import { Eye, EyeOff, Minus, Plus } from 'mdi-material-ui';
import TokenUtil from 'utils/TokenUtil';
import _ from 'lodash';
import ConfigInput from 'api/override/ConfigInputModel';
import HideOnProd from 'components/HideOnProd';
import ButtonStyled from 'components/styled/ButtonStyled';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface ConnectorContainerConfigDialogProps extends BaseFormModalProps<PostConfigModelInterface> {
    config?: ConfigData;
    connectorContainer: ConnectorContainer;
}

const ConnectorContainerConfigDialog = (props: ConnectorContainerConfigDialogProps) => { // this file is the sole reason for my insanity
    const { t } = useTranslation();
    const { config, connectorContainer } = props
    const {classes} = useDialogStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const yupValidation = {};
    const initValues = {} as PostConfigModelInterface;
    const allowedInputs: string[] = ['text', 'number', 'boolean', 'file', 'dictionary', 'password', 'select', 'arrayObjects', 'array']
    const isAdmin: boolean = TokenUtil.getTokenUtil().isAccountManager()
    const filterAdmin = (input: ConfigInput): boolean => {
        if (input?.inputs?.bbIsEmpty() === false) {
            input.inputs = input.inputs.filter( filterAdmin );
        }
        return !(input.admin_only && !isAdmin)
    }
    const inputArray = (config?.allowed_input)?.filter(filterAdmin) ?? []
    const generateValidation = (field: any) => {
        if (field.type === 'arrayObjects') {
            let arrayObjectValidation = {}
            field.inputs.forEach(arrayObject => {
                arrayObjectValidation[arrayObject.name] = generateValidation(arrayObject)
            })
            return yup.array().of(
                yup.object().shape(arrayObjectValidation)
            ).compact()
        }
        else if (field.type === 'dictionary') {
            return yup.array().of(
                yup.object().shape({
                    key: yup.string().ensure().when('value', {
                        is: '',
                        then: yup.string(),
                        otherwise: field.optional ? yup.string().nullable() : yup.string().required(t('global.form.required'))
                    }),
                    value: yup.string().ensure().when('key', {
                        is: '',
                        then: yup.string(),
                        otherwise: field.optional ? yup.string().nullable() : yup.string().required(t('global.form.required'))
                    }),
                }, [['key', 'value']])
            ).compact()
        }
        else if (field.type === 'array') {
            if(field.optional) {
                return yup.array().of(
                    yup.string().required(t('global.form.required'))
                )
            }
            return yup.array().min(1, t('global.form.at_least_one')).of(
                yup.string().required(t('global.form.required'))
            )
        } else {
            const type = InputMapper[field.type];
            return field.optional ? yup[type]().nullable() : yup[type]().required(t('global.form.required'));
        }
    }
    inputArray.forEach((input, i) => {
        // unrecognised input check
        if (!allowedInputs.includes(input.type)) {
            enqueueSnackbar(t('connector_container.view.input_type_model_does_not_exist', { type: input.name }), { variant: 'error' })
            console.log('Unrecognised input: ', input)
            return;
        }
        // initial values
        if (input.type === 'arrayObjects') {
            initValues[input.name] = [...(config.config[input.name] ?? [])];
        }
        else if (input.type === 'dictionary') {
            initValues[input.name] = config.dictData[input.name] ?? [];
        } else {
            initValues[input.name] = config.config[input.name] ?? '';
        }
        // yup
        yupValidation[input.name] = generateValidation(input)
    })

    const makeTemplateObject = (inputName: string): Object => {
        const configInputModel = inputArray.find((input: ConfigInput) => input.name === inputName)
        if(configInputModel != null) {
            const blueprint = {};
            configInputModel.inputs.forEach((input: ConfigInput) => {
                switch(input.type) {
                    case "boolean":
                        blueprint[input.name] = false;
                        break;
                    case "number":
                        blueprint[input.name] = 0;
                        break;
                    case "dictionary":
                    case "arrayObjects":
                        blueprint[input.name] = [];
                        break;
                    default:
                        blueprint[input.name] = '';
                        break;
                }
            })
            return blueprint;
        }
        return {};
    }

    const getInputElement = (input: ConfigInput, i: number, formik: FormikProps<PostConfigModelInterface>, namePrefix: string = ''): JSX.Element => {
        const fieldName: string = namePrefix + input.name;
        const formikTouched: boolean = _.get(formik.touched, fieldName);
        const formikErrors: FormikErrors<PostConfigModelInterface> = _.get(formik.errors, fieldName);
        switch (input.type) {
            case "arrayObjects":
                return (
                    <FieldArray key={`${fieldName}_${i}`} name={fieldName}>
                        {({ insert, remove, push }) => (
                            <div className={classes.add_objects_container}>
                                <p className={classes.add_object_title}>{input.name}</p>
                                {formik.values[input.name].map((_, inputIndex) => (
                                    <div key={`${fieldName}_${i}_${inputIndex}`}>
                                        <Tooltip title={t('global.action.remove_model', {model: input.name})}>
                                            <IconButton
                                                className={classes.remove_arr_object_button}
                                                onClick={() => remove(inputIndex)}
                                                size="large">
                                                <Minus/>
                                            </IconButton>
                                        </Tooltip>
                                        {input.inputs.map((arrayObject: ConfigInput, index: number) =>
                                            <React.Fragment key={`${input.name}_${i}_${index}`}>
                                                {getInputElement(arrayObject, index, formik, `${namePrefix}${input.name}[${inputIndex}].`)}
                                            </React.Fragment>
                                        )}
                                    </div>
                                ))}
                                <Tooltip title={t('global.action.add_model', {model: input.name})}>
                                    <IconButton
                                        className={classes.add_arr_object_button}
                                        onClick={() => push(makeTemplateObject(fieldName))}
                                        size="large">
                                        <Plus/>
                                    </IconButton>
                                </Tooltip>              
                            </div>  
                        )}
                    </FieldArray>
                );
            case 'array':
                return (
                    <FieldArray key={`${fieldName}_${i}`} name={fieldName}>
                        {({ insert, remove, push }) => (
                            <div className={classes.add_objects_container}>
                                <p className={classes.add_object_title}>{input.name}</p>
                                { !Array.isArray(formikErrors) && <p style={{color: 'red', textAlign: 'center'}}>{JSON.stringify(formikErrors)}</p> }
                                {formik.values[fieldName].map((item, index) => (
                                    <div key={`${input.name}_${i}_${index}_container`} style={{display: 'flex', alignItems: 'center'}}>
                                        <TextFieldStyled 
                                            key={`${input.name}_${i}_${index}`}
                                            id={`${fieldName}[${index}]`}
                                            name={`${fieldName}[${index}]`}
                                            value={_.get(formik.values, fieldName)[index]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            helperText={formikErrors != null ? formikErrors[index] : ""}
                                            error={Boolean(formikErrors?.[index])}
                                        />
                                        <IconButton
                                            key={`${input.name}_${i}_${index}_removeBtn`}
                                            className={classes.remove_arr_item_button}
                                            onClick={() => remove(index)}
                                            size="large">
                                            <Minus/>
                                        </IconButton>
                                    </div>
                                ))}
                                <Tooltip key={`${input.name}_${i}_tooltip`} title={t('global.action.add_model', {model: input.name})}>
                                    <IconButton
                                        className={classes.add_arr_object_button}
                                        onClick={() => push('')}
                                        size="large">
                                        <Plus/>
                                    </IconButton>
                                </Tooltip>
                            </div>  
                        )}
                    </FieldArray>
                );
            case "text":
            case 'password':
                return (
                    <TextFieldStyled
                        key={`${input.name}_${i}`}
                        id={input.name}
                        name={fieldName}
                        label={input.getTitle()}
                        type={input.type === 'password' && !isPasswordVisible ? 'password' : 'text'}
                        value={_.get(formik.values, fieldName)}
                        helperText={<>{formikTouched ? formikErrors : ""}</>}
                        error={formikTouched && Boolean(formikErrors)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                            endAdornment: input.type !== 'password' ? null :
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setIsPasswordVisible(!isPasswordVisible)} size="large">
                                        {isPasswordVisible ? <Eye /> : <EyeOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                );

            case "number":
                return (
                    <TextFieldStyled
                        key={`${input.name}_${i}`}
                        id={input.name}
                        name={fieldName}
                        label={input.getTitle()}
                        value={_.get(formik.values, fieldName, 0)}
                        helperText={<>{formikTouched ? formikErrors : ""}</>}
                        error={formikTouched && Boolean(formikErrors)}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                )

            case "file":
                return (
                    <FileUpload
                        key={`${input.name}_${i}`}
                        name={fieldName}
                        onFileUpload={(file: ExtendedFile) => {
                            formik.setFieldValue(input.name, file)
                        }}
                    />
                )

            case "boolean":
                return (
                    <React.Fragment key={`${input.name}_${i}`}>
                        <BBCheckbox
                            checkboxProps={{
                                checked: _.get(formik.values, fieldName, false),
                                onChange: (_, checked: boolean) => {
                                    formik.setFieldValue(fieldName, checked)
                                },
                                id: input.name,
                                name: fieldName,
                                onBlur: formik.handleBlur
                            }}
                            label={input.getTitle()}
                        />
                        <ErrorMessage component="div" className={classes.error_message} name={fieldName} />
                    </React.Fragment>
                )

            case "select":
                return (
                    <FormControl key={`select_formcontrol${i}`} style={{ paddingBottom: 10 }}>
                        <InputLabel key={`select_label_${i}`} style={{ marginLeft: 13, top: -4 }} id={`select_label_${i}`}>{input.name}</InputLabel>
                        <Select
                            id={`select_${input.name}`}
                            key={`select_${i}`}
                            name={fieldName}
                            label={input.getTitle()}
                            onChange={formik.handleChange}
                            value={_.get(formik.values, fieldName, '')}
                            onBlur={formik.handleBlur}
                            variant='outlined'
                            className={formikTouched && Boolean(formikErrors) ? classes.select_error : ''}
                        >
                            {Object.entries(input?.options ?? []).map(([key, value]) => <MenuItem key={`${input.name}${key}`} value={key}> {value} </MenuItem>)}
                        </Select>
                        <FormHelperText error={formikTouched && Boolean(formikErrors)}>{<>{formikTouched ? formikErrors : ""}</>}</FormHelperText>
                    </FormControl>
                )

            case "dictionary":
                const dictionaryKey: string = `dictionary_${i}`
                return (
                    <React.Fragment key={`${input.name}_${i}`}>
                        <h2 className={classes.dictionary_heading}> {input.name.bbCapitalize()}</h2>
                        <FieldArray name={input.name} key={dictionaryKey}>
                            {({ insert, remove, push }) => (
                                <React.Fragment key={`${input.name}_${i}`}>
                                    {(formik.values[input.name] as KeyValueInterface[]).map((item: KeyValueInterface, index: number) => (
                                        <React.Fragment key={'input_' + index}>
                                            <Grid container spacing={2}>
                                                <Grid item md={5}>
                                                    <TextFieldStyled
                                                        style={{ width: '100%', marginBottom: 10 }}
                                                        name={`${input.name}[${index}][key]`}
                                                        value={item.key}
                                                        label={input.getKeyTitle()}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        helperText={formikTouched ? formikErrors?.[index]?.key : ""}
                                                        error={formikTouched && Boolean(formikErrors?.[index]?.key)}
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextFieldStyled
                                                        style={{ width: '100%', marginBottom: 10 }}
                                                        name={`${input.name}[${index}][value]`}
                                                        value={item.value}
                                                        onBlur={formik.handleBlur}
                                                        label={input.getValueTitle()}
                                                        onChange={formik.handleChange}
                                                        helperText={formikTouched ? formikErrors?.[index]?.value : ""}
                                                        error={formikTouched && Boolean(formikErrors?.[index]?.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={1}>
                                                    <IconButton onClick={() => remove(index)} size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))}

                                    <ButtonStyled
                                        key={'add_button_' + i}
                                        aria-label='add_dictionary_item'
                                        onClick={() => push({ key: '', value: '' } as KeyValueInterface)}
                                        startIcon={<AddCircleIcon />}
                                    >
                                        {t('global.action.add_model', { model: `${input.name}` })}
                                    </ButtonStyled>

                                </React.Fragment>
                            )}
                        </FieldArray>
                    </React.Fragment>
                );
            case "title":
                return (
                    <div className={classes.cc_config_title_divider}>
                        <h3>{input.name}</h3>
                        <Divider/>
                    </div>
                )

            default:
                return null
        }
    }

    return (
        config != null &&
        <BaseFormModal {...props} title={t('global.title.details_model', { model: t('connector_container.model') })} subtitle={`${connectorContainer.instance?.name} - ${connectorContainer.connector?.name}`}
            initialValues={initValues}
            onSubmit={props.onModalSubmit}
            validationSchema={yup.object().shape(yupValidation)}
            action={ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG}
            renderForm={(formik) =>
                <div className={classes.fields_container}>
                    <div className={classes.label_container}>
                        <p>{connectorContainer.instance?.name} - {connectorContainer.connector?.name}</p>
                    </div>
                    <HideOnProd hide404>{JSON.stringify(formik.errors)}</HideOnProd>
                    {inputArray.map((input: ConfigInput, i) =>
                        getInputElement(input, i, formik)
                    )}
                </div>
            }
        />
    );
};

export default ConnectorContainerConfigDialog;