/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceConnectorRetrieveListModelInterface {
    offset?: number;
    limit?: number;
}

/**
 * 
 * @export
 * @interface InstanceConnectorRetrieveListModel
 */
export class InstanceConnectorRetrieveListModel extends BaseModel<InstanceConnectorRetrieveListModelInterface> {

    /**
     * Offset
     * @type {number}
     * @memberof InstanceConnectorRetrieveListModel
     */
    offset?: number;
    /**
     * Limit
     * @type {number}
     * @memberof InstanceConnectorRetrieveListModel
     */
    limit?: number;

    constructor(data?: InstanceConnectorRetrieveListModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceConnectorRetrieveListModel, value: StringDateInterface<InstanceConnectorRetrieveListModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceConnectorRetrieveListModelFromJSON(json: any): InstanceConnectorRetrieveListModel {
    return InstanceConnectorRetrieveListModelFromJSONTyped(json, false);
}

export function InstanceConnectorRetrieveListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceConnectorRetrieveListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceConnectorRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./InstanceConnectorRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'offset': !exists(json, 'offset') ? undefined : Number(json['offset']),
        'limit': !exists(json, 'limit') ? undefined : Number(json['limit']),
    });
}

export function InstanceConnectorRetrieveListModelToJSON(value?: InstanceConnectorRetrieveListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceConnectorRetrieveListModel;
    try {
        let localClass = require.context('../override', true)('./InstanceConnectorRetrieveListModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'offset': value.offset,
        'limit': value.limit,
    } as StringDateInterface<InstanceConnectorRetrieveListModel>;
    return createClass.toJson(value, model);
}


