import { Theme } from '@mui/material/styles';

const SignInStyles = (theme: Theme) => ({
    root: {
        height: '100vh'
    },
    image_section: {
        position: 'absolute',
        background: theme.palette.background.paper,
        height: '100%',
        width: '100%',
        '& > img': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '80%'
        }
    },
    form_section: {
        position: 'absolute',
        width: 500,
        minWidth: 400,
        height: '100%',
        background: theme.palette.background.paper,
        top: 0,
        right: 0,
        padding: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.15)',
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.background.paper,
        },
        '& > h1': {
            fontSize: 40
        },
        '& > h3': {
            margin: '0 0 50px 0',
            fontSize: 20
        },
        '& > img': {
            position: 'absolute',
            top: 20,
            right: 20
        }
    },
})

export default SignInStyles