import { Backdrop, CardHeader, CircularProgress, IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Stepview from "api/override/StepviewModel";
import Wizard from "api/override/WizardModel";
import ButtonStyled from "components/styled/ButtonStyled";
import { FieldArray, Formik, FormikProps } from "formik";
import { AutoFix } from "mdi-material-ui";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWizardBuilderStyles from "styles/WizardBuilderStyles";
import StepviewDetailsDialog from "./StepviewDetailsDialog";
import StepviewWizardCard from "./StepviewWizardCard";
import WizardDetailsDialog from "./WizardDetailsDialog";
import Widget from "api/override/WidgetModel";
import { useDispatch, useSelector } from "react-redux";
import StepviewActions from "reducers/types/Stepview";
import NotificationCenter, { NCListener } from "services/NotificationCenter";
import ActionTypes from "config/ActionTypes";
import { BaseAction } from "reducers/interface/ReducerAction";
import { RootState } from "store/AppStore";
import BranchConditionCreateDialog from "./BranchConditionCreateDialog";
import Condition from "api/override/ConditionModel";
import ConditionActions from "reducers/types/Condition";
import Branch from "api/override/BranchModel";
import ReloadDataButton from "components/ReloadDataButton";
import WizardActions, { WizardRetrieveSuccess } from "reducers/types/Wizard";
import BranchActions from "reducers/types/Branch";
import BranchConditionUpdateDialog from "./BranchConditionUpdateDialog";
import MultiSelectOption from "interface/MultiSelectOption";
import ConfirmDeleteDialog from "views/Dialog/ConfirmDeleteDialog";
import DummyDataSetDialog from "./DummyDataSetDialog";
import { useParams } from "react-router-dom";

interface WizardBuilderProps {
    wizard?: Wizard;
    onSaveChanges: (wizard: Wizard) => void;
    onUpdateTitle: (wizard: Wizard) => void;
}

const WizardBuilder = (props: WizardBuilderProps) => {
    const {wizard, onSaveChanges, onUpdateTitle} = props
    const { t } = useTranslation();
    const formRef = useRef<FormikProps<Wizard>>(null);
    const { classes } = useWizardBuilderStyles()
    const dispatch = useDispatch()
    const globalReducer = useSelector((state: RootState) => state)
    const params = useParams();
    const [formikWizard, setFormikWizard] = useState(wizard ?? new Wizard())
    const [isEditWizardModalOpen, setIsEditWizardModalOpen] = useState<boolean>(false)
    const [isEditStepviewModalOpen, setIsEditStepviewModalOpen] = useState<boolean>(false)
    const [isConditionCreateModalOpen, setIsConditionCreateModalOpen] = useState<boolean>(false)
    const [isConditionUpdateModalOpen, setIsConditionUpdateModalOpen] = useState<boolean>(false)
    const [isSetDummyDataModalOpen, setIsSetDummyDataModalOpen] = useState<boolean>(false)
    const [isDeleteStepviewAlertDialogOpen, setIsDeleteStepviewAlertDialogOpen] = useState<boolean>(false)
    const [selectedStepviewId, setSelectedStepviewId] = useState<number>(undefined)
    const [selectedCondition, setSelectedCondition] = useState<Condition>(undefined)
    const [selectedBranch, setSelectedBranch] = useState<Branch>(undefined)

    const handleComponentDidMount = () => {
        Widget.prepareCache()
        const wizardRetrieved =  new NCListener(ActionTypes.API_WIZARD_RETRIEVE_SUCCESS, (action: BaseAction) => onWizardRetrieveSuccess(action as WizardRetrieveSuccess))
        const stepviewCreated = new NCListener(ActionTypes.API_STEPVIEW_CREATE_SUCCESS, () => onSuccessResponse())
        const stepviewDeleted = new NCListener(ActionTypes.API_STEPVIEW_DELETE_SUCCESS, () => onSuccessResponse(true))
        const stepviewUpdated = new NCListener(ActionTypes.API_STEPVIEW_UPDATE_SUCCESS, () => onSuccessResponse(true))
        const conditionCreated = new NCListener(ActionTypes.API_CONDITION_CREATE_SUCCESS, () => onSuccessResponse(true))
        const branchUpdated = new NCListener(ActionTypes.API_BRANCH_UPDATE_SUCCESS, () => onSuccessResponse())
        const conditionUpdated = new NCListener(ActionTypes.API_CONDITION_UPDATE_SUCCESS, () => onSuccessResponse(true))
        const branchCreated = new NCListener(ActionTypes.API_BRANCH_CREATE_SUCCESS, () => onSuccessResponse())
        NotificationCenter.default.addNCListeners([stepviewCreated, stepviewDeleted, stepviewUpdated, conditionCreated, wizardRetrieved, branchCreated, conditionUpdated, branchUpdated])
        return () => NotificationCenter.default.removeListeners([stepviewCreated, stepviewDeleted, stepviewUpdated, conditionCreated, wizardRetrieved, branchCreated, conditionUpdated, branchUpdated])
    }
    // eslint-disable-next-line
    React.useEffect(handleComponentDidMount, [])

    const onSuccessResponse = (shouldCloseModal?: boolean) => {
        if(shouldCloseModal) {
            closeModal();
        }
        fetchWizard()
    }

    const fetchWizard = () => {
        const wizardId: number = wizard.id ?? parseInt(params.slug)
        dispatch(WizardActions.CreateWizardRetrieve(wizardId))
    }

    const onWizardRetrieveSuccess = (action: WizardRetrieveSuccess) => {
        const newWiz = new Wizard(action.params);
        setFormikWizard(newWiz)
        formRef.current.setValues(newWiz)
    }

    const cardHeaderTitleComponent: JSX.Element = (
        <div className={classes.header_container}>
            <div className={classes.header_title}>
                {formikWizard.id != null ? <h1>{formikWizard.name}</h1> : <h1>{t('general.title')}</h1>}
                {formikWizard.id != null ? <p>{formikWizard.description}</p> : <p>{t('general.description')}</p>}
            </div>
            <IconButton
                onClick={() => {setIsEditWizardModalOpen(true)}}
                color="primary"
                size="large">
                <Edit/>
            </IconButton>
        </div>)
    
    const getHeaderAction = (): JSX.Element => formikWizard.id != null ? 
        <ButtonStyled type="submit" className={classes.form_save_btn}>{t('global.button.save_changes')}</ButtonStyled> 
        : 
        <ButtonStyled type="submit" className={classes.form_save_btn}>{t('global.button.create')}</ButtonStyled>

    const closeModal = () => {
        setIsEditWizardModalOpen(false)
        setIsEditStepviewModalOpen(false)
        setIsConditionCreateModalOpen(false)
        setIsConditionUpdateModalOpen(false)
        setIsDeleteStepviewAlertDialogOpen(false)
        setIsSetDummyDataModalOpen(false)
        setSelectedStepviewId(undefined)
    }

    const updateWizardNameDescription = (updatedWizard: Wizard, formik: FormikProps<Wizard>) => {
        formik.setFieldValue('name', updatedWizard.name)
        formik.setFieldValue('description', updatedWizard.description)
        const wiz: Wizard = new Wizard({...formikWizard, name: updatedWizard.name, description: updatedWizard.description})
        onUpdateTitle(wiz)
        closeModal()
    }

    const onEditStepview = (id: number) => {
        setSelectedStepviewId(id)
        setIsEditStepviewModalOpen(true)
    }

    const onAddNewStepview = (branch_id: number) => {
        const newStepview: Stepview = new Stepview({wizard_id: formikWizard.id, branch_id: branch_id, position: 1})
        dispatch(StepviewActions.CreateStepviewCreate(newStepview))
    }

    const onUpdateStepview = (sw: Stepview) => {
        dispatch(StepviewActions.CreateStepviewUpdate(sw))
    }

    const onDeleteStepview = (id: number) => {
        setSelectedStepviewId(id)
        setIsDeleteStepviewAlertDialogOpen(true)
    }

    const onAddBranch = (condition: Condition) => {
        dispatch(ConditionActions.CreateConditionCreate(condition))
    }

    const onUpdateCondition = (condition: Condition) => {
        dispatch(ConditionActions.CreateConditionUpdate(condition))
    }

    const isLoading = (): boolean => {
        const {isFetchingData} = globalReducer.app
        return isFetchingData.get(ActionTypes.API_STEPVIEW_CREATE)
        || isFetchingData.get(ActionTypes.API_STEPVIEW_DELETE)
        || isFetchingData.get(ActionTypes.API_BRANCH_CREATE)
        || isFetchingData.get(ActionTypes.API_CONDITION_CREATE)
        || isFetchingData.get(ActionTypes.API_CONDITION_UPDATE)
    }

    const onConditionConfig = (stepviewId: number) => {
        setSelectedStepviewId(stepviewId)
        setIsConditionCreateModalOpen(true)
    }

    const onConditionUpdate = (condition: Condition) => {
        setSelectedCondition(condition)
        setIsConditionUpdateModalOpen(true)
    }

    const onConditionValueUpdate = (newVal: MultiSelectOption, branchToUpdate: Branch) => {
        const updatedBranch: Branch = new Branch({...branchToUpdate, value: newVal.value.toString()})
        dispatch(BranchActions.CreateBranchUpdate(updatedBranch))
    }

    const onAddBranchToMultiSelectCondition = (condition: Condition) => {
        dispatch(BranchActions.CreateBranchCreate(new Branch({condition_id: condition.id, wizard_id: formikWizard.id})))
    }

    const onSetDummyData = (branch: Branch) => {
        setSelectedBranch(branch)
        setIsSetDummyDataModalOpen(true)
    }

    const setBranchDummyData = (branch: Branch) => {
        // dispatch branch update and set the dummy data id based on the selected one from the modal
    }

    const renderBranch = (wizard: Wizard, branches: Array<Branch>): JSX.Element => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-around'}} key={`parent_branch_container_${wizard.id}`}>
                {branches.map((br: Branch) => {
                    const lastStepviewIdInBranch: number = wizard.getLastStepviewByBranchId(br.id).id
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10px'}} key={`branch_container_${br.id}`}>
                            {(wizard.getStepviewsByBranchId(br.id)).map((stepview: Stepview) => {
                                return (
                                    <StepviewWizardCard 
                                        wizard={wizard}
                                        stepview={stepview}
                                        key={`stepview_${stepview.stepview_uuid}`}
                                        onEdit={onEditStepview}
                                        onAddStepview={() => onAddNewStepview(stepview.branch_id)}
                                        onAddBranch={onConditionConfig}
                                        onConditionValueUpdate={onConditionValueUpdate}
                                        onAddMoreThanTwoBranches={onAddBranchToMultiSelectCondition}
                                        onDelete={() => onDeleteStepview(stepview.id)}
                                        onSetDummyData={() => onSetDummyData(br)}
                                        isLast={stepview.id === lastStepviewIdInBranch && !stepview.hasCondition()}
                                        onConditionUpdate={onConditionUpdate}
                                    />
                                )
                            })}
                            {br.children.length > 0 && renderBranch(wizard, br.children)}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <Formik
            initialValues={formikWizard}
            onSubmit={onSaveChanges}
            innerRef={formRef}>
            {(formik: FormikProps<Wizard>) => 
                {
                return (
                    <div>
                        <Backdrop className={classes.backdrop} open={isLoading()}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <ReloadDataButton loadData={fetchWizard} />
                        <CardHeader
                            avatar={<AutoFix/>}
                            title={cardHeaderTitleComponent}
                            action={getHeaderAction()}
                        />
                        <div className={classes.stepviews_container}>
                            <FieldArray name='stepviews'>
                                {({insert, remove, push}) => (
                                    <>
                                        {formik.values.getOrderedBranches().map((branch: Branch) => {
                                            return (
                                                <Fragment key={`branch_${branch.id}`}>{renderBranch(formik.values, [branch])}</Fragment>
                                            )
                                        })}
                                    </>
                                )}
                            </FieldArray>
                        </div>
                        <WizardDetailsDialog
                            wizard={formik.values}
                            open={isEditWizardModalOpen}
                            onModalClosePress={closeModal}
                            onModalSubmit={(updatedWizard: Wizard) => {updateWizardNameDescription(updatedWizard, formik)}}
                        />
                        <BranchConditionCreateDialog
                            open={isConditionCreateModalOpen}
                            stepviewId={selectedStepviewId}
                            onModalClosePress={closeModal}
                            onModalSubmit={onAddBranch}
                        />
                        <BranchConditionUpdateDialog
                            open={isConditionUpdateModalOpen}
                            condition={selectedCondition}
                            onModalClosePress={closeModal}
                            onModalSubmit={onUpdateCondition}
                        />
                        
                        {selectedStepviewId != null && 
                            <>
                            <StepviewDetailsDialog
                                stepviewId={selectedStepviewId}
                                open={isEditStepviewModalOpen}
                                onModalClosePress={closeModal}
                                onModalSubmit={onUpdateStepview}
                            />
                            <ConfirmDeleteDialog
                                open={isDeleteStepviewAlertDialogOpen}
                                onModalClosePress={closeModal}
                                onConfirm={() => {
                                    dispatch(StepviewActions.CreateStepviewDelete(selectedStepviewId))
                                }}
                                title={t('global.title.delete_confirm_model', {model: (t('stepviews.model')).toLowerCase()})}
                            />
                            </>
                        }

                        {selectedBranch != null && 
                            <DummyDataSetDialog 
                                open={isSetDummyDataModalOpen}
                                branch={selectedBranch}
                                onModalClosePress={closeModal}
                                onModalSubmit={setBranchDummyData}
                            />
                        }
                    </div>)
                }   
            }
        </Formik>
    )
}

export default WizardBuilder