import { StepviewModel, StepviewModelInterface } from "api/models/StepviewModel";
import Historiable from "interface/Historiable";
import { nanoid } from "nanoid";
import Condition from "./ConditionModel";

interface StepviewInterface extends StepviewModelInterface {
    stepview_uuid?: string;
}

export default class Stepview extends StepviewModel implements Historiable {

    actionName: string = "stepview"
    reducerName: string = "stepviews"

    stepview_uuid?: string;
    condition: Condition = null;

    constructor(data?: StepviewInterface) {
        super(data);
        this.stepview_uuid = data?.stepview_uuid ?? nanoid()
        this.condition = data?.condition as Condition
    }

    hasCondition(): boolean {
        return this.condition_id != null
    }

}