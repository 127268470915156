/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ConditionCreateSuccessModel,
    ConditionCreateSuccessModelFromJSON,
    ConditionCreateSuccessModelToJSON,
    ConditionListResultModel,
    ConditionListResultModelFromJSON,
    ConditionListResultModelToJSON,
    ConditionModel,
    ConditionModelFromJSON,
    ConditionModelToJSON,
    ConditionRemoveSuccessModel,
    ConditionRemoveSuccessModelFromJSON,
    ConditionRemoveSuccessModelToJSON,
    ConditionRetrieveListModel,
    ConditionRetrieveListModelFromJSON,
    ConditionRetrieveListModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
} from '../models';

export interface ConditionCreateRequest {
    conditionModel: ConditionModel;
}

export interface ConditionDeleteRequest {
    id: number;
}

export interface ConditionHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ConditionListRequest {
    conditionRetrieveListModel: ConditionRetrieveListModel;
}

export interface ConditionRetrieveRequest {
    id: number;
}

export interface ConditionUpdateRequest {
    id: number;
    conditionModel: ConditionModel;
}

/**
 * 
 */
export class ConditionApi extends runtime.BaseAPI {

    /**
     * Create a new condition
     * Create a new condition
     */
    async conditionCreateRaw(requestParameters: ConditionCreateRequest): Promise<runtime.ApiResponse<ConditionCreateSuccessModel>> {
        if (requestParameters.conditionModel === null || requestParameters.conditionModel === undefined) {
            throw new runtime.RequiredError('conditionModel','Required parameter requestParameters.conditionModel was null or undefined when calling conditionCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionModelToJSON(requestParameters.conditionModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new condition
     * Create a new condition
     */
    conditionCreate = async(requestParameters: ConditionCreateRequest): Promise<ConditionCreateSuccessModel> => {
        const response = await this.conditionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an condition
     * Delete an condition
     */
    async conditionDeleteRaw(requestParameters: ConditionDeleteRequest): Promise<runtime.ApiResponse<ConditionRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conditionDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an condition
     * Delete an condition
     */
    conditionDelete = async(requestParameters: ConditionDeleteRequest): Promise<ConditionRemoveSuccessModel> => {
        const response = await this.conditionDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an condition
     * retrieve a list of historical logs for an condition
     */
    async conditionHistoryListRaw(requestParameters: ConditionHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conditionHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling conditionHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an condition
     * retrieve a list of historical logs for an condition
     */
    conditionHistoryList = async(requestParameters: ConditionHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.conditionHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all conditions
     * List all conditions
     */
    async conditionListRaw(requestParameters: ConditionListRequest): Promise<runtime.ApiResponse<ConditionListResultModel>> {
        if (requestParameters.conditionRetrieveListModel === null || requestParameters.conditionRetrieveListModel === undefined) {
            throw new runtime.RequiredError('conditionRetrieveListModel','Required parameter requestParameters.conditionRetrieveListModel was null or undefined when calling conditionList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionRetrieveListModelToJSON(requestParameters.conditionRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionListResultModelFromJSON(jsonValue));
    }

    /**
     * List all conditions
     * List all conditions
     */
    conditionList = async(requestParameters: ConditionListRequest): Promise<ConditionListResultModel> => {
        const response = await this.conditionListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a condition by its id
     * Retrieve an condition by its id
     */
    async conditionRetrieveRaw(requestParameters: ConditionRetrieveRequest): Promise<runtime.ApiResponse<ConditionModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conditionRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a condition by its id
     * Retrieve an condition by its id
     */
    conditionRetrieve = async(requestParameters: ConditionRetrieveRequest): Promise<ConditionModel> => {
        const response = await this.conditionRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an condition
     * Update an condition
     */
    async conditionUpdateRaw(requestParameters: ConditionUpdateRequest): Promise<runtime.ApiResponse<ConditionCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conditionUpdate.');
        }

        if (requestParameters.conditionModel === null || requestParameters.conditionModel === undefined) {
            throw new runtime.RequiredError('conditionModel','Required parameter requestParameters.conditionModel was null or undefined when calling conditionUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/condition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionModelToJSON(requestParameters.conditionModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an condition
     * Update an condition
     */
    conditionUpdate = async(requestParameters: ConditionUpdateRequest): Promise<ConditionCreateSuccessModel> => {
        const response = await this.conditionUpdateRaw(requestParameters);
        return await response.value();
    }

}
