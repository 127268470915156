import { ConnectorContainerApi, ConnectorContainerRetrieveRequest, ConnectorContainerDeleteRequest, ConnectorContainerConfigureRequest, ConnectorContainerConfigureGetRequest, ConnectorContainerFileRequest, ConnectorContainerTasksRequest, ConnectorContainerUpdateRequest, ConnectorContainerUpdateModel, ConnectorContainerHistoryListRequest, ConnectorContainerResetRequest } from 'api';
import { AppActions } from 'reducers/types/App';
import ConnectorContainerActions, { ConnectorContainerRetrieve, ConnectorContainerCreate, ConnectorContainerDelete, ConnectorContainerGetConfig, ConnectorContainerSetConfig, ConnectorContainerSetConfigFiles, ConnectorContainerTriggerJob, ConnectorContainerUpdate, ConnectorContainerHistoryList, ConnectorContainerReset } from 'reducers/types/ConnectorContainer';
import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';

export function* ConnectorContainerSagas() {
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE, ConnectorContainerSaga.connectorContainerRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_CREATE, ConnectorContainerSaga.connectorContainerCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_DELETE, ConnectorContainerSaga.connectorContainerDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG, ConnectorContainerSaga.connectorContainerSetConfigWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG, ConnectorContainerSaga.connectorContainerGetConfigWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES, ConnectorContainerSaga.connectorContainerSetConfigFilesWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB, ConnectorContainerSaga.connectorContainerTriggerJobWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_UPDATE, ConnectorContainerSaga.connectorContainerUpdateWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_RESET, ConnectorContainerSaga.connectorContainerResetWorkerSaga);
    yield takeLatest(ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST, ConnectorContainerSaga.connectorContainerHistoryListWorkerSaga);
}

class ConnectorContainerSaga {
    static *connectorContainerRetrieveWorkerSaga(action: ConnectorContainerRetrieve) {
        try {

            const connectorsArgs = {
                uuid: action.params
            } as ConnectorContainerRetrieveRequest
            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerRetrieve, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorContainerActions.CreateConnectorContainerRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_FAILURE, error as Error))
        }
    }

    static *connectorContainerCreateWorkerSaga(action: ConnectorContainerCreate) {

        try {
            const connectorsArgs = {
                connectorContainerModel: action.params
            }
            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerCreate, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorContainerActions.CreateConnectorContainerCreateSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_CREATE_FAILURE, error as Error))
        }
    }

    static *connectorContainerDeleteWorkerSaga(action: ConnectorContainerDelete) {
        try {
            const connectorsArgs = {
                uuid: action.params
            } as ConnectorContainerDeleteRequest
            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerDelete, connectorsArgs)

            // dispatch a success action to the store with the activities
            yield put(ConnectorContainerActions.CreateConnectorContainerDeleteSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_DELETE_FAILURE, error as Error))
        }
    }

    static *connectorContainerSetConfigWorkerSaga(action: ConnectorContainerSetConfig) {
        try {
            const setArgs = {
                uuid: action.params.uuid,
                postConfigModel: {
                    items: action.params.config
                }
            } as ConnectorContainerConfigureRequest
            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerConfigure, setArgs)
            yield put(ConnectorContainerActions.CreateConnectorContainerSetConfigSuccess(data))

        } catch (error) {   
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FAILURE, error as Error))
        }
    }

    static *connectorContainerGetConfigWorkerSaga(action: ConnectorContainerGetConfig) {
        try {
            const getArgs = {
                uuid: action.params
            } as ConnectorContainerConfigureGetRequest

            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerConfigureGet, getArgs);
            yield put(ConnectorContainerActions.CreateConnectorContainerGetConfigSuccess(data))
        } catch (error) {
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE, error as Error))
        }
    }

    static *connectorContainerSetConfigFilesWorkerSaga(action: ConnectorContainerSetConfigFiles) {
        try {
            const fileArgs = {
                uuid: action.params.uuid,
                fileUploadModel: action.params.fileUploadModel
            } as ConnectorContainerFileRequest

            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerFile, fileArgs)
            yield put(ConnectorContainerActions.CreateConnectorContainerSetConfigFilesSuccess(data))
        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FILES_FAILURE, error as Error))
        }
    }

    static *connectorContainerTriggerJobWorkerSaga(action: ConnectorContainerTriggerJob) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                task: action.params.task,
                uuids: action.params.uuids?.bbIsEmpty() ? undefined : action.params.uuids,
                dbName: action.params.dbName,
            } as ConnectorContainerTasksRequest
            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerTasks, ccArgs)
            yield put(ConnectorContainerActions.CreateConnectorContainerTriggerJobSuccess(data))
            
        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_TRIGGER_JOB_FAILURE, error as Error))
        }
    }

    static *connectorContainerUpdateWorkerSaga(action: ConnectorContainerUpdate) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                connectorContainerUpdateModel: new ConnectorContainerUpdateModel(action.params)
            } as ConnectorContainerUpdateRequest

            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerUpdate, ccArgs)
            yield put(ConnectorContainerActions.CreateConnectorContainerUpdateSuccess(data))
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_FAILURE, error as Error))
        }
    }

    static *connectorContainerHistoryListWorkerSaga(action: ConnectorContainerHistoryList) {
        try {
            const ccArgs = {
                uuid: action.params.uuid,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as ConnectorContainerHistoryListRequest

            const data = yield ApiHandler(new ConnectorContainerApi().connectorContainerHistoryList, ccArgs);
            yield put(ConnectorContainerActions.CreateConnectorContainerHistoryListSuccess(data))

        } catch(error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_LIST_FAILURE, error as Error))
        }
    }

    static *connectorContainerResetWorkerSaga(action: ConnectorContainerReset) {
        try {
            const connectorsArgs = {
                uuid: action.params
            } as ConnectorContainerResetRequest
            yield ApiHandler(new ConnectorContainerApi().connectorContainerReset, connectorsArgs)
            // dispatch a success action to the store with the activities
            yield put(ConnectorContainerActions.CreateConnectorContainerResetSuccess())
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_CONNECTOR_CONTAINER_RESET_FAILURE, error as Error));
        }
    }
}