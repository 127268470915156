import ActionTypes from "config/ActionTypes";
import Setting from 'api/override/SettingModel';
import BaseReducerAction from "./interface/ReducerAction";
import SettingState from "./interface/SettingState";
import { SettingListSuccess } from "./types/Setting";
import TimeUtil from "utils/TimeUtil";
import Constants from "config/Constants";

const initialState: SettingState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: null,
    error: null,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0
};

export function SettingReducers(state = initialState, action: BaseReducerAction): SettingState {
    switch (action.type) {
        case ActionTypes.API_SETTING_UPDATE:
        case ActionTypes.API_SETTING_RETRIEVE:
        case ActionTypes.API_SETTING_LIST:
        case ActionTypes.API_SETTING_CREATE:
        case ActionTypes.API_SETTING_DELETE:
        case ActionTypes.API_SETTING_HISTORY_LIST:
            return {
                ...state,
                error: null
            };

        case ActionTypes.API_SETTING_UPDATE_SUCCESS:
        case ActionTypes.API_SETTING_CREATE_SUCCESS:
        case ActionTypes.API_SETTING_DELETE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_SETTING_UPDATE_FAILURE:
        case ActionTypes.API_SETTING_CREATE_FAILURE:
        case ActionTypes.API_SETTING_DELETE_FAILURE:
        case ActionTypes.API_SETTING_LIST_FAILURE:
        case ActionTypes.API_SETTING_RETRIEVE_FAILURE:
            return {
                ...state,
                error: action.params
            }

        case ActionTypes.API_SETTING_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Setting,
                error: null
            };

        case ActionTypes.API_SETTING_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                selectedObject: new Setting(),
                history: action.params,
            };

        case ActionTypes.API_SETTING_LIST_SUCCESS:
            const result = action as SettingListSuccess
            return {
                ...state,
                collection: {
                    items: result.params.items as Array<Setting>,
                    count_filtered: result.params.count_filtered,
                    count_unfiltered: result.params.count_unfiltered
                },
                timestampLastList: TimeUtil.getNowUnixTimestamp()
            };
            case ActionTypes.API_SETTING_LIST_CLEAR:
                if(action.params.force || TimeUtil.havePassedSince(Constants.TIME_TO_REFRESH, state.timestampLastList)) {
                    return {
                        ...initialState
                    }
                } else {
                    return {
                        ...state
                    }
                }
    
        default:
            return state;
    }
}