import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import i18next from "../../services/i18n";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from 'notistack';
import { ViewProps } from 'components/base/BasePageComponent';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from '../../interface/TableViewDelegate';
import { TableParamsSorting } from '../../models/table/TableParams';
import TableColumn from '../../models/table/TableColumn';
import FilterColumn from 'models/table/FilterColumn';
import TableStyles from 'styles/TableStyles';
import AppState from 'reducers/interface/AppState';
import ReloadDataButton from 'components/ReloadDataButton';
import TableHeader from 'components/TableHeader';
import EditIcon from '@mui/icons-material/Edit';
import { FormSelect } from 'mdi-material-ui';
import FormActions from 'reducers/types/Form';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {  FormRetrieveListModel } from 'api';
import FormState from 'reducers/interface/FormState';
import Form from 'api/override/FormModel';
import SystemFormCard from './components/SystemFormCard';
import TableFilter from 'components/TableFilter';
import TableList from 'components/TableList';
import EnvUtil from 'utils/EnvUtil';
import CustomActionButton, { CustomLinkIconButton } from 'models/table/CustomActionButton';

interface StateProps {
    formReducer: FormState;
    appReducer: AppState;
}

interface DispatchProps {
    listForms: (params: FormRetrieveListModel) => void;
    clearForms: () => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    form?: Form;
}

class FormList extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customButtons: CustomActionButton[];

    constructor(props) {
        super(props);

        this.state = {
            form: undefined
        };

        this.customButtons = EnvUtil.isProduction() || EnvUtil.isStage() ? [] : [
            new CustomLinkIconButton('formBuilderLink', i18next.t('forms.builder'), 'redir-form-builder', (_, row?: Form) => {
                this.onFormBuilder(row)
            }, EditIcon, '/forms/', 'id', '/builder')
        ]
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'title', isSortable: true, label: i18next.t('general.title')}),
            new TableColumn({ key: 'description', isSortable: true, label: i18next.t('general.description')}),
            new TableColumn({ key: 'system', isSortable: true, label: i18next.t('forms.system'), customValue: (row: Form) => row.system ? <CheckOutlinedIcon/> : <CloseOutlinedIcon/> }),
        ];
    }

    onFormBuilder = (row: Form) => {
        this.props.navigate(`/forms/${row.id}/builder`)
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_FORM_LIST_FAILURE
        ])
        this.updateTabTitle(i18next.t('forms.model_plural'))
    }

    getData = () => {this.reloadData()}

    getItemCount = (): number => {
        return this.props.formReducer.collection.items.length
    }

    reloadData = () => {
        if(this.getParams() != null){
            this.props.listForms( this.getParams().getApiFilter() )
        }
    }

    onDetails = (row?: Form) => {
        this.props.navigate(`/forms/${row?.id}`)
    }

    onCreate = () => {
        this.props.navigate("/forms/create")
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        if(!this.isPathIncludedHistory('forms', this.props.location)){
            this.props.clearForms();
        }
    }

    getSystemForms(): Array<Form> {
        return this.props.formReducer.collection.items?.filter((form: Form) => form.system)
    }

    renderView() {
        const { classes, appReducer, formReducer } = this.props;
        if(this.tableParams == null) {
            return null;
        }
        return (
            <React.Fragment>
                <ReloadDataButton loadData={this.reloadData} />
                <Container maxWidth={"lg"}>
                    <Card className={`${classes.root} ${(EnvUtil.isProduction() || EnvUtil.isStage()) && classes.formPage}`}>
                        <TableHeader
                            entity={i18next.t('forms.model')}
                            headerIcon={<FormSelect/>}
                            onCreate={this.onCreate}
                        />
                        
                        <CardContent className={classes.table_container}>
                            {!appReducer.isFetchingData.get(ActionTypes.API_FORM_LIST) && 
                                <div className={classes.form_card_container}>
                                    {this.getSystemForms().map((form: Form, i: number) => (
                                        <SystemFormCard key={`${form.title}_${i}`} form={form} />
                                    ))}
                                </div>               
                            }
                            <TableFilter
                                searchLabel={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('forms.model_plural')}) }
                                searchPlaceholder={ i18next.t('global.form.search_placeholder', {placeholder: i18next.t('forms.model_plural')}) }
                                onFilterChange={ this.setFilters }
                                columns={ this.tableParams?.filters ?? this.getFilterColumns() }
                                searchText={this.tableParams?.search_text}
                            />
                            <Grid item xs={12} >
                                <TableList
                                    hasDetailsAction
                                    columns={this.columns}
                                    count={formReducer.collection.count_filtered}
                                    listItems={formReducer.collection.items}
                                    isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_FORM_LIST)}
                                    tableParams={ this.getParams() }
                                    customActions={this.customButtons}
                                    delegate={ this }
                                    shouldDisableTopPagination />
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </React.Fragment>
        );
    }
    
    getInitialSorting = (): TableParamsSorting => {
        return new TableParamsSorting('id', 'asc');
    }

    getFilterColumns = (): FilterColumn[] => {
        return [
            
        ]
    }

}

const  mapStateToProps = (state: any) => ({
    formReducer: state.forms,
    appReducer: state.app
});

const mapDispatchToProps = (dispatch) => {
    return {
        listForms: (params: FormRetrieveListModel) => dispatch(FormActions.CreateFormList(params)),
        clearForms: (params: boolean) => dispatch(FormActions.CreateClearFormList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(FormList, TableStyles)
            )
        )
    )
);