import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import { DashboardApi, RetrieveDataRequest } from 'api';
import DashboardActions, { WidgetGetData } from 'reducers/types/Dashboard';

export function* DashboardSagas() {
    yield takeLatest(ActionTypes.API_WIDGET_GET_AVAILABLE, DashboardSaga.getAvailableWidgetsWorkerSaga);
    yield takeLatest(ActionTypes.API_WIDGET_GET_DATA, DashboardSaga.fetchWidgetDataWorkerSaga);
}

export class DashboardSaga {
    static *getAvailableWidgetsWorkerSaga() {
        try {
            const data = yield ApiHandler(new DashboardApi().getAvailable);
            // dispatch a success action to the store with the activities
            yield put(DashboardActions.CreateWidgetGetAvailableSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_WIDGET_GET_AVAILABLE_FAILURE, error as Error))
        }
    }

    static *fetchWidgetDataWorkerSaga(action: WidgetGetData) {
        try {
            const dashboardArgs = {
                widgets: action.params.widgets
            } as RetrieveDataRequest
            const data = yield ApiHandler(new DashboardApi().retrieveData, dashboardArgs);
            // dispatch a success action to the store with the activities
            yield put(DashboardActions.CreateWidgetGetDataSuccess(data));
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_WIDGET_GET_DATA_FAILURE, error as Error))
        }
    }
}

