/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    StepviewModel,
    StepviewModelFromJSON,
    StepviewModelFromJSONTyped,
    StepviewModelToJSON,
    WidgetModel,
    WidgetModelFromJSON,
    WidgetModelFromJSONTyped,
    WidgetModelToJSON,
} from './';


export interface StepviewWidgetModelInterface {
    id?: number;
    stepview_id?: number;
    stepview?: StepviewModel;
    widget_id?: number;
    widget?: WidgetModel;
    position?: number;
    fields?: object;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface StepviewWidgetModel
 */
export class StepviewWidgetModel extends BaseModel<StepviewWidgetModelInterface> {

    /**
     * id of the stepview widget
     * @type {number}
     * @memberof StepviewWidgetModel
     */
    id?: number;
    /**
     * The id of the stepview this stepview widget is on
     * @type {number}
     * @memberof StepviewWidgetModel
     */
    stepview_id?: number;
    /**
     * 
     * @type {StepviewModel}
     * @memberof StepviewWidgetModel
     */
    stepview?: StepviewModel;
    /**
     * The id of the widget this stepview widget based on
     * @type {number}
     * @memberof StepviewWidgetModel
     */
    widget_id?: number;
    /**
     * 
     * @type {WidgetModel}
     * @memberof StepviewWidgetModel
     */
    widget?: WidgetModel;
    /**
     * The position of this widget in the stepview
     * @type {number}
     * @memberof StepviewWidgetModel
     */
    position?: number;
    /**
     * The assigned values to the stepview widget
     * @type {object}
     * @memberof StepviewWidgetModel
     */
    fields?: object;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof StepviewWidgetModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof StepviewWidgetModel
     */
    updated_at?: Date;

    constructor(data?: StepviewWidgetModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: StepviewWidgetModel, value: StringDateInterface<StepviewWidgetModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function StepviewWidgetModelFromJSON(json: any): StepviewWidgetModel {
    return StepviewWidgetModelFromJSONTyped(json, false);
}

export function StepviewWidgetModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepviewWidgetModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = StepviewWidgetModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'stepview_id': !exists(json, 'stepview_id') ? undefined : Number(json['stepview_id']),
        'stepview': !exists(json, 'stepview') ? undefined : StepviewModelFromJSON(json['stepview']),
        'widget_id': !exists(json, 'widget_id') ? undefined : Number(json['widget_id']),
        'widget': !exists(json, 'widget') ? undefined : WidgetModelFromJSON(json['widget']),
        'position': !exists(json, 'position') ? undefined : Number(json['position']),
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function StepviewWidgetModelToJSON(value?: StepviewWidgetModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = StepviewWidgetModel;
    try {
        let localClass = require.context('../override', true)('./StepviewWidgetModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'stepview_id': value.stepview_id,
        'stepview': StepviewModelToJSON(value.stepview),
        'widget_id': value.widget_id,
        'widget': WidgetModelToJSON(value.widget),
        'position': value.position,
        'fields': value.fields,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<StepviewWidgetModel>;
    return createClass.toJson(value, model);
}


