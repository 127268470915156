/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    CentralInstanceListResultModel,
    CentralInstanceListResultModelFromJSON,
    CentralInstanceListResultModelToJSON,
    InstanceRetrieveListModel,
    InstanceRetrieveListModelFromJSON,
    InstanceRetrieveListModelToJSON,
} from '../models';

export interface CentralInstanceListRequest {
    instanceRetrieveListModel: InstanceRetrieveListModel;
}

/**
 * 
 */
export class CentralInstanceApi extends runtime.BaseAPI {

    /**
     * List all central instances
     * List all central instances
     */
    async centralInstanceListRaw(requestParameters: CentralInstanceListRequest): Promise<runtime.ApiResponse<CentralInstanceListResultModel>> {
        if (requestParameters.instanceRetrieveListModel === null || requestParameters.instanceRetrieveListModel === undefined) {
            throw new runtime.RequiredError('instanceRetrieveListModel','Required parameter requestParameters.instanceRetrieveListModel was null or undefined when calling centralInstanceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/central-instances/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceRetrieveListModelToJSON(requestParameters.instanceRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CentralInstanceListResultModelFromJSON(jsonValue));
    }

    /**
     * List all central instances
     * List all central instances
     */
    centralInstanceList = async(requestParameters: CentralInstanceListRequest): Promise<CentralInstanceListResultModel> => {
        const response = await this.centralInstanceListRaw(requestParameters);
        return await response.value();
    }

}
