import React from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Form from 'api/override/FormModel';
import { retrieveFormUpdateSchema } from 'schemas/Form';


interface FormDetailsDialogProps extends BaseFormModalProps<Form> {
    form: Form;
}

const FormDetailsDialog = (props: FormDetailsDialogProps) => {

    const { form } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('forms.model')})}
            initialValues={form}
            validationSchema={retrieveFormUpdateSchema()}
            action={ActionTypes.API_FORM_UPDATE}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="title" 
                        name="title"
                        label={t('general.title')}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                    />

                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('general.description')}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />

                    
                </div>
            }
        />
    );
};

export default FormDetailsDialog;