import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { componentConnect, ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { PackageCreateSuccessModel } from 'api';
import NotificationCenter from 'services/NotificationCenter';
import PackageActions from 'reducers/types/Package';
import TabManager, { TabView } from 'components/tabs/TabManager';
import ReloadDataButton from 'components/ReloadDataButton';
// import PackageDetailsDialog from 'views/Dialog/PackageDetailsDialog';
// import PackageCreateForm from 'views/CreationForms/PackageCreateForm';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import Package from 'api/override/PackageModel';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import DetailsViewHeader from 'components/DetailsViewHeader';
import PackageState from 'reducers/interface/PackageState';
import PackageDetails from './components/PackageDetails';
import PackageCreateForm from 'views/CreationForms/PackageCreateForm';
import PackageDetailsDialog from 'views/Dialog/PackageDetailsDialog';

interface StateProps {
    appReducer: AppState;
    packageReducer: PackageState;
}

interface DispatchProps {
    retrievePackage: (params: number) => void;
    deletePackage: (params: number) => void;
    createPackage: (params: Package) => void;
    updatePackage: (params: Package) => void;
    clearPackages: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    package?: Package;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class PackageView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            package: null,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_PACKAGE_UPDATE_FAILURE, 
            ActionTypes.API_PACKAGE_DELETE_FAILURE, 
            ActionTypes.API_PACKAGE_CREATE_FAILURE,
            ActionTypes.API_PACKAGE_RETRIEVE_FAILURE
        ])
        this.loadData();
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_PACKAGE_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_PACKAGE_UPDATE_SUCCESS, 
            this.onUpdateSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_PACKAGE_DELETE_SUCCESS, 
            this.onDeleteSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_PACKAGE_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({package: action.params}, () => {
            this.updateTabTitle(this.state.package.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const packageSuccess: PackageCreateSuccessModel = action.params
        this.setState({package: new Package(packageSuccess.model)})
        this.props.navigate(`/packages/${packageSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('packages.model')}), {variant: 'success'})
        this.props.clearPackages(true);
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('packages.model')}), {variant: 'success'})
        this.props.clearPackages(true);
        this.reloadData();
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('packages.model')}), {variant: 'success'})
        this.props.clearPackages(true)
        this.props.navigate('/packages')
    }

    clearData = () => {}

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrievePackage(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_PACKAGE_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.package != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, packageReducer, appReducer } = this.props;
        return (
            <React.Fragment>
                {this.shouldRenderDetails() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_PACKAGE_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                    <div>
                                    <DetailsViewHeader
                                        model={i18next.t('packages.model')}
                                        title={this.state.package?.name}
                                    />
                                    <TabManager
                                        items={[
                                            {
                                                title: i18next.t('general.detail'),
                                                link: `/packages/${this.state.package?.id}`,
                                                view: (
                                                    <PackageDetails 
                                                        pk={this.state.package} 
                                                        onDelete={this.onDelete}
                                                        onEdit={this.onEdit}
                                                    />
                                                )
                                            },{
                                                title:  i18next.t('history.model'),
                                                link: `/packages/${this.state.package?.id}/history`,
                                                initialTab: this.isPathIncluded('history'),
                                                view: (
                                                    <HistoryTab 
                                                        model={this.state.package}
                                                    />
                                                )
                                            }
                                        ]}
                                        onTabSwitch={(newTab: TabView) => {
                                            if (newTab.link.includes('history')){
                                                if (packageReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_PACKAGE_HISTORY_LIST)) {
                                                    this.sendNotification({type: ActionTypes.API_PACKAGE_HISTORY_UPDATE}, null);
                                                }
                                            }
                                        }}
                                    />
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <PackageCreateForm
                                            onFormSubmit={(pk: Package) => {
                                                this.props.createPackage(pk);
                                            }}
                                        /> 
                                    </CardContent>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                <PackageDetailsDialog
                    pk={this.state.package}
                    open={this.state.isEditModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onModalSubmit={(pk: Package) => {
                        this.props.updatePackage(pk);
                    }}
                />

                <ConfirmDeleteDialog
                    open={this.state.isDeleteModalOpen}
                    onModalClosePress={this.dialogClosed}
                    onConfirm={() => {
                        this.props.deletePackage(this.state.package.id)
                    }}
                    title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('packages.model')).toLowerCase()})}
                />
                    
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    packageReducer: state.packages,
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrievePackage: (params: number) => dispatch(PackageActions.CreatePackageRetrieve(params)),
        deletePackage: (params: number) => dispatch(PackageActions.CreatePackageDelete(params)),
        createPackage: (params: Package) => dispatch(PackageActions.CreatePackageCreate(params)),
        updatePackage: (params: Package) => dispatch(PackageActions.CreatePackageUpdate(params)),
        clearPackages: (params: boolean) => dispatch(PackageActions.CreateClearPackageList(params))
    }
}

export default componentConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(PackageView, InstanceViewStyles)
            )
        )
    )
);