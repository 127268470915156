import i18next from "i18next";
import * as Yup from "yup";

export const retrieveClientSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    company_name: Yup.string()
        .required(i18next.t('global.validation_schema.required')),
    email: Yup.string()
        .required(i18next.t('global.validation_schema.required'))
        .email(i18next.t('global.validation_schema.invalid_email')),
});