/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface NodeCapacityModelInterface {
    cpu?: string;
    memory?: string;
    pods?: string;
    ephemeral_storage?: string;
}

/**
 * 
 * @export
 * @interface NodeCapacityModel
 */
export class NodeCapacityModel extends BaseModel<NodeCapacityModelInterface> {

    /**
     * value describing CPU
     * @type {string}
     * @memberof NodeCapacityModel
     */
    cpu?: string;
    /**
     * value describing Memory
     * @type {string}
     * @memberof NodeCapacityModel
     */
    memory?: string;
    /**
     * value describing Pods
     * @type {string}
     * @memberof NodeCapacityModel
     */
    pods?: string;
    /**
     * value describing storage
     * @type {string}
     * @memberof NodeCapacityModel
     */
    ephemeral_storage?: string;

    constructor(data?: NodeCapacityModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: NodeCapacityModel, value: StringDateInterface<NodeCapacityModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function NodeCapacityModelFromJSON(json: any): NodeCapacityModel {
    return NodeCapacityModelFromJSONTyped(json, false);
}

export function NodeCapacityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeCapacityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NodeCapacityModel;
    try {
        let localClass = require.context('../override', true)('./NodeCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'cpu': !exists(json, 'cpu') ? undefined : json['cpu'],
        'memory': !exists(json, 'memory') ? undefined : json['memory'],
        'pods': !exists(json, 'pods') ? undefined : json['pods'],
        'ephemeral_storage': !exists(json, 'ephemeral_storage') ? undefined : json['ephemeral_storage'],
    });
}

export function NodeCapacityModelToJSON(value?: NodeCapacityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NodeCapacityModel;
    try {
        let localClass = require.context('../override', true)('./NodeCapacityModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'cpu': value.cpu,
        'memory': value.memory,
        'pods': value.pods,
        'ephemeral_storage': value.ephemeral_storage,
    } as StringDateInterface<NodeCapacityModel>;
    return createClass.toJson(value, model);
}


