import { Theme } from '@mui/material/styles';

const InstanceViewStyles = ((theme: Theme) => ({
    root: {
        minWidth: 275,
        marginTop: '6em'
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title: {
        fontSize: 14,
        marginBottom: '20px'
    },
    header: {
        display: 'flex',
        height: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 40
    },
    error_container: {
        backgroundColor: theme.palette.error.main,
        padding: 5,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: 5,
        margin: '10px auto',
        width: '50%',

    },
    titles: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
        '& > *': {
            margin: 0
        }
    } ,
    chip: {
        color: "#fff",
        alignSelf: 'center',
        marginLeft: 10,
        textTransform: 'uppercase',
        fontWeight: 700,
        height: 'initial',
        padding: 3,
        '&.active': {
            background: theme.palette.success.dark
        },
        '&.deleted': {
            background: theme.palette.error.main
        },
        '&.inactive': {
            background: '#696969'
        },
        '&.migrated': {
            background: theme.palette.warning.main
        }
    },
    button_add_container: {
        color: 'white',
        position: 'relative',
        left: 15,
        top: 35,
        zIndex: 1250
    },
}))

export default InstanceViewStyles