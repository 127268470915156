/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceSettingsValueOptionModelInterface {
    name?: string;
    value?: string;
}

/**
 * 
 * @export
 * @interface InstanceSettingsValueOptionModel
 */
export class InstanceSettingsValueOptionModel extends BaseModel<InstanceSettingsValueOptionModelInterface> {

    /**
     * The name of the value option (just as a description)
     * @type {string}
     * @memberof InstanceSettingsValueOptionModel
     */
    name?: string;
    /**
     * The value of the value option (should be posted as value for the setting)
     * @type {string}
     * @memberof InstanceSettingsValueOptionModel
     */
    value?: string;

    constructor(data?: InstanceSettingsValueOptionModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSettingsValueOptionModel, value: StringDateInterface<InstanceSettingsValueOptionModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSettingsValueOptionModelFromJSON(json: any): InstanceSettingsValueOptionModel {
    return InstanceSettingsValueOptionModelFromJSONTyped(json, false);
}

export function InstanceSettingsValueOptionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSettingsValueOptionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSettingsValueOptionModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsValueOptionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    });
}

export function InstanceSettingsValueOptionModelToJSON(value?: InstanceSettingsValueOptionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSettingsValueOptionModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSettingsValueOptionModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'name': value.name,
        'value': value.value,
    } as StringDateInterface<InstanceSettingsValueOptionModel>;
    return createClass.toJson(value, model);
}


