/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface CentralInstanceModelInterface {
    id?: number;
    name?: string;
    domain?: string;
    fl_cluster_id?: number;
    cluster_name?: string;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface CentralInstanceModel
 */
export class CentralInstanceModel extends BaseModel<CentralInstanceModelInterface> {

    /**
     * id of the instance
     * @type {number}
     * @memberof CentralInstanceModel
     */
    id?: number;
    /**
     * Full name of this instance
     * @type {string}
     * @memberof CentralInstanceModel
     */
    name?: string;
    /**
     * DNS domain name of this instance
     * @type {string}
     * @memberof CentralInstanceModel
     */
    domain?: string;
    /**
     * ID of the cluster this instance belongs to
     * @type {number}
     * @memberof CentralInstanceModel
     */
    fl_cluster_id?: number;
    /**
     * Name of the cluster this instance belongs to
     * @type {string}
     * @memberof CentralInstanceModel
     */
    cluster_name?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof CentralInstanceModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof CentralInstanceModel
     */
    updated_at?: Date;

    constructor(data?: CentralInstanceModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: CentralInstanceModel, value: StringDateInterface<CentralInstanceModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function CentralInstanceModelFromJSON(json: any): CentralInstanceModel {
    return CentralInstanceModelFromJSONTyped(json, false);
}

export function CentralInstanceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CentralInstanceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = CentralInstanceModel;
    try {
        let localClass = require.context('../override', true)('./CentralInstanceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'fl_cluster_id': !exists(json, 'fl_cluster_id') ? undefined : Number(json['fl_cluster_id']),
        'cluster_name': !exists(json, 'cluster_name') ? undefined : json['cluster_name'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function CentralInstanceModelToJSON(value?: CentralInstanceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = CentralInstanceModel;
    try {
        let localClass = require.context('../override', true)('./CentralInstanceModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'domain': value.domain,
        'fl_cluster_id': value.fl_cluster_id,
        'cluster_name': value.cluster_name,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<CentralInstanceModel>;
    return createClass.toJson(value, model);
}


