/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConnectorModel,
    ConnectorModelFromJSON,
    ConnectorModelFromJSONTyped,
    ConnectorModelToJSON,
    ImageVersionModel,
    ImageVersionModelFromJSON,
    ImageVersionModelFromJSONTyped,
    ImageVersionModelToJSON,
    InstanceModel,
    InstanceModelFromJSON,
    InstanceModelFromJSONTyped,
    InstanceModelToJSON,
} from './';


export interface ConnectorContainerModelInterface {
    uuid?: string;
    status?: string;
    instance_id?: number;
    instance_uuid?: string | null;
    connector_id?: number | null;
    config_url?: string | null;
    ready_for_setup?: boolean;
    is_patched?: boolean;
    text?: string | null;
    image_version_id?: number | null;
    image_version?: ImageVersionModel;
    instance?: InstanceModel;
    connector?: ConnectorModel;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

/**
 * 
 * @export
 * @interface ConnectorContainerModel
 */
export class ConnectorContainerModel extends BaseModel<ConnectorContainerModelInterface> {

    /**
     * uuid of the connector container
     * @type {string}
     * @memberof ConnectorContainerModel
     */
    uuid?: string;
    /**
     * Last known status of the container
     * @type {string}
     * @memberof ConnectorContainerModel
     */
    status?: string;
    /**
     * id of the instance connected to this connector
     * @type {number}
     * @memberof ConnectorContainerModel
     */
    instance_id?: number;
    /**
     * uuid of the instance connected to this connector
     * @type {string}
     * @memberof ConnectorContainerModel
     */
    instance_uuid?: string | null;
    /**
     * id of the connector
     * @type {number}
     * @memberof ConnectorContainerModel
     */
    connector_id?: number | null;
    /**
     * URL for fetching configuration
     * @type {string}
     * @memberof ConnectorContainerModel
     */
    config_url?: string | null;
    /**
     * Connector container is ready for initial setup
     * @type {boolean}
     * @memberof ConnectorContainerModel
     */
    ready_for_setup?: boolean;
    /**
     * Connector container is patched to the new DB structure
     * @type {boolean}
     * @memberof ConnectorContainerModel
     */
    is_patched?: boolean;
    /**
     * WYSWYG text about the connector container
     * @type {string}
     * @memberof ConnectorContainerModel
     */
    text?: string | null;
    /**
     * id of the image version
     * @type {number}
     * @memberof ConnectorContainerModel
     */
    image_version_id?: number | null;
    /**
     * 
     * @type {ImageVersionModel}
     * @memberof ConnectorContainerModel
     */
    image_version?: ImageVersionModel;
    /**
     * 
     * @type {InstanceModel}
     * @memberof ConnectorContainerModel
     */
    instance?: InstanceModel;
    /**
     * 
     * @type {ConnectorModel}
     * @memberof ConnectorContainerModel
     */
    connector?: ConnectorModel;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConnectorContainerModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConnectorContainerModel
     */
    updated_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof ConnectorContainerModel
     */
    deleted_at?: Date;

    constructor(data?: ConnectorContainerModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: ConnectorContainerModel, value: StringDateInterface<ConnectorContainerModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function ConnectorContainerModelFromJSON(json: any): ConnectorContainerModel {
    return ConnectorContainerModelFromJSONTyped(json, false);
}

export function ConnectorContainerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorContainerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = ConnectorContainerModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'instance_id': !exists(json, 'instance_id') ? undefined : Number(json['instance_id']),
        'instance_uuid': !exists(json, 'instance_uuid') ? undefined : json['instance_uuid'],
        'connector_id': !exists(json, 'connector_id') ? undefined : Number(json['connector_id']),
        'config_url': !exists(json, 'config_url') ? undefined : json['config_url'],
        'ready_for_setup': !exists(json, 'ready_for_setup') ? undefined : json['ready_for_setup'],
        'is_patched': !exists(json, 'is_patched') ? undefined : json['is_patched'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'image_version_id': !exists(json, 'image_version_id') ? undefined : Number(json['image_version_id']),
        'image_version': !exists(json, 'image_version') ? undefined : ImageVersionModelFromJSON(json['image_version']),
        'instance': !exists(json, 'instance') ? undefined : InstanceModelFromJSON(json['instance']),
        'connector': !exists(json, 'connector') ? undefined : ConnectorModelFromJSON(json['connector']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'].replace(' ', 'T'))),
    });
}

export function ConnectorContainerModelToJSON(value?: ConnectorContainerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = ConnectorContainerModel;
    try {
        let localClass = require.context('../override', true)('./ConnectorContainerModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
        'status': value.status,
        'instance_id': value.instance_id,
        'instance_uuid': value.instance_uuid,
        'connector_id': value.connector_id,
        'config_url': value.config_url,
        'ready_for_setup': value.ready_for_setup,
        'is_patched': value.is_patched,
        'text': value.text,
        'image_version_id': value.image_version_id,
        'image_version': ImageVersionModelToJSON(value.image_version),
        'instance': InstanceModelToJSON(value.instance),
        'connector': ConnectorModelToJSON(value.connector),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
        'deleted_at': value.deleted_at === undefined ? undefined : (value.deleted_at.toApiFormat()),
    } as StringDateInterface<ConnectorContainerModel>;
    return createClass.toJson(value, model);
}


