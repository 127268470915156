import { BranchModel } from "api/models/BranchModel";
import Historiable from "interface/Historiable";

export default class Branch extends BranchModel implements Historiable {
    actionName: string = "branch"
    reducerName: string = "branches"

    children: Branch[] = [];

    assignChildren = (branches: Branch[]): Branch => {
        this.children = branches.filter(br => this.next_ids.includes(br.id))
        for (const br of this.children) {
            br.assignChildren(
                branches.filter(br => !this.next_ids.includes(br.id))
            )
        }
        return this;
    }

}