/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    UserCreateSuccessModel,
    UserCreateSuccessModelFromJSON,
    UserCreateSuccessModelToJSON,
    UserListResultModel,
    UserListResultModelFromJSON,
    UserListResultModelToJSON,
    UserModel,
    UserModelFromJSON,
    UserModelToJSON,
    UserRetrieveListModel,
    UserRetrieveListModelFromJSON,
    UserRetrieveListModelToJSON,
} from '../models';

export interface UserCreateRequest {
    userModel: UserModel;
}

export interface UserDeleteRequest {
    uuid: string;
}

export interface UserHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface UserListRequest {
    userRetrieveListModel: UserRetrieveListModel;
}

export interface UserRetrieveRequest {
    uuid: string;
}

export interface UserUpdateRequest {
    uuid: string;
    userModel: UserModel;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * create a user object
     * create a user object
     */
    async userCreateRaw(requestParameters: UserCreateRequest): Promise<runtime.ApiResponse<UserCreateSuccessModel>> {
        if (requestParameters.userModel === null || requestParameters.userModel === undefined) {
            throw new runtime.RequiredError('userModel','Required parameter requestParameters.userModel was null or undefined when calling userCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserModelToJSON(requestParameters.userModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * create a user object
     * create a user object
     */
    userCreate = async(requestParameters: UserCreateRequest): Promise<UserCreateSuccessModel> => {
        const response = await this.userCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a user object
     * delete a user object
     */
    async userDeleteRaw(requestParameters: UserDeleteRequest): Promise<runtime.ApiResponse<ApiSuccessResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling userDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * delete a user object
     * delete a user object
     */
    userDelete = async(requestParameters: UserDeleteRequest): Promise<ApiSuccessResponseModel> => {
        const response = await this.userDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for a user
     * retrieve a list of historical logs for a user
     */
    async userHistoryListRaw(requestParameters: UserHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling userHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling userHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for a user
     * retrieve a list of historical logs for a user
     */
    userHistoryList = async(requestParameters: UserHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.userHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of user objects
     * retrieve a list of user objects
     */
    async userListRaw(requestParameters: UserListRequest): Promise<runtime.ApiResponse<UserListResultModel>> {
        if (requestParameters.userRetrieveListModel === null || requestParameters.userRetrieveListModel === undefined) {
            throw new runtime.RequiredError('userRetrieveListModel','Required parameter requestParameters.userRetrieveListModel was null or undefined when calling userList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRetrieveListModelToJSON(requestParameters.userRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResultModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of user objects
     * retrieve a list of user objects
     */
    userList = async(requestParameters: UserListRequest): Promise<UserListResultModel> => {
        const response = await this.userListRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a single user object
     * retrieve a single user object
     */
    async userRetrieveRaw(requestParameters: UserRetrieveRequest): Promise<runtime.ApiResponse<UserModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling userRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModelFromJSON(jsonValue));
    }

    /**
     * retrieve a single user object
     * retrieve a single user object
     */
    userRetrieve = async(requestParameters: UserRetrieveRequest): Promise<UserModel> => {
        const response = await this.userRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a user object
     * update a user object
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest): Promise<runtime.ApiResponse<UserCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling userUpdate.');
        }

        if (requestParameters.userModel === null || requestParameters.userModel === undefined) {
            throw new runtime.RequiredError('userModel','Required parameter requestParameters.userModel was null or undefined when calling userUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/user/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserModelToJSON(requestParameters.userModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * update a user object
     * update a user object
     */
    userUpdate = async(requestParameters: UserUpdateRequest): Promise<UserCreateSuccessModel> => {
        const response = await this.userUpdateRaw(requestParameters);
        return await response.value();
    }

}
