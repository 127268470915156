import React from 'react'
import StorageIcon from '@mui/icons-material/Storage';
import { AccountGroup, Package, AppsBox, AutoFix, Cog, Connection, Docker, EmailOpenMultipleOutline, EmailOutline, EmoticonPoop, FolderTableOutline, HelpCircleOutline } from 'mdi-material-ui';
import { SvgIconTypeMap } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import LocalStorageManager from './LocalStorageManager';
import EnvUtil from './EnvUtil';


export default class WidgetUtil {

    static getIconForEntity(entity: string): JSX.Element {
        let AssignedIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> = null;
        switch (entity?.toLocaleLowerCase()) {
            case 'instance':
            case 'instances':
                AssignedIcon = StorageIcon
                break;
            case 'image':
            case 'images':
                AssignedIcon = Docker
                break;
            case 'setting':
            case 'settings':
                AssignedIcon = Cog
                break;
            case 'user':
            case 'users':
                AssignedIcon = PersonIcon
                break;
            case 'connector':
            case 'connectors':
                AssignedIcon = Connection
                break;
            case 'client':
            case 'clients':
                AssignedIcon = AccountGroup
                break;
            case 'property':
            case 'properties':
                AssignedIcon = FolderTableOutline
                break;
            case 'mail':
            case 'mails':
                AssignedIcon = EmailOutline
                break;
            case 'mail-log':
            case 'mail-logs':
                AssignedIcon = EmailOpenMultipleOutline
                break;
            case 'service':
            case 'services':
                AssignedIcon = AppsBox
                break;
            case 'wizard':
            case 'wizards':
                AssignedIcon = AutoFix
                break;
            case 'package':
            case 'packages':
                AssignedIcon = Package
                break;
            default:
                AssignedIcon = EnvUtil.isDevelopment() ? EmoticonPoop : HelpCircleOutline
                break;
        }
        return <AssignedIcon/>
    }

    static setActiveWidgets(widgetNames: Array<string>): void {
        LocalStorageManager.setActiveWidgets(widgetNames)
    }

    static getActiveWidgets(): Array<string> {
        return LocalStorageManager.getActiveWidgets()
    }

    static getNumberOfActiveWidgets(): number {
        return LocalStorageManager.getActiveWidgets()?.length ?? 0
    }

    static isActiveWidget(widgetName: string): boolean {
        return LocalStorageManager.isActiveWidget(widgetName)
    }

    static addActiveWidget(widgetName: string): void {
        LocalStorageManager.addActiveWidget(widgetName)
    }

    static removeActiveWidget(widgetName: string): void {
        LocalStorageManager.removeActiveWidget(widgetName)
    }
}