import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import {useTranslation} from "react-i18next";
import LaunchIcon from '@mui/icons-material/Launch';
import NCNotification from 'models/NCNotification';
import MinimizeIcon from '@mui/icons-material/Minimize';
import useProcessPanelStyles from 'styles/ProcessPanelStyles';
import GlobalProcessProgressNotification from 'models/GlobalProcessProgressNotification';
import GlobalProcess from './GlobalProcess';


interface ProcessPanelProps {
    notifications?: NCNotification[],
    notifRecord?: Record<string, GlobalProcessProgressNotification>
    onDismiss: (key: string) => void;
}

const ProcessPanel = (props: ProcessPanelProps) => {
 
    const { t } = useTranslation();
    const {classes} = useProcessPanelStyles();
    const { notifRecord } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return !isExpanded ?
    <div className={classes.collapsed_panel} onClick={() => setIsExpanded(true)}>
        <p style={{fontSize: '14px'}}>{t('global.process.process_running')}</p>
        <LaunchIcon/>
    </div>
    :
    <div className={classes.expanded_panel}>
        <div className={classes.expanded_panel_header}>
            <p style={{fontSize: '14px'}}>{t('global.process.process_overview')}</p>
            <IconButton
                onClick={() => setIsExpanded(false)}
                className={classes.minimize_button}
                size="large">
                <MinimizeIcon/>
            </IconButton>
        </div>
        <div className={classes.expanded_panel_content}>
            { Object.values(notifRecord).reverse().map((process: GlobalProcessProgressNotification) => !process.isDismissed && <GlobalProcess key={process.notifications[0].getKey()} onDismiss={props.onDismiss} globalProcess={process}/> )}
        </div>
    </div>;
};

export default ProcessPanel;