import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import { MailRetrieveRequest, MailLogApi, MailLogListRequest } from 'api';
import MailLogActions, { MailLogList, MailLogRetrieve } from 'reducers/types/MailLog';
import MailLog from 'api/override/MailLogModel';

export function* MailLogSagas() {
    yield takeLatest(ActionTypes.API_MAIL_LOG_LIST, MailLogSaga.mailLogListWorkerSaga);
    yield takeLatest(ActionTypes.API_MAIL_LOG_RETRIEVE, MailLogSaga.mailLogRetrieveWorkerSaga);
}

export class MailLogSaga {
    
    static *mailLogListWorkerSaga(action: MailLogList) {
        try {
            const mailLogArgs = {
                mailLogRetrieveListModel: action.params
            } as MailLogListRequest

            const data = yield ApiHandler(new MailLogApi().mailLogList, mailLogArgs)
            yield put(MailLogActions.CreateMailLogListSuccess(data))

        } catch(error) {
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_LOG_LIST_FAILURE, error as Error))
        }
    }

    static mailRetrieve = async(mailLog: number): Promise<MailLog> => {
        return await new MailLogApi().mailLogRetrieve({id: mailLog}) as MailLog;
    }
    
    static *mailLogRetrieveWorkerSaga(action: MailLogRetrieve) {
        try {
    
            const mailLogArgs = {
                id: action.params
            } as MailRetrieveRequest
            const data = yield ApiHandler(new MailLogApi().mailLogRetrieve, mailLogArgs)
    
            // dispatch a success action to the store with the activities
            yield put(MailLogActions.CreateMailLogRetrieveSuccess(data))
        } catch(error) {
            // dispatch a failure action to the store with the error
            yield put( AppActions.CreateError(ActionTypes.API_MAIL_LOG_RETRIEVE_FAILURE, error as Error))
        }
    }
}