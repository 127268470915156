import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import SingleSelect from "components/form_controls/SingleSelect";
import MultiSelectOption from "interface/MultiSelectOption";
import ConnectorContainerController from "controllers/ConnectorContainerController";
import { TableParams } from "models/table/TableParams";

interface ConnectorContainerHistorySelectProps {
    instanceUuid: string;
    onContainerSelect?: (ccUuid: string) => void;
}

const ConnectorContainerHistorySelect = (props: ConnectorContainerHistorySelectProps) => {
    const { onContainerSelect, instanceUuid } = props
    const { t } = useTranslation();
    const [connectorContainerController] = useState<ConnectorContainerController>(new ConnectorContainerController())
    const [params] = useState(TableParams.getIncrementingSelectParams());
    const [chosenConnectorContainer, setChosenConnectorContainer] = React.useState<MultiSelectOption>(null);
    const getConnectorContainers = connectorContainerController.connectorContainerToMultiselect;
    const setConnectorContainer = (_, val: MultiSelectOption) => {
        setChosenConnectorContainer(val);
        onContainerSelect(val.value.toString())
    }
    return (
        <div style={{padding: '5px 150px'}}>
            <SingleSelect
                label={t('connector_container.model')}
                value={chosenConnectorContainer}
                name="connector-container-select"
                onChange={setConnectorContainer}
                getAsyncValuesOnSearch
                tableParams={params}
                getAsyncOptions={(params: TableParams) => getConnectorContainers(instanceUuid, params)}
            />
        </div>
    );
}

export default ConnectorContainerHistorySelect;