import React, { createRef, useState } from 'react';
import MultiSelectOption from 'interface/MultiSelectOption';
import SingleSelect from './SingleSelect';
import { FormikProps } from 'formik';
import { TableParams } from 'models/table/TableParams';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ImageController from 'controllers/ImageController';
import { ImageModelForModelEnum } from 'api';
import FilterColumn, { FilterTypes } from 'models/table/FilterColumn';
import { BaseSelectRef } from 'components/base/BaseSelect';


interface ImageSingleSelectProps<T> {
    id: string;
    currentImage?: MultiSelectOption;
    controller: ImageController;
    formik: FormikProps<T>;
    name: string;
    disabled?: boolean;
    label?: string;
    model?: ImageModelForModelEnum[];
    additionalActionOnImageChange?: (formik: FormikProps<T>) => void;
}

function  ImageSingleSelect<T>(props: ImageSingleSelectProps<T>) {
    const { t } = useTranslation();
    const [params] = useState(TableParams.getIncrementingSelectParams([new FilterColumn('for_model', FilterTypes.ENUM, '').setInputValue(props.model)]));
    const parentRef = createRef<BaseSelectRef>();
    const [chosenImage, setChosenImage] = React.useState<MultiSelectOption>(props.formik.values[props.name]);
    const [initImage, setInitImage] = React.useState<MultiSelectOption | undefined>(props.formik.values[`${props.id}`] != null ? {value: props.formik.values[`${props.id}`]?.id, label: props.formik.values[`${props.id}`]?.name} : undefined)
    const getImages = props.controller.imageToMultiselect;

    const handleCurrentImageChange = () => {
        if (props.currentImage != null) {
            setImage(null, props.currentImage);
        }
    }
    // eslint-disable-next-line
    useEffect(handleCurrentImageChange, [props.currentImage])

    const setImage = (_, val: MultiSelectOption) => {
        setInitImage(null)
        setChosenImage(val);
        props.formik.setFieldValue(props.name, `${val.value}`);
    }

    const handleadditionalChange = () => {
        props.additionalActionOnImageChange?.(props.formik)
    }

    // needs to be in useEffect because apparently setFieldValue is slow??
    // eslint-disable-next-line
    React.useEffect(handleadditionalChange, [props.formik.values[props.name]])

    return (
        <SingleSelect
            ref={parentRef}
            disabled={props.disabled}
            label={props.label != null ? props.label : t('connector.image')}
            name={props.name}
            tableParams={params}
            value={initImage != null ? initImage : chosenImage}
            getAsyncValuesOnSearch
            getAsyncOptions={(params: TableParams) => getImages(params)}
            onChange={setImage}
            formikError={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])}
            formikHelperText={props.formik.touched[props.name] ? props.formik.errors[props.name] : ""}
        />
    )
}

export default ImageSingleSelect;
