/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    ImageCreateSuccessModel,
    ImageCreateSuccessModelFromJSON,
    ImageCreateSuccessModelToJSON,
    ImageListResultModel,
    ImageListResultModelFromJSON,
    ImageListResultModelToJSON,
    ImageModel,
    ImageModelFromJSON,
    ImageModelToJSON,
    ImageRemoveSuccessModel,
    ImageRemoveSuccessModelFromJSON,
    ImageRemoveSuccessModelToJSON,
    ImageRetrieveListModel,
    ImageRetrieveListModelFromJSON,
    ImageRetrieveListModelToJSON,
} from '../models';

export interface ImageCreateRequest {
    imageModel: ImageModel;
}

export interface ImageDeleteRequest {
    id: number;
}

export interface ImageHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ImageListRequest {
    imageRetrieveListModel: ImageRetrieveListModel;
}

export interface ImageRetrieveRequest {
    id: number;
}

export interface ImageUpdateRequest {
    id: number;
    imageModel: ImageModel;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     * Create a new image
     * Create a new image
     */
    async imageCreateRaw(requestParameters: ImageCreateRequest): Promise<runtime.ApiResponse<ImageCreateSuccessModel>> {
        if (requestParameters.imageModel === null || requestParameters.imageModel === undefined) {
            throw new runtime.RequiredError('imageModel','Required parameter requestParameters.imageModel was null or undefined when calling imageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImageModelToJSON(requestParameters.imageModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new image
     * Create a new image
     */
    imageCreate = async(requestParameters: ImageCreateRequest): Promise<ImageCreateSuccessModel> => {
        const response = await this.imageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an image
     * Delete an image
     */
    async imageDeleteRaw(requestParameters: ImageDeleteRequest): Promise<runtime.ApiResponse<ImageRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete an image
     * Delete an image
     */
    imageDelete = async(requestParameters: ImageDeleteRequest): Promise<ImageRemoveSuccessModel> => {
        const response = await this.imageDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an image
     * retrieve a list of historical logs for an image
     */
    async imageHistoryListRaw(requestParameters: ImageHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling imageHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an image
     * retrieve a list of historical logs for an image
     */
    imageHistoryList = async(requestParameters: ImageHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.imageHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all images
     * List all images
     */
    async imageListRaw(requestParameters: ImageListRequest): Promise<runtime.ApiResponse<ImageListResultModel>> {
        if (requestParameters.imageRetrieveListModel === null || requestParameters.imageRetrieveListModel === undefined) {
            throw new runtime.RequiredError('imageRetrieveListModel','Required parameter requestParameters.imageRetrieveListModel was null or undefined when calling imageList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImageRetrieveListModelToJSON(requestParameters.imageRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageListResultModelFromJSON(jsonValue));
    }

    /**
     * List all images
     * List all images
     */
    imageList = async(requestParameters: ImageListRequest): Promise<ImageListResultModel> => {
        const response = await this.imageListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a image by its id
     * Retrieve an image by its id
     */
    async imageRetrieveRaw(requestParameters: ImageRetrieveRequest): Promise<runtime.ApiResponse<ImageModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a image by its id
     * Retrieve an image by its id
     */
    imageRetrieve = async(requestParameters: ImageRetrieveRequest): Promise<ImageModel> => {
        const response = await this.imageRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an image
     * Update an image
     */
    async imageUpdateRaw(requestParameters: ImageUpdateRequest): Promise<runtime.ApiResponse<ImageCreateSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageUpdate.');
        }

        if (requestParameters.imageModel === null || requestParameters.imageModel === undefined) {
            throw new runtime.RequiredError('imageModel','Required parameter requestParameters.imageModel was null or undefined when calling imageUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImageModelToJSON(requestParameters.imageModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an image
     * Update an image
     */
    imageUpdate = async(requestParameters: ImageUpdateRequest): Promise<ImageCreateSuccessModel> => {
        const response = await this.imageUpdateRaw(requestParameters);
        return await response.value();
    }

}
