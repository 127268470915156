import React, { useEffect, useState } from 'react';
import useFormBuilderStyles from 'styles/FormBuilderStyles';
import { useTranslation } from 'react-i18next';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import FormField from 'api/override/FormFieldModel';
import { IconButton, Tooltip } from '@mui/material';
import { ChevronDoubleDown, ChevronDoubleUp, ChevronDown, ChevronUp, Minus, Plus } from 'mdi-material-ui';
import { DirectionsEnum } from 'config/Constants';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface OptionsPaneProps {
    field: FormField;
    onChangeField: (field: FormField) => void;
    onReorder: (direction: DirectionsEnum) => void;
    formInputsLength: number;
}

const OptionsPane = (props: OptionsPaneProps) => {
    const { t } = useTranslation();
    const {classes} = useFormBuilderStyles();
    const [fieldInput, setFieldInput] = useState<FormField>(props.field)
    const [fieldInputOptions, setFieldInputOptions] = useState<Array<string>>(props.field.getOptions())

    const handleFieldChange = (e, prop: string) => {
        const newFieldInput = fieldInput.copy();
        switch (prop) {
            case 'label':
                newFieldInput.label = e.target.value;
                break;
            case 'name':
                newFieldInput.name = e.target.value;
                break;
            case 'require':
                newFieldInput.require = !fieldInput?.require ?? true;
                break;
            default:
                break;
        }
        setFieldInput(newFieldInput);
    }

    const isFirstInput = (): boolean => props.field?.position === 1
    const isLastInput = (): boolean => props.field?.position === props.formInputsLength

    useEffect(() => {
        props.onChangeField(fieldInput)
        // eslint-disable-next-line
    }, [fieldInput])

    useEffect(() => {
        setFieldInput(props.field)
        setFieldInputOptions(props.field.getOptions())
    }, [props.field])

    const sharedOptions: JSX.Element = 
        <>
            <div className={classes.rearrange_btns_container}>
                <Tooltip title={t('forms.input_to_top') ?? ''}><span><IconButton
                    disabled={isFirstInput()}
                    onClick={() => props.onReorder(DirectionsEnum.TOP)}
                    size="large"><ChevronDoubleUp/></IconButton></span></Tooltip>
                <Tooltip title={t('forms.input_up') ?? ''}><span><IconButton
                    disabled={isFirstInput()}
                    onClick={() => props.onReorder(DirectionsEnum.UP)}
                    size="large"><ChevronUp/></IconButton></span></Tooltip>
                <Tooltip title={t('forms.input_down') ?? ''}><span><IconButton
                    disabled={isLastInput()}
                    onClick={() => props.onReorder(DirectionsEnum.DOWN)}
                    size="large"><ChevronDown/></IconButton></span></Tooltip>
                <Tooltip title={t('forms.input_to_bottom') ?? ''}><span><IconButton
                    disabled={isLastInput()}
                    onClick={() => props.onReorder(DirectionsEnum.BOTTOM)}
                    size="large"><ChevronDoubleDown/></IconButton></span></Tooltip>
            </div>
            <TextFieldStyled
                label={t('general.name')}
                onChange={(e) => handleFieldChange(e, 'name')}
                value={fieldInput?.name ?? ''}
            />
            <TextFieldStyled
                label={t('general.label')}
                onChange={(e) => handleFieldChange(e, 'label')}
                value={fieldInput?.label ?? ''}
            />
            <BBCheckbox
                checkboxProps={{
                    checked: fieldInput?.require ?? false,
                    onChange: (e) => handleFieldChange(e, 'require'),
                    name: 'require'
                }}
                label={t('global.form.required')}
            />
        </>

    const handleAddNewOption = () => {
        setFieldInputOptions([...fieldInputOptions, ''])
    }

    const updateFieldWithOptions = (newArr: Array<string>) => {
        setFieldInputOptions([...newArr])
        let newFieldInput = fieldInput.copy();
        newFieldInput.options = JSON.stringify(newArr)
        setFieldInput(newFieldInput)
        props.onChangeField(newFieldInput)
    }

    const removeOption = (index: number) => {
        const newArr: Array<string> = fieldInputOptions;
        newArr.splice(index, 1)
        updateFieldWithOptions(newArr)
    }

    const handleFieldOptionChange = (val: string, index: number) => {
        const newArr: Array<string> = fieldInputOptions;
        for (const i in fieldInputOptions) {
            if(i === index.toString()) {
                newArr[index] = val;
                break;
            }
        }
        updateFieldWithOptions(newArr)
    }

    const renderOptions = (): JSX.Element => {
        switch (fieldInput.type) {
            case 'text':
            case 'email':
            case 'checkbox':
            case 'textarea':
            case 'tel':
                return (
                    <>
                        {sharedOptions}
                    </> 
                )
            case 'radio':
            case 'select':
                return <>
                    {sharedOptions}
                    <h3 style={{textAlign: 'center'}}>{t('general.option_plural')}</h3>
                    {fieldInputOptions.map((opt: string, i: number) => {
                        return (
                            <div className={classes.options_field_container} key={`${fieldInput.input_field_uuid}_${i}`}>
                            <TextFieldStyled
                                label={`${t('general.option')} ${i}`}
                                onChange={(e) => handleFieldOptionChange(e.target?.value, i)}
                                value={opt ?? ''}
                            />
                            <IconButton
                                onClick={() => removeOption(i)}
                                key={`input_remove_btn_${i}`}
                                size="large">
                                <Minus />
                            </IconButton>
                            </div>
                        );
                    })}
                    <IconButton
                        onClick={() => handleAddNewOption()}
                        key={`input_add_btn`}
                        color="primary"
                        className={classes.add_option_btn}
                        size="large">
                        <Plus />
                    </IconButton>
                </>;
            default:
                return (<></>)
        }
    }

    return (
        <div className={classes.options_pane}>
            {fieldInput != null &&
                <div className={classes.options_pane_inputs}>
                    {renderOptions()}
                </div>
            }
        </div>
    );
};

export default OptionsPane;