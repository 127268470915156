import { ApiSuccessResponseModel, HistoryResponseModel } from "api";
import { StepviewHistoryListRequest } from "api/apis/StepviewApi";
import { StepviewCreateSuccessModel } from "api/models/StepviewCreateSuccessModel";
import { StepviewListResultModel } from "api/models/StepviewListResultModel";
import { StepviewRetrieveListModel } from "api/models/StepviewRetrieveListModel";
import Stepview from "api/override/StepviewModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface StepviewList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_LIST;
    params: StepviewRetrieveListModel;
}

export interface StepviewListSuccess extends BaseSagaListResponse<StepviewListResultModel> {
    type: ActionTypes.API_STEPVIEW_LIST_SUCCESS;
}

export interface StepviewCreate extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_CREATE;
    params: Stepview;
}

export interface StepviewCreateSuccess extends BaseSagaListResponse<StepviewCreateSuccessModel> {
    type: ActionTypes.API_STEPVIEW_CREATE_SUCCESS;
}

export interface StepviewUpdateParams {
    id: number;
    stepviewModel: Stepview;
}

export interface StepviewUpdate extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_UPDATE;
    params: StepviewUpdateParams;
}

export interface StepviewUpdateSuccess extends BaseSagaListResponse<StepviewCreateSuccessModel> {
    type: ActionTypes.API_STEPVIEW_UPDATE_SUCCESS;
}

export interface StepviewRetrieve extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_RETRIEVE;
    params: number;
}

export interface StepviewRetrieveSuccess extends BaseSagaListResponse<Stepview> {
    type: ActionTypes.API_STEPVIEW_RETRIEVE_SUCCESS
}

export interface StepviewDelete extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_DELETE;
    params: number;
}

export interface StepviewDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_STEPVIEW_DELETE_SUCCESS;
    deletedId: number;
}

export interface StepviewHistoryList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_HISTORY_LIST;
    params: StepviewHistoryListRequest;
}

export interface StepviewHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_STEPVIEW_HISTORY_LIST_SUCCESS;
}

export interface StepviewClearListParams {
    force?: boolean;
}
export interface StepviewClearList extends BaseReducerAction {
    type: ActionTypes.API_STEPVIEW_LIST_CLEAR;
    params?: StepviewClearListParams;
}

export default class StepviewActions {
    
    static CreateStepviewList(params: StepviewRetrieveListModel): StepviewList {
        return {
            type: ActionTypes.API_STEPVIEW_LIST,
            params: params
        }
    }

    static CreateStepviewListSuccess(params: StepviewListResultModel): StepviewListSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_LIST_SUCCESS,
            params: params
        }
    }

    static CreateStepviewCreate(params: Stepview): StepviewCreate {
        return {
            type: ActionTypes.API_STEPVIEW_CREATE,
            params: params
        }
    }

    static CreateStepviewCreateSuccess(params: StepviewCreateSuccessModel): StepviewCreateSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewUpdate(params: Stepview): StepviewUpdate {
        return {
            type: ActionTypes.API_STEPVIEW_UPDATE,
            params: {
                id: params.id,
                stepviewModel: params
            }
        }
    }

    static CreateStepviewUpdateSuccess(params: StepviewCreateSuccessModel): StepviewUpdateSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewRetrieve(params: number): StepviewRetrieve {
        return {
            type: ActionTypes.API_STEPVIEW_RETRIEVE,
            params: params
        }
    }

    static CreateStepviewRetrieveSuccess(params: Stepview): StepviewRetrieveSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateStepviewDelete(params: number): StepviewDelete {
        return {
            type: ActionTypes.API_STEPVIEW_DELETE,
            params: params
        }
    }

    static CreateStepviewDeleteSuccess(params: ApiSuccessResponseModel, deletedId: number): StepviewDeleteSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_DELETE_SUCCESS,
            params: params,
            deletedId: deletedId
        }
    }

    static CreateStepviewHistoryList(params: StepviewHistoryListRequest): StepviewHistoryList {
        return {
            type: ActionTypes.API_STEPVIEW_HISTORY_LIST,
            params: params
        }
    }

    static CreateStepviewHistoryListSuccess(params: HistoryResponseModel): StepviewHistoryListSuccess {
        return {
            type: ActionTypes.API_STEPVIEW_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearStepviewList(force: boolean = false): StepviewClearList {
        return {
            type: ActionTypes.API_STEPVIEW_LIST_CLEAR,
            params: {force: force}
        }   
    }

}