import React  from 'react';
import { useTranslation } from 'react-i18next';
import BaseWidget, { BaseWidgetProps } from "./BaseWidget";
import WidgetRowView from './WidgetRowView';

interface LiveCountWidgetProps extends BaseWidgetProps {
}

/*
    t('dashboard.widgets.live_connector_container_count');
    t('dashboard.widgets.live_connector_count');
    t('dashboard.widgets.live_instance_count');
    t('dashboard.widgets.live_service_count');
    t('dashboard.widgets.live_service_container_count');
*/
const LiveCountWidget = (props: LiveCountWidgetProps) => {
 
    const { t } = useTranslation();
    const { data } = props;
    
    return (
        <BaseWidget {...props}>
            <WidgetRowView isHeader>
                <h4>{t('dashboard.widgets.entity')}</h4>
                <h4>{t('dashboard.widgets.count')}</h4>
            </WidgetRowView>
            {Object.entries(data).map(([key, widgetData]) => {
                return (
                    <WidgetRowView key={`liveCount_${key}`}>
                        <p>{t(`dashboard.widgets.${key}`)}</p>
                        <p>{widgetData}</p>
                    </WidgetRowView>
                )
            })}
        </BaseWidget>
    );
};

export default LiveCountWidget;