/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface SettingListFilterModelInterface {
    include_removed?: boolean;
    type?: Array<string>;
    group?: Array<string>;
}

/**
 * 
 * @export
 * @interface SettingListFilterModel
 */
export class SettingListFilterModel extends BaseModel<SettingListFilterModelInterface> {

    /**
     * Include removed settings
     * @type {boolean}
     * @memberof SettingListFilterModel
     */
    include_removed?: boolean;
    /**
     * Types
     * @type {Array<string>}
     * @memberof SettingListFilterModel
     */
    type?: Array<string>;
    /**
     * Group
     * @type {Array<string>}
     * @memberof SettingListFilterModel
     */
    group?: Array<string>;

    constructor(data?: SettingListFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: SettingListFilterModel, value: StringDateInterface<SettingListFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function SettingListFilterModelFromJSON(json: any): SettingListFilterModel {
    return SettingListFilterModelFromJSONTyped(json, false);
}

export function SettingListFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingListFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = SettingListFilterModel;
    try {
        let localClass = require.context('../override', true)('./SettingListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'include_removed': !exists(json, 'include_removed') ? undefined : json['include_removed'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'group': !exists(json, 'group') ? undefined : json['group'],
    });
}

export function SettingListFilterModelToJSON(value?: SettingListFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = SettingListFilterModel;
    try {
        let localClass = require.context('../override', true)('./SettingListFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'include_removed': value.include_removed,
        'type': value.type,
        'group': value.group,
    } as StringDateInterface<SettingListFilterModel>;
    return createClass.toJson(value, model);
}


