/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    InstanceUserModel,
    InstanceUserModelFromJSON,
    InstanceUserModelFromJSONTyped,
    InstanceUserModelToJSON,
    InstanceUsersListResultModelAllOf,
    InstanceUsersListResultModelAllOfFromJSON,
    InstanceUsersListResultModelAllOfFromJSONTyped,
    InstanceUsersListResultModelAllOfToJSON,
} from './';


export interface InstanceUsersListResultModelInterface {
    message?: string;
    code?: number;
    data?: Array<InstanceUserModel>;
    success?: boolean;
}

/**
 * 
 * @export
 * @interface InstanceUsersListResultModel
 */
export class InstanceUsersListResultModel extends BaseModel<InstanceUsersListResultModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof InstanceUsersListResultModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof InstanceUsersListResultModel
     */
    code?: number;
    /**
     * 
     * @type {Array<InstanceUserModel>}
     * @memberof InstanceUsersListResultModel
     */
    data?: Array<InstanceUserModel>;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof InstanceUsersListResultModel
     */
    success?: boolean;

    constructor(data?: InstanceUsersListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceUsersListResultModel, value: StringDateInterface<InstanceUsersListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceUsersListResultModelFromJSON(json: any): InstanceUsersListResultModel {
    return InstanceUsersListResultModelFromJSONTyped(json, false);
}

export function InstanceUsersListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceUsersListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceUsersListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceUsersListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InstanceUserModelFromJSON)),
        'success': !exists(json, 'success') ? undefined : json['success'],
    });
}

export function InstanceUsersListResultModelToJSON(value?: InstanceUsersListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceUsersListResultModel;
    try {
        let localClass = require.context('../override', true)('./InstanceUsersListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InstanceUserModelToJSON)),
        'success': value.success,
    } as StringDateInterface<InstanceUsersListResultModel>;
    return createClass.toJson(value, model);
}


