import { ApiSuccessResponseModel, HistoryResponseModel, PackageCreateSuccessModel, PackageHistoryListRequest, PackageListResultModel, PackageRetrieveListModel } from "api";
import Package from "api/override/PackageModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";


export interface PackageReducerAction extends BaseReducerAction {
    errorObj?: any;
}

export interface PackageList extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_LIST;
    params: PackageRetrieveListModel;
}

export interface PackageListSuccess extends BaseSagaListResponse<PackageListResultModel> {
    type: ActionTypes.API_PACKAGE_LIST_SUCCESS;
}

export interface PackageUpdate extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_UPDATE;
    params: Package;
}

export interface PackageUpdateSuccess extends BaseSagaListResponse<PackageCreateSuccessModel> { 
    type: ActionTypes.API_PACKAGE_UPDATE_SUCCESS;
}

export interface PackageRetrieve extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_RETRIEVE;
    params: number;
}

export interface PackageRetrieveSuccess extends BaseSagaListResponse<Package> {
    type: ActionTypes.API_PACKAGE_RETRIEVE_SUCCESS;
}

export interface PackageDelete extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_DELETE;
    params: number;
}

export interface PackageDeleteSuccess extends BaseSagaListResponse<ApiSuccessResponseModel> {
    type: ActionTypes.API_PACKAGE_DELETE_SUCCESS;
}

export interface PackageCreate extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_CREATE;
    params: Package;
}

export interface PackageCreateSuccess extends BaseSagaListResponse<PackageCreateSuccessModel> {
    type: ActionTypes.API_PACKAGE_CREATE_SUCCESS;
}

export interface PackageHistoryList extends PackageReducerAction {
    type: ActionTypes.API_PACKAGE_HISTORY_LIST;
    params: PackageHistoryListRequest;
}

export interface PackageHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_PACKAGE_HISTORY_LIST_SUCCESS;
}

export interface PackageClearListParams {
    force?: boolean;
}
export interface PackageClearList extends BaseReducerAction {
    type: ActionTypes.API_PACKAGE_LIST_CLEAR;
    params?: PackageClearListParams;
}

export default class PackageActions {

    static CreatePackageList(params: PackageRetrieveListModel): PackageList {
        return {
            type: ActionTypes.API_PACKAGE_LIST,
            params: params
        }
    }

    static CreatePackageListSuccess(params: PackageListResultModel): PackageListSuccess {
        return {
            type: ActionTypes.API_PACKAGE_LIST_SUCCESS,
            params: params
        }
    }

    static CreatePackageUpdate(params: Package): PackageUpdate {
        return {
            type: ActionTypes.API_PACKAGE_UPDATE,
            params: params
        }
    }

    static CreatePackageUpdateSuccess(params: PackageCreateSuccessModel): PackageUpdateSuccess {
        return {
            type: ActionTypes.API_PACKAGE_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreatePackageRetrieve(params: number): PackageRetrieve {
        return {
            type: ActionTypes.API_PACKAGE_RETRIEVE,
            params: params
        }
    }

    static CreatePackageRetrieveSuccess(params: Package): PackageRetrieveSuccess {
        return {
            type: ActionTypes.API_PACKAGE_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreatePackageDelete(params: number): PackageDelete {
        return {
            type: ActionTypes.API_PACKAGE_DELETE,
            params: params
        }
    }

    static CreatePackageDeleteSuccess(params: ApiSuccessResponseModel): PackageDeleteSuccess {
        return {
            type: ActionTypes.API_PACKAGE_DELETE_SUCCESS,
            params: params
        }
    }

    static CreatePackageCreate(params: Package): PackageCreate {
        return {
            type: ActionTypes.API_PACKAGE_CREATE,
            params: params
        }
    }

    static CreatePackageCreateSuccess(params: PackageCreateSuccessModel): PackageCreateSuccess {
        return {
            type: ActionTypes.API_PACKAGE_CREATE_SUCCESS,
            params: params
        }
    }

    static CreatePackageHistoryList(params: PackageHistoryListRequest): PackageHistoryList {
        return {
            type: ActionTypes.API_PACKAGE_HISTORY_LIST,
            params: params
        }
    }

    static CreatePackageHistoryListSuccess(params: HistoryResponseModel): PackageHistoryListSuccess {
        return {
            type: ActionTypes.API_PACKAGE_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearPackageList(force: boolean = false): PackageClearList {
        return {
            type: ActionTypes.API_PACKAGE_LIST_CLEAR,
            params: {force: force}
        }   
    }
}