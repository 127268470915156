import { HistoryResponseModel, ImageVersionCreateSuccessModel, ImageVersionHistoryListRequest, ImageVersionListResultModel, ImageVersionRemoveSuccessModel, ImageVersionRetrieveListModel } from "api";
import ImageVersion from "api/override/ImageVersionModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface ImageVersionReducerAction extends BaseReducerAction {
    errorObj?: any;
}
export interface ImageVersionList extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_LIST;
    params: ImageVersionRetrieveListModel;
}

export interface ImageVersionListSuccess extends BaseSagaListResponse<ImageVersionListResultModel> {
    type: ActionTypes.API_IMAGE_VERSION_LIST_SUCCESS;
}

export interface ImageVersionCreate extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_CREATE;
    params: ImageVersion;
}

export interface ImageVersionCreateSuccess extends BaseSagaListResponse<ImageVersionCreateSuccessModel> {
    type: ActionTypes.API_IMAGE_VERSION_CREATE_SUCCESS;
}

export interface ImageVersionUpdateParams {
    id: number;
    imageVersionModel: ImageVersion;
}

export interface ImageVersionUpdate extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_UPDATE;
    params: ImageVersionUpdateParams;
}

export interface ImageVersionUpdateSuccess extends BaseSagaListResponse<ImageVersionCreateSuccessModel> {
    type: ActionTypes.API_IMAGE_VERSION_UPDATE_SUCCESS;
}

export interface ImageVersionRetrieve extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_RETRIEVE;
    params: number;
}

export interface ImageVersionRetrieveSuccess extends BaseSagaListResponse<ImageVersion> {
    type: ActionTypes.API_IMAGE_VERSION_RETRIEVE_SUCCESS
}

export interface ImageVersionDelete extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_DELETE;
    params: number;
}

export interface ImageVersionDeleteSuccess extends BaseSagaListResponse<ImageVersionRemoveSuccessModel> {
    type: ActionTypes.API_IMAGE_VERSION_DELETE_SUCCESS;
}

export interface ImageVersionHistoryList extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_VERSION_HISTORY_LIST;
    params: ImageVersionHistoryListRequest;
}

export interface ImageVersionHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_IMAGE_VERSION_HISTORY_LIST_SUCCESS;
}

export default class ImageVersionActions {
    
    static CreateImageVersionList(params: ImageVersionRetrieveListModel): ImageVersionList {
        return {
            type: ActionTypes.API_IMAGE_VERSION_LIST,
            params: params
        }
    }

    static CreateImageVersionListSuccess(params: ImageVersionListResultModel): ImageVersionListSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_LIST_SUCCESS,
            params: params
        }
    }

    static CreateImageVersionCreate(params: ImageVersion): ImageVersionCreate {
        return {
            type: ActionTypes.API_IMAGE_VERSION_CREATE,
            params: params
        }
    }

    static CreateImageVersionCreateSuccess(params: ImageVersionCreateSuccessModel): ImageVersionCreateSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateImageVersionUpdate(params: ImageVersion): ImageVersionUpdate {
        return {
            type: ActionTypes.API_IMAGE_VERSION_UPDATE,
            params: {
                id: params.id,
                imageVersionModel: params
            }
        }
    }

    static CreateImageVersionUpdateSuccess(params: ImageVersionCreateSuccessModel): ImageVersionUpdateSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateImageVersionRetrieve(params: number): ImageVersionRetrieve {
        return {
            type: ActionTypes.API_IMAGE_VERSION_RETRIEVE,
            params: params
        }
    }

    static CreateImageVersionRetrieveSuccess(params: ImageVersion): ImageVersionRetrieveSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateImageVersionDelete(params: number): ImageVersionDelete {
        return {
            type: ActionTypes.API_IMAGE_VERSION_DELETE,
            params: params
        }
    }

    static CreateImageVersionDeleteSuccess(params: ImageVersionRemoveSuccessModel): ImageVersionDeleteSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateImageVersionHistoryList(params: ImageVersionHistoryListRequest): ImageVersionHistoryList {
        return {
            type: ActionTypes.API_IMAGE_VERSION_HISTORY_LIST,
            params: params
        }
    }

    static CreateImageVersionHistoryListSuccess(params: HistoryResponseModel): ImageVersionHistoryListSuccess {
        return {
            type: ActionTypes.API_IMAGE_VERSION_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

}