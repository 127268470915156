import { ApiResponseModel, ConnectorHistoryListRequest, ConnectorInstanceListResultModel, ConnectorInstanceRetrieveListModel, ConnectorListResultModel, ConnectorRemoveSuccessModel, ConnectorRetrieveListModel, ConnectorSuccessResponseModel, HistoryResponseModel, ProcessListResultModel } from "api";
import ActionTypes from "config/ActionTypes";
import { TableParams } from "models/table/TableParams";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";
import Connector from 'api/override/ConnectorModel';

export interface ConnectorList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_LIST;
    params: ConnectorRetrieveListModel;
}

export interface ConnectorListSuccess extends BaseSagaListResponse<ConnectorListResultModel> {
    type: ActionTypes.API_CONNECTOR_LIST_SUCCESS;
}

export interface ConnectorListInstancesParams {
    uuid: string;
    connectorInstanceRetrieveListModel: ConnectorInstanceRetrieveListModel;
}
export interface ConnectorListInstances extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_LIST_INSTANCES;
    params: ConnectorListInstancesParams;
}

export interface ConnectorListInstancesSuccess extends BaseSagaListResponse<ConnectorInstanceListResultModel>{
    type: ActionTypes.API_CONNECTOR_LIST_INSTANCES_SUCCESS;
}

export interface ConnectorCreate extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_CREATE;
    params: Connector;
}

export interface ConnectorCreateSuccess extends BaseSagaListResponse<ConnectorSuccessResponseModel> {
    type: ActionTypes.API_CONNECTOR_CREATE_SUCCESS;
}

export interface ConnectorUpdateParams {
    uuid: string;
    connectorModel: Connector;
}

export interface ConnectorUpdate extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_UPDATE;
    params: ConnectorUpdateParams;
}

export interface ConnectorUpdateSuccess extends BaseSagaListResponse<ConnectorSuccessResponseModel> {
    type: ActionTypes.API_CONNECTOR_UPDATE_SUCCESS;
}

export interface ConnectorRetrieve extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_RETRIEVE;
    params: string;
}

export interface ConnectorRetrieveSuccess extends BaseSagaListResponse<Connector> {
    type: ActionTypes.API_CONNECTOR_RETRIEVE_SUCCESS
}

export interface ConnectorDelete extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_DELETE;
    params: string;
}

export interface ConnectorDeleteSuccess extends BaseSagaListResponse<ConnectorRemoveSuccessModel> {
    type: ActionTypes.API_CONNECTOR_DELETE_SUCCESS;
}

export interface ConnectorTriggerJobParams {
    uuid: string;
    task: string;
    uuids?: string[];
}

export interface ConnectorTriggerJob extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_TRIGGER_JOB;
    params: ConnectorTriggerJobParams;
}

export interface ConnectorTriggerJobSuccess extends BaseSagaListResponse<ApiResponseModel> {
    type: ActionTypes.API_CONNECTOR_TRIGGER_JOB_SUCCESS;
}
export interface ConnectorLogRequest {
    uuid: string;
    tableParams: TableParams;
}
export interface ConnectorLogsList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_LOGS_LIST;
    params: ConnectorLogRequest;
}

export interface ConnectorLogsListSuccess extends BaseSagaListResponse<ProcessListResultModel> {
    type: ActionTypes.API_CONNECTOR_LOGS_LIST_SUCCESS;
}

export interface ConnectorHistoryList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_HISTORY_LIST;
    params: ConnectorHistoryListRequest;
}

export interface ConnectorHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_CONNECTOR_HISTORY_LIST_SUCCESS;
}

export interface ConnectorClearListParams {
    force?: boolean;
}
export interface ConnectorClearList extends BaseReducerAction {
    type: ActionTypes.API_CONNECTOR_LIST_CLEAR;
    params?: ConnectorClearListParams;
}

export default class ConnectorActions {
    
    static CreateConnectorList(params: ConnectorRetrieveListModel): ConnectorList {
        return {
            type: ActionTypes.API_CONNECTOR_LIST,
            params: params
        }
    }

    static CreateConnectorListSuccess(params: ConnectorListResultModel): ConnectorListSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_LIST_SUCCESS,
            params: params
        }
    }

    static CreateConnectorListInstances(params: ConnectorListInstancesParams): ConnectorListInstances {
        return {
            type: ActionTypes.API_CONNECTOR_LIST_INSTANCES,
            params: params
        }
    }

    static CreateConnectorListInstancesSuccess(params: ConnectorInstanceListResultModel): ConnectorListInstancesSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_LIST_INSTANCES_SUCCESS,
            params: params
        }
    }

    static CreateConnectorCreate(params: Connector): ConnectorCreate {
        return {
            type: ActionTypes.API_CONNECTOR_CREATE,
            params: params
        }
    }

    static CreateConnectorCreateSuccess(params: ConnectorSuccessResponseModel): ConnectorCreateSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorUpdate(params: Connector): ConnectorUpdate {
        return {
            type: ActionTypes.API_CONNECTOR_UPDATE,
            params: {
                uuid: params.uuid,
                connectorModel: params
            }
        }
    }

    static CreateConnectorUpdateSuccess(params: ConnectorSuccessResponseModel): ConnectorUpdateSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorRetrieve(params: string): ConnectorRetrieve {
        return {
            type: ActionTypes.API_CONNECTOR_RETRIEVE,
            params: params
        }
    }

    static CreateConnectorRetrieveSuccess(params: Connector): ConnectorRetrieveSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorDelete(params: string): ConnectorDelete {
        return {
            type: ActionTypes.API_CONNECTOR_DELETE,
            params: params
        }
    }

    static CreateConnectorDeleteSuccess(params: ConnectorRemoveSuccessModel): ConnectorDeleteSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateConnectorTriggerJob(params: ConnectorTriggerJobParams): ConnectorTriggerJob {
        return {
            type: ActionTypes.API_CONNECTOR_TRIGGER_JOB,
            params: params
        }
    }

    static CreateConnectorTriggerJobSuccess(params: ApiResponseModel): ConnectorTriggerJobSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_TRIGGER_JOB_SUCCESS,
            params: params
        }
    }

    static CreateClearInstances(): BaseReducerAction {
        return {
            type: ActionTypes.CONNECTOR_VIEW_CLEAR_INSTANCES
        }
    }

    static CreateConnectorLogsList(params: ConnectorLogRequest): ConnectorLogsList {
        return {
            type: ActionTypes.API_CONNECTOR_LOGS_LIST,
            params: params
        }
    }

    static CreateConnectorLogsListSuccess(params: ProcessListResultModel): ConnectorLogsListSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_LOGS_LIST_SUCCESS,
            params: params
        }
    }

    static CreateConnectorHistoryList(params: ConnectorHistoryListRequest): ConnectorHistoryList {
        return {
            type: ActionTypes.API_CONNECTOR_HISTORY_LIST,
            params: params
        }
    }

    static CreateConnectorHistoryListSuccess(params: HistoryResponseModel): ConnectorHistoryListSuccess {
        return {
            type: ActionTypes.API_CONNECTOR_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearConnectorList(force: boolean = false): ConnectorClearList {
        return {
            type: ActionTypes.API_CONNECTOR_LIST_CLEAR,
            params: {force: force}
        }   
    }
}