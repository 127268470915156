import React from "react";
import BaseFormModal, { ThisProps as FormProps } from "./base/BaseFormModal";

function SmallFormModal<T>(props: FormProps<T>): JSX.Element {

    return (
        <BaseFormModal
            {...props}
            maxWidth='sm'
        />
    )
}

export default SmallFormModal;