import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import useDialogStyles from 'styles/DialogStyles';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Instance from 'api/override/InstanceModel';
import { retrieveInstanceSchemaEdit } from 'schemas/Instance';
import FormErrorTextStyled from 'components/styled/FormErrorTextStyled';
import PackageSingleSelect from 'components/form_controls/PackageSingleSelect';
import PackageController from 'controllers/PackageController';
import StatusSingleSelect from 'components/form_controls/StatusSingleSelect';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface InstanceDetailsDialogProps extends BaseFormModalProps<Instance> {
    instance: Instance;
}

const InstanceDetailsDialog = (props: InstanceDetailsDialogProps) => {

    const { instance } = props;
    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const [packageController] = useState<PackageController>(new PackageController())

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', {model: t('instance.model')})}
            initialValues={new Instance({...instance, admin_password: ''})}
            action={ActionTypes.API_INSTANCE_UPDATE}
            onSubmit={props.onModalSubmit}
            validationSchema={retrieveInstanceSchemaEdit()}
            renderForm={(formik) => {
                return (
                    <div className={classes.fields_container}>
                        <TextFieldStyled
                            shouldShowNameHelperText
                            id="name" 
                            name="name"
                            label={t('instance.name')}
                            helperText={formik.touched.name ? formik.errors.name : ""}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />

                        <StatusSingleSelect formik={formik as any} />

                        <PackageSingleSelect
                            id='_package'
                            name='_package'
                            formik={formik}
                            controller={packageController}
                        />

                        <FormErrorTextStyled key={`credentials_warning`} isWarning message={t('instance.edit_warning')}/>

                        <TextFieldStyled  
                            id="admin_email" 
                            name="admin_email"
                            label={t('instance.admin_email')}
                            helperText={formik.touched.admin_email ? formik.errors.admin_email : ""}
                            error={formik.touched.admin_email && Boolean(formik.errors.admin_email)}
                            value={formik.values.admin_email}
                            onChange={formik.handleChange}
                        />

                        <TextFieldStyled  
                            id="admin_password" 
                            name="admin_password"
                            label={t('instance.admin_password')}
                            helperText={formik.touched.admin_password ? formik.errors.admin_password : ""}
                            error={formik.touched.admin_password && Boolean(formik.errors.admin_password)}
                            value={formik.values.admin_password}
                            onChange={formik.handleChange}
                        />

                        <TextFieldStyled  
                            id="api_key" 
                            name="api_key"
                            label={t('instance.api_key')}
                            helperText={formik.touched.api_key ? formik.errors.api_key : ""}
                            error={formik.touched.api_key && Boolean(formik.errors.api_key)}
                            value={formik.values.api_key}
                            onChange={formik.handleChange}
                        />

                        <TextFieldStyled  
                            id="system_key" 
                            name="system_key"
                            label={t('instance.system_key')}
                            helperText={formik.touched.system_key ? formik.errors.system_key : ""}
                            error={formik.touched.system_key && Boolean(formik.errors.system_key)}
                            value={formik.values.system_key}
                            onChange={formik.handleChange}
                        />

                        <BBCheckbox
                            checkboxProps={{
                                checked: formik.values.active,
                                onChange: formik.handleChange,
                                id: "active",
                                name: "active",
                            }}
                            label={t('instance.active')}
                        />
                    </div>
                )
            }
            }
        />
    );
};

export default InstanceDetailsDialog;