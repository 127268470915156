import { Theme } from '@mui/material/styles';

const RancherStyles = ((theme: Theme) => ({
    root: {
        width: "auto",
        height: 450,
        marginTop: 20
    },
    card_content: {
        display: "flex",
        flexDirection: "column",
        width: "auto",
        height: 390,
        justifyContent: "space-between"
    } ,
    chip: {
        width: "auto",
        alignSelf: "center",
        margin: "0 20px"
    },
    center: {
        alignSelf: "center"
    },
    bar_label: {
        alignSelf: "center",
        margin: 0,
        marginLeft: 3,
        fontSize: "larger"
    },
    label_container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: 2
    } ,
    bar_content: {
        display: "flex",
        flexDirection: "column"
    } ,
    chips_container: {
        display: "flex",
        flexDirection: "row",
        marginTop: 0,
        justifyContent: "center",
        padding: "0 50px"
    } ,
    in_bar_percentage: {
        zIndex: 2,
        position: "absolute",
        color: "white",
        margin: 0,
        fontSize: "medium",
        fontWeight: 502
    } ,
    bar_containerA: {
        display: "flex",
        justifyContent: "center"
    },
    center_text: {
        textAlign: "center"
    } ,
    comp_status_header: {
        marginTop: 40,
        textAlign: "center",
        alignSelf: "center",
        padding: 0,
        width: "250px",
        borderBottom: "green solid 2px"
    } ,
    card_container: {
        minWidth: 275,
        marginTop: '20px'
    },
    node_card: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 20
    } 
}))

export default RancherStyles