import React from 'react';
import { BaseFormModalProps } from 'components/base/BaseFormModal';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import SmallFormModal from 'components/SmallFormModal';
import ActionTypes from 'config/ActionTypes';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import { InputAdornment } from '@mui/material';
import ImageVersion from 'api/override/ImageVersionModel';
import Image from 'api/override/ImageModel';
import { retrieveImageVersionSchema } from 'schemas/ImageVersion';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface ImageVersionCreateDialogProps extends BaseFormModalProps<ImageVersion> {
    image: Image;
}

const ImageVersionCreateDialog = (props: ImageVersionCreateDialogProps) => {

    const { t } = useTranslation()
    const { classes } = useDialogStyles()

    return (
        <SmallFormModal {...props}  
            title={t('global.action.create_model', {model: 'Image Version'})}
            initialValues={new ImageVersion({
                image: props?.image,
                image_id: props?.image?.id,
                version_name: '',
                description: '',
                version_tag: '',
                latest: false
            })}
            action={ActionTypes.API_IMAGE_VERSION_CREATE}
            validationSchema={retrieveImageVersionSchema()}
            onSubmit={props.onModalSubmit}
            renderForm={(formik) => 
                <div className={classes.fields_container}>
                    <TextFieldStyled 
                        id="version_name" 
                        name="version_name"
                        label={t('image_versions.version_name')}
                        helperText={formik.touched.version_name ? formik.errors.version_name : ""}
                        error={formik.touched.version_name && Boolean(formik.errors.version_name)}
                        value={formik.values.version_name}
                        onChange={formik.handleChange}
                    />
                    <TextFieldStyled 
                        id="description" 
                        name="description"
                        label={t('image_versions.description')}
                        helperText={formik.touched.description ? formik.errors.description : ""}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />
                    <TextFieldStyled 
                        id="version_tag" 
                        name="version_tag"
                        label={t('image_versions.version_tag')}
                        helperText={formik.touched.version_tag ? formik.errors.version_tag : ""}
                        error={formik.touched.version_tag && Boolean(formik.errors.version_tag)}
                        value={formik.values.version_tag}
                        onChange={formik.handleChange}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>{props?.image?.docker_image}:</InputAdornment>
                        }}
                    />
                    <BBCheckbox
                        checkboxProps={{
                            checked: formik.values.latest,
                            onChange: formik.handleChange,
                            id: "latest",
                            name: "latest",
                        }}
                        label={t('image_versions.latest')}
                    />
                </div>
            }
        />
    )
}

export default ImageVersionCreateDialog