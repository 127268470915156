/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ApiSuccessResponseModel,
    ApiSuccessResponseModelFromJSON,
    ApiSuccessResponseModelFromJSONTyped,
    ApiSuccessResponseModelToJSON,
    NamespaceResponseModelAllOf,
    NamespaceResponseModelAllOfFromJSON,
    NamespaceResponseModelAllOfFromJSONTyped,
    NamespaceResponseModelAllOfToJSON,
} from './';


export interface NamespaceResponseModelInterface {
    message?: string;
    code?: number;
    data?: Array<string>;
    success?: boolean;
}

/**
 * 
 * @export
 * @interface NamespaceResponseModel
 */
export class NamespaceResponseModel extends BaseModel<NamespaceResponseModelInterface> {

    /**
     * Message of the Great Success
     * @type {string}
     * @memberof NamespaceResponseModel
     */
    message?: string;
    /**
     * HTTP response code
     * @type {number}
     * @memberof NamespaceResponseModel
     */
    code?: number;
    /**
     * Array of namespaces
     * @type {Array<string>}
     * @memberof NamespaceResponseModel
     */
    data?: Array<string>;
    /**
     * Succes of the call
     * @type {boolean}
     * @memberof NamespaceResponseModel
     */
    success?: boolean;

    constructor(data?: NamespaceResponseModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: NamespaceResponseModel, value: StringDateInterface<NamespaceResponseModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function NamespaceResponseModelFromJSON(json: any): NamespaceResponseModel {
    return NamespaceResponseModelFromJSONTyped(json, false);
}

export function NamespaceResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NamespaceResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = NamespaceResponseModel;
    try {
        let localClass = require.context('../override', true)('./NamespaceResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'code': !exists(json, 'code') ? undefined : Number(json['code']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'success': !exists(json, 'success') ? undefined : json['success'],
    });
}

export function NamespaceResponseModelToJSON(value?: NamespaceResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = NamespaceResponseModel;
    try {
        let localClass = require.context('../override', true)('./NamespaceResponseModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'message': value.message,
        'code': value.code,
        'data': value.data,
        'success': value.success,
    } as StringDateInterface<NamespaceResponseModel>;
    return createClass.toJson(value, model);
}


