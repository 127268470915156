import React  from 'react';
import { LoginLog } from 'interface/widgets/LoginLog';
import { useTranslation } from 'react-i18next';
import BaseWidget, { BaseWidgetProps } from "./BaseWidget";
import WidgetRowView from './WidgetRowView';
import { dateTimeFormatDistance } from 'config/DateTimeFormatter';

interface LoginHistoryWidgetProps extends BaseWidgetProps {
}

const LoginHistoryWidget = (props: LoginHistoryWidgetProps) => {
 
    const { t } = useTranslation();
    const { data } = props;
    const limitedDataset = Object.values(data).slice(0, 9)
    const getFormattedDistance = (timestamp: string): string => {
        return dateTimeFormatDistance(new Date(timestamp), Date.now(), localStorage.getItem('i18nextLng'), {addSuffix: true})
    }
    return (
        <BaseWidget {...props}>
            <WidgetRowView isHeader>
                <h4>{t('users.model')}</h4>
                <h4>{t('dashboard.widgets.last_login')}</h4>
            </WidgetRowView>
            {limitedDataset.map((loginLog: LoginLog) => {
                return (
                    <WidgetRowView key={`loginHistory_${loginLog.timestamp}`}>
                        <p>{loginLog.username}</p>
                        <p>{getFormattedDistance(loginLog.timestamp)}</p>
                    </WidgetRowView>
                )
            })}
        </BaseWidget>
    );
};

export default LoginHistoryWidget;