import { BaseAPI } from "api";
import BaseController from "./BaseController";

export default abstract class DispatchController<T> extends BaseController<T> {

    abstract modelName: string;
    abstract getApi(): BaseAPI; 

    unlockModel = async (uuid: string): Promise<boolean> => {
        const controller = this.getApi();
        return (await controller[this.modelName +'Unlock']({
            uuid: uuid
        })).code === 200
    }

}