enum Pages {
    instances = 'instances',
    settings = 'settings',
    users = 'users',
    connectors = 'connectors',
    services = 'services',
    images = 'images',
    history = 'history',
    taskMonitor = 'task-monitor',
    client = 'clients',
    property = 'properties',
    form = 'forms',
    mail = 'mails',
    mailLog = 'mail-logs',
    wizard = 'wizards',
    widget = 'widgets',
    packages = 'packages',
    centralInstances = 'central-instances',
}

export default Pages