/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ConditionModel,
    ConditionModelFromJSON,
    ConditionModelFromJSONTyped,
    ConditionModelToJSON,
} from './';


export interface BranchModelInterface {
    id?: number;
    value?: string;
    next_ids?: Array<number>;
    wizard_id?: number;
    condition_id?: number;
    condition?: ConditionModel;
    dummy_id?: number;
    first_stepview_id?: number;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface BranchModel
 */
export class BranchModel extends BaseModel<BranchModelInterface> {

    /**
     * id of the branch
     * @type {number}
     * @memberof BranchModel
     */
    id?: number;
    /**
     * If the branch comes from a condition, this value represents the value that needs to be met by the condition
     * @type {string}
     * @memberof BranchModel
     */
    value?: string;
    /**
     * Array of branch ids that appear after this branch
     * @type {Array<number>}
     * @memberof BranchModel
     */
    next_ids?: Array<number>;
    /**
     * The wizard id this branch is assigned to
     * @type {number}
     * @memberof BranchModel
     */
    wizard_id?: number;
    /**
     * The condition id this branch came from
     * @type {number}
     * @memberof BranchModel
     */
    condition_id?: number;
    /**
     * 
     * @type {ConditionModel}
     * @memberof BranchModel
     */
    condition?: ConditionModel;
    /**
     * The added dummy data for when someone is on this branch
     * @type {number}
     * @memberof BranchModel
     */
    dummy_id?: number;
    /**
     * The id of the first stepview in the branch
     * @type {number}
     * @memberof BranchModel
     */
    first_stepview_id?: number;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof BranchModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof BranchModel
     */
    updated_at?: Date;

    constructor(data?: BranchModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: BranchModel, value: StringDateInterface<BranchModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function BranchModelFromJSON(json: any): BranchModel {
    return BranchModelFromJSONTyped(json, false);
}

export function BranchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = BranchModel;
    try {
        let localClass = require.context('../override', true)('./BranchModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'next_ids': !exists(json, 'next_ids') ? undefined : json['next_ids'],
        'wizard_id': !exists(json, 'wizard_id') ? undefined : Number(json['wizard_id']),
        'condition_id': !exists(json, 'condition_id') ? undefined : Number(json['condition_id']),
        'condition': !exists(json, 'condition') ? undefined : ConditionModelFromJSON(json['condition']),
        'dummy_id': !exists(json, 'dummy_id') ? undefined : Number(json['dummy_id']),
        'first_stepview_id': !exists(json, 'first_stepview_id') ? undefined : Number(json['first_stepview_id']),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function BranchModelToJSON(value?: BranchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = BranchModel;
    try {
        let localClass = require.context('../override', true)('./BranchModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'value': value.value,
        'next_ids': value.next_ids,
        'wizard_id': value.wizard_id,
        'condition_id': value.condition_id,
        'condition': ConditionModelToJSON(value.condition),
        'dummy_id': value.dummy_id,
        'first_stepview_id': value.first_stepview_id,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<BranchModel>;
    return createClass.toJson(value, model);
}


