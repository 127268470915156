import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import Package from 'api/override/PackageModel';
import PackageForm from 'views/Packages/components/PackageForm';
import { retrievePackageSchema } from 'schemas/Package';
import BaseFormModal, { BaseFormModalProps } from 'components/base/BaseFormModal';
import ActionTypes from 'config/ActionTypes';

interface PackageDetailsDialogProps extends BaseFormModalProps<Package> {
    pk: Package
}

const PackageDetailsDialog = (props: PackageDetailsDialogProps) => {

    const {pk} = props
    const { t } = useTranslation();
    const { classes } = useDialogStyles();
    const formRef = useRef(null);

    return (
        <BaseFormModal {...props} title={t('global.title.details_model', { model: t('packages.model') })}
            initialValues={pk}
            validationSchema={retrievePackageSchema()}
            onSubmit={props.onModalSubmit}
            action={ActionTypes.API_PACKAGE_UPDATE}
            innerRef={formRef}
            renderForm={(formik) => (
                <div className={classes.fields_container}>
                    <PackageForm formik={formik} />
                </div>
            )}
        />
    );
}

export default PackageDetailsDialog;