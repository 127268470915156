/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface InstanceSetResourceLevelModelInterface {
    resource_level?: InstanceSetResourceLevelModelResourceLevelEnum;
}

/**
 * 
 * @export
 * @interface InstanceSetResourceLevelModel
 */
export class InstanceSetResourceLevelModel extends BaseModel<InstanceSetResourceLevelModelInterface> {

    /**
     * Resource Level of this Instance
     * @type {string}
     * @memberof InstanceSetResourceLevelModel
     */
    resource_level?: InstanceSetResourceLevelModelResourceLevelEnum;

    constructor(data?: InstanceSetResourceLevelModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: InstanceSetResourceLevelModel, value: StringDateInterface<InstanceSetResourceLevelModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function InstanceSetResourceLevelModelFromJSON(json: any): InstanceSetResourceLevelModel {
    return InstanceSetResourceLevelModelFromJSONTyped(json, false);
}

export function InstanceSetResourceLevelModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstanceSetResourceLevelModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = InstanceSetResourceLevelModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSetResourceLevelModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'resource_level': !exists(json, 'resource_level') ? undefined : json['resource_level'],
    });
}

export function InstanceSetResourceLevelModelToJSON(value?: InstanceSetResourceLevelModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = InstanceSetResourceLevelModel;
    try {
        let localClass = require.context('../override', true)('./InstanceSetResourceLevelModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'resource_level': value.resource_level,
    } as StringDateInterface<InstanceSetResourceLevelModel>;
    return createClass.toJson(value, model);
}

/**
* @export
* @enum {string}
*/
export enum InstanceSetResourceLevelModelResourceLevelEnum {
    Large = 'large',
    Medium = 'medium',
    Small = 'small'
}


