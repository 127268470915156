import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import useFileUploadStyles from 'styles/FileUploadStyles';
import { useTranslation } from 'react-i18next';
import ExtendedFile from 'interface/ExtendedFile';
import { Buffer } from 'buffer';

interface FileUploadProps {
    name: string;
    onFileUpload: (file?: ExtendedFile) => void
}

const FileUpload = (props: FileUploadProps) => {
    const { name, onFileUpload } = props;
    const { t } = useTranslation();
    const {classes} = useFileUploadStyles();
    const [selectedFile, setSelectedFile] = useState<ExtendedFile>(null)
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        toBase64(e.target.files[0]).then((res: ExtendedFile) => {
            setSelectedFile( res )
            onFileUpload(res)
        })
    }

    const toBase64 = (file: File) => new Promise((resolve: (file: ExtendedFile) => void, reject: (ev: ProgressEvent<FileReader>) => void) => {
        let copy = {};
        for (let key in file) {
            copy[key] = file[key];
        }
        const reader = new FileReader();
        reader.onload = () => {
            resolve({
                ...copy,
                base64: Buffer.from( reader.result.toString() ).toString('base64')
            } as ExtendedFile)
        };
        reader.onerror = error => reject(error);
        reader.readAsText(file, "UTF-8");
    });

    return (
        <label className={classes.container} htmlFor={ name }>
            <IconButton
                className={classes.button}
                component="label"
                htmlFor={ name }
                size="large">
                <PublishIcon/>
            </IconButton>
            <p className={classes.title}>{selectedFile?.name ?? `${t('general.browse')} ${name}`}</p>
            <input id={ name } type='file' onChange={onFileChange} hidden/>
        </label>
    );
}

export default FileUpload;