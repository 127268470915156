import {CentralInstanceModel} from "api";
import Historiable from "interface/Historiable";

export default class CentralInstance extends CentralInstanceModel implements Historiable {

    actionName: string = "central_instance"
    reducerName: string = "central_instances"

    getPrettyUrl(): string {
        return `${this.domain}.freightlive.eu`
    }

    getUrl(): string {
        return `https://${this.getPrettyUrl()}`
    }

    getCentralUrl(): string {
        return `https://central.freightlive.eu/remote-control/instance/detail?id=${this.id}`
    }

}