import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { UserModel } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticateActions from 'reducers/types/Authenticate';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import ActionTypes from 'config/ActionTypes';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';
import { ContentCopy, TicketAccount } from 'mdi-material-ui';
import ConfirmDialog from 'components/ConfirmDialog';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { RootState } from 'store/AppStore';
import TokenUtil from 'utils/TokenUtil';

interface UserDetailsProps {
    user: UserModel;
    onDelete?: () => void;
    onEdit?: () => void;
}

const UserDetails = (props: UserDetailsProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = props;
    const {classes} = useDetailsTabStyles();
    const dispatch = useDispatch();
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    const isFetchingToken = useSelector((state: RootState) => state.app.isFetchingData.get(ActionTypes.API_SYSTEM_TOKEN))
    const [token, setToken] = React.useState<string | undefined>();
    const userIsAbleToGenerateAPIToken: boolean = ['admin', 'developer_admin', 'developer', 'manager'].includes(TokenUtil.getTokenUtil().getRole())

    function onGenerateToken() {
        if(props.user.role_name.toString() === 'system' && props.user.uuid != null) {
            dispatch(AuthenticateActions.authenticateInvalidateToken(props.user.uuid));
        }
        toggleDialog();
    }
    function toggleDialog() {
        setConfirmDialogOpen(!confirmDialogOpen)
    }
    function handleCopyToken() {
        navigator.clipboard.writeText(token)
        enqueueSnackbar(t('global.action.token_added_clipboard'), {variant: 'info'})
    }

    function handleComponentDidMount() {
        const tokenInvalidated: NCListener = new NCListener(ActionTypes.API_INVALIDATE_TOKEN_SUCCESS, () => dispatch(AuthenticateActions.authenticateSystemToken(props.user.uuid)))
        NotificationCenter.default.addNCListener(tokenInvalidated)
        return () => NotificationCenter.default.removeListener(tokenInvalidated)
    }
    // eslint-disable-next-line
    React.useEffect(handleComponentDidMount, [])
    
    function handleButtonDidMount () {
        const tokenReceived = new NCListener(ActionTypes.API_SYSTEM_TOKEN_SUCCESS, (action) => setToken(action['response'].token))
        NotificationCenter.default.addNCListener(tokenReceived)
        return () => NotificationCenter.default.removeListener(tokenReceived)
    }

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('users.model')}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onMount={handleButtonDidMount}
                detailsActionButton={[userIsAbleToGenerateAPIToken && props.user.role_name.toString() === 'system' ? {
                    title: t('global.button.generate_token'),
                    onClickCallback: toggleDialog,
                    icon: TicketAccount
                } : null].filter(Boolean)}
            >
                {isFetchingToken 
                    ? <CircularProgress size={20} thickness={4} variant='indeterminate' style={{marginTop: 12}} />
                    : <div>
                        {token != null && 
                            <>
                                <i className={classes.token_heading}>{t('users.api_token')}</i>
                                <div className={classes.token_panel}>
                                    <Tooltip title={token}>
                                        <p>{token}</p>
                                    </Tooltip>
                                    <Tooltip title={t('general.copy_token')}>
                                        <IconButton
                                            onClick={handleCopyToken}
                                            className={`${classes.action_button} copy`}
                                            size="large">
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </> 
                        }
                        <ConfirmDialog
                            open={confirmDialogOpen}
                            onConfirm={onGenerateToken}
                            onModalClosePress={toggleDialog}
                            title={''} 
                        />       
                    </div>
                }
            </BaseDetailsViewActions>
            <DetailsField propKey={t('users.email')} propVal={user?.email} />
            <DetailsField propKey={t('users.full_name')} propVal={user?.full_name} />
            <DetailsField propKey={t('users.role_name')} propVal={user?.role_name.toString()} />
        </div>
    );
};

export default UserDetails;