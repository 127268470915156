import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import BasePageComponent, { ViewProps } from 'components/base/BasePageComponent';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import AppState from 'reducers/interface/AppState';
import { MailCreateSuccessModel } from 'api';
import NotificationCenter from 'services/NotificationCenter';
import MailState from 'reducers/interface/MailState';
import MailActions from 'reducers/types/Mail';
import TabManager, { TabView } from 'components/tabs/TabManager';
import ReloadDataButton from 'components/ReloadDataButton';
import { BaseAction } from 'reducers/interface/ReducerAction';
import HistoryTab from 'components/tabs/HistoryTab';
import Mail from 'api/override/MailModel';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import DetailsViewHeader from 'components/DetailsViewHeader';
import MailDetails from './components/MailDetails';
import MailDetailsDialog from './components/MailDetailsDialog';
import MailCreateForm from './components/MailCreateForm';
import MailTemplateDefaultContent from 'config/MailTemplateDefaultContent.json'
import HideOnProd from 'components/HideOnProd';
import HideOnStage from 'components/HideOnStage';

interface StateProps {
    appReducer: AppState;
    mailReducer: MailState;
}

interface DispatchProps {
    retrieveMail: (params: string) => void;
    deleteMail: (params: number) => void;
    createMail: (params: Mail) => void;
    updateMail: (params: Mail) => void;
    clearMails: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    mail?: Mail;
    isDeleteModalOpen: boolean;
    isEditModalOpen: boolean;
}

class MailView extends BasePageComponent<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            mail: null,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.setFailuresToListenTo([
            ActionTypes.API_MAIL_UPDATE_FAILURE, 
            ActionTypes.API_MAIL_DELETE_FAILURE, 
            ActionTypes.API_MAIL_CREATE_FAILURE,
            ActionTypes.API_MAIL_RETRIEVE_FAILURE
        ])
        this.loadData();
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, 
            ActionTypes.API_MAIL_RETRIEVE_SUCCESS, 
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_MAIL_UPDATE_SUCCESS, 
            this.onUpdateSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_MAIL_DELETE_SUCCESS, 
            this.onDeleteSuccess  
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_MAIL_CREATE_SUCCESS, 
            this.onCreateSuccess
        );
    }

    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({mail: action.params}, () => {
            this.updateTabTitle(this.state.mail.name)
        })
    }

    onCreateSuccess = (action: BaseAction) => {
        const mailSuccess: MailCreateSuccessModel = action.params
        this.setState({mail: new Mail(mailSuccess.model)})
        this.props.navigate(`/mails/${mailSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('mails.model')}), {variant: 'success'})
        this.props.clearMails(true);
    }

    onUpdateSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('mails.model')}), {variant: 'success'})
        this.props.clearMails(true);
        this.reloadData();
    }

    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('mails.model')}), {variant: 'success'})
        this.props.clearMails(true)
        this.props.navigate('/mails')
    }

    clearData = () => {}

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveMail(this.props.params.slug);
        }
    }

    reloadData = () => {
        if (this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_MAIL_HISTORY_UPDATE}, null);
        }
        this.loadData();
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.mail != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    onDelete = () => {
        this.setState({
            isDeleteModalOpen: true,
        });
    };

    onEdit = () => {
        this.setState({
            isEditModalOpen: true
        })
    }

    dialogClosed = () => {
        this.setState({
            isDeleteModalOpen: false,
            isEditModalOpen: false,
        })
    }

    renderView() {
        const { classes, mailReducer, appReducer } = this.props;
        return (
            <HideOnProd>
                <HideOnStage>
                    <React.Fragment>
                        {this.shouldRenderDetails() && <ReloadDataButton loadData={this.reloadData} />}
                        <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_MAIL_RETRIEVE) || appReducer.isFetchingData.get(ActionTypes.API_ROLE_LIST)}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Container maxWidth={"lg"}>
                            <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                                <Grid item xs={12} md={12} lg={9}>
                                    <Card className={classes.root}>
                                        {this.shouldRenderDetails() &&
                                            <div>
                                            <DetailsViewHeader
                                                model={i18next.t('mails.model')}
                                                title={this.state.mail?.name}
                                            />
                                            <TabManager
                                                items={[
                                                    {
                                                        title: i18next.t('general.detail'),
                                                        link: `/mails/${this.state.mail?.id}`,
                                                        view: (
                                                            <MailDetails 
                                                                mail={this.state.mail} 
                                                                onDelete={this.onDelete}
                                                                onEdit={this.onEdit}
                                                            />
                                                        )
                                                    },{
                                                        title:  i18next.t('mail.editor_tab_title'),
                                                        link: `/mails/${this.state.mail?.id}/editor`,
                                                        initialTab: this.isPathIncluded('editor'),
                                                        view: (
                                                            <div>
                                                                {/* UNLAYER GOES HERE */}
                                                            </div>
                                                        )
                                                    },{
                                                        title:  i18next.t('history.model'),
                                                        link: `/mails/${this.state.mail?.id}/history`,
                                                        initialTab: this.isPathIncluded('history'),
                                                        view: (
                                                            <HistoryTab 
                                                                model={this.state.mail}
                                                            />
                                                        )
                                                    }
                                                ]}
                                                onTabSwitch={(newTab: TabView) => {
                                                    if (newTab.link.includes('history')){
                                                        if (mailReducer.history.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_MAIL_HISTORY_LIST)) {
                                                            this.sendNotification({type: ActionTypes.API_MAIL_HISTORY_UPDATE}, null);
                                                        }
                                                    }
                                                }}
                                            />
                                            </div>
                                        }
                                        {this.shouldRenderCreateForm() &&
                                            <CardContent>
                                                <MailCreateForm
                                                    onFormSubmit={(mail: Mail) => {
                                                        const mailWithDefaultContent: Mail = new Mail({...mail, content: JSON.stringify(MailTemplateDefaultContent)})
                                                        this.props.createMail(mailWithDefaultContent);
                                                    }}
                                                />        
                                            </CardContent>
                                        }
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>

                        <MailDetailsDialog
                            mail={this.state.mail}
                            open={this.state.isEditModalOpen}
                            onModalClosePress={this.dialogClosed}
                            onModalSubmit={(mail: Mail) => {
                                this.props.updateMail(mail);
                            }}
                        />                

                        <ConfirmDeleteDialog
                            open={this.state.isDeleteModalOpen}
                            onModalClosePress={this.dialogClosed}
                            onConfirm={() => {
                                this.props.deleteMail(this.state.mail.id)
                            }}
                            title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('mails.model')).toLowerCase()})}
                        />
                            
                    </React.Fragment>
                </HideOnStage>
            </HideOnProd>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    mailReducer: state.mails,
});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveMail: (params: number) => dispatch(MailActions.CreateMailRetrieve(params)),
        deleteMail: (params: number) => dispatch(MailActions.CreateMailDelete(params)),
        createMail: (params: Mail) => dispatch(MailActions.CreateMailCreate(params)),
        updateMail: (params: Mail) => dispatch(MailActions.CreateMailUpdate(params)),
        clearMails: (params: boolean) => dispatch(MailActions.CreateClearMailList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(MailView, InstanceViewStyles)
            )
        )
    )
);