import React from 'react';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { MailModel } from 'api';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface MailDetailsProps {
    mail: MailModel;
    onDelete?: () => void;
    onEdit?: () => void;
}

const MailDetails = (props: MailDetailsProps) => {
    const { t } = useTranslation();
    const { mail, onEdit, onDelete } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('mails.model')}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            <DetailsField propKey={t('general.name')} propVal={mail?.name} />
            <DetailsField propKey={t('mails.subject')} propVal={mail?.subject} />
            <DetailsField propKey={t('mails.event')} propVal={mail?.event} />
            <DetailsField propKey={t('general.description')} propVal={mail?.description} />
        </div>
    );
};

export default MailDetails;