import { HistoryResponseModel, ImageCreateSuccessModel, ImageHistoryListRequest, ImageListResultModel, ImageRemoveSuccessModel, ImageRetrieveListModel } from "api";
import ActionTypes from "config/ActionTypes";
import Image from "api/override/ImageModel";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface ImageReducerAction extends BaseReducerAction {
    errorObj?: any;
}
export interface ImageList extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_LIST;
    params: ImageRetrieveListModel;
}

export interface ImageListSuccess extends BaseSagaListResponse<ImageListResultModel> {
    type: ActionTypes.API_IMAGE_LIST_SUCCESS;
}

export interface ImageCreate extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_CREATE;
    params: Image;
}

export interface ImageCreateSuccess extends BaseSagaListResponse<ImageCreateSuccessModel> {
    type: ActionTypes.API_IMAGE_CREATE_SUCCESS;
}

export interface ImageUpdateParams {
    id: number;
    imageModel: Image;
}

export interface ImageUpdate extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_UPDATE;
    params: ImageUpdateParams;
}

export interface ImageUpdateSuccess extends BaseSagaListResponse<ImageCreateSuccessModel> {
    type: ActionTypes.API_IMAGE_UPDATE_SUCCESS;
}

export interface ImageRetrieve extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_RETRIEVE;
    params: number;
}

export interface ImageRetrieveSuccess extends BaseSagaListResponse<Image> {
    type: ActionTypes.API_IMAGE_RETRIEVE_SUCCESS
}

export interface ImageDelete extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_DELETE;
    params: number;
}

export interface ImageDeleteSuccess extends BaseSagaListResponse<ImageRemoveSuccessModel> {
    type: ActionTypes.API_IMAGE_DELETE_SUCCESS;
}

export interface ImageHistoryList extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_HISTORY_LIST;
    params: ImageHistoryListRequest;
}

export interface ImageHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_IMAGE_HISTORY_LIST_SUCCESS;
}

export interface ImageClearListParams {
    force?: boolean;
}
export interface ImageClearList extends BaseReducerAction {
    type: ActionTypes.API_IMAGE_LIST_CLEAR;
    params?: ImageClearListParams;
}

export default class ImageActions {
    
    static CreateImageList(params: ImageRetrieveListModel): ImageList {
        return {
            type: ActionTypes.API_IMAGE_LIST,
            params: params
        }
    }

    static CreateImageListSuccess(params: ImageListResultModel): ImageListSuccess {
        return {
            type: ActionTypes.API_IMAGE_LIST_SUCCESS,
            params: params
        }
    }

    static CreateImageCreate(params: Image): ImageCreate {
        return {
            type: ActionTypes.API_IMAGE_CREATE,
            params: params
        }
    }

    static CreateImageCreateSuccess(params: ImageCreateSuccessModel): ImageCreateSuccess {
        return {
            type: ActionTypes.API_IMAGE_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateImageUpdate(params: Image): ImageUpdate {
        return {
            type: ActionTypes.API_IMAGE_UPDATE,
            params: {
                id: params.id,
                imageModel: params
            }
        }
    }

    static CreateImageUpdateSuccess(params: ImageCreateSuccessModel): ImageUpdateSuccess {
        return {
            type: ActionTypes.API_IMAGE_UPDATE_SUCCESS,
            params: params
        }
    }

    static CreateImageRetrieve(params: number): ImageRetrieve {
        return {
            type: ActionTypes.API_IMAGE_RETRIEVE,
            params: params
        }
    }

    static CreateImageRetrieveSuccess(params: Image): ImageRetrieveSuccess {
        return {
            type: ActionTypes.API_IMAGE_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateImageDelete(params: number): ImageDelete {
        return {
            type: ActionTypes.API_IMAGE_DELETE,
            params: params
        }
    }

    static CreateImageDeleteSuccess(params: ImageRemoveSuccessModel): ImageDeleteSuccess {
        return {
            type: ActionTypes.API_IMAGE_DELETE_SUCCESS,
            params: params
        }
    }

    static CreateImageHistoryList(params: ImageHistoryListRequest): ImageHistoryList {
        return {
            type: ActionTypes.API_IMAGE_HISTORY_LIST,
            params: params
        }
    }

    static CreateImageHistoryListSuccess(params: HistoryResponseModel): ImageHistoryListSuccess {
        return {
            type: ActionTypes.API_IMAGE_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearImageList(force: boolean = false): ImageClearList {
        return {
            type: ActionTypes.API_IMAGE_LIST_CLEAR,
            params: {force: force}
        }
    }
}