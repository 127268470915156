
import { TrialModelStatusEnum } from "api/models";
import { ClientModel, ClientModelInterface } from "api/models/ClientModel";
import Historiable from "interface/Historiable";
import Property from "./PropertyModel";
import Trial from "./TrialModel";

export default class Client extends ClientModel implements Historiable {

    actionName: string = "client"
    reducerName: string = "clients"

    constructor(data?: ClientModelInterface) {
        super(data);
        this.properties = data?.properties ?? {}
    }

    getWebsiteUrl(): string {
        if(this.website == null) {
            return ''
        }
        if (this.website?.includes('https://') || this.website?.includes('http://')) {
            return this.website
        } else {
            return `https://${this.website}`;
        }
    }

    prepareProperties = (properties: Property[]) => {
        let finalProps = {}
        properties.filter(prop => prop.for_model === 'Client').forEach(prop => {
            finalProps[prop.name] = prop.getDefaultValue();
        })
        this.properties = finalProps;
        return this;
    }

    getClientTrials = (status?: TrialModelStatusEnum): Array<Trial> => {
        let result = this.trials as Trial[] ?? [];
        switch (status) {
            case TrialModelStatusEnum.Active:
                result = result.filter((trial: Trial) => trial.status === TrialModelStatusEnum.Active)
                break;
            case TrialModelStatusEnum.Cancelled:
                result = result.filter((trial: Trial) => trial.status === TrialModelStatusEnum.Cancelled)
                break;
            case TrialModelStatusEnum.Extended:
                result = result.filter((trial: Trial) => trial.status === TrialModelStatusEnum.Extended)
                break;
            case TrialModelStatusEnum.Finished:
                result = result.filter((trial: Trial) => trial.status === TrialModelStatusEnum.Finished)
                break;
            default:
                break;
        }
        return result
    }

    getActiveTrial = (): Trial => {
        return new Trial((this.trials as Trial[]).find((trial: Trial) => trial.status === TrialModelStatusEnum.Active))
    }

}