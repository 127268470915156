import React from 'react';
import { useTranslation } from "react-i18next";
import useDialogStyles from 'styles/DialogStyles';
import { FormikProps, FieldArray } from 'formik';
import TextFieldStyled from 'components/styled/TextFieldStyled';
import Widget from 'api/override/WidgetModel';
import WidgetField from 'api/override/WidgetFieldModel';
import Constants from 'config/Constants';
import SingleSelect from 'components/form_controls/SingleSelect';
import IconButtonStyled from 'components/styled/IconButtonStyled';
import { Minus, Plus } from 'mdi-material-ui';
import BBCheckbox from 'components/form_controls/BBCheckbox';

interface WidgetFormProps {
    formik: FormikProps<Widget>
}

const WidgetForm = (props: WidgetFormProps) => {

    const { t } = useTranslation();
    const {classes} = useDialogStyles();
    const {formik} = props

    return (
        <>
            <TextFieldStyled 
                id="name" 
                name="name"
                label={t('general.name')}
                helperText={formik.touched.name ? formik.errors.name : ""}
                error={formik.touched.name && Boolean(formik.errors.name)}
                value={formik.values.name}
                onChange={formik.handleChange}
            />

            <TextFieldStyled 
                id="icon" 
                name="icon"
                label={t('widgets.icon')}
                helperText={formik.touched.icon ? formik.errors.icon : t('widgets.icon_helper_text')}
                error={formik.touched.icon && Boolean(formik.errors.icon)}
                multiline
                value={formik.values.icon}
                onChange={formik.handleChange}
            />

            {/* Widget Fields */}
            <h3 className={classes.widget_fields_section}> {t('widgets.widget_field_plural')} </h3> 

            <FieldArray name='fields'>
                {({insert, remove, push}) => (
                    <>
                        {formik.values.fields.map((field: WidgetField, index: number) => {
                            const {touched, errors} = formik
                            const {fields} = formik.values
                            return (
                                <div key={`widget_field_${index}`} className={classes.add_objects_container}>

                                    <div className={classes.widget_field_container_header}>
                                        <h4>{t('widgets.widget_field')} {index + 1}</h4>
                                        <IconButtonStyled onClick={() => remove(index)} disabled={fields.length < 2}>
                                            <Minus/>
                                        </IconButtonStyled>
                                    </div>

                                    <TextFieldStyled 
                                        id="field" 
                                        name={`fields[${index}].field`}
                                        label={t('general.field')}
                                        helperText={touched.fields?.[index]?.['field'] ? errors.fields?.[index]?.['field'] : ""}
                                        error={touched.fields?.[index]?.['field'] && Boolean(errors.fields?.[index]?.['field'])}
                                        value={fields[index]['field']}
                                        onChange={formik.handleChange}
                                    />

                                    <TextFieldStyled 
                                        id="title"
                                        name={`fields[${index}].title`}
                                        label={t('general.title')}
                                        helperText={touched.fields?.[index]?.['title'] ? errors.fields?.[index]?.['title'] : ""}
                                        error={touched.fields?.[index]?.['title'] && Boolean(errors.fields?.[index]?.['title'])}
                                        value={fields[index]['title']}
                                        onChange={formik.handleChange}
                                    />

                                    <SingleSelect
                                        id="type"
                                        name={`fields[${index}].type`}
                                        label={t('general.type')}
                                        options={Constants.WIDGET_FIELD_TYPE()}
                                        value={Constants.WIDGET_FIELD_TYPE().find(type => type.value === fields[index]['type'])}
                                        onChange={formik.handleChange}
                                        formikError={touched.fields?.[index]?.['type'] && Boolean(errors.fields?.[index]?.['type'])}
                                        formikHelperText={touched.fields?.[index]?.['type'] ? errors.fields?.[index]?.['type'] : ""}
                                    />

                                    <BBCheckbox
                                        checkboxProps={{
                                            disabled: fields[index].type === 'bool',
                                            checked: fields[index]['optional'],
                                            onChange: formik.handleChange,
                                            id: `fields[${index}].optional`,
                                            name: `fields[${index}].optional`,
                                        }}
                                        label={t('global.form.optional')}
                                    />

                                </div>
                            )
                        })}
                        <IconButtonStyled 
                            className={classes.add_new_field_btn} 
                            onClick={() => 
                                insert(formik.values.fields.length + 1, new WidgetField())
                            }>
                            <Plus/>
                        </IconButtonStyled>
                    </>
                )}
            </FieldArray>
        </>
    );
};

export default WidgetForm;