import React from 'react';
import { withRouter } from 'utils/withRouter';
import {withTranslation} from "react-i18next";
import { Container, Card, CardContent, Grid } from "@mui/material";
import { withStyles } from 'tss-react/mui';
import ActionTypes from "config/ActionTypes";
import {withSnackbar} from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { ViewProps } from 'components/base/BasePageComponent';
import { BaseAction } from 'reducers/interface/ReducerAction';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import AppState from 'reducers/interface/AppState';
import { ImageCreateSuccessModel, ImageVersionFilterModel, ImageVersionRetrieveListModel } from 'api';
import ImageActions from 'reducers/types/Image';
import NotificationCenter from 'services/NotificationCenter';
import ImageDetails from './components/ImageDetails';
import BaseTableViewComponent, { BaseTableViewState, tableConnect } from '../../components/base/BaseTableViewComponent';
import TableViewDelegate from 'interface/TableViewDelegate';
import TabManager, { TabView } from 'components/tabs/TabManager';
import TableList from 'components/TableList';
import ImageVersionActions from 'reducers/types/ImageVersion';
import TableColumn from 'models/table/TableColumn';
import CustomActionButton from 'models/table/CustomActionButton';
import ImageVersionState from 'reducers/interface/ImageVersionState';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImageVersionDetailsDialog from 'views/Dialog/ImageVersionDetailsDialog';
import ImageVersionCreateDialog from 'views/Dialog/ImageVersionCreateDialog';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import ImageDetailsDialog from 'views/Dialog/ImageDetailsDialog';
import ImageCreateForm from 'views/CreationForms/ImageCreateForm';
import ReloadDataButton from 'components/ReloadDataButton';
import TokenUtil from 'utils/TokenUtil';
import Image from 'api/override/ImageModel';
import HistoryTab from 'components/tabs/HistoryTab';
import ImageVersion from 'api/override/ImageVersionModel';
import ImageVersionHistorySelect from './components/ImageVersionHistorySelect';
import DetailsViewHeader from 'components/DetailsViewHeader';
import ImageState from 'reducers/interface/ImageState';
import ButtonStyled from 'components/styled/ButtonStyled';


interface StateProps {
    appReducer: AppState;
    imageVersionReducer: ImageVersionState;
    imageReducer: ImageState;
}

interface DispatchProps {
    retrieveImage: (params: number) => void;
    createImage: (params: Image) => void;
    updateImage: (params: Image) => void;
    deleteImage: (params: number) => void;
    listImageVersions: (params: ImageVersionRetrieveListModel) => void;
    createImageVersion: (params: ImageVersion) => void;
    updateImageVersion: (params: ImageVersion) => void;
    deleteImageVersion: (params: number) => void;
    retrieveImageVersion: (params: number) => void;
    clearImages: (params: boolean) => void;
}

type Props = StateProps & DispatchProps & ViewProps;

interface State extends BaseTableViewState {
    image: Image;
    imageVersion: ImageVersion;
    historyImageVersion: ImageVersion;
    isImageVersionDetailsModalOpen: boolean;
    isImageVersionCreateModalOpen: boolean;
    isImageEditModalOpen: boolean;
    isImageDeleteModalOpen: boolean;
    isImageVersionDeleteModalOpen: boolean;
}

class ImageView extends BaseTableViewComponent<Props, State> implements TableViewDelegate {

    columns: TableColumn[];
    customActionButtons: CustomActionButton[];
    
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            imageVersion: null,
            historyImageVersion: null,
            isImageVersionDetailsModalOpen: false,
            isImageVersionCreateModalOpen: false,
            isImageEditModalOpen: false,
            isImageDeleteModalOpen: false,
            isImageVersionDeleteModalOpen: false,
        };

        this.setFailuresToListenTo([
            ActionTypes.API_IMAGE_CREATE_FAILURE,
            ActionTypes.API_IMAGE_UPDATE_FAILURE,
            ActionTypes.API_IMAGE_DELETE_FAILURE,
            ActionTypes.API_IMAGE_VERSION_CREATE_FAILURE,
            ActionTypes.API_IMAGE_VERSION_UPDATE_FAILURE,
            ActionTypes.API_IMAGE_VERSION_DELETE_FAILURE,
        ])
    }

    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'version_name', isSortable: true, label: i18next.t('image_versions.version_name')}),
            new TableColumn({ key: 'description', isSortable: true, label: i18next.t('image_versions.description')}),
            new TableColumn({ key: 'version_tag', isSortable: false, label: i18next.t('image_versions.version_tag')}),
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadData()
    };

    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_RETRIEVE_SUCCESS, 
            (action) => this.onRetrieveSuccess(action.params)
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_VERSION_CREATE_SUCCESS, 
            this.onVersionCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_VERSION_UPDATE_SUCCESS, 
            this.onVersionUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_CREATE_SUCCESS, 
            (action) => this.onImageCreateSuccess(action)
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_UPDATE_SUCCESS, 
            (action) => this.onImageUpdateSuccess(action)
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_DELETE_SUCCESS, 
            this.onImageDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_VERSION_DELETE_SUCCESS, 
            this.onImageVersionDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_IMAGE_VERSION_RETRIEVE_SUCCESS, 
            (action) => this.onImageVersionRetrieveSuccess(action)
        );
    }

    loadData = () => {
        if(this.isDetailsView(this.props)){
            this.props.retrieveImage(this.props.params.slug);
            if(this.isPathIncluded('versions')){
                this.getData()
            }
        }
    }

    reloadData = () => {
        if(this.isPathIncluded('img-version-history')) {
            this.sendNotification({type: ActionTypes.API_IMAGE_VERSION_HISTORY_UPDATE}, null)
        } 
        else if(this.isPathIncluded('history')) {
            this.sendNotification({type: ActionTypes.API_IMAGE_HISTORY_UPDATE}, null)
        }
        this.loadData();
    }

    getData = () => {
        if(this.isDetailsView(this.props)){
            const filters = new ImageVersionFilterModel({image_id: this.props.params.slug})
            this.props.listImageVersions( {...this.getParams()?.getApiFilter(), filters: filters });
        }
    }

    getItemCount = (): number => {
        return this.props.imageVersionReducer.collection.items.length
    }

    onImageDeleteSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('image.model')}), {variant: 'success'})
        this.dialogClosed()
        this.props.navigate('/images')
        this.props.clearImages(true)
    }

    onImageCreateSuccess = (action: BaseAction) => {
        const imageSuccess: ImageCreateSuccessModel = action.params
        this.props.navigate(`/images/${imageSuccess?.model?.id}`)
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('image.model')}), {variant: 'success'})
        this.setState({
            image: new Image(imageSuccess.model)
        })
        this.props.clearImages(true)
    }

    onRetrieveSuccess = (model: Image) => {
        this.setState({image: model}, () => {
            this.updateTabTitle(this.state.image.name)
        })
    }

    onImageUpdateSuccess = (action: BaseAction) => {
        const imageSuccess: ImageCreateSuccessModel = action.params
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('image.model')}), {variant: 'success'})
        this.setState({
            image: new Image(imageSuccess.model)
        })
        this.dialogClosed();
        this.props.clearImages(true)
    }

    dialogClosed = () => {
        this.setState({
            isImageVersionDetailsModalOpen: false,
            isImageVersionCreateModalOpen: false,
            isImageDeleteModalOpen: false,
            isImageEditModalOpen: false,
            isImageVersionDeleteModalOpen: false
        })
    }

    onDetails = (row: ImageVersion) => {
        this.setState({imageVersion: row, isImageVersionDetailsModalOpen: true})
    }

    onDelete = (row: ImageVersion) => {
        this.setState({imageVersion: row, isImageVersionDeleteModalOpen: true})
    }

    deleteImageVersion = () => {
        this.props.deleteImageVersion(this.state.imageVersion?.id)
    }

    onImageVersionDeleteSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_success_model', {model: i18next.t('image_versions.model')}), {variant: 'success'})
        this.dialogClosed()
        this.getData()
    }

    onImageVersionRetrieveSuccess = (action: BaseAction) => {
        this.setState({historyImageVersion: new ImageVersion(action?.params)})
    }

    onImageEdit = () => {
        this.setState({isImageEditModalOpen: true})
    }

    onImageDelete = () => {
        this.setState({isImageDeleteModalOpen: true})
    }

    deleteImage = () => {
        this.props.deleteImage(this.state.image?.id);
        this.dialogClosed()
    };

    onVersionUpdateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('image_versions.model')}), {variant: 'success'})
        this.getData()
        this.dialogClosed()
    }
    
    onImageVersionCreate = () => { this.setState({isImageVersionCreateModalOpen: true}) }

    onVersionCreateSuccess = () => {  
        this.props.enqueueSnackbar(i18next.t('global.notification.create_success_model', {model: i18next.t('image_versions.model')}), {variant: 'success'})
        this.getData()
        this.dialogClosed()
    }

    shouldRenderDetails = () => {
        return this.isDetailsView(this.props) && this.state.image != null
    }

    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }

    renderView() {
        const { classes, appReducer, imageVersionReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_IMAGE_RETRIEVE)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderDetails() &&
                                    <div>
                                        <DetailsViewHeader
                                            model={i18next.t('image.model')}
                                            title={this.state.image?.name}
                                        />
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/images/${this.state.image?.id}`,
                                                    view: (
                                                        <ImageDetails 
                                                            image={this.state.image}
                                                            onImageEdit={this.onImageEdit}
                                                            onImageDelete={this.onImageDelete}
                                                        />
                                                    )
                                                },{
                                                    title: i18next.t('history.model'),
                                                    link: `/images/${this.state.image?.id}/history`,
                                                    initialTab: this.isPathIncluded(`/images/${this.state.image?.id}/history`),
                                                    view: (
                                                        <HistoryTab model={this.state.image} />
                                                    )
                                                },{
                                                    title: i18next.t('mainnav.image_versions'),
                                                    link: `/images/${this.state.image?.id}/versions`,
                                                    initialTab: this.isPathIncluded('versions'),
                                                    view: (
                                                        <Grid item xs={12} >
                                                            <ButtonStyled 
                                                                className={classes.button_add_container}
                                                                aria-label="instance" 
                                                                startIcon={<AddCircleOutlineIcon />}
                                                                onClick={this.onImageVersionCreate}
                                                            >
                                                                { i18next.t('global.action.create_model', {model: i18next.t('image_versions.model')}) }
                                                            </ButtonStyled>
                                                            <TableList
                                                                hasDetailsAction
                                                                hasDeleteAction={TokenUtil.getTokenUtil().isAccountManager()}
                                                                columns={this.columns}
                                                                count={imageVersionReducer.collection.count_filtered}
                                                                listItems={imageVersionReducer.collection.items}
                                                                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_IMAGE_VERSION_LIST)}
                                                                delegate={ this }
                                                                tableParams={this.getParams()}
                                                                customActions={this.customActionButtons}
                                                            /> 
                                                        </Grid>
                                                    )
                                                },{
                                                    title: i18next.t('history.history_model', {model: i18next.t('image_versions.model')}),
                                                    link: `/images/${this.state.image?.id}/img-version-history`,
                                                    initialTab: this.isPathIncluded('img-version-history'),
                                                    view: (
                                                        <>
                                                            <ImageVersionHistorySelect 
                                                                imageId={this.state.image?.id}
                                                                onVersionSelect={(id: number) => this.setState({
                                                                    historyImageVersion: null
                                                                }, () => this.props.retrieveImageVersion(id))}
                                                            />
                                                            {this.state.historyImageVersion != null &&
                                                                <HistoryTab model={this.state.historyImageVersion} />
                                                            }
                                                        </>
                                                        
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if(newTab.link.includes('version')){
                                                    this.getData()
                                                }
                                            }}
                                        />
                                        
                                    </div>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <ImageCreateForm 
                                            onFormSubmit={(image: Image) => {
                                                this.props.createImage(image);
                                            }}
                                        />
                                    </CardContent>
                                }
                                
                            </Card>

                            <ImageVersionDetailsDialog
                                open={this.state.isImageVersionDetailsModalOpen}
                                onModalClosePress={this.dialogClosed}
                                imageVersion={this.state.imageVersion}
                                onModalSubmit={(imageVersion: ImageVersion) => {
                                    this.props.updateImageVersion(imageVersion)
                                }}
                            />

                            <ImageVersionCreateDialog
                                open={this.state.isImageVersionCreateModalOpen}
                                image={this.state.image}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(imageVersion: ImageVersion) => {
                                    this.props.createImageVersion(imageVersion)
                                }}
                            />

                            <ImageDetailsDialog
                                open={this.state.isImageEditModalOpen}
                                onModalClosePress={this.dialogClosed}
                                image={this.state.image}
                                onModalSubmit={(image: Image) => {
                                    this.props.updateImage(image)
                                }}
                            />

                            <ConfirmDeleteDialog
                                open={this.state.isImageDeleteModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteImage}
                                title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('image.model')).toLowerCase()})}
                            />

                            <ConfirmDeleteDialog
                                open={this.state.isImageVersionDeleteModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteImageVersion}
                                title={i18next.t('global.title.delete_confirm_model', {model: (i18next.t('image_versions.model')).toLowerCase()})}
                            />

                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

const  mapStateToProps = (state: any) => ({
    appReducer: state.app,
    imageVersionReducer: state.image_versions,
    imageReducer: state.images

});

const mapDispatchToProps = (dispatch) => {
    return {
        retrieveImage: (params: number) => dispatch(ImageActions.CreateImageRetrieve(params)),
        createImage: (params: Image) => dispatch(ImageActions.CreateImageCreate(params)),
        updateImage: (params: Image) => dispatch(ImageActions.CreateImageUpdate(params)),
        deleteImage: (params: number) => dispatch(ImageActions.CreateImageDelete(params)),
        listImageVersions: (params: ImageVersionRetrieveListModel) => dispatch(ImageVersionActions.CreateImageVersionList(params)),
        createImageVersion: (params: ImageVersion) => dispatch(ImageVersionActions.CreateImageVersionCreate(params)),
        updateImageVersion: (params: ImageVersion) => dispatch(ImageVersionActions.CreateImageVersionUpdate(params)),
        deleteImageVersion: (params: number) => dispatch(ImageVersionActions.CreateImageVersionDelete(params)),
        retrieveImageVersion: (params: number) => dispatch(ImageVersionActions.CreateImageVersionRetrieve(params)),
        clearImages: (params: boolean) => dispatch(ImageActions.CreateClearImageList(params))
    }
}

export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(ImageView, InstanceViewStyles)
            )
        )
    )
);