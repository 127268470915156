/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MailModelInterface {
    id?: number;
    name?: string;
    description?: string | null;
    subject?: string;
    event?: string;
    content?: string;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface MailModel
 */
export class MailModel extends BaseModel<MailModelInterface> {

    /**
     * id of the mail
     * @type {number}
     * @memberof MailModel
     */
    id?: number;
    /**
     * The readable name of this mail
     * @type {string}
     * @memberof MailModel
     */
    name?: string;
    /**
     * Description of the mail
     * @type {string}
     * @memberof MailModel
     */
    description?: string | null;
    /**
     * The subject of the mail
     * @type {string}
     * @memberof MailModel
     */
    subject?: string;
    /**
     * The event that should occur for a mail to send
     * @type {string}
     * @memberof MailModel
     */
    event?: string;
    /**
     * The content of the mail. Expected in unlayer JSON format
     * @type {string}
     * @memberof MailModel
     */
    content?: string;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof MailModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof MailModel
     */
    updated_at?: Date;

    constructor(data?: MailModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MailModel, value: StringDateInterface<MailModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MailModelFromJSON(json: any): MailModel {
    return MailModelFromJSONTyped(json, false);
}

export function MailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MailModel;
    try {
        let localClass = require.context('../override', true)('./MailModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function MailModelToJSON(value?: MailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MailModel;
    try {
        let localClass = require.context('../override', true)('./MailModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'subject': value.subject,
        'event': value.event,
        'content': value.content,
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<MailModel>;
    return createClass.toJson(value, model);
}


