/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    ServiceModel,
    ServiceModelFromJSON,
    ServiceModelFromJSONTyped,
    ServiceModelToJSON,
} from './';


export interface PackageModelInterface {
    id?: number;
    name?: string;
    description?: string;
    price?: number;
    services?: Array<ServiceModel>;
    created_at?: Date;
    updated_at?: Date;
}

/**
 * 
 * @export
 * @interface PackageModel
 */
export class PackageModel extends BaseModel<PackageModelInterface> {

    /**
     * id of the package
     * @type {number}
     * @memberof PackageModel
     */
    id?: number;
    /**
     * TODO: Documentation
     * @type {string}
     * @memberof PackageModel
     */
    name?: string;
    /**
     * TODO: Documentation
     * @type {string}
     * @memberof PackageModel
     */
    description?: string;
    /**
     * TODO: Documentation
     * @type {number}
     * @memberof PackageModel
     */
    price?: number;
    /**
     * 
     * @type {Array<ServiceModel>}
     * @memberof PackageModel
     */
    services?: Array<ServiceModel>;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof PackageModel
     */
    created_at?: Date;
    /**
     * UTC date time string
     * @type {Date}
     * @memberof PackageModel
     */
    updated_at?: Date;

    constructor(data?: PackageModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: PackageModel, value: StringDateInterface<PackageModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function PackageModelFromJSON(json: any): PackageModel {
    return PackageModelFromJSONTyped(json, false);
}

export function PackageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = PackageModel;
    try {
        let localClass = require.context('../override', true)('./PackageModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : Number(json['price']),
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ServiceModelFromJSON)),
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'].replace(' ', 'T'))),
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'].replace(' ', 'T'))),
    });
}

export function PackageModelToJSON(value?: PackageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = PackageModel;
    try {
        let localClass = require.context('../override', true)('./PackageModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ServiceModelToJSON)),
        'created_at': value.created_at === undefined ? undefined : (value.created_at.toApiFormat()),
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toApiFormat()),
    } as StringDateInterface<PackageModel>;
    return createClass.toJson(value, model);
}


