/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    ApiResponseModel,
    ApiResponseModelFromJSON,
    ApiResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    InstanceListResultModel,
    InstanceListResultModelFromJSON,
    InstanceListResultModelToJSON,
    InstanceRetrieveListModel,
    InstanceRetrieveListModelFromJSON,
    InstanceRetrieveListModelToJSON,
    ProcessListResultModel,
    ProcessListResultModelFromJSON,
    ProcessListResultModelToJSON,
    ServiceContainerListResultModel,
    ServiceContainerListResultModelFromJSON,
    ServiceContainerListResultModelToJSON,
    ServiceContainerRetrieveListModel,
    ServiceContainerRetrieveListModelFromJSON,
    ServiceContainerRetrieveListModelToJSON,
    ServiceCreateSuccessModel,
    ServiceCreateSuccessModelFromJSON,
    ServiceCreateSuccessModelToJSON,
    ServiceListResultModel,
    ServiceListResultModelFromJSON,
    ServiceListResultModelToJSON,
    ServiceModel,
    ServiceModelFromJSON,
    ServiceModelToJSON,
    ServiceRemoveSuccessModel,
    ServiceRemoveSuccessModelFromJSON,
    ServiceRemoveSuccessModelToJSON,
    ServiceRetrieveListModel,
    ServiceRetrieveListModelFromJSON,
    ServiceRetrieveListModelToJSON,
    ServiceSecretModel,
    ServiceSecretModelFromJSON,
    ServiceSecretModelToJSON,
    ServiceSecretResponseModel,
    ServiceSecretResponseModelFromJSON,
    ServiceSecretResponseModelToJSON,
} from '../models';

export interface ServiceCreateRequest {
    serviceModel: ServiceModel;
}

export interface ServiceDeleteRequest {
    uuid: string;
}

export interface ServiceHistoryListRequest {
    uuid: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface ServiceInstanceListRequest {
    uuid: string;
    instanceRetrieveListModel: InstanceRetrieveListModel;
}

export interface ServiceListRequest {
    serviceRetrieveListModel: ServiceRetrieveListModel;
}

export interface ServiceLogsListRequest {
    uuid: string;
    limit: number;
    offset: number;
    sortBy?: string;
    sortDir?: string;
    searchText?: string;
}

export interface ServiceRetrieveRequest {
    uuid: string;
}

export interface ServiceSecretsRetrieveRequest {
    uuid: string;
}

export interface ServiceSecretsUpdateRequest {
    uuid: string;
    serviceSecretModel: ServiceSecretModel;
}

export interface ServiceServiceContainerListRequest {
    uuid: string;
    serviceContainerRetrieveListModel: ServiceContainerRetrieveListModel;
}

export interface ServiceTasksRequest {
    uuid: string;
    task: string;
    uuids?: Array<string>;
}

export interface ServiceUnlockRequest {
    uuid: string;
}

export interface ServiceUpdateRequest {
    uuid: string;
    serviceModel: ServiceModel;
}

/**
 * 
 */
export class ServiceApi extends runtime.BaseAPI {

    /**
     * Create a new service
     * Create a new service
     */
    async serviceCreateRaw(requestParameters: ServiceCreateRequest): Promise<runtime.ApiResponse<ServiceCreateSuccessModel>> {
        if (requestParameters.serviceModel === null || requestParameters.serviceModel === undefined) {
            throw new runtime.RequiredError('serviceModel','Required parameter requestParameters.serviceModel was null or undefined when calling serviceCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceModelToJSON(requestParameters.serviceModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new service
     * Create a new service
     */
    serviceCreate = async(requestParameters: ServiceCreateRequest): Promise<ServiceCreateSuccessModel> => {
        const response = await this.serviceCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a service
     * Delete a service
     */
    async serviceDeleteRaw(requestParameters: ServiceDeleteRequest): Promise<runtime.ApiResponse<ServiceRemoveSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a service
     * Delete a service
     */
    serviceDelete = async(requestParameters: ServiceDeleteRequest): Promise<ServiceRemoveSuccessModel> => {
        const response = await this.serviceDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an service
     * retrieve a list of historical logs for an service
     */
    async serviceHistoryListRaw(requestParameters: ServiceHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling serviceHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/history`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an service
     * retrieve a list of historical logs for an service
     */
    serviceHistoryList = async(requestParameters: ServiceHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.serviceHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all instances that use this service
     * List all instances that use this service
     */
    async serviceInstanceListRaw(requestParameters: ServiceInstanceListRequest): Promise<runtime.ApiResponse<InstanceListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceInstanceList.');
        }

        if (requestParameters.instanceRetrieveListModel === null || requestParameters.instanceRetrieveListModel === undefined) {
            throw new runtime.RequiredError('instanceRetrieveListModel','Required parameter requestParameters.instanceRetrieveListModel was null or undefined when calling serviceInstanceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/instances`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstanceRetrieveListModelToJSON(requestParameters.instanceRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InstanceListResultModelFromJSON(jsonValue));
    }

    /**
     * List all instances that use this service
     * List all instances that use this service
     */
    serviceInstanceList = async(requestParameters: ServiceInstanceListRequest): Promise<InstanceListResultModel> => {
        const response = await this.serviceInstanceListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all services
     * List all services
     */
    async serviceListRaw(requestParameters: ServiceListRequest): Promise<runtime.ApiResponse<ServiceListResultModel>> {
        if (requestParameters.serviceRetrieveListModel === null || requestParameters.serviceRetrieveListModel === undefined) {
            throw new runtime.RequiredError('serviceRetrieveListModel','Required parameter requestParameters.serviceRetrieveListModel was null or undefined when calling serviceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceRetrieveListModelToJSON(requestParameters.serviceRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceListResultModelFromJSON(jsonValue));
    }

    /**
     * List all services
     * List all services
     */
    serviceList = async(requestParameters: ServiceListRequest): Promise<ServiceListResultModel> => {
        const response = await this.serviceListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all logs of a service
     * List all logs of a service
     */
    async serviceLogsListRaw(requestParameters: ServiceLogsListRequest): Promise<runtime.ApiResponse<ProcessListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceLogsList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling serviceLogsList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling serviceLogsList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDir !== undefined) {
            queryParameters['sort_dir'] = requestParameters.sortDir;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/logs`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProcessListResultModelFromJSON(jsonValue));
    }

    /**
     * List all logs of a service
     * List all logs of a service
     */
    serviceLogsList = async(requestParameters: ServiceLogsListRequest): Promise<ProcessListResultModel> => {
        const response = await this.serviceLogsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a service by its uuid
     * Retrieve an service by its uuid
     */
    async serviceRetrieveRaw(requestParameters: ServiceRetrieveRequest): Promise<runtime.ApiResponse<ServiceModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a service by its uuid
     * Retrieve an service by its uuid
     */
    serviceRetrieve = async(requestParameters: ServiceRetrieveRequest): Promise<ServiceModel> => {
        const response = await this.serviceRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve the secrets of a service
     * Retrieve the secrets of a service
     */
    async serviceSecretsRetrieveRaw(requestParameters: ServiceSecretsRetrieveRequest): Promise<runtime.ApiResponse<ServiceSecretResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceSecretsRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/secrets`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretResponseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the secrets of a service
     * Retrieve the secrets of a service
     */
    serviceSecretsRetrieve = async(requestParameters: ServiceSecretsRetrieveRequest): Promise<ServiceSecretResponseModel> => {
        const response = await this.serviceSecretsRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the secrets of a service
     * Update the secrets of a service
     */
    async serviceSecretsUpdateRaw(requestParameters: ServiceSecretsUpdateRequest): Promise<runtime.ApiResponse<ServiceSecretResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceSecretsUpdate.');
        }

        if (requestParameters.serviceSecretModel === null || requestParameters.serviceSecretModel === undefined) {
            throw new runtime.RequiredError('serviceSecretModel','Required parameter requestParameters.serviceSecretModel was null or undefined when calling serviceSecretsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/secrets`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceSecretModelToJSON(requestParameters.serviceSecretModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceSecretResponseModelFromJSON(jsonValue));
    }

    /**
     * Update the secrets of a service
     * Update the secrets of a service
     */
    serviceSecretsUpdate = async(requestParameters: ServiceSecretsUpdateRequest): Promise<ServiceSecretResponseModel> => {
        const response = await this.serviceSecretsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all service containers of a service
     * List all service containers of a service
     */
    async serviceServiceContainerListRaw(requestParameters: ServiceServiceContainerListRequest): Promise<runtime.ApiResponse<ServiceContainerListResultModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceServiceContainerList.');
        }

        if (requestParameters.serviceContainerRetrieveListModel === null || requestParameters.serviceContainerRetrieveListModel === undefined) {
            throw new runtime.RequiredError('serviceContainerRetrieveListModel','Required parameter requestParameters.serviceContainerRetrieveListModel was null or undefined when calling serviceServiceContainerList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/containers`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceContainerRetrieveListModelToJSON(requestParameters.serviceContainerRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceContainerListResultModelFromJSON(jsonValue));
    }

    /**
     * List all service containers of a service
     * List all service containers of a service
     */
    serviceServiceContainerList = async(requestParameters: ServiceServiceContainerListRequest): Promise<ServiceContainerListResultModel> => {
        const response = await this.serviceServiceContainerListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Schedule tasks for a service
     * Schedule tasks for a service
     */
    async serviceTasksRaw(requestParameters: ServiceTasksRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceTasks.');
        }

        if (requestParameters.task === null || requestParameters.task === undefined) {
            throw new runtime.RequiredError('task','Required parameter requestParameters.task was null or undefined when calling serviceTasks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.task !== undefined) {
            queryParameters['task'] = requestParameters.task;
        }

        if (requestParameters.uuids) {
            queryParameters['uuids[]'] = requestParameters.uuids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/task`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Schedule tasks for a service
     * Schedule tasks for a service
     */
    serviceTasks = async(requestParameters: ServiceTasksRequest): Promise<ApiResponseModel> => {
        const response = await this.serviceTasksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unlock service
     * Unlock service
     */
    async serviceUnlockRaw(requestParameters: ServiceUnlockRequest): Promise<runtime.ApiResponse<ApiResponseModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}/unlock`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponseModelFromJSON(jsonValue));
    }

    /**
     * Unlock service
     * Unlock service
     */
    serviceUnlock = async(requestParameters: ServiceUnlockRequest): Promise<ApiResponseModel> => {
        const response = await this.serviceUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an service
     * Update an service
     */
    async serviceUpdateRaw(requestParameters: ServiceUpdateRequest): Promise<runtime.ApiResponse<ServiceCreateSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling serviceUpdate.');
        }

        if (requestParameters.serviceModel === null || requestParameters.serviceModel === undefined) {
            throw new runtime.RequiredError('serviceModel','Required parameter requestParameters.serviceModel was null or undefined when calling serviceUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/service/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceModelToJSON(requestParameters.serviceModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Update an service
     * Update an service
     */
    serviceUpdate = async(requestParameters: ServiceUpdateRequest): Promise<ServiceCreateSuccessModel> => {
        const response = await this.serviceUpdateRaw(requestParameters);
        return await response.value();
    }

}
