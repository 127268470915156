import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailsField from 'components/DetailsField';
import { ImageModel } from 'api';
import useDetailsTabStyles from 'styles/DetailsTabStyles';
import BaseDetailsViewActions from 'components/BaseDetailsViewActions';

interface ImageDetailsProps {
    image: ImageModel;
    onImageEdit?: () => void;
    onImageDelete?: () => void;
}

const ImageDetails = (props: ImageDetailsProps) => {
    const { t } = useTranslation();
    const { image, onImageEdit, onImageDelete } = props;
    const {classes} = useDetailsTabStyles();

    return (
        <div className={classes.card_content}>
            <BaseDetailsViewActions
                label={t('image.model')}
                onEdit={onImageEdit}
                onDelete={onImageDelete}
            />
            <DetailsField propKey={t('image.name')} propVal={image?.name} />
            <DetailsField propKey={t('image.description')} propVal={image?.description} />
            <DetailsField propKey={t('image.docker_img')} propVal={image?.docker_image} />
            <DetailsField propKey={t('image.for_model')} propVal={image?.for_model} />
        </div>
    );
};

export default ImageDetails;