import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useTerminalStyles = makeStyles()((theme: Theme) => {
    return {
        root: {
            '&.show': {
                display: 'flex',
            },
            height: '100%',
            width: '100%',
            zIndex: 20000,
            display: 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            background: 'rgba(0, 0, 0, .6)',
            '& > div': {
                height: '60% !important',
                width: '80%',
                opacity: '.9',
                borderRadius: 10,
                boxShadow: theme.palette.mode === 'dark' ? '0px 0px 30px 4px #000000' : '0px 0px 30px 4px #242424',
                '& > div': {
                    borderRadius: 10,
                }
            },
            '& > button': {
                marginLeft: '78%',
                marginBottom: 10,
                background: '#272b36',
                color: '#fff',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                    background: '#15171e',
                    color: theme.palette.error.main,
                    transform: 'rotate(90deg)'
                }
            },
            '& #terminalEditor': {
                fontSize: '14px',
                '& > div:last-child > span:nth-of-type(2) > span': {
                    height: 18,
                    top: 0,
                    width: 2,
                    background: 'rgb(182, 67, 209) !important'
                }
            }
        }
    }
})

export default useTerminalStyles